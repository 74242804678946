import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    tabs: {
      backgroundColor: "#2A343C",
      paddingLeft: theme.spacing(1),
      flexShrink: 0,
    },
    tab: {
      color: "#CBD1E1",
      opacity: 0.4,
    },
    active: {
      opacity: 1,
      color: "#fff",
    },
    scroller: {
      overflow: "hidden",
      height: "100%",
      flexGrow: 1,
    },
    framesWrapper: {
      height: "100%",
      display: "flex",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.complex,
        easing: theme.transitions.easing.sharp,
      }),
    },
  }),
  {
    name: "TerminalContainer",
  }
)

export default styles
