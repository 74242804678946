import { getLearningCues } from "actions/lessons"
import { setLearningCues } from "actions/lessons"
import { orderBy, uniqBy } from "lodash"
import { difference, isEmpty } from "lodash"
import PropTypes from "prop-types"
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"
import { useMutation, useQuery } from "react-query"
import { v4 as uuidv4 } from "uuid"

import AppContext from "./App"

const LearningCuesContext = createContext()
export default LearningCuesContext

export const LearningCuesContainer = ({ children, lesson }) => {
  const { openSnackBar } = useContext(AppContext)

  const [backwardSteps, setBackwardSteps] = useState([])
  const [forwardSteps, setForwardSteps] = useState([])

  const [cueCount, setCueCount] = useState(null)

  // editingLearningCues is the object we pass to render components
  const editingLearningCues = useMemo(() => {
    if (backwardSteps?.length === 0 || backwardSteps?.[0] === undefined) {
      // while query.isLoading, sets to empty arr.
      return []
    } else {
      return backwardSteps[backwardSteps?.length - 1]
    }
  }, [backwardSteps])

  const [highlightedLearningCue, setHighlightedLearningCue] = useState(null)

  const learningCuesQuery = useQuery(
    ["getLearningCues", lesson.id],
    () => getLearningCues(lesson.id),
    {
      onSuccess: (data) => {
        setCueCount(data?.length)
        setBackwardSteps([data])
      },
      cacheTime: 0,
    }
  )

  const resetLearningCues = (updateData) => {
    setBackwardSteps((currentSteps) => {
      if (updateData) {
        return [updateData]
      } else {
        return [learningCuesQuery.data]
      }
    })
    setForwardSteps([])
  }

  const addNewLearningCue = (time) => {
    setBackwardSteps((currentBackwardSteps) => {
      return [
        ...currentBackwardSteps,
        [
          ...editingLearningCues,
          {
            id: uuidv4(),
            start_seconds: time,
            fullscreen: false,
            force_pause: false,
          },
        ],
      ]
    })
    setForwardSteps([]) // clears any redo steps
  }

  const copyLearningCue = (time, learningCue) => {
    setBackwardSteps((currentBackwardSteps) => {
      return [
        ...currentBackwardSteps,
        [...editingLearningCues, { ...learningCue, start_seconds: time }],
      ]
    })
    setForwardSteps([]) // clears any redo steps
  }

  const removeLearningCue = (learningCueId) => {
    setBackwardSteps((currentBackwardSteps) => {
      return [
        ...currentBackwardSteps,
        editingLearningCues.filter((cue) => cue.id !== learningCueId),
      ]
    })
    setForwardSteps([]) // clears any redo steps
  }

  const changeCheckedValue = (learningCueId, name, checked) => {
    setBackwardSteps((currentBackwardSteps) => {
      return [
        ...currentBackwardSteps,
        editingLearningCues.map((cue) => {
          if (cue.id === learningCueId) {
            cue = {
              ...cue,
              [name]: checked,
            }
          }
          return cue
        }),
      ]
    })
    setForwardSteps([]) // clears any redo steps
  }

  const cleanCues = (cues, type) => {
    return orderBy(
      cues.filter((c) => c.hasOwnProperty(type) && !isEmpty(c[type])),
      ["start_seconds"],
      ["asc"]
    ).map((cue, index, arr) => {
      let thisCue = {
        ...cue,
      }
      let endingTime = thisCue.start_seconds + thisCue[type].display_seconds
      const nextCueOfType = arr[index + 1]
      if (nextCueOfType && nextCueOfType.start_seconds < endingTime) {
        thisCue = {
          ...thisCue,
          [type]: {
            ...thisCue[type],
            display_seconds:
              thisCue[type].display_seconds -
              (endingTime - nextCueOfType.start_seconds),
          },
        }
        endingTime = thisCue.start_seconds + thisCue[type].display_seconds
      }
      if (endingTime > Math.floor(lesson?.duration)) {
        thisCue = {
          ...thisCue,
          [type]: {
            ...thisCue[type],
            display_seconds: Math.floor(
              lesson.duration - thisCue.start_seconds
            ),
          },
        }
      }
      return thisCue
    })
  }
  const cleanFullscreenCues = (cues) => {
    const fullscreenCues = orderBy(
      cues.filter((c) => c.fullscreen),
      ["start_seconds"],
      ["asc"]
    )
    const otherCues = orderBy(
      cues.filter(
        (c) => c.hasOwnProperty("media") || c.hasOwnProperty("knowledgeCheck")
      ),
      ["start_seconds"],
      ["asc"]
    )
    return fullscreenCues.map((cue) => {
      let thisCue = {
        ...cue,
      }
      // ending time of this fullscreen cue
      let endingTime = thisCue.start_seconds + thisCue.fullscreen_seconds
      // cues that start before the start this fullscreen cue
      const beforeCues = otherCues.filter(
        (c) => c.start_seconds <= thisCue.start_seconds
      )
      // the before cue that is the closes in start time to this fullscreen cue
      const closestBeforeCue = beforeCues[beforeCues.length - 1]
      // cues that start after the start of this fullscreen cue
      const afterCues = otherCues.filter(
        (c) => c.start_seconds >= thisCue.start_seconds
      )
      if (closestBeforeCue) {
        // get the furthest ending time of the closest before cue
        const closestBeforeCueEndingTime =
          closestBeforeCue.start_seconds +
          (closestBeforeCue?.media?.display_seconds ||
            closestBeforeCue?.knowledgeCheck?.display_seconds)
        if (
          thisCue.start_seconds > closestBeforeCue.start_seconds &&
          thisCue.start_seconds < closestBeforeCueEndingTime
        ) {
          // the start of this fullscreen cue is in between the closest cue starting before this fullscreen cue
          thisCue = {
            ...thisCue,
            start_seconds: closestBeforeCueEndingTime, // sets this fullscreen cue to start at the end of the closest before cue
          }
          endingTime = thisCue.start_seconds + thisCue.fullscreen_seconds
        }
      }
      if (afterCues.length > 0 && endingTime > afterCues[0].start_seconds) {
        // if the end of this fullscreen cue is after the start of the closest following cue
        thisCue = {
          ...thisCue,
          // set this cues fullscreen_seconds to stop before the start of the very next media or knowledge check
          fullscreen_seconds:
            thisCue.fullscreen_seconds -
            (endingTime - otherCues[0].start_seconds),
        }
      }
      if (endingTime > Math.floor(lesson?.duration)) {
        thisCue = {
          ...thisCue,
          fullscreen_seconds: Math.floor(
            lesson.duration - thisCue.start_seconds
          ),
        }
      }
      return thisCue
    })
  }

  const updateCuesWithContent = (cues) => {
    // clean up overlapping cues
    const emptyCues = cues.filter(
      (c) =>
        isEmpty(c.topBanner) &&
        isEmpty(c.bottomBanner) &&
        isEmpty(c.media) &&
        isEmpty(c.knowledgeCheck) &&
        !c.fullscreen
    )
    const notEmptyCues = difference(cues, emptyCues)
    const mediaCues = cleanCues(notEmptyCues, "media")
    const topBannerCues = cleanCues(notEmptyCues, "topBanner")
    const bottomBannerCues = cleanCues(notEmptyCues, "bottomBanner")
    const knowledgeCheckCues = cleanCues(notEmptyCues, "knowledgeCheck")
    const fullscreenCues = cleanFullscreenCues(notEmptyCues, "fullscreen")
    return uniqBy(
      emptyCues.concat(
        mediaCues,
        topBannerCues,
        bottomBannerCues,
        knowledgeCheckCues,
        fullscreenCues
      ),
      "id"
    )
  }

  const updateLearningCueTime = (learningCue, time) => {
    setBackwardSteps((currentBackwardSteps) => {
      const cleanedTime = time > lesson?.duration ? lesson?.duration : time
      const updatedEditingLearningCues = editingLearningCues.map(
        (cue, index) => {
          return {
            ...cue,
            start_seconds:
              cue.id === learningCue.id ? cleanedTime : cue.start_seconds,
          }
        }
      )
      // clean up overlapping cues
      const cleanedCues = updateCuesWithContent(updatedEditingLearningCues)
      return [...currentBackwardSteps, cleanedCues]
    })
    setForwardSteps([]) // clears any redo steps
  }

  const setLearningCueContent = (cueId, data) => {
    setBackwardSteps((currentSteps) => {
      // update learning cues first
      const updatedEditingLearningCues = editingLearningCues.map((c) => {
        if (c.id === cueId) {
          return {
            ...c,
            ...data,
          }
        }
        return c
      })
      const cleanedCues = updateCuesWithContent(updatedEditingLearningCues)
      return [...currentSteps, cleanedCues]
    })
    setForwardSteps([]) // clears any redo steps
  }

  const undo = useCallback(() => {
    setBackwardSteps((currentBackwardSteps) => {
      const newBackSteps = [...currentBackwardSteps]
      const lastStep = newBackSteps.pop()
      setForwardSteps((currentForwardSteps) => {
        return [lastStep, ...currentForwardSteps]
      })
      return newBackSteps
    })
  }, [])

  const redo = useCallback(() => {
    setForwardSteps((currentForwardSteps) => {
      const newForwardSteps = [...currentForwardSteps]
      const firstStep = newForwardSteps.shift()
      setBackwardSteps((currentBackwardSteps) => {
        return [...currentBackwardSteps, firstStep]
      })
      return newForwardSteps
    })
  }, [])

  const setLearningCuesMutation = useMutation(
    "setLearningCues",
    ({ id, data }) => setLearningCues(data, id)
  )

  const saveLearningCues = ({ onSuccess, onError, ...options }) => {
    return setLearningCuesMutation.mutate(
      { id: lesson.id, data: editingLearningCues },
      {
        onSuccess: (responseData) => {
          // clear history on success
          resetLearningCues(responseData)
          setCueCount(responseData?.length)
          openSnackBar({
            message: "Learning cues saved successfully",
          })
          onSuccess?.(responseData)
        },
        onError: (err) => {
          console.error(err)
          openSnackBar({
            message:
              err?.response?.error || "There was an error saving learning cues",
          })
          onError?.(err)
        },
        ...options,
      }
    )
  }

  return (
    <LearningCuesContext.Provider
      value={{
        editingLearningCues,
        addNewLearningCue,
        copyLearningCue,
        removeLearningCue,
        changeCheckedValue,
        backwardSteps,
        forwardSteps,
        undo,
        redo,
        updateLearningCueTime,
        saveLearningCues,
        learningCuesQuery,
        setLearningCueContent,
        resetLearningCues,
        highlightedLearningCue,
        setHighlightedLearningCue,
        setCueCount,
        cueCount,
      }}
    >
      {children}
    </LearningCuesContext.Provider>
  )
}

LearningCuesContainer.propTypes = {
  children: PropTypes.node,
  lesson: PropTypes.object,
}
