import { Box, LinearProgress } from "@material-ui/core"
import { listPaths } from "actions/paths"
import resourcesColor from "assets/Icons/colored-glasses.svg"
import analyticsColor from "assets/Icons/colored-pie-chart.svg"
import contentColor from "assets/Icons/colored-video.svg"
import analytics from "assets/Icons/feather-pie-chart.svg"
import content from "assets/Icons/feather-video.svg"
import resources from "assets/Icons/noun_Glasses_1182135.svg"
import ContentTabs from "components/Common/ContentTabs"
import PathHeader from "components/Paths/PathHeader"
import UnsavedChanges from "components/Paths/UnsavedChanges"
import AppContext from "contexts/App"
import { PathContainer } from "contexts/Path"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useContext } from "react"
import { useQuery } from "react-query"
import Router from "router"

import useStyles from "./styles"

const Path = ({ routes = [], ...rest }) => {
  const classes = useStyles()
  const pathId = +rest?.match?.params?.learningPath_id

  const { authUser } = useContext(AppContext)

  const { data: paths, isLoading: pathsLoading } = useQuery("paths", listPaths)
  let path = paths?.filter((x) => x.id === pathId)[0]

  const withAnalytics = [
    {
      label: "Learning Path Content",
      // link: `/learningPath/${learningPathId}`,
      link: `/learning-paths/${pathId}/content`,

      icon1: content,
      icon2: contentColor,
    },

    {
      label: "Analytics",
      // link: `/learningPath/${learningPathId}/analytics`,
      link: `/learning-paths/${pathId}/analytics`,
      icon1: analytics,
      icon2: analyticsColor,
    },
  ]
  const withoutAnalytics = [
    {
      label: "Learning Path Content",
      // link: `/learningPath/${learningPathId}`,
      link: `/learning-paths/${pathId}/content`,

      icon1: content,
      icon2: contentColor,
    },
  ]

  const tabs =
    authUser?.access_analytics === 1 ? withAnalytics : withoutAnalytics

  if (!path?.id) {
    return <LinearProgress color="primary" />
  }

  return (
    <Page>
      <PathContainer path={path}>
        <Box display="flex" flexDirection="column" height="100%">
          <Box flexShrink={0} flexGrow={0}>
            <PathHeader pathId={1} />
            <ContentTabs tabs={tabs} />
          </Box>
          <Box flexGrow={1} overflow="auto">
            <Router routes={routes} {...rest} />
          </Box>
        </Box>
        <UnsavedChanges />
      </PathContainer>
    </Page>
  )
}

Path.propTypes = {
  routes: PropTypes.array,
}

export default Path
