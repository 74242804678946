import LoaderButton from "@bit/c_t.components.loader-button"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core"
import AppContext from "contexts/App"
import { Form, Formik } from "formik"
import { useContext } from "react"
import { useQueryClient } from "react-query"
import serialize from "store/serialize"
import * as Yup from "yup"

import useStyles from "./styles"

const CreateAdminDialog = () => {
  const classes = useStyles()
  const { dialogs, closeDialog } = useContext(AppContext)
  const { openSnackBar } = useContext(AppContext)
  const queryClient = useQueryClient()
  const thisDialog = dialogs?.["createAdminDialog"] || {}
  const { open = false, data } = thisDialog
  let createAdminMutation = thisDialog?.data?.createAdminFunc
  let refetchAdmins = thisDialog?.data?.refetchAdmins

  const schema = Yup.object().shape({
    name: Yup.string().required("Please enter a full name"),
    email: Yup.string().email().required("Please enter a valid email"),
  })
  const handleClose = () => {
    closeDialog("createAdminDialog", false)
  }

  const onSubmit = (values, actions) => {
    createAdminMutation.mutate(values, {
      onSuccess: (res) => {
        serialize("admins", res.data).then((serializedData) => {
          queryClient.setQueryData("admins", (oldData) => {
            return [...oldData, serializedData]
          })
        })
        openSnackBar({
          message: "Admin Invited",
        })
      },
      onError: (err) => {
        if (err.response.data.error.includes("Duplicate entry")) {
          openSnackBar({
            message: "An invite has already been sent to this email",
          })
        } else {
          openSnackBar({
            message: "Error Inviting Admin",
          })
        }
      },
      onSettled: () => {
        actions.setSubmitting(false)
        handleClose()
        refetchAdmins()
      },
    })
  }

  return (
    <Dialog classes={{ paperWidthSm: classes.dialogMinHeight }} open={open}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography variant="h2" className={classes.title}>
          New Admin
        </Typography>
        <Typography color="primary" variant="subtitle2">
          Invite admin to join
        </Typography>
      </DialogTitle>
      <Formik
        initialValues={{ name: "", email: "" }}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        {({
          handleChange,
          values,
          errors,
          touched,
          handleBlur,
          isSubmitting,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <DialogContent className={classes.dialogContent}>
              <Typography color="secondary" className={classes.title}>
                Full Name
              </Typography>

              <TextField
                type="text"
                name="name"
                id="name"
                value={values.name}
                // placeholder="Email"
                // label="Email"
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.name && errors.name ? errors.name : " "}
                InputProps={{
                  classes: {
                    root: classes.inputBackgroundColor,
                  },
                }}
                error={touched.name && errors.name}
                variant="filled"
                size="small"
                fullWidth
                FormHelperTextProps={{
                  className: classes.formHelperText,
                }}
              />
              <Typography color="secondary" className={classes.title}>
                Email
              </Typography>

              <TextField
                type="text"
                name="email"
                id="email"
                value={values.email}
                // placeholder="Email"
                // label="Email"
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.email && errors.email ? errors.email : " "}
                InputProps={{
                  classes: {
                    root: classes.inputBackgroundColor,
                  },
                }}
                error={touched.name && errors.email}
                variant="filled"
                size="small"
                fullWidth
                FormHelperTextProps={{
                  className: classes.formHelperText,
                }}
              />
            </DialogContent>
            <DialogActions
              style={{
                position: "absolute",
                bottom: 4,
                right: 16,
              }}
            >
              <Button onClick={handleClose} style={{ height: "40px" }}>
                Cancel
              </Button>
              <LoaderButton
                type="submit"
                // onClick={onSubmit}
                classoverrides={{
                  wrapper: classes.button,
                  button: {
                    root: classes.buttonRoot,
                  },
                }}
                variant="contained"
                color="secondary"
                working={isSubmitting}
                size="small"
              >
                add
              </LoaderButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default CreateAdminDialog
