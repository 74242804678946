import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  dialogPaper: {
    maxWidth: 855,
  },
  dialogPaperLocked: {
    maxWidth: 500,
  },
  dialogContent: {
    display: "flex",
    padding: theme.spacing(2.25),
    paddingRight: theme.spacing(1.25),
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      overflow: "scroll",
    },
  },
  questionsNumber: {
    marginLeft: theme.spacing(15),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(0),
    },
  },
  minPassScore: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  inputLabel: {
    marginBottom: theme.spacing(1),
  },
  actionBox: {
    [theme.breakpoints.down("sm")]: {
      width: 300,
      margin: 0,
    },
  },
  requiredText: {
    color: theme.palette.warning.main,
    marginTop: theme.spacing(1),
  },
  root: {
    color: "red",
  },
}))

export default styles
