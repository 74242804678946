import PropTypes from "prop-types"
import { createContext } from "react"

const SubscriptionsContext = createContext()
export default SubscriptionsContext

export const SubscriptionsContainer = ({ children }) => {
  return (
    <SubscriptionsContext.Provider value={{}}>
      {children}
    </SubscriptionsContext.Provider>
  )
}

SubscriptionsContainer.propTypes = {
  children: PropTypes.array,
}
