import LoaderButton from "@bit/c_t.components.loader-button"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  Typography,
} from "@material-ui/core"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle"
import { ReactComponent as NotesIcon } from "assets/Icons/pen-icon.svg"
import CounterHeader from "components/Common/CounterHeader"
import App from "contexts/App"
import { Field, FieldArray, Formik } from "formik"
import { TextField } from "formik-material-ui"
import { isEmpty } from "lodash"
import { useCallback, useContext, useMemo } from "react"
import * as Yup from "yup"

import DialogTitle from "../components/DialogTitle"
import useStyles from "./styles"

const schema = Yup.object({
  notes: Yup.array().of(
    Yup.object().shape({
      content: Yup.string("Note content is required")
        .required("Note content is required")
        .max(1000, "Question must be less than 1000 characters"),
    })
  ),
})

const NotesDialog = () => {
  const classes = useStyles()

  const { dialogs, closeDialog } = useContext(App)
  const thisDialog = dialogs?.["notes"] || {}
  const { open = false, data = {} } = thisDialog
  const { lockdown, startSeconds, ...restData } = data

  const handleClose = () => {
    closeDialog("notes")
  }

  const handleSubmit = useCallback((values, actions) => {
    closeDialog("notes", true, values)
    actions.setSubmitting(false)
  }, [])

  const initialValues = useMemo(() => {
    // new banners getting restData, but don't have id
    if (!isEmpty(restData) && !restData.id) {
      return {
        ...restData,
      }
      // id only pre-existing bottom banner info
    } else if (!isEmpty(restData) && restData.id) {
      return restData
    } else {
      return {
        notes: [
          {
            content: "",
          },
        ],
      }
    }
  }, [restData])

  const handleRemove = () => {
    closeDialog("notes", true, undefined)
  }

  const handleAddNewNote = () => {}

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ submitForm, resetForm, values, isValid }) => (
        <Dialog open={open} onClose={handleClose} onExited={resetForm}>
          <DialogTitle
            title="Notes"
            icon={
              <NotesIcon
                style={{
                  width: 16,
                  height: 16,
                  position: "relative",
                  top: 1,
                  marginLeft: 10,
                  color: "#0078F6",
                }}
              />
            }
            disableTypography
            timestamp={startSeconds}
          />
          <DialogContent>
            <FieldArray name="notes">
              {({ push, remove }) => (
                <>
                  {values.notes.map((_, index) => (
                    <>
                      <CounterHeader
                        title={" " + "Enter note"}
                        value={values.notes[index].content}
                        max={1000}
                      />
                      <Field
                        component={TextField}
                        variant="filled"
                        name={`notes.${index}.content`}
                        fullWidth
                        style={{
                          marginBottom: index === 0 ? 24 : 0,
                        }}
                        disabled={lockdown}
                        multiline
                        maxRows={4}
                      />
                      {index > 0 && (
                        <Box textAlign="right">
                          <Button
                            startIcon={<RemoveCircleIcon />}
                            size="small"
                            onClick={() => remove(index)}
                            style={{ color: "red" }}
                          >
                            Remove
                          </Button>
                        </Box>
                      )}
                    </>
                  ))}
                  <br />
                  <Button
                    startIcon={<AddCircleIcon color="inherit" />}
                    color="secondary"
                    size="small"
                    onClick={() => push({ content: "" })}
                  >
                    Add Note
                  </Button>
                </>
              )}
            </FieldArray>
          </DialogContent>
          <DialogActions style={{ justifyContent: "space-between" }}>
            <Box>
              {!isEmpty(restData) && (
                <Button
                  className={classes.removeButton}
                  onClick={handleRemove}
                  type="button"
                  size="small"
                  disabled={lockdown}
                >
                  Remove
                </Button>
              )}
            </Box>
            <Box>
              <Button
                onClick={handleClose}
                type="button"
                style={{ marginRight: "2vw" }}
              >
                Close
              </Button>
              <LoaderButton
                variant="contained"
                color="secondary"
                onClick={submitForm}
                type="button"
                disabled={lockdown || !isValid}
              >
                Save
              </LoaderButton>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  )
}

export default NotesDialog
