import { getInstanceTypes } from "actions/labs"
import { listLabs } from "actions/labs"
import { createLab } from "actions/labs"
import PropTypes from "prop-types"
import { createContext } from "react"
import { useMutation, useQuery } from "react-query"

const LabsContext = createContext()
export default LabsContext

export const LabsContainer = ({ children }) => {
  const createLabMutation = useMutation(createLab)

  const { data: labs, isLoading: labsLoading } = useQuery("labs", listLabs)
  const { data: instanceTypes } = useQuery("instance_types", getInstanceTypes)

  return (
    <LabsContext.Provider
      value={{ createLabMutation, labs, labsLoading, instanceTypes }}
    >
      {children}
    </LabsContext.Provider>
  )
}

LabsContainer.propTypes = {
  children: PropTypes.node,
}
