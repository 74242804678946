import LoaderButton from "@bit/c_t.components.loader-button"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slider,
  Typography,
} from "@material-ui/core"
import App from "contexts/App"
import React, { useCallback, useContext, useEffect, useState } from "react"
import Cropper from "react-easy-crop"
import getCroppedImg from "utils/cropImage.js"

import useStyles from "./styles"

const CropThumbnailDialog = () => {
  const classes = useStyles()

  const { dialogs, closeDialog } = useContext(App)

  const thisDialog = dialogs?.["cropThumbnail"] || {}
  const { open = false, data } = thisDialog
  const imageFile = thisDialog?.data?.acceptedFiles?.[0]
  const setLoading = thisDialog?.data?.setLoading
  const type = thisDialog?.data?.type

  const [croppedAreaPixels, setCroppedAreaPixels] = useState("")
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1.1)
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const [img, setImg] = useState(null)

  const handleClose = () => {
    setZoom(1.1)
    setCrop({ x: 0, y: 0 })
    setImg(null)
    closeDialog("cropThumbnail")
  }

  function getBase64(file) {
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      setImg(reader.result)
    }

    reader.onerror = function (error) {
      console.log("Error: ", error)
    }
  }

  const save = async () => {
    const newImage = await getCroppedImg(img, croppedAreaPixels)
    let blob = await fetch(newImage).then((r) => r.blob())
    setZoom(1.1)
    setCrop({ x: 0, y: 0 })
    setImg(null)
    setLoading(true)
    closeDialog("cropThumbnail", true, blob)
  }

  useEffect(() => {
    if (imageFile) {
      getBase64(imageFile)
    }
  }, [imageFile])

  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle disableTypography>
        <Typography color="primary" variant="h3" style={{ fontWeight: 600 }}>
          {type === "background" ? "Crop Background Image" : "Crop Thumbnail"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box className={classes.cropContainer}>
            {type !== "badge_image" ? (
              <Cropper
                image={img}
                crop={crop}
                zoom={zoom}
                aspect={16 / 9}
                minZoom={0.1}
                onCropChange={setCrop}
                restrictPosition={false}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            ) : (
              <Cropper
                image={img}
                crop={crop}
                zoom={zoom}
                // aspect={16 / 9}
                cropSize={{ height: 400, width: 400 }}
                cropShape="round"
                minZoom={0.01}
                onCropChange={setCrop}
                restrictPosition={false}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            )}
          </Box>
        </Box>
        <Box marginTop={1} display="flex" justifyContent="center">
          <Slider
            value={zoom}
            min={0.1}
            max={3}
            step={type !== "badge_image" ? 0.1 : 0.01}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => setZoom(zoom)}
            style={{ width: "50%" }}
          />
        </Box>
      </DialogContent>
      <DialogActions style={{ marginRight: "16px" }}>
        <Button onClick={() => handleClose()}>Cancel</Button>
        <LoaderButton
          type="submit"
          onClick={() => save()}
          classoverrides={{
            wrapper: classes.button,
            button: {
              root: classes.buttonRoot,
            },
          }}
          variant="contained"
          color="secondary"
          size="small"
        >
          Save
        </LoaderButton>
      </DialogActions>
    </Dialog>
  )
}

export default CropThumbnailDialog
