import { Box, Typography } from "@material-ui/core"
import { AddCircle } from "@material-ui/icons"
import DarkButton from "components/Common/DarkButton"
import NoFinalAssessment from "components/courses/NoFinalAssessment"
import NoLessonAdded from "components/courses/NoLessonAdded"
import AppContext from "contexts/App"
import PathContext from "contexts/Path"
import AddCourseDialog from "dialogs/AddCourseDialog"
import React, { useContext, useEffect } from "react"

import AddedCourseCard from "../AddedCourseCard"
import NoCourseAdded from "../NoCourseAdded"
import useStyles from "./styles"

const PathCourses = () => {
  const classes = useStyles()

  const { path, courseArray, setCourseArray, setInitialCourses } = useContext(
    PathContext
  )
  const { openDialog } = useContext(AppContext)

  const handleOpen = () => {
    openDialog("addCourseDialog")
  }

  useEffect(() => {
    if (path.courses.length > 0) {
      let newArr = []
      path.courses.map((c) => {
        if (c.versions.find((v) => v.published === 1)) {
          const published = c.versions.find((v) => v.published === 1)
          newArr = [...newArr, published]
        } else {
          const latestVersion = c.versions[c.versions.length - 1]
          newArr = [...newArr, latestVersion]
        }
      })
      setCourseArray(newArr)
    }
  }, [])

  const disabledPath =
    (path.published || path.has_been_published) && path.archived === 0

  return (
    <Box position="relative" minHeight="100%">
      {disabledPath ? (
        <Box className={classes.publishedBanner}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {path?.published === 1 ? (
              <Typography className={classes.title} variant="h3">
                You are in a published learning path, this can not be updated
              </Typography>
            ) : null}
            {path?.published === 0 && path?.has_been_published === 1 ? (
              <Typography className={classes.title} variant="h3">
                You are in a unpublished learning path, this can not be updated
              </Typography>
            ) : null}
          </Box>
        </Box>
      ) : null}
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        padding="31px 37px 8px 37px"
        marginBottom="10px"
      >
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          <Typography
            variant="h3"
            color="primary"
            style={{ fontWeight: "700", marginRight: "8px" }}
          >
            Courses
          </Typography>
          <Typography
            variant="h3"
            color="primary"
            className={classes.orderText}
          >
            In order as they appear
          </Typography>
        </Box>
        <DarkButton
          onClick={handleOpen}
          style={path?.published ? { display: "none" } : null}
          startIcon={
            <AddCircle fontSize="small" classes={{ root: classes.addCircle }} />
          }
        >
          Add Course
        </DarkButton>
      </Box>
      <Box marginLeft={"37px"} marginRight="37px" paddingBottom={2}>
        {courseArray?.length > 0 ? (
          courseArray.map((course) => (
            <AddedCourseCard course={course} key={course.id} type="view" />
          ))
        ) : (
          <NoCourseAdded />
        )}
      </Box>
      {disabledPath ? <Box className={classes.publishedOverlay} /> : null}
      <AddCourseDialog />
    </Box>
  )
}

PathCourses.propTypes = {}
export default PathCourses
