import { Box } from "@material-ui/core"
import classnames from "clsx"
import LearningCuesContext from "contexts/LearningCues"
import VideoContext from "contexts/Video"
import { isEmpty } from "lodash"
import { memo, useContext } from "react"
import getPercentFromTime from "utils/getPercentFromTime"

import useStyles from "./styles"

const LearningCueBlocks = () => {
  const classes = useStyles()

  const { editingLearningCues, highlightedLearningCue } = useContext(
    LearningCuesContext
  )
  const { duration } = useContext(VideoContext)

  return (
    <Box className={classes.learningCueBlocks}>
      {editingLearningCues.length > 0 &&
        editingLearningCues.map((c) => {
          const leftOffset = getPercentFromTime(c.start_seconds, duration)
          return (
            <>
              {!isEmpty(c.media) && (
                <div
                  key={`${c.id}-media`}
                  className={classnames(classes.block, classes.mediaBlock, {
                    [classes.highlighted]: highlightedLearningCue === c.id,
                  })}
                  style={{
                    left: `${leftOffset}%`,
                    width: `${getPercentFromTime(
                      c.media?.display_seconds || 0,
                      duration
                    )}%`,
                  }}
                />
              )}
              {!isEmpty(c.topBanner) && (
                <div
                  key={`${c.id}-topBanner`}
                  className={classnames(classes.block, classes.topBannerBlock, {
                    [classes.highlighted]: highlightedLearningCue === c.id,
                  })}
                  style={{
                    left: `${leftOffset}%`,
                    width: `${getPercentFromTime(
                      c.topBanner?.display_seconds || 0,
                      duration
                    )}%`,
                  }}
                />
              )}
              {!isEmpty(c.bottomBanner) && (
                <div
                  key={`${c.id}-bottomBanner`}
                  className={classnames(
                    classes.block,
                    classes.bottomBannerBlock,
                    {
                      [classes.highlighted]: highlightedLearningCue === c.id,
                    }
                  )}
                  style={{
                    left: `${leftOffset}%`,
                    width: `${getPercentFromTime(
                      c.bottomBanner?.display_seconds || 0,
                      duration
                    )}%`,
                  }}
                />
              )}
              {c.fullscreen && (
                <div
                  key={`${c.id}-fullscreen`}
                  className={classnames(
                    classes.block,
                    classes.fullscreenBlock,
                    {
                      [classes.highlighted]: highlightedLearningCue === c.id,
                    }
                  )}
                  style={{
                    left: `${leftOffset}%`,
                    width: `${getPercentFromTime(
                      c.fullscreen_seconds || 0,
                      duration
                    )}%`,
                  }}
                />
              )}
            </>
          )
        })}
    </Box>
  )
}

export default memo(LearningCueBlocks)
