import PropTypes from "prop-types"
import React from "react"

const Terminal = () => {
  return (
    <iframe
      style={{
        height: "100%",
        width: "calc(100% - 16px)",
        marginLeft: "8px",
        border: "none",
      }}
      src="http://localhost:1212/"
      title="Lab Terminal"
    />
  )
}

Terminal.propTypes = {}

export default Terminal
