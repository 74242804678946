import { destroy, types as t } from "mobx-state-tree"
import { v4 as uuidv4 } from "uuid"

import Question, { FillInTheBlank, MultipleChoice, TrueFalse } from "./Question" // TrueFalse, // MultipleChoice, // FillInTheBlank,

const FinalAssessment = t
  .model("FinalAssessment", {
    id: t.optional(t.union(t.number, t.string), () => uuidv4()),
    questions: t.optional(
      t.array(t.union(Question, MultipleChoice, TrueFalse, FillInTheBlank)),
      []
    ),
  })
  .actions((self) => ({
    addQuestion(question) {
      self.questions.push(question)
    },
    removeQuestion(question) {
      destroy(question)
    },
  }))
  .views((self) => ({
    get isValid() {
      return (
        self.questions.map((q) => q.isValid).filter((q) => q === false)
          .length === 0
      )
    },
  }))

export default FinalAssessment
