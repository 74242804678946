import LoaderButton from "@bit/c_t.components.loader-button"
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  LinearProgress,
  MenuItem,
  Radio,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core"
import { listCourses } from "actions/courses"
import { ReactComponent as TopVideoBannerIcon } from "assets/Icons/top-video-banner-icon.svg"
import classnames from "clsx"
import App from "contexts/App"
import LessonContext from "contexts/Lesson"
import { Field, Formik, useFormikContext } from "formik"
import { RadioGroup, Select, TextField } from "formik-material-ui"
import { Autocomplete } from "formik-material-ui-lab"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import { useCallback, useContext, useEffect, useMemo } from "react"
import { useQuery } from "react-query"
import * as Yup from "yup"

import DialogTitle from "../components/DialogTitle"
import useStyles from "./styles"

const CourseChip = ({ course }) => {
  const classes = useStyles()

  return (
    <Chip
      size="small"
      className={classnames({
        [classes.publishedChip]: course.published,
        [classes.draftChip]: !course.published,
      })}
      label={course.published ? "Published" : "Draft"}
    />
  )
}

CourseChip.propTypes = {
  course: PropTypes.object,
}

const DialogFormContent = ({
  handleClose,
  handleRemove,
  showRemove,
  lockdown,
  mainVideoSecondsLeft,
  videoProgress,
  startSeconds,
}) => {
  const classes = useStyles()

  const {
    submitForm,
    isSubmitting,
    values,
    errors,
    setFieldValue,
  } = useFormikContext()

  const { lesson } = useContext(LessonContext)

  const courses = useQuery("courses", listCourses, {
    enabled: false,
  })

  useEffect(() => {
    if (values.type === "watchlist" && !courses.isFetched) {
      courses.refetch()
    }
  }, [values, courses])

  const selectableCourses = useMemo(() => {
    return (
      courses?.data
        ?.filter?.(
          (c) =>
            !c.archived &&
            ((!c.published && !c.has_been_published) || // draft courses
              c.published)
        )
        .filter?.((x, i, a) => {
          const found = a.find(
            (y) => y.container_id === x.container_id && y.id !== x.id
          )
          return !found || !found.published
        }) || []
    )
  }, [courses?.data])

  const handleSelectChange = (e, value) => {
    setFieldValue("course", value || "")
    setFieldValue("course_container_id", value?.container_id || "")
  }

  return (
    <>
      <DialogContent>
        <FormControl
          component="fieldset"
          fullWidth
          style={{ marginBottom: 16 }}
        >
          <Field
            component={RadioGroup}
            name="type"
            className={classes.radioButtons}
          >
            <FormControlLabel
              value="resource"
              control={<Radio disabled={isSubmitting} />}
              label="Resource Alert"
              disabled={isSubmitting || lockdown}
            />
            <FormControlLabel
              value="watchlist"
              control={<Radio disabled={isSubmitting} />}
              label="Add to watchlist"
              disabled={isSubmitting || lockdown}
            />
          </Field>
          <FormHelperText error>
            {errors.type ? errors.type : " "}
          </FormHelperText>
        </FormControl>
        {values.type === "resource" && (
          <>
            <InputLabel
              htmlFor="resource-select"
              className={classes.inputLabel}
            >
              Select Resource
            </InputLabel>
            <FormControl fullWidth style={{ marginBottom: 24 }}>
              <Field
                component={Select}
                name="lesson_resource_id"
                inputProps={{
                  id: "resource-select",
                }}
                variant="filled"
                disabled={lesson?.resources?.length === 0 || lockdown}
              >
                {lesson?.resources?.map((resource) => (
                  <MenuItem key={resource.id} value={resource.id}>
                    {resource.name}
                  </MenuItem>
                ))}
              </Field>
              <FormHelperText error>
                {errors.lesson_resource_id ? errors.lesson_resource_id : " "}
              </FormHelperText>
            </FormControl>
          </>
        )}
        {values.type === "watchlist" && courses.isLoading && (
          <LinearProgress color="secondary" />
        )}
        {values.type === "watchlist" && courses.isFetched && (
          <>
            <InputLabel htmlFor="course-select" className={classes.inputLabel}>
              Select watchlist Course
            </InputLabel>
            <FormControl fullWidth style={{ marginBottom: 24 }}>
              <Field
                name="course"
                component={Autocomplete}
                options={selectableCourses}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option === value}
                onChange={handleSelectChange}
                renderOption={(option) => (
                  <>
                    <CourseChip course={option} /> {option.name}
                  </>
                )}
                style={{ paddingTop: 0 }}
                renderInput={(params) => (
                  <MuiTextField {...params} variant="filled" />
                )}
                classes={{
                  inputRoot: classes.autocompleteRoot,
                }}
                disabled={lockdown}
              />
              <FormHelperText error>
                {errors.course_container_id ? errors.course_container_id : " "}
              </FormHelperText>
            </FormControl>
          </>
        )}
        <InputLabel htmlFor="display-time" className={classes.inputLabel}>
          Display Time
        </InputLabel>
        <Box display="flex" alignItems="center" maxWidth="50%">
          <FormControl fullWidth style={{ display: "flex" }}>
            <Field
              component={TextField}
              type="number"
              name="display_seconds"
              variant="filled"
              disabled={lockdown}
              InputProps={{
                inputProps: {
                  max: `${Math.floor(mainVideoSecondsLeft - startSeconds)}`,
                  min: 1,
                },
              }}
            />
          </FormControl>
          <Typography
            style={{
              flexShrink: 0,
              color: "#444444",
              opacity: 0.3,
              marginLeft: 8,
              marginBottom: 14,
            }}
          >
            {`In seconds (${Math.floor(
              mainVideoSecondsLeft - startSeconds
            )} seconds left)`}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <Box>
          {showRemove && (
            <Button
              className={classes.removeButton}
              onClick={handleRemove}
              type="button"
              size="small"
              disabled={lockdown}
            >
              Remove
            </Button>
          )}
        </Box>
        <Box>
          <Button
            onClick={handleClose}
            type="button"
            style={{ marginRight: "2vw" }}
          >
            Close
          </Button>
          <LoaderButton
            variant="contained"
            color="secondary"
            onClick={submitForm}
            type="button"
            disabled={lockdown}
          >
            Done
          </LoaderButton>
        </Box>
      </DialogActions>
    </>
  )
}

DialogFormContent.propTypes = {
  handleClose: PropTypes.func,
  handleRemove: PropTypes.func,
  showRemove: PropTypes.bool,
  lockdown: PropTypes.bool,
  mainVideoSecondsLeft: PropTypes.number,
  videoProgress: PropTypes.number,
  startSeconds: PropTypes.number,
}

const TopVideoBannerDialog = () => {
  const classes = useStyles()

  const { dialogs, closeDialog } = useContext(App)
  const thisDialog = dialogs?.["topVideoBanner"] || {}
  const { open = false, data = {} } = thisDialog
  const { lockdown, ...restData } = data
  let mainVideoSecondsLeft = thisDialog?.data?.lessonVideoDuration
  let videoProgress = thisDialog?.data?.videoProgress
  let startSeconds = thisDialog?.data?.startSeconds

  const { data: courses } = useQuery("courses", () => listCourses())

  const handleClose = () => {
    closeDialog("topVideoBanner")
  }

  const schema = useMemo(() => {
    return Yup.object({
      type: Yup.string("Please select a type").required("Please select a type"),
      lesson_resource_id: Yup.mixed()
        .test((value) => value === "" || Yup.number().isValidSync(value))
        .transform((value) => (value === "" ? value : +value))
        .when("type", {
          is: "resource",
          then: Yup.number(
            "Please select which resource should be shown"
          ).required("Please select which resource should be shown"),
        }),
      course_container_id: Yup.mixed()
        .test((value) => value === "" || Yup.number().isValidSync(value))
        .transform((value) => (value === "" ? value : +value))
        .when("type", {
          is: "watchlist",
          then: Yup.number(
            "Please select which course should be added to the users watchlist"
          ).required(
            "Please select which course should be added to the users watchlist"
          ),
        }),
      display_seconds: Yup.number().moreThan(
        0,
        "Display seconds should be greater than 0"
      ),
    })
  }, [])

  const handleSubmit = useCallback(({ type, ...values }, actions) => {
    const topBannerValues = {
      display_seconds: values.display_seconds,
    }
    if (values.course_container_id) {
      topBannerValues.course_container_id = values.course_container_id
    } else {
      topBannerValues.lesson_resource_id = values.lesson_resource_id
    }
    closeDialog("topVideoBanner", true, topBannerValues)
    actions.setSubmitting(false)
  }, [])

  const handleRemove = () => {
    closeDialog("topVideoBanner", true, undefined)
  }

  // const length = restData?.display_seconds
  //   ? restData?.display_seconds
  //   : mainVideoSecondsLeft - startSeconds < 5
  //   ? parseInt(mainVideoSecondsLeft - startSeconds)
  //     : 5

  const bannerSecs = () => {
    let result
    if (restData?.display_seconds) {
      result = restData?.display_seconds
    } else {
      if (mainVideoSecondsLeft - startSeconds < 5) {
        result = parseInt(mainVideoSecondsLeft - startSeconds)
      } else {
        result = 5
      }
    }
    return result
  }

  const initialValues = useMemo(() => {
    if (!isEmpty(restData)) {
      const courseObj = courses?.find(
        (c) => c.container_id === restData.course_container_id
      )
      return {
        ...restData,
        type: restData.course_container_id ? "watchlist" : "resource",
        course: courseObj,
        // if display_seconds exists, that ...
        // otherwise, default to 5 or remaining, whcihever is less
        display_seconds: bannerSecs(),
      }
    } else {
      return {
        type: "",
        lesson_resource_id: "",
        course_container_id: "",
        course: "",
        display_seconds: "",
      }
    }
  }, [restData])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ resetForm }) => (
        <Dialog open={open} onClose={handleClose} onExited={resetForm}>
          <DialogTitle
            title="Top Video Banner"
            subtitle="Banner in the top right corner"
            icon={
              <TopVideoBannerIcon
                style={{
                  position: "relative",
                  top: 3,
                  marginLeft: 10,
                  color: "#FF6A40",
                }}
              />
            }
            disableTypography
            timestamp={data.startSeconds}
          />
          <DialogFormContent
            showRemove={!isEmpty(restData)}
            handleClose={handleClose}
            handleRemove={handleRemove}
            lockdown={lockdown}
            mainVideoSecondsLeft={mainVideoSecondsLeft}
            videoProgress={videoProgress}
            startSeconds={startSeconds}
          />
        </Dialog>
      )}
    </Formik>
  )
}

export default TopVideoBannerDialog
