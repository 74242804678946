import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  wrapper: {},
  nameBox: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  nameText: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    wordBreak: "break-word",
  },
  versionBox: {
    width: "25%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  formControl: {
    minWidth: 120,
    [theme.breakpoints.down("md")]: {
      minWidth: 80,
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: 80,
    },
  },
  selectBorder: {
    color: "#17A5B1",
    "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #17A5B1",
    },
    "&.Mui-disabled": {
      "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(0, 0, 0, 0.26)",
      },
    },
  },
  icon: {
    color: "#17A5B1",
  },
  instructorsBox: {
    width: "30%",
    display: "flex",
    justifyContent: "center",
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      width: "28%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
  },
  addedBox: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  buttonBox: {
    width: "15%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
}))

export default styles
