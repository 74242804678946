import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  contentRoot: {
    padding: 0,
    minHeight: "300px",
    maxHeight: "500px",
  },
  viewBtn: {
    width: "25%",
  },
}))

export default styles
