import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
  },
  inner: {
    padding: "37px",
  },
  title: {
    fontWeight: "bold",
    marginBottom: theme.spacing(3),
  },
  publishedBanner: {
    position: "relative",
    width: "100%",
    height: 85,
    backgroundColor: "rgb(9 41 88 / 30%)",
    zIndex: 7,
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      padding: theme.spacing(2),
    },
  },
  bannerTitle: {
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  subtitle: {
    color: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
  requiredText: {
    color: theme.palette.warning.main,
  },
  inputLabel: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  inputBackgroundColor: {
    backgroundColor: "#F4F4F4",
  },
  inputDescription: {
    backgroundColor: "#F4F4F4",
    borderRadius: "4px",
    paddingTop: "10px !important",
    paddingBottom: "10px !important",
    paddingLeft: "10px",
    paddingRight: "10px",
    "&.Mui-focused": {
      backgroundColor: "rgba(0, 0, 0, 0.09)",
    },
    marginBottom: theme.spacing(1),
  },
  autocompleteRoot: {
    paddingTop: "0 !important",
    marginBottom: theme.spacing(1),
  },
  publishedOverlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 5,
    position: "absolute",
    backgroundColor: "rgba(255,255,255,0.5)",
  },
}))

export default styles
