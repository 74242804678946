import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  videoBox: {
    backgroundColor: "#000",
    width: "40%",
    flexShrink: 0,
    alignItems: "center",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  bottomSection: {
    display: "flex",
    flexGrow: 1,
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      overflow: "scroll",
    },
  },
}))

export default styles
