import LoaderButton from "@bit/c_t.components.loader-button"
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import { listPublishedCourses } from "actions/clients"
import AccessCodeCard from "components/Clients/AccesCodeCard"
import App from "contexts/App"
import ClientsContext from "contexts/Clients"
import dayjs from "dayjs"
import { Form, Formik } from "formik"
import React, { useContext, useEffect, useRef, useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import serialize from "store/serialize"
import { v4 as uuidv4 } from "uuid"
import * as Yup from "yup"

import useStyles from "./styles"

const EditClientDialog = () => {
  const formRef = useRef()
  const classes = useStyles()
  const theme = useTheme()
  const queryClient = useQueryClient()
  const { dialogs, closeDialog, openDialog, openSnackBar } = useContext(App)
  const {
    deleteClientMutation,
    setClientId,
    updateClientMutation,
    clients,
    setAccessCodeMutation,
  } = useContext(ClientsContext)
  const thisDialog = dialogs?.["editClientDialog"] || {}
  const { open = false } = thisDialog
  const clientId = thisDialog?.data?.client?.id
  const client = clients?.filter((x) => x.id === clientId)[0]
  //save loading state
  const [loading, setLoading] = useState(false)
  const { checkAccessCodeMutation } = useContext(ClientsContext)
  const [accessCodeArray, setAccessCodeArray] = useState([])
  const [saveClicked, setSaveClicked] = useState(false)
  //video loading to disable save button
  const [videoLoading, setVideoLoading] = useState(false)

  const smDown = useMediaQuery(theme.breakpoints.down("sm"))

  const { data: publishedCourses } = useQuery("publishedCourses", () =>
    listPublishedCourses()
  )

  useEffect(() => {
    if (client) {
      setClientId(client?.id)
      setAccessCodeArray(
        client?.access_codes?.map((x) => ({ ...x, key: uuidv4() }))
      )
    }
  }, [client])

  const schema = Yup.object().shape({
    name: Yup.string().required(`Name is required`),
  })

  const handleClose = () => {
    closeDialog("editClientDialog", false)
  }

  const handleRemove = () => {
    let data = {
      title: "Remove Client",
      subtitle: "",
      type: "client",
      delete: deleteClientMutation,
      id: client?.id,
      confirmWord: "delete",
    }
    openDialog("confirmationTypingDialog", data).then(() => handleClose())
  }

  const updateName = (values) => {
    return new Promise((resolve, reject) => {
      // if name form is dirty, proceed with updating name
      if (formRef?.current?.dirty) {
        const data = { name: values.name }
        updateClientMutation.mutate(data, {
          onSuccess: () => {
            resolve()
          },
          onError: () => {
            openSnackBar({
              message: "Error. Failed to update Client",
            })
            reject()
            setLoading(false)
          },
        })
      } else {
        // if name hasn't been updated, resolve and move forward
        resolve()
      }
    })
  }

  const submitAccessCodes = () => {
    let refs = accessCodeArray.map((x) => x.ref.current)
    let promises = refs.map((ref, i) => {
      return new Promise((resolve, reject) => {
        ref.submitForm().then(() => {
          if (ref.isValid === true) {
            resolve(ref)
          } else {
            setLoading(false)
            reject(ref)
          }
        })
      })
    })
    return Promise.all(promises)
  }

  const checkAccessCode = () => {
    let refs = accessCodeArray.map((x) => x.ref)
    //all ref items in the refs arr return as {current: null} unconditionally here
    let promises = refs.map((x) => {
      //promises each unconditionally returning as {x: {current: null}}
      return new Promise((resolve, reject) => {
        let code = { code: x.current.values.code }
        let currentCode = {
          id: x.current.values.id,
          client_id: client.id,
          expiration: dayjs(x.current.values.expiration).format(
            "YYYY-MM-DD 00:00:00"
          ),
          seats: Number(x.current.values.seats),
          course_containers: x.current.values.course_containers.map(
            (x) => x.container_id
          ),
          is_active: x.current.values.is_active === "active" ? true : false,
          code: x.current.values.code,
          description: x.current.values.description,
          image: x.current.values.image,
          amazon_sso: x.current.values.amazon_sso,
          facebook_sso: x.current.values.facebook_sso,
          github_sso: x.current.values.github_sso,
          video: x.current.values.video,
          //google_sso not used for now
          google_sso: x.current.values.google_sso,
        }
        // inside this if when edits to access codes
        if (x?.current?.initialValues?.code !== x?.current?.values?.code) {
          checkAccessCodeMutation
            .mutateAsync(code)
            .then(() => {
              setLoading(false)
              openSnackBar({ message: "Access Code in use" })
              reject(currentCode)
            })
            .catch((err) => {
              resolve(currentCode)
            })
        } else {
          resolve(currentCode)
        }
      })
    })
    return Promise.all(promises)
  }

  const createAccessCode = (res) => {
    return new Promise((resolve, reject) => {
      setAccessCodeMutation
        .mutateAsync(res)
        .then((res) => {
          serialize("clients", res.data).then((serializedData) => {
            return queryClient.setQueryData("clients", (oldData) => {
              let newArray = []
              newArray = oldData.map((x) => {
                if (x.id !== serializedData.id) {
                  return x
                } else {
                  return serializedData
                }
              })
              return [...newArray]
            })
          })
          resolve()
        })
        .catch((err) => {
          reject()
          openSnackBar({ message: "Error. Failed to update Client" })
          setLoading(false)
        })
    })
  }

  const updateClient = (values) => {
    setLoading(true)
    setSaveClicked(true)
    return updateName(values)
      .then(submitAccessCodes)
      .then((res) => {
        checkAccessCode().then((res) => {
          createAccessCode(res).then(() => {
            openSnackBar({ message: "Client Updated" })
            // handleClose()
            setLoading(false)
          })
        })
      })
      .catch((err) => {
        setLoading(false)
        openSnackBar({ message: "Error. Failed to update Client" })
      })
  }

  // appends blank access code card to bottom (onClick or via useEffect)
  const addCode = () => {
    setAccessCodeArray((oldCodes) => [...oldCodes, { key: uuidv4() }])
    if (accessCodeArray?.length > 0) {
      setTimeout(() => {
        const element = document.getElementById("editClientScroll")
        if (element) {
          element.scrollIntoView({ block: "end", behavior: "smooth" })
        }
      }, 100)
    }
  }

  //on page load, adds empty obj (blank accessCodeCard) if array length is 0
  useEffect(() => {
    if (open && accessCodeArray?.length === 0) {
      addCode()
    }
  }, [open])

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      classes={{ paperWidthXs: classes.dialogWrapper }}
      fullScreen={smDown}
    >
      <DialogTitle disableTypography>
        <Box display="flex" justifyContent="space-between">
          <Typography color="primary" variant="h3" style={{ fontWeight: 600 }}>
            {client?.name}
          </Typography>
          <Typography variant="subtitle1">{"ID: " + client?.id}</Typography>
        </Box>
      </DialogTitle>
      <DialogContent classes={{ root: classes.contentContainer }}>
        <Box
          display="flex"
          flexDirection="column"
          className={classes.contentSubContainer}
        >
          <Formik
            initialValues={{
              name: client?.name || "",
            }}
            onSubmit={updateClient}
            validationSchema={schema}
            innerRef={formRef}
          >
            {({
              handleChange,
              values,
              errors,
              touched,
              handleBlur,
              handleSubmit,
              setFieldValue,
              dirty,
            }) => (
              <Form id="name-form">
                <Typography color="secondary" className={classes.title}>
                  Client Name
                </Typography>
                <TextField
                  type="text"
                  name="name"
                  id="name"
                  value={values.name}
                  variant="filled"
                  size="small"
                  onChange={handleChange}
                  className={classes.textField}
                  onBlur={handleBlur}
                  helperText={
                    /* eslint-disable */
                    clients
                      ?.filter((x) => x?.id !== client?.id)
                      .find(
                        (x) =>
                          x?.name?.toLowerCase() === values?.name?.toLowerCase()
                      )
                      ? "This client name already exists."
                      : touched.name && errors.name
                      ? errors.name
                      : " "
                    /* eslint-enable */
                  }
                  FormHelperTextProps={{
                    className: classes.formHelperText,
                  }}
                />

                <Box>
                  <Divider color="secondary" width="100%" />
                </Box>

                <Box>
                  <IconButton
                    classes={{ root: classes.addNewCodeButton }}
                    onClick={() => addCode()}
                  >
                    <AddCircleOutlineIcon
                      className={classes.addIcon}
                      color="secondary"
                    />
                    <Typography color="secondary" variant="subtitle2">
                      ADD NEW CODE
                    </Typography>
                  </IconButton>
                </Box>
              </Form>
            )}
          </Formik>
          {accessCodeArray?.length > 0 &&
            accessCodeArray.map((accessCode, i) => {
              return (
                <Box
                  id={
                    accessCodeArray.length - 1 === i ? "editClientScroll" : null
                  }
                  key={accessCode.key}
                >
                  <AccessCodeCard
                    accessCode={accessCode}
                    accessCodeArray={accessCodeArray}
                    setAccessCodeArray={setAccessCodeArray}
                    courses={publishedCourses}
                    open={open}
                    saveClicked={saveClicked}
                    setVideoLoading={setVideoLoading}
                  />
                </Box>
              )
            })}
        </Box>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          m={2}
          className={classes.buttonsContainer}
        >
          <Box className={classes.topRowButton}>
            <Button className={classes.remove} onClick={() => handleRemove()}>
              Remove Client
            </Button>
          </Box>
          <Box className={classes.bottomRowButtons}>
            <Button className={classes.cancel} onClick={() => handleClose()}>
              Close
            </Button>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              form="name-form"
              disabled={videoLoading}
            >
              {loading ? (
                <CircularProgress size={20} style={{ color: "white" }} />
              ) : (
                "Save"
              )}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default EditClientDialog
