import PropTypes from "prop-types"
import { createContext, useCallback, useEffect, useRef, useState } from "react"

const VideoContext = createContext()
export default VideoContext

export const VideoContainer = ({ lesson, children }) => {
  const videoPlayerRef = useRef
  // ref to hold the current running animation frame
  const requestRef = useRef()
  // play / pause state
  const [playing, setPlaying] = useState(false)
  const togglePlayPause = useCallback(() => {
    setPlaying(!playing)
  }, [playing])

  const [videoProgress, setVideoProgress] = useState(0)

  const getPlayedPercentage = useCallback(() => {
    return (videoPlayerRef.current.getCurrentTime() / lesson.duration) * 100
  }, [videoPlayerRef])

  // function to run on animation frame loop
  const animate = useCallback(
    (time) => {
      if (videoPlayerRef.current) {
        // const playedPercentage = getPlayedPercentage()
        setVideoProgress(videoPlayerRef.current.getCurrentTime())
        requestRef.current = requestAnimationFrame(animate)
      } else {
        // cleans up request animation frame if the videoPlayerRef became unavailable
        cancelAnimationFrame(requestRef.current)
      }
    },
    [videoPlayerRef]
  )

  const handleStartProgress = useCallback(() => {
    requestRef.current = requestAnimationFrame(animate)
  }, [animate])

  const handleEndProgress = useCallback(() => {
    setTimeout(() => {
      cancelAnimationFrame(requestRef.current)
    })
  }, [])

  const handleDuration = (duration) => {
    lesson.duration = duration
    // props?.onDuration?.(duration)
  }

  const setVideoPlayhead = (seconds) => {
    // const secondsAtVideoLength = (percentage / 100) * lesson.duration
    setVideoProgress(seconds)
    videoPlayerRef.current.seekTo(seconds)
  }

  const setVideoProgressFromInput = (offset, totalWidth) => {
    const percentage = (offset / totalWidth) * 100
    setVideoPlayhead(percentage)
    setVideoProgress(percentage)
  }

  const setVideoPlayheadSeconds = (time) => {
    // seek to specified time on video
    videoPlayerRef.current.seekTo(time)

    // calculate played percentage and set current progress
    const playedPercentage = getPlayedPercentage()
    setVideoProgress(playedPercentage)
  }

  const seek = {
    subtract: (value) => {
      const newPlayheadPosition =
        videoPlayerRef.current.getCurrentTime() - value
      if (newPlayheadPosition <= 0) {
        // if this would result in less than 0 set to the beginning of the video
        setVideoPlayheadSeconds(0)
      } else {
        // set to the new playhead time
        setVideoPlayheadSeconds(newPlayheadPosition)
      }
    },
    add: (value) => {
      const newPlayheadPosition =
        videoPlayerRef.current.getCurrentTime() + value
      if (newPlayheadPosition < lesson.duration) {
        // if this would result in more seconds than the total video length, set to the end of the video
        setVideoPlayheadSeconds(newPlayheadPosition)
      } else {
        // set to the new playhead time
        setVideoPlayheadSeconds(lesson.duration)
      }
    },
  }

  useEffect(() => {
    return () => {
      cancelAnimationFrame(requestRef.current)
      setPlaying(false)
    }
  }, [])

  return (
    <VideoContext.Provider
      value={{
        playing,
        togglePlayPause,
        videoProgress,
        setVideoProgressFromInput,
        setVideoPlayhead,
        seek,
        duration: lesson.video.duration,
      }}
    >
      {children({
        ReactPlayerProps: {
          onPlay: handleStartProgress,
          onPause: handleEndProgress,
          onEnded: handleEndProgress,
          onDuration: handleDuration,
          ref: (node) => {
            videoPlayerRef.current = node
          },
        },
      })}
    </VideoContext.Provider>
  )
}

VideoContainer.propTypes = {
  children: PropTypes.any,
  lesson: PropTypes.object,
}
