import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core"
import animateScrollTo from "animated-scroll-to"
import { ReactComponent as CheckIcon } from "assets/Icons/check-icon.svg"
import Content from "components/Questions/Content"
import KnowledgeCheck from "components/Questions/models/KnowledgeCheck"
import {
  FillInTheBlank,
  MultipleChoice,
  TrueFalse,
} from "components/Questions/models/Question"
import Sidebar from "components/Questions/Sidebar"
import App from "contexts/App"
import usePrevious from "hooks/usePrevious"
import { isEmpty } from "lodash"
import { observer } from "mobx-react"
import PropTypes from "prop-types"
import { useContext, useEffect, useRef, useState } from "react"

import DialogTitle from "../components/DialogTitle"
import useStyles from "./styles"

const questionTypes = [
  {
    name: "Multiple Choice",
    model: MultipleChoice,
    type: "multiple_choice",
  },
  {
    name: "True / False",
    model: TrueFalse,
    type: "true_false",
  },
  {
    name: "Fill in the Blank",
    model: FillInTheBlank,
    type: "fill_blank",
  },
]

const KnowledgeCheckEditorDialog = () => {
  const scrollAreaRef = useRef()
  const [store, setStore] = useState({})

  const classes = useStyles()

  const { dialogs, closeDialog } = useContext(App)
  const thisDialog = dialogs?.["knowledgeCheckEditor"] || {}
  const { open = false, data = {} } = thisDialog
  const { learningCueId, ...knowledgeCheckData } = data
  const { lockdown, startSeconds, ...restData } = knowledgeCheckData

  const setLocalState = () => {
    let newKnowledgeCheck
    console.log("restData", restData)
    if (!isEmpty(restData)) {
      newKnowledgeCheck = KnowledgeCheck.create({
        ...restData,
        questions: restData.questions.map((q) =>
          questionTypes.find((t) => t.type === q.type).model.create(q)
        ),
      })
    } else {
      newKnowledgeCheck = KnowledgeCheck.create()
    }
    setStore(newKnowledgeCheck)
  }

  const [showErrors, setShowErrors] = useState(false)

  const handleClose = () => {
    closeDialog("knowledgeCheckEditor")
  }

  const handleSave = () => {
    if (!store?.isValid) {
      setShowErrors(true)
    } else {
      setShowErrors(false)
      closeDialog("knowledgeCheckEditor", true, store.toJSON())
    }
  }

  const handleRemove = () => {
    closeDialog("knowledgeCheckEditor", true, undefined)
  }

  const previousQuestionsLength = usePrevious(store?.questions?.length || 0)

  useEffect(() => {
    if (
      store?.questions?.length > previousQuestionsLength &&
      previousQuestionsLength !== 0
    ) {
      const lastQuestionId = store.questions[store.questions.length - 1].id
      animateScrollTo(
        document.querySelector(`#question-id-${lastQuestionId}`),
        {
          elementToScroll: scrollAreaRef.current,
        }
      )
    }
  }, [previousQuestionsLength, store?.questions?.length])

  return (
    <Dialog
      onEntering={setLocalState}
      open={open}
      onClose={handleClose}
      classes={{
        paperWidthSm: classes.dialogContainer,
      }}
    >
      <DialogTitle
        title="Add Knowledge Check"
        icon={
          <CheckIcon
            style={{
              position: "relative",
              top: 3,
              marginLeft: 10,
              color: "#29B117",
            }}
          />
        }
        disableTypography
        timestamp={startSeconds}
      />
      <DialogContent className={classes.dialogContent}>
        <Sidebar
          lockdown={lockdown}
          questionTypes={questionTypes}
          store={store}
        />
        <Box
          paddingLeft={2.5}
          paddingRight={2}
          flexGrow="1"
          overflow="auto"
          ref={scrollAreaRef}
        >
          {store?.questions?.length === 0 && (
            <Box
              display="flex"
              justifyContent="center"
              height="100%"
              alignItems="center"
            >
              <Typography>
                Click a question type on the left to add a question to this{" "}
                <Typography
                  component="span"
                  style={{ fontWeight: 700 }}
                  color="secondary"
                >
                  Knowledge Check
                </Typography>
              </Typography>
            </Box>
          )}
          {store?.questions?.map?.((question) => (
            <Content
              key={question}
              question={question}
              showingErrors={showErrors}
              lockdown={lockdown}
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <Box>
          {!isEmpty(restData) && (
            <Button
              className={classes.removeButton}
              onClick={handleRemove}
              type="button"
              size="small"
              disabled={lockdown}
            >
              Remove
            </Button>
          )}
        </Box>
        <Box>
          <Button
            onClick={handleClose}
            type="button"
            style={{ marginRight: "2vw" }}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSave}
            disabled={lockdown}
          >
            Done
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default observer(KnowledgeCheckEditorDialog)
