import LoaderButton from "@bit/c_t.components.loader-button"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  Typography,
} from "@material-ui/core"
import { ReactComponent as readyCheckIcon } from "assets/Icons/bottom-video-banner-icon.svg"
import { ReactComponent as ResumeIcon } from "assets/Icons/chess-icon.svg"
import CounterHeader from "components/Common/CounterHeader"
import App from "contexts/App"
import { Field, Formik } from "formik"
import { TextField } from "formik-material-ui"
import { isEmpty } from "lodash"
import { useCallback, useContext, useMemo } from "react"
import * as Yup from "yup"

import DialogTitle from "../components/DialogTitle"
import useStyles from "./styles"

const schema = Yup.object({
  question: Yup.string("Question is required")
    .required("Question is required")
    .max(1000, "Question must be less than 1000 characters"),
  yes_explanation: Yup.string().max(
    1000,
    "Yes explanation must be less than 1000 characters"
  ),
  no_explanation: Yup.string().max(
    1000,
    "No explanation must be less than 1000 characters"
  ),
})

const ReadyCheckDialog = () => {
  const classes = useStyles()

  const { dialogs, closeDialog } = useContext(App)
  const thisDialog = dialogs?.["readyCheck"] || {}
  const { open = false, data = {} } = thisDialog
  const { lockdown, startSeconds, ...restData } = data

  const handleClose = () => {
    closeDialog("readyCheck")
  }

  const handleSubmit = useCallback((values, actions) => {
    closeDialog("readyCheck", true, values)
    actions.setSubmitting(false)
  }, [])

  const initialValues = useMemo(() => {
    // new banners getting restData, but don't have id
    if (!isEmpty(restData) && !restData.id) {
      return {
        ...restData,
      }
      // id only pre-existing bottom banner info
    } else if (!isEmpty(restData) && restData.id) {
      return restData
    } else {
      return {
        question: "",
        yes_explanation: "",
        no_explanation: "",
      }
    }
  }, [restData])

  const handleRemove = () => {
    closeDialog("readyCheck", true, undefined)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ submitForm, resetForm, values }) => (
        <Dialog open={open} onClose={handleClose} onExited={resetForm}>
          <DialogTitle
            title="Ready Check"
            icon={
              <ResumeIcon
                style={{
                  width: 16,
                  height: 16,
                  position: "relative",
                  top: 1,
                  marginLeft: 10,
                  color: "#B821E2",
                }}
              />
            }
            disableTypography
            timestamp={startSeconds}
          />
          <DialogContent>
            <CounterHeader
              title={" " + "Question"}
              value={values.question}
              max={1000}
            />
            <Field
              component={TextField}
              variant="filled"
              name="question"
              fullWidth
              style={{ marginBottom: 24 }}
              disabled={lockdown}
            />
            <CounterHeader
              title={" " + "Yes explanation (optional)"}
              value={values.yes_explanation}
              max={1000}
            />
            <Field
              component={TextField}
              variant="filled"
              name="yes_explanation"
              fullWidth
              style={{ marginBottom: 24 }}
              disabled={lockdown}
            />
            <CounterHeader
              title={" " + "No explanation (optional)"}
              value={values.no_explanation}
              max={1000}
            />
            <Field
              component={TextField}
              variant="filled"
              name="no_explanation"
              fullWidth
              style={{ marginBottom: 24 }}
              disabled={lockdown}
            />
          </DialogContent>
          <DialogActions style={{ justifyContent: "space-between" }}>
            <Box>
              {!isEmpty(restData) && (
                <Button
                  className={classes.removeButton}
                  onClick={handleRemove}
                  type="button"
                  size="small"
                  disabled={lockdown}
                >
                  Remove
                </Button>
              )}
            </Box>
            <Box>
              <Button
                onClick={handleClose}
                type="button"
                style={{ marginRight: "2vw" }}
              >
                Close
              </Button>
              <LoaderButton
                variant="contained"
                color="secondary"
                onClick={submitForm}
                type="button"
                disabled={lockdown}
              >
                Done
              </LoaderButton>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  )
}

export default ReadyCheckDialog
