import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  container: {
    maxHeight: `calc(100% - ${theme.spacing(1)}px)`,
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
}))

export default styles
