import api from "store/api"
import serialize from "store/serialize"

export const listClients = (data) => {
  return api
    .get("/clients", data)
    .then((res) => serialize("client", res.data.data))
}

export const createClient = (data) => {
  return api.post("/clients", data).then((res) => res.data)
}

export const checkAccessCode = (data) => {
  return api.post("/check_access_code", data).then((res) => res.data)
}

export const setAccessCode = (data, id) => {
  return api.post(`/access_codes/${id}`, data).then((res) => res.data)
}

export const updateClient = (data, id) => {
  return api.put(`/clients/${id}`, data).then((res) => res.data)
}

export const removeClient = (id) => {
  return api.delete(`/clients/${id}`).then((res) => res.data)
}

export const listPublishedCourses = () => {
  return api
    .get("/published_courses")
    .then((res) => serialize("course", res.data.data))
}
