import { Box, Typography } from "@material-ui/core"

import useStyles from "./styles"

const EmailSent = () => {
  const classes = useStyles()

  return (
    <Box width="80%" textAlign="center">
      <Typography variant="h3" className={classes.title}>
        Your request has been sent. Please check your email for further
        instructions.
      </Typography>
    </Box>
  )
}

export default EmailSent
