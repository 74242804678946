import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    padding: "37px",
    minHeight: "100%",
  },
  title: {
    fontWeight: "bold",
    marginBottom: theme.spacing(3),
  },
  requiredText: {
    color: theme.palette.warning.main,
  },
  inputLabel: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  inputBackgroundColor: {
    backgroundColor: "#F4F4F4",
  },
  inputDescription: {
    backgroundColor: "#F4F4F4",
    borderRadius: "4px",
    paddingTop: "10px !important",
    paddingBottom: "10px !important",
    paddingLeft: "10px",
    paddingRight: "10px",
    "&.Mui-focused": {
      backgroundColor: "rgba(0, 0, 0, 0.09)",
    },
  },
  autocompleteRoot: {
    paddingTop: "0 !important",
  },
  underline: {
    "&:before": {
      borderBottomStyle: "none",
    },
  },
}))

export default styles
