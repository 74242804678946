import { Avatar, Box } from "@material-ui/core"
import { uploadThumbnail } from "actions/upload"
import EditIcon from "assets/Icons/edit-icon.svg"
import PropTypes from "prop-types"
import { useCallback } from "react"

import FileUpload from "../FileUpload"
import useStyles from "./styles"

const AvatarUpload = ({
  cloudinaryUploadPreset,
  setUploadRes,
  uploadRes,
  value,
  imageLoading,
  setImageLoading,
}) => {
  const classes = useStyles()

  const onDrop = useCallback(
    (acceptedFiles) => {
      setImageLoading(true)
      uploadThumbnail(acceptedFiles[0], cloudinaryUploadPreset)
        .then((res) => setUploadRes(res))
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          setImageLoading(false)
        })
    },
    [cloudinaryUploadPreset]
  )

  return (
    <FileUpload
      onDrop={onDrop}
      showLoading={imageLoading}
      childHeight="145px"
      height="145px"
      type="avatar"
    >
      {uploadRes ? (
        <Box className={classes.avatarBox}>
          <Box className={classes.editBox}>
            <img src={EditIcon} alt="Edit thumbnail" style={{ width: 24 }} />
          </Box>
          <Avatar
            src={uploadRes.data.url}
            style={{ height: "100%", width: "100%" }}
          />
        </Box>
      ) : (
        value?.url && (
          <Box className={classes.avatarBox}>
            <Box className={classes.editBox}>
              <img src={EditIcon} alt="Edit thumbnail" style={{ width: 24 }} />
            </Box>
            <Avatar src={value.url} style={{ height: "100%", width: "100%" }} />
          </Box>
        )
      )}
    </FileUpload>
  )
}

AvatarUpload.propTypes = {
  cloudinaryUploadPreset: PropTypes.string,
  onUpload: PropTypes.func,
  value: PropTypes.object,
  uploadRes: PropTypes.object,
  setUploadRes: PropTypes.func,
  imageLoading: PropTypes.bool,
  setImageLoading: PropTypes.func,
}

export default AvatarUpload
