import Record from "./base"

export class LearningCue extends Record {
  constructor(...args) {
    super(...args)
    const [model, props] = args
    if (this?.knowledgeCheck) {
      this.knowledgeCheck.questions = this.knowledgeCheck.questions.map(
        (q) => ({
          ...q,
          explanation: q.explanation === null ? "" : q.explanation,
        })
      )
    }
  }
}
