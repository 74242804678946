import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  responseItem: {
    display: "flex",
  },
  radioGroup: {
    flexDirection: "row",
    marginBottom: theme.spacing(2),
  },
  radio: {
    padding: 0,
    paddingLeft: 3,
    paddingRight: 6,
    "& .MuiSvgIcon-root": {
      fontSize: 12,
    },
    "&:hover": {
      backgroundColor: ["transparent", "!important"],
    },
  },
  radioChecked: {
    color: [theme.palette.success.main, "!important"],
  },
}))

export default styles
