import LoaderButton from "@bit/c_t.components.loader-button"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core"
import {
  requestCoursePDFMediaUpload,
  requestLessonPDFMediaUpload,
  uploadPDF,
} from "actions/upload"
import PdfUpload from "components/Common/PdfUpload"
import AppContext from "contexts/App"
import { orderBy } from "lodash"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { useHistory } from "react-router"

import useStyles from "./styles"

const EditResourceDialog = () => {
  const classes = useStyles()

  const queryClient = useQueryClient()

  const { dialogs, closeDialog, openSnackBar } = useContext(AppContext)
  const thisDialog = dialogs?.["editResourceDialog"] || {}
  const { open = false, data } = thisDialog
  const history = useHistory()

  const initialName = thisDialog?.data?.name
  const initialPDF = thisDialog?.data?.file
  const initialLink = thisDialog?.data?.link
  const initialAccess = thisDialog?.data?.access
  const initialId = thisDialog?.data?.id
  const initialOrder = thisDialog?.data?.order
  let resources = thisDialog?.data?.data
  const setFunction = thisDialog?.data?.setFn
  const [name, setName] = useState("")
  const [link, setLink] = useState("")
  const [order, setOrder] = useState("")
  const [access, setAccess] = useState(false)
  const [isWorking, setIsWorking] = useState(false)
  const [resourceType, setResourceType] = useState("link")
  const [file, setFile] = useState(null)
  const [temporaryFiles, setTemporaryFiles] = useState("")
  const [loading, setLoading] = useState(false)
  const [tryToSave, setTryToSave] = useState(false)

  const requestLessonPDFMediaUploadMutation = useMutation(
    "requestLessonPDFMediaUpload",
    requestLessonPDFMediaUpload
  )

  const requestCoursePDFMediaUploadMutation = useMutation(
    "requestCoursePDFMediaUpload",
    requestCoursePDFMediaUpload
  )

  const uploadMedia = async (pdfFile) => {
    setLoading(true)
    const timestamp = +new Date()
    if (history?.location?.pathname.includes("lessons")) {
      requestLessonPDFMediaUploadMutation
        .mutateAsync({
          key: `lessonResources/${timestamp}-${pdfFile.name}`,
          type: pdfFile.type,
        })
        .then((data) => {
          uploadPDF({
            ...data.data,
            file: pdfFile,
          })
            .then((uploadResponse) => {
              setTemporaryFiles(`lessonResources/${timestamp}-${pdfFile.name}`)
              setLoading(false)
            })
            .catch((uploadErr) => {
              console.error(uploadErr)
              openSnackBar({
                message: "There was an error uploading this pdf",
              })
              setLoading(false)
            })
        })
        .catch((err) => {
          console.error(err)
          openSnackBar({
            message: "There was an error uploading this pdf file",
          })
          setLoading(false)
        })
    } else {
      requestCoursePDFMediaUploadMutation
        .mutateAsync({
          key: `courseResources/${timestamp}-${pdfFile.name}`,
          type: pdfFile.type,
        })
        .then((data) => {
          uploadPDF({
            ...data.data,
            file: pdfFile,
          })
            .then((uploadResponse) => {
              setTemporaryFiles(`courseResources/${timestamp}-${pdfFile.name}`)
              setLoading(false)
            })
            .catch((uploadErr) => {
              console.error(uploadErr)
              openSnackBar({
                message: "There was an error uploading this pdf",
              })
              setLoading(false)
            })
        })
        .catch((err) => {
          console.error(err)
          openSnackBar({
            message: "There was an error uploading this pdf file",
          })
          setLoading(false)
        })
    }
  }

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0])
    uploadMedia(acceptedFiles[0])
  }, [])

  const handleClose = () => {
    closeDialog("editResourceDialog", false)
    setName("")
    setLink("")
    setAccess(false)
    setResourceType("link")
    setFile(null)
    setTemporaryFiles("")
    setTryToSave(false)
  }

  const handleSwitch = (event) => {
    setAccess(!access)
  }

  const handleSubmit = () => {
    setTryToSave(true)
    const disabled = resourceType === "link" ? disabledLink : disabledFile
    if (!disabled) {
      let newResource
      if (resourceType === "link") {
        newResource = {
          name: name,
          url: link,
          lock_until_over: access,
          order: resources?.length,
        }
      } else {
        newResource = {
          name: name,
          file: temporaryFiles || file,
          lock_until_over: access,
          order: resources?.length,
        }
      }
      const data = [...resources, newResource]
      setIsWorking(true)
      setFunction.mutate(
        data.map((x, i) => ({
          // resets order prop based on index
          ...x,
          order: i,
        })),
        {
          onSuccess: (res) => {
            // if statement specifies to follow lesson or course mutation
            if (res?.resources[0]?.lesson_id) {
              const lessonId = res.resources[0].lesson_id
              let orderedRes = orderBy(res.resources, [(x) => x.order])
              queryClient.setQueryData(["lesson", lessonId], (oldData) => {
                return { ...oldData, resources: orderedRes }
              })
            } else {
              const courseId = res.resources[0].course_id
              // order the response to sort on order
              let orderedRes = orderBy(res.resources, [(x) => x.order])
              queryClient.setQueryData(["course", courseId], (oldData) => {
                return { ...oldData, resources: orderedRes }
              })
            }
            openSnackBar({
              message: "Resource Added",
            })
            handleClose()
          },
          onError: () =>
            openSnackBar({
              message: "Error adding resource",
            }),
          onSettled: () => {
            setIsWorking(false)
          },
        }
      )
    }
  }

  const handleEdit = () => {
    setTryToSave(true)
    const disabled = resourceType === "link" ? disabledLink : disabledFile
    if (!disabled) {
      resources = resources.filter((x) => x.id !== initialId)
      let newResource
      if (resourceType === "link") {
        newResource = {
          id: initialId,
          name: name,
          url: link,
          lock_until_over: access,
          order: order,
          file: null,
        }
      } else {
        newResource = {
          id: initialId,
          name: name,
          file: temporaryFiles || file,
          lock_until_over: access,
          order: order,
          url: null,
        }
      }
      const data = [...resources, newResource]
      setIsWorking(true)
      setFunction.mutate(
        // resets order prop based on index
        data.map((x, i) => ({
          ...x,
          order: i,
        })),
        {
          onSuccess: (res) => {
            if (res?.resources[0]?.lesson_id) {
              const lessonId = res.resources[0].lesson_id
              // let orderedRes = orderBy(res.resources, [(x) => x.order])
              queryClient.setQueryData(["lesson", lessonId], (oldData) => {
                let newArray = oldData.resources.map((x) => {
                  if (x.id === initialId) {
                    return {
                      ...newResource,
                      id: x.id,
                      lesson_id: x.lesson_id,
                    }
                  } else {
                    return x
                  }
                })
                return { ...oldData, resources: newArray }
              })
            } else {
              const courseId = res.resources[0].course_id
              // let orderedRes = orderBy(res.resources, [(x) => x.order])
              queryClient.setQueryData(["course", courseId], (oldData) => {
                let newArray = oldData.resources.map((x) => {
                  if (x.id === initialId) {
                    return {
                      ...newResource,
                      id: x.id,
                      course_id: x.course_id,
                    }
                  } else {
                    return x
                  }
                })
                return { ...oldData, resources: newArray }
              })
            }
            openSnackBar({
              message: "Resource Updated",
            })
            handleClose()
          },
          onError: (err) => {
            console.log(err)
            openSnackBar({
              message: "Error updating resource",
            })
          },
          onSettled: () => {
            setIsWorking(false)
          },
        }
      )
    }
  }

  useEffect(() => {
    if (initialName) {
      setName(initialName)
      setLink(initialLink)
      setAccess(initialAccess)
      setOrder(initialOrder)
      if (initialName) {
        setResourceType("link")
      }
      if (initialPDF) {
        setResourceType("pdf")
        setFile(initialPDF)
      }
    }
  }, [data])

  var expression =
    "(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})"
  var regex = new RegExp(expression)

  const linkError =
    (link?.length === 0 && resourceType === "link" && tryToSave) ||
    (resourceType === "link" && tryToSave && link?.match(regex) === null)

  const disabledFile = loading || name === "" || !file
  const disabledLink =
    loading || link?.length === 0 || link?.match(regex) === null || name === ""

  return (
    <Dialog open={open} maxWidth="xs">
      <DialogTitle classes={{ root: classes.dialogTitle }}>
        {initialName ? "Edit Resource" : "New Resource"}
      </DialogTitle>
      <DialogContent>
        <Box>
          <Typography color="secondary" className={classes.title}>
            Resource Name [Display Name]
          </Typography>
          <TextField
            value={name}
            type="text"
            InputProps={{
              classes: {
                root: classes.inputBackgroundColor,
              },
            }}
            variant="filled"
            size="small"
            fullWidth
            onChange={(e) => {
              setName(e.target.value)
            }}
          />
          {name?.length === 0 && tryToSave ? (
            <Typography className={classes.requiredText}>
              Name is required
            </Typography>
          ) : null}
        </Box>
        <Box marginTop={3}>
          <RadioGroup
            value={resourceType}
            onChange={(e) => setResourceType(e.target.value)}
          >
            <Box display="flex">
              <Box>
                <FormControlLabel
                  label={
                    <Typography style={{ fontWeight: 600 }} color="primary">
                      Link
                    </Typography>
                  }
                  value="link"
                  control={<Radio />}
                />
              </Box>
              <Box>
                <FormControlLabel
                  label={
                    <Typography style={{ fontWeight: 600 }} color="primary">
                      PDF
                    </Typography>
                  }
                  value="pdf"
                  control={<Radio />}
                />
              </Box>
            </Box>
          </RadioGroup>
        </Box>
        {resourceType === "link" && (
          <Box marginTop={3}>
            <Typography color="secondary" className={classes.title}>
              Link
            </Typography>
            <TextField
              value={link}
              type="text"
              InputProps={{
                classes: {
                  root: classes.inputBackgroundColor,
                },
              }}
              variant="filled"
              size="small"
              fullWidth
              onChange={(e) => setLink(e.target.value)}
            />
            {linkError ? (
              <Typography className={classes.requiredText}>
                Link is required and needs to be a valid url
              </Typography>
            ) : null}
          </Box>
        )}
        {resourceType === "pdf" && (
          <Box marginTop={3}>
            <Typography color="secondary" className={classes.title}>
              PDF
            </Typography>
            <Box width="50%">
              <PdfUpload onDrop={onDrop} value={file} loading={loading} />
            </Box>
          </Box>
        )}
        {!file && resourceType === "pdf" && tryToSave ? (
          <Typography className={classes.requiredText}>
            Upload a PDF is required
          </Typography>
        ) : null}

        <Box display="flex" alignItems="center" marginTop={3} marginBottom={10}>
          <Switch size="small" checked={access} onChange={handleSwitch} />
          <Typography
            variant="subtitle2"
            color="inherit"
            style={{ color: access ? "#17A5B1" : "#808080" }}
          >
            Lock until course has ended
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box m={2}>
          <Button onClick={handleClose} style={{ marginRight: "8px" }}>
            Cancel
          </Button>
          <LoaderButton
            type="submit"
            classoverrides={{
              wrapper: classes.button,
              button: {
                root: classes.buttonRoot,
              },
            }}
            variant="contained"
            color="secondary"
            working={isWorking}
            onClick={initialId ? handleEdit : handleSubmit}
            // disabled={loading}
          >
            Save
          </LoaderButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default EditResourceDialog
