import { destroy, getParent, types as t } from "mobx-state-tree"
import { v4 as uuidv4 } from "uuid"

import SetModel, { SentimentSetModel } from "./Set"

const QuestionBase = t
  .model({
    id: t.optional(t.union(t.number, t.string), () => uuidv4()),
    name: "",
    type: t.string,
  })
  .actions((self) => ({
    remove() {
      getParent(self, 2).removeQuestion(self)
    },
    addSet(set) {
      self.sets.push(set)
    },
    updateName(value) {
      self.name = value
    },
  }))
  .views((self) => ({
    get errors() {
      const errorsObj = {}
      if (self.name === "") {
        errorsObj.name = "Question name is required"
      }
      return errorsObj
    },
  }))

const Question = t
  .compose(
    "Question",
    QuestionBase,
    t.model({
      explanation: t.optional(t.string, ""),
      attempts: 1,
    })
  )
  .actions((self) => ({
    updateExplanation(value) {
      self.explanation = value
    },
    updateAttempts(value) {
      self.attempts = value
    },
  }))

export const TrueFalse = Question.named("TrueFalse")
  .props({
    type: "true_false",
    sets: t.optional(t.array(SetModel), () => [
      SetModel.create({
        options: [
          {
            name: "True",
          },
          {
            name: "False",
          },
        ],
      }),
    ]),
  })
  .views((self) => ({
    get isValid() {
      return (
        self.name !== "" &&
        self.sets.map((s) => s.isValid).filter((s) => s === false).length === 0
      )
    },
  }))

export const MultipleChoice = Question.named("MultipleChoice")
  .props({
    type: "multiple_choice",
    sets: t.optional(t.array(SetModel), () => [SetModel.create()]),
  })
  .views((self) => ({
    get isValid() {
      return (
        self.name !== "" &&
        self.sets.map((s) => s.isValid).filter((s) => s === false).length === 0
      )
    },
  }))

export const Sentiment = t
  .compose(
    "Sentiment",
    QuestionBase,
    t.model({
      type: "multiple_choice",
      sets: t.optional(t.array(SentimentSetModel), () => [
        SentimentSetModel.create(),
      ]),
    })
  )
  .views((self) => ({
    get isValid() {
      return (
        self.name !== "" &&
        self.sets.map((s) => s.isValid).filter((s) => s === false).length === 0
      )
    },
  }))

const randomSetId = uuidv4()

export const FillInTheBlank = Question.named("FillInTheBlank")
  .props({
    type: "fill_blank",
    name: `Fill {:${randomSetId}:} the blank`,
    sets: t.optional(t.array(SetModel), () => [
      SetModel.create({ id: randomSetId }),
    ]),
  })
  .actions((self) => ({
    removeSet(set) {
      destroy(set)
    },
  }))
  .views((self) => ({
    get isValid() {
      return (
        self.name !== "" &&
        self.sets.length > 0 &&
        self.sets.map((s) => s.isValid).filter((s) => s === false).length === 0
      )
    },
    get errors() {
      const errorsObj = {}
      if (self.name === "") {
        errorsObj.name = "Question name is required"
      }
      if (self.sets.length === 0) {
        errorsObj.sets = "At least 1 response set is required"
      }
      return errorsObj
    },
  }))

export default Question
