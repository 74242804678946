import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  container: {
    padding: "30px",
    [theme.breakpoints.down("md")]: {
      padding: "10px",
    },
  },
}))
export default styles
