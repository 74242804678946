import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  timelineCuePoints: {
    position: "relative",
  },
  timelineCuePointsSlider: {
    position: "absolute",
    pointerEvents: "none",
    backgroundColor: "transparent",
  },
}))

export default styles
