import { Box, IconButton, Paper, Typography } from "@material-ui/core"
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle"
import dragGray from "assets/Icons/noun_drag_3124730_gray.svg"
import HoverText from "components/Common/HoverText"
import InstructorGroup from "components/Common/InstructorGroup"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"

import useStyles from "./styles"

const AddedLessonCardMobile = ({
  lesson,
  removeLesson,
  provided,
  disabledCourse,
}) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Paper
      elevation={0}
      className={classes.paperRow}
      ref={provided.innerRef}
      {...provided.draggableProps}
    >
      <Box
        m={2}
        onClick={() => history.push(`/lessons/${lesson.lesson_id}`)}
        style={disabledCourse ? { filter: "opacity(0.5)", zIndex: "10" } : null}
      >
        <HoverText
          data={lesson?.data?.name}
          size={30}
          variant="h3"
          className={classes.nameText}
        />
      </Box>
      <Box marginBottom={1}>
        {lesson?.data?.instructors?.length > 0 ? (
          <InstructorGroup instructorsArray={lesson?.data?.instructors} />
        ) : null}
      </Box>
      <Typography>{"Version " + lesson?.data?.version}</Typography>
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <IconButton onClick={() => removeLesson(lesson?.lesson_id)}>
          <RemoveCircleIcon />
        </IconButton>
        <Box {...provided.dragHandleProps}>
          <img src={dragGray} alt="" width="20px" />
        </Box>
      </Box>
    </Paper>
  )
}

AddedLessonCardMobile.propTypes = {
  lesson: PropTypes.object,
  removeLesson: PropTypes.func,
  provided: PropTypes.object,
  disabledCourse: PropTypes.number,
}

export default AddedLessonCardMobile
