import { publishLesson } from "actions/lessons"
import { listPublishedLessons } from "actions/lessons"
import { createTags } from "actions/lessons"
import { archiveLesson } from "actions/lessons"
import { unarchiveLesson } from "actions/lessons"
import { unpublishLesson } from "actions/lessons"
import {
  addLessonInstructor,
  getVideo,
  getVideoSrt,
  updateLesson,
} from "actions/lessons"
import { setLessonResources } from "actions/lessons"
import { newVersion } from "actions/lessons"
import { copyLesson } from "actions/lessons"
import { listLessons } from "actions/lessons"
import { getLessonPreview } from "actions/lessons"
import { lessonAnalytics } from "actions/lessons"
import { xyz } from "color"
import { updatedDiff } from "deep-object-diff"
import PropTypes from "prop-types"
import { createContext, useContext, useEffect } from "react"
import { useState } from "react"
import { QueryCache, useMutation, useQuery, useQueryClient } from "react-query"
import { useHistory } from "react-router-dom"
import serialize from "store/serialize"
import differenceBetween from "utils/differenceBetween"

import AppContext from "./App"

const LessonContext = createContext()
export default LessonContext

export const LessonContainer = ({
  children,
  lesson,
  lessonLoading,
  refetchLesson,
}) => {
  //state to check if there is data unsaved
  const [unsavedState, setUnsavedState] = useState([])
  const queryClient = useQueryClient()
  const { openSnackBar, openDialog } = useContext(AppContext)
  //loding state for the save  as published if lesson is published
  //or save as draft if is a draft
  const [saveLoading, setSaveLoading] = useState(false)
  // loading state when publish a lesson
  const [publishLoading, setPublishLoading] = useState(false)
  // loading state when unpublish a course
  const [unpublishLoading, setUnpublishLoading] = useState(false)
  // loading state when archive a course
  const [archiveLoading, setArchiveLoading] = useState(false)
  // loading state when unarchive a course
  const [unarchiveLoading, setUnarchiveLoading] = useState(false)
  //open and close header menu
  const [anchorEl, setAnchorEl] = useState(null)
  //state to show the validation errors if the user try to publish
  const [tryPublish, setTryPublish] = useState(false)
  //state to show the validation errors for name, descritpion, headline
  const [lengthError, setLengthError] = useState(false)

  const history = useHistory()

  const { data: lessons, refetch: refetchLessons } = useQuery("lessons", () =>
    listLessons()
  )

  const {
    data: publishedLessons,
    refetch: refetchPublishedLessons,
  } = useQuery("publishedLessons", () => listPublishedLessons())

  useEffect(() => {
    setUnsavedState([])
  }, [])

  //lesson general information state with debounced
  const [debouncedDetails, setDebouncedDetails] = useState({
    name: "",
    description: "",
  })

  const [lessonDetailsValues, setLessonDetailsValues] = useState({
    name: "",
    description: "",
    headline: "",
    theater_view: false,
  })

  //course general information initial state
  const [initialDetailsContext, setInitialDetailsContext] = useState({
    name: "",
    description: "",
    headline: "",
    theater_view: false,
  })

  //check unsave details data
  useEffect(() => {
    const unsavedData = updatedDiff(initialDetailsContext, lessonDetailsValues)

    if (
      Object.keys(unsavedData)?.length > 0 &&
      !unsavedState.includes("details")
    ) {
      setUnsavedState((oldState) => [...oldState, "details"])
    } else if (
      Object.keys(unsavedData)?.length === 0 &&
      unsavedState.includes("details")
    ) {
      setUnsavedState(
        unsavedState.filter((x) => {
          return x !== "details"
        })
      )
    }
  }, [lessonDetailsValues, JSON.stringify(lesson)])

  //tags state
  const [tagsContext, setTagsContext] = useState(lesson?.tags || [])
  //check unsave tags data
  useEffect(() => {
    let initialValues = lesson?.tags?.map((x) => x.name)
    let currentValues = tagsContext?.map((x) => x.name)

    let unsavedData = differenceBetween(initialValues, currentValues)

    if (unsavedData?.length > 0 && !unsavedState.includes("tags")) {
      setUnsavedState((oldState) => [...oldState, "tags"])
    } else if (unsavedData?.length === 0 && unsavedState.includes("tags")) {
      setUnsavedState(
        unsavedState.filter((x) => {
          return x !== "tags"
        })
      )
    }
  }, [tagsContext, JSON.stringify(lesson)])

  //array of instructors connected to lessons
  const [instructorIdArr, setInstructorIdArr] = useState([])

  const [instructorsArr, setInstructorsArr] = useState(
    lesson?.instructors || []
  )

  //check unsave instructors data
  useEffect(() => {
    let initialValues = lesson?.instructors?.map((x) => x.id)
    let currentValues = instructorsArr?.map((x) => x.id)

    let unsavedData = differenceBetween(initialValues, currentValues)

    if (unsavedData?.length > 0 && !unsavedState.includes("instructors")) {
      setUnsavedState((oldState) => [...oldState, "instructors"])
    } else if (
      unsavedData?.length === 0 &&
      unsavedState.includes("instructors")
    ) {
      setUnsavedState(
        unsavedState.filter((x) => {
          return x !== "instructors"
        })
      )
    }
  }, [instructorsArr, JSON.stringify(lesson)])

  const [resourcesLessonArray, setResourceLessonArray] = useState([])
  const createTagsMutation = useMutation((data) => createTags(data, lesson.id))

  const videoUrl = useQuery(
    ["getVideoUrl", lesson?.video_id],
    () => getVideo(lesson?.video_id),
    {
      enabled: lesson?.video_id !== null,
      staleTime: 1000 * 60 * 60, // 1 hour
    }
  )

  const videoSrt = useQuery(
    ["getVideoSrt", lesson?.video_id],
    () => getVideoSrt(lesson?.video_id),
    {
      enabled: lesson?.video?.srt_key !== null && lesson?.video_id !== null,
    }
  )

  const updateLessonMutation = useMutation((data) =>
    updateLesson(data, lesson.id)
  )

  const addLessonInstructorMutation = useMutation((data) =>
    addLessonInstructor(data, lesson.id)
  )
  const publishLessonMutation = useMutation(() => publishLesson(lesson?.id))

  const unpublishLessonMutation = useMutation(() => unpublishLesson(lesson?.id))

  const archiveLessonMutation = useMutation(() => archiveLesson(lesson?.id))

  const unarchiveLessonMutation = useMutation(() => unarchiveLesson(lesson?.id))

  const lessonResourceMutation = useMutation((data) =>
    setLessonResources(data, lesson?.id)
  )

  const newLessonVersionMutation = useMutation((data) =>
    newVersion(data, lesson?.id)
  )

  const copyLessonMutation = useMutation((data) => copyLesson(data, lesson?.id))

  const getPreviewMutation = useMutation(() => getLessonPreview(lesson?.id))

  const analyticsMutation = useMutation(() => lessonAnalytics(lesson?.id))

  //if stament to know what we need to complete to publish
  const disabledSave =
    debouncedDetails?.name === "" ||
    debouncedDetails?.name?.length > 50 ||
    debouncedDetails?.description === "" ||
    debouncedDetails?.description?.length > 2000 ||
    tagsContext?.length === 0 ||
    Object.keys(lesson?.thumbnail)?.length === 0 ||
    instructorsArr?.length === 0

  //disabled the input depends the length when save
  let correctInputsLength =
    debouncedDetails?.name?.length <= 50 &&
    debouncedDetails?.description?.length <= 2000

  //close menu
  const handleClose = () => {
    setAnchorEl(null)
  }

  // update lesson details
  const updateDetails = () => {
    return new Promise((resolve, reject) => {
      if (unsavedState.includes("details")) {
        updateLessonMutation.mutate(debouncedDetails, {
          onSuccess: (res) => {
            serialize("lesson", res.data).then((serializedData) => {
              return queryClient.setQueryData(
                ["lesson", res.data.id],
                (oldState) => {
                  return serializedData
                }
              )
            })
            serialize("lesson", res.data).then((serializedData) => {
              return queryClient.setQueryData("lessons", (oldData) => {
                let newArray = []
                newArray = oldData.map((x) => {
                  if (x.id !== serializedData.id) {
                    return x
                  } else {
                    return serializedData
                  }
                })
                return [...newArray]
              })
            })
            serialize("lesson", res.data).then((serializedData) => {
              return queryClient.setQueryData("publishedLessons", (oldData) => {
                if (oldData) {
                  let newArray = []
                  newArray = oldData.map((x) => {
                    if (x.id !== lesson.id) {
                      return x
                    } else {
                      const version = x.versions.find((l) => l.id === lesson.id)
                      const versionIndex = x.versions.indexOf(version)
                      x.versions[versionIndex].name = serializedData.name
                      return x
                    }
                  })
                  return [...newArray]
                }
              })
            })
            //if is published send the updated message
            if (lesson.published === 1) {
              openSnackBar({ message: "Lesson Updated" })
            }
            resolve()
            openSnackBar({
              message: "Lesson updated successfully",
            })
          },
          onError: (err) => {
            openSnackBar({
              message: "Error Updating Lesson",
            })
            setSaveLoading(false)
            reject()
          },
        })
      } else {
        resolve()
      }
    })
  }

  //update tags promise
  const addTags = () => {
    return new Promise((resolve, reject) => {
      if (unsavedState.includes("tags")) {
        createTagsMutation.mutate(tagsContext, {
          onSuccess: (res) => {
            serialize("lesson", res.tags).then((serializedData) => {
              return queryClient.setQueryData(
                ["lesson", lesson.id],
                (oldState) => {
                  oldState.tags = serializedData
                  return oldState
                }
              )
            })
            resolve()
          },
          onError: (err) => {
            openSnackBar({
              message: "Error Updating tags",
            })
            setSaveLoading(false)
            reject()
          },
        })
      } else {
        resolve()
      }
    })
  }

  //update instructors inside lesson
  const addInstructors = () => {
    return new Promise((resolve, reject) => {
      if (unsavedState.includes("instructors")) {
        addLessonInstructorMutation.mutate(instructorIdArr, {
          onSuccess: (res) => {
            serialize("instructor", res.instructors).then((serializedData) => {
              return queryClient.setQueryData(
                ["lesson", lesson.id],
                (oldState) => {
                  oldState.instructors = serializedData
                  const version = oldState.versions.find(
                    (l) => l.id === lesson.id
                  )
                  const versionIndex = oldState.versions.indexOf(version)
                  oldState.versions[versionIndex].instructors = serializedData
                  return oldState
                }
              )
            })
            serialize("instructor", res.instructors).then((serializedData) => {
              return queryClient.setQueryData("lessons", (oldData) => {
                let newArray = []
                newArray = oldData.map((x) => {
                  if (x.id !== lesson.id) {
                    return x
                  } else {
                    x.instructors = serializedData
                    return x
                  }
                })
                return [...newArray]
              })
            })
            serialize("instructor", res.instructors).then((serializedData) => {
              return queryClient.setQueryData("publishedLessons", (oldData) => {
                if (oldData) {
                  let newArray = []
                  newArray = oldData.map((x) => {
                    if (x.id !== lesson.id) {
                      return x
                    } else {
                      const version = x.versions.find((l) => l.id === lesson.id)
                      const versionIndex = x.versions.indexOf(version)
                      x.versions[versionIndex].instructors = serializedData
                      x.instructors = serializedData
                      return x
                    }
                  })
                  return [...newArray]
                }
              })
            })
            resolve()
          },
          onError: () => {
            openSnackBar({
              message: "Error adding instructors",
            })
            setSaveLoading(false)
            reject()
          },
        })
      } else {
        resolve()
      }
    })
  }
  //update resources order
  const reorderResources = () => {
    setSaveLoading(true)
    return new Promise((resolve, reject) => {
      if (resourcesLessonArray?.length > 0) {
        lessonResourceMutation.mutate(
          //map resets the order prop to the index
          resourcesLessonArray.map((x, i) => ({
            ...x,
            order: i,
          })),
          {
            onSuccess: (res) => {
              const lessonId = res.resources[0].lesson_id
              queryClient.setQueryData(["lesson", lessonId], (oldData) => {
                return { ...oldData, resources: res.resources }
              })
              resolve()
              setSaveLoading(false)
            },
            onError: () => {
              openSnackBar({
                message: "Error ordering resource",
              })
              setSaveLoading(false)
              reject()
            },
          }
        )
      } else {
        resolve()
        setSaveLoading(false)
      }
    })
  }

  //update lessons depends if is published or not
  const handleUpdate = () => {
    // if starting on resources tab (ex: refresh/reload page), disabledSave is always true.
    // by keying on url path including /resources, reordering allowed in this situation.
    // note: disabledSave rights itself on navigating away from the resource page.
    if (history?.location?.pathname?.includes("resources")) {
      reorderResources()
        .then((res) => {
          openSnackBar({
            message: "Resources Order Updated",
          })
        })
        .catch((err) => {
          openSnackBar({
            message: "Error Ordering Resources",
          })
          handleClose()
        })
    } else if (disabledSave && lesson.published === 1) {
      //if lesson is published dont let save if some data is ont completed
      setTryPublish(true)
      handleClose()
    } else {
      //hide errors needed if the user click publish before and have errors to publish
      setTryPublish(false)
      if (!correctInputsLength) {
        setLengthError(true)
      } else {
        setSaveLoading(true)
        return addTags()
          .then(addInstructors)
          .then(updateDetails)
          .then(() => {
            openSnackBar({ message: "Lesson Updated" })
            setSaveLoading(false)
            handleClose()
          })
          .catch((err) => {
            openSnackBar({
              message: "Error updating resource",
            })
            setSaveLoading(false)
            handleClose()
          })
      }
    }
  }

  //publish lesson
  const publishLessonFunc = () => {
    //open errors if the users didnt complete all that is require in disabledSave
    if (disabledSave) {
      setTryPublish(true)
      handleClose()
    } else {
      if (videoUrl?.isSuccess && videoSrt?.isSuccess) {
        let data = {
          title: "Publish Confirmation",
          subtitle: "",
          type: "publish",
          confirmWord: "ship it",
        }
        handleClose()
        openDialog("confirmationTypingDialog", data).then(() => {
          //open confirm dialog
          handleUpdate().then(() => {
            setPublishLoading(true)
            publishLessonMutation.mutate(
              {},
              {
                onSuccess: (res) => {
                  serialize("lesson", res.data).then((serializedData) => {
                    return queryClient.setQueryData(
                      ["lesson", res.data.id],
                      (oldData) => {
                        return serializedData
                      }
                    )
                  })
                  serialize("lesson", res.data).then((serializedData) => {
                    return queryClient.setQueryData("lessons", (oldData) => {
                      let newArray = []
                      newArray = oldData.map((x) => {
                        if (x.id !== serializedData.id) {
                          return x
                        } else {
                          return serializedData
                        }
                      })
                      return [...newArray]
                    })
                  })
                  refetchPublishedLessons()
                  openSnackBar({
                    message: "This lesson was published",
                  })
                  setPublishLoading(false)
                  handleClose()
                },
                onError: (err) => {
                  openSnackBar({
                    message: "Error Publishing Lesson",
                  })
                  setPublishLoading(false)
                },
              }
            )
          })
        })
      } else {
        setPublishLoading(false)
        handleClose()
        openSnackBar({
          message: !videoUrl.isSuccess
            ? "You must upload a video to publish a lesson"
            : "You must upload an srt file to publish a lesson",
        })
      }
    }
  }

  //unpublish lesson
  const unpublishLessonFunc = () => {
    //check if the inputs have the corrent length
    if (!correctInputsLength) {
      setLengthError(true)
      handleClose()
    } else {
      let data = {
        title: "Unpublish Confirmation",
        subtitle: "",
        type: "unpublish",
        confirmWord: "unpublish",
      }
      handleClose()
      openDialog("confirmationTypingDialog", data).then(() => {
        setUnpublishLoading(true)
        handleUpdate().then(() => {
          unpublishLessonMutation.mutate(
            {},
            {
              onSuccess: (res) => {
                serialize("lesson", res.data).then((serializedData) => {
                  return queryClient.setQueryData(
                    ["lesson", res.data.id],
                    (oldData) => {
                      return serializedData
                    }
                  )
                })
                serialize("lesson", res.data).then((serializedData) => {
                  return queryClient.setQueryData("lessons", (oldData) => {
                    let newArray = []
                    newArray = oldData.map((x) => {
                      if (x.id !== serializedData.id) {
                        return x
                      } else {
                        return serializedData
                      }
                    })
                    return [...newArray]
                  })
                })
                refetchPublishedLessons()
                openSnackBar({
                  message: "This course was unpublished",
                })
                setUnpublishLoading(false)
                handleClose()
              },
              onError: (err) => {
                openSnackBar({
                  message: "Error Unpublishing Course",
                })
                setUnpublishLoading(false)
                handleClose()
              },
            }
          )
        })
      })
    }
  }

  //archive lesson
  const archiveLessonFunc = () => {
    //delete errors if we try to publish before
    setTryPublish(false)
    //check if the inputs have the corrent length
    if (!correctInputsLength) {
      setLengthError(true)
      handleClose()
    } else {
      //open confirm dialog
      let data = {
        title: "Archive Confirmation",
        subtitle: "",
        type: "archive",
        confirmWord: "archive",
      }
      handleClose()
      openDialog("confirmationTypingDialog", data).then(() => {
        setArchiveLoading(true)
        handleUpdate().then(() => {
          archiveLessonMutation.mutate(
            {},
            {
              onSuccess: (res) => {
                serialize("lesson", res.data).then((serializedData) => {
                  return queryClient.setQueryData(
                    ["lesson", res.data.id],
                    (oldData) => {
                      return serializedData
                    }
                  )
                })
                serialize("lesson", res.data).then((serializedData) => {
                  return queryClient.setQueryData("lessons", (oldData) => {
                    let newArray = []
                    newArray = oldData.map((x) => {
                      if (x.id !== serializedData.id) {
                        return x
                      } else {
                        return serializedData
                      }
                    })
                    return [...newArray]
                  })
                })
                openSnackBar({
                  message: "This lesson was archived",
                })
                setArchiveLoading(false)
                handleClose()
              },
              onError: (err) => {
                openSnackBar({
                  message: "Error archiving Lesson",
                })
                setArchiveLoading(false)
                handleClose()
              },
            }
          )
        })
      })
    }
  }

  //unarchive lesson
  const unarchiveLessonFunc = () => {
    //check if the inputs have the corrent length
    if (!correctInputsLength) {
      setLengthError(true)
      handleClose()
    } else {
      //open confirm dialog
      let data = {
        title: "Un-archive Confirmation",
        subtitle: "",
        type: "unarchive",
        confirmWord: "unarchive",
      }
      openDialog("confirmationTypingDialog", data).then(() => {
        setUnarchiveLoading(true)
        handleUpdate().then(() => {
          unarchiveLessonMutation.mutate(
            {},
            {
              onSuccess: (res) => {
                serialize("lesson", res.data).then((serializedData) => {
                  return queryClient.setQueryData(
                    ["lesson", res.data.id],
                    (oldData) => {
                      return serializedData
                    }
                  )
                })
                serialize("lesson", res.data).then((serializedData) => {
                  return queryClient.setQueryData("lessons", (oldData) => {
                    let newArray = []
                    newArray = oldData.map((x) => {
                      if (x.id !== serializedData.id) {
                        return x
                      } else {
                        return serializedData
                      }
                    })
                    return [...newArray]
                  })
                })
                openSnackBar({
                  message: "This lesson was un-archived",
                })
                setUnarchiveLoading(false)
                handleClose()
              },
              onError: (err) => {
                openSnackBar({
                  message: "Error Un-archiving Lesson",
                })
                setUnarchiveLoading(false)
                handleClose()
              },
            }
          )
        })
      })
    }
  }

  //discard changes
  const discardChanges = () => {
    if (unsavedState.includes("details")) {
      setLessonDetailsValues(initialDetailsContext)
      setDebouncedDetails(initialDetailsContext)
    } else if (unsavedState.includes("tags")) {
      setTagsContext(lesson?.tags)
    } else if (unsavedState.includes("instructors")) {
      setInstructorsArr(lesson?.instructors)
    }
  }
  return (
    <LessonContext.Provider
      value={{
        lesson,
        unsavedState,
        setUnsavedState,
        saveLoading,
        publishLoading,
        unpublishLoading,
        archiveLoading,
        unarchiveLoading,
        anchorEl,
        setAnchorEl,
        tryPublish,
        lengthError,
        setTryPublish,
        debouncedDetails,
        setDebouncedDetails,
        lessonDetailsValues,
        setLessonDetailsValues,
        setInitialDetailsContext,
        tagsContext,
        setTagsContext,
        instructorIdArr,
        setInstructorIdArr,
        instructorsArr,
        setInstructorsArr,
        resourcesLessonArray,
        setResourceLessonArray,
        addLessonInstructorMutation,
        lessonResourceMutation,
        publishLessonMutation,
        updateLessonMutation,
        createTagsMutation,
        addTags,
        addInstructors,
        reorderResources,
        updateLesson,
        handleUpdate,
        publishLessonFunc,
        unpublishLessonFunc,
        archiveLessonFunc,
        unarchiveLessonFunc,
        discardChanges,
        videoUrl,
        refetchLesson,
        lessonLoading,
        newLessonVersionMutation,
        copyLessonMutation,
        videoSrt,
        disabledSave,
        getPreviewMutation,
        analyticsMutation,
        correctInputsLength,
      }}
    >
      {children}
    </LessonContext.Provider>
  )
}

LessonContainer.propTypes = {
  children: PropTypes.node,
  lesson: PropTypes.object,
  refetchLesson: PropTypes.func,
  lessonLoading: PropTypes.bool,
}
