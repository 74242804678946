import { Box } from "@material-ui/core"
import UsersFilter from "components/Users/UsersFilter"
import UsersTable from "components/Users/UsersTable"
import AppContext from "contexts/App"
import UsersContext from "contexts/Users"
import Page from "pages/Page"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useRef, useState } from "react"
import { useQuery } from "react-query"
import AutoSizer from "react-virtualized-auto-sizer"
import Router from "router"

import useStyles from "./styles"

const User = ({ routes = [], ...rest }) => {
  const tableRef = useRef()

  const [filteredData, setFilteredData] = useState([])
  const [totalNumber, setTotalNumber] = useState(null)
  const [totalShowing, setTotalShowing] = useState(null)
  const [loading, setLoading] = useState(false)
  const {
    searchValue,
    activeValue,
    listAppUsersMutation,
    sort,
    sliderValue,
    publishedCoursesCountMutation,
    setSliderValue,
    setCoursesCount,
    debouncedSliderValue,
    setDebouncedSliderValue,
    coursesCount,
  } = useContext(UsersContext)
  const [onlyFirstTime, setOnlyFirstTime] = useState(true)
  const { setPageSubTitle, setPageTitle, openSnackBar } = useContext(AppContext)
  //active filters array
  const classes = useStyles()

  //call the api for new data when searchValue and activeValue changes
  useEffect(() => {
    if (!onlyFirstTime && debouncedSliderValue[1] !== null) {
      tableRef.current && tableRef.current.onQueryChange()
    }
  }, [
    searchValue,
    activeValue,
    debouncedSliderValue[0],
    debouncedSliderValue[1],
  ])

  //call the user table data for the first time
  useEffect(() => {
    //prevent two calls every time that we do a sort or a filter after the first load
    if (onlyFirstTime) {
      publishedCoursesCountMutation.mutate(
        {},
        {
          onSuccess: (res) => {
            setSliderValue([0, res])
            setDebouncedSliderValue([0, res])
            setCoursesCount(res)
            let data = {
              sort: {
                key: sort.name,
                direction: "DESC",
              },
              filter: {
                search: "",
                last_online: "all",
                completed_courses: [0, res],
              },
              page: 0,
              pageSize: 25,
            }
            listAppUsersMutation.mutate(data, {
              onSuccess: (res) => {
                setLoading(true)
                setFilteredData(res.data)
                setTotalNumber(res?.total)
                setTotalShowing(res?.searchTotal)
                //set courses  subtitle depends the search
                setPageSubTitle(res?.searchTotal)
                setPageSubTitle(res?.total)
                setPageTitle("Users")
                if (tableRef.current) {
                  // gets table container and sets scrolltop to 0 to move table to the top
                  tableRef.current.tableContainerDiv.current.scrollTop = 0
                }
              },
              onError: (err) => {
                openSnackBar({
                  message: "Error loading app users data",
                })
              },
              onSettled: () => {
                setLoading(false)
              },
            })
            setOnlyFirstTime(false)
          },
        }
      )
    }
  }, [])

  return (
    <Page>
      <Box className={classes.usersListContainer} height="100%">
        <Box className={classes.leftContainer} height="100%">
          <UsersFilter
            totalNumber={totalNumber}
            totalShowing={totalShowing}
            setOnlyFirstTime={setOnlyFirstTime}
          />
        </Box>
        <Box className={classes.rigthContainer} height="100%">
          <AutoSizer style={{ width: "100%", height: "100%" }}>
            {({ width, height }) => {
              return (
                <>
                  <UsersTable
                    setTotalNumber={setTotalNumber}
                    setTotalShowing={setTotalShowing}
                    users={filteredData}
                    tableRef={tableRef}
                    classes={classes}
                    loading={loading}
                    height={height}
                  />
                </>
              )
            }}
          </AutoSizer>
        </Box>
      </Box>
      <Router routes={routes} {...rest} />
    </Page>
  )
}
User.propTypes = {
  routes: PropTypes.array,
}
export default User
