import {
  Box,
  Button,
  Card,
  CardContent,
  Grow,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import StarOutlineIcon from "@material-ui/icons/StarOutline"
import video from "assets/Icons/feather-video-grey.svg"
import LabIcon from "assets/Icons/lab-icon.svg"
import lesson from "assets/lesson.svg"
import { Image, Transformation } from "cloudinary-react"
import DraftPublishedBox from "components/Common/DraftPublishedBox"
import HoverText from "components/Common/HoverText"
import AppContext from "contexts/App"
import dayjs from "dayjs"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import { useContext, useEffect, useState } from "react"

import useStyles from "./styles"

const CourseCard = ({ lessonLength, openCourse, course }) => {
  const classes = useStyles()

  const [courseDuration, setCourseDuration] = useState(0)

  const { openDialog } = useContext(AppContext)

  const latestVersion = course?.versions[course?.versions?.length - 1]

  // looks through course and puts each lesson's video duration in an array
  const lessonDurations = () => {
    let durationArray = []
    //map through each course's lessons array
    course?.lessons?.map((lesson) => {
      if (lesson?.video && Object.keys(lesson?.video).length > 0) {
        // if a lesson has a video, pushing the duration (in s) to the array
        durationArray.push(
          lesson?.video?.duration || lesson?.video[0]?.duration
        )
      }
    })
    // sumDurations adds each value in the array - gives total duration (in s)
    const sumDurations = durationArray.reduce((a, b) => a + b, 0)
    return sumDurations
  }

  const handleOpenHistory = () => {
    let data = {
      data: course,
      type: "course",
    }
    openDialog("historyVersionDialog", data)
  }

  useEffect(() => {
    setCourseDuration(lessonDurations())
  }, [course])

  // const [anchorEl, setAnchorEl] = useState(null)

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget)
  // }

  // const handleClose = () => {
  //   setAnchorEl(null)
  // }

  return (
    <Grow in={true} timeout={350} style={{ transformOrigin: "center" }}>
      <Box className={classes.container}>
        <Card className={classes.root} raised={true} elevation={0}>
          {!isEmpty(course.thumbnail) && (
            <Image
              publicId={course.thumbnail.public_id}
              style={{
                verticalAlign: "bottom",
                width: "100%",
                cursor: "pointer",
              }}
              onClick={openCourse}
            >
              <Transformation
                width="335"
                crop="fill"
                gravity="auto"
                aspectRatio="16:9"
              />
            </Image>
          )}
          {isEmpty(course.thumbnail) && (
            <Box className={classes.cardMedia} onClick={openCourse} />
          )}
          <CardContent>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box className={classes.versionBtnBox}>
                <Button
                  color="default"
                  className={classes.versionBtn}
                  onClick={handleOpenHistory}
                >
                  Version: {" " + course.version}
                </Button>
                {latestVersion?.version === course?.version && (
                  <Tooltip title="Latest Version" placement="top">
                    <StarOutlineIcon
                      color="primary"
                      fontSize="inherit"
                      className={classes.starIcon}
                    />
                  </Tooltip>
                )}
                <Typography variant="caption" className={classes.date}>
                  Last Updated {course?.updated?.format("M/D/YYYY")}
                </Typography>
              </Box>
            </Box>
            <Box
              marginTop={1.5}
              height="60px"
              onClick={openCourse}
              style={{ cursor: "pointer" }}
            >
              <HoverText
                variant="h5"
                className={classes.title}
                size={38}
                data={course?.name}
              />
            </Box>
            <Box
              display="flex"
              marginTop={1.5}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <Typography
                  color="primary"
                  variant="subtitle1"
                  className={classes.lessons}
                >
                  {course?.lessons?.length}
                </Typography>
                <img src={lesson} alt="" className={classes.image} />
                {/* <IconButton>
                  <MoreVertIcon
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                  ></MoreVertIcon>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose}>Publish Course</MenuItem>
                    <MenuItem onClick={handleClose}>Archive Course</MenuItem>
                    <MenuItem onClick={handleClose}>Schedule Course</MenuItem>
                    <MenuItem onClick={handleClose}>Delete Course</MenuItem>
                  </Menu>
                </IconButton> */}
              </Box>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="subtitle1"
                  color="primary"
                  style={{ fontWeight: "600" }}
                >
                  {dayjs
                    .duration(0, "s")
                    .add(courseDuration, "seconds")
                    .format("mm:ss")}
                </Typography>
                <img src={video} alt="" className={classes.durationIcon} />
              </Box>
              <Box width="10px">
                {course.lab_id !== null && (
                  <img
                    src={LabIcon}
                    alt="Lab"
                    style={{ width: 10, verticalAlign: "middle" }}
                  />
                )}
              </Box>
              <Box>
                <DraftPublishedBox
                  published={course.published}
                  has_been_published={course.has_been_published}
                  archived={course.archived}
                  publish_date={course.publish_date}
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Grow>
  )
}

CourseCard.propTypes = {
  course: PropTypes.object,
  lessonLength: PropTypes.string,
  openCourse: PropTypes.func,
}

export default CourseCard
