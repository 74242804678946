import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  dialogTitle: {
    color: theme.palette.primary.main,
    fontSize: "18px",
    fontWeight: "bold",
  },
  title: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  inputBackgroundColor: {
    backgroundColor: "#F4F4F4",
  },
  requiredText: {
    color: theme.palette.warning.main,
  },
}))

export default styles
