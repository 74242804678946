import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  filtersContainer: {
    width: "100%",
    paddingTop: "20px",
    paddingLeft: "16px",
    paddingRight: "18.5px",
    color: "#fff",
    position: "relative",
    height: "100%",
  },
  title: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    fontWeight: "bold",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
    },
  },
  inputBackgroundColor: {
    backgroundColor: "#fff",
    marginTop: "20px",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
    },
    "&.Mui-focused": {
      background: "white",
    },
    "&:hover": {
      background: "white",
    },
  },
  formControl: {
    height: "32px",
    width: "100%",
  },
  sliderColor: {
    color: "#fff",
    marginTop: theme.spacing(2),
  },
  buttonColor: {
    color: "#fff",
  },
  clearButton: {
    left: "0",
    right: "0",
    position: "absolute",
    textAlign: "center",
    bottom: 50,
    [theme.breakpoints.down("md")]: {
      bottom: 25,
    },
  },
  select: {
    height: "100%",
    backgroundColor: "#0929582B",
    borderRadius: "4px",
  },
  selectRoot: {
    "&:focus": {
      backgroundColor: "rgb(0,0,0,0)",
    },
  },
  icon: {
    color: "#fff",
  },
}))

export default styles
