import {
  Box,
  CircularProgress,
  LinearProgress,
  Typography,
} from "@material-ui/core"
import { listClients } from "actions/clients"
import ClientCard from "components/Clients/ClientCard"
import ListTitle from "components/Common/ListTitle"
import NoData from "components/Common/NoData"
import AppContext from "contexts/App"
import PropTypes from "prop-types"
import { useContext, useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useQuery } from "react-query"
import sortHelper from "utils/sortHelper"

import useStyles from "./styles"

const ClientsCardList = ({ searchValue, sort, openCreateDialog }) => {
  const classes = useStyles()
  const [filteredData, setFilteredData] = useState([])
  const { setPageSubTitle } = useContext(AppContext)
  //amount of data that we show using the infinite scroll
  const [showedData, setShowedData] = useState([])
  const { data: clients, isLoading: clientsLoading } = useQuery("clients", () =>
    listClients()
  )

  // set clients data to filteredData on first load
  useEffect(() => {
    if (clients?.length > 0 && !filteredData) {
      setFilteredData(clients)
    }
  }, [clients])

  // handles search by client name, and now sorting asc/desc by client name
  useEffect(() => {
    if (clients) {
      let newArray = [...clients]
      if (searchValue.length > 0 || sort?.name !== "") {
        // if a search val is applied
        if (searchValue.length > 0) {
          newArray = newArray.filter((x) =>
            x.name.toLowerCase().includes(searchValue.toLowerCase())
          )
        }
        //checks if a sort has been applied
        if (sort?.name !== "") {
          //util function to sort the data
          newArray = sortHelper(
            newArray,
            sort?.type,
            sort?.name,
            sort?.direction
          )
        }
      } else if (searchValue === "") {
        // handles no search value
        newArray = clients
      }
      setFilteredData(newArray)
      //show 20 first elements of the newArray
      setShowedData(newArray?.slice(0, 20 || []))
      //set client subtitle
      setPageSubTitle(newArray?.length)
    }
  }, [searchValue, sort, clients])

  //loadData using infinite scroll
  const loadData = () => {
    setShowedData(() => filteredData.slice(0, showedData.length + 20))
  }

  //send the user to the top if a  sort or search value change
  useEffect(() => {
    const element = document.getElementById("clientsListScroll")
    if (element) {
      element.scrollTo(0, 0)
    }
  }, [searchValue, sort])

  if (clientsLoading) {
    return <LinearProgress />
  }

  return (
    <Box overflow="auto" className={classes.container} id="clientsListScroll">
      <Box marginLeft={2} className={classes.dataWrapper}>
        {filteredData?.length > 0 ? (
          <Box width="85%" paddingLeft={1}>
            <ListTitle
              list={[
                { name: "Client ID", size: "20%" },
                { name: "Company", size: "25%" },
                { name: "Codes", size: "15%" },
                { name: "Total Seats", size: "15%" },
                { name: "Active Seats", size: "15%" },
                { name: "" },
              ]}
            />
          </Box>
        ) : null}
        <Box className={classes.cardBox} height="100%">
          {filteredData?.length > 0 ? (
            <InfiniteScroll
              style={{ overflow: "hidden" }}
              dataLength={showedData?.length || 0}
              scrollableTarget="clientsListScroll"
              next={loadData}
              loader={
                <Box display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              }
              hasMore={filteredData?.length > showedData?.length || false}
              endMessage={
                <Box width="100%" textAlign="center">
                  {filteredData.length > 20 && (
                    <Typography className={classes.postsInfiniteText}>
                      End of Clients List
                    </Typography>
                  )}
                </Box>
              }
            >
              {showedData.map((client, index) => {
                return <ClientCard key={index} client={client} />
              })}
            </InfiniteScroll>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              minHeight="80vh"
            >
              <NoData
                text="You have not created any clients"
                buttonFunc={openCreateDialog}
                buttonText="create a client"
                searchValue={searchValue}
                length={clients?.length}
                searchLength={filteredData?.length}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

ClientsCardList.propTypes = {
  clients: PropTypes.array,
  sort: PropTypes.object,
  searchValue: PropTypes.string,
  openCreateDialog: PropTypes.func,
}

export default ClientsCardList
