import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  rowWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  idBox: {
    width: "15%",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
  },
  clientName: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    whiteSpace: "nowrap",
  },
  nameBox: {
    width: "25%",
  },
  codeCountBox: {
    width: "15%",
    paddingLeft: theme.spacing(2),
  },
  totalSeatsBox: {
    width: "15%",
    paddingLeft: theme.spacing(4),
  },
  activeSeatsBox: {
    width: "15%",
    paddingLeft: theme.spacing(4),
  },
  completionRateBox: {
    width: "15%",
    paddingLeft: theme.spacing(5),
  },
  buttonBox: {
    width: "10%",
    display: "flex",
    justifyContent: "flex-end",
  },
  paper: {
    border: "1px solid #E2E2E2",
    borderRadius: "8px",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    height: "200px",
  },
}))

export default styles
