import { Box, Switch, TextField, Typography } from "@material-ui/core"
import DateTime from "components/Certifications/DateTime"
import CounterHeader from "components/Common/CounterHeader"
import ThumbnailUpload from "components/Common/ThumbnailUpload"
import AppContext from "contexts/App"
import CertificationContext from "contexts/Certification"
import PropTypes from "prop-types"
import React, { useContext, useEffect } from "react"
import { useQueryClient } from "react-query"
import serialize from "store/serialize"
import { useDebounce } from "use-lodash-debounce"

import useStyles from "./styles"
const CertificationDetails = () => {
  const classes = useStyles()
  const queryClient = useQueryClient()

  const { openSnackBar, setPageSubTitle, authUser } = useContext(AppContext)

  const {
    certification,
    debouncedDetails,
    setDebouncedDetails,
    certDetailsValues,
    setCertDetailsValues,
    setInitialDetailsContext,
    tryPublish,
    lengthError,
    updateCertMutation,
  } = useContext(CertificationContext)
  const debouncedLocalValues = useDebounce(certDetailsValues, 200)

  //show errors if inputs are empty or more than allow characters
  const descriptionError =
    ((debouncedDetails?.description === "" ||
      debouncedDetails?.description?.length > 2000) &&
      tryPublish) ||
    (lengthError && debouncedDetails?.description?.length > 2000)

  const nameError =
    ((debouncedDetails?.name === "" || debouncedDetails?.name?.length > 50) &&
      tryPublish) ||
    (lengthError && debouncedDetails?.name?.length > 50)

  const headlineError =
    ((debouncedDetails?.headline === "" ||
      debouncedDetails?.headline?.length > 100) &&
      tryPublish) ||
    (lengthError && debouncedDetails?.headline?.length > 100)

  // datetime disbaled if certification is locked
  const disablePicker = certification?.published === 1

  const setThumbnail = (uploadRes, loadingAction, type) => {
    const image = JSON.stringify({
      url: uploadRes.data.url,
      secure_url: uploadRes.data.secure_url,
      originial_filename: uploadRes.data.original_filename,
      version: uploadRes.data.version,
      format: uploadRes.data.format,
      public_id: uploadRes.data.public_id,
    })
    const data = {
      [type]: image,
    }
    updateCertMutation.mutate(data, {
      onSuccess: (res) => {
        setTimeout(() => {
          loadingAction(false)
        }, 400)
        serialize("certification", res.data).then((serializedData) => {
          queryClient.setQueryData(
            ["certification", res.data.id],
            (oldData) => {
              oldData.thumbnail = serializedData.thumbnail
              return oldData
            }
          )
          return queryClient.setQueryData("certifications", (oldData) => {
            let newArray = []
            newArray = oldData.map((x) => {
              if (x.id !== serializedData.id) {
                return x
              } else {
                x[type] = serializedData[type]
                return x
              }
            })
            return [...newArray]
          })
        })
      },
      onError: (err) => {
        console.log(err)
        loadingAction(false)
        openSnackBar({
          message: "Error Updating Thumbnail",
        })
      },
    })
  }

  // //set certification subtitle to empty string
  useEffect(() => {
    setPageSubTitle("")
  }, [])

  // // set learning path details to compoment state
  useEffect(() => {
    if (certification?.id) {
      setCertDetailsValues({
        name: certification.name,
        description: certification.description || "",
        headline: certification.headline || "",
        is_public: certification?.is_public ? true : false,
      })
      setInitialDetailsContext({
        name: certification.name,
        description: certification.description || "",
        headline: certification.headline || "",
        is_public: certification?.is_public ? true : false,
      })
    }
  }, [certification])

  // //set details context state from this component state using debounce
  useEffect(() => {
    setDebouncedDetails(certDetailsValues)
  }, [debouncedLocalValues])

  return (
    <Box className={classes.container}>
      <Typography variant="h3" color="primary" className={classes.title}>
        Certification Details
      </Typography>
      <CounterHeader
        title="Certification Name"
        value={debouncedDetails?.name}
        max={50}
      />
      <TextField
        value={certDetailsValues?.name || ""}
        onChange={(e) => {
          setCertDetailsValues((oldState) => ({
            ...oldState,
            name: e.target.value,
          }))
        }}
        style={{ marginBottom: "10px" }}
        InputProps={{
          classes: {
            root: classes.inputBackgroundColor,
          },
        }}
        variant="filled"
        size="small"
        fullWidth
      />
      {nameError ? (
        <Typography className={classes.requiredText}>
          Name is required and it has to be less than 50 characters
        </Typography>
      ) : null}
      <CounterHeader
        title="Certification Description"
        value={debouncedDetails?.description}
        max={2000}
      />
      <TextField
        value={certDetailsValues.description || ""}
        multiline
        rows={10}
        onChange={(e) => {
          setCertDetailsValues((oldState) => ({
            ...oldState,
            description: e.target.value,
          }))
        }}
        InputProps={{
          disableUnderline: true,
          classes: {
            root: classes.inputDescription,
          },
        }}
        size="small"
        fullWidth
      />
      {descriptionError ? (
        <Typography className={classes.requiredText}>
          Description is required and it has to be less than 2000 characters
        </Typography>
      ) : null}

      {/* <DateTime disablePicker={disablePicker} /> */}

      <Box display="flex" justifyContent="space-between" marginBottom={1}>
        <Box width="48%">
          <Typography color="secondary" className={classes.inputLabel}>
            Thumbnail
          </Typography>
          <ThumbnailUpload
            title="Drop thumbnail image here"
            cloudinaryUploadPreset={
              process.env.REACT_APP_CLOUDINARY_CLOUD_THUMBNAIL_UPLOAD
            }
            value={certification?.thumbnail}
            onUpload={setThumbnail}
            type="thumbnail"
          />
          {Object.keys(certification?.thumbnail)?.length === 0 && tryPublish ? (
            <Typography className={classes.requiredText}>
              Thumbnail is required
            </Typography>
          ) : null}
        </Box>
      </Box>
      <CounterHeader
        title="Headline"
        value={debouncedDetails?.headline}
        max={100}
      />
      <TextField
        value={certDetailsValues?.headline || ""}
        onChange={(e) => {
          setCertDetailsValues((oldState) => ({
            ...oldState,
            headline: e.target.value,
          }))
        }}
        style={{ marginBottom: "8px" }}
        InputProps={{
          classes: {
            root: classes.inputDescription,
          },
        }}
        variant="filled"
        size="small"
        fullWidth
      />
      {headlineError ? (
        <Typography className={classes.requiredText}>
          Headline is required and it has to be less than 100 characters
        </Typography>
      ) : null}
      <Box>
        <Typography color="secondary" className={classes.inputLabel}>
          Public | Private
        </Typography>
        <Switch
          onChange={(e) => {
            setCertDetailsValues((oldState) => ({
              ...oldState,
              is_public: e.target.checked,
            }))
          }}
          value={certDetailsValues?.is_public}
          checked={certDetailsValues?.is_public}
        />
      </Box>
    </Box>
  )
}

export default CertificationDetails
