import { Box, Button, IconButton, Switch, Typography } from "@material-ui/core"
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf"
import { courseViewPDF } from "actions/courses"
import { lessonViewPDF } from "actions/lessons"
import trash from "assets/Icons/feather-trash-2.svg"
import dragGray from "assets/Icons/noun_drag_3124730_gray.svg"
import HoverText from "components/Common/HoverText"
import PaperListRow from "components/Common/PaperListRow"
import AppContext from "contexts/App"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useHistory } from "react-router"

import useStyles from "./styles"

const ResourceCard = ({
  name,
  link,
  access,
  id,
  resources,
  setFn,
  order,
  file,
  provided = {},
}) => {
  const classes = useStyles()

  const { openSnackBar } = useContext(AppContext)
  const { openDialog } = useContext(AppContext)
  const history = useHistory()

  const handleOpen = () => {
    openDialog("editResourceDialog", {
      name,
      link,
      access,
      id,
      order,
      data: resources,
      setFn,
      file,
    })
  }

  const openConfirmationDialog = () => {
    openDialog("confirmationClickDialog", {
      data: resources,
      id,
      setFn,
      title: "Delete Resource",
      type: "resource",
    })
  }

  const openPDF = () => {
    if (history?.location?.pathname.includes("lessons")) {
      lessonViewPDF(id)
        .then((res) => {
          window.open(res)
        })
        .catch((err) => {
          openSnackBar({
            message: "There was an error showing pdf",
          })
        })
    } else {
      courseViewPDF(id)
        .then((res) => {
          window.open(res)
        })
        .catch((err) => {
          openSnackBar({
            message: "There was an error showing pdf",
          })
        })
    }
  }

  return (
    <Box className={classes.cardContainer}>
      <PaperListRow provided={provided}>
        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          className={classes.contentWrapper}
        >
          <Box className={classes.firstLineWrapper}>
            <Box className={classes.titleContanier}>
              <HoverText variant="subtitle1" data={name} size={27} />
            </Box>
            <Box className={classes.linkContainer}>
              {link ? (
                <HoverText variant="subtitle1" data={link} size={40} />
              ) : (
                <IconButton onClick={openPDF}>
                  <PictureAsPdfIcon />
                </IconButton>
              )}
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            className={classes.switchContainer}
          >
            <Switch
              size="small"
              checked={Boolean(access)}
              /* eslint-disable */
              classes={
                !access
                ? {
                  switchBase: classes.switchBase,
                  track: classes.switchTrack,
                }
                : {
                  checked: classes.checkedSwitch,
                }
              }
              /* eslint-enable */
              disableRipple
              disableFocusRipple
              disableTouchRipple
            />
            <Typography
              variant="subtitle2"
              color="inherit"
              style={{ color: access ? "#17A5B1" : "#808080" }}
            >
              {resources?.[0]?.lesson_id
                ? "Lock until lesson has ended"
                : "Lock until course has ended"}
            </Typography>
          </Box>
          <Box className={classes.buttonContainer}>
            <Button color="secondary" onClick={handleOpen}>
              Edit
            </Button>
            <IconButton onClick={openConfirmationDialog}>
              <img src={trash} alt="remove resource" />
            </IconButton>
          </Box>
        </Box>
        <Box {...provided.dragHandleProps} className={classes.dragHandle}>
          <img src={dragGray} alt="" width="20px" />
        </Box>
      </PaperListRow>
    </Box>
  )
}

ResourceCard.propTypes = {
  name: PropTypes.string,
  link: PropTypes.string,
  access: PropTypes.number,
  removeFn: PropTypes.func,
  order: PropTypes.number,
  setFn: PropTypes.object,
  id: PropTypes.number,
  resources: PropTypes.array,
  provided: PropTypes.object,
  file: PropTypes.string,
}

export default ResourceCard
