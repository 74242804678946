import { Box, LinearProgress } from "@material-ui/core"
import { listLabs } from "actions/labs"
import AdminActionBar from "components/Common/AdminActionBar"
import StatusFilters from "components/Common/StatusFilters"
import LabsCardList from "components/Labs/LabsCardList"
import LabStatusFilters from "components/Labs/LabStatusFilters"
import AppContext from "contexts/App"
import LabsContext from "contexts/Labs"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useContext, useEffect, useState } from "react"
import { useQuery } from "react-query"
import Router from "router"

import useStyles from "./styles"

const List = ({ routes = [], ...rest }) => {
  const classes = useStyles()

  const { openDialog, setPageTitle, setPageSubTitle } = useContext(AppContext)
  const { createLabMutation } = useContext(LabsContext)
  //set serach value
  const [searchValue, setSearchValue] = useState("")
  //set sort data
  const [sort, setSort] = useState({
    name: "",
    type: "",
    direction: "",
    icon: "",
  })

  const filterOptions = [
    { id: 1, name: "Name" },
    { id: 2, name: "Size" },
    { id: 3, name: "Type" },
  ]

  const { data: labs, isLoading: labsLoading } = useQuery("labs", listLabs)

  console.log(labs)

  //set lesson title and subtitle
  useEffect(() => {
    setPageTitle("Labs")
    setPageSubTitle(labs?.length)
  }, [labs])

  //reusable dialog to create lessons, courses, clientes and instructor
  //pass a title, subtitle, type (for example course) a create mutation func and refecth data
  const openCreateDialog = () => {
    let data = {
      title: "New Lab",
      subtitle: "Create a new Lab",
      type: "lab",
      create: createLabMutation,
    }
    openDialog("createCourseLessonDialog", data)
  }
  //set lessons status
  const [statusState, setStatusState] = useState("locked")
  //set the length of each type of lesson
  const [statusNumber, setStatusNumber] = useState({
    locked: null,
    unlocked: null,
  })

  return (
    <Page>
      <AdminActionBar
        buttonName="New Lab"
        buttonFunc={openCreateDialog}
        sortOptions={filterOptions}
        sortState={sort}
        setSortState={setSort}
        setFilterState={setSearchValue}
        searchText="Search labs"
      />
      {labsLoading ? (
        <LinearProgress color="primary" />
      ) : (
        <Box display="flex" height="100%" className={classes.container}>
          <Box className={classes.leftContainer}>
            <LabStatusFilters
              setStatusState={setStatusState}
              statusState={statusState}
              statusNumber={statusNumber}
            />
          </Box>

          <Box className={classes.rightContainer}>
            <LabsCardList
              labs={labs}
              searchValue={searchValue}
              sort={sort}
              openCreateDialog={openCreateDialog}
              statusState={statusState}
              setStatusNumber={setStatusNumber}
            />
          </Box>
        </Box>
      )}
      <Router routes={routes} {...rest} />
    </Page>
  )
}

List.propTypes = {
  routes: PropTypes.array,
}

export default List
