import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  container: {
    marginLeft: theme.spacing(2),
    maxHeight: "calc(100% - 70px)",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  tableWrapper: {
    width: "100%",
    paddingRight: theme.spacing(2),
  },
}))

export default styles
