import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { AvatarGroup } from "@material-ui/lab"
import AppContext from "contexts/App"
import PropTypes from "prop-types"
import { useContext } from "react"

import Avatar from "../Avatar"
import useStyles from "./styles"

const InstructorGroup = ({ instructorsArray }) => {
  const classes = useStyles()
  const theme = useTheme()

  const { openDialog } = useContext(AppContext)

  const xsDown = useMediaQuery(theme.breakpoints.down("xs"))
  const maxAvatars = xsDown ? 3 : 4

  const handleView = (instructor) => {
    openDialog("viewInstructorDialog", { instructor })
  }

  const handleOpenList = (e) => {
    const instructorIdArr = instructorsArray.map((i) => i.id)
    if (e.target.classList.contains("MuiAvatar-colorDefault")) {
      openDialog("instructorListDialog", { instructorIdArr, view: true })
    }
  }
  return (
    <AvatarGroup
      max={maxAvatars}
      classes={{ avatar: classes.avatars }}
      onClick={(e) => handleOpenList(e)}
    >
      {instructorsArray?.map((instructor) => {
        return (
          <Avatar
            key={instructor.id}
            user={instructor}
            size={40}
            style={{ border: "1px solid #092958" }}
            onClick={() => handleView(instructor)}
            tooltipEnabled={true}
          />
        )
      })}
    </AvatarGroup>
  )
}

InstructorGroup.propTypes = {
  instructorsArray: PropTypes.array,
}

export default InstructorGroup
