import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  leftContainer: {
    width: "15%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  rightContainer: {
    width: "85%",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      width: "100%",
      height: "100%",
      overflow: "scroll",
    },
  },
}))

export default styles
