const equalAndSameOrder = (array1, arra2) => {
  var is_same =
    array1.length == arra2.length &&
    array1.every(function (element, index) {
      return element === arra2[index]
    })

  return is_same
}

export default equalAndSameOrder
