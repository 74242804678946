import LoaderButton from "@bit/c_t.components.loader-button"
import {
  Box,
  Button,
  Collapse,
  FilledInput,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import LaunchIcon from "@material-ui/icons/Launch"
import classnames from "clsx"
import { titleize } from "inflection"
import { orderBy } from "lodash"
import { observer } from "mobx-react"
import PropTypes from "prop-types"
import { useEffect, useMemo, useRef, useState } from "react"

import SetModel from "../models/Set"
import FillInTheBlank from "./FillInTheBlank"
import SingleChoice from "./SingleChoice"
import SingleChoiceSentiment from "./SingleChoiceSentiment"
import useStyles from "./styles"
import TrueFalse from "./TrueFalse"
const ContentElements = {
  multiple_choice: SingleChoice,
  multiple_choice_sentiment: SingleChoiceSentiment,
  true_false: TrueFalse,
  fill_blank: FillInTheBlank,
}

function getCaretCharacterOffsetWithin(element) {
  var caretOffset = 0
  if (typeof window.getSelection != "undefined") {
    var range = window.getSelection().getRangeAt(0)
    var preCaretRange = range.cloneRange()
    preCaretRange.selectNodeContents(element)
    preCaretRange.setEnd(range.endContainer, range.endOffset)
    caretOffset = preCaretRange.toString().length
  } else if (
    typeof document.selection != "undefined" &&
    document.selection.type != "Control"
  ) {
    var textRange = document.selection.createRange()
    var preCaretTextRange = document.body.createTextRange()
    preCaretTextRange.moveToElementText(element)
    preCaretTextRange.setEndPoint("EndToEnd", textRange)
    caretOffset = preCaretTextRange.text.length
  }
  return caretOffset
}

function pasteHtmlAtCaret(html) {
  var sel, range
  if (window.getSelection) {
    // IE9 and non-IE
    sel = window.getSelection()
    if (sel.getRangeAt && sel.rangeCount) {
      range = sel.getRangeAt(0)
      range.deleteContents()

      // Range.createContextualFragment() would be useful here but is
      // non-standard and not supported in all browsers (IE9, for one)
      var el = document.createElement("div")
      el.innerHTML = html
      var frag = document.createDocumentFragment(),
        node,
        lastNode
      while ((node = el.firstChild)) {
        lastNode = frag.appendChild(node)
      }
      range.insertNode(frag)

      // Preserve the selection
      if (lastNode) {
        range = range.cloneRange()
        range.setStartAfter(lastNode)
        range.collapse(true)
        sel.removeAllRanges()
        sel.addRange(range)
      }
    }
  } else if (document.selection && document.selection.type != "Control") {
    // IE < 9
    document.selection.createRange().pasteHTML(html)
  }
}

const Content = ({
  question,
  showingErrors,
  lockdown,
  usesAttempts = true,
  isSentiment = false,
}) => {
  const contentEditableRef = useRef()

  const classes = useStyles()

  const [isEdit, setIsEdit] = useState(false)

  const handleEdit = () => {
    setIsEdit(true)
  }

  const handleSave = () => {
    if (!question.isValid) {
      setShowErrors(true)
    } else {
      setShowErrors(false)
      setIsEdit(false)
      //specific below to fill-in-blank questions
      const elem = document.getElementById(`content-editable-${question.id}`)
      if (elem) {
        setTemplatedString(elem.innerHTML)
      }
    }
  }

  let Component = null
  if (isSentiment) {
    Component = ContentElements[`${question.type}_sentiment`] || null
  } else if (ContentElements[question.type]) {
    Component = ContentElements[question.type]
  }

  const questionType = useMemo(() => {
    if (question?.type === "true_false") {
      return "True / False"
    }
    return titleize(question?.type)
  }, [question?.type])

  // index is setting to indexed by entire string
  const getTokenElement = (token, count) => {
    return `<span contenteditable="false" data-token="${token
      .replace("{:", "")
      .replace(":}", "")}" class="${
      classes.fillInTheBlankToken
    }">[blank-${count}]</span>`
  }

  const [templatedString, setTemplatedString] = useState("")

  const previousBlanks = useRef()

  useEffect(() => {
    if (question.type === "fill_blank") {
      const templateRegexMatches = question?.name?.match(
        /([\w\d\s]+)|({:[\d\w-]+:})/g
      )

      setTemplatedString(
        templateRegexMatches
          .map((token) => {
            if (token.indexOf("{:") > -1) {
              const parsedToken = token.replace("{:", "").replace(":}", "")
              const index = question
                .toJSON()
                .sets.findIndex((x) => x.id + "" === parsedToken + "")
              return getTokenElement(token, index + 1)
            }
            return token
          })
          .join("")
      )
    } else {
      return ""
    }
  }, [])

  useEffect(() => {
    previousBlanks.current = document.querySelectorAll(
      `.${classes.contentEditable} .${classes.fillInTheBlankToken}`
    )
  }, [templatedString, classes, isEdit])

  const handleFillInTheBlankChange = (e) => {
    const blanks = document.querySelectorAll(
      `.${classes.contentEditable} .${classes.fillInTheBlankToken}`
    )
    if (blanks.length < previousBlanks.current?.length) {
      const previousBlanksArray = Array.from(previousBlanks.current)
      const currentBlanksArray = Array.from(blanks)
      const removedBlank = previousBlanksArray.filter((n) => {
        return currentBlanksArray.indexOf(n) === -1
      })
      if (removedBlank?.[0]) {
        try {
          question.removeSet(
            question.sets.find(
              (s) => "" + s.id === "" + removedBlank[0].dataset.token
            )
          )
          question.sets.forEach((s, i) => {
            const el = document.querySelectorAll(`[data-token="${s.id}"]`)
            el[0].innerHTML = `[blank-${i + 1}]`
          })
        } catch (e) {
          console.error(e)
        }
      }
    }
    previousBlanks.current = blanks
    const fakeDocument = document.createElement("div")
    fakeDocument.innerHTML = e.target.innerHTML
    const elements = fakeDocument
      .querySelectorAll("[data-token]")
      .forEach((n) => {
        const tokenValue = n.dataset.token
        n.replaceWith(`{:${tokenValue}:}`) //flipping id to {:id:}
      })
    question.updateName(fakeDocument.innerHTML.replaceAll("&nbsp;", " "))
  }

  const [showErrors, setShowErrors] = useState(false)

  useEffect(() => {
    if (showingErrors) {
      setShowErrors(true)
    }
  }, [showingErrors])

  const handleAddNewSet = () => {
    const newSet = SetModel.create()
    question.addSet(newSet)
    const newSpan = getTokenElement(newSet.id, question.sets.length)
    document.getElementById(`content-editable-${question.id}`).focus()
    pasteHtmlAtCaret(newSpan)
    // trigger onInput
    var event = new Event("input", { bubbles: true, cancelable: true })
    var el = document.getElementById(`content-editable-${question.id}`)
    el.dispatchEvent(event)
  }

  console.log("showingErrors", showingErrors)
  console.log("showErrors isValid", question?.isValid)

  return (
    <Collapse
      id={`question-id-${question.id}`}
      in={isEdit || lockdown}
      collapsedHeight={70}
      className={classnames(classes.collapse, {
        [classes.isError]: showingErrors && !question?.isValid,
      })}
      classes={{
        entered: classnames(classes.isEdit, {
          [classes.lockdown]: lockdown,
        }),
      }}
    >
      <Box
        className={classes.content}
        // style={!question?.isValid ? { border: "1px solid red" } : null}
      >
        <Box className={classes.inputBox}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <InputLabel className={classes.inputLabel} htmlFor="name">
              {questionType}
            </InputLabel>
            <Box
              className={classes.buttonsBox}
              style={lockdown ? { visibility: "hidden" } : null}
            >
              <Button
                size="small"
                className={classnames(classes.button, classes.removeButton)}
                onClick={() => question.remove()}
                disableRipple
              >
                Remove
              </Button>
              {isEdit && (
                <LoaderButton
                  size="small"
                  classoverrides={{ button: { root: classes.button } }}
                  color="primary"
                  onClick={handleSave}
                  disableRipple
                >
                  Save
                </LoaderButton>
              )}
              {!isEdit && (
                <Button
                  size="small"
                  className={classes.button}
                  color="primary"
                  onClick={handleEdit}
                  disableRipple
                >
                  Edit
                </Button>
              )}
              {!isEdit && (
                <IconButton
                  onClick={() => setIsEdit(!isEdit)}
                  size="small"
                  style={{ marginBottom: "20px" }}
                >
                  <KeyboardArrowUpIcon />
                </IconButton>
              )}
              {isEdit && (
                <IconButton
                  onClick={() => setIsEdit(!isEdit)}
                  size="small"
                  style={{ marginBottom: "20px" }}
                >
                  <KeyboardArrowDownIcon />
                </IconButton>
              )}
            </Box>
          </Box>
          {isEdit && (
            <>
              {question.type === "fill_blank" && (
                <>
                  <div
                    id={`content-editable-${question.id}`}
                    contentEditable="true"
                    style={showErrors ? { border: "1px solid red" } : null}
                    className={classes.contentEditable}
                    onInput={handleFillInTheBlankChange}
                    dangerouslySetInnerHTML={{
                      __html: templatedString,
                    }}
                  />
                  <Button
                    onClick={handleAddNewSet}
                    color="secondary"
                    style={{ fontSize: "0.75rem", marginLeft: -1 }}
                    startIcon={<AddCircleIcon style={{ fontSize: 12 }} />}
                  >
                    Add Response
                  </Button>
                  {showErrors && (
                    <FormHelperText error>
                      {question.errors.sets}
                    </FormHelperText>
                  )}
                </>
              )}
              {question.type !== "fill_blank" && (
                <>
                  <FilledInput
                    fullWidth
                    classes={{ input: classes.input }}
                    id="name"
                    placeholder="Question title"
                    value={question.name}
                    onChange={(e) => question.updateName(e.target.value)}
                  />
                  {showErrors && (
                    <FormHelperText error>
                      {question?.errors?.name}
                    </FormHelperText>
                  )}
                </>
              )}
            </>
          )}
          {!isEdit && (
            <>
              {question.type === "fill_blank" && (
                <Typography
                  style={{ minHeight: 18 }}
                  dangerouslySetInnerHTML={{ __html: templatedString }}
                />
              )}
              {question.type !== "fill_blank" && (
                <>
                  {question.name && <Typography>{question.name}</Typography>}
                  {!question.name && (
                    <Typography style={{ color: "#aaa", fontStyle: "italic" }}>
                      Edit question title
                    </Typography>
                  )}
                </>
              )}
            </>
          )}
        </Box>
        <Component
          question={question}
          isEdit={isEdit}
          showErrors={showErrors}
        />
        {!isSentiment && (
          <Box className={classes.inputBox}>
            <InputLabel className={classes.inputLabel} htmlFor="name">
              Answer Explanation (optional)
            </InputLabel>
            {isEdit && (
              <FilledInput
                fullWidth
                classes={{ input: classes.input }}
                id="name"
                value={question.explanation}
                onChange={(e) => question.updateExplanation(e.target.value)}
                multiline
              />
            )}
            {!isEdit && (
              <Typography style={{ minHeight: 20 }}>
                {question.explanation}
              </Typography>
            )}
          </Box>
        )}
        {usesAttempts && (
          <Box display="flex" alignItems="flex-end">
            <InputLabel className={classes.inputLabel} htmlFor="name">
              Attempts
            </InputLabel>
            {!isEdit && (
              <Typography
                className={classnames(classes.inputLabel, classes.attemptsText)}
              >
                {question.attempts}
              </Typography>
            )}
            {isEdit && (
              <Select
                labelId="attempts-label"
                id="attempts"
                value={question.attempts}
                onChange={(e) => question.updateAttempts(e.target.value)}
                variant="outlined"
                size="small"
                IconComponent={ExpandMoreIcon}
                classes={{
                  select: classes.select,
                  icon: classes.selectIcon,
                }}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
              </Select>
            )}
          </Box>
        )}
      </Box>
    </Collapse>
  )
}

Content.propTypes = {
  question: PropTypes.object,
  showingErrors: PropTypes.bool,
  lockdown: PropTypes.bool,
  usesAttempts: PropTypes.bool,
  isSentiment: PropTypes.bool,
}

export default observer(Content)
