import { createClient } from "actions/clients"
import { listClients } from "actions/clients"
import ClientsCardList from "components/Clients/ClientsCardList"
import AdminActionBar from "components/Common/AdminActionBar"
import AppContext from "contexts/App"
import { ClientsContainer } from "contexts/Clients"
import EditClientDialog from "dialogs/EditClientDialog"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useContext, useEffect, useState } from "react"
import { useMutation, useQuery } from "react-query"
import Router from "router"
import useStyles from "./styles"

const Clients = ({ routes = [], ...rest }) => {
  const classes = useStyles()

  const { openDialog, setPageTitle, setPageSubTitle } = useContext(AppContext)
  const createClientMutation = useMutation(createClient)
  const { data: clients, refetch: refetchClients } = useQuery("clients", () =>
    listClients()
  )

  const [searchValue, setSearchValue] = useState("")

  // reference sortType and sortHelper for understanding of this object
  const [sort, setSort] = useState({
    name: "",
    type: "",
    direction: "",
    icon: "",
  })

  // sortation options - name only at this point.
  const filterOptions = [{ id: 1, name: "name" }]

  //set title and subtitle
  useEffect(() => {
    setPageTitle("Clients")
    setPageSubTitle(clients?.length)
  }, [clients])

  // reusable dialog to create lessons, courses, clientes and instructor
  // pass a title, subtitle, type (for example course) a create mutation func and refecth data
  const openCreateDialog = () => {
    let data = {
      title: "New Client",
      subtitle: "Create a new Client",
      type: "client",
      create: createClientMutation,
      listData: clients,
    }
    openDialog("createClientDialog", data)
  }

  return (
    <Page>
      <ClientsContainer>
        <AdminActionBar
          buttonName="New Client"
          buttonFunc={openCreateDialog}
          sortOptions={filterOptions}
          sortState={sort}
          setSortState={setSort}
          setFilterState={setSearchValue}
          searchText="Search clients"
        />
        <ClientsCardList
          searchValue={searchValue}
          sort={sort}
          openCreateDialog={openCreateDialog}
        />
        <EditClientDialog />
        <Router routes={routes} {...rest} />
      </ClientsContainer>
    </Page>
  )
}

Clients.propTypes = {
  routes: PropTypes.array,
}

export default Clients
