import { Box, LinearProgress } from "@material-ui/core"
import PlayerWithControls from "components/Common/PlayerWithControls"
import LearningCuesList from "components/Lessons/LearningCues/LearningCuesList"
import LearningCuesTimeline from "components/Lessons/LearningCues/LearningCuesTimeline"
import LearningCuesContext from "contexts/LearningCues"
import LessonContext from "contexts/Lesson"
import { VideoContainer } from "contexts/Video"
import useThumbnail from "hooks/useThumbnail"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useContext, useEffect } from "react"
import { Redirect } from "react-router"

import useStyles from "./styles"

const Cues = ({ routes = [], ...rest }) => {
  const classes = useStyles()

  const { lesson, videoUrl } = useContext(LessonContext)
  const { learningCuesQuery, resetLearningCues } = useContext(
    LearningCuesContext
  )

  const lessonThumbnail = useThumbnail(lesson?.thumbnail)

  // useEffect(() => {
  //   return () => {
  //     resetLearningCues()
  //   }
  // }, [])

  const lockdown =
    lesson?.published === 1 ||
    (lesson?.published === 0 &&
      lesson?.has_been_published === 1 &&
      lesson?.archived === 0)

  if (learningCuesQuery.isLoading) {
    return <LinearProgress color="primary" />
  }

  return (
    <Page>
      {lesson?.video_id !== null && (
        <VideoContainer lesson={lesson}>
          {({ ReactPlayerProps }) => (
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              overflow="hidden"
            >
              <LearningCuesTimeline lockdown={lockdown} />
              <Box className={classes.bottomSection}>
                <Box className={classes.videoBox}>
                  <Box width="100%">
                    {/* fixes video box size due to flex styles */}
                    {videoUrl.isFetched && videoUrl.isSuccess && (
                      <PlayerWithControls
                        url={videoUrl?.data}
                        poster={lessonThumbnail}
                        ReactPlayerProps={ReactPlayerProps}
                        duration={lesson?.duration || 0}
                      />
                    )}
                  </Box>
                </Box>
                <LearningCuesList lockdown={lockdown} />
              </Box>
            </Box>
          )}
        </VideoContainer>
      )}
      {lesson?.video_id === null && <Redirect to={`/lessons/${lesson.id}`} />}
    </Page>
  )
}

Cues.propTypes = {
  routes: PropTypes.array,
}

export default Cues
