import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "left",
      marginTop: theme.spacing(2),
    },
  },
  titleContainer: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
  },
  instructorsContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      marginBottom: theme.spacing(2),
    },
  },
  buttonLabel: {
    color: theme.palette.primary.main,
  },
  nameText: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    wordBreak: "break-word",
  },
  paperRow: {
    border: "1px solid #E2E2E2",
    borderRadius: "8px",
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))

export default styles
