import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  controlsBox: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2,
    color: "#fff",
    background: "rgba(0,0,0,0.3)",
  },
  progressBox: {
    flexGrow: 1,
    cursor: "pointer",
    padding: theme.spacing(1, 0),
  },
  linearProgress: {
    backgroundColor: "rgba(255,255,255,0.24)",
  },
  barColorPrimary: {
    backgroundColor: "#fff",
  },
  bar1Determinate: {
    transition: "none",
  },
}))

export default styles
