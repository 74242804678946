import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  container: {
    display: "flex",
    marginTop: "34px",
    marginLeft: "28px",
    overflow: "scroll",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
      flexWrap: "wrap",
      marginRight: theme.spacing(2),
      overflow: "scroll",
    },
  },
  leftContainer: {
    height: "80vh",
    width: "70%",
    overflow: "scroll",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      height: "70vh",
      width: "100%",
      order: 2,
    },
  },
  rightContainer: {
    marginTop: "51px",
    marginLeft: theme.spacing(3),
    width: "30%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      order: 1,
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(0),
    },
  },
}))

export default styles
