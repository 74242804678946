import {
  Box,
  Button,
  Divider,
  Menu,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import StarOutlineIcon from "@material-ui/icons/StarOutline"
import copy from "assets/Icons/feather-copy.svg"
import CourseLessonMenuItem from "components/Common/CourseLessonMenuItem"
import DraftPublishedBox from "components/Common/DraftPublishedBox"
import HoverText from "components/Common/HoverText"
import App from "contexts/App"
import Course from "contexts/Course"
import PathContext from "contexts/Path"
import UsersContext from "contexts/Users"
import useHeaderBackground from "hooks/useHeaderBackground"
import PropTypes from "prop-types"
import { React, useEffect, useState } from "react"
import { useContext } from "react"
import { useQueryClient } from "react-query"
import { useHistory } from "react-router"

import useStyles from "./styles"

const PathHeader = () => {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const queryClient = useQueryClient()

  const mdDown = useMediaQuery(theme.breakpoints.down("md"))
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))

  const nameSize = smDown ? 30 : mdDown ? 40 : 50

  const [adminName, setAdminName] = useState("")

  const {
    debouncedDetails,
    path,
    anchorEl,
    setAnchorEl,
    handleUpdate,
    saveLoading,
    publishLoading,
    unpublishLoading,
    archiveLoading,
    unarchiveLoading,
    publishPathFunc,
    unpublishPathFunc,
    archivePathFunc,
    unarchivePathFunc,
  } = useContext(PathContext)
  const { admins } = useContext(UsersContext)
  const headerSrc = useHeaderBackground(path?.thumbnail)

  const { openSnackBar, openDialog } = useContext(App)

  //open menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  //set admin name using the id
  useEffect(() => {
    if (admins) {
      setAdminName(admins?.find((x) => x.id === path?.updated_admin_id)?.name)
    }
  }, [admins])

  const analytics = history.location.pathname.includes("analytics")

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-evenly"
      position="relative"
      className={classes.headerContainer}
    >
      {headerSrc && (
        <img
          className={classes.backgroundImage}
          src={headerSrc}
          alt=""
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            objectFit: "cover",
            zIndex: -1,
            opacity: 0.2,
          }}
        />
      )}

      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        <Box marginLeft={2}>
          <HoverText
            data={
              debouncedDetails?.name !== ""
                ? debouncedDetails?.name
                : path?.name
            }
            variant="h1"
            className={classes.title}
            size={nameSize}
          />
        </Box>
        <Box marginRight={2} className={classes.saveContainer}>
          {!analytics && (
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={handleClick}
              className={classes.saveBtn}
            >
              Save
              <ExpandMoreIcon />
            </Button>
          )}
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            classes={{ paper: classes.menu }}
          >
            {path.archived === 1 ? (
              <div>
                <CourseLessonMenuItem
                  func={handleUpdate}
                  loadingState={saveLoading}
                  text="SAVE"
                />
                <Divider
                  style={{
                    backgroundColor: "#FFF",
                    opacity: "0.2",
                    margin: "3px",
                  }}
                />
                <CourseLessonMenuItem
                  func={unarchivePathFunc}
                  loadingState={unarchiveLoading}
                  text="SAVE & UNARCHIVE"
                />
              </div>
            ) : null}
            {path?.published === 0 && path.archived === 0 ? (
              <div>
                <CourseLessonMenuItem
                  func={handleUpdate}
                  loadingState={saveLoading && !publishLoading}
                  text="SAVE"
                />
                <Divider
                  style={{
                    backgroundColor: "#FFF",
                    opacity: "0.2",
                    margin: "3px",
                  }}
                />
                {!path?.has_been_published ? (
                  <>
                    <CourseLessonMenuItem
                      func={publishPathFunc}
                      loadingState={publishLoading}
                      text="SAVE & PUBLISH"
                    />
                    <Divider
                      style={{
                        backgroundColor: "#FFF",
                        opacity: "0.2",
                        margin: "3px",
                      }}
                    />
                  </>
                ) : null}

                <CourseLessonMenuItem
                  func={archivePathFunc}
                  loadingState={archiveLoading}
                  text="SAVE & ARCHIVE"
                />
              </div>
            ) : null}
            {path?.published === 1 ? (
              <div>
                <CourseLessonMenuItem
                  func={handleUpdate}
                  loadingState={saveLoading}
                  text="SAVE"
                />
                <Divider
                  style={{
                    backgroundColor: "#FFF",
                    opacity: "0.2",
                    margin: "3px",
                  }}
                />
                <CourseLessonMenuItem
                  func={unpublishPathFunc}
                  loadingState={unpublishLoading}
                  text="SAVE & UNPUBLISH"
                />
              </div>
            ) : null}
          </Menu>
        </Box>
      </Box>
      <Box marginLeft={2} display="flex" alignItems="center" flexWrap="wrap">
        <DraftPublishedBox
          published={path.published}
          has_been_published={path.has_been_published}
          archived={path.archived}
          size={"100px"}
        />
        <Box className={classes.lastUpdated}>
          <Typography variant="subtitle2" className={classes.updated}>
            Last Updated {path?.updated?.format("M/D/YYYY")}
          </Typography>
          <Typography variant="subtitle2" className={classes.creator}>
            By {" " + adminName}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

PathHeader.propTypes = {
  courseId: PropTypes.number,
}

export default PathHeader
