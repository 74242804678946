import { listTags } from "actions/lessons"
import CourseLessonContentContainer from "components/Common/CourseLessonContentContainer"
import LessonContent from "components/Lessons/LessonContent"
import LessonDetails from "components/Lessons/LessonDetails"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useQuery } from "react-query"
import Router from "router"

const Content = ({ routes = [], ...rest }) => {
  const { data: tags } = useQuery("tags", () => listTags())

  return (
    <Page>
      <CourseLessonContentContainer
        leftComponent={<LessonDetails tags={tags} />}
        rightComponent={<LessonContent />}
      />
      <Router routes={routes} {...rest} />
    </Page>
  )
}

Content.propTypes = {
  routes: PropTypes.array,
}

export default Content
