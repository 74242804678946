import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  cardContainer: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      marginRight: theme.spacing(3),
    },
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "64px",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      height: "96px",
      flexWrap: "wrap",
      order: 0,
    },
    [theme.breakpoints.down("xs")]: {
      width: "85%",
      height: "130px",
    },
  },
  firstLineWrapper: {
    display: "flex",
    width: "65%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
  },
  titleContanier: {
    width: "45%",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  linkContainer: {
    width: "55%",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  switchContainer: {
    width: "27%",
    [theme.breakpoints.down("sm")]: {
      width: "35%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  checkedSwitch: {
    cursor: "default",
    "&&:hover": {
      backgroundColor: "transparent",
      cursor: "default",
    },
  },
  switchBase: {
    color: "#D5D5D5",
    cursor: "default",
    "&&:hover": {
      backgroundColor: "transparent",
      cursor: "default",
    },
  },
  switchTrack: {
    cursor: "default",
    backgroundColor: "#D5D5D5",
  },
  buttonContainer: {
    width: "15%",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "center",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  dragHandle: {
    marginRight: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(0.5),
      order: 10,
    },
  },
}))

export default styles
