import LoaderButton from "@bit/c_t.components.loader-button"
import { Box, Button, TextField, Typography } from "@material-ui/core"
import { forgotPassword } from "actions/users"
import App from "contexts/App"
import { Form, Formik } from "formik"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useMutation } from "react-query"
import * as Yup from "yup"
import useStyles from "./styles"

const ForgotPassword = ({ setLoginStep }) => {
  const classes = useStyles()

  const { openSnackBar } = useContext(App)
  const forgetPasswordSchema = Yup.object().shape({
    email: Yup.string().email().required("Email address is required"),
  })
  //come back to loginForm compoment
  const backToLogin = () => {
    setLoginStep(0)
  }

  const forgotPasswordMutation = useMutation(forgotPassword)

  const onSubmit = (values, actions) => {
    forgotPasswordMutation.mutate(values, {
      onSuccess: () => {
        setLoginStep(2)
        openSnackBar({ message: "A link was sent to your Email" })
      },
      onSettled: () => actions.setSubmitting(false),
    })
  }

  return (
    <Formik
      initialValues={{ email: "" }}
      onSubmit={onSubmit}
      validationSchema={forgetPasswordSchema}
    >
      {({
        handleChange,
        values,
        errors,
        touched,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <div className={classes.inputContainer}>
          <Form>
            <Typography className={classes.explanation} variant="subtitle2">
              Please enter email associated with your account
            </Typography>
            <Typography color="secondary" className={classes.title}>
              Email
            </Typography>
            <TextField
              type="email"
              name="email"
              id="email"
              value={values.email}
              // placeholder="Email"
              // label="Email"
              onChange={handleChange}
              onBlur={handleBlur}
              className={classes.textField}
              helperText={touched.email && errors.email ? errors.email : " "}
              InputProps={{
                classes: {
                  root: classes.inputBackgroundColor,
                },
              }}
              error={touched.email && errors.email}
              variant="outlined"
              size="small"
              fullWidth
              FormHelperTextProps={{
                className: classes.formHelperText,
              }}
            />

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginTop="30px"
            >
              <Button
                variant="outlined"
                color="secondary"
                style={{ height: "40px" }}
                onClick={backToLogin}
              >
                Cancel
              </Button>
              <LoaderButton
                type="submit"
                classoverrides={{
                  wrapper: classes.button,
                  button: {
                    root: classes.buttonRoot,
                  },
                }}
                variant="contained"
                color="secondary"
                working={isSubmitting}
                size="small"
              >
                reset password
              </LoaderButton>
            </Box>
          </Form>
        </div>
      )}
    </Formik>
  )
}

ForgotPassword.propTypes = {
  setLoginStep: PropTypes.func,
}
export default ForgotPassword
