import LoaderButton from "@bit/c_t.components.loader-button"
import {
  Box,
  Button,
  Divider,
  Menu,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import copy from "assets/Icons/feather-copy.svg"
import CourseLessonMenuItem from "components/Common/CourseLessonMenuItem"
import HoverText from "components/Common/HoverText"
import AppContext from "contexts/App"
import LabContext from "contexts/Lab"
import UsersContext from "contexts/Users"
import dayjs from "dayjs"
import React, { useContext, useEffect, useState } from "react"

import StatusBox from "../StatusBox"
import useStyles from "./styles"

const LabHeader = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { openSnackBar, openDialog } = useContext(AppContext)
  const [adminName, setAdminName] = useState("")

  const mdDown = useMediaQuery(theme.breakpoints.down("md"))
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))

  const nameSize = smDown ? 30 : mdDown ? 40 : 50

  const {
    debouncedDetails,
    lab,
    setAnchorEl,
    anchorEl,
    handleUpdate,
    saveLoading,
    copyLessonMutation,
    lockLabFunc,
    lockLoading,
    unlockLabFunc,
    unlockLoading,
  } = useContext(LabContext)

  const { admins } = useContext(UsersContext)

  //set admin name using the id
  useEffect(() => {
    if (admins) {
      setAdminName(admins?.find((x) => x.id === lab?.updated_admin_id)?.name)
    }
  }, [admins, lab?.updated_admin_id])

  //open menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCopy = () => {
    openDialog("createCopyDialog", {
      lab,
      type: "lab",
      copyMutation: copyLessonMutation,
    })
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-evenly"
      className={classes.headerContainer}
    >
      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        <Box marginLeft={2}>
          <HoverText
            data={
              debouncedDetails?.name !== "" ? debouncedDetails?.name : lab?.name
            }
            variant="h1"
            size={nameSize}
            className={classes.title}
          />
        </Box>
        <Box marginRight={2} marginLeft={2} className={classes.saveContainer}>
          {/* <Tooltip title="Create Copy" aria-label="create copy">
            <Button
              variant="outlined"
              size="large"
              className={classes.copyBtn}
              classes={{ root: classes.copyButton }}
              onClick={handleCopy}
            >
              <img src={copy} alt="" />
            </Button>
          </Tooltip> */}
          {lab.locked && (
            <LoaderButton
              variant="contained"
              size="small"
              color="primary"
              onClick={unlockLabFunc}
              working={unlockLoading}
            >
              Unlock
            </LoaderButton>
          )}
          {!lab.locked && (
            <>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={handleClick}
                className={classes.saveBtn}
              >
                Save
                <ExpandMoreIcon />
              </Button>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                classes={{ paper: classes.menu }}
              >
                <div>
                  <CourseLessonMenuItem
                    func={handleUpdate}
                    loadingState={saveLoading}
                    text="SAVE"
                  />
                  <Divider
                    style={{
                      backgroundColor: "#FFF",
                      opacity: "0.2",
                      margin: "3px",
                    }}
                  />
                  <CourseLessonMenuItem
                    func={lockLabFunc}
                    loadingState={lockLoading}
                    text="SAVE & LOCK"
                  />
                </div>
              </Menu>
            </>
          )}
        </Box>
      </Box>
      <Box
        marginLeft={2}
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        className={classes.detailBox}
      >
        <StatusBox locked={lab?.locked} size={"100px"} />
        <Box className={classes.lastUpdated}>
          <Typography variant="subtitle2" className={classes.updated}>
            Last Updated {dayjs(lab?.updated)?.format("M/D/YYYY")}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

LabHeader.propTypes = {}

export default LabHeader
