import { Box, LinearProgress } from "@material-ui/core"
import CertificationCardList from "components/Certifications/CertificationCardList"
import AdminActionBar from "components/Common/AdminActionBar"
import StatusFilters from "components/Common/StatusFilters"
import LabStatusFilters from "components/Labs/LabStatusFilters"
import AppContext from "contexts/App"
import CertificationsContext from "contexts/Certifications"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useContext, useEffect, useState } from "react"
import Router from "router"

import useStyles from "./styles"

const CertificationsList = ({ routes = [], ...rest }) => {
  const classes = useStyles()
  const { openDialog, setPageSubTitle, setPageTitle } = useContext(AppContext)
  const {
    certifications,
    certifcationsLoading,
    createCertMutation,
  } = useContext(CertificationsContext)

  const filterOptions = [
    { id: 1, name: "Name" },
    { id: 2, name: "Learning Paths" },
    { id: 3, name: "Last Updated" },
  ]

  //set serach value
  const [searchValue, setSearchValue] = useState("")

  //set sort data
  const [sort, setSort] = useState({
    name: "",
    type: "",
    direction: "",
    icon: "",
  })

  //Certification Status
  const [statusState, setStatusState] = useState("published")

  const [statusNumber, setStatusNumber] = useState({
    published: null,
    unpublished: null,
    draft: null,
  })

  //create certification dialog
  const openCreateDialog = () => {
    let data = {
      create: createCertMutation,
    }
    openDialog("createCertificationDialog", data)
  }

  //set learning paths title and subtitle
  useEffect(() => {
    setPageTitle("Certifications")
    setPageSubTitle(certifications?.length)
  }, [certifications])

  return (
    <Page>
      <AdminActionBar
        buttonName="New Certification"
        buttonFunc={openCreateDialog}
        sortOptions={filterOptions}
        sortState={sort}
        setSortState={setSort}
        setFilterState={setSearchValue}
        searchText="Search Certifications"
      />
      {certifcationsLoading ? (
        <LinearProgress color="primary" />
      ) : (
        <Box
          display="flex"
          height="calc(100% - 60px)"
          className={classes.container}
        >
          <Box className={classes.leftContainer}>
            <StatusFilters
              setStatusState={setStatusState}
              statusState={statusState}
              statusNumber={statusNumber}
              type="certifications"
            />
          </Box>
          <Box className={classes.rightContainer}>
            <CertificationCardList
              searchValue={searchValue}
              sort={sort}
              openCreateDialog={openCreateDialog}
              setStatusNumber={setStatusNumber}
              statusState={statusState}
              certifications={certifications}
            />
          </Box>
        </Box>
      )}

      <Router routes={routes} {...rest} />
    </Page>
  )
}

CertificationsList.propTypes = {
  routes: PropTypes.array,
}

export default CertificationsList
