import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  name: {
    fontSize: "1.313rem",
    fontWeight: "bold",
  },
  activeDate: {
    color: "#808080",
  },
}))

export default styles
