import { Box, Chip, TextField, Typography } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { listTags } from "actions/lessons"
import CounterHeader from "components/Common/CounterHeader"
import Tag from "components/Common/Tag"
import ThumbnailUpload from "components/Common/ThumbnailUpload"
import TheatreMode from "components/TheatreMode"
import AppContext from "contexts/App"
import InstructorsContext from "contexts/Instructors"
import LessonContext from "contexts/Lesson"
import PropTypes from "prop-types"
import { useContext, useEffect, useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import serialize from "store/serialize"
import { useDebounce } from "use-lodash-debounce"
import characterCounter from "utils/characterCounter"

import useStyles from "./styles"

const LessonDetails = ({ tags }) => {
  const classes = useStyles()
  const queryClient = useQueryClient()

  const {
    setDebouncedDetails,
    debouncedDetails,
    instructorIdArr,
    setInstructorIdArr,
    lesson,
    tagsContext,
    setTagsContext,
    updateLessonMutation,
    tryPublish,
    lengthError,
    lessonDetailsValues,
    setLessonDetailsValues,
    setInitialDetailsContext,
    instructorsArr,
    setInstructorsArr,
  } = useContext(LessonContext)

  const { openSnackBar, setPageSubTitle } = useContext(AppContext)
  const debouncedLocalValues = useDebounce(lessonDetailsValues, 300)
  const [tagAutocompleteValue, setTagAutocompleteValue] = useState("")
  const [tagTextFieldValue, setTagTextFieldValue] = useState("")
  const { instructors } = useContext(InstructorsContext)
  const [instructorSelectVal, setInstructorSelectVal] = useState("")

  //show errors if inputs are empty or more than allow characters
  const descriptionError =
    ((debouncedDetails?.description === "" ||
      debouncedDetails?.description?.length > 2000) &&
      tryPublish) ||
    (lengthError && debouncedDetails?.description?.length > 2000)

  const nameError =
    ((debouncedDetails?.name === "" || debouncedDetails?.name?.length > 50) &&
      tryPublish) ||
    (lengthError && debouncedDetails?.name?.length > 50)

  const setThumbnail = (uploadRes, loadingAction) => {
    updateLessonMutation.mutate(
      {
        thumbnail: JSON.stringify({
          url: uploadRes.data.url,
          secure_url: uploadRes.data.secure_url,
          originial_filename: uploadRes.data.original_filename,
          version: uploadRes.data.version,
          format: uploadRes.data.format,
          public_id: uploadRes.data.public_id,
        }),
      },
      {
        onSuccess: (res) => {
          serialize("lesson", res.data).then((serializedData) => {
            queryClient.setQueryData(["lesson", res.data.id], (oldData) => {
              //only update image to dont loss data that it is not save
              oldData.thumbnail = serializedData.thumbnail
              return oldData
            })
            setTimeout(() => {
              loadingAction(false)
            }, 400)
          })
        },
        onError: (err) => {
          loadingAction(false)
          openSnackBar({
            message: "Error Updating Video Cover",
          })
        },
      }
    )
  }

  //set lesson subtitle to empty string
  useEffect(() => {
    setPageSubTitle("")
  }, [])

  //delete tag from the tags array in lesson context
  const deleteTag = (name) => {
    setTagsContext(tagsContext?.filter((x) => x.name !== name))
  }

  //set at the begining tags to the data that come with lesson
  useEffect(() => {
    // setTagsContext(lesson?.tags)
    setInstructorsArr(lesson?.instructors)
  }, [lesson])

  //selects instructor card
  const handleSelect = (val) => {
    setInstructorsArr([...instructorsArr, val])
    setInstructorSelectVal(val)
  }

  //removes selected instructor card
  const handleRemove = (id) => {
    const newInstructorArr = instructorsArr.filter((x) => x.id !== id)
    setInstructorsArr(newInstructorArr)
  }

  //sets lesson details to context
  useEffect(() => {
    if (lesson?.id) {
      setLessonDetailsValues({
        name: lesson?.name,
        description: lesson?.description,
        date: lesson?.date,
        theater_view: lesson.theater_view,
      })
      setInitialDetailsContext({
        name: lesson.name,
        description: lesson.description || "",
        theater_view: lesson.theater_view,
      })
    }
  }, [lesson])

  // sets instructor array to context
  useEffect(() => {
    if (lesson?.id) {
      const idArr = instructorsArr.map((i) => ({
        instructor_id: i.instructor_id ? i.instructor_id : i.id,
      }))
      setInstructorIdArr(idArr)
    }
  }, [instructorsArr])

  useEffect(() => {
    setDebouncedDetails(lessonDetailsValues)
  }, [debouncedLocalValues])

  return (
    <Box className={classes.container}>
      <Typography variant="h3" color="primary" className={classes.title}>
        Lesson Details
      </Typography>
      <CounterHeader
        title="Lesson Name"
        value={debouncedDetails?.name}
        max={50}
      />

      <TextField
        value={lessonDetailsValues?.name || ""}
        onChange={(e) => {
          setLessonDetailsValues((oldState) => ({
            ...oldState,
            name: e.target.value,
            theater_view: !oldState,
          }))
        }}
        InputProps={{
          classes: {
            root: classes.inputBackgroundColor,
          },
        }}
        variant="filled"
        size="small"
        fullWidth
      />
      {nameError ? (
        <Typography className={classes.requiredText}>
          Name is required and it has to be less than 50 characters
        </Typography>
      ) : null}
      <Typography color="secondary" className={classes.inputLabel}>
        Instructors
      </Typography>
      <Autocomplete
        size="small"
        value={instructorSelectVal.name || ""}
        options={instructors || []}
        getOptionLabel={(option) => option.name || ""}
        getOptionDisabled={(option) =>
          !!instructorsArr?.find(
            (x) => x.instructor_id === option.id || x.id === option.id
          )
        }
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Add Instructors (auto complete)"
            variant="filled"
          />
        )}
        onChange={(_e, val) => {
          handleSelect(val)
        }}
        openOnFocus
        disableClearable
        classes={{
          inputRoot: classes.autocompleteRoot,
        }}
      />
      {instructorsArr?.length === 0 && tryPublish ? (
        <Typography className={classes.requiredText}>
          Instructors are required
        </Typography>
      ) : null}

      <Box marginTop={1} marginBottom={2} display="flex" flexWrap="wrap">
        {instructorsArr?.map((instructor, i) => {
          return (
            <Chip
              key={i}
              label={instructor.name}
              size="small"
              color="primary"
              onDelete={() => handleRemove(instructor.id)}
              style={{ marginRight: "8px", marginTop: "8px" }}
            />
          )
        })}
      </Box>
      <CounterHeader
        title="Lesson Description"
        value={debouncedDetails?.description}
        max={2000}
      />
      <TextField
        value={lessonDetailsValues.description || ""}
        multiline
        rows={10}
        onChange={(e) => {
          setLessonDetailsValues((oldState) => ({
            ...oldState,
            description: e.target.value,
          }))
        }}
        InputProps={{
          disableUnderline: true,
          classes: {
            root: classes.inputDescription,
          },
        }}
        size="small"
        fullWidth
      />
      {descriptionError ? (
        <Typography className={classes.requiredText}>
          Description is required and it has to be less than 2000 characters
        </Typography>
      ) : null}
      <Typography color="secondary" className={classes.inputLabel}>
        Search Terms
      </Typography>
      <Autocomplete
        id="tags-autocomplete"
        options={tags || []}
        renderInput={(params) => <TextField {...params} variant="filled" />}
        value={tagAutocompleteValue}
        onChange={(e, val) => setTagAutocompleteValue(val)}
        getOptionLabel={(option) => option.name || option}
        getOptionDisabled={(option) =>
          !!tagsContext.find((y) => y.name === option.name)
        }
        freeSolo
        openOnFocus
        onInputChange={(e, val) => setTagTextFieldValue(val)}
        inputValue={tagTextFieldValue}
        style={{
          width: "100%",
          backgroundColor: "#F4F4F4",
          borderRadius: "4px",
        }}
        onKeyPress={(e) => {
          if (
            e.key === "Enter" &&
            (tagTextFieldValue !== "" || tagAutocompleteValue !== "")
          ) {
            //check if we are using the same work with capital letters or spaces

            if (
              tags.find(
                (y) =>
                  y.name.replace(/\s+/g, "").toLowerCase() ===
                  tagTextFieldValue.replace(/\s+/g, "").toLowerCase()
              ) &&
              tagsContext.find(
                (y) =>
                  y.name.replace(/\s+/g, "").toLowerCase() ===
                  tagTextFieldValue.replace(/\s+/g, "").toLowerCase()
              )
            ) {
              setTagAutocompleteValue("")
              setTagTextFieldValue("")
            } else {
              const found = tags.find(
                (y) =>
                  y.name.replace(/\s+/g, "").toLowerCase() ===
                  tagTextFieldValue.replace(/\s+/g, "").toLowerCase()
              )
              if (found) {
                setTagsContext([...tagsContext, found])
                setTagAutocompleteValue("")
                setTagTextFieldValue("")
              } else {
                setTagAutocompleteValue("")
                setTagTextFieldValue("")
                setTagsContext([
                  ...tagsContext,
                  typeof tagAutocompleteValue === "object"
                    ? tagAutocompleteValue
                    : { name: tagAutocompleteValue },
                ])
              }
              return queryClient.setQueryData("tags", (oldData) => {
                return [
                  ...oldData,
                  typeof tagAutocompleteValue === "object"
                    ? tagAutocompleteValue
                    : { name: tagAutocompleteValue },
                ]
              })
            }
          }
        }}
        size="small"
        classes={{
          inputRoot: classes.autocompleteRoot,
        }}
      />
      {tagsContext?.length === 0 && tryPublish ? (
        <Typography className={classes.inputLabel}>
          Search Terms are required
        </Typography>
      ) : null}
      <Box display="flex" flexWrap="wrap" style={{}} marginBottom={3}>
        {tagsContext?.map((x, i) => {
          return (
            <Tag
              key={i}
              tagName={x.name || x}
              deleteTag={() => deleteTag(x.name)}
            />
          )
        })}
      </Box>

      {/* This is there the new switch will be added */}
      <Typography color="secondary" className={classes.inputLabel}>
        Theatre Mode
      </Typography>
      <TheatreMode />
      {/*  */}

      <Typography color="secondary" className={classes.inputLabel}>
        Video Cover
      </Typography>
      <Box width="50%">
        <ThumbnailUpload
          title="Add Video Cover here"
          cloudinaryUploadPreset={
            process.env.REACT_APP_CLOUDINARY_CLOUD_THUMBNAIL_UPLOAD
          }
          value={lesson?.thumbnail}
          onUpload={setThumbnail}
        />
      </Box>
      {Object.keys(lesson?.thumbnail)?.length === 0 && tryPublish ? (
        <Typography className={classes.requiredText}>
          Video Cover is required
        </Typography>
      ) : null}
      {/* <Typography color="secondary" className={classes.inputLabel}>
        Expiration Date
      </Typography>
      <TextField
        type="date"
        variant="outlined"
        className={classes.date}
        InputProps={{
          classes: {
            root: classes.inputBackgroundColor,
          },
        }}
        size="small"
        value={lessonDetailsValues.date}
        onChange={(e) =>
          setLessonDetailsValues((oldState) => ({
            ...oldState,
            date: e.target.value,
          }))
        }
      /> */}
    </Box>
  )
}

LessonDetails.propTypes = {
  tags: PropTypes.array,
}

export default LessonDetails
