import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  bankText: {
    fontWeight: "bold",
  },
  updatedText: {
    marginTop: theme.spacing(1),
    color: "#868686",
  },
  numQuestions: {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
  },
}))

export default styles
