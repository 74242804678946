import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  title: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      overflow: "hidden",
    },
  },
  avatars: {
    cursor: "pointer",
  },
  statusRow: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      width: "100px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
      width: "20%",
      display: "flex",
      justifyContent: "center",
    },
  },
  nameRow: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      display: "flex",
      justifyContent: "flex-start",
      width: "60%",
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "flex-start",
      marginTop: theme.spacing(2),
      width: "40%",
      maxHeight: "40px",
      overflow: "hidden",
    },
  },
  typeRow: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      width: "25%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "25%",
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
  },
  updatedRow: {
    [theme.breakpoints.down("sm")]: {
      width: "70%",
      alignItems: "center",
    },
    [theme.breakpoints.down("xs")]: {
      // marginTop: theme.spacing(2),
      width: "40%",
    },
  },
}))

export default styles
