import { addLearningPaths } from "actions/certifications"
import { publishCertification } from "actions/certifications"
import { unpublishCertification } from "actions/certifications"
import { updateCertification } from "actions/certifications"
import { updatedDiff } from "deep-object-diff"
import PropTypes from "prop-types"
import { createContext, useContext, useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import serialize from "store/serialize"
import differenceBetween from "utils/differenceBetween"
import equalAndSameOrder from "utils/equalAndSameOrder"

import AppContext from "./App"

const CertificationContext = createContext()
export default CertificationContext

export const CertificationContainer = ({ children, certification }) => {
  const queryClient = useQueryClient()
  const { openSnackBar, openDialog } = useContext(AppContext)
  //state to check if there is data unsaved
  const [unsavedState, setUnsavedState] = useState([])
  //open and close header menu
  const [anchorEl, setAnchorEl] = useState(null)
  //state to show the validation errors if the user try to publish
  const [tryPublish, setTryPublish] = useState(false)
  //state to show the validation errors for name, descritpion, headline
  const [lengthError, setLengthError] = useState(false)
  //or save as draft if is a draft
  const [saveLoading, setSaveLoading] = useState(false)
  // publish loading state
  const [publishLoading, setPublishLoading] = useState(false)
  // unpublish loading
  const [unpublishLoading, setUnpublishLoading] = useState(false)
  // published date values
  const date = certification?.publish_date ? certification?.publish_date : null
  const [dateValue, setDateValue] = useState(date)
  //add paths to a certification
  const [attachedPaths, setAttachedPaths] = useState([])
  const [initialAttachedPaths, setInitialAttachedPaths] = useState([])

  useEffect(() => {
    setUnsavedState([])
  }, [])

  //certification general information state with debounced
  const [debouncedDetails, setDebouncedDetails] = useState({
    name: "",
    description: "",
    headline: "",
  })

  //certification general information state
  const [certDetailsValues, setCertDetailsValues] = useState({
    name: "",
    description: "",
    headline: "",
    is_public: false,
  })

  // certification general information initial state
  const [initialDetailsContext, setInitialDetailsContext] = useState({
    name: "",
    description: "",
    headline: "",
    is_public: false,
  })

  //check unsave details data
  useEffect(() => {
    const unsavedData = updatedDiff(initialDetailsContext, certDetailsValues)
    if (
      Object.keys(unsavedData)?.length > 0 &&
      !unsavedState.includes("details")
    ) {
      setUnsavedState((oldState) => [...oldState, "details"])
    } else if (
      Object.keys(unsavedData)?.length === 0 &&
      unsavedState.includes("details")
    ) {
      setUnsavedState(
        unsavedState.filter((x) => {
          return x !== "details"
        })
      )
    }
  }, [certDetailsValues, JSON.stringify(certification)])

  // //check unsaved paths
  useEffect(() => {
    const initialValues = certification?.learning_paths?.map((p) =>
      Number(p.id)
    )

    const currentValues = attachedPaths.map((p) => Number(p.id))

    const order = equalAndSameOrder(initialValues, currentValues)
    const changes = differenceBetween(initialValues, currentValues)

    if ((!order || changes.length > 0) && !unsavedState.includes("paths")) {
      setUnsavedState((oldState) => [...oldState, "paths"])
    } else if (
      order &&
      changes.length === 0 &&
      unsavedState.includes("paths")
    ) {
      setUnsavedState(
        unsavedState.filter((x) => {
          return x !== "paths"
        })
      )
    }
  }, [attachedPaths, JSON.stringify(certification)])

  //if stament to know what we need to complete to lock
  const disabledSave =
    debouncedDetails?.name === "" ||
    debouncedDetails?.name?.length > 50 ||
    debouncedDetails?.description === "" ||
    debouncedDetails?.description?.length > 2000 ||
    debouncedDetails?.headline === "" ||
    debouncedDetails?.headline?.length > 100 ||
    Object.keys(certification?.thumbnail)?.length === 0

  //disabled the input depends the length when save
  let correctInputsLength =
    debouncedDetails?.name?.length <= 50 &&
    debouncedDetails?.description !== null &&
    debouncedDetails?.description?.length <= 2000 &&
    debouncedDetails?.headline.length <= 100
      ? true
      : false

  //Mutations
  const updateCertMutation = useMutation((data) =>
    updateCertification(data, certification.id)
  )

  const addPathMutation = useMutation((data) =>
    addLearningPaths(data, certification.id)
  )

  const publishCertMutation = useMutation(() =>
    publishCertification(certification?.id)
  )

  const unpublishCertMutation = useMutation(() =>
    unpublishCertification(certification?.id)
  )

  //close menu
  const handleClose = () => {
    setAnchorEl(null)
  }

  //update certification depends if is published or not
  const handleUpdate = () => {
    if (disabledSave && certification?.published === 1) {
      // if errors don't allow publish cert to save
      setTryPublish(true)
      handleClose()
    } else {
      //hide errors needed if the user click lock before and have errors to lock
      setTryPublish(false)
      if (!correctInputsLength) {
        setLengthError(true)
      } else {
        setSaveLoading(true)
        return attachLearningPaths()
          .then(updateDetails)
          .then(() => {
            openSnackBar({ message: "Certification Updated" })
            // TODO: might have to take this out
            setUnsavedState([])
            setSaveLoading(false)
            handleClose()
          })
          .catch((err) => {
            openSnackBar({
              message: "Error updating Certification",
            })
            setSaveLoading(false)
            handleClose()
          })
      }
    }
  }

  // update certification details
  const updateDetails = () => {
    return new Promise((resolve, reject) => {
      if (unsavedState.includes("details")) {
        updateCertMutation.mutate(debouncedDetails, {
          onSuccess: (res) => {
            serialize("certification", res.data).then((serializedData) => {
              return queryClient.setQueryData("certifications", (oldData) => {
                let newArray = []
                newArray = oldData.map((x) => {
                  if (x.id !== serializedData.id) {
                    return x
                  } else {
                    return serializedData
                  }
                })
                return [...newArray]
              })
            })
            //if is published send the updated message
            if (certification.published === 1) {
              openSnackBar({ message: "Certification Updated" })
            }
            resolve()
            openSnackBar({
              message: "Certification updated successfully",
            })
          },
          onError: (err) => {
            openSnackBar({
              message: "Error Updating Certification",
            })
            setSaveLoading(false)
            reject()
          },
        })
      } else {
        resolve()
      }
    })
  }

  // add paths to a certification
  const attachLearningPaths = () => {
    return new Promise((resolve, reject) => {
      if (unsavedState.includes("paths")) {
        const learningPaths = attachedPaths.map((path, i) => {
          if (path?.learning_path_id) {
            return path.learning_path_id
          } else {
            return path.id
          }
        })
        addPathMutation.mutate(learningPaths, {
          onSuccess: (res) => {
            serialize("certification", res.data).then((serializedData) => {
              return queryClient.setQueryData("certifications", (oldData) => {
                let newArray = []
                newArray = oldData.map((x) => {
                  if (x.id !== serializedData.id) {
                    return x
                  } else {
                    return serializedData
                  }
                })
                return [...newArray]
              })
            })
            resolve()
          },
          onError: (res) => {
            openSnackBar({
              message: "Error Updating Learning Path",
            })
            setSaveLoading(false)
            reject()
          },
        })
      } else {
        resolve()
      }
    })
  }

  // publish certification
  const publishCert = () => {
    if (disabledSave) {
      setTryPublish(true)
      handleClose()
    } else if (attachedPaths?.length === 0) {
      openSnackBar({
        message: "You must attach a learning path to publish a certification",
      })
    } else if (
      attachedPaths.length > 0 &&
      attachedPaths.filter((x) => x.published).length !== attachedPaths.length
    ) {
      openSnackBar({
        message:
          "You must attach only published learning paths to publish this certification",
      })
    } else {
      let data = {
        title: "Publish Confirmation",
        subtitle: "",
        type: "publish",
        confirmWord: "ship it",
      }
      handleClose()
      openDialog("confirmationTypingDialog", data).then(() => {
        handleUpdate().then(() => {
          setPublishLoading(true)
          publishCertMutation.mutate(
            {},
            {
              onSuccess: (res) => {
                serialize("certification", res.data).then((serializedData) => {
                  queryClient.setQueryData(
                    ["certification", res.data.id],
                    (oldData) => {
                      return serializedData
                    }
                  )
                  queryClient.setQueryData("certifications", (oldData) => {
                    let newArray = []
                    newArray = oldData.map((x) => {
                      if (x.id !== serializedData.id) {
                        return x
                      } else {
                        return serializedData
                      }
                    })
                    return [...newArray]
                  })
                  setUnsavedState([])
                  return
                })
                openSnackBar({
                  message: "Certification Published",
                })
                setPublishLoading(false)
                handleClose()
              },
              onError: (err) => {
                openSnackBar({
                  message: "Error Publishing Certification",
                })
                setPublishLoading(false)
                handleClose()
              },
            }
          )
        })
      })
    }
  }

  const unpublishCert = () => {
    let data = {
      title: "Unpublish Confirmation",
      subtitle: "",
      type: "unpublish",
      confirmWord: "unpublish",
    }
    handleClose()
    openDialog("confirmationTypingDialog", data).then(() => {
      handleUpdate().then(() => {
        setPublishLoading(true)
        unpublishCertMutation.mutate(
          {},
          {
            onSuccess: (res) => {
              serialize("certification", res.data).then((serializedData) => {
                queryClient.setQueryData(
                  ["certification", res.data.id],
                  (oldData) => {
                    return serializedData
                  }
                )
                queryClient.setQueryData("certifications", (oldData) => {
                  let newArray = []
                  newArray = oldData.map((x) => {
                    if (x.id !== serializedData.id) {
                      return x
                    } else {
                      return serializedData
                    }
                  })
                  return [...newArray]
                })
                setUnsavedState([])
                return
              })
              openSnackBar({
                message: "Certification Unpublished",
              })
              setPublishLoading(false)
              handleClose()
            },
            onError: (err) => {
              openSnackBar({
                message: "Error Unpublishing Certification",
              })
              setPublishLoading(false)
              handleClose()
            },
          }
        )
      })
    })
  }

  //discard changes
  const discardChanges = () => {
    if (unsavedState.includes("details")) {
      setCertDetailsValues(initialDetailsContext)
      setDebouncedDetails(initialDetailsContext)
    }
  }

  return (
    <CertificationContext.Provider
      value={{
        certification,
        anchorEl,
        setAnchorEl,
        debouncedDetails,
        setDebouncedDetails,
        certDetailsValues,
        setCertDetailsValues,
        initialDetailsContext,
        setInitialDetailsContext,
        tryPublish,
        setTryPublish,
        updateCertMutation,
        unsavedState,
        handleUpdate,
        saveLoading,
        setSaveLoading,
        discardChanges,
        lengthError,
        dateValue,
        setDateValue,
        disabledSave,
        attachedPaths,
        setAttachedPaths,
        initialAttachedPaths,
        setInitialAttachedPaths,
        publishCert,
        publishLoading,
        unpublishCert,
        unpublishLoading,
      }}
    >
      {children}
    </CertificationContext.Provider>
  )
}

CertificationContainer.propTypes = {
  children: PropTypes.node,
  certification: PropTypes.object,
}
