import { Box, CircularProgress, Typography } from "@material-ui/core"
import ListTitle from "components/Common/ListTitle"
import NoData from "components/Common/NoData"
import AppContext from "contexts/App"
import PropTypes from "prop-types"
import { useContext, useEffect, useState } from "react"
import sortHelper from "utils/sortHelper"
import InfiniteScroll from "react-infinite-scroll-component"
import DiscountCard from "../DiscountCard"
import useStyles from "./styles"

const DiscountCardList = ({ searchValue, sort, discounts, handleOpen }) => {
  const classes = useStyles()
  const [filteredData, setFilteredData] = useState([])
  const { setPageSubTitle } = useContext(AppContext)
  //amount of data that we show using the infinite scroll
  const [showedData, setShowedData] = useState([])

  useEffect(() => {
    //sets lessons array to filteredData
    if (discounts?.length > 0 && !filteredData) {
      setFilteredData(discounts)
    }
  }, [discounts])
  //filters depending on the searchValue or sort
  useEffect(() => {
    if (discounts) {
      let newArray = [...discounts]
      if (searchValue.length > 0 || sort?.name !== "") {
        if (searchValue.length > 0) {
          newArray = newArray?.filter((x) =>
            x.name.toLowerCase().includes(searchValue.toLowerCase())
          )
        }
        if (sort?.name !== "") {
          //util function to sort the data
          newArray = sortHelper(
            newArray,
            sort?.type,
            sort?.name,
            sort?.direction
          )
        }
      } else if (searchValue === "") {
        newArray = discounts
      }
      setFilteredData(newArray)
      //show 20 first elements of the newArray
      setShowedData(newArray?.slice(0, 20 || []))
      //set lessons  subtitle depends the search
      setPageSubTitle(newArray?.length)
    }
  }, [searchValue, discounts, sort])

  //loadData using infinite scroll
  const loadData = () => {
    setShowedData(() => filteredData.slice(0, showedData.length + 20))
  }

  //send the user to the top if a  sort or search value change
  useEffect(() => {
    const element = document.getElementById("discountsListScroll")
    if (element) {
      element.scrollTo(0, 0)
    }
  }, [searchValue, sort])

  return (
    <Box className={classes.container} id="discountsListScroll">
      <Box className={classes.tableWrapper}>
        {filteredData?.length > 0 ? (
          <ListTitle
            list={[
              { name: "", size: "1%" },
              { name: "Name", size: "23%" },
              { name: "Code", size: "10%" },
              { name: "Type", size: "10%" },
              { name: "Amount", size: "10%" },
              { name: "Start", size: "9%" },
              { name: "End", size: "9%" },
              { name: "Available", size: "10%" },
              { name: "used", size: "8%" },
              { name: "Status", size: "9%" },
              { name: "", size: "4%" },
            ]}
          />
        ) : null}
        {filteredData?.length > 0 ? (
          <InfiniteScroll
            style={{ overflow: "hidden" }}
            dataLength={showedData?.length || 0}
            scrollableTarget="discountsListScroll"
            next={loadData}
            loader={
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            }
            hasMore={filteredData?.length > showedData?.length || false}
            endMessage={
              <Box width="100%" textAlign="center">
                {filteredData.length > 20 && (
                  <Typography className={classes.postsInfiniteText}>
                    End of Discounts List
                  </Typography>
                )}
              </Box>
            }
          >
            {showedData.map((d) => {
              return (
                <DiscountCard
                  id={d.id}
                  key={d.id}
                  name={d.name}
                  code={d.coupon}
                  fixed={d.fixed}
                  fixedOff={d.fixed_amount_off}
                  percentOff={d.percent_off}
                  start={d.start_date.format("M/D/YY")}
                  end={
                    d.end_date.format() === "Invalid Date"
                      ? "No Expiration"
                      : d.end_date.format("M/D/YY")
                  }
                  available={d.allowed_uses}
                  used={d.uses}
                  containers={d.containers}
                  active={d.active}
                />
              )
            })}
          </InfiniteScroll>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            minHeight="80vh"
          >
            <NoData
              text="You have not created any discount codes"
              buttonFunc={handleOpen}
              buttonText="create my first code"
              searchValue={searchValue}
              length={discounts?.length}
              searchLength={filteredData?.length}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}

DiscountCardList.propTypes = {
  searchValue: PropTypes.string,
  discounts: PropTypes.array,
  sort: PropTypes.object,
  handleOpen: PropTypes.func,
}

export default DiscountCardList
