const PathModel = {
  created: "date",
  updated: "date",
  thumbnail: "object",
  background_image: "object",
  badge_image: "object",
  courses: ["hasMany", "course"],
}

export default PathModel
