import { Box, Button, Typography } from "@material-ui/core/"
import { withStyles } from "@material-ui/core/styles"
import logoWords from "assets/logo-in-words.svg"
import PropTypes from "prop-types"
import React from "react"
import { useHistory } from "react-router-dom"
import styles from "./styles"

const NotFound = ({ classes }) => {
  const history = useHistory()

  const goBack = () => {
    history.goBack()
  }

  return (
    <Box
      className={classes.notFoundContainer}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box className={classes.logoContainer}>
        <img
          src={logoWords}
          alt="Data Protocol Logo"
          className={classes.logo}
        />
      </Box>
      <Box className={classes.textContainer}>
        <Typography
          variant="h2"
          color="primary"
          className={classes.notFoundNumbers}
        >
          404
        </Typography>
        <Typography
          variant="h3"
          color="primary"
          className={classes.notFoundText}
        >
          No page matching that url
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={goBack}
          className={classes.backButton}
        >
          Go Back
        </Button>
      </Box>
    </Box>
  )
}

NotFound.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(NotFound)
