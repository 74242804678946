import Record from "./base"

export class Tag extends Record {
  constructor(...args) {
    super(...args)
    const [model, props] = args
    if (this.id) {
      this.tag_id = this.id
      delete this.id
    }
  }
}
