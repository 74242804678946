import { Box, Button, CircularProgress, Tooltip } from "@material-ui/core"
import { AddCircle, ReplayOutlined } from "@material-ui/icons"
import DarkButton from "components/Common/DarkButton"
import ListTitle from "components/Common/ListTitle"
import NoData from "components/Common/NoData"
import AppContext from "contexts/App"
import { orderBy } from "lodash"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useRef, useState } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { useQueryClient } from "react-query"
import { useHistory } from "react-router-dom"

import ResourceCard from "../ResourceCard"
import useStyles from "./styles"

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

function Resource({ resource, index, resourcesData, setFn }) {
  return (
    <Draggable draggableId={resource.id.toString()} index={index}>
      {(provided) => (
        <ResourceCard
          key={resource.id}
          name={resource.name}
          link={resource.url}
          access={resource.lock_until_over}
          id={resource.id}
          order={resource.order}
          file={resource.file}
          resources={resourcesData}
          setFn={setFn}
          provided={provided}
        />
      )}
    </Draggable>
  )
}

const ResourceList = React.memo(function LessonList({
  resources,
  resourcesData,
  setFn,
}) {
  return resources.map((resource, index) => (
    <Resource
      resource={resource}
      index={index}
      key={resource.id}
      resourcesData={resourcesData}
      setFn={setFn}
    />
  ))
})

const ResourceCardList = ({
  data,
  setFn,
  setRecoursesArray,
  recoursesArray,
  discardChanges,
}) => {
  // setRecoursesArray set recourses array to a course context or
  // lesson context depends in what page the user  is in

  const { openDialog, openSnackBar } = useContext(AppContext)

  const classes = useStyles()
  const history = useHistory()
  const queryClient = useQueryClient()

  const blockRef = useRef()

  const [reorderRan, setReorderRan] = useState(0)
  // increments every time resources are reordered, reset to 0 on save
  const [isLoading, setIsLoading] = useState(false)

  //open dialog if we go to other part of the app without save
  useEffect(() => {
    if (reorderRan > 0) {
      blockRef.current = {
        unblock: history.block((tx) => {
          openDialog("unsavedDataDialog", tx).then(() => {
            // discardChanges()
            // removed because this page automatically rejects unsaved changes
            blockRef.current.unblock()
            // tx.retry();
            history.push(tx.pathname)
          })
          return false
        }),
      }
    } else {
      blockRef.current?.unblock?.()
    }
  }, [reorderRan])

  //set the lesson data or course data to recourses array that is in context
  useEffect(() => {
    setReorderRan(0) // if using global save, this doesn't reset on its own
    let orderedData = orderBy(data, [(x) => x.order])
    setRecoursesArray(orderedData)
  }, [data])

  const handleOpen = () => {
    openDialog("editResourceDialog", { setFn, data })
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const resources = reorder(
      recoursesArray,
      result.source.index,
      result.destination.index
    )
    setReorderRan(reorderRan + 1)
    setRecoursesArray(resources)
  }
  // key to differentiate context to update in save reorder below
  let lessonId = data?.[0]?.lesson_id
  let courseId = data?.[0]?.course_id

  // fn to save reordered resources order
  const saveReorder = () => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      if (recoursesArray?.length > 0) {
        setFn.mutate(
          //map over array and set order prop to current index
          recoursesArray.map((x, i) => ({
            ...x,
            order: i,
          })),
          {
            onSuccess: (res) => {
              // differentiate between course resource & lesson resource
              if (lessonId) {
                // before setting query data, sort on the order property
                let orderedRes = orderBy(res.resources, [(x) => x.order])
                queryClient.setQueryData(["lesson", lessonId], (oldData) => {
                  return { ...oldData, resources: orderedRes }
                })
              } else {
                // before setting query data, sort on the order property
                let orderedRes = orderBy(res.resources, [(x) => x.order])
                queryClient.setQueryData(["course", courseId], (oldData) => {
                  return { ...oldData, resources: orderedRes }
                })
              }
              openSnackBar({
                message: "Updated Order of Resources",
              })
              setIsLoading(false)
              setReorderRan(0)
              resolve()
            },
            onError: () => {
              openSnackBar({
                message: "Error ordering resource",
              })
              setIsLoading(false)
              setReorderRan(0)
              reject()
            },
          }
        )
      } else {
        resolve()
      }
    })
  }

  //undo button runs this - set Array back to original data from pageload
  const undoReorder = () => {
    let original = orderBy(data, [(x) => x.order])
    setRecoursesArray(original)
    setReorderRan(0)
  }

  return (
    <Box height="100%">
      {data?.length > 0 ? (
        <Box display="flex" className={classes.container}>
          <Box marginLeft={3} className={classes.leftContainer}>
            <Box marginLeft={2}>
              <ListTitle
                list={[
                  { name: "Name", size: "25%" },
                  { name: "Link/PDF", size: "40%" },
                  { name: "Access", size: "25%" },
                  { name: "", size: "10%" },
                ]}
              />
            </Box>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="list">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <ResourceList
                      resources={recoursesArray}
                      resourcesData={recoursesArray}
                      setFn={setFn}
                    />
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
          <Box
            marginLeft={3}
            marginTop="52px"
            display="flex"
            flexDirection="column"
            className={classes.rightContainer}
          >
            <DarkButton
              onClick={handleOpen}
              startIcon={<AddCircle fontSize="small" />}
            >
              Add Resource
            </DarkButton>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              marginTop={2}
            >
              <Button
                type="submit"
                onClick={saveReorder}
                variant="contained"
                color="secondary"
                disabled={reorderRan === 0 || isLoading}
                className={classes.saveReorderButton}
              >
                {isLoading ? (
                  <CircularProgress size={20} style={{ color: "#fff" }} />
                ) : (
                  "Save Order"
                )}
              </Button>
              <Tooltip title="Undo sort" aria-label="undo sort">
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={undoReorder}
                  style={!reorderRan ? { display: "none" } : null}
                  className={classes.undoButton}
                >
                  <ReplayOutlined />
                </Button>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          width="100%"
        >
          <NoData
            text="You have not added any resources"
            buttonFunc={handleOpen}
            buttonText="add a resource"
            length={data?.length}
          />
        </Box>
      )}
    </Box>
  )
}

Resource.propTypes = {
  resource: PropTypes.object,
  index: PropTypes.number,
  order: PropTypes.number,
  resourcesData: PropTypes.array,
  setFn: PropTypes.object,
}

ResourceList.propTypes = {
  resources: PropTypes.array,
  resourcesData: PropTypes.array,
  setFn: PropTypes.object,
}

ResourceCardList.propTypes = {
  data: PropTypes.array,
  setFn: PropTypes.object,
  recoursesArray: PropTypes.array,
  setRecoursesArray: PropTypes.func,
  discardChanges: PropTypes.func,
}

export default ResourceCardList
