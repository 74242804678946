import { createCertification } from "actions/certifications"
import { listCertifications } from "actions/certifications"
import PropTypes from "prop-types"
import { createContext } from "react"
import { useMutation, useQuery } from "react-query"

const CertificationsContext = createContext()
export default CertificationsContext

export const CertificationsContainer = ({ children }) => {
  const createCertMutation = useMutation(createCertification)

  const { data: certifications, isLoading: certificationsLoading } = useQuery(
    "certifications",
    listCertifications
  )
  return (
    <CertificationsContext.Provider
      value={{ certifications, certificationsLoading, createCertMutation }}
    >
      {children}
    </CertificationsContext.Provider>
  )
}

CertificationsContainer.propTypes = {
  children: PropTypes.node,
}
