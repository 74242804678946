import { Box, Typography } from "@material-ui/core"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import HoverText from "components/Common/HoverText"
import PaperListRow from "components/Common/PaperListRow"
import PropTypes from "prop-types"

import StatusBox from "../StatusBox"
import useStyles from "./styles"

const LabCard = ({ creator, openLesson, lab }) => {
  const classes = useStyles()
  const theme = useTheme()

  const tabletView = useMediaQuery(theme.breakpoints.down("sm"))

  const titleSize = tabletView ? 30 : 40
  const componentHeight = tabletView ? "128px" : "96px"

  return (
    <Box marginBottom={2}>
      <PaperListRow componentHeight={componentHeight}>
        <Box width="100px" className={classes.statusRow}>
          <StatusBox locked={lab?.locked} size={"90px"} />
        </Box>
        <Box width="38%" className={classes.nameRow}>
          <Box
            onClick={openLesson}
            style={{ cursor: "pointer" }}
            display="flex"
          >
            <HoverText
              data={lab.name}
              size={titleSize}
              variant="h3"
              className={classes.title}
            />
          </Box>
        </Box>
        <Box width="20%" className={classes.typeRow}>
          <Typography variant="subtitle2" style={{ color: "#868686" }}>
            {lab.instance_type}
          </Typography>
        </Box>
        {!tabletView ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="20%"
            className={classes.updatedRow}
          >
            <Typography variant="subtitle2" style={{ color: "#868686" }}>
              Last Updated {lab?.updated?.format?.("MM/DD/YYYY")}
            </Typography>
          </Box>
        ) : (
          <Box
            width="75%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            marginBottom={2}
            marginTop={1}
          >
            <Typography
              variant="subtitle2"
              style={{ color: "#868686", marginLeft: "8px" }}
            >
              Last Updated {lab?.updated?.format?.("MM/DD/YYYY")}
            </Typography>
          </Box>
        )}
      </PaperListRow>
    </Box>
  )
}

LabCard.propTypes = {
  updatedDate: PropTypes.string,
  creator: PropTypes.number,
  openLesson: PropTypes.func,
  published: PropTypes.number,
  lab: PropTypes.object,
}

export default LabCard
