import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  root: {
    borderRadius: "4px",
  },
  inputLabel: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  inputBackgroundColor: {
    backgroundColor: "#F4F4F4",
  },
  publish: {
    width: "160px",
    backgroundColor: "#F4F4F4",
  },
  expiration: {
    width: "115px",
    backgroundColor: "#F4F4F4",
    borderRadius: "6px",
    "& .MuiFilledInput-inputMarginDense": {
      paddingTop: 0,
      paddingBottom: 0,
      height: "35px",
    },
  },
  disabledExpiration: {
    paddingLeft: "10px",
  },

  calendarIcon: {
    fontSize: 14,
  },

  checkBox: {
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
}))

export default styles
