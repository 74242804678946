import { listPaths } from "actions/paths"
import PropTypes from "prop-types"
import { createContext } from "react"
import { createPath } from "actions/paths"
import { useMutation, useQuery } from "react-query"

const LearningPathsContext = createContext()
export default LearningPathsContext

export const PathsContainer = ({ children }) => {
  const createPathMutation = useMutation(createPath)

  const { data: paths, isLoading: pathsLoading } = useQuery("paths", listPaths)
  return (
    <LearningPathsContext.Provider
      value={{ paths, pathsLoading, createPathMutation }}
    >
      {children}
    </LearningPathsContext.Provider>
  )
}

PathsContainer.propTypes = {
  children: PropTypes.node,
}
