import { LinearProgress } from "@material-ui/core"
import AppContext from "contexts/App"
import CoursesContext, { CoursesContainer } from "contexts/Courses"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useContext, useEffect } from "react"
import Router from "router"
import useStyles from "./styles"

const Courses = ({ routes = [], ...rest }) => {
  const classes = useStyles()
  const { setPageTitle } = useContext(AppContext)

  useEffect(() => {
    setPageTitle("Courses")
  }, [])

  return (
    <Page>
      <CoursesContainer>
        <CoursesWrapper>
          <Router routes={routes} {...rest} />
        </CoursesWrapper>
      </CoursesContainer>
    </Page>
  )
}

Courses.propTypes = {
  routes: PropTypes.array,
}

const CoursesWrapper = ({ children }) => {
  const { coursesLoading } = useContext(CoursesContext)
  if (coursesLoading) {
    return <LinearProgress />
  } else {
    return children
  }
}

CoursesWrapper.propTypes = {
  children: PropTypes.node,
}

export default Courses
