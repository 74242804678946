import axios from "axios"
import api from "store/api"

export const uploadThumbnail = (file, cloudinaryUploadPreset) => {
  const form = new FormData()
  form.append("upload_preset", cloudinaryUploadPreset)
  form.append("file", file)
  return axios.post(
    `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/upload`,
    form,
    { headers: { "Content-Type": "multipart/form-data" } }
  )
}

export const requestSignedUrl = () => {
  return api.get("/videos/request").then((res) => res.data.data)
}

export const uploadVideo = ({ url, fields, file }, opts) => {
  const form = new FormData()
  for (const [key, value] of Object.entries(fields)) {
    form.append(key, value)
  }
  form.append("file", file)
  return axios.post(url, form, {
    ...opts,
  })
}

export const createVideo = (data) => {
  return api.post("/videos", data).then((res) => res.data)
}

export const requestLearningCueMediaUpload = (data) =>
  api.post("/lesson_request_media_upload", data).then((res) => res.data)

export const uploadLearningCueMedia = ({ url, fields, file }, opts) => {
  const form = new FormData()
  for (const [key, value] of Object.entries(fields)) {
    form.append(key, value)
  }
  form.append("file", file)
  return axios.post(url, form, {
    ...opts,
  })
}

export const requestLessonPDFMediaUpload = (data) =>
  api.post("/lesson_request_media_upload", data).then((res) => res.data)

export const requestCoursePDFMediaUpload = (data) =>
  api.post("/course_request_resource_upload", data).then((res) => res.data)

export const uploadPDF = ({ url, fields, file }, opts) => {
  const form = new FormData()
  for (const [key, value] of Object.entries(fields)) {
    form.append(key, value)
  }
  form.append("file", file)
  return axios.post(url, form, {
    ...opts,
  })
}

export const requestSrtUpload = (data) =>
  api.post("/videos/request/srt", data).then((res) => res.data)

export const uploadSrt = ({ url, fields, file }, opts) => {
  const form = new FormData()
  for (const [key, value] of Object.entries(fields)) {
    form.append(key, value)
  }
  form.append("file", file)
  return axios.post(url, form, {
    ...opts,
  })
}

export const addSrt = (data, id) =>
  api.post(`/videos/${id}/srt`, data).then((res) => res.data)
