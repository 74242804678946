import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  cuePointIcon: {
    position: "absolute",
    top: 0,
  },
}))

export default styles
