import api from "store/api"
import serialize from "store/serialize"

export const createDiscount = (data) => {
  return api.post("/coupons", data).then((res) => res.data)
}

export const listDiscounts = () => {
  return api.get("/coupons").then((res) => serialize("discount", res.data.data))
}

export const setDiscountCourses = (data, id) => {
  return api
    .post(`coupon_course_containers/${id}`, data)
    .then((res) => res.data)
}

export const updateDiscount = (data, id) => {
  return api.put(`coupons/${id}`, data).then((res) => res.data)
}

export const removeDiscount = (id) => {
  return api.delete(`coupons/${id}`).then((res) => res.data)
}
