import api from "store/api"
import serialize from "store/serialize"

export const listLessons = (data) => {
  return api
    .get("/lessons", data)
    .then((res) => serialize("lesson", res.data.data))
}

export const listPublishedLessons = () => {
  return api
    .get("/published_lessons")
    .then((res) => serialize("lesson", res.data.data))
}

export const createLesson = (data) => {
  return api.post("/lessons", data).then((res) => res.data)
}

export const updateLesson = (data, id) => {
  return api.put(`lessons/${id}`, data).then((res) => res.data)
}

export const getLesson = (id) => {
  return api
    .get(`/lessons/${id}`)
    .then((res) => serialize("lesson", res.data.data))
}

export const getVideo = (id) => {
  return api.get(`videos/watch/${id}`).then((res) => res.data.data)
}

export const addLessonInstructor = (data, id) => {
  return api.post(`lesson_instructors/${id}`, data).then((res) => res.data)
}

export const listTags = (data) => {
  return api.get("/tags", data).then((res) => serialize("tag", res.data.data))
}

export const createTags = (data, id) => {
  return api.post(`/lesson_tags/${id}`, data).then((res) => res.data)
}

export const publishLesson = (id) => {
  return api.post(`/publish_lesson/${id}`).then((res) => res.data)
}

export const unpublishLesson = (id) => {
  return api.post(`/unpublish_lesson/${id}`).then((res) => res.data)
}

export const archiveLesson = (id) => {
  return api.post(`/archive_lesson/${id}`).then((res) => res.data)
}

export const unarchiveLesson = (id) => {
  return api.post(`/unarchive_lesson/${id}`).then((res) => res.data)
}

export const setLessonResources = (data, id) => {
  return api.post(`/lesson_resources/${id}`, data).then((res) => res.data)
}

export const newVersion = (data, id) => {
  return api.post(`/lesson_version/${id}`, data).then((res) => res.data)
}

export const copyLesson = (data, id) => {
  return api.post(`/copy_lesson/${id}`, data).then((res) => res.data)
}

export const getLearningCues = (id) => {
  return api
    .get(`/lessons/${id}/learning_cues`)
    .then((res) => serialize("learningCue", res.data.data))
}

export const setLearningCues = (data, id) => {
  return api
    .post(`/lesson_learning_cues/${id}`, data)
    .then((res) => serialize("learningCue", res.data.data))
}

export const getLearningCueMedia = (key) =>
  api.get(`/lesson_watch_media/${key}`).then((res) => res.data.url)

export const lessonViewPDF = (id) =>
  api.get(`/lesson_view_resource/${id}`).then((res) => res.data.url)

export const getVideoSrt = (id) =>
  api.get(`/videos/${id}/srt`).then((res) => res.data.data)

export const getLessonPreview = (lessonId) =>
  api.get(`/lessons/${lessonId}/request_preview`).then((res) => res.data.token)

export const lessonAnalytics = (id) =>
  api.get(`/lessons/${id}/analytics`).then((res) => {
    return res.data.data
  })
