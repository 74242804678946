import { Box, Button, IconButton, Paper, Typography } from "@material-ui/core"
import { AddCircle, RemoveCircle } from "@material-ui/icons"
import { Image, Transformation } from "cloudinary-react"
import DraftPublishedBox from "components/Common/DraftPublishedBox"
import HoverText from "components/Common/HoverText"
import PathContext from "contexts/Path"
import PropTypes from "prop-types"
import { useContext, useEffect, useState } from "react"
import serialize from "store/serialize"

import useStyles from "./styles"

const AddedCourseCard = ({ course, type, addedCourses, setAddedCourses }) => {
  const classes = useStyles()

  const { courseArray, setCourseArray } = useContext(PathContext)

  const [highlight, setHighlight] = useState(false)
  const [serializedCourse, setSerializedCourse] = useState(null)

  const unpublished =
    !course?.published && course?.has_been_published && !course?.archived

  const draft =
    !course?.published &&
    !course?.has_been_published &&
    !course?.archived &&
    !course?.publish_date

  const archived = !course?.published && course?.archived

  const scheduled =
    !course?.published &&
    !course?.has_been_published &&
    !course?.archived &&
    course?.publish_date

  const addCourse = () => {
    setAddedCourses([...addedCourses, course])
    setHighlight(true)
  }

  const removeCourse = () => {
    setAddedCourses(addedCourses.filter((c) => c.id !== course.id))
    setHighlight(false)
  }

  //remove for view type on pathCourses
  const deleteCourse = () => {
    setCourseArray(courseArray.filter((c) => c.id !== course.id))
  }

  useEffect(() => {
    if (addedCourses?.find((c) => c.id === course.id)) {
      setHighlight(true)
    }
  }, [addedCourses])

  useEffect(() => {
    serialize("course", course).then((res) => setSerializedCourse(res))
  }, [])

  return (
    <Paper
      elevation={0}
      className={classes.paperRow}
      style={{
        backgroundColor: type !== "view" && highlight ? "#D0D5DD" : "#fff",
      }}
    >
      {serializedCourse?.thumbnail.public_id ? (
        <Image
          publicId={serializedCourse.thumbnail.public_id}
          className={classes.image}
        >
          <Transformation
            width="125"
            crop="fill"
            gravity="auto"
            aspectRatio="16:9"
          />
        </Image>
      ) : (
        <Box className={classes.imgBox}></Box>
      )}

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        className={classes.cardContainer}
      >
        <Box display="flex" flexDirection="column" marginLeft={1}>
          {type !== "view" ? (
            <Typography
              variant="h3"
              color="primary"
              style={{ fontWeight: 600, marginBottom: "8px" }}
            >
              {serializedCourse?.name}
            </Typography>
          ) : (
            <HoverText
              data={serializedCourse?.name}
              size={40}
              variant="h3"
              className={classes.name}
            />
          )}
          <Box display="flex" alignItems="center">
            <Typography
              variant="body2"
              color="primary"
              style={{ fontWeight: 600 }}
            >
              Version {serializedCourse?.version}
            </Typography>
            <Typography
              variant="body2"
              style={{ color: "#868686", marginLeft: "4px" }}
            >
              Last Updated {serializedCourse?.updated?.format("M/D/YYYY")}
            </Typography>
            {type !== "view" && serializedCourse?.published ? (
              <Typography
                varaint="body2"
                style={{ color: "#29B117", marginLeft: "8px" }}
              >
                Published
              </Typography>
            ) : null}
            {type !== "view" && unpublished ? (
              <Typography
                varaint="body2"
                style={{ color: "#FF8439", marginLeft: "8px" }}
              >
                Unpublished
              </Typography>
            ) : null}
            {type !== "view" && draft ? (
              <Typography
                varaint="body2"
                style={{ color: "#BC9822", marginLeft: "8px" }}
              >
                Draft
              </Typography>
            ) : null}
            {type !== "view" && archived ? (
              <Typography
                varaint="body2"
                style={{ color: "#808080", marginLeft: "8px" }}
              >
                Archived
              </Typography>
            ) : null}
            {type !== "view" && scheduled ? (
              <Typography
                varaint="body2"
                style={{ color: "#8952D8", marginLeft: "8px" }}
              >
                Scheduled
              </Typography>
            ) : null}
          </Box>
        </Box>
        {type === "view" ? (
          <Box display="flex" alignItems="center">
            <Box marginRight={1}>
              <IconButton onClick={deleteCourse}>
                <RemoveCircle />
              </IconButton>
            </Box>
            <Box marginRight={1} width={100}>
              <DraftPublishedBox
                published={serializedCourse?.published}
                has_been_published={serializedCourse?.has_been_published}
                archived={serializedCourse?.archived}
              />
            </Box>
          </Box>
        ) : (
          <Box width="15%" className={classes.buttonBox}>
            {highlight ? (
              <Box className={classes.addedBox}>
                <Typography>Added</Typography>
                <IconButton
                  className={classes.removeButton}
                  onClick={removeCourse}
                >
                  <RemoveCircle color="primary" />
                </IconButton>
              </Box>
            ) : (
              <Button
                className={classes.addButton}
                classes={{ label: classes.button }}
                variant="contained"
                disableElevation
                startIcon={<AddCircle color="primary" />}
                onClick={addCourse}
              >
                <Typography color="primary" className={classes.buttonText}>
                  ADD
                </Typography>
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Paper>
  )
}

AddedCourseCard.propTypes = {
  course: PropTypes.object,
  type: PropTypes.string,
  addedCourses: PropTypes.array,
  setAddedCourses: PropTypes.func,
}

export default AddedCourseCard
