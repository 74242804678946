import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  active: {
    color: "#29B117",
  },
  inactive: {
    color: "#FF5E69",
  },

  inputBackgroundColor: {
    backgroundColor: "#F4F4F4",
    "& .MuiFilledInput-inputMarginDense": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    height: "35px",
  },

  grayBackgroundColor: {
    backgroundColor: "#F4F4F4",
    '&[class*="MuiFilledInput-root"]': {
      paddingTop: 0,
    },
  },

  buttonRootRemove: {
    color: "#FF5E69",
    // [theme.breakpoints.down("xs")]: {
    //   marginBottom: theme.spacing(2),
    // },
  },
  outline: {
    border: "1px solid #A21F28",
  },
  percent: {
    color: "black",
  },
  percentStart: {
    "&.MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)": {
      marginTop: 0,
    },
  },
  formHelperText: {
    marginLeft: 0,
  },
  dialogContent: {
    maxHeight: "460px",
    // overflow: "scroll",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "1000px",
    },
  },
  buttonsContainer: {
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
      alignItems: "center",
    },
  },
  bottomRowButtons: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      width: "100%",
      marginTop: theme.spacing(2),
      justifyContent: "center",
    },
  },
  cancel: {
    [theme.breakpoints.down("xs")]: {
      marginRight: theme.spacing(4),
    },
  },
}))

export default styles
