import { Box, Button, Typography } from "@material-ui/core"
import { AddCircle } from "@material-ui/icons"
import DarkButton from "components/Common/DarkButton"
import AppContext from "contexts/App"
import CourseContext from "contexts/Course"
import AddAssessmentDialog from "dialogs/AddAssesmentDialog"
import AddLabDialog from "dialogs/AddLabDialog"
import AddLessonDialog from "dialogs/AddLessonDialog"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"

import AddeddAssessment from "../AddedAssessment"
import AddedLab from "../AddedLab"
import AddedLessonCard from "../AddedLessonCard"
import NoFinalAssessment from "../NoFinalAssessment"
import NoLabAdded from "../NoLabAdded"
import NoLessonAdded from "../NoLessonAdded"
import useStyles from "./styles"

//function to reorder the lessons
//it is using a react library called react-beautiful-dnd
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

function Lesson({ lesson, index, disabledCourse }) {
  return (
    <Draggable draggableId={lesson.lesson_id.toString()} index={index}>
      {(provided) => (
        <AddedLessonCard
          lesson={lesson}
          provided={provided}
          disabledCourse={disabledCourse}
        />
      )}
    </Draggable>
  )
}

const LessonList = React.memo(function LessonList({ lessons, disabledCourse }) {
  return lessons.map((lesson, index) => (
    <Lesson
      lesson={lesson}
      index={index}
      key={lesson.lesson_id}
      disabledCourse={disabledCourse}
    />
  ))
})

const CourseContent = () => {
  const classes = useStyles()
  const { openDialog } = useContext(AppContext)
  const {
    course,
    lessonsArray,
    setLessonsArray,
    setInitialLessonsArray,
  } = useContext(CourseContext)

  const openAddLessonDialog = () => {
    let data = { course: course }
    openDialog("addLessonDialog", data)
  }

  const openAddAssessmentDialog = () => {
    let data = { courseId: course.id }
    if (course?.published === 1) {
      data = { courseId: course.id, lockdown: true }
    }
    openDialog("addAssessmentDialog", data)
  }

  const openAddLabDialog = () => {
    openDialog("addLabDialog", course)
  }

  //populate lessons array
  useEffect(() => {
    let newArray = []
    course?.lessons?.map((x) => {
      newArray.push({ lesson_id: x.id, data: x })
    })
    setLessonsArray(newArray)
    setInitialLessonsArray(newArray)
  }, [course.lessons])

  function onDragEnd(result) {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const lessons = reorder(
      lessonsArray,
      result.source.index,
      result.destination.index
    )

    setLessonsArray(lessons)
  }

  const disabledCourse =
    (course.published || course.has_been_published) && course.archived === 0

  const assessmentButtonText =
    course?.published === 1 ? "View Assessment" : "Edit Assessment"

  return (
    <Box position="relative" minHeight="100%">
      {disabledCourse ? (
        <Box className={classes.publishedBanner}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {course?.published === 1 ? (
              <Typography className={classes.title} variant="h3">
                You are in a published course, this can not be updated
              </Typography>
            ) : null}
            {course?.published === 0 && course?.has_been_published === 1 ? (
              <Typography className={classes.title} variant="h3">
                You are in a unpublished course, this can not be updated
              </Typography>
            ) : null}
            <Typography variant="subtitle1" className={classes.subtitle}>
              To update this you must create a new version
            </Typography>
          </Box>
        </Box>
      ) : null}
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        padding="31px 37px 8px 37px"
        marginBottom="10px"
      >
        <Box display="flex" flexDirection="row" minHeight="37px">
          <Typography
            variant="h3"
            color="primary"
            style={{ fontWeight: "700", zIndex: 4 }}
          >
            Lab
          </Typography>
        </Box>
      </Box>
      <Box marginLeft={"37px"} marginRight="37px" paddingBottom={2}>
        {course?.lab_id !== null ? (
          <AddedLab
            courseId={course?.id}
            lab={course?.lab}
            disabled={disabledCourse}
          />
        ) : (
          <NoLabAdded onClick={openAddLabDialog} />
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        padding="31px 37px 8px 37px"
        marginBottom="10px"
      >
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          minHeight="37px"
        >
          <Typography
            variant="h3"
            color="primary"
            style={{ fontWeight: "700", marginRight: "8px" }}
          >
            Lessons{" "}
          </Typography>
          <Typography
            variant="h3"
            color="primary"
            className={classes.orderText}
          >
            In order as they appear
          </Typography>
        </Box>
        {lessonsArray?.length > 0 && (
          <DarkButton
            onClick={openAddLessonDialog}
            startIcon={
              <AddCircle
                fontSize="small"
                classes={{ root: classes.addCircle }}
              />
            }
            style={course?.published ? { display: "none" } : null}
          >
            Add Lesson
          </DarkButton>
        )}
      </Box>
      <Box marginLeft={"37px"} marginRight="37px">
        {lessonsArray?.length > 0 ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <LessonList
                    lessons={lessonsArray}
                    disabledCourse={disabledCourse}
                  />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <NoLessonAdded onClick={openAddLessonDialog} />
        )}
      </Box>
      {disabledCourse ? <Box className={classes.publishedOverlay} /> : null}

      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        padding="31px 37px 8px 37px"
        marginBottom="10px"
      >
        <Box display="flex" flexDirection="row" minHeight="37px">
          <Typography
            variant="h3"
            color="primary"
            style={{ fontWeight: "700", zIndex: 4 }}
          >
            Final Assessment
          </Typography>
        </Box>
        {course?.assessment_id !== null && (
          <DarkButton
            onClick={openAddAssessmentDialog}
            style={{ zIndex: 8 }}
            startIcon={
              <AddCircle
                fontSize="small"
                classes={{ root: classes.addCircle }}
              />
            }
          >
            {assessmentButtonText}
          </DarkButton>
        )}
      </Box>
      <Box marginLeft={"37px"} marginRight="37px" paddingBottom={2}>
        {course?.assessment_id !== null ? (
          <AddeddAssessment assessment={course?.assessment} />
        ) : (
          <NoFinalAssessment onClick={openAddAssessmentDialog} />
        )}
      </Box>

      <AddLessonDialog />
      <AddAssessmentDialog />
      <AddLabDialog />
    </Box>
  )
}

Lesson.propTypes = {
  lesson: PropTypes.object,
  index: PropTypes.number,
  disabledCourse: PropTypes.bool,
}

LessonList.propTypes = {
  lessons: PropTypes.array,
  disabledCourse: PropTypes.number,
}

CourseContent.propTypes = {
  lesson: PropTypes.object,
  index: PropTypes.number,
  "lesson.id": PropTypes.string,
  disabledCourse: PropTypes.number,
}
export default CourseContent
