import { Box, Button, IconButton, Paper, Typography } from "@material-ui/core"
import { AddCircle, RemoveCircle } from "@material-ui/icons"
import dragGray from "assets/Icons/noun_drag_3124730_gray.svg"
import { Image, Transformation } from "cloudinary-react"
import HoverText from "components/Common/HoverText"
import CertificationContext from "contexts/Certification"
import PropTypes from "prop-types"
import { useContext, useEffect, useState } from "react"

import useStyles from "./styles"

const AddedPathCard = ({ path, type, addedPaths, setAddedPaths, provided }) => {
  const classes = useStyles()

  const { attachedPaths, setAttachedPaths } = useContext(CertificationContext)

  const [highlight, setHighlight] = useState(false)

  const unpublished =
    !path?.published && path?.has_been_published && !path?.archived

  const draft =
    !path?.published &&
    !path?.has_been_published &&
    !path?.archived &&
    !path?.publish_date

  const archived = !path?.published && path?.archived

  const scheduled =
    !path?.published &&
    !path?.has_been_published &&
    !path?.archived &&
    path?.publish_date

  const addPath = () => {
    setAddedPaths([...addedPaths, path])
    setHighlight(true)
  }

  const removePath = () => {
    const localPaths = addedPaths.filter((p) => {
      if (p.learning_path_id) {
        return p.learning_path_id !== path.id
      } else {
        return p.id !== path.id
      }
    })
    setAddedPaths(localPaths)
    setHighlight(false)
  }

  //remove for view type
  const deleteLearningPath = () => {
    setAttachedPaths(attachedPaths.filter((p) => p.id !== path.id))
  }

  useEffect(() => {
    if (addedPaths?.find((p) => p.id === path.id)) {
      setHighlight(true)
    }
    if (addedPaths?.find((p) => p.learning_path_id === path.id)) {
      setHighlight(true)
    }
  }, [addedPaths])

  return (
    <Paper
      elevation={0}
      className={classes.paperRow}
      style={{
        backgroundColor: type !== "view" && highlight ? "#D0D5DD" : "#fff",
      }}
      ref={provided?.innerRef}
      {...provided?.draggableProps}
    >
      {path?.badge_image.public_id ? (
        <Box
          width="132px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Image
            publicId={path.badge_image.public_id}
            className={classes.image}
            style={{ height: "58px", width: "58px", borderRadius: "100%" }}
          ></Image>
        </Box>
      ) : (
        <Box className={classes.imgBox}></Box>
      )}

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        className={classes.cardContainer}
      >
        <Box
          display="flex"
          flexDirection="column"
          marginLeft={1}
          width={type === "view" ? 225 : 300}
        >
          <HoverText
            data={path?.name}
            size={type === "view" ? 25 : 35}
            variant="h3"
            className={classes.name}
          />
          <Box display="flex" alignItems="center">
            <Typography
              variant="body2"
              style={{ color: "#868686", marginTop: "2px" }}
            >
              Last Updated {path?.updated?.format("M/D/YYYY")}
            </Typography>
            {path?.published ? (
              <Typography
                varaint="body2"
                style={{ color: "#29B117", marginLeft: "8px" }}
              >
                Published
              </Typography>
            ) : null}
            {unpublished ? (
              <Typography
                varaint="body2"
                style={{ color: "#FF8439", marginLeft: "8px" }}
              >
                Unpublished
              </Typography>
            ) : null}
            {draft ? (
              <Typography
                varaint="body2"
                style={{ color: "#BC9822", marginLeft: "8px" }}
              >
                Draft
              </Typography>
            ) : null}
            {archived ? (
              <Typography
                varaint="body2"
                style={{ color: "#808080", marginLeft: "8px" }}
              >
                Archived
              </Typography>
            ) : null}
            {scheduled ? (
              <Typography
                varaint="body2"
                style={{ color: "#8952D8", marginLeft: "8px" }}
              >
                Scheduled
              </Typography>
            ) : null}
          </Box>
        </Box>
        <Box display="flex" flexGrow={1} marginLeft={3}>
          {path?.courses ? (
            <Typography
              variant="subtitle1"
              color="primary"
              style={{ fontWeight: 600 }}
            >
              {path?.courses?.length}{" "}
              {path?.courses?.length > 1 || path?.courses?.length === 0
                ? "Courses"
                : "Course"}
            </Typography>
          ) : (
            <Typography
              variant="subtitle1"
              color="primary"
              style={{ fontWeight: 600 }}
            >
              {path?.course_count}{" "}
              {path?.course_count > 1 || path?.course_count === 0
                ? "Courses"
                : "Course"}
            </Typography>
          )}
        </Box>
        {type === "view" ? (
          <Box display="flex" alignItems="center">
            <Box marginRight={2}>
              <IconButton onClick={deleteLearningPath}>
                <RemoveCircle />
              </IconButton>
            </Box>
            <Box {...provided.dragHandleProps}>
              <img
                src={dragGray}
                alt=""
                width="20px"
                style={{ marginRight: "16px" }}
              />
            </Box>
          </Box>
        ) : (
          <Box width="15%" className={classes.buttonBox}>
            {highlight ? (
              <Box className={classes.addedBox}>
                <Typography>Added</Typography>
                <IconButton
                  className={classes.removeButton}
                  onClick={removePath}
                >
                  <RemoveCircle color="primary" />
                </IconButton>
              </Box>
            ) : (
              <Button
                className={classes.addButton}
                classes={{ label: classes.button }}
                variant="contained"
                disableElevation
                startIcon={<AddCircle color="primary" />}
                onClick={addPath}
              >
                <Typography color="primary" className={classes.buttonText}>
                  ADD
                </Typography>
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Paper>
  )
}

AddedPathCard.propTypes = {
  path: PropTypes.object,
  type: PropTypes.string,
  addedPaths: PropTypes.array,
  setAddedPaths: PropTypes.func,
  provided: PropTypes.object,
}

export default AddedPathCard
