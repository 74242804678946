import { Box, Button, Typography } from "@material-ui/core"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import TimelineCuePoints from "components/Common/TimelineCuePoints"
import TimelineSlider from "components/Common/TimelineSlider"
import LearningCuesContext from "contexts/LearningCues"
import VideoContext from "contexts/Video"
import dayjs from "dayjs"
import PropTypes from "prop-types"
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"

import useStyles from "./styles"

const timeForPercentage = (percent, duration) => {
  return (percent / 100) * duration
}

const LearningCuesTimeline = ({ lockdown }) => {
  const timelineRef = useRef()
  const classes = useStyles()

  const { duration, videoProgress, setVideoPlayhead } = useContext(VideoContext)

  const [currentPlayheadPosition, setCurrentPlayheadPosition] = useState(0)

  useEffect(() => {
    if (timelineRef) {
      setCurrentPlayheadPosition(videoProgress)
    }
  }, [videoProgress, timelineRef])

  const videoBreakpoints = useMemo(() => {
    if (duration) {
      let breakpointsArr = []
      for (let index = 0; index < 6; index++) {
        const percentage = 20 * index
        const time = timeForPercentage(percentage, duration)
        breakpointsArr.push(time)
      }
      return breakpointsArr
    } else {
      return []
    }
  }, [duration])

  const handleChange = useCallback(
    (e, value) => {
      let cleanValue = value
      if (value === duration) {
        // if trying to move to the very end of the video, max at 1 second less
        cleanValue = value - 1
      }
      setCurrentPlayheadPosition(cleanValue)
      setVideoPlayhead(cleanValue)
    },
    [duration]
  )

  const format = useMemo(() => {
    return duration > 3600 ? "HH:mm:ss" : "mm:ss"
  }, [duration])

  return (
    <Box className={classes.learningCuesTimelineBox}>
      <Box className={classes.timeline} ref={timelineRef}>
        <TimelineSlider
          duration={duration}
          value={currentPlayheadPosition}
          onChange={handleChange}
          lockdown={lockdown}
        />
        <TimelineCuePoints duration={duration} lockdown={lockdown} />
        {videoBreakpoints.map((breakpoint, index, arr) => {
          let leftOffset = `${index * 20}%`
          return (
            <Box
              key={breakpoint}
              className={classes.breakpoint}
              style={{ left: leftOffset }}
            >
              <Typography style={{ fontSize: "0.6875rem" }}>
                {dayjs
                  .duration(0, "s")
                  .add(breakpoint, "seconds")
                  .format(format)}
              </Typography>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

LearningCuesTimeline.propTypes = {
  videoDuration: PropTypes.number,
  playerRef: PropTypes.object,
  lockdown: PropTypes.bool,
}

export default LearningCuesTimeline
