import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  dialogActions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cropContainer: {
    position: "relative",
    paddingTop: "56.25%",
    width: "100%",
    border: `2px solid ${theme.palette.secondary.main}`,
  },
  thumbnailBox: {
    position: "relative",
    "&:hover": {
      "& $editBox": {
        opacity: 1,
      },
    },
  },
  mediaKey: {
    opacity: 0.4,
    fontStyle: "italic",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: "0.75rem",
  },
  editBox: {
    zIndex: 1,
    transition: theme.transitions.create("opacity", {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeInOut,
    }),
    opacity: 0,
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  removeButton: {
    color: theme.palette.error.main,
  },
  tempMediaImgBox: {
    width: "100%",
  },
  tempMediaImg: {
    width: "100%",
  },
  inputLabel: {
    marginTop: theme.spacing(2),
    color: theme.palette.secondary.main,
    fontWeight: 700,
    fontSize: "0.75rem",
    marginBottom: theme.spacing(0.5),
  },
}))

export default styles
