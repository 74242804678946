import { Box, Divider, Menu, MenuItem, Typography } from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import AppContext from "contexts/App"
import PropTypes from "prop-types"
import { useContext } from "react"
import React from "react"
import { useIndexedDB } from "react-indexed-db"

// import Avatar from "../Avatar"
import useStyles from "./styles"
const Appbar = ({ children }) => {
  const classes = useStyles()
  const { clear } = useIndexedDB("auth")
  const { openDialog, pageTitle, pageSubTitle, authUser } = useContext(
    AppContext
  )
  const [anchorEl, setAnchorEl] = React.useState(null)

  //logout that send you to login page
  const logout = () => {
    clear().finally(() => {
      window.location.href = "/login"
    })
  }

  //open my acount dialog
  const handleOpen = () => {
    openDialog("myAccountDialog")
    handleClose()
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  //close the menu
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <Box className={classes.appBar}>
      <Typography color="primary" variant="h3" style={{ fontWeight: 600 }}>
        {pageTitle}
      </Typography>
      {pageSubTitle ? (
        <Typography color="primary" variant="h3" className={classes.subtitle}>
          {pageSubTitle}
        </Typography>
      ) : null}
      <Box className={classes.spacer} />
      <Box
        display="flex"
        alignItems="center"
        onClick={handleClick}
        className={classes.myProfile}
      >
        <Typography variant="subtitle1" color="secondary">
          {authUser?.name}
        </Typography>
        <ExpandMoreIcon color="secondary" style={{ fontSize: "15px" }} />
      </Box>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleOpen} color="secondary">
          <Typography
            variant="subtitle1"
            color="secondary"
            className={classes.myProfile}
          >
            My account
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={logout}>
          <Typography
            variant="subtitle1"
            color="secondary"
            className={classes.myProfile}
          >
            Logout
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  )
}
Appbar.propTypes = {
  children: PropTypes.node,
}
export default Appbar
