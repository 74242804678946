import { Box, Typography } from "@material-ui/core"
import PropTypes from "prop-types"
import React from "react"

import useStyles from "./styles"

const ListTitle = ({ list }) => {
  const classes = useStyles()

  return (
    <Box className={classes.container} width="100%">
      {list?.map((x, i) => {
        return (
          <Box width={x.size} key={i}>
            <Typography
              variant="subtitle2"
              color="primary"
              className={classes.title}
            >
              {x?.name?.toUpperCase()}
            </Typography>
          </Box>
        )
      })}
    </Box>
  )
}

ListTitle.propTypes = {
  list: PropTypes.array,
}

export default ListTitle
