import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(3),
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      width: "90%",
      justifyContent: "center",
      marginTop: theme.spacing(2),
    },
  },
  buttonContainer: {
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(2),
    },
  },
  button: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}))

export default styles
