import { Box, LinearProgress } from "@material-ui/core"
import { getLab } from "actions/labs"
import CourseLessonContentContainer from "components/Common/CourseLessonContentContainer"
import LabContent from "components/Labs/LabContent"
import LabDetails from "components/Labs/LabDetails"
import LabHeader from "components/Labs/LabHeader"
import UnsavedChanges from "components/Labs/UnsavedChanges"
import AppContext from "contexts/App"
import { LabContainer } from "contexts/Lab"
import Page from "pages/Page"
import { useContext, useEffect } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"

import useStyles from "./styles"

const Lab = () => {
  const classes = useStyles()
  const { lab_id } = useParams()
  const labId = parseInt(lab_id)

  const { setPageTitle } = useContext(AppContext)

  const { data: lab, isLoading: labLoading } = useQuery(
    ["lab", labId],
    () => getLab(labId),
    {
      enabled: Boolean(labId),
    }
  )

  //set lab title and subtitle
  useEffect(() => {
    setPageTitle("Lessons")
  }, [labId, setPageTitle])

  if (labLoading) {
    return <LinearProgress color="primary" />
  }

  return (
    <Page>
      <LabContainer lab={lab} labLoading={labLoading}>
        {/* <QuestionsContainer> */}
        <Box display="flex" flexDirection="column" height="100%">
          <Box flexShrink={0} flexGrow={0}>
            <LabHeader />
          </Box>
          <Box flexGrow={1} overflow="auto">
            <Page>
              <CourseLessonContentContainer
                leftComponent={<LabDetails />}
                rightComponent={<LabContent />}
              />
            </Page>
          </Box>
        </Box>
        <UnsavedChanges />
      </LabContainer>
    </Page>
  )
}

Lab.propTypes = {}

export default Lab
