import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Tooltip,
  Typography,
} from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined"
import FullscreenIcon from "@material-ui/icons/Fullscreen"
import MoreHorizIcon from "@material-ui/icons/MoreHoriz"
import MediaIcon from "@material-ui/icons/PermMedia"
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline"
import { ReactComponent as BottomVideoBannerIcon } from "assets/Icons/bottom-video-banner-icon.svg"
import { ReactComponent as CheckIcon } from "assets/Icons/check-icon.svg"
import { ReactComponent as ResumeIcon } from "assets/Icons/chess-icon.svg"
import { ReactComponent as NotesIcon } from "assets/Icons/pen-icon.svg"
import { ReactComponent as SentimentQuestionIcon } from "assets/Icons/sentiment-icon.svg"
import { ReactComponent as TopVideoBannerIcon } from "assets/Icons/top-video-banner-icon.svg"
import classnames from "clsx"
import AppContext from "contexts/App"
import LearningCuesContext from "contexts/LearningCues"
import VideoContext from "contexts/Video"
import dayjs from "dayjs"
import { motion, usePresence } from "framer-motion"
import useLearningCueMediaFileName from "hooks/useLearningCueMediaFileName"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import { useCallback, useContext, useState } from "react"
import TimeField from "react-simple-timefield"

import LearningCueChip from "./LearningCueChip"
import useStyles from "./styles"

const transition = { type: "spring", stiffness: 500, damping: 50, mass: 1 }

const LearningCueItem = ({ learningCue, updateTime, lockdown }) => {
  const classes = useStyles()

  const [isPresent, safeToRemove] = usePresence()

  const [internalValue, setInternalValue] = useState("")

  const animations = {
    layout: true,
    initial: "out",
    style: {
      position: isPresent ? "static" : "absolute",
    },
    animate: isPresent ? "in" : "out",
    whileTap: "tapped",
    variants: {
      in: { scaleY: 1 },
      out: { scaleY: 0, zIndex: -1 },
    },
    onAnimationComplete: () => !isPresent && safeToRemove(),
    transition,
  }

  const { openDialog } = useContext(AppContext)
  const {
    changeCheckedValue,
    setLearningCueContent,
    removeLearningCue,
    copyLearningCue,
    setHighlightedLearningCue,
    learningCuesQuery,
  } = useContext(LearningCuesContext)
  const { videoProgress, duration } = useContext(VideoContext)

  const [rowFocused, setRowFocused] = useState(false)

  const handleMediaChipClick = () => {
    if (!lockdown || (lockdown && learningCue?.media)) {
      const data = {
        ...learningCue?.media,
        lockdown: lockdown,
        lessonVideoDuration: duration,
        videoProgress: videoProgress,
        startSeconds: learningCue?.start_seconds,
      }
      openDialog("mediaUpload", data).then((res) => {
        setLearningCueContent(learningCue.id, {
          media: res,
          displaySecondsMax: duration - learningCue.start_seconds,
        })
      })
    }
  }

  const handleKnowledgeCheckChipClick = () => {
    if (!lockdown || (lockdown && learningCue?.knowledgeCheck)) {
      openDialog("knowledgeCheckEditor", {
        learningCueId: learningCue.id,
        ...learningCue.knowledgeCheck,
        lockdown: lockdown,
        startSeconds: learningCue?.start_seconds,
      }).then((values) => {
        setLearningCueContent(learningCue.id, {
          knowledgeCheck: values,
          force_pause: true,
        })
      })
    }
  }

  const handleSentimentQuestionClick = () => {
    if (!lockdown || (lockdown && learningCue?.sentimentQuestions)) {
      openDialog("sentimentQuestionsEditor", {
        learningCueId: learningCue.id,
        questions: learningCue.sentimentQuestions,
        lockdown: lockdown,
        startSeconds: learningCue?.start_seconds,
      }).then((values) => {
        setLearningCueContent(learningCue.id, {
          sentimentQuestions: values,
          force_pause: true,
        })
      })
    }
  }

  const handleTopVideoBannerChipClick = () => {
    if (!lockdown || (lockdown && learningCue?.topBanner)) {
      const data = {
        ...learningCue?.topBanner,
        lockdown: lockdown,
        lessonVideoDuration: duration,
        videoProgress: videoProgress,
        startSeconds: learningCue?.start_seconds,
      }
      openDialog("topVideoBanner", data).then((values) => {
        setLearningCueContent(learningCue.id, {
          topBanner: values,
        })
      })
    }
  }

  const handleBottomVideoBannerChipClick = () => {
    if (!lockdown || (lockdown && learningCue?.bottomBanner)) {
      const data = {
        ...learningCue?.bottomBanner,
        lockdown: lockdown,
        lessonVideoDuration: duration,
        videoProgress: videoProgress,
        startSeconds: learningCue?.start_seconds,
      }
      openDialog("bottomVideoBanner", data).then((values) => {
        setLearningCueContent(learningCue.id, {
          bottomBanner: values,
        })
      })
    }
  }

  const handleFullscreenChipClick = () => {
    if (!lockdown || (lockdown && learningCue?.fullscreen)) {
      const data = {
        lockdown: lockdown,
        lessonVideoDuration: duration,
        videoProgress: videoProgress,
        startSeconds: learningCue?.start_seconds,
      }
      if (learningCue?.fullscreen_seconds) {
        data.fullscreen_seconds = learningCue?.fullscreen_seconds
      }
      openDialog("fullscreen", data).then((values) => {
        setLearningCueContent(learningCue.id, {
          fullscreen: values === undefined ? 0 : 1,
          fullscreen_seconds:
            values === undefined ? null : values.fullscreen_seconds,
        })
      })
    }
  }

  const handleReadyCheckChipClick = () => {
    if (!lockdown || (lockdown && learningCue?.readyCheck)) {
      const data = {
        ...learningCue?.readyCheck,
        lockdown: lockdown,
        startSeconds: learningCue?.start_seconds,
      }
      openDialog("readyCheck", data).then((values) => {
        setLearningCueContent(learningCue.id, {
          readyCheck: values,
        })
      })
    }
  }

  const handleNotesChipClick = () => {
    if (!lockdown || (lockdown && learningCue?.prefilledNotes)) {
      const data = {
        ...learningCue?.prefilledNotes,
        lockdown: lockdown,
        startSeconds: learningCue?.start_seconds,
      }
      openDialog("notes", data).then((values) => {
        setLearningCueContent(learningCue.id, {
          prefilledNotes: values,
        })
      })
    }
  }

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked
    const name = e.target.name
    changeCheckedValue(learningCue.id, name, checked)
  }

  const [menuAnchorEl, setMenuAnchorEl] = useState(null)

  const handleOpenMenu = (event) => {
    setMenuAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setMenuAnchorEl(null)
  }

  const handleMenuClick = (menuItem) => () => {
    if (menuItem === "remove") {
      removeLearningCue(learningCue.id)
    } else if (menuItem === "copy") {
      copyLearningCue(videoProgress, learningCue)
    }
    handleCloseMenu()
  }

  const handleFocusInput = () => {
    setRowFocused(true)
    // setHighlightedLearningCue(learningCue.id)
  }

  const handleBlurInput = () => {
    setRowFocused(false)
    if (internalValue !== "") {
      // a new value was set, call updateTime
      const startTime = dayjs("00:00:00", "HH:mm:ss")
      const timestamp = dayjs(internalValue, "HH:mm:ss")
      const seconds = timestamp.diff(startTime, "second")
      updateTime(seconds)
      setInternalValue("")
    }
    // setHighlightedLearningCue(null)
  }

  const showChip = (boolean) => {
    return !lockdown || boolean
  }

  const learningCueMediaName = useLearningCueMediaFileName(
    learningCue?.media?.key
  )

  const [hovered, setHovered] = useState(false)

  const handleMouseEnter = () => {
    setHovered(true)
    setHighlightedLearningCue(learningCue.id)
  }

  const handleMouseLeave = () => {
    setHovered(false)
    setHighlightedLearningCue(null)
  }

  return (
    <motion.div
      {...animations}
      className={classnames(classes.learningCueItem, {
        // [classes.focused]: rowFocused,
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box className={classes.inputBox}>
        <TimeField
          value={dayjs
            .duration(0, "s")
            .add(learningCue.start_seconds, "seconds")
            .format("HH:mm:ss")}
          onChange={(event, value) => {
            setInternalValue(value)
          }}
          input={
            <OutlinedInput
              className={classes.textField}
              variant="outlined"
              size="small"
              classes={{
                input: classes.input,
                notchedOutline: classes.none,
                focused: classes.inputFocused,
              }}
              onFocus={handleFocusInput}
              onBlur={handleBlurInput}
              disabled={lockdown}
            />
          }
          colon=":"
          showSeconds
        />
      </Box>
      <Box className={classes.chips}>
        <LearningCueChip
          locked={lockdown}
          learningCue={learningCue}
          type="Media"
          property="media"
          ActiveIcon={MediaIcon}
          disabled={learningCue.fullscreen}
          hover={hovered}
          onClick={handleMediaChipClick}
        />
        {/* {showChip(!isEmpty(learningCue.media)) && (
          <Tooltip
            title={
              !isEmpty(learningCue.media)
                ? learningCueMediaName
                : "Add Media Cue"
            }
            placement="top"
          >
            <Box
              className={classnames(classes.chip, {
                [classes.enabled]: !isEmpty(learningCue.media),
                [classes.buttonDisabled]: learningCue.fullscreen,
              })}
              onClick={() => {
                if (!learningCue.fullscreen) {
                  handleMediaChipClick()
                }
              }}
            >
              {!isEmpty(learningCue.media) && (
                <MediaIcon style={{ width: 16 }} />
              )}
              {isEmpty(learningCue.media) && <AddIcon style={{ width: 16 }} />}
            </Box>
          </Tooltip>
        )}
        {lockdown && isEmpty(learningCue.media) && (
          <div className={classes.chipSpacer} />
        )} */}
        <LearningCueChip
          locked={lockdown}
          learningCue={learningCue}
          type="Top Video Banner"
          property="topBanner"
          ActiveIcon={TopVideoBannerIcon}
          hover={hovered}
          onClick={handleTopVideoBannerChipClick}
        />
        {/* {showChip(!isEmpty(learningCue.topBanner)) && (
          <Tooltip title="Add Top Video Banner Cue" placement="top">
            <Box
              className={classnames(classes.chip, classes.topVideoBanner, {
                [classes.enabled]: !isEmpty(learningCue.topBanner),
              })}
              onClick={handleTopVideoBannerChipClick}
            >
              {!isEmpty(learningCue.topBanner) && (
                <TopVideoBannerIcon style={{ width: 16 }} />
              )}
              {isEmpty(learningCue.topBanner) && (
                <AddIcon style={{ width: 16 }} />
              )}
            </Box>
          </Tooltip>
        )}
        {lockdown && isEmpty(learningCue.topBanner) && (
          <div className={classes.chipSpacer} />
        )} */}
        <LearningCueChip
          locked={lockdown}
          learningCue={learningCue}
          type="Bottom Video Banner"
          property="bottomBanner"
          ActiveIcon={BottomVideoBannerIcon}
          hover={hovered}
          onClick={handleBottomVideoBannerChipClick}
        />
        {/* {showChip(!isEmpty(learningCue.bottomBanner)) && (
          <Tooltip title="Add Bottom Video Banner Cue" placement="top">
            <Box
              className={classnames(classes.chip, classes.bottomVideoBanner, {
                [classes.enabled]: !isEmpty(learningCue.bottomBanner),
              })}
              onClick={handleBottomVideoBannerChipClick}
            >
              {!isEmpty(learningCue.bottomBanner) && (
                <BottomVideoBannerIcon style={{ width: 16 }} />
              )}
              {isEmpty(learningCue.bottomBanner) && (
                <AddIcon style={{ width: 16 }} />
              )}
            </Box>
          </Tooltip>
        )}
        {lockdown && isEmpty(learningCue.bottomBanner) && (
          <div className={classes.chipSpacer} />
        )} */}
        <LearningCueChip
          locked={lockdown}
          learningCue={learningCue}
          type="Knowledge Check"
          property="knowledgeCheck"
          ActiveIcon={CheckIcon}
          disabled={learningCue.fullscreen}
          hover={hovered}
          onClick={handleKnowledgeCheckChipClick}
        />
        {/* {showChip(!isEmpty(learningCue.knowledgeCheck)) && (
          <Tooltip title="Add Knowledge Check Cue" placement="top">
            <Box
              className={classnames(classes.chip, classes.knowledgeCheck, {
                [classes.enabled]: !isEmpty(learningCue.knowledgeCheck),
                [classes.buttonDisabled]: learningCue.fullscreen,
              })}
              onClick={() => {
                if (!learningCue.fullscreen) {
                  handleKnowledgeCheckChipClick()
                }
              }}
            >
              {!isEmpty(learningCue.knowledgeCheck) && (
                <CheckIcon style={{ width: 16 }} />
              )}
              {isEmpty(learningCue.knowledgeCheck) && (
                <AddIcon style={{ width: 16 }} />
              )}
            </Box>
          </Tooltip>
        )}
        {lockdown && isEmpty(learningCue.knowledgeCheck) && (
          <div className={classes.chipSpacer} />
        )} */}
        <LearningCueChip
          locked={lockdown}
          learningCue={learningCue}
          type="Sentiment Question"
          property="sentimentQuestions"
          ActiveIcon={SentimentQuestionIcon}
          disabled={learningCue.fullscreen || learningCue.knowledgeCheck}
          hover={hovered}
          onClick={handleSentimentQuestionClick}
        />
        <LearningCueChip
          locked={lockdown}
          learningCue={learningCue}
          type="Fullscreen"
          property="fullscreen"
          ActiveIcon={FullscreenIcon}
          disabled={learningCue.knowledgeCheck}
          hover={hovered}
          onClick={handleFullscreenChipClick}
        />
        {/* {showChip(learningCue.fullscreen) && (
          <Tooltip title="Add Fullscreen trigger" placement="top">
            <Box
              className={classnames(classes.chip, classes.fullscreen, {
                [classes.enabled]: learningCue.fullscreen,
                [classes.buttonDisabled]:
                  !isEmpty(learningCue.media) ||
                  !isEmpty(learningCue.knowledgeCheck),
              })}
              onClick={() => {
                if (
                  isEmpty(learningCue.media) &&
                  isEmpty(learningCue.knowledgeCheck)
                ) {
                  handleFullscreenChipClick()
                }
              }}
            >
              {learningCue.fullscreen && (
                <FullscreenIcon style={{ fontSize: 16 }} />
              )}
              {!learningCue.fullscreen && <AddIcon style={{ width: 16 }} />}
            </Box>
          </Tooltip>
        )}
        {lockdown && !learningCue.fullscreen && (
          <div className={classes.chipSpacer} />
        )} */}
        <LearningCueChip
          locked={lockdown}
          learningCue={learningCue}
          type="Ready Check"
          property="readyCheck"
          ActiveIcon={ResumeIcon}
          hover={hovered}
          onClick={handleReadyCheckChipClick}
        />
        {/* {showChip(learningCue.readyCheck) && (
          <Tooltip title="Add Ready Check trigger" placement="top">
            <Box
              className={classnames(classes.chip, classes.readyCheck, {
                [classes.enabled]: learningCue.readyCheck,
              })}
              onClick={handleReadyCheckChipClick}
            >
              {learningCue.readyCheck && (
                <ResumeIcon style={{ width: 12, height: 12 }} />
              )}
              {!learningCue.readyCheck && <AddIcon style={{ width: 16 }} />}
            </Box>
          </Tooltip>
        )}
        {lockdown && !learningCue.readyCheck && (
          <div className={classes.chipSpacer} />
        )} */}
        <LearningCueChip
          locked={lockdown}
          learningCue={learningCue}
          type="Notes"
          property="prefilledNotes"
          ActiveIcon={NotesIcon}
          hover={hovered}
          onClick={handleNotesChipClick}
        />
        {/* {showChip(learningCue.prefilledNotes) && (
          <Tooltip title="Add Notes trigger" placement="top">
            <Box
              className={classnames(classes.chip, classes.notes, {
                [classes.enabled]: learningCue.prefilledNotes,
              })}
              onClick={handleNotesChipClick}
            >
              {learningCue.prefilledNotes && (
                <NotesIcon style={{ fontSize: 16 }} />
              )}
              {!learningCue.prefilledNotes && <AddIcon style={{ width: 16 }} />}
            </Box>
          </Tooltip>
        )}
        {lockdown && !learningCue.notes && (
          <div className={classes.chipSpacer} />
        )} */}
      </Box>
      <Box
        className={classes.chips}
        display="flex"
        justifyContent="flex-end"
        flexGrow={1}
      >
        <FormControlLabel
          control={
            <Checkbox
              className={classes.checkbox}
              checked={learningCue.force_pause}
              name="force_pause"
              onChange={handleCheckboxChange}
              disabled={learningCue.knowledgeCheck !== undefined || lockdown}
            />
          }
          label="Force pause"
        />
      </Box>
      <Box className={classes.buttons}>
        <IconButton
          size="small"
          className={classes.moreButton}
          onClick={handleOpenMenu}
          disabled={lockdown}
        >
          <MoreHorizIcon style={{ fontSize: 16 }} />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={handleMenuClick("remove")}>
            <ListItemIcon className={classes.listItemIcon}>
              <RemoveCircleOutlineIcon className={classes.removeIcon} />
            </ListItemIcon>
            <ListItemText primary="Remove" />
          </MenuItem>
          <MenuItem onClick={handleMenuClick("copy")}>
            <ListItemIcon className={classes.listItemIcon}>
              <FileCopyOutlinedIcon style={{ fontSize: 16 }} />
            </ListItemIcon>
            <ListItemText primary="Copy" />
          </MenuItem>
        </Menu>
      </Box>
    </motion.div>
  )
}

LearningCueItem.propTypes = {
  learningCue: PropTypes.object,
  updateTime: PropTypes.func,
  lockdown: PropTypes.bool,
}

export default LearningCueItem
