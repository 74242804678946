import { Box, LinearProgress } from "@material-ui/core"
import { getLesson } from "actions/lessons"
import resourcesColor from "assets/Icons/colored-glasses.svg"
import analyticsColor from "assets/Icons/colored-pie-chart.svg"
import cuesColor from "assets/Icons/colored-plus.svg"
import contentColor from "assets/Icons/colored-video.svg"
import analytics from "assets/Icons/feather-pie-chart.svg"
import cues from "assets/Icons/feather-plus-circle.svg"
import content from "assets/Icons/feather-video.svg"
import resources from "assets/Icons/noun_Glasses_1182135.svg"
import ContentTabs from "components/Common/ContentTabs"
import LessonHeader from "components/Lessons/LessonHeader"
import UnsavedChanges from "components/Lessons/UnsavedChanges"
import AppContext from "contexts/App"
import { LearningCuesContainer } from "contexts/LearningCues"
import { LessonContainer } from "contexts/Lesson"
import KnowledgeCheckEditorDialog from "dialogs/LearningCueDialogs/KnowledgeCheckEditor"
import MediaUploadDialog from "dialogs/LearningCueDialogs/MediaUpload"
import SentimentQuestionsEditor from "dialogs/LearningCueDialogs/SentimentQuestionsEditor"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useContext, useEffect } from "react"
import { useQuery } from "react-query"
import Router from "router"

import useStyles from "./styles"

const Lesson = ({ routes = [], ...rest }) => {
  const classes = useStyles()
  const lessonId = +rest?.match?.params?.lesson_id

  const { authUser, setPageTitle, setPageSubTitle } = useContext(AppContext)

  const { data: lesson, isLoading: lessonLoading } = useQuery(
    ["lesson", lessonId],
    () => getLesson(lessonId)
  )

  //set lesson title and subtitle
  useEffect(() => {
    setPageTitle("Lessons")
  }, [lessonId])

  const withAnalytics = [
    {
      label: "Lesson Content",
      link: `/lessons/${lessonId}`,
      icon1: content,
      icon2: contentColor,
    },
    {
      label: "Learning Cues",
      link: `/lessons/${lessonId}/cues`,
      icon1: cues,
      icon2: cuesColor,
      TabProps: {
        disabled: lesson ? lesson.video_id === null : true,
      },
    },
    {
      label: "Resources",
      link: `/lessons/${lessonId}/resources`,
      icon1: resources,
      icon2: resourcesColor,
    },
    {
      label: "Analytics",
      link: `/lessons/${lessonId}/analytics`,
      icon1: analytics,
      icon2: analyticsColor,
    },
  ]

  const withoutAnalytics = [
    {
      label: "Lesson Content",
      link: `/lessons/${lessonId}`,
      icon1: content,
      icon2: contentColor,
    },
    {
      label: "Learning Cues",
      link: `/lessons/${lessonId}/cues`,
      icon1: cues,
      icon2: cuesColor,
      TabProps: {
        disabled: lesson ? lesson.video_id === null : true,
      },
    },
    {
      label: "Resources",
      link: `/lessons/${lessonId}/resources`,
      icon1: resources,
      icon2: resourcesColor,
    },
  ]

  const tabs =
    authUser?.access_analytics === 1 ? withAnalytics : withoutAnalytics

  if (lessonLoading) {
    return <LinearProgress color="primary" />
  }

  return (
    <Page>
      <LessonContainer lesson={lesson} lessonLoading={lessonLoading}>
        <LearningCuesContainer lesson={lesson}>
          {/* <QuestionsContainer> */}
          <Box display="flex" flexDirection="column" height="100%">
            <Box flexShrink={0} flexGrow={0}>
              <LessonHeader lessonId={lessonId} />
              <ContentTabs tabs={tabs} />
            </Box>
            <Box flexGrow={1} overflow="auto">
              <Router routes={routes} {...rest} />
            </Box>
          </Box>
          <UnsavedChanges />
          <MediaUploadDialog />
          <KnowledgeCheckEditorDialog />
          <SentimentQuestionsEditor />
        </LearningCuesContainer>
      </LessonContainer>
    </Page>
  )
}

Lesson.propTypes = {
  routes: PropTypes.array,
}

export default Lesson
