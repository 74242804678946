import dayjs from "dayjs"

const sortHelper = (array, type, value, direction) => {
  //function to sort the data
  //data=>array
  //type=> type of sorting
  //value=> what value in data is sorted
  //direction ASC or DSC

  if (type === "string" && array?.length > 0) {
    if (direction === "ASC") {
      return array?.sort((a, b) => a[value].localeCompare(b[value]))
    } else if (direction === "DSC") {
      return array?.sort((a, b) => b[value].localeCompare(a[value]))
    } else {
      return array?.sort((a, b) => a.id - b.id)
    }
  } else if (type === "duration" && array?.length > 0) {
    if (direction === "ASC") {
      return array?.sort(
        (a, b) =>
          Number(a?.video?.duration || 0) - Number(b?.video?.duration || 0)
      )
    } else if (direction === "DSC") {
      return array?.sort(
        (a, b) =>
          Number(b?.video?.duration || 0) - Number(a?.video?.duration || 0)
      )
    } else {
      return array?.sort((a, b) => a.id - b.id)
    }
  } else if (type === "number" && array?.length > 0) {
    //change the value to lesson_count that is how is comming in the data base
    value = "lesson_count"
    if (direction === "ASC") {
      return array?.sort((a, b) => a[value] - b[value])
    } else if (direction === "DSC") {
      return array?.sort((a, b) => b[value] - a[value])
    } else {
      return array?.sort((a, b) => a.id - b.id)
    }
  } else if (type === "date" && array?.length > 0) {
    //change the value to updated that is how is comming in the data base
    value = "updated"
    if (direction === "ASC") {
      return array.sort((a, b) => a[value].unix() - b[value].unix())
    } else if (direction === "DSC") {
      return array.sort((a, b) => b[value].unix() - a[value].unix())
    } else {
      return array?.sort((a, b) => a.id - b.id)
    }
  }
}

export default sortHelper
