import LoaderButton from "@bit/c_t.components.loader-button"
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import animateScrollTo from "animated-scroll-to"
import AddedCourseCard from "components/Paths/AddedCourseCard"
import Content from "components/Questions/Content"
import FinalAssessment from "components/Questions/models/FinalAssessment"
import {
  FillInTheBlank,
  MultipleChoice,
  TrueFalse,
} from "components/Questions/models/Question"
import Sidebar from "components/Questions/Sidebar"
import AppContext from "contexts/App"
import CourseContext from "contexts/Course"
import usePrevious from "hooks/usePrevious"
import { isEmpty } from "lodash"
import { observer } from "mobx-react"
import { useContext, useEffect, useRef, useState } from "react"
import { useQueryClient } from "react-query"
import { useHistory } from "react-router-dom"

import useStyles from "./styles"

const questionTypes = [
  {
    name: "Multiple Choice",
    model: MultipleChoice,
    type: "multiple_choice",
  },
  {
    name: "True / False",
    model: TrueFalse,
    type: "true_false",
  },
]

const AddAssessmentDialog = () => {
  const [store, setStore] = useState({})

  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const { dialogs, closeDialog, openSnackBar } = useContext(AppContext)
  const thisDialog = dialogs?.["addAssessmentDialog"] || {}
  const { open = false, data = {} } = thisDialog
  const { courseId, ...courseFinallAssessmentData } = data
  const { lockdown, ...restData } = courseFinallAssessmentData
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))
  const { createAssessmentMutation, getAssessmentMutation } = useContext(
    CourseContext
  )
  const scrollAreaRef = useRef()
  const [showErrors, setShowErrors] = useState(false)
  //number of questions to answer
  const [numQuestions, setNumQuestions] = useState(12)
  //final assessment pass score
  const [passScore, setPassScore] = useState(75)
  //set local state with the data from api
  const [localState, setLocalState] = useState()
  //state to know if the user is saving
  const [tryToSave, setTryToSave] = useState(false)
  //loading state for the handle save function
  const [loading, setLoading] = useState(false)
  const queryClient = useQueryClient()

  const handleClose = () => {
    //clear states
    setTimeout(() => {
      setNumQuestions(12)
      setPassScore(75)
      setTryToSave(false)
    }, 500)
    closeDialog("addAssessmentDialog", false)
  }

  useEffect(() => {
    //set data when open dialog
    if (courseId) {
      getAssessmentMutation
        .mutateAsync(courseId)
        .then((res) => {
          setLocalState(() => {
            let courseFinallAssessment
            courseFinallAssessment = FinalAssessment.create({
              ...res,
              questions: res?.questions?.map((q) =>
                questionTypes.find((t) => t.type === q.type).model.create(q)
              ),
            })
            console.log(courseFinallAssessment)
            setStore(courseFinallAssessment)
          })
          setPassScore(res.pass_score)
          setNumQuestions(res?.question_count)
        })
        .catch((err) => {
          setLocalState(() => {
            let courseFinallAssessment
            courseFinallAssessment = FinalAssessment.create()
            setStore(courseFinallAssessment)
          })
        })
    }
  }, [courseId])

  const previousQuestionsLength = usePrevious(store?.questions?.length || 0)

  useEffect(() => {
    if (
      store?.questions?.length > previousQuestionsLength &&
      previousQuestionsLength !== 0
    ) {
      const lastQuestionId = store.questions[store.questions.length - 1].id
      animateScrollTo(
        document.querySelector(`#question-id-${lastQuestionId}`),
        {
          elementToScroll: scrollAreaRef.current,
        }
      )
    }
  }, [previousQuestionsLength, store?.questions?.length])

  //pass score error
  let passScoreError =
    (passScore < 0 || passScore > 100 || !passScore) && tryToSave
  //number of questions error

  let numberQuestionsError = (numQuestions < 0 || !numQuestions) && tryToSave
  //check if at least there is one valid question
  let questionError = store?.questions?.length === 0 && tryToSave
  //error if questions to answer are greater than quesition bank
  let bankError = store?.questions?.length < numQuestions && tryToSave
  //all errors
  let errors =
    passScore < 0 ||
    passScore > 100 ||
    !passScore ||
    numQuestions < 0 ||
    !numQuestions ||
    store?.questions?.length === 0 ||
    store?.questions?.length < numQuestions

  const handleSave = () => {
    //check if there is errors or the questions are valid
    if (errors || !store?.isValid) {
      setShowErrors(true)
      setTryToSave(true)
    } else if (!errors && store?.isValid) {
      setLoading(true)
      setShowErrors(false)
      setTryToSave(false)
      let data = store?.toJSON()
      let newData = {}
      newData.question_count = numQuestions
      newData.pass_score = passScore
      newData = { ...newData, ...data }
      delete newData.id
      createAssessmentMutation
        .mutateAsync(newData)
        .then((res) => {
          queryClient.setQueryData(["course", courseId], (oldState) => {
            oldState.assessment_id = res.id
            oldState.assessment = res
            return oldState
          })

          openSnackBar({ message: "Assessment created" })
          handleClose()
          setLoading(false)
        })
        .catch((err) => {
          openSnackBar({ message: "Error creating assessment" })
          setLoading(false)
        })
    }
  }

  return (
    <Dialog
      onEntering={setLocalState}
      classes={{
        paper: !lockdown ? classes.dialogPaper : classes.dialogPaperLocked,
      }}
      open={open}
      fullScreen={smDown}
    >
      {getAssessmentMutation?.isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <DialogTitle disableTypography className={classes.dialogTitle}>
            <Box display="flex">
              <Typography
                variant="h6"
                component="h2"
                style={{ fontWeight: 700, marginBottom: 8 }}
              >
                Test Bank {" " + "(" + store?.questions?.length + ")"}
              </Typography>
              <Box className={classes.questionsNumber}>
                <Typography color="secondary" className={classes.inputLabel}>
                  Questions
                </Typography>
                <TextField
                  value={numQuestions}
                  onChange={(e) => setNumQuestions(e.target.value)}
                  variant="filled"
                  disabled={lockdown}
                  style={
                    bankError
                      ? { width: "60px" }
                      : { width: "60px", color: "red" }
                  }
                  InputProps={bankError ? { className: classes.root } : null}
                />
                {numberQuestionsError ? (
                  <Typography className={classes.requiredText}>
                    Greater or equal than 0
                  </Typography>
                ) : null}
              </Box>

              <Box className={classes.minPassScore}>
                <Typography color="secondary" className={classes.inputLabel}>
                  Min pass score
                </Typography>
                <TextField
                  value={passScore}
                  onChange={(e) => setPassScore(e.target.value)}
                  style={{ width: "60px" }}
                  variant="filled"
                  disabled={lockdown}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
                {passScoreError ? (
                  <Typography className={classes.requiredText}>
                    Between 0 and 100
                  </Typography>
                ) : null}
              </Box>
            </Box>
          </DialogTitle>

          <DialogContent className={classes.dialogContent}>
            {/* <Box display="flex"> */}
            {!lockdown && (
              <Sidebar
                insideCourse={true}
                questionTypes={questionTypes}
                store={store}
              />
            )}
            <Box
              paddingLeft={2.5}
              paddingRight={2}
              flexGrow="1"
              overflow="auto"
              ref={scrollAreaRef}
            >
              {store?.questions?.length === 0 && (
                <Box
                  display="flex"
                  justifyContent="center"
                  height="100%"
                  alignItems="center"
                >
                  <Typography>
                    Click a question type on the left to add a question to this{" "}
                    <Typography
                      component="span"
                      style={{ fontWeight: 700 }}
                      color="secondary"
                    >
                      Final Assessment
                    </Typography>
                    {questionError ? (
                      <Typography className={classes.requiredText}>
                        Add at least one question
                      </Typography>
                    ) : null}
                  </Typography>
                </Box>
              )}
              {store?.questions?.map?.((question) => (
                <Content
                  key={question}
                  question={question}
                  showingErrors={showErrors}
                  lockdown={lockdown}
                  usesAttempts={false}
                />
              ))}
              {bankError ? (
                <Typography className={classes.requiredText}>
                  Total number of questions needs to be greater or equal to
                  number of randomized questions
                </Typography>
              ) : null}
            </Box>
            {/* </Box> */}
          </DialogContent>
          <DialogActions style={{ justifyContent: "space-between" }}>
            <Box>
              {!isEmpty(restData) && (
                <Button
                  className={classes.removeButton}
                  // onClick={handleRemove}
                  type="button"
                  size="small"
                  disabled={lockdown}
                >
                  Remove
                </Button>
              )}
            </Box>
            <Box>
              <Button
                onClick={handleClose}
                type="button"
                style={{ marginRight: "2vw" }}
              >
                Close
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSave}
                disabled={lockdown}
              >
                {loading ? (
                  <CircularProgress size={"18px"} />
                ) : (
                  <Typography>Done</Typography>
                )}
              </Button>
            </Box>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

AddAssessmentDialog.propTypes = {}

export default observer(AddAssessmentDialog)
