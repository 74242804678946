import api from "store/api"
import serialize from "store/serialize"

export const listCourses = (data) => {
  return api
    .get("/courses", data)
    .then((res) => serialize("course", res.data.data))
}

export const listPublishedCourses = () => {
  return api
    .get("/published_courses")
    .then((res) => serialize("course", res.data.data))
}

export const createCourse = (data) => {
  return api.post("/courses", data).then((res) => res.data)
}

export const updateCourse = (data, id) => {
  return api.put(`/courses/${id}`, data).then((res) => res.data)
}
export const getCourse = (id) => {
  return api
    .get(`/courses/${id}`)
    .then((res) => serialize("course", res.data.data))
}

export const listTags = (data) => {
  return api.get("/tags", data).then((res) => serialize("tag", res.data.data))
}

export const listCategories = (data) => {
  return api
    .get("/categories", data)
    .then((res) => serialize("category", res.data.data))
}

export const createTags = (data, id) => {
  return api.post(`/course_tags/${id}`, data).then((res) => res.data)
}

export const addLesson = (data, id) => {
  return api.post(`/course_lessons/${id}`, data).then((res) => res.data)
}

export const addCategory = (data, id) => {
  return api.post(`/course_categories/${id}`, data).then((res) => res.data)
}

export const publishCourse = (id) => {
  return api.post(`/publish_course/${id}`).then((res) => res.data)
}

export const unpublishCourse = (id) => {
  return api.post(`/unpublish_course/${id}`).then((res) => res.data)
}

export const archiveCourse = (id) => {
  return api.post(`/archive_course/${id}`).then((res) => res.data)
}

export const unarchiveCourse = (id) => {
  return api.post(`/unarchive_course/${id}`).then((res) => res.data)
}

export const setCourseResouces = (data, id) => {
  return api.post(`/course_resources/${id}`, data).then((res) => res.data)
}

export const setPricing = (data, id) => {
  return api.post(`/course_pricing/${id}`, data).then((res) => res.data)
}

export const newVersion = (data, id) => {
  return api.post(`/course_version/${id}`, data).then((res) => res.data)
}

export const copyCourse = (data, id) => {
  return api.post(`/copy_course/${id}`, data).then((res) => res.data)
}

export const courseViewPDF = (id) =>
  api.get(`/course_view_resource/${id}`).then((res) => res.data.url)

export const courseAnalytics = (id) =>
  api.get(`/course_analytics/${id}`).then((res) => {
    return res.data.data
  })

export const setPublishCourseDate = (data, id) =>
  api.post(`/course_publish_date/${id}`, data).then((res) => res.data)

export const createAssessment = (data, courseId) =>
  api
    .post(`/courses/${courseId}/assessment`, data)
    .then((res) => res.data.assessment)

export const getAssessment = (courseId) =>
  api.get(`/courses/${courseId}/assessment`).then((res) => res.data.data)

export const getCoursePreview = (courseId) =>
  api.get(`/courses/${courseId}/request_preview`).then((res) => res.data.token)

export const setCourseLab = (courseId, data) =>
  api
    .post(`/course_lab/${courseId}`, data)
    .then((res) => serialize("course", res.data.data))
