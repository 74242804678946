import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  paperRow: {
    border: "1px solid #E2E2E2",
    borderRadius: "8px",
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    // height: "64px",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      width: "100%",
      height: "100% !important",
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
}))

export default styles
