import { Box } from "@material-ui/core"
import AppContext from "contexts/App"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import { useContext } from "react"
import Appbar from "../Appbar"
import Sidebar from "../Sidebar"
import useStyles from "./styles"

const PageWrapper = ({ children }) => {
  const classes = useStyles()

  const { token } = useContext(AppContext)
  return (
    <Box className={classes.pageWrapper}>
      {!isEmpty(token) && (
        <Box className={classes.sideBar}>
          <Sidebar />
        </Box>
      )}
      <Box className={isEmpty(token) ? classes.loginPage : classes.mainContent}>
        {!isEmpty(token) && (
          <Box className={classes.appBar}>
            <Appbar />
          </Box>
        )}
        <Box className={classes.pageContent}>{children}</Box>
      </Box>
    </Box>
  )
}

PageWrapper.propTypes = {
  children: PropTypes.node,
}

export default PageWrapper
