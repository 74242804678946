import { listInstructors } from "actions/instructors"
import { updateInstructor } from "actions/instructors"
import { removeInstructor } from "actions/instructors"
import { listPublishedLessons } from "actions/lessons"
import PropTypes from "prop-types"
import { createContext, useContext, useState } from "react"
import { useMutation, useQuery } from "react-query"

import AppContext from "./App"

const InstructorsContext = createContext()
export default InstructorsContext

export const InstructorsContainer = ({ children }) => {
  const { authUser } = useContext(AppContext)

  const [selectedInstructor, setSelectedInstructor] = useState()

  const {
    data: instructors,
    isLoading: instructorsLoading,
    refetch: refetchInstructors,
  } = useQuery(["instructors", authUser], () => listInstructors(), {
    enabled: !!authUser,
  })

  const instructor = instructors?.find(
    (x) => x.id === Number(selectedInstructor)
  )

  const updateInstructorMutation = useMutation((data) =>
    updateInstructor(data.values, data.id)
  )

  const deleteInstructorMutation = useMutation((data) => removeInstructor(data))

  const { data: lessons } = useQuery(
    "publishedLessons",
    () => listPublishedLessons(),
    { enabled: !!authUser }
  )

  return (
    <InstructorsContext.Provider
      value={{
        selectedInstructor,
        setSelectedInstructor,
        instructor,
        instructors,
        instructorsLoading,
        updateInstructorMutation,
        deleteInstructorMutation,
        refetchInstructors,
      }}
    >
      {children}
    </InstructorsContext.Provider>
  )
}

InstructorsContainer.propTypes = {
  children: PropTypes.node,
}
