const LessonModel = {
  created: "date",
  updated: "date",
  description: "string",
  video: ["hasOne", "video"],
  thumbnail: "object",
  instructors: ["hasMany", "instructor"],
  versions: ["hasMany", "lesson"],
}

export default LessonModel
