import { CloudinaryContext } from "cloudinary-react"
import PageWrapper from "components/Common/PageWrapper"
import Interceptor from "components/interceptor"
import Snackbar from "components/Snackbar"
import { AppContainer } from "contexts/App"
import { InstructorsContainer } from "contexts/Instructors"
import { UsersContainer } from "contexts/Users"
import ConfirmationClickDialog from "dialogs/ConfirmationClickDialog"
import ConfirmationTypingDialog from "dialogs/ConfirmationTypingDialog"
import ConfirmPassword from "dialogs/ConfirmPassword"
import CreateAdminDialog from "dialogs/CreateAdmin"
import CreateCertificationDialog from "dialogs/CreateCertificationDialog"
import CreateClientDialog from "dialogs/CreateClientDialog"
import CreateCopyDialog from "dialogs/createCopyDialog"
import CreateCourseLessonDialog from "dialogs/CreateCourseLessonDialog"
import CreateDiscountDialog from "dialogs/CreateDiscountDialog"
import CreateInstructorDialog from "dialogs/CreateInstructorDialog"
import CreateLearningPathDialog from "dialogs/CreateLearningPathDialog"
import CreateNewVersionDialog from "dialogs/CreateNewVersionDialog"
import CropThumbnailDialog from "dialogs/CropThumbnailDialog"
import EditInstructorDialog from "dialogs/EditInstructorDialog"
import EditResourceDialog from "dialogs/EditResourceDialog"
import HistoryVersionDialog from "dialogs/HistoryVersionDialog"
import InstructorListDialog from "dialogs/InstructorListDialog"
import MyAccountDialog from "dialogs/MyAccount"
import PermissionsDialog from "dialogs/PermissionsDialog"
import SimpleConfirmDialog from "dialogs/SimpleConfirmDialog"
import UnsavedDataDialog from "dialogs/UnsavedDataDialog"
import UserDetailsDialog from "dialogs/UserDetailsDialog"
import ViewInstructorDialog from "dialogs/ViewInstructorDialog"
import { withRouter } from "react-router"
import Router, { routeConfig } from "router"

import useStyles from "./styles"

const App = () => {
  const { classes } = useStyles()

  return (
    <AppContainer>
      <InstructorsContainer>
        <UsersContainer>
          <PageWrapper>
            <CloudinaryContext
              style={{ height: "100%" }}
              cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
            >
              <Router routes={routeConfig} />
            </CloudinaryContext>
          </PageWrapper>
          <div id="dialog-holder">
            {/* LEAVE THIS IN PLACE FOR DIALOGS TO POPULATE INTO */}
            <MyAccountDialog />
            <CreateCourseLessonDialog />
            <CreateInstructorDialog />
            <CreateClientDialog />
            <CreateAdminDialog />
            <ConfirmPassword />
            <ConfirmationTypingDialog />
            <UserDetailsDialog />
            <HistoryVersionDialog />
            <EditResourceDialog />
            <EditInstructorDialog />
            <InstructorListDialog />
            <ViewInstructorDialog />
            <ConfirmationClickDialog />
            <CropThumbnailDialog />
            <UnsavedDataDialog />
            <CreateDiscountDialog />
            <CreateNewVersionDialog />
            <CreateCopyDialog />
            <CreateLearningPathDialog />
            <PermissionsDialog />
            <SimpleConfirmDialog />
            <CreateCertificationDialog />
          </div>
          <Snackbar />
          <Interceptor />
        </UsersContainer>
      </InstructorsContainer>
    </AppContainer>
  )
}

export default withRouter(App)
