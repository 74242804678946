import { Slider } from "@material-ui/core"
import { withStyles } from "@material-ui/styles"

const VideoPlaybar = withStyles((theme) => ({
  root: {
    color: "#fff",
    height: 6,
    padding: 0,
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -4.5,
    // marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 6,
    borderRadius: 4,
  },
  rail: {
    height: 6,
    borderRadius: 4,
  },
}))(Slider)

export default VideoPlaybar
