import { Box, IconButton, Typography } from "@material-ui/core"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import { observer } from "mobx-react"
import PropTypes from "prop-types"

import ResponseListWithCorrectAnswer from "../../ResponseListWithCorrectAnswer"
import useStyles from "./styles"

const FillInTheBlank = ({ question, isEdit, showErrors }) => {
  const classes = useStyles()

  // breaks out question ids for sorting of answer blocks
  const sortedList = Array.from(
    question.name.matchAll(/(\{:)[\w-]+(:\})/g)
  ).map((x) => x[0].replace(/[:{}]/g, ""))
  return (
    <Box display="flex" flexWrap="wrap">
      {sortedList.map((x, i) => {
        const sIndex = question.sets.findIndex((y) => "" + y.id === "" + x)
        const s = question.sets[sIndex]
        return (
          <Box key={s.id} className={classes.column}>
            <Typography className={classes.columnLabel}>
              Blank {sIndex + 1}
            </Typography>
            <ResponseListWithCorrectAnswer
              responseSet={s}
              isEdit={isEdit}
              showErrors={showErrors}
            />
          </Box>
        )
      })}
    </Box>
  )
}

FillInTheBlank.propTypes = {
  question: PropTypes.object,
  isEdit: PropTypes.bool,
  showErrors: PropTypes.bool,
}

export default observer(FillInTheBlank)
