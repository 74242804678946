import { Box, Tooltip } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import classnames from "clsx"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"

import useStyles from "./styles"

const LearningCueChip = ({
  locked,
  learningCue,
  type,
  property,
  ActiveIcon,
  disabled,
  hover,
  onClick,
}) => {
  const classes = useStyles()
  const propertyIsSet = !isEmpty(learningCue[property])

  return (
    <>
      {(!locked || propertyIsSet) && (
        <Tooltip title={`Add ${type} Cue`} placement="top">
          <Box
            className={classnames(classes.chip, {
              [classes.enabled]: propertyIsSet,
              [classes.buttonDisabled]: disabled,
              [classes.hovered]: hover,
              [classes[property]]: true,
            })}
            onClick={() => {
              if (!disabled) {
                onClick?.()
              }
            }}
          >
            {propertyIsSet ? (
              <ActiveIcon style={{ width: 16 }} />
            ) : (
              <AddIcon style={{ width: 16 }} />
            )}
          </Box>
        </Tooltip>
      )}
      {locked && !propertyIsSet && <div className={classes.chipSpacer} />}
    </>
  )
}

LearningCueChip.propTypes = {
  locked: PropTypes.bool,
  learningCue: PropTypes.object,
  type: PropTypes.string,
  property: PropTypes.string,
  ActiveIcon: PropTypes.node,
  disabled: PropTypes.bool,
  hover: PropTypes.bool,
  onClick: PropTypes.func,
}

export default LearningCueChip
