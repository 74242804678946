import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  sidebar: {
    width: 170,
    borderRight: "1px solid rgba(9, 41, 88, 0.28)",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2.75),
    boxSizing: "content-box",
    flexShrink: 0,
  },
  list: {
    padding: 0,
  },
  listItem: {
    padding: "12.5px 0px",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: 5,
    textAlign: "center",
    marginBottom: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#bdeaec",
    },
  },
  listItemText: {
    margin: 0,
    color: theme.palette.secondary.main,
    fontWeight: 700,
  },
}))

export default styles
