import { LinearProgress } from "@material-ui/core"
import LessonsContext, { LessonsContainer } from "contexts/Lessons"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useContext } from "react"
import Router from "router"
import useStyles from "./styles"

const Lessons = ({ routes = [], ...rest }) => {
  const classes = useStyles()

  return (
    <Page>
      <LessonsContainer>
        <LessonsWrapper>
          <Router routes={routes} {...rest} />
        </LessonsWrapper>
      </LessonsContainer>
    </Page>
  )
}

Lessons.propTypes = {
  routes: PropTypes.array,
}

const LessonsWrapper = ({ children }) => {
  const { lessonsLoading } = useContext(LessonsContext)
  if (lessonsLoading) {
    return <LinearProgress />
  } else {
    return children
  }
}

LessonsWrapper.propTypes = {
  children: PropTypes.node,
}

export default Lessons
