import LoaderButton from "@bit/c_t.components.loader-button"
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import { Search } from "@material-ui/icons"
import { listLessons } from "actions/lessons"
import { listPublishedLessons } from "actions/lessons"
import AddLessonCard from "components/courses/AddLessonCard"
import AppContext from "contexts/App"
import CourseContext from "contexts/Course"
import { uniqBy } from "lodash"
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react"
import { useQuery } from "react-query"
import { useHistory } from "react-router-dom"

import useStyles from "./styles"

const AddLessonDialog = () => {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const { dialogs, closeDialog, openSnackBar } = useContext(AppContext)
  const thisDialog = dialogs?.["addLessonDialog"] || {}
  const { open = false, data } = thisDialog
  const { data: lessons } = useQuery("lessons", listLessons)

  const publishedAndDraftLessons = useMemo(() => {
    return uniqBy(lessons, "container_id")?.filter?.((l) => !l.archived) || []
  }, [lessons])

  const smDown = useMediaQuery(theme.breakpoints.down("sm"))

  const { lessonsArray, setLessonsArray } = useContext(CourseContext)
  const [searchValue, setSearchValue] = useState("")
  // const [filteredLessons, setFilteredLessons] = useState([])
  const [lessonsComponentArray, setLessonsComponentArray] = useState([])

  //close dialog set search value to empty string and
  //set component array state to the context state
  const handleClose = () => {
    closeDialog("addLessonDialog", false)
    setSearchValue("")
    setLessonsComponentArray(lessonsArray)
  }

  //open Lesson dialog
  const openLessons = () => {
    history.push("/lessons")
    handleClose()
  }

  //close the dialog
  const addLesson = () => {
    setLessonsArray(lessonsComponentArray)
    handleClose()
  }
  ///set lessons array that is in context to a component state to only replace the context array
  //if the user click add button
  useLayoutEffect(() => {
    setLessonsComponentArray(lessonsArray)
  }, [lessonsArray])

  const getFilteredLessons = () => {
    return publishedAndDraftLessons?.filter((lesson) => {
      return lesson?.versions?.[lesson?.versions?.length - 1]?.name
        ?.toLowerCase?.()
        ?.includes?.(searchValue.toLowerCase())
    })
  }

  const filteredLessons =
    searchValue.length > 0 ? getFilteredLessons() : publishedAndDraftLessons

  return (
    <Dialog
      classes={{ paperWidthSm: classes.dialogContainer }}
      open={open}
      fullScreen={smDown}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={classes.dialogTop}
        >
          <Typography variant="h2" className={classes.title}>
            ADD LESSON
          </Typography>
          <TextField
            placeholder="Search lessons"
            className={classes.search}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                setSearchValue(e.target.value)
              }
            }}
            onChange={(e) => e.target.value === "" && setSearchValue("")}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start" style={{ marginLeft: "10px" }}>
                  <Search className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        {!lessons ? (
          <CircularProgress />
        ) : (
          filteredLessons?.map((lesson) => {
            return (
              <AddLessonCard
                lesson={lesson}
                key={lesson?.id}
                setLessonsComponentArray={setLessonsComponentArray}
                lessonsComponentArray={lessonsComponentArray}
              />
            )
          })
        )}
      </DialogContent>
      <DialogActions className={classes.DialogActions}>
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          marginLeft="20px"
          marginRight="20px"
        >
          <Box>
            <Button className={classes.remove} onClick={openLessons}>
              Create Lesson
            </Button>
          </Box>
          <Box>
            <Button
              onClick={handleClose}
              style={{ height: "40px", marginRight: "10px" }}
            >
              Cancel
            </Button>
            <LoaderButton
              onClick={addLesson}
              classoverrides={{
                wrapper: classes.button,
                button: {
                  root: classes.buttonRoot,
                },
              }}
              variant="contained"
              color="secondary"
              //   working={isSubmitting}
              size="small"
            >
              add
            </LoaderButton>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

AddLessonDialog.propTypes = {}

export default AddLessonDialog
