import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  contentContainer: {
    // overflow: "scroll",
    maxHeight: "400px",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "100%",
    },
  },
  topContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  leftContainer: {
    width: "145px",
    marginRight: theme.spacing(1),
  },
  rightContainer: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  title: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
  },
  inputBackgroundColor: {
    backgroundColor: "#F4F4F4",
    borderRadius: "4px",
  },
  helper: {
    marginLeft: "0 !important",
    marginRight: "0 !important",
  },
  disabled: {
    color: "black",
  },
  inputBio: {
    backgroundColor: "#F4F4F4",
    borderRadius: "4px",
    paddingTop: "10px !important",
    paddingBottom: "10px !important",
    paddingLeft: "10px",
    paddingRight: "10px",
    "&.Mui-focused": {
      backgroundColor: "rgba(0, 0, 0, 0.09)",
    },
  },
  cancel: {
    marginRight: theme.spacing(1),
  },
  remove: {
    color: "#FF5E69",
    border: "1px solid #A21F28",
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(2),
    },
  },

  textFieldContainer: {
    [theme.breakpoints.down("xs")]: {
      height: 150,
      margin: 0,
    },
  },
  textField: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(2),
      width: "100%",
    },
  },
  buttonsContainer: {
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
      justifyContent: "center",
    },
  },
}))

export default styles
