import { makeStyles } from "@material-ui/core/styles"
import avatar from "assets/person-add.svg"

const styles = makeStyles((theme) => ({
  pdfBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    backgroundColor: "#D0D5DD",
    cursor: "pointer",
  },
}))

export default styles
