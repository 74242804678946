import { Box, CircularProgress, Typography } from "@material-ui/core"
import ListTitle from "components/Common/ListTitle"
import NoData from "components/Common/NoData"
import LessonsCard from "components/Lessons/LessonsCard"
import AppContext from "contexts/App"
import PropTypes from "prop-types"
import { useContext, useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useHistory } from "react-router-dom"
import sortHelper from "utils/sortHelper"

import useStyles from "./styles"

const LessonCardList = ({
  searchValue,
  sort,
  lessons,
  openCreateDialog,
  statusState,
  setStatusNumber,
}) => {
  const classes = useStyles()
  const [filteredData, setFilteredData] = useState([])
  const { setPageSubTitle } = useContext(AppContext)
  //amount of data that we show using the infinite scroll

  const [showedData, setShowedData] = useState([])

  const history = useHistory()

  const handleRoute = (id) => {
    history.push(`/lessons/${id}`)
  }

  //set draft, published, unpublished, archived length
  useEffect(() => {
    setStatusNumber({
      published: lessons.filter((x) => x.published === 1).length,
      unpublished: lessons.filter(
        (x) =>
          x.published === 0 && x.has_been_published === 1 && x.archived === 0
      ).length,
      draft: lessons.filter(
        (x) =>
          x.published === 0 && x.has_been_published === 0 && x.archived === 0
      ).length,
      archived: lessons.filter((x) => x.archived === 1 && x.published === 0)
        .length,
    })
  }, [lessons])

  //filters depending on the searchValue or sort
  useEffect(() => {
    if (lessons) {
      let newArray = [...lessons]

      if (statusState === "published") {
        newArray = newArray.filter((x) => x.published === 1)
      } else if (statusState === "unpublished") {
        newArray = newArray.filter(
          (x) =>
            x.published === 0 && x.has_been_published === 1 && x.archived === 0
        )
      } else if (statusState === "draft") {
        newArray = newArray.filter(
          (x) =>
            x.published === 0 && x.has_been_published === 0 && x.archived === 0
        )
      } else if (statusState === "archived") {
        newArray = newArray.filter((x) => x.archived === 1 && x.published === 0)
      }
      if (searchValue.length > 0 || sort?.name !== "") {
        if (searchValue.length > 0) {
          newArray = newArray?.filter((x) =>
            x.name.toLowerCase().includes(searchValue.toLowerCase())
          )
        }
        if (sort?.name !== "") {
          //util function to sort the data
          newArray = sortHelper(
            newArray,
            sort?.type,
            sort?.name,
            sort?.direction
          )
        }
      }
      setFilteredData(newArray)
      //show 20 first elements of the newArray
      setShowedData(newArray?.slice(0, 20 || []))
      //set lessons  subtitle depends the search
      setPageSubTitle(newArray?.length)
    }
  }, [searchValue, sort, lessons, statusState])

  //loadData using infinite scroll
  const loadData = () => {
    setShowedData(() => filteredData.slice(0, showedData.length + 20))
  }

  //send the user to the top if a status state, sort or search value change
  useEffect(() => {
    const element = document.getElementById("lessonsListScroll")
    if (element) {
      element.scrollTo(0, 0)
    }
  }, [statusState, searchValue, sort])

  return (
    <Box className={classes.container} overflow="scroll" id="lessonsListScroll">
      {filteredData?.length > 0 ? (
        <ListTitle
          list={[
            { name: "", size: "16%" },
            { name: "Title", size: "38%" },
            { name: "Instructor", size: "20%" },
            { name: "Version", size: "19%" },
            { name: "Duration", size: "9%" },
          ]}
        />
      ) : null}
      {filteredData?.length > 0 ? (
        <InfiniteScroll
          style={{ overflow: "hidden" }}
          dataLength={showedData?.length || 0}
          scrollableTarget="lessonsListScroll"
          next={loadData}
          loader={
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          }
          hasMore={filteredData?.length > showedData?.length || false}
          endMessage={
            <Box width="100%" textAlign="center">
              {filteredData.length > 20 && (
                <Typography className={classes.postsInfiniteText}>
                  End of Lessons List
                </Typography>
              )}
            </Box>
          }
        >
          {showedData.map((lesson) => {
            return (
              <LessonsCard
                key={lesson?.id}
                name={lesson?.name}
                openLesson={() => handleRoute(lesson?.id)}
                version={lesson?.version}
                lesson={lesson}
                updatedDate={
                  typeof lesson?.updated === "string"
                    ? lesson?.udpated
                    : lesson?.updated?.format("M/D/YYYY")
                }
                creator={lesson?.creator_admin_id}
                published={lesson?.published}
                instructorsArray={lesson?.instructors}
                duration={lesson?.video?.duration || 0}
              />
            )
          })}
        </InfiniteScroll>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          minHeight="80vh"
        >
          <NoData
            text="You have not created any courses"
            buttonFunc={openCreateDialog}
            searchValue={searchValue}
            buttonText="create my first course"
            length={lessons.length}
            searchLength={filteredData.length}
          />
        </Box>
      )}
    </Box>
  )
}

LessonCardList.propTypes = {
  lessons: PropTypes.array,
  searchValue: PropTypes.string,
  sort: PropTypes.object,
  openCreateDialog: PropTypes.func,
  statusState: PropTypes.string,
  setStatusNumber: PropTypes.func,
}

export default LessonCardList
