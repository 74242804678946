import { Box, Tab, Tabs, Typography } from "@material-ui/core"
import { findIndex } from "lodash"
import PropTypes from "prop-types"
import { useMemo } from "react"
import { Link, useLocation } from "react-router-dom"

import useStyles from "./styles"

const ContentTabs = ({ tabs }) => {
  const location = useLocation()
  const classes = useStyles()

  const currentTab = useMemo(() => {
    return findIndex(tabs, { link: location.pathname })
  }, [location.pathname])

  return (
    <Box
      height={50}
      style={{
        backgroundColor: "#fff",
        position: "relative",
        boxShadow: "0px 3px 6px #00000015",
      }}
    >
      <Tabs
        style={{ height: "100%" }}
        value={currentTab}
        aria-label="content tabs"
        TabIndicatorProps={{ style: { display: "none" } }}
        variant="scrollable"
        classes={{
          flexContainer: classes.flexContainer,
        }}
      >
        {tabs.map((tab, i) => {
          return (
            <Tab
              key={i}
              component={Link}
              to={tab.link}
              label={
                <Box display="flex" alignItems="center">
                  {currentTab === i ? (
                    <img src={tab.icon2} alt="" className={classes.image} />
                  ) : (
                    <img src={tab.icon1} alt="" className={classes.image} />
                  )}

                  <Typography
                    variant="subtitle2"
                    color={currentTab === i ? "secondary" : "primary"}
                    className={classes.label}
                  >
                    {tab.label}
                  </Typography>
                </Box>
              }
              className={currentTab === i ? classes.current : classes.tab}
              {...tab?.TabProps}
            />
          )
        })}
      </Tabs>
    </Box>
  )
}

ContentTabs.propTypes = {
  tabs: PropTypes.array,
}

export default ContentTabs
