import api from "store/api"
import serialize from "store/serialize"

export const listAppUsers = (data) => {
  return api.post("/app/users", data).then((res) => {
    return res.data
  })
}

export const getWailist = () => {
  return api
    .get("/waitlist")
    .then((res) => serialize("waitlist", res.data.data))
}

export const listAdmins = () => {
  return api.get("/users").then((res) => serialize("admin", res.data.data))
}

export const publishedCoursesCount = () => {
  return api.get("/courses/has_been_published").then((res) => res.data.count)
}
export const createAdmin = (data) => {
  return api.post("/users/invite", data).then((res) => res.data)
}

export const deleteAdmin = (id) => {
  return api.delete(`/users/${id}`).then((res) => res.data)
}

export const setPermissions = (data) => {
  return api.post("/users/permissions", data).then((res) => res.data)
}

export const getMe = () => {
  return api.get("/users/me").then((res) => res.data.data)
}

export const updateMe = (data) => {
  return api.put("/users/me", data).then((res) => {
    return res.data
  })
}

export const updatePassword = (data) => {
  return api.put("/users/me/password", data).then((res) => res.data)
}

export const magicAuth = (data) => {
  return api.post("/users/auth/magic", data).then((res) => {
    api.defaults.headers.common["Authorization"] = res.data.token
    return res.data
  })
}

export const forgotPassword = (data) => {
  return api.post("/users/forgot", data).then((res) => {
    api.defaults.headers.common["Authorization"] = res.data.token
    return res.data
  })
}

export const resetPassword = (data) => {
  return api.post("/users/reset/magic", data).then((res) => res.data)
}

export const getUsersCourseDetails = (id) => {
  return api.get(`/users/courses/${id}`).then((res) => res.data)
}

export const listUserInvites = (id) => {
  return api.get(`/users/invites/${id}`).then((res) => res.data)
}
