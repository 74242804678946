import LoaderButton from "@bit/c_t.components.loader-button"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import Avatar from "components/Common/Avatar"
import AvatarUpload from "components/Common/AvatarUpload"
import App from "contexts/App"
import InstructorsContext from "contexts/Instructors"
import { Form, Formik } from "formik"
import React, { useContext, useState } from "react"
import { useQueryClient } from "react-query"
import serialize from "store/serialize"
import * as Yup from "yup"
import CounterHeader from "components/Common/CounterHeader"

import useStyles from "./styles"

const EditInstructorDialog = () => {
  const classes = useStyles()
  const theme = useTheme()
  const queryClient = useQueryClient()
  const {
    dialogs,
    closeDialog,
    openDialog,
    openSnackBar,
    authUser,
  } = useContext(App)
  const { deleteInstructorMutation, instructors } = useContext(
    InstructorsContext
  )
  const thisDialog = dialogs?.["editInstructorDialog"] || {}
  const { open = false, data } = thisDialog

  const smDown = useMediaQuery(theme.breakpoints.down("sm"))

  const instructorId = thisDialog?.data?.id
  const updateMutation = thisDialog?.data?.update
  const instructorPassed = thisDialog?.data?.instructor

  //finds instructor that was clicked or instructor that was passed from InstructorGroup
  const instructor = instructorPassed
    ? instructorPassed
    : instructors?.find((x) => x.id === Number(instructorId))

  const [imageLoading, setImageLoading] = useState(false)
  const [helperText, setHelperText] = useState(null)
  const [uploadRes, setUploadRes] = useState(null)

  const schema = Yup.object().shape({
    name: Yup.string()
      .required(`Please enter the instructor's name`)
      .max(50, "Name must be less than 50 characters"),
    title: Yup.string()
      //.required(`Please enter the instructor's title`),
      .max(50, "Title must be less than 50 characters"),
    bio: Yup.string()
      //.required(`Please enter the instructor's bio`),
      .max(255, "Bio must be less than 255 characters"),
    // avatar not required at this time
  })

  const handleClose = () => {
    setHelperText(null)
    closeDialog("editInstructorDialog")
    setTimeout(() => {
      setUploadRes(null)
    }, 100)
  }

  const handleRemove = () => {
    let data = {
      title: "Remove an Instructor",
      subtitle: "",
      type: "instructor",
      delete: deleteInstructorMutation,
      id: instructorId,
      confirmWord: "delete",
    }
    openDialog("confirmationTypingDialog", data).then(() => handleClose())
  }

  // fn that submits values from fields/avatar upload and handles response
  const onSubmit = (values, actions) => {
    const data = {
      name: values.name,
      title: values.title,
      bio: values.bio,
      /* eslint-disable */
      //will send the upload response object if there is one, if not sends the instructor profile_image object
      profile_image: uploadRes
        ? JSON.stringify({
            url: uploadRes.data.url,
            secure_url: uploadRes.data.secure_url,
            originial_filename: uploadRes.data.original_filename,
            version: uploadRes.data.version,
            format: uploadRes.data.format,
            public_id: uploadRes.data.public_id,
          })
        : JSON.stringify(values.avatar),
      /* eslint-enable */
    }
    updateMutation.mutate(
      { values: data, id: instructorId },
      {
        onSuccess: (res) => {
          serialize("instructor", res.data[0]).then((serializedData) => {
            queryClient.setQueryData(["instructors", authUser], (oldData) => {
              let newArray = []
              newArray = oldData.map((x) => {
                if (x.id !== serializedData.id) {
                  return x
                } else {
                  return serializedData
                }
              })
              return [...newArray]
            })
          })
          openSnackBar({ message: "Instructor Updated" })
          handleClose()
        },
        onError: (err) => {
          console.log(err)
          openSnackBar({
            message: "Error Editing Instructor",
          })
        },
        onSettled: () => {
          actions.setSubmitting(false)
          handleClose()
        },
      }
    )
  }

  return (
    <Dialog open={open} maxWidth="xs" fullScreen={smDown}>
      <DialogTitle disableTypography>
        <Typography color="primary" variant="h3" style={{ fontWeight: 600 }}>
          Edit Instructor
        </Typography>
      </DialogTitle>
      <Formik
        initialValues={{
          name: instructor?.name,
          title: instructor?.title,
          bio: instructor?.bio,
          avatar: instructor?.profile_image,
        }}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        {({
          handleChange,
          values,
          errors,
          touched,
          handleBlur,
          isSubmitting,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <DialogContent className={classes.contentContainer}>
              <Box className={classes.topContainer}>
                <Box className={classes.leftContainer}>
                  <AvatarUpload
                    cloudinaryUploadPreset={
                      process.env.REACT_APP_CLOUDINARY_CLOUD_THUMBNAIL_UPLOAD
                    }
                    uploadRes={uploadRes}
                    setUploadRes={setUploadRes}
                    value={values.avatar}
                    imageLoading={imageLoading}
                    setImageLoading={setImageLoading}
                  />
                </Box>
                <Box className={classes.rightContainer}>
                  <CounterHeader
                    title={" " + "Name"}
                    value={values.name}
                    max={50}
                  />
                  <TextField
                    type="text"
                    name="name"
                    id="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={
                      /* eslint-disable */
                      helperText
                        ? helperText
                        : touched.name && errors.name
                        ? errors.name
                        : " "
                      /* eslint-enable */
                    }
                    /* eslint-disable-next-line */
                    autoFocus
                    FormHelperTextProps={{
                      className: classes.helper,
                    }}
                    value={values.name}
                    variant="filled"
                    fullWidth
                    InputProps={{
                      classes: {
                        root: classes.inputBackgroundColor,
                        disabled: classes.disabled,
                      },
                    }}
                    className={classes.textField}
                    error={touched.name && errors.name}
                  />
                  <CounterHeader
                    title={" " + "Title"}
                    value={values.title}
                    max={50}
                  />
                  <TextField
                    type="text"
                    name="title"
                    id="title"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={
                      /* eslint-disable */
                      helperText
                        ? helperText
                        : touched.title && errors.title
                        ? errors.title
                        : " "
                      /* eslint-enable */
                    }
                    FormHelperTextProps={{
                      className: classes.helper,
                    }}
                    value={values.title}
                    variant="filled"
                    fullWidth
                    InputProps={{
                      classes: {
                        root: classes.inputBackgroundColor,
                        disabled: classes.disabled,
                      },
                    }}
                    className={classes.textField}
                    error={touched.title && errors.title}
                  />
                </Box>
              </Box>
              <CounterHeader title={" " + "Bio"} value={values.bio} max={255} />
              <TextField
                type="text"
                name="bio"
                id="bio"
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={
                  /* eslint-disable */
                  helperText
                    ? helperText
                    : touched.bio && errors.bio
                    ? errors.bio
                    : " "
                  /* eslint-enable */
                }
                size="small"
                value={values.bio}
                fullWidth
                multiline
                rows={12}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.inputBio,
                    disabled: classes.disabled,
                  },
                }}
                error={touched.bio && errors.bio}
              />
            </DialogContent>
            <DialogActions>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                m={2}
                className={classes.buttonsContainer}
              >
                <Box>
                  <Button
                    className={classes.remove}
                    onClick={() => handleRemove()}
                  >
                    Remove Instructor
                  </Button>
                </Box>
                <Box>
                  <Button
                    className={classes.cancel}
                    onClick={() => handleClose()}
                  >
                    Cancel
                  </Button>
                  <LoaderButton
                    // onClick={() => saveUpdate()}
                    type="submit"
                    variant="contained"
                    color="secondary"
                    working={isSubmitting}
                    disabled={imageLoading || isSubmitting}
                  >
                    save
                  </LoaderButton>
                </Box>
              </Box>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default EditInstructorDialog
