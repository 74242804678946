import { destroy, types as t } from "mobx-state-tree"
import { v4 as uuidv4 } from "uuid"

import Option from "./Option"

const SetBase = t
  .model({
    id: t.optional(t.union(t.number, t.string), () => uuidv4()),
    options: t.optional(t.array(Option), () => [
      Option.create(),
      Option.create(),
    ]),
  })
  .actions((self) => ({
    addOption() {
      self.options.push(Option.create({ name: "" }))
    },
    removeOption(option) {
      destroy(option)
    },
  }))
  .views((self) => ({
    get isValid() {
      return (
        self.correct_option_id !== "" &&
        self.options.length > 1 &&
        self.options.map((o) => o.isValid).filter((o) => o === false).length ===
          0
      )
    },
    get errors() {
      const errorsObj = {}
      if (self.correct_option_id === "") {
        errorsObj.correct_option_id = "A correct option is required"
      }
      if (self.options.length <= 1) {
        errorsObj.options = "2 or more options are required"
      }
      return errorsObj
    },
  }))

const SetModel = t
  .compose(
    "Set",
    SetBase,
    t.model({
      correct_option_id: t.optional(t.union(t.number, t.string), ""),
    })
  )
  .actions((self) => ({
    setCorrectOption(id) {
      self.correct_option_id = id
    },
  }))

export const SentimentSetModel = SetBase.named("Set").views((self) => ({
  get isValid() {
    return (
      self.options.length > 1 &&
      self.options.map((o) => o.isValid).filter((o) => o === false).length === 0
    )
  },
  get errors() {
    const errorsObj = {}
    if (self.options.length <= 1) {
      errorsObj.options = "2 or more options are required"
    }
    return errorsObj
  },
}))

export default SetModel
