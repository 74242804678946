import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  container: {
    display: "flex",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  leftContainer: {
    width: "40%",
    overflow: "auto",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  rightContainer: {
    width: "60%",
    overflow: "auto",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}))

export default styles
