import { Box, Radio, RadioGroup, Typography } from "@material-ui/core"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import { observer } from "mobx-react"
import PropTypes from "prop-types"

import useStyles from "./styles"

const TrueFalse = ({ question, isEdit }) => {
  const classes = useStyles()

  return (
    <Box disaply="flex">
      <RadioGroup
        aria-label="responses"
        name="response"
        value={question.sets[0].correct_option_id + ""}
        onChange={(e) => question.sets[0].setCorrectOption(e.target.value)}
        className={classes.radioGroup}
      >
        <Box className={classes.responseItem}>
          <Radio
            classes={{
              checked: classes.radioChecked,
            }}
            disabled={!isEdit}
            className={classes.radio}
            disableRipple
            value={question.sets[0].options[0].id + ""}
            checkedIcon={<CheckCircleIcon />}
          />
          <Box flexGrow={1}>
            <Typography>True</Typography>
          </Box>
        </Box>
        <Box className={classes.responseItem}>
          <Radio
            classes={{
              checked: classes.radioChecked,
            }}
            disabled={!isEdit}
            className={classes.radio}
            disableRipple
            value={question.sets[0].options[1].id + ""}
            checkedIcon={<CheckCircleIcon />}
          />
          <Box flexGrow={1}>
            <Typography>False</Typography>
          </Box>
        </Box>
      </RadioGroup>
    </Box>
  )
}

TrueFalse.propTypes = {
  question: PropTypes.object,
  isEdit: PropTypes.bool,
}

export default observer(TrueFalse)
