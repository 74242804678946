import { Box, IconButton } from "@material-ui/core"
import PauseIcon from "@material-ui/icons/Pause"
import PlayArrowIcon from "@material-ui/icons/PlayArrow"
import VolumeOffIcon from "@material-ui/icons/VolumeOff"
import VolumeUpIcon from "@material-ui/icons/VolumeUp"
import PropTypes from "prop-types"
import { useCallback, useRef, useState } from "react"
import ReactPlayer from "react-player"
import urlPropType from "url-prop-type"

import VideoPlaybar from "../VideoPlaybar"
import useStyles from "./styles"

const SimplePlayer = ({
  url,
  disableMute = false,
  videoPlayerRef,
  ...props
}) => {
  const innerVideoPlayerRef = useRef()
  // ref to hold the current running animation frame
  const requestRef = useRef()
  const classes = useStyles()

  const [muted, setMuted] = useState(true)
  const [playing, setPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [videoProgress, setVideoProgress] = useState(0)

  // function to run on animation frame loop
  const animate = useCallback(
    (time) => {
      if (innerVideoPlayerRef.current) {
        // const playedPercentage = getPlayedPercentage()
        setVideoProgress(innerVideoPlayerRef.current.getCurrentTime())
        requestRef.current = requestAnimationFrame(animate)
      } else {
        // cleans up request animation frame if the innerVideoPlayerRef became unavailable
        cancelAnimationFrame(requestRef.current)
      }
    },
    [innerVideoPlayerRef]
  )

  const handleStartProgress = useCallback(() => {
    requestRef.current = requestAnimationFrame(animate)
  }, [animate])

  const handleEndProgress = useCallback(() => {
    setTimeout(() => {
      cancelAnimationFrame(requestRef.current)
    })
  }, [])

  const handleDuration = (duration) => {
    setDuration(duration)
    props?.onDuration?.(duration)
  }

  const togglePlayPause = useCallback(() => {
    setPlaying(!playing)
  }, [playing])

  const handleToggleMute = useCallback(() => {
    setMuted(!muted)
  }, [muted])

  const setVideoPlayhead = (seconds) => {
    // const secondsAtVideoLength = (percentage / 100) * lesson.duration
    setVideoProgress(seconds)
    innerVideoPlayerRef.current.seekTo(seconds)
  }

  const handleChange = (e, value) => {
    setVideoPlayhead(value)
  }

  return (
    <Box position="relative" paddingTop="56.25%">
      <Box className={classes.controlsBox}>
        <IconButton color="inherit" onClick={togglePlayPause}>
          {playing && <PauseIcon />}
          {!playing && <PlayArrowIcon />}
        </IconButton>
        <Box className={classes.progressBox}>
          <VideoPlaybar
            value={videoProgress}
            onChange={handleChange}
            max={duration}
          />
        </Box>
        <Box minWidth={24}>
          {!disableMute && (
            <IconButton color="inherit" onClick={handleToggleMute}>
              {muted && <VolumeUpIcon />}
              {!muted && <VolumeOffIcon />}
            </IconButton>
          )}
        </Box>
      </Box>
      <ReactPlayer
        playing={playing}
        muted={muted}
        url={url}
        config={{
          file: {
            attributes: {
              preload: "auto",
            },
          },
        }}
        width="100%"
        height="100%"
        style={{ position: "absolute", top: 0 }}
        onPlay={handleStartProgress}
        onPause={handleEndProgress}
        onEnded={handleEndProgress}
        onDuration={handleDuration}
        ref={(node) => {
          innerVideoPlayerRef.current = node
          videoPlayerRef.current = node
        }}
      />
    </Box>
  )
}

SimplePlayer.displayName = "SimplePlayer"

SimplePlayer.propTypes = {
  url: urlPropType.isRequired,
  onDuration: PropTypes.func,
  disableMute: PropTypes.bool,
  videoPlayerRef: PropTypes.object,
}

export default SimplePlayer
