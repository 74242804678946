import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import { StarOutline } from "@material-ui/icons"
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import PersonIcon from "@material-ui/icons/Person"
import HoverText from "components/Common/HoverText"
import PaperListRow from "components/Common/PaperListRow"
import AppContext from "contexts/App"
import UsersContext from "contexts/Users"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"

import useStyles from "./styles"

const AdminCard = ({ admin, i }) => {
  const classes = useStyles()
  const theme = useTheme()
  const smScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const { setPermissionsMutation, deleteAdminMutation } = useContext(
    UsersContext
  )
  const { authUser, openDialog } = useContext(AppContext)
  const adminIsSuper = authUser?.super
  const [currentAdminIsSuper, setCurrentAdminIsSuper] = useState(
    admin.super || 0
  )

  const [anchorEl, setAnchorEl] = React.useState(null)

  //open dialog to delete admin
  const openDeleteConfirmation = () => {
    let data = {
      title: "Delete Admin",
      subtitle: "",
      type: "admin",
      delete: deleteAdminMutation,
      id: admin.id,
      confirmWord: "delete",
    }
    openDialog("confirmationTypingDialog", data)
    handleClose()
  }

  const handleOpenPermissions = () => {
    openDialog("permissionsDialog", {
      admin,
      setPermissionsMutation,
    })
    handleClose()
  }

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    setCurrentAdminIsSuper(admin.super)
  }, [admin])

  return (
    <Box marginBottom="16px">
      <PaperListRow
        key={i}
        componentWidth="100%"
        componentHeight={smScreen ? "90px" : "64px"}
      >
        <Box display="flex" alignItems="center" className={classes.nameText}>
          <HoverText data={admin.name} size={100} variant={"subtitle1"} />
          {admin?.super ? (
            <Tooltip title="Super Admin" placement="top">
              <StarOutline
                color="primary"
                fontSize="inherit"
                style={{ fontSize: "16px", marginLeft: "4px" }}
              />
            </Tooltip>
          ) : null}
        </Box>
        <Box className={classes.emailText}>
          <HoverText data={admin.email} size={100} variant={"subtitle1"} />
        </Box>
        <Box className={classes.statusText}>
          <Typography variant="subtitle1">{admin.status}</Typography>
        </Box>
        <Box width="15%" display="flex" justifyContent="flex-end">
          {authUser?.super === 1 ? (
            <>
              <IconButton onClick={handleOpenMenu}>
                <MoreVertIcon color="primary" />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleOpenPermissions}>
                  <PersonIcon
                    fontSize="inherit"
                    style={{ fontSize: "16px", marginRight: "4px" }}
                  />
                  <Typography>Edit Permissions</Typography>
                </MenuItem>
                {adminIsSuper && !currentAdminIsSuper ? (
                  <MenuItem onClick={openDeleteConfirmation}>
                    <DeleteOutlineIcon
                      fontSize="inherit"
                      color="inherit"
                      style={{
                        fontSize: "16px",
                        marginRight: "4px",
                        color: "#FF5E69",
                      }}
                    />
                    <Typography color="inherit" style={{ color: "#FF5E69" }}>
                      Remove
                    </Typography>
                  </MenuItem>
                ) : null}
              </Menu>
            </>
          ) : null}
        </Box>
      </PaperListRow>
    </Box>
  )
}

AdminCard.propTypes = {
  admin: PropTypes.object,
  i: PropTypes.string,
}

export default AdminCard
