import { Box } from "@material-ui/core"
import CertificationContent from "components/Certifications/CertificationContent"
import CertificationDetails from "components/Certifications/CertificationDetails"
import CourseLessonContentContainer from "components/Common/CourseLessonContentContainer"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useQuery } from "react-query"
import Router from "router"

import useStyles from "./styles"

const Content = ({ routes = [], ...rest }) => {
  const classes = useStyles()

  return (
    <Page>
      <Box className={classes.container}>
        <CourseLessonContentContainer
          leftComponent={<CertificationDetails />}
          rightComponent={<CertificationContent />}
        />
        <Router routes={routes} {...rest} />
      </Box>
    </Page>
  )
}

Content.propTypes = {
  routes: PropTypes.array,
}

export default Content
