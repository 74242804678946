import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  chipRootLocked: {
    borderRadius: "3px",
    fontSize: "11px",
    fontWeight: "600",
    color: "#29B117",
    backgroundColor: "#D3EFCF",
  },
  chipRootUnlocked: {
    borderRadius: "3px",
    fontSize: "11px",
    fontWeight: "600",
    color: "#FFD034",
    backgroundColor: "#FFF2C5",
  },
}))

export default styles
