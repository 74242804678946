import { Box, Button, Typography } from "@material-ui/core"
import labsPlaceholder from "assets/labs-placeholder.svg"
import serverOpenLottie from "assets/server-open-lottie.json"
import LabContext from "contexts/Lab"
import { useContext, useMemo } from "react"
import Lottie from "react-lottie"
import AutoSizer from "react-virtualized-auto-sizer"

import useStyles from "./styles"
import TabsBuilder from "./TabsBuilder"
import TerminalContainer from "./TerminalContainer"

const LabContent = () => {
  const classes = useStyles()

  const { lab } = useContext(LabContext)

  const serverStarting = useMemo(() => {
    // use lab properties to determine if server is starting
    return false
  }, [])

  const serverStarted = useMemo(() => {
    // use lab to determine if server has started
    return true
  }, [])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: serverOpenLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  if (serverStarted) {
    return (
      <Box className={classes.container}>
        <AutoSizer disableHeight style={{ width: "100%" }}>
          {({ width }) => (
            <>
              <TerminalContainer width={width} height={286} />
              <Box marginTop={2} style={{ float: "right" }}>
                <Button
                  className={classes.buildLabButton}
                  variant="contained"
                  disabled={lab?.locked}
                >
                  Build Lab
                </Button>
              </Box>
              <TabsBuilder ports={lab?.ports} disabled={lab?.locked} />
            </>
          )}
        </AutoSizer>
      </Box>
    )
  } else {
    return (
      <Box className={classes.noContent}>
        {serverStarting && (
          <Box marginBottom="54px">
            <Lottie options={defaultOptions} width={260} />
          </Box>
        )}
        {!serverStarting && (
          <img
            src={labsPlaceholder}
            alt="Server not started"
            style={{ marginBottom: 54 }}
          />
        )}
        <Typography
          variant="h6"
          color="secondary"
          style={{ fontSize: "1.1875rem", fontWeight: 700, marginBottom: 14 }}
        >
          {serverStarting
            ? "Initiating Servers…"
            : "The server has’t started yet"}
        </Typography>
        <Typography variant="h3">
          {serverStarting
            ? "Hold tight, this could take a minute"
            : "Click “Start Server” to get started"}
        </Typography>
      </Box>
    )
  }
}

export default LabContent
