import LoaderButton from "@bit/c_t.components.loader-button"
import { Box, Button } from "@material-ui/core"
import { createVideo, requestSignedUrl, uploadVideo } from "actions/upload"
import AWS from "aws-sdk"
import FileUpload from "components/Common/FileUpload"
import AppContext from "contexts/App"
import LessonContext from "contexts/Lesson"
import PropTypes from "prop-types"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { useHistory } from "react-router"
import LinearProgressWithLabel from "../LinearProgressWithLabel"

const VideoUpload = ({ folderId }) => {
  const { openSnackBar, openDialog } = useContext(AppContext)
  const { updateLessonMutation, videoUrl, lesson } = useContext(LessonContext)
  const queryClient = useQueryClient()
  const history = useHistory()

  const [uploadProgress, setUploadProgress] = useState(0)
  const [showLoading, setShowLoading] = useState(false)

  const requestSignedUrlMutation = useMutation(requestSignedUrl)
  const createVideoMutation = useMutation(createVideo)

  const blockRef = useRef()

  const resetState = () => {
    setUploadProgress(0)
    setShowLoading(false)
  }

  const onDrop = useCallback((acceptedFiles) => {
    setShowLoading(true)
    const timestamp = +new Date()
    const videoKey = `lessonVideos/${timestamp}-${acceptedFiles[0].name}`
    requestSignedUrlMutation
      .mutateAsync()
      .then((data) => {
        const params = {
          Bucket: process.env.REACT_APP_UPLOAD_BUCKET,
          Key: videoKey,
          ContentType: acceptedFiles[0].type,
          Body: acceptedFiles[0],
        }

        // SETUP AWS
        const credentials = new AWS.Credentials({
          accessKeyId: data.key,
          secretAccessKey: data.secret,
          sessionToken: data.session,
        })
        const s3 = new AWS.S3({
          credentials,
          region: "us-east-1",
        })

        AWS.config.httpOptions.timeout = 0

        var upload = s3.upload(params).on("httpUploadProgress", function (evt) {
          var uploaded = Math.round((evt.loaded / evt.total) * 100)
          setUploadProgress(uploaded)
        })
        var promise = upload.promise()
        promise
          .then((uploadRes) => {
            createVideoMutation.mutate(
              {
                key: videoKey,
              },
              {
                onSuccess: (createVideoRes) => {
                  updateLessonMutation.mutate(
                    {
                      video_id: createVideoRes.data.id,
                    },
                    queryClient.setQueryData(
                      ["lesson", lesson.id],
                      (oldData) => {
                        oldData.video_id = createVideoRes.data.id
                        oldData.video = createVideoRes.data

                        return oldData
                      }
                    ),
                    resetState(),
                    {
                      onError: (updateLessonErr) => {
                        console.error(updateLessonErr)
                        openSnackBar({
                          message: "There was an error uploading this video",
                        })
                        resetState()
                      },
                    }
                  )
                },
                onError: (createVideoErr) => {
                  console.error(createVideoErr)
                  resetState()
                  openSnackBar({
                    message: "There was an error uploading this video",
                  })
                },
              }
            )
          })
          .catch((err) => {
            console.error(err)
            resetState()
            openSnackBar({
              message: "There was an error uploading this video",
            })
          })
      })
      .catch((err) => {
        console.error(err)
        resetState()
        openSnackBar({
          message: "There was an error uploading this video",
        })
      })
  }, [])

  const onError = useCallback((rejectedFiles) => {
    openSnackBar({
      message:
        rejectedFiles[0].errors[0].code === "file-invalid-type"
          ? "You can only upload a video file here"
          : "There was an error uploading that file",
    })
  }, [])

  useEffect(() => {
    if (showLoading) {
      blockRef.current = {
        unblock: history.block((tx) => {
          openDialog("unsavedDataDialog", {
            tx,
            text:
              "Your video is still uploading, leaving this page will cancel the upload.  Are you sure you want to continue?",
            discardButtonText: "Leave Page",
          }).then(() => {
            blockRef.current.unblock()
            history.push(tx.pathname)
          })
          return false
        }),
      }
    } else {
      blockRef.current?.unblock?.()
    }
  }, [showLoading])

  return (
    <>
      <FileUpload
        title="Drop Lesson Video here to Begin"
        DropZoneProps={{ accept: "video/*" }}
        onDrop={onDrop}
        onError={onError}
        showLoading={showLoading}
      >
        {videoUrl?.data ? (
          <Box paddingY={3}>
            <LoaderButton working={showLoading} color="secondary">
              Reupload / change video
            </LoaderButton>
          </Box>
        ) : null}
      </FileUpload>
      {uploadProgress > 0 && (
        <LinearProgressWithLabel value={uploadProgress} color="secondary" />
      )}
    </>
  )
}

VideoUpload.propTypes = {
  folderId: PropTypes.string,
}

export default VideoUpload
