import { Chip } from "@material-ui/core"
import PropTypes from "prop-types"
import React, { useMemo } from "react"

import useStyles from "./styles"

const StatusBox = ({ locked = false, size = "100%" }) => {
  const classes = useStyles()

  const chipData = useMemo(() => {
    if (locked) {
      return { label: "LOCKED", class: "chipRootLocked" }
    } else {
      return { label: "UNLOCKLED", class: "chipRootUnlocked" }
    }
  }, [locked])

  return (
    <>
      <Chip
        label={chipData.label}
        classes={{ root: classes[chipData.class] }}
        style={{ minWidth: size }}
      />
    </>
  )
}

StatusBox.propTypes = {
  locked: PropTypes.bool,
  size: PropTypes.string,
}

export default StatusBox
