import { AnalyticsContainer } from "contexts/Analytics"
import AppContext from "contexts/App"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useContext, useEffect } from "react"
import Router from "router"
import useStyles from "./styles"

const Analytics = ({ routes = [], ...rest }) => {
  const classes = useStyles()

  const { setPageTitle } = useContext(AppContext)

  useEffect(() => {
    setPageTitle("Analytics")
  }, [])

  return (
    <Page>
      <AnalyticsContainer>
        <div>Analytics</div>
        <Router routes={routes} {...rest} />
      </AnalyticsContainer>
    </Page>
  )
}

Analytics.propTypes = {
  routes: PropTypes.array,
}

export default Analytics
