import { Box, Typography } from "@material-ui/core"
import AnalyticsFilters from "components/Analytics/AnalyticsFilters"
import AnalyticsTable from "components/Analytics/AnalyticsTable"
import AppContext from "contexts/App"
import LearningCuesContext from "contexts/LearningCues"
import LessonContext from "contexts/Lesson"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { useContext } from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import Router from "router"

import useStyles from "./styles"

const Analytics = ({ routes = [], ...rest }) => {
  const history = useHistory()
  const { authUser } = useContext(AppContext)
  const { analyticsMutation, lesson } = useContext(LessonContext)
  const [knowledgeCheckCount, setKnowledgeCheckCount] = useState(0)
  const { learningCuesQuery, resetLearningCues } = useContext(
    LearningCuesContext
  )

  useEffect(() => {
    setKnowledgeCheckCount(
      learningCuesQuery?.data?.filter((x) => x.knowledgeCheck)?.length
    )
  }, [learningCuesQuery])

  const headCells = [
    {
      title: "USER ID",
      field: "user_id",
      headerStyle: {
        width: "20%",
        maxWidth: "20%",
        padding: "5px 20px",
      },
      cellStyle: {
        width: "20%",
        maxWidth: "20%",
        height: "25px",
        maxHeight: "25px",
        padding: "5px 20px",
      },
      render: function idFunc(rowData) {
        return (
          <>
            <Typography variant="subtitle1">{rowData.user_id}</Typography>
          </>
        )
      },
    },
    {
      title: "User Name",
      field: "username",

      headerStyle: {
        width: "20%",
        maxWidth: "20%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "20%",
        maxWidth: "20%",
        maxHeight: "25px",
        height: "25px",

        padding: "5px 10px",
      },
      render: function userNameFunc(rowData) {
        return (
          <>
            <Typography variant="subtitle1">{rowData.username}</Typography>
          </>
        )
      },
    },
    {
      title: "First Name",
      field: "first_name",
      headerStyle: {
        width: "20%",
        maxWidth: "20%",
        height: "25px",

        padding: "5px 10px",
      },
      cellStyle: {
        width: "20%",
        maxWidth: "20%",
        maxHeight: "25px",
        height: "25px",

        padding: "5px 10px",
      },
      render: function firstNameFunc(rowData) {
        return (
          <>
            <Typography variant="subtitle1">{rowData.first_name}</Typography>
          </>
        )
      },
    },
    {
      title: "Last Name",
      field: "last_name",
      headerStyle: {
        width: "20%",
        maxWidth: "20%",
        height: "25px",

        padding: "5px 10px",
      },
      cellStyle: {
        width: "20%",
        maxWidth: "20%",
        maxHeight: "25px",
        height: "25px",

        padding: "5px 10px",
      },
      render: function lastNameFunc(rowData) {
        return (
          <>
            <Typography variant="subtitle1">{rowData.last_name}</Typography>
          </>
        )
      },
    },
    {
      title: "Email",
      field: "email",
      headerStyle: {
        width: "15%",
        maxWidth: "15%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "15%",
        maxWidth: "15%",
        maxHeight: "25px",
        height: "25px",

        padding: "5px 10px",
      },
      render: function emailFunc(rowData) {
        return (
          <>
            <Typography variant="subtitle1">{rowData.email}</Typography>
          </>
        )
      },
    },
    {
      title: "Course Name",
      field: "course_name",
      headerStyle: {
        width: "15%",
        maxWidth: "15%",
        height: "25px",

        padding: "5px 10px",
      },
      cellStyle: {
        width: "15%",
        maxWidth: "15%",
        maxHeight: "25px",
        height: "25px",

        padding: "5px 10px",
      },
      render: function courseNameFunc(rowData) {
        return (
          <>
            <Typography variant="subtitle1">{rowData.course_name}</Typography>
          </>
        )
      },
    },
    {
      title: "Lesson ID",
      field: "lesson_id",
      headerStyle: {
        width: "15%",
        maxWidth: "15%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "15%",
        maxWidth: "15%",
        maxHeight: "25px",
        height: "25px",

        padding: "5px 10px",
      },
      render: function lessonIdFunc(rowData) {
        return (
          <>
            <Typography variant="subtitle1">{rowData.lesson_id}</Typography>
          </>
        )
      },
    },
    {
      title: "Course ID",
      field: "course_id",
      headerStyle: {
        width: "15%",
        maxWidth: "15%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "15%",
        maxWidth: "15%",
        maxHeight: "25px",
        height: "25px",

        padding: "5px 10px",
      },
      render: function courseIdFunc(rowData) {
        return (
          <>
            <Typography variant="subtitle1">{rowData.course_id}</Typography>
          </>
        )
      },
    },
    {
      title: "Finished Knowledge Checks",
      field: "finished_knowledge_checks",
      headerStyle: {
        width: "15%",
        maxWidth: "15%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "15%",
        maxWidth: "15%",
        maxHeight: "25px",
        height: "25px",

        padding: "5px 10px",
      },
      render: function knowledgeChecksFunc(rowData) {
        return (
          <>
            <Typography variant="subtitle1">
              {rowData.finished_knowledge_checks}
            </Typography>
          </>
        )
      },
    },
    {
      title: "Time on Lesson",
      field: "time_on_lesson",
      headerStyle: {
        width: "15%",
        maxWidth: "15%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "15%",
        maxWidth: "15%",
        maxHeight: "25px",
        height: "25px",

        padding: "5px 10px",
      },
      render: function timeOnLessonFunc(rowData) {
        return (
          <>
            <Typography variant="subtitle1">
              {rowData.time_on_course === null ? "-" : rowData.time_on_lesson}
            </Typography>
          </>
        )
      },
    },
    {
      title: "Version",
      field: "version",
      headerStyle: {
        width: "10%",
        maxWidth: "10%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "10%",
        maxWidth: "10%",
        height: "25px",
        maxHeight: "25px",
        padding: "5px 10px",
      },
      render: function versionFunc(rowData) {
        return (
          <>
            <Typography variant="subtitle1">{rowData.version}</Typography>
          </>
        )
      },
    },
  ]
  const classes = useStyles()

  //checks auth user role
  useEffect(() => {
    if (authUser?.access_analytics !== 1) {
      history.push("/lessons")
    }
  }, [authUser])

  const fields = [
    "user_id",
    "username",
    "first_name",
    "last_name",
    "email",
    "course_name",
    "lesson_id",
    "course_id",
    "finished_knowledge_checks",
    "best_assessment_score",
    "time_on_lesson",
    "version",
  ]

  return (
    <Page>
      <Box padding="30px">
        {/* <AnalyticsFilters type={"lessons"} /> */}
        <AnalyticsTable
          headCells={headCells}
          mutation={analyticsMutation}
          type={"lesson"}
          data={{ ...lesson, count: knowledgeCheckCount }}
          fields={fields}
        />
        <Router routes={routes} {...rest} />
      </Box>
    </Page>
  )
}

Analytics.propTypes = {
  routes: PropTypes.array,
}

export default Analytics
