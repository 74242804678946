import { Box, LinearProgress } from "@material-ui/core"
import { listPaths } from "actions/paths"
import AdminActionBar from "components/Common/AdminActionBar"
import StatusFilters from "components/Common/StatusFilters"
import PathCardList from "components/Paths/PathCardList"
import PathStatusFilters from "components/Paths/PathStatusFilters"
import AppContext from "contexts/App"
import LearningPathsContext from "contexts/Paths"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useContext, useEffect, useState } from "react"
import { useQuery } from "react-query"
import Router from "router"

import useStyles from "./styles"

const List = ({ routes = [], ...rest }) => {
  const classes = useStyles()
  const { openDialog, setPageSubTitle, setPageTitle } = useContext(AppContext)
  const { createPathMutation } = useContext(LearningPathsContext)

  //set serach value
  const [searchValue, setSearchValue] = useState("")
  //set sort data
  const [sort, setSort] = useState({
    name: "",
    type: "",
    direction: "",
    icon: "",
  })

  const filterOptions = [
    { id: 1, name: "Name" },
    { id: 2, name: "Courses" },
    { id: 3, name: "Last Updated" },
  ]

  const { data: paths, isLoading: pathsLoading } = useQuery("paths", () =>
    listPaths()
  )

  //set learning paths title and subtitle
  useEffect(() => {
    setPageTitle("Learning Paths")
    setPageSubTitle(paths?.length)
  }, [paths])
  //create learning path dialog, mutations is passed to create one
  const openCreateDialog = () => {
    let data = {
      create: createPathMutation,
    }
    openDialog("createLearningPathDialog", data)
  }

  //set courses status
  const [statusState, setStatusState] = useState("published")
  //set the length of each type of course
  const [statusNumber, setStatusNumber] = useState({
    published: null,
    unpublished: null,
    draft: null,
    archived: null,
  })

  return (
    <Page>
      <AdminActionBar
        buttonName="New Learning Path"
        buttonFunc={openCreateDialog}
        sortOptions={filterOptions}
        sortState={sort}
        setSortState={setSort}
        setFilterState={setSearchValue}
        searchText="Search Learning Paths"
      />
      {pathsLoading ? (
        <LinearProgress color="primary" />
      ) : (
        <Box
          display="flex"
          height="calc(100% - 60px)"
          className={classes.container}
        >
          <Box className={classes.leftContainer}>
            <StatusFilters
              setStatusState={setStatusState}
              statusState={statusState}
              statusNumber={statusNumber}
            />
          </Box>
          <Box className={classes.rightContainer}>
            <PathCardList
              searchValue={searchValue}
              sort={sort}
              openCreateDialog={openCreateDialog}
              setStatusNumber={setStatusNumber}
              statusState={statusState}
              paths={paths}
            />
          </Box>
        </Box>
      )}

      <Router routes={routes} {...rest} />
    </Page>
  )
}

List.propTypes = {
  routes: PropTypes.array,
}

export default List
