import { Box, CircularProgress, MenuItem } from "@material-ui/core"
import PropTypes from "prop-types"
import React from "react"

const CourseLessonMenuItem = ({ func, text, loadingState, ...props }) => {
  return (
    <MenuItem onClick={func} {...props}>
      {loadingState ? (
        <Box display="flex" justifyContent="center" width="100%">
          <CircularProgress style={{ color: "#fff" }} size={18} />
        </Box>
      ) : (
        text
      )}
    </MenuItem>
  )
}

CourseLessonMenuItem.propTypes = {
  func: PropTypes.func,
  loadingState: PropTypes.bool,
  text: PropTypes.string,
}

export default CourseLessonMenuItem
