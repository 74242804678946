import LoaderButton from "@bit/c_t.components.loader-button"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import { VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons"
import AppContext from "contexts/App"
import { Form, Formik } from "formik"
import PropTypes from "prop-types"
import { useContext, useState } from "react"
import { useQueryClient } from "react-query"
import * as Yup from "yup"

import useStyles from "./styles"

const MyAccountDialog = () => {
  const classes = useStyles()
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))

  const queryClient = useQueryClient()

  const {
    dialogs,
    closeDialog,
    authUser,
    updateMeMutation,
    updatePasswordMutation,
    openSnackBar,
    refetchMe,
  } = useContext(AppContext)
  const thisDialog = dialogs?.["myAccountDialog"] || {}
  const { open = false, data } = thisDialog

  const [visibility, setVisibility] = useState(true)
  const [helperText, setHelperText] = useState(null)

  const handleClose = () => {
    closeDialog("myAccountDialog", false)
    setHelperText(null)
    setVisibility(true)
  }

  const schema = Yup.object().shape({
    email: Yup.string().email(),
    new_password: Yup.string().matches(
      /^(?=.*[\d])(?=.*[!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/])(?=.*[A-Z])[A-Za-z\d!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/]{8,}$/,
      "Must contain at least 8 characters, one capital letter, one number and one special character"
    ),
  })

  const updateUserInfo = (values) => {
    const data = { name: values.name, email: values.email }
    return new Promise((resolve, reject) => {
      updateMeMutation.mutate(data, {
        onSuccess: (res) => {
          queryClient.setQueryData(["getMe"], { id: res.data.id }, data)
          resolve()
        },
        onError: () => {
          openSnackBar({
            message: "Error Updating user",
          })
          reject()
        },
      })
    })
  }

  const resetPassword = (values) => {
    const data = {
      current_password: values.current_password,
      new_password: values.new_password,
    }
    return new Promise((resolve, reject) => {
      if (values.new_password === values.confirmPassword) {
        updatePasswordMutation.mutate(data, {
          onSuccess: () => {
            resolve()
          },
          onError: (err) => {
            openSnackBar({
              message: err,
            })
            reject()
          },
        })
      } else {
        reject()
        setHelperText("Passwords do not match")
        openSnackBar({ message: "Error" })
      }
    })
  }

  // chains updateUserInfo with resetPassword
  // only updates password if both textfields are filled
  const onSubmit = (values, actions) => {
    return Promise.all([
      updateUserInfo(values, actions),
      values.current_password && values.new_password
        ? resetPassword(values, actions)
        : null,
    ])
      .then((res) => {
        actions.setSubmitting(false)
        setHelperText(null)
        setVisibility(true)
        openSnackBar({
          message: "User Updated",
        })
        refetchMe()
        handleClose()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <Dialog maxWidth="xs" open={open} fullScreen={smDown}>
      <DialogTitle disableTypography>
        <Typography color="primary" variant="h2" style={{ fontWeight: 600 }}>
          My Account
        </Typography>
        <Typography variant="subtitle1" color="primary">
          Edit Your Account Information
        </Typography>
      </DialogTitle>
      <Formik
        initialValues={{
          name: authUser ? authUser.name : "",
          email: authUser ? authUser.email : "",
          current_password: "",
          new_password: "",
          confirmPassword: "",
        }}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        {({
          handleChange,
          values,
          errors,
          touched,
          handleBlur,
          isSubmitting,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              <Box marginBottom={2}>
                <Typography color="secondary" className={classes.title}>
                  Full Name
                </Typography>
                <TextField
                  type="text"
                  name="name"
                  value={values.name}
                  InputProps={{
                    classes: {
                      root: classes.inputBackgroundColor,
                    },
                  }}
                  variant="filled"
                  size="small"
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Box>
              <Box marginBottom={4}>
                <Typography color="secondary" className={classes.title}>
                  Email
                </Typography>
                <TextField
                  type="text"
                  value={values.email}
                  name="email"
                  InputProps={{
                    classes: {
                      root: classes.inputBackgroundColor,
                    },
                  }}
                  variant="filled"
                  size="small"
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && errors.email}
                  helperText={
                    touched.email && errors.email ? errors.email : " "
                  }
                />
              </Box>
              <Divider />
              <Box marginTop={4}>
                <Typography color="primary" className={classes.subtitle}>
                  Reset Password
                </Typography>
              </Box>
              <Box marginTop={2}>
                <Typography color="secondary" className={classes.title}>
                  Current Password
                </Typography>
                <TextField
                  type={visibility ? "password" : "text"}
                  value={values.current_password}
                  name="current_password"
                  InputProps={{
                    classes: {
                      root: classes.inputBackgroundColor,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          size="small"
                          onClick={() => setVisibility(!visibility)}
                        >
                          {visibility ? (
                            <VisibilityOffOutlined
                              color="primary"
                              fontSize="small"
                            />
                          ) : (
                            <VisibilityOutlined
                              color="primary"
                              fontSize="small"
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="filled"
                  size="small"
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Box>
              <Box marginTop={2}>
                <Typography color="secondary" className={classes.title}>
                  New Password
                </Typography>
                <TextField
                  type={visibility ? "password" : "text"}
                  name="new_password"
                  value={values.new_password}
                  InputProps={{
                    classes: {
                      root: classes.inputBackgroundColor,
                    },
                  }}
                  variant="filled"
                  size="small"
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.new_password && errors.new_password}
                  helperText={
                    touched.new_password && errors.new_password
                      ? errors.new_password
                      : " "
                  }
                />
              </Box>
              <Box>
                <Typography color="secondary" className={classes.title}>
                  Confirm Password
                </Typography>
                <TextField
                  type={visibility ? "password" : "text"}
                  value={values.confirmPassword}
                  name="confirmPassword"
                  InputProps={{
                    classes: {
                      root: classes.inputBackgroundColor,
                    },
                  }}
                  variant="filled"
                  size="small"
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!helperText}
                  helperText={helperText && helperText}
                />
              </Box>
            </DialogContent>
            <DialogActions className={classes.actions}>
              <Box m={2}>
                <Button style={{ marginRight: "8px" }} onClick={handleClose}>
                  Cancel
                </Button>
                <LoaderButton
                  type="submit"
                  // onClick={onSubmit}
                  classoverrides={{
                    wrapper: classes.button,
                    button: {
                      root: classes.buttonRoot,
                    },
                  }}
                  variant="contained"
                  color="secondary"
                  working={isSubmitting}
                >
                  Save
                </LoaderButton>
              </Box>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

MyAccountDialog.propTypes = {
  children: PropTypes.array,
}

export default MyAccountDialog
