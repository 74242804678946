import { destroy, getParent, types as t } from "mobx-state-tree"
import { v4 as uuidv4 } from "uuid"

const Option = t
  .model("Option", {
    id: t.optional(t.union(t.number, t.string), () => uuidv4()),
    name: "",
  })
  .actions((self) => ({
    update(value) {
      self.name = value
    },
    remove() {
      getParent(self, 2).removeOption(self)
    },
  }))
  .views((self) => ({
    get isValid() {
      return self.name !== ""
    },
    get errors() {
      const errorsObj = {}
      if (self.name === "") {
        errorsObj.name = "Option name is required"
      }
      return errorsObj
    },
  }))

export default Option
