const sortTypeUtil = (sortName) => {
  //return sort type that will be use in sortHelper
  if (sortName === "name" || sortName === "title") {
    return "string"
  } else if (sortName === "duration") {
    return "duration"
  } else if (sortName === "lessons") {
    return "number"
  } else {
    return "date"
  }
}

export default sortTypeUtil
