import PropTypes from "prop-types"

import ResponseList from "../../ResponseList"
import useStyles from "./styles"

const SingleChoiceSentiment = ({ question, isEdit, showErrors }) => {
  const classes = useStyles()

  return (
    <ResponseList
      responseSet={question.sets[0]}
      isEdit={isEdit}
      showErrors={showErrors}
    />
  )
}

SingleChoiceSentiment.propTypes = {
  question: PropTypes.object,
  isEdit: PropTypes.bool,
  showErrors: PropTypes.bool,
}

export default SingleChoiceSentiment
