import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  learningCueItem: {
    borderBottom: "1px solid #C4C4C4",
    height: 42,
    display: "flex",
    alignItems: "center",
    backgroundColor: "transparent",
    transition: theme.transitions.create(["background-color"], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeIn,
    }),
    "&:hover, &$focused": {
      backgroundColor: "#FCF9F2",
      "& $chip": {
        opacity: 1,
        "&$buttonDisabled": {
          opacity: 0.5,
        },
      },
      "& $input": {
        backgroundColor: "#fff",
        borderColor: "#a0a0a0",
      },
      "& $inputBox": {
        borderLeftColor: "#FFD034",
      },
      "& $buttons": {
        opacity: 1,
      },
    },
  },
  focused: {},
  inputBox: {
    width: 100,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    borderRight: "1px solid #C4C4C4",
    paddingRight: 13,
    borderLeftWidth: 10,
    borderLeftStyle: "solid",
    borderLeftColor: "transparent",
    transition: theme.transitions.create(
      ["background-color", "border-left-color"],
      {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeIn,
      }
    ),
  },
  textField: {
    padding: 0,
    borderRadius: 0,
    margin: 0,
  },
  input: {
    color: theme.palette.text.primary,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "transparent",
    backgroundColor: "transparent",
    padding: "4.5px 0px",
    fontSize: "0.6875rem",
    textAlign: "center",
    fontWeight: 700,
    lineHeight: "0.6875rem",
    width: 51,
    transition: theme.transitions.create(["background-color", "border-color"], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.sharp,
    }),
  },
  inputFocused: {
    "& > input": {
      backgroundColor: "#fff",
      borderColor: "#a0a0a0",
    },
  },
  none: {
    border: ["none", "!important"],
  },
  chips: {
    display: "flex",
    paddingLeft: 11,
  },
  enabled: {},
  buttonDisabled: {
    cursor: ["not-allowed", "!important"],
  },
  chip: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    color: theme.palette.secondary.main,
    borderWidth: 1,
    borderStyle: "dashed",
    borderColor: theme.palette.secondary.main,
    borderRadius: 4,
    height: 30,
    width: 60,
    cursor: "pointer",
    marginRight: theme.spacing(1),
    opacity: 0,
    transition: theme.transitions.create(
      ["opacity", "background-color", "border-color"],
      {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeIn,
      }
    ),
    "&$enabled": {
      opacity: 1,
      backgroundColor: "#E2F1F2",
      borderColor: "transparent",
    },
  },
  chipSpacer: {
    width: 60,
    marginRight: theme.spacing(1),
  },
  knowledgeCheck: {
    color: "#29B117",
    borderColor: "#29B117",
    "&$enabled": {
      backgroundColor: "#D3EFCF",
      borderColor: "transparent",
    },
  },
  topVideoBanner: {
    color: "#FF6A40",
    borderColor: "#FF6A40",
    "&$enabled": {
      backgroundColor: "#FFC4B4",
      borderColor: "transparent",
    },
  },
  bottomVideoBanner: {
    color: "#E32B6E",
    borderColor: "#E32B6E",
    "&$enabled": {
      backgroundColor: "#FDE0EB",
      borderColor: "transparent",
    },
  },
  fullscreen: {
    color: "#888888",
    borderColor: "#888888",
    "&$enabled": {
      backgroundColor: "#e2e2e2",
      borderColor: "transparent",
    },
  },
  readyCheck: {
    color: "#B821E2",
    borderColor: "#B821E2",
    "&$enabled": {
      backgroundColor: "#EDC7F8",
      borderColor: "transparent",
    },
  },
  notes: {
    color: "#0078F6",
    borderColor: "#0078F6",
    "&$enabled": {
      backgroundColor: "#B1D6FC",
      borderColor: "transparent",
    },
  },
  checkbox: {
    padding: 2,
  },
  buttons: {
    paddingRight: theme.spacing(2),
    opacity: 0,
    transition: theme.transitions.create(["opacity"], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeIn,
    }),
  },
  moreButton: {
    border: "1px solid #C4C4C4",
  },
  listItemIcon: {
    minWidth: "initial",
    paddingRight: 16,
  },
  removeIcon: {
    fontSize: 16,
    color: theme.palette.error.main,
  },
}))

export default styles
