import ActionBar from "@bit/c_t.components.action-bar"
import { Box, Button, InputAdornment, TextField } from "@material-ui/core"
import { Add, Search } from "@material-ui/icons"
import AppContext from "contexts/App"
import { SubscriptionsContainer } from "contexts/Subscriptions"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useContext, useEffect, useState } from "react"
import Router from "router"
import useStyles from "./styles"

const Subscriptions = ({ routes = [], ...rest }) => {
  const classes = useStyles()
  const { setPageTitle, setPageSubTitle } = useContext(AppContext)

  useEffect(() => {
    setPageTitle("Subscriptions")
    setPageSubTitle("")
  }, [])

  return (
    <Page>
      <SubscriptionsContainer>
        <ActionBar
          elevation={0}
          left={() => (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Add fontSize="small" />}
            >
              New Subscription
            </Button>
          )}
          right={() => (
            <Box display="flex" alignItems="center">
              <TextField
                placeholder="Search subscriptions"
                style={{
                  flex: 10,
                  background: "white",
                  borderRadius: "5px",
                  marginLeft: "2vw",
                  width: "20vw",
                }}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          )}
        />

        <Router routes={routes} {...rest} />
      </SubscriptionsContainer>
    </Page>
  )
}

Subscriptions.propTypes = {
  routes: PropTypes.array,
}

export default Subscriptions
