import LoaderButton from "@bit/c_t.components.loader-button"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle"
import { Autocomplete } from "@material-ui/lab"
import { listPublishedCourses } from "actions/courses"
import CounterHeader from "components/Common/CounterHeader"
import NumberFormatCustom from "components/Common/NumberFormat"
import DiscountDateTime from "components/Discounts/DiscountDateTime"
import AppContext from "contexts/App"
import dayjs from "dayjs"
import { Form, Formik } from "formik"
import { useContext, useEffect, useRef, useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import serialize from "store/serialize"
import characterCounter from "utils/characterCounter"
import * as Yup from "yup"

import useStyles from "./styles"

const CreateDiscountDialog = () => {
  const classes = useStyles()
  const theme = useTheme()
  const queryClient = useQueryClient()

  const smDown = useMediaQuery(theme.breakpoints.down("sm"))

  const {
    dialogs,
    closeDialog,
    openSnackBar,
    openDialog,
    authUser,
  } = useContext(AppContext)
  const thisDialog = dialogs?.["createDiscount"] || {}
  const { open = false, data } = thisDialog

  const { data: publishedCourses } = useQuery(
    "publishedCourses",
    () => listPublishedCourses(),
    {
      enabled: !!authUser,
    }
  )

  const createMutation = thisDialog?.data?.create
  const updateMutation = thisDialog?.data?.updateDiscount
  const removeMutation = thisDialog?.data?.removeDiscount
  const setCoursesMutation = thisDialog?.data?.setDiscountCourses
  const edit = thisDialog?.data?.edit
  const discountData = thisDialog?.data

  const containerIds = discountData?.containers?.map(
    (x) => x.course_container_id
  )
  const initialCourses = containerIds?.map((x) =>
    publishedCourses.find((c) => c.container_id === x)
  )

  const formikState = useRef()

  const [discountPrice, setDiscountPrice] = useState("percent")
  const [discountCourses, setDiscountCourses] = useState("all")
  const [startDate, setStartDate] = useState(dayjs())
  const [expirationDate, setExpirationDate] = useState(dayjs())
  const [noExpiration, setNoExpiration] = useState(false)
  const [active, setActive] = useState("active")
  const [clearTextfield, setClearTextfield] = useState(false)

  const [statusChanged, setStatusChanged] = useState(false)

  const handleClose = () => {
    closeDialog("createDiscount")
    setDiscountPrice("percent")
    setDiscountCourses("all")
    setStartDate(dayjs())
    setExpirationDate(dayjs())
    setNoExpiration(false)
    setActive("active")
    setStatusChanged(false)
  }

  const removeSelectedCourse = (selectedCourses, index, setFieldValue) => {
    const newSelected = selectedCourses.filter((x, i) => i !== index)
    setFieldValue("selectedCourses", [...newSelected])
  }

  const handleRemove = () => {
    let data = {
      title: "Remove a Discount Code",
      subtitle: "",
      type: "discount",
      delete: removeMutation,
      id: discountData.id,
      confirmWord: "delete",
    }
    openDialog("confirmationTypingDialog", data).then(() => handleClose())
  }

  //setStatusChanged is flag submit edits with only inactive/active state change
  //fn called with each
  const handleStatusChange = (e) => {
    if (statusChanged === false) {
      setStatusChanged(true)
    }
    setActive(e.target.value)
  }

  const handleDiscountCourses = (e) => {
    if (statusChanged === false) {
      setStatusChanged(true)
    }
    setDiscountCourses(e.target.value)
  }

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true)
    // orders the data to be sent
    const data = {
      name: values.name,
      coupon: values.code,
      allowed_uses: values.numUses,
      start_date: dayjs(startDate).format(),
      end_date: noExpiration ? null : dayjs(expirationDate).format(),
      active: active === "active" ? true : false,
      fixed: discountPrice === "fixed" ? true : false,
      fixed_amount_off:
        discountPrice === "fixed"
          ? (values.fixedDiscount * 100).toString()
          : null,
      percent_off:
        discountPrice === "percent" ? Number(values.percentDiscount) : null,
      all_courses: discountCourses === "all" ? true : false,
    }
    if (!edit) {
      //initial create function
      createMutation.mutate(data, {
        onSuccess: (res) => {
          //if selected courses are chosen, it will make the setCourseContainer call using the res id
          if (discountCourses !== "all") {
            const containerIds = values.selectedCourses.map(
              (c) => c.container_id
            )
            const courseData = {
              courses: [...containerIds],
              id: res.data.id,
            }
            setCoursesMutation.mutate(courseData, {
              onSuccess: (res) => {
                serialize("discount", res.data).then((serializedData) => {
                  return queryClient.setQueryData("discounts", (oldData) => {
                    let newArray = []
                    newArray = oldData.map((x) => {
                      if (x.id !== serializedData.id) {
                        return x
                      } else {
                        return serializedData
                      }
                    })
                    return [...newArray]
                  })
                })
                actions.setSubmitting(false)
                openSnackBar({ message: "Discount Created" })
                handleClose()
              },
              onError: (err) => {
                openSnackBar({ message: err?.response?.data?.error })
                setStatusChanged(false)
                actions.setSubmitting(false)
              },
            })
          } else {
            actions.setSubmitting(false)
            openSnackBar({ message: "Discount Created" })
            handleClose()
          }
          serialize("discount", res.data).then((serializedData) => {
            queryClient.setQueryData("discounts", (oldData) => {
              return [...oldData, serializedData]
            })
          })
        },
        onError: (err) => {
          openSnackBar({ message: err?.response?.data?.error })
          setStatusChanged(false)
          actions.setSubmitting(false)
        },
      })
    } else {
      // update function, based on if edit:true
      if (formikState.current.dirty || statusChanged) {
        updateMutation.mutate(
          { data: data, id: discountData.id },
          {
            onSuccess: (res) => {
              // if all courses was chosen, sets course containers to empty array else uses the selected courses
              let containerIds
              if (discountCourses === "all") {
                containerIds = []
              } else {
                const filtered = values?.selectedCourses.filter(
                  (x) => x !== undefined
                )
                containerIds = filtered.map((c) => c.container_id)
              }
              const courseData = {
                courses: [...containerIds],
                id: res.data.id,
              }
              setCoursesMutation.mutate(courseData, {
                onSuccess: (res) => {
                  serialize("discount", res.data).then((serializedData) => {
                    return queryClient.setQueryData("discounts", (oldData) => {
                      let newArray = []
                      newArray = oldData.map((x) => {
                        if (x.id !== serializedData.id) {
                          return x
                        } else {
                          return serializedData
                        }
                      })
                      return [...newArray]
                    })
                  })
                  openSnackBar({ message: "Discount Code Updated" })
                  handleClose()
                  setStatusChanged(false)
                  actions.setSubmitting(false)
                },
                onError: (err) => {
                  openSnackBar({ message: err?.response?.data?.error })
                  setStatusChanged(false)
                  actions.setSubmitting(false)
                },
              })
            },
            onError: (err) => {
              openSnackBar({ message: err?.response?.data?.error })
              setStatusChanged(false)
              actions.setSubmitting(false)
            },
          }
        )
      }
    }
  }

  // used to set intial values if the edit button is clicked
  useEffect(() => {
    if (edit) {
      setActive(discountData.active ? "active" : "inactive")
      setDiscountPrice(discountData.fixed ? "fixed" : "percent")
      setDiscountCourses(
        discountData.containers.length > 0 ? "specific" : "all"
      )
      setStartDate(discountData.start)
      if (discountData.end === "No Expiration") {
        setNoExpiration(true)
        setExpirationDate(dayjs())
      } else {
        setExpirationDate(discountData.end)
      }
    }
  }, [discountData])
  const schema = Yup.object().shape({
    /* eslint-disable */
    name: Yup.string()
      .required("Name is required")
      .max(50, "Name must be less than 50 characters"),
    code: Yup.string().required("Discount Code is required"),
    numUses: Yup.number()
      .typeError("# of Uses requires a number")
      .required("# of Uses is required")
      .positive("# of Uses requires a positive number")
      .integer("# of Uses requires a integer"),
    percentDiscount:
      discountPrice === "percent"
        ? Yup.number()
            .typeError("Discount requires a number")
            .required("Discount is required")
            .positive("Discount requires a positive number")
            .integer("Discount must be an integer")
            .max(100, "Discount must be 100% or less")
        : null,
    fixedDiscount:
      discountPrice === "fixed"
        ? Yup.number()
            .required("Discount is required")
            .positive("Discount requires a positive number")
            .integer("Discount must be an integer")
        : null,
    selectedCourses:
      discountCourses === "specific"
        ? Yup.array().test({
            message: "Select at least one course",
            test: (arr) => arr.length > 0,
          })
        : null,

    /* eslint-enable */
  })

  return (
    <Dialog open={open} maxWidth="xs" fullScreen={smDown}>
      <DialogTitle disableTypography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h3" color="primary" style={{ fontWeight: 600 }}>
            {edit ? "Edit Discount Code" : "New Discount Code"}
          </Typography>
          <RadioGroup value={active} onChange={handleStatusChange}>
            <Box display="flex">
              <Box>
                <FormControlLabel
                  value="active"
                  label="Active"
                  control={
                    <Radio
                      size="small"
                      classes={{ checked: classes.active }}
                      color="default"
                    />
                  }
                />
              </Box>
              <Box>
                <FormControlLabel
                  value="inactive"
                  label="Inactive"
                  control={
                    <Radio
                      size="small"
                      classes={{ checked: classes.inactive }}
                      color="default"
                    />
                  }
                />
              </Box>
            </Box>
          </RadioGroup>
        </Box>
      </DialogTitle>
      <Formik
        initialValues={{
          name: edit ? discountData?.name : "",
          code: edit ? discountData?.code : "",
          numUses: edit ? discountData?.available : "",
          percentDiscount: edit ? discountData?.percentOff : "",
          fixedDiscount: edit ? (discountData?.fixedOff / 100).toFixed(2) : "",
          selectedCourses: edit ? initialCourses : [],
        }}
        onSubmit={handleSubmit}
        validationSchema={schema}
        innerRef={formikState}
      >
        {({
          handleChange,
          values,
          errors,
          touched,
          handleBlur,
          isSubmitting,
          handleSubmit,
          setFieldValue,
          dirty,
        }) => (
          <Form>
            <DialogContent className={classes.dialogContent}>
              <Box marginBottom={3}>
                <CounterHeader title="Name" value={values.name} max={50} />
                <TextField
                  type="text"
                  InputProps={{
                    classes: {
                      root: classes.inputBackgroundColor,
                    },
                  }}
                  variant="filled"
                  size="small"
                  fullWidth
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && errors.name}
                  helperText={touched.name && errors.name ? errors.name : ""}
                  FormHelperTextProps={{
                    className: classes.formHelperText,
                  }}
                />
                <Box marginTop={1} display="flex">
                  <Box flexGrow={1}>
                    <Typography color="secondary" className={classes.title}>
                      Discount Code
                    </Typography>
                    <TextField
                      type="text"
                      InputProps={{
                        classes: {
                          root: classes.inputBackgroundColor,
                        },
                      }}
                      variant="filled"
                      size="small"
                      fullWidth
                      name="code"
                      value={values.code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.code && errors.code}
                      helperText={
                        touched.code && errors.code ? errors.code : ""
                      }
                      FormHelperTextProps={{
                        className: classes.formHelperText,
                      }}
                    />
                  </Box>
                  <Box marginLeft={2}>
                    <Typography color="secondary" className={classes.title}>
                      # of Uses
                    </Typography>
                    <TextField
                      type="text"
                      InputProps={{
                        classes: {
                          root: classes.inputBackgroundColor,
                        },
                      }}
                      variant="filled"
                      size="small"
                      fullWidth
                      name="numUses"
                      value={values.numUses}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.numUses && errors.numUses}
                      helperText={
                        touched.numUses && errors.numUses ? errors.numUses : ""
                      }
                      FormHelperTextProps={{
                        className: classes.formHelperText,
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box marginTop={2} marginBottom={3}>
                <Typography color="secondary" className={classes.title}>
                  Discount Type
                </Typography>
                <RadioGroup
                  value={discountPrice}
                  onChange={(e) => setDiscountPrice(e.target.value)}
                >
                  <Box display="flex">
                    <Box marginRight={2}>
                      <FormControlLabel
                        value="percent"
                        label="Percentage"
                        control={<Radio />}
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        value="fixed"
                        label="Fixed Amount"
                        control={<Radio />}
                      />
                    </Box>
                  </Box>
                </RadioGroup>
                {discountPrice === "percent" ? (
                  <Box marginTop={2}>
                    <Typography color="secondary" className={classes.title}>
                      Percentage discount
                    </Typography>
                    <TextField
                      InputProps={{
                        classes: {
                          root: classes.inputBackgroundColor,
                        },
                        startAdornment: values.percentDiscount ? (
                          <InputAdornment
                            position="start"
                            disableTypography
                            classes={{
                              root: classes.percent,
                              positionStart: classes.percentStart,
                              filled: classes.percentStart,
                            }}
                          >
                            %
                          </InputAdornment>
                        ) : null,
                      }}
                      variant="filled"
                      size="small"
                      name="percentDiscount"
                      value={values.percentDiscount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.percentDiscount && errors.percentDiscount}
                      helperText={
                        touched.percentDiscount && errors.percentDiscount
                          ? errors.percentDiscount
                          : ""
                      }
                      FormHelperTextProps={{
                        className: classes.formHelperText,
                      }}
                    />
                  </Box>
                ) : (
                  <Box marginTop={2}>
                    <Typography color="secondary" className={classes.title}>
                      Fixed amount discount
                    </Typography>
                    <TextField
                      InputProps={{
                        classes: {
                          root: classes.inputBackgroundColor,
                        },
                        inputComponent: NumberFormatCustom,
                      }}
                      variant="filled"
                      size="small"
                      name="fixedDiscount"
                      value={values.fixedDiscount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.fixedDiscount && errors.fixedDiscount}
                      helperText={
                        touched.fixedDiscount && errors.fixedDiscount
                          ? errors.fixedDiscount
                          : ""
                      }
                      FormHelperTextProps={{
                        className: classes.formHelperText,
                      }}
                    />
                  </Box>
                )}
              </Box>
              <Divider />
              <Box marginTop={2}>
                <DiscountDateTime
                  startDate={startDate}
                  setStartDate={setStartDate}
                  expirationDate={expirationDate}
                  setExpirationDate={setExpirationDate}
                  noExpiration={noExpiration}
                  setNoExpiration={setNoExpiration}
                  statusChanged={statusChanged}
                  setStatusChanged={setStatusChanged}
                />
              </Box>
              <Box marginTop={2}>
                <Typography color="secondary" className={classes.title}>
                  Discount Type
                </Typography>
                <RadioGroup
                  value={discountCourses}
                  onChange={(e) => handleDiscountCourses(e)}
                >
                  <Box display="flex">
                    <Box marginRight={2}>
                      <FormControlLabel
                        value="all"
                        label="All Courses"
                        control={<Radio />}
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        value="specific"
                        label="Specific Courses"
                        control={<Radio />}
                      />
                    </Box>
                  </Box>
                </RadioGroup>
                {discountCourses === "specific" ? (
                  <Box marginTop={2}>
                    <Typography color="secondary" className={classes.title}>
                      Courses included
                    </Typography>
                    <Autocomplete
                      name="selectedCourses"
                      value={values.selectedCourses}
                      options={publishedCourses || []}
                      getOptionLabel={(option) => option?.name || ""}
                      getOptionDisabled={(option) =>
                        !!values.selectedCourses?.find(
                          (x) => x?.id === option?.id
                        )
                      }
                      renderInput={(params) => {
                        if (clearTextfield) {
                          params.inputProps.value = ""
                        }
                        return (
                          <TextField
                            {...params}
                            placeholder="Search courses"
                            variant="filled"
                            onChange={() => setClearTextfield(false)}
                            helperText={
                              touched.selectedCourses && errors.selectedCourses
                                ? errors.selectedCourses
                                : ""
                            }
                            error={
                              touched.selectedCourses && errors.selectedCourses
                            }
                            FormHelperTextProps={{
                              className: classes.formHelperText,
                            }}
                          />
                        )
                      }}
                      onChange={(e, val) => {
                        setFieldValue("selectedCourses", [
                          ...values.selectedCourses,
                          val,
                        ])
                        setClearTextfield(true)
                      }}
                      onBlur={handleBlur}
                      openOnFocus
                      disableClearable
                      classes={{ inputRoot: classes.grayBackgroundColor }}
                    />
                    {values?.selectedCourses?.map((c, index) => (
                      <Box
                        key={c?.id}
                        height={36}
                        borderRadius={3}
                        border="1px solid #17A5B1"
                        display="flex"
                        alignItems="center"
                        marginTop={1}
                        justifyContent="space-between"
                      >
                        <Typography
                          color="secondary"
                          style={{ fontWeight: 600, marginLeft: "4px" }}
                        >
                          {c?.name
                            ? c.name
                            : "This course is no longer published"}
                        </Typography>
                        <IconButton
                          onClick={() =>
                            removeSelectedCourse(
                              values.selectedCourses,
                              index,
                              setFieldValue
                            )
                          }
                          size="small"
                        >
                          <RemoveCircleIcon
                            style={{ color: "#FF5E69", marginRight: "4px" }}
                            fontSize="small"
                          />
                        </IconButton>
                      </Box>
                    ))}
                  </Box>
                ) : null}
              </Box>
            </DialogContent>
            <DialogActions>
              <Box
                display="flex"
                alignItems="center"
                justifyContent={edit ? "space-between" : "flex-end"}
                width="100%"
                className={classes.buttonsContainer}
              >
                {edit ? (
                  <LoaderButton
                    classoverrides={{
                      wrapper: classes.button,
                      button: {
                        root: classes.buttonRootRemove,
                        outlined: classes.outline,
                      },
                    }}
                    variant="outlined"
                    working={false}
                    onClick={handleRemove}
                  >
                    Remove Code
                  </LoaderButton>
                ) : null}
                <Box className={classes.bottomRowButtons}>
                  <Button className={classes.cancel} onClick={handleClose}>
                    Cancel
                  </Button>
                  <LoaderButton
                    type="submit"
                    classoverrides={{
                      wrapper: classes.button,
                      button: {
                        root: classes.buttonRoot,
                      },
                    }}
                    variant="contained"
                    color="secondary"
                    working={isSubmitting}
                    style={{ marginLeft: "8px" }}
                  >
                    Save
                  </LoaderButton>
                </Box>
              </Box>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default CreateDiscountDialog
