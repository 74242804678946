import LoaderButton from "@bit/c_t.components.loader-button"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { setCourseLab } from "actions/courses"
import { listLabs } from "actions/labs"
import AppContext from "contexts/App"
import { isEmpty } from "lodash"
import { useCallback, useContext, useLayoutEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useHistory } from "react-router-dom"

import useStyles from "./styles"

const AddLabDialog = () => {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const { dialogs, closeDialog } = useContext(AppContext)
  const thisDialog = dialogs?.["addLabDialog"] || {}
  const { open = false, data } = thisDialog

  const [selectedLab, setSelectedLab] = useState()

  const queryClient = useQueryClient()

  useLayoutEffect(() => {
    if (data?.lab_id !== null && data?.lab_id !== undefined) {
      setSelectedLab(data?.lab_id)
    }
  }, [data?.lab_id])

  const labsQuery = useQuery("labs", listLabs, {
    enabled: open,
  })

  const smDown = useMediaQuery(theme.breakpoints.down("sm"))

  const handleClose = () => {
    closeDialog("addLabDialog", false)
  }

  //open Lesson dialog
  const openLabs = () => {
    history.push("/labs")
    handleClose()
  }

  const setCourseLabMutation = useMutation("setCourseLab", (mutationData) =>
    setCourseLab(...mutationData)
  )

  //close the dialog
  const addLab = useCallback(() => {
    setCourseLabMutation.mutate([data?.id, { lab_id: selectedLab }], {
      onSuccess: (res) => {
        queryClient.setQueryData(["course", data?.id], () => {
          return res
        })
        handleClose()
      },
      onError: (err) => {
        console.error(err)
      },
    })
  }, [data?.id, selectedLab])

  const handleSelect = (lab) => {
    setSelectedLab(lab.id)
  }

  const cleanup = () => {
    setSelectedLab(null)
  }

  return (
    <Dialog
      classes={{ paperWidthSm: classes.dialogContainer }}
      open={open}
      fullScreen={smDown}
      onExited={cleanup}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={classes.dialogTop}
        >
          <Typography variant="h2" className={classes.title}>
            ADD LAB
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Autocomplete
          size="small"
          value={selectedLab}
          options={labsQuery?.data || []}
          getOptionLabel={(option) => option.name || ""}
          renderInput={(params) => (
            <TextField {...params} placeholder="Select" variant="filled" />
          )}
          onChange={(_e, val) => handleSelect(val)}
          openOnFocus
          disableClearable
          // disabled={detailsLocked}
          classes={{
            inputRoot: classes.autocompleteRoot,
          }}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box>
            <Button className={classes.remove} onClick={openLabs}>
              Create Lab
            </Button>
          </Box>
          <Box>
            <Button
              onClick={handleClose}
              style={{ height: "40px", marginRight: "10px" }}
            >
              Cancel
            </Button>
            <LoaderButton
              onClick={addLab}
              classoverrides={{
                wrapper: classes.button,
                button: {
                  root: classes.buttonRoot,
                },
              }}
              variant="contained"
              color="secondary"
              disabled={selectedLab === null}
              working={setCourseLabMutation.isLoading}
              size="small"
            >
              add
            </LoaderButton>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

AddLabDialog.propTypes = {}

export default AddLabDialog
