import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Slide,
  Snackbar,
  Typography,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import AppContext from "contexts/App"
import LabContext from "contexts/Lab"
import LearningCuesContext from "contexts/LearningCues"
import { useContext, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router"

import useStyles from "./styles"

function TransitionDown(props) {
  return <Slide {...props} direction="up" />
}

const UnsavedChanges = () => {
  const classes = useStyles()

  const blockRef = useRef()

  const [openSaveSnack, setOpenSaveSnack] = useState(false)
  const [openErrorSnack, setOpenErrorSnack] = useState(false)
  const [dismissed, setDismissed] = useState(false)
  const history = useHistory()
  const [transition, setTransition] = useState(undefined)
  const {
    unsavedState,
    handleUpdate,
    saveLoading,
    discardChanges,
    tryPublish,
    disabledSave,
  } = useContext(LabContext)
  const { openDialog } = useContext(AppContext)

  //open error snack bar if the user need to complete more data before publish
  useEffect(() => {
    if (disabledSave && tryPublish) {
      setOpenErrorSnack(true)
      setTransition(() => TransitionDown)
    } else if (!disabledSave || !tryPublish) {
      setOpenErrorSnack(false)
    }
  }, [disabledSave, tryPublish])

  //prevent reload
  useEffect(() => {
    if (unsavedState.length > 0) {
      window.onbeforeunload = function () {
        return "Unsaved Data"
      }
    } else {
      window.onbeforeunload = function () {
        return null
      }
    }
  }, [unsavedState])

  //open save snack bar if we have unsaved data
  useEffect(() => {
    if (unsavedState.length > 0) {
      setOpenSaveSnack(true)
      setTransition(() => TransitionDown)
    } else if (unsavedState.length === 0) {
      setOpenSaveSnack(false)
      setDismissed(false)
    }
  }, [unsavedState])

  //open dialog if we go to other part of the app without save
  useEffect(() => {
    if (unsavedState.length > 0) {
      blockRef.current = {
        unblock: history.block((tx) => {
          openDialog("unsavedDataDialog", tx).then(() => {
            discardChanges() // for clearing lab details (except cues) back to last saved state
            blockRef.current.unblock()
            // tx.retry();
            history.push(tx.pathname)
          })
          return false
        }),
      }
    } else {
      blockRef.current?.unblock?.()
    }
  }, [unsavedState])

  return (
    <>
      <Snackbar
        open={openSaveSnack && !dismissed}
        classes={{ root: classes.snackBar }}
        message={<Typography variant="subtitle1">Unsaved Changes</Typography>}
        className={classes.snackBar}
        TransitionComponent={transition}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        action={
          <>
            {saveLoading ? (
              <Box display="flex" justifyContent="center" width="100%">
                <CircularProgress style={{ color: "#fff" }} size={18} />
              </Box>
            ) : (
              <Button color="secondary" size="small" onClick={handleUpdate}>
                SAVE
              </Button>
            )}
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setDismissed(true)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
      <Snackbar
        open={openErrorSnack}
        message={
          <Typography variant="subtitle1">
            Please complete all required inputs.
          </Typography>
        }
        ContentProps={{
          "aria-describedby": "message-id",
          className: classes.snackbarStyleViaContentProps,
        }}
        style={openSaveSnack ? { marginBottom: "80px" } : null}
        classes={{ root: classes.errorSnackBar }}
        TransitionComponent={transition}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setOpenErrorSnack(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </>
  )
}

export default UnsavedChanges
