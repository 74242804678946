import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  actionButtons: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  search: {
    flex: 10,
    background: "white",
    borderRadius: "5px",
    marginLeft: "2vw",
    width: 228,
    [theme.breakpoints.down("sm")]: {
      width: 200,
    },
  },
  searchIcon: {
    color: "#E2E2E2",
  },
}))

export default styles
