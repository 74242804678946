import { Box, CircularProgress, Typography } from "@material-ui/core"
import { AddCircle } from "@material-ui/icons"
import classnames from "clsx"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import { useCallback, useMemo } from "react"
import { useDropzone } from "react-dropzone"

import useStyles from "./styles"

const FileUpload = (props) => {
  const classes = useStyles()

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length) {
        props?.onError(rejectedFiles)
        return
      } else if (props?.type === "srt") {
        props?.onDrop(acceptedFiles, props?.videoUploaded)
      } else {
        props?.onDrop(acceptedFiles)
      }
    },
    [props]
  )

  const disableDrag = useMemo(() => {
    return !isEmpty(props.children)
  }, [props.children])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: props?.accepted ? props?.accepted : "image/*",
    maxFiles: 1,
    multiple: false,
    ...(props?.DropZoneProps || {}),
    noDrag: disableDrag,
    noDragEventsBubbling: disableDrag,
    disabled: props?.DropZoneProps?.disabled || props?.showLoading || false,
  })

  if (props.children) {
    return (
      <Box
        height={props?.childHeight}
        width={props?.childWidth}
        display="flex"
        position="relative"
      >
        {props?.showLoading && (
          <Box className={classes.loadingBox}>
            <CircularProgress color="secondary" />
          </Box>
        )}
        <Box {...getRootProps()} width="100%">
          <input {...getInputProps()} id={props?.id || "file-upload"} />
          {props.children}
        </Box>
      </Box>
    )
  } else {
    return (
      <Box height={props?.height} className={classes.uploadBox}>
        <Box
          {...getRootProps()}
          className={
            /* eslint-disable */
            props?.type !== "avatar"
              ? classnames(classes.dropzoneBox, classes.withBorder)
              : classnames(classes.avatarDropzoneBox, classes.avatarWithBorder)
            /* eslint-enable */
          }
        >
          {props?.showLoading && (
            <Box className={classes.loadingBox}>
              <CircularProgress color="secondary" />
            </Box>
          )}
          <input {...getInputProps()} id={props?.id || "file-upload"} />
          {props?.children && (
            <>
              <Box></Box>
              {props.children}
            </>
          )}
          {props.type === "avatar" ? (
            <Box m={1} className={classes.avatarBackground}>
              <Typography variant="subtitle2" color="secondary">
                {props?.title || "Drag image"}
              </Typography>
              <Typography variant="subtitle2" color="secondary">
                or <strong>click to browse files</strong> from your computer
              </Typography>
            </Box>
          ) : (
            <Box>
              <AddCircle color="primary" style={{ marginBottom: "4px" }} />
              <Typography
                variant="subtitle2"
                style={{ fontWeight: 700 }}
                className={classes.uploadTitle}
              >
                {props?.title || "Drop file to upload here"}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    )
  }
}

FileUpload.propTypes = {
  id: PropTypes.any,
  DropZoneProps: PropTypes.object,
  title: PropTypes.string,
  onDrop: PropTypes.func.isRequired,
  onError: PropTypes.func,
  children: PropTypes.node,
  showLoading: PropTypes.bool,
  height: PropTypes.string,
  type: PropTypes.string,
  videoUploaded: PropTypes.bool,
}

export default FileUpload
