import LoaderButton from "@bit/c_t.components.loader-button"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core"
import CounterHeader from "components/Common/CounterHeader"
import AppContext from "contexts/App"
import { Form, Formik } from "formik"
import PropTypes from "prop-types"
import { useContext, useState } from "react"
import { useQueryClient } from "react-query"
import { useHistory } from "react-router-dom"
import serialize from "store/serialize"
import characterCounter from "utils/characterCounter"
import * as Yup from "yup"

import useStyles from "./styles"

const CreateCourseLessonDialog = () => {
  const queryClient = useQueryClient()
  //reused dialog for create course, create lesson, create cliente and create instructor
  const classes = useStyles()
  const authContext = useContext(AppContext)
  const history = useHistory()
  const { dialogs, closeDialog, openSnackBar } = useContext(AppContext)
  const thisDialog = dialogs?.["createCourseLessonDialog"] || {}
  const { open = false, data } = thisDialog
  //you need to pass a title (string), subittle(string), type(string, example: course)
  //and createFunctionMutation (func to create), refetchData(update data) to the dialog
  let title = thisDialog?.data?.title
  let subtitle = thisDialog?.data?.subtitle
  let createFuncMutation = thisDialog?.data?.create
  let type = thisDialog?.data?.type

  const [helperText, setHelperText] = useState(null)

  const schema = Yup.object().shape({
    name: Yup.string()
      .required(`Please enter a ${type} name`)
      .max(50, "Name must be less than 50 characters"),
  })

  const handleClose = () => {
    setHelperText(null)
    closeDialog("createCourseLessonDialog", false)
  }

  const onSubmit = (values, actions) => {
    createFuncMutation.mutate(values, {
      onSuccess: (res) => {
        serialize(type, res.data).then((serializedData) => {
          queryClient.setQueryData(type + "s", (oldData) => {
            return [...oldData, serializedData]
          })
        })
        history.push(`/${type + "s"}/${res.data.id}`)
        authContext.openSnackBar({
          message: `${
            type?.[0]?.toUpperCase() + type.substring(1) + " Created"
          }`,
        })
      },
      onError: (err) => {
        console.error(err)
        // actions.setSubmitting(false)
        authContext.openSnackBar({
          message: `There was an error creating a ${type}`,
        })
      },
      onSettled: () => {
        handleClose()
      },
    })
  }

  return (
    <Dialog classes={{ paperWidthSm: classes.dialogMinHeight }} open={open}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>
        <Typography color="primary" variant="subtitle2">
          {subtitle}
        </Typography>
      </DialogTitle>
      <Formik
        initialValues={{ name: "" }}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        {({
          handleChange,
          values,
          errors,
          touched,
          handleBlur,
          isSubmitting,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <DialogContent className={classes.dialogContent}>
              <CounterHeader
                title={
                  type?.[0]?.toUpperCase() + type?.substring(1) + " " + "Name"
                }
                value={values.name}
                max={50}
              />
              <TextField
                type="text"
                name="name"
                id="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={
                  /* eslint-disable */
                  helperText
                    ? helperText
                    : touched.name && errors.name
                    ? errors.name
                    : " "
                  /* eslint-enable */
                }
                /* eslint-disable-next-line */
                autoFocus
                InputProps={{
                  classes: {
                    root: classes.inputBackgroundColor,
                  },
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter" && e.target.value.length > 0) {
                    onSubmit({ name: e.target.value })
                    e.preventDefault()
                  }
                }}
                error={helperText ? true : touched.name && errors.name}
                variant="filled"
                size="small"
                fullWidth
                FormHelperTextProps={{
                  className: classes.formHelperText,
                }}
              />
            </DialogContent>
            <DialogActions
              style={{
                position: "absolute",
                bottom: 4,
                right: 16,
              }}
            >
              <Button onClick={handleClose} style={{ height: "40px" }}>
                Cancel
              </Button>
              <LoaderButton
                type="submit"
                // onClick={onSubmit}
                classoverrides={{
                  wrapper: classes.button,
                  button: {
                    root: classes.buttonRoot,
                  },
                }}
                variant="contained"
                color="secondary"
                working={isSubmitting}
                size="small"
              >
                add
              </LoaderButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

CreateCourseLessonDialog.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  content: PropTypes.node,
}

export default CreateCourseLessonDialog
