import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  paper: {
    border: "1px solid #E2E2E2",
    borderRadius: "8px",
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: "200px",
  },
}))

export default styles
