import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  cropContainer: {
    position: "relative",
    height: "510px",
    width: "100%",
    border: `2px solid ${theme.palette.secondary.main}`,
  },
}))

export default styles
