import { updateCourse } from "actions/courses"
import { addLesson } from "actions/courses"
import { publishCourse } from "actions/courses"
import { archiveCourse } from "actions/courses"
import { unarchiveCourse } from "actions/courses"
import { unpublishCourse } from "actions/courses"
import { createTags } from "actions/courses"
import { setCourseResouces } from "actions/courses"
import { setPricing } from "actions/courses"
import { courseAnalytics } from "actions/courses"
import { addCategory } from "actions/courses"
import { newVersion } from "actions/courses"
import { getAssessment } from "actions/courses"
import { getCoursePreview } from "actions/courses"
import { createAssessment } from "actions/courses"
import { copyCourse } from "actions/courses"
import { listCourses } from "actions/courses"
import { setPublishCourseDate } from "actions/courses"
import { updatedDiff } from "deep-object-diff"
import { orderBy } from "lodash"
import PropTypes from "prop-types"
import { createContext, useContext, useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useHistory } from "react-router"
import serialize from "store/serialize"
import differenceBetween from "utils/differenceBetween"
import equalAndSameOrder from "utils/equalAndSameOrder"

import AppContext from "./App"

const CourseContext = createContext()
export default CourseContext

export const CourseContainer = ({ children, course, courseLoading }) => {
  //state to check if there is data unsaved
  const [unsavedState, setUnsavedState] = useState([])
  const queryClient = useQueryClient()
  const { openSnackBar, openDialog } = useContext(AppContext)
  //loding state for the save  as published if lesson is published
  //or save as draft if is a draft
  const [saveLoading, setSaveLoading] = useState(false)
  // loading state when publish a course
  const [publishLoading, setPublishLoading] = useState(false)
  // loading state when unpublish a course
  const [unpublishLoading, setUnpublishLoading] = useState(false)
  // loading state when archive a course
  const [archiveLoading, setArchiveLoading] = useState(false)
  // loading state when unarchive a course
  const [unarchiveLoading, setUnarchiveLoading] = useState(false)
  //open and close header menu
  const [anchorEl, setAnchorEl] = useState(null)
  //state to show the validation errors if the user try to publish
  const [tryPublish, setTryPublish] = useState(false)
  //state to show the validation errors for name, descritpion, headline
  const [lengthError, setLengthError] = useState(false)

  const history = useHistory()
  const { data: courses, refetch: refetchCourses } = useQuery("courses", () =>
    listCourses()
  )

  useEffect(() => {
    setUnsavedState([])
  }, [])

  //course general information state with debounced
  const [debouncedDetails, setDebouncedDetails] = useState({
    name: "",
    description: "",
    headline: "",
    mailgun_subject: "",
  })
  //course general information state

  const [courseDetailsValues, setCourseDetailsValues] = useState({
    name: "",
    description: "",
    headline: "",
    is_public: false,
    mailgun_template_id: "",
    mailgun_subject: "",
  })
  //course general information initial state
  const [initialDetailsContext, setInitialDetailsContext] = useState({
    name: "",
    description: "",
    headline: "",
    is_public: false,
    mailgun_template_id: "",
    mailgun_subject: "",
  })

  //check unsave details data
  useEffect(() => {
    const unsavedData = updatedDiff(initialDetailsContext, courseDetailsValues)
    if (
      Object.keys(unsavedData)?.length > 0 &&
      !unsavedState.includes("details")
    ) {
      setUnsavedState((oldState) => [...oldState, "details"])
    } else if (
      Object.keys(unsavedData)?.length === 0 &&
      unsavedState.includes("details")
    ) {
      setUnsavedState(
        unsavedState.filter((x) => {
          return x !== "details"
        })
      )
    }
  }, [courseDetailsValues, JSON.stringify(course)])

  const date = course?.publish_date ? course?.publish_date : null
  const [dateValue, setDateValue] = useState(date)

  const level = course?.level ? course?.level : ""
  const [levelValue, setLevelValue] = useState(level)

  //check unsave level data that is going inside details

  useEffect(() => {
    const initialValue = course.level ? course?.level : ""
    const unsavedData = initialValue !== levelValue
    if (unsavedData && !unsavedState.includes("level")) {
      setUnsavedState((oldState) => [...oldState, "level"])
    } else if (!unsavedData && unsavedState.includes("level")) {
      setUnsavedState(
        unsavedState.filter((x) => {
          return x !== "level"
        })
      )
    }
  }, [levelValue, JSON.stringify(course)])

  //tags state
  const [tagsContext, setTagsContext] = useState(course?.tags || [])

  //check unsave tags data
  useEffect(() => {
    let initialValues = course?.tags?.map((x) => x.name)
    let currentValues = tagsContext?.map((x) => x.name)

    let unsavedData = differenceBetween(initialValues, currentValues)

    if (unsavedData?.length > 0 && !unsavedState.includes("tags")) {
      setUnsavedState((oldState) => [...oldState, "tags"])
    } else if (unsavedData?.length === 0 && unsavedState.includes("tags")) {
      setUnsavedState(
        unsavedState.filter((x) => {
          return x !== "tags"
        })
      )
    }
  }, [tagsContext, JSON.stringify(course)])

  //categories state
  const [categoriesContext, setCategoriesContext] = useState(
    course?.categories || []
  )
  //check unsave categories data
  useEffect(() => {
    let initialValues = course?.categories?.map((x) => x.name)
    let currentValues = categoriesContext?.map((x) => x.name)

    let unsavedData = differenceBetween(initialValues, currentValues)

    if (unsavedData?.length > 0 && !unsavedState.includes("categories")) {
      setUnsavedState((oldState) => [...oldState, "categories"])
    } else if (
      unsavedData?.length === 0 &&
      unsavedState.includes("categories")
    ) {
      setUnsavedState(
        unsavedState.filter((x) => {
          return x !== "categories"
        })
      )
    }
  }, [categoriesContext, JSON.stringify(course)])

  //add lessons to a course
  const [lessonsArray, setLessonsArray] = useState([])
  //course general information initial state
  const [initialLessonsArray, setInitialLessonsArray] = useState([])

  //check unsave lessons data
  useEffect(() => {
    let initialValues = initialLessonsArray.map((x) => Number(x.lesson_id))
    let currentValues = lessonsArray.map((x) => Number(x.lesson_id))

    //check the order
    let unsavedData = equalAndSameOrder(initialValues, currentValues)
    //check if are the same
    let unsavedData2 = differenceBetween(initialValues, currentValues)
    if (
      (!unsavedData || unsavedData2?.length > 0) &&
      !unsavedState.includes("lessons")
    ) {
      setUnsavedState((oldState) => [...oldState, "lessons"])
    } else if (
      unsavedData &&
      unsavedData2?.length === 0 &&
      unsavedState.includes("lessons")
    ) {
      setUnsavedState(
        unsavedState.filter((x) => {
          return x !== "lessons"
        })
      )
    }
  }, [lessonsArray, JSON.stringify(course)])

  const [pricingContext, setPricingContext] = useState(course.pricing)
  const [stripePrice, setStripePrice] = useState("")

  // check unsave payments data
  useEffect(() => {
    let initialValues = course?.pricing

    let currentValues =
      pricingContext !== "paid"
        ? pricingContext
        : (stripePrice * 100).toString()

    let unsavedData = initialValues === currentValues
    if (!unsavedData && !unsavedState.includes("pricing")) {
      setUnsavedState((oldState) => [...oldState, "pricing"])
    } else if (unsavedData && unsavedState.includes("pricing")) {
      setUnsavedState(
        unsavedState.filter((x) => {
          return x !== "pricing"
        })
      )
    }
  }, [pricingContext, stripePrice, JSON.stringify(course)])

  //array of recourses to save the order
  const [recoursesCourseArray, setResourcesCourseArray] = useState([])
  //state to dont show error until the user try to publish the course

  const updateCourseMutation = useMutation((data) =>
    updateCourse(data, course.id)
  )
  const createTagsMutation = useMutation((data) => createTags(data, course.id))

  const addCategoryMutation = useMutation((data) =>
    addCategory(data, course.id)
  )

  const addLessonMutation = useMutation((data) => addLesson(data, course.id))

  const publishCourseMutation = useMutation(() => publishCourse(course?.id))

  const unpublishCourseMutation = useMutation(() => unpublishCourse(course?.id))

  const archiveCourseMutation = useMutation(() => archiveCourse(course?.id))

  const unarchiveCourseMutation = useMutation(() => unarchiveCourse(course?.id))

  const courseResourceMutation = useMutation((data) =>
    setCourseResouces(data, course?.id)
  )

  const pricingMutation = useMutation((data) => setPricing(data, course?.id))

  const newCourseVersionMutation = useMutation((data) =>
    newVersion(data, course?.id)
  )

  const analyticsMutation = useMutation(() => courseAnalytics(course?.id))

  const copyCourseMutation = useMutation((data) => copyCourse(data, course?.id))

  const setPublishDateMutation = useMutation((data) =>
    setPublishCourseDate(data, course?.id)
  )

  const createAssessmentMutation = useMutation((data) =>
    createAssessment(data, course?.id)
  )

  const getAssessmentMutation = useMutation(() => getAssessment(course?.id))

  const getPreviewMutation = useMutation(() => getCoursePreview(course?.id))

  //if stament to know what we need to complete to publish
  const disabledSave =
    debouncedDetails.name === "" ||
    debouncedDetails?.name?.length > 50 ||
    debouncedDetails.description === "" ||
    debouncedDetails?.description?.length > 2000 ||
    debouncedDetails?.mailgun_subject?.length > 256 ||
    levelValue === null ||
    // dateValue === null ||
    debouncedDetails.headline === "" ||
    debouncedDetails?.headline?.length > 100 ||
    tagsContext?.length === 0 ||
    categoriesContext?.length === 0 ||
    pricingContext?.length === 0 ||
    (pricingContext === "paid" && !stripePrice) ||
    // (pricingContext === "paid" &&
    //   !stripePrice?.match(/^(\d{1,3}(\d{3})*|(\d+))(\.\d{2})?$/g)) ||
    Object.keys(course?.thumbnail)?.length === 0 ||
    Object.keys(course?.background_image)?.length === 0

  //disabled the input depends the length when save
  let correctInputsLength =
    debouncedDetails?.name?.length <= 50 &&
    debouncedDetails?.headline?.length <= 100 &&
    debouncedDetails?.description?.length <= 2000 &&
    debouncedDetails?.mailgun_subject?.length <= 256

  //close menu
  const handleClose = () => {
    setAnchorEl(null)
  }
  //update price
  const setPrice = () => {
    return new Promise((resolve, reject) => {
      if (unsavedState.includes("pricing")) {
        if (
          (pricingContext === "paid" && !stripePrice) ||
          (pricingContext === "paid" &&
            !stripePrice.match(/^(\d{1,3}(\d{3})*|(\d+))(\.\d{2})?$/g))
        ) {
          openSnackBar({ message: "Error. Please enter a valid price" })
          reject()
        } else {
          const newPrice = (stripePrice * 100).toString()
          const data = {
            pricing: pricingContext === "paid" ? newPrice : pricingContext,
          }
          pricingMutation.mutate(data, {
            onSuccess: (res) => {
              serialize("course", res.data).then((serializedData) => {
                return queryClient.setQueryData(
                  ["course", res.data.id],
                  (oldState) => {
                    return serializedData
                  }
                )
              })
              resolve()
            },
            onError: (err) => {
              openSnackBar({ message: "Error updating price" })
              reject()
            },
          })
        }
      } else {
        resolve()
      }
    })
  }

  //update tags promise
  const addTags = () => {
    return new Promise((resolve, reject) => {
      if (unsavedState.includes("tags")) {
        createTagsMutation.mutate(tagsContext, {
          onSuccess: (res) => {
            serialize("course", res.tags).then((serializedData) => {
              return queryClient.setQueryData(
                ["course", course.id],
                (oldState) => {
                  oldState.tags = serializedData
                  return oldState
                }
              )
            })
            resolve()
          },
          onError: (err) => {
            openSnackBar({
              message: "Error Updating Course",
            })
            setSaveLoading(false)
            reject()
          },
        })
      } else {
        resolve()
      }
    })
  }

  //update cagegory promise
  const addCategories = () => {
    return new Promise((resolve, reject) => {
      if (unsavedState.includes("categories")) {
        addCategoryMutation.mutate(categoriesContext, {
          onSuccess: (res) => {
            serialize("course", res.data).then((serializedData) => {
              return queryClient.setQueryData(
                ["course", course.id],
                (oldState) => {
                  oldState.categories = serializedData
                  return oldState
                }
              )
            })
            resolve()
          },
          onError: (err) => {
            openSnackBar({
              message: "Error Updating Course",
            })
            setSaveLoading(false)
            reject()
          },
        })
      } else {
        resolve()
      }
    })
  }

  //update course data promise
  const updateData = () => {
    return new Promise((resolve, reject) => {
      if (unsavedState.includes("details") || unsavedState.includes("level")) {
        let data = debouncedDetails
        data.level = levelValue
        updateCourseMutation.mutate(data, {
          onSuccess: (res) => {
            serialize("course", res.data).then((serializedData) => {
              return queryClient.setQueryData(
                ["course", res.data.id],
                (oldState) => {
                  return serializedData
                }
              )
            })
            serialize("course", res.data).then((serializedData) => {
              return queryClient.setQueryData("courses", (oldData) => {
                //to update the number of lessons
                serializedData.lesson_count = serializedData.lessons.length
                let newArray = []
                newArray = oldData.map((x) => {
                  if (x.id !== serializedData.id) {
                    return x
                  } else {
                    return serializedData
                  }
                })
                return [...newArray]
              })
            })
            //if is published send the updated message
            if (course.published === 1) {
              openSnackBar({
                message: "Course Updated",
              })
            }
            resolve()
          },
          onError: (err) => {
            openSnackBar({
              message: "Error Updating Course",
            })
            setSaveLoading(false)
            reject()
          },
        })
      } else {
        resolve()
      }
    })
  }
  //add lessons to the course
  const addLessons = () => {
    return new Promise((resolve, reject) => {
      if (unsavedState.includes("lessons")) {
        let data = lessonsArray.map((x) => {
          return { lesson_id: x.lesson_id }
        })
        addLessonMutation.mutate(data, {
          onSuccess: (res) => {
            serialize("lesson", res.lessons).then((serializedData) => {
              queryClient.setQueryData(["course", course?.id], (oldData) => {
                oldData.lessons = serializedData
                return oldData
              })
            })

            resolve()
          },

          onError: (err) => {
            openSnackBar({
              message: `Error adding lessons`,
            })
            setSaveLoading(false)
            reject()
          },
        })
      } else {
        resolve()
      }
    })
  }

  //update resources order
  const reorderResources = () => {
    setSaveLoading(true)
    return new Promise((resolve, reject) => {
      if (recoursesCourseArray?.length > 0) {
        courseResourceMutation.mutate(
          //map resets the order prop to the index
          recoursesCourseArray.map((x, i) => ({
            ...x,
            order: i,
          })),
          {
            onSuccess: (res) => {
              const courseId = res.resources[0].course_id
              queryClient.setQueryData(["course", courseId], (oldData) => {
                return { ...oldData, resources: res.resources }
              })
              setSaveLoading(false)

              resolve()
            },
            onError: () => {
              openSnackBar({
                message: "Error ordering resource",
              })
              setSaveLoading(false)
              reject()
            },
          }
        )
      } else {
        resolve()
        setSaveLoading(false)
      }
    })
  }

  const setPublishDateFunc = (e) => {
    if (unsavedState?.length === 0) {
      let data = e === null ? { publish_date: null } : { publish_date: e }
      return new Promise((resolve, reject) => {
        setPublishDateMutation.mutate(data, {
          onSuccess: (res) => {
            serialize("course", res.data).then((serializedData) => {
              return queryClient.setQueryData(
                ["course", res.data.id],
                (oldData) => {
                  return serializedData
                }
              )
            })
            serialize("course", res.data).then((serializedData) => {
              return queryClient.setQueryData("courses", (oldData) => {
                let newArray = []
                newArray = oldData.map((x) => {
                  if (x.id !== serializedData.id) {
                    return x
                  } else {
                    return serializedData
                  }
                })
                return [...newArray]
              })
            })
            if (e === null) {
              openSnackBar({ message: "Publish date cancelled" })
            } else {
              openSnackBar({ message: "Publish date set" })
            }
            resolve()
          },
          onError: (err) => {
            openSnackBar({
              message: "Error setting publish date",
            })
            reject()
          },
        })
      })
    }
  }

  //update course depends if is published or not
  const handleUpdate = () => {
    // if starting on resources tab (ex: refresh/reload page), disabledSave is always true.
    // by keying on url path including /resources, reordering allowed in this situation.
    // note: disabledSave rights itself on navigating away from the resource page.
    if (history?.location?.pathname?.includes("resources")) {
      reorderResources()
        .then(() => {
          openSnackBar({
            message: "Resources Order Updated",
          })
        })
        .catch((err) => {
          openSnackBar({
            message: "Error Ordering Resources",
          })
          handleClose()
        })
    } else if (course.published === 0) {
      //hide errors needed if the user click publish before and have errors to publish
      setTryPublish(false)
      if (!correctInputsLength) {
        setLengthError(true)
      } else {
        setSaveLoading(true)
        return addTags()
          .then(addCategories)
          .then(addLessons)
          .then(setPrice)
          .then(updateData)
          .then(() => {
            openSnackBar({
              message: "Course Updated",
            })
            setSaveLoading(false)
            handleClose()
          })
          .catch((err) => {
            setSaveLoading(false)
            handleClose()
          })
      }
    } else {
      if (disabledSave) {
        //if course is published dont let save if some data is ont completed
        setTryPublish(true)
        handleClose()
      } else {
        setSaveLoading(true)
        return addTags()
          .then(addCategories)
          .then(updateData)
          .then(setPrice)
          .then(() => {
            openSnackBar({
              message: "Course Updated",
            })
            setSaveLoading(false)
            handleClose()
          })
          .catch((err) => {
            console.log(err)
            setSaveLoading(false)
            handleClose()
          })
      }
    }
  }
  //publish course
  const publishCourseFunc = () => {
    //open errors if the users didnt complete all that is require in disabledSave
    if (disabledSave) {
      setTryPublish(true)
      handleClose()
    } else {
      //open confirm dialog if the user attached at least one lesson and attached the final assessment
      if (lessonsArray?.length > 0 && course?.assessment_id !== null) {
        let data = {
          title: "Publish Confirmation",
          subtitle: "",
          type: "publish",
          confirmWord: "ship it",
        }
        handleClose()
        openDialog("confirmationTypingDialog", data).then(() => {
          handleUpdate().then(() => {
            setPublishLoading(true)
            let id = course.id
            publishCourseMutation.mutate(
              {},
              {
                onSuccess: (res) => {
                  serialize("course", res.data).then((serializedData) => {
                    return queryClient.setQueryData(
                      ["course", res.data.id],
                      (oldData) => {
                        return serializedData
                      }
                    )
                  })
                  refetchCourses()
                  openSnackBar({
                    message: "This course was published",
                  })
                  setPublishLoading(false)
                  handleClose()
                },
                onError: (err) => {
                  openSnackBar({
                    message: "Error Publishing Course",
                  })
                  setPublishLoading(false)
                  handleClose()
                },
              }
            )
          })
        })
      } else {
        setPublishLoading(false)
        if (lessonsArray?.length === 0) {
          openSnackBar({
            message: "You must attach a lesson to publish a course",
          })
        } else {
          openSnackBar({
            message: "You must attach an assessment",
          })
        }
      }
    }
  }
  //unpublishCourse
  const unpublishCourseFunc = () => {
    //check if the inputs have the corrent length
    if (!correctInputsLength) {
      setLengthError(true)
      handleClose()
    } else {
      //open confirm dialog
      let data = {
        title: "Unpublish Confirmation",
        subtitle: "",
        type: "unpublish",
        confirmWord: "unpublish",
      }
      handleClose()
      openDialog("confirmationTypingDialog", data).then(() => {
        setUnpublishLoading(true)
        handleUpdate().then(() => {
          let id = course.id
          unpublishCourseMutation.mutate(
            {},
            {
              onSuccess: (res) => {
                serialize("course", res.data).then((serializedData) => {
                  return queryClient.setQueryData(
                    ["course", res.data.id],
                    (oldData) => {
                      return serializedData
                    }
                  )
                })
                serialize("course", res.data).then((serializedData) => {
                  return queryClient.setQueryData("courses", (oldData) => {
                    //to update the number of lessons
                    serializedData.lesson_count = serializedData.lessons.length
                    let newArray = []
                    newArray = oldData.map((x) => {
                      if (x.id !== serializedData.id) {
                        return x
                      } else {
                        return serializedData
                      }
                    })
                    return [...newArray]
                  })
                })
                openSnackBar({
                  message: "This course was unpublished",
                })
                setUnpublishLoading(false)
                handleClose()
              },
              onError: (err) => {
                openSnackBar({
                  message: "Error unpublishing Course",
                })
                setUnpublishLoading(false)
                handleClose()
              },
            }
          )
        })
      })
    }
  }
  //archive course
  const archiveCourseFunc = () => {
    //delete errors if we try to publish before
    setTryPublish(false)
    //check if the inputs have the corrent length
    if (!correctInputsLength) {
      setLengthError(true)
      handleClose()
    } else {
      //open confirm dialog
      let data = {
        title: "Archive Confirmation",
        subtitle: "",
        type: "archive",
        confirmWord: "archive",
      }
      handleClose()
      openDialog("confirmationTypingDialog", data).then(() => {
        if (course.publish_date !== null) {
          setPublishDateFunc(null).then(() => {
            setDateValue(null)
          })
        }
        setArchiveLoading(true)
        handleUpdate().then(() => {
          let id = course.id
          archiveCourseMutation.mutate(
            {},
            {
              onSuccess: (res) => {
                serialize("course", res.data).then((serializedData) => {
                  return queryClient.setQueryData(
                    ["course", res.data.id],
                    (oldData) => {
                      return serializedData
                    }
                  )
                })
                serialize("course", res.data).then((serializedData) => {
                  return queryClient.setQueryData("courses", (oldData) => {
                    //to update the number of lessons
                    serializedData.lesson_count = serializedData.lessons.length
                    let newArray = []
                    newArray = oldData.map((x) => {
                      if (x.id !== serializedData.id) {
                        return x
                      } else {
                        return serializedData
                      }
                    })
                    return [...newArray]
                  })
                })
                openSnackBar({
                  message: "This course was archived",
                })
                setArchiveLoading(false)
                handleClose()
              },
              onError: (err) => {
                openSnackBar({
                  message: "Error archiving Course",
                })
                setArchiveLoading(false)
                handleClose()
              },
            }
          )
        })
      })
    }
  }
  //unarchive course
  const unarchiveCourseFunc = () => {
    //check if the inputs have the corrent length
    if (!correctInputsLength) {
      setLengthError(true)
      handleClose()
    } else {
      //open confirm dialog
      let data = {
        title: "Un-archive Confirmation",
        subtitle: "",
        type: "unarchive",
        confirmWord: "unarchive",
      }
      openDialog("confirmationTypingDialog", data).then(() => {
        setUnarchiveLoading(true)
        handleUpdate().then(() => {
          let id = course.id
          unarchiveCourseMutation.mutate(
            {},
            {
              onSuccess: (res) => {
                serialize("course", res.data).then((serializedData) => {
                  return queryClient.setQueryData(
                    ["course", res.data.id],
                    (oldData) => {
                      return serializedData
                    }
                  )
                })
                serialize("course", res.data).then((serializedData) => {
                  return queryClient.setQueryData("courses", (oldData) => {
                    //to update the number of lessons
                    serializedData.lesson_count = serializedData.lessons.length
                    let newArray = []
                    newArray = oldData.map((x) => {
                      if (x.id !== serializedData.id) {
                        return x
                      } else {
                        return serializedData
                      }
                    })
                    return [...newArray]
                  })
                })
                openSnackBar({
                  message: "This course was un-archived",
                })
                setUnarchiveLoading(false)
                handleClose()
              },
              onError: (err) => {
                openSnackBar({
                  message: "Error Un-archiving Course",
                })
                setUnarchiveLoading(false)
                handleClose()
              },
            }
          )
        })
      })
    }
  }

  //discard changes
  const discardChanges = () => {
    if (unsavedState.includes("details")) {
      setCourseDetailsValues(initialDetailsContext)
      setDebouncedDetails(initialDetailsContext)
    } else if (unsavedState.includes("tags")) {
      setTagsContext(course?.tags)
    } else if (unsavedState.includes("lessons")) {
      setLessonsArray(initialLessonsArray)
    } else if (unsavedState.includes("pricing")) {
      setPricingContext(course?.pricing)
    } else if (unsavedState.includes("categories")) {
      setPricingContext(course?.categories)
    } else if (unsavedState.includes("level")) {
      setLevelValue(course?.level)
    }
  }

  return (
    <CourseContext.Provider
      value={{
        course,
        unsavedState,
        setUnsavedState,
        saveLoading,
        publishLoading,
        unpublishLoading,
        archiveLoading,
        unarchiveLoading,
        anchorEl,
        setAnchorEl,
        tryPublish,
        setTryPublish,
        dateValue,
        setDateValue,
        lengthError,
        debouncedDetails,
        setDebouncedDetails,
        courseDetailsValues,
        setCourseDetailsValues,
        levelValue,
        setLevelValue,
        setInitialDetailsContext,
        tagsContext,
        setTagsContext,
        categoriesContext,
        setCategoriesContext,
        lessonsArray,
        setLessonsArray,
        setInitialLessonsArray,
        pricingContext,
        setPricingContext,
        stripePrice,
        setStripePrice,
        recoursesCourseArray,
        setResourcesCourseArray,
        createTagsMutation,
        addLessonMutation,
        publishCourseMutation,
        courseResourceMutation,
        setPrice,
        addTags,
        updateData,
        addLessons,
        reorderResources,
        handleUpdate,
        publishCourseFunc,
        unpublishCourseFunc,
        archiveCourseFunc,
        unarchiveCourseFunc,
        setPublishDateFunc,
        discardChanges,
        pricingMutation,
        courseLoading,
        updateCourseMutation,
        newCourseVersionMutation,
        copyCourseMutation,
        analyticsMutation,
        setPublishDateMutation,
        disabledSave,
        createAssessmentMutation,
        getAssessmentMutation,
        getPreviewMutation,
        correctInputsLength,
      }}
    >
      {children}
    </CourseContext.Provider>
  )
}

CourseContainer.propTypes = {
  children: PropTypes.node,
  course: PropTypes.object,
  courseLoading: PropTypes.bool,
}
