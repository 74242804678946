import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  inputLabel: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },

  publish: {
    width: "115px",
    backgroundColor: "#F4F4F4",
    borderRadius: "6px",
    "& .MuiFilledInput-inputMarginDense": {
      paddingTop: 0,
      paddingBottom: 0,
      height: "35px",
    },
  },
  expiration: {
    width: "115px",
    backgroundColor: "#F4F4F4",
    borderRadius: "6px",
    "& .MuiFilledInput-inputMarginDense": {
      paddingTop: 0,
      paddingBottom: 0,
      height: "35px",
    },
  },
  disabledExpiration: {
    // paddingLeft: "10px",
  },

  calendarIcon: {
    fontSize: 14,
  },
  checkBox: {
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  dialogWidth: { width: "unset" },
}))

export default styles
