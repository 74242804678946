import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@material-ui/core"
import beaker from "assets/Sidebar/beaker.svg"
import book from "assets/Sidebar/feather-book.svg"
import grid from "assets/Sidebar/feather-grid.svg"
import path from "assets/Sidebar/feather-path.svg"
import users from "assets/Sidebar/feather-users.svg"
import logo from "assets/Sidebar/logo.svg"
import discount from "assets/Sidebar/noun_Discount.svg"
import gradcap from "assets/Sidebar/noun_GraduationCap.svg"
import certificate from "assets/Sidebar/noun-certified.svg"
import work from "assets/Sidebar/work_outline.svg"
import AppContext from "contexts/App"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import { useContext, useMemo, useState } from "react"
import { Link, useHistory, useLocation } from "react-router-dom"

import useStyles from "./styles"

const Sidebar = () => {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()
  const { token, authUser } = useContext(AppContext)

  //checks current url first word to conditionally highlight
  const current = useMemo(() => {
    if (location.pathname !== "/") {
      const match = location.pathname.match(/^(\/[\w-]+)/)
      return match[1]
    }
  }, [location.pathname])

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Box className={classes.sideBar}>
        <Link to="/certifications" className={classes.link}>
          <Box height={80} marginTop={1}>
            <IconButton color="inherit">
              <img src={logo} alt="" className={classes.logo} />
            </IconButton>
          </Box>
        </Link>

        <Box
          className={
            current === "/certifications" ? classes.highlight : classes.tab
          }
        >
          <Tooltip title="certifications" placement="right">
            <Link to="/certifications" className={classes.link}>
              <IconButton color="inherit">
                <img src={certificate} alt="" />
              </IconButton>
            </Link>
          </Tooltip>
        </Box>

        <Box
          className={
            current === "/learning-paths" ? classes.highlight : classes.tab
          }
        >
          <Tooltip title="Learning Paths" placement="right">
            <Link to="/learning-paths" className={classes.link}>
              <IconButton color="inherit">
                <img src={path} alt="" />
              </IconButton>
            </Link>
          </Tooltip>
        </Box>

        <Box
          className={current === "/courses" ? classes.highlight : classes.tab}
        >
          <Tooltip title="Courses" placement="right">
            <Link to="/courses" className={classes.link}>
              <IconButton color="inherit">
                <img src={grid} alt="" />
              </IconButton>
            </Link>
          </Tooltip>
        </Box>

        <Box
          className={current === "/lessons" ? classes.highlight : classes.tab}
        >
          <Tooltip title="Lessons" placement="right">
            <Link to="/lessons" className={classes.link}>
              <IconButton color="inherit">
                <img src={book} alt="" />
              </IconButton>
            </Link>
          </Tooltip>
        </Box>
        <Box className={current === "/labs" ? classes.highlight : classes.tab}>
          <Tooltip title="Labs" placement="right">
            <Link to="/labs" className={classes.link}>
              <IconButton color="inherit">
                <img src={beaker} style={{ height: 21 }} alt="" />
              </IconButton>
            </Link>
          </Tooltip>
        </Box>
        <Divider className={classes.divider} />
        <Box className={current === "/users" ? classes.highlight : classes.tab}>
          <Tooltip title="Users" placement="right">
            <Link to="/users/user-list" className={classes.link}>
              <IconButton color="inherit">
                <img src={users} alt="" />
              </IconButton>
            </Link>
          </Tooltip>
        </Box>
        <Box
          className={current === "/clients" ? classes.highlight : classes.tab}
        >
          <Tooltip title="Clients" placement="right">
            <Link to="/clients" className={classes.link}>
              <IconButton color="inherit">
                <img src={work} alt="" />
              </IconButton>
            </Link>
          </Tooltip>
        </Box>
        <Box
          className={
            current === "/instructors" ? classes.highlight : classes.tab
          }
        >
          <Tooltip title="Instructors" placement="right">
            <Link to="/instructors" className={classes.link}>
              <IconButton color="inherit">
                <img src={gradcap} alt="" />
              </IconButton>
            </Link>
          </Tooltip>
        </Box>
        {authUser?.access_coupons ? (
          <Box
            className={
              current === "/discounts" ? classes.highlight : classes.tab
            }
          >
            <Tooltip title="Discount Codes" placement="right">
              <Link to="/discounts" className={classes.link}>
                <IconButton color="inherit">
                  <img src={discount} alt="" />
                </IconButton>
              </Link>
            </Tooltip>
          </Box>
        ) : null}
      </Box>
      <Box
        className={classes.phoneViewSideBar}
        display="flex"
        alignItems="center"
        style={!isEmpty(token) ? { height: 80 } : { height: 0 }}
      >
        <Box>
          <IconButton color="inherit" onClick={() => history.push("/courses")}>
            <img src={logo} alt="" className={classes.logo} />
          </IconButton>
        </Box>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <Typography variant="h4" style={{ color: "#fff" }}>
            Menu
          </Typography>
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          style={{ width: "200px" }}
          classes={{ paper: classes.menu }}
        >
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              history.push("/learning-paths")
            }}
          >
            Learning Paths
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              history.push("/courses")
            }}
          >
            Courses
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              history.push("/lessons")
            }}
          >
            Lessons
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              history.push("/users/user-list")
            }}
          >
            Users
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              history.push("/clients")
            }}
          >
            Clients
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              history.push("/instructors")
            }}
          >
            Instructors
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              history.push("/discounts")
            }}
          >
            Discounts
          </MenuItem>
        </Menu>
      </Box>
    </>
  )
}

Sidebar.propTypes = {
  children: PropTypes.node,
}

export default Sidebar
