import Record from "./base"

export class Admin extends Record {
  constructor(...args) {
    super(...args)
    const [model, props] = args
    //capitalize pending
    this.status = this.status.charAt(0).toUpperCase() + this.status.slice(1)
  }
}
