import api from "store/api"
import serialize from "store/serialize"

export const listLabs = (data) => {
  return api.get("/labs", data).then((res) => serialize("lab", res.data.labs))
}

export const getInstanceTypes = () => {
  return api.get("/labs/instance_types").then((res) => res.data.types)
}

export const createLab = (data) => {
  return api.post("/labs", data).then((res) => res.data.lab)
}

export const updateLab = (data, id) => {
  return api
    .put(`labs/${id}`, data)
    .then((res) => serialize("lab", res.data.lab))
}

export const getLab = (id) => {
  return api.get(`/labs/${id}`).then((res) => serialize("lab", res.data.lab))
}

export const lockLab = ({ id, name }) => {
  return api
    .put(`/labs/${id}`, { name, locked: true })
    .then((res) => serialize("lab", res.data.lab))
}

export const unlockLab = ({ id, name }) => {
  return api
    .put(`/labs/${id}`, { name, locked: false })
    .then((res) => serialize("lab", res.data.lab))
}

export const copyLab = (data, id) => {
  return api.post(`/copy_lab/${id}`, data).then((res) => res.data)
}

export const createPort = (labId, data) => {
  return api
    .post(`/labs/${labId}/port`, data)
    .then((res) => serialize("lab", res.data.lab))
}

export const updatePort = (labId, portId, data) => {
  return api
    .put(`/labs/${labId}/port/${portId}`, data)
    .then((res) => serialize("lab", res.data.lab))
}

export const removePort = (labId, portId) => {
  return api
    .delete(`/labs/${labId}/port/${portId}`)
    .then((res) => serialize("lab", res.data.lab))
}

export const orderPorts = (labId, data) => {
  return api
    .put(`/labs/${labId}/port/order`, data)
    .then((res) => serialize("lab", res.data.lab))
}
