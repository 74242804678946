import { Box, Button, CircularProgress, Typography } from "@material-ui/core"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import DescriptionIcon from "@material-ui/icons/Description"
import LockIcon from "@material-ui/icons/Lock"
import { getVideoSrt } from "actions/lessons"
import { requestSrtUpload } from "actions/upload"
import { addSrt } from "actions/upload"
import { uploadSrt } from "actions/upload"
import EditIcon from "assets/Icons/edit-icon.svg"
import DarkButton from "components/Common/DarkButton"
import FileUpload from "components/Common/FileUpload"
import PlayerWithControls from "components/Common/PlayerWithControls"
import VideoUpload from "components/Common/VideoUpload"
import AppContext from "contexts/App"
import LearningCuesContext from "contexts/LearningCues"
import LessonContext from "contexts/Lesson"
import { VideoContainer } from "contexts/Video"
import useThumbnail from "hooks/useThumbnail"
import { useCallback, useContext, useEffect, useState } from "react"
import { useMutation } from "react-query"
import { useQueryClient } from "react-query"
import { Link } from "react-router-dom"

import useStyles from "./styles"

const LessonContent = () => {
  const classes = useStyles()

  const { videoUrl, videoSrt, lesson } = useContext(LessonContext)
  const { openSnackBar } = useContext(AppContext)
  const { learningCuesQuery, cueCount, setCueCount } = useContext(
    LearningCuesContext
  )

  const lessonThumbnail = useThumbnail(lesson?.thumbnail)

  const queryClient = useQueryClient()

  const [srt, setSrt] = useState(null)
  const [loading, setLoading] = useState(false)

  const videoUploaded = videoUrl?.isSuccess
  const srtName = lesson?.video?.srt_key?.split("-")?.[1]

  const disabledLesson =
    (lesson.published || lesson.has_been_published) && lesson.archived === 0

  const requestSrtUploadMutation = useMutation(
    "requestSrtUpload",
    requestSrtUpload
  )

  const uploadSrtFile = async (srt, videoUploaded) => {
    if (lesson.video_id || videoUploaded) {
      setLoading(true)
      const timestamp = +new Date()
      requestSrtUploadMutation
        .mutateAsync({
          key: `srt/${timestamp}-${srt.name}`,
          type: "text/plain",
        })
        .then((res) => {
          uploadSrt({ ...res.data, file: srt })
            .then(() => addSrt({ key: res.data.fields.key }, lesson?.video_id))
            .then(() => setSrt(srt.name))
            .then(() =>
              queryClient.setQueryData(["lesson", lesson.id], (oldData) => {
                oldData.video.srt_key = res.data.fields.key
                return oldData
              })
            )
            .catch(
              (err) =>
                openSnackBar({
                  message: "There was an error uploading this srt file",
                }),
              setSrt(null)
            )
            .finally(() => setLoading(false))
        })
    } else {
      openSnackBar({ message: "You must add a video before adding captions" })
    }
  }

  const onDrop = (acceptedFiles, videoUploaded) => {
    uploadSrtFile(acceptedFiles[0], videoUploaded)
  }

  const openSrt = () => {
    if (videoSrt.data) {
      window.open(videoSrt.data)
    } else {
      getVideoSrt(lesson?.video_id)
        .then((res) => window.open(res))
        .catch(() =>
          openSnackBar({ message: "There was an error showing the srt file" })
        )
    }
  }

  useEffect(() => {
    const srtKey = lesson?.video?.srt_key
    if (srtKey) {
      const filename = srtKey.split("-")
      setSrt(filename[1])
    }
  }, [lesson.video])

  return (
    <>
      {disabledLesson ? (
        <Box className={classes.publishedBanner}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {lesson?.published === 1 ? (
              <Typography className={classes.title} variant="h3">
                You are in a published lesson, this can not be updated
              </Typography>
            ) : null}
            {lesson?.published === 0 && lesson?.has_been_published === 1 ? (
              <Typography className={classes.title} variant="h3">
                You are in a unpublished lesson, this can not be updated
              </Typography>
            ) : null}
            <Typography variant="subtitle1" className={classes.subtitle}>
              To update this you must create a new version
            </Typography>
          </Box>
        </Box>
      ) : null}
      <Box className={classes.contentContainer}>
        <Box flexGrow={1} paddingRight={3.25}>
          {videoUrl?.data && (
            <Box>
              <VideoContainer lesson={lesson}>
                {({ ReactPlayerProps }) => (
                  <>
                    <Typography variant="h6" className={classes.previewTitle}>
                      Video Preview
                    </Typography>
                    <PlayerWithControls
                      url={videoUrl?.data}
                      poster={lessonThumbnail}
                      ReactPlayerProps={ReactPlayerProps}
                      duration={lesson?.duration || 0}
                    />
                  </>
                )}
              </VideoContainer>
            </Box>
          )}
          {!disabledLesson && <VideoUpload />}
        </Box>
        <Box marginTop={3.25} className={classes.lessonCues} flexShrink={0}>
          {learningCuesQuery.isLoading ? (
            <CircularProgress color="primary" />
          ) : (
            <>
              <Typography className={classes.cueCountText} color="secondary">
                {cueCount ? cueCount : `0`}
              </Typography>
              <Typography
                color="secondary"
                variant="h5"
                className={classes.cuesText}
              >
                Lesson Cues
              </Typography>
              <DarkButton
                disabled={lesson.video_id === null || Boolean(disabledLesson)}
                style={disabledLesson ? { display: "none" } : null}
                component={Link}
                to={`/lessons/${lesson.id}/cues`}
                size="large"
                startIcon={<AddCircleIcon />}
              >
                {cueCount
                  ? `Add another learning cue`
                  : `Add your first learning cue`}
              </DarkButton>
            </>
          )}
        </Box>
      </Box>
      <Box>
        <Box className={classes.srtUploadContainer}>
          <Typography color="secondary" className={classes.inputLabel}>
            Captions
          </Typography>
          <FileUpload
            title="Add .srt file here"
            DropZoneProps={{ accept: ".srt", disabled: disabledLesson }}
            onDrop={onDrop}
            // onError={onError}
            showLoading={loading}
            type="srt"
            videoUploaded={videoUploaded}
          >
            {(srt || srtName) && (
              <Box className={classes.srtContainer}>
                <Box className={classes.editBox}>
                  {disabledLesson ? (
                    <LockIcon
                      fontSize="large"
                      color="inherit"
                      style={{ color: "#fff" }}
                    />
                  ) : (
                    <img src={EditIcon} alt="Edit file" style={{ width: 24 }} />
                  )}
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  width={271}
                >
                  <DescriptionIcon color="primary" />
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    style={{ fontWeight: "bold" }}
                  >
                    {srt || srtName}
                  </Typography>
                </Box>
              </Box>
            )}
          </FileUpload>
          {srt ? (
            <Button
              onClick={openSrt}
              color="secondary"
              style={{ marginTop: "16px" }}
            >
              View srt file
            </Button>
          ) : null}
        </Box>
      </Box>
    </>
  )
}

export default LessonContent
