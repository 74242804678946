import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  selectBorder: {
    color: "#17A5B1",
    "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #17A5B1",
    },
  },
  icon: {
    color: "#17A5B1",
  },
  inputBackgroundColor: {
    background: "white",
    "&.Mui-focused": {
      background: "white",
    },
    "&:hover": {
      background: "white",
    },
  },
  downloadText: {
    fontWeight: "bold",
  },
}))

export default styles
