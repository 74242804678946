import { Button } from "@material-ui/core"
import PropTypes from "prop-types"

import useStyles from "./styles"

const DarkButton = (props) => {
  const classes = useStyles()

  return (
    <Button
      variant="contained"
      disableElevation
      classes={{
        root: classes.darkButton,
        disabled: classes.buttonDisabled,
        label: classes.buttonLabel,
      }}
      {...props}
    >
      {props.children}
    </Button>
  )
}

DarkButton.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DarkButton
