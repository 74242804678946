import { Box, CircularProgress, Typography } from "@material-ui/core"
import ListTitle from "components/Common/ListTitle"
import NoData from "components/Common/NoData"
import AppContext from "contexts/App"
import PropTypes from "prop-types"
import { useContext, useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useHistory } from "react-router-dom"
import sortHelper from "utils/sortHelper"

import LabCard from "../LabCard"
import useStyles from "./styles"

const LabCardList = ({
  searchValue,
  sort,
  labs,
  openCreateDialog,
  statusState,
  setStatusNumber,
}) => {
  const classes = useStyles()
  const [filteredData, setFilteredData] = useState([])
  const { setPageSubTitle } = useContext(AppContext)
  //amount of data that we show using the infinite scroll

  const [showedData, setShowedData] = useState([])

  const history = useHistory()

  const handleRoute = (id) => {
    history.push(`/labs/${id}`)
  }

  //set statuses
  useEffect(() => {
    setStatusNumber({
      locked: labs.filter((x) => x.locked).length,
      unlocked: labs.filter((x) => !x.locked).length,
    })
  }, [labs])

  //filters depending on the searchValue or sort
  useEffect(() => {
    if (labs) {
      let newArray = [...labs]

      if (statusState === "locked") {
        newArray = newArray.filter((x) => x.locked)
      } else {
        newArray = newArray.filter((x) => !x.locked)
      }
      if (searchValue.length > 0 || sort?.name !== "") {
        if (searchValue.length > 0) {
          newArray = newArray?.filter((x) =>
            x.name.toLowerCase().includes(searchValue.toLowerCase())
          )
        }
        if (sort?.name !== "") {
          //util function to sort the data
          newArray = sortHelper(
            newArray,
            sort?.type,
            sort?.name,
            sort?.direction
          )
        }
      }
      setFilteredData(newArray)
      //show 20 first elements of the newArray
      setShowedData(newArray?.slice(0, 20 || []))
      //set labs  subtitle depends the search
      setPageSubTitle(newArray?.length)
    }
  }, [searchValue, sort, labs, statusState])

  //loadData using infinite scroll
  const loadData = () => {
    setShowedData(() => filteredData.slice(0, showedData.length + 20))
  }

  //send the user to the top if a status state, sort or search value change
  useEffect(() => {
    const element = document.getElementById("labsListScroll")
    if (element) {
      element.scrollTo(0, 0)
    }
  }, [statusState, searchValue, sort])

  return (
    <Box className={classes.container} overflow="scroll" id="labsListScroll">
      <Box className={classes.headerRow}>
        {filteredData?.length > 0 ? (
          <ListTitle
            list={[
              { name: "", size: "100px" },
              { name: "Title", size: "38%" },
              { name: "Server Type", size: "20%" },
              { name: "", size: "20%" },
            ]}
          />
        ) : null}
      </Box>
      {filteredData?.length > 0 ? (
        <InfiniteScroll
          style={{ overflow: "hidden" }}
          dataLength={showedData?.length || 0}
          scrollableTarget="labsListScroll"
          next={loadData}
          loader={
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          }
          hasMore={filteredData?.length > showedData?.length || false}
          endMessage={
            <Box width="100%" textAlign="center">
              {filteredData.length > 20 && (
                <Typography className={classes.postsInfiniteText}>
                  End of Labs List
                </Typography>
              )}
            </Box>
          }
        >
          {showedData.map((lab) => {
            return (
              <LabCard
                key={lab?.id}
                openLesson={() => handleRoute(lab?.id)}
                lab={lab}
                updatedDate={
                  typeof lab?.updated === "string"
                    ? lab?.udpated
                    : lab?.updated?.format("M/D/YYYY")
                }
                creator={lab?.creator_admin_id}
              />
            )
          })}
        </InfiniteScroll>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          minHeight="80vh"
        >
          <NoData
            text="You have not created any courses"
            buttonFunc={openCreateDialog}
            searchValue={searchValue}
            buttonText="create my first course"
            length={labs.length}
            searchLength={filteredData.length}
          />
        </Box>
      )}
    </Box>
  )
}

LabCardList.propTypes = {
  labs: PropTypes.array,
  searchValue: PropTypes.string,
  sort: PropTypes.object,
  openCreateDialog: PropTypes.func,
  statusState: PropTypes.string,
  setStatusNumber: PropTypes.func,
}

export default LabCardList
