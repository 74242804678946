import { Box, Typography } from "@material-ui/core"
import { courseAnalytics } from "actions/courses"
import AnalyticsFilters from "components/Analytics/AnalyticsFilters"
import AnalyticsTable from "components/Analytics/AnalyticsTable"
import AppContext from "contexts/App"
import CourseContext from "contexts/Course"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useContext, useEffect, useState } from "react"
import { useQuery } from "react-query"
import { useHistory } from "react-router-dom"
import Router from "router"

import useStyles from "./styles"

const Analytics = ({ routes = [], ...rest }) => {
  const classes = useStyles()
  const history = useHistory()

  const { analyticsMutation, course } = useContext(CourseContext)
  const { authUser } = useContext(AppContext)

  const [courseDuration, setCourseDuration] = useState(0)

  // looks through course and puts each lesson's video duration in an array
  const lessonDurations = () => {
    let durationArray = []
    //map through each course's lessons array
    course?.lessons?.map((lesson) => {
      if (lesson?.video && Object.keys(lesson?.video).length > 0) {
        // if a lesson has a video, pushing the duration (in s) to the array
        durationArray.push(
          lesson?.video?.duration || lesson?.video[0]?.duration
        )
      }
    })
    // sumDurations adds each value in the array - gives total duration (in s)
    const sumDurations = durationArray.reduce((a, b) => a + b, 0)
    return sumDurations
  }
  // on inital load of card, set state variable
  useEffect(() => {
    setCourseDuration(lessonDurations())
  }, [course])

  const headCells = [
    {
      title: "USER ID",
      field: "user_id",
      headerStyle: {
        width: "20%",
        maxWidth: "20%",
        padding: "5px 20px",
      },
      cellStyle: {
        width: "20%",
        maxWidth: "20%",
        height: "25px",
        maxHeight: "25px",
        padding: "5px 20px",
      },
      render: function idFunc(rowData) {
        return (
          <>
            <Typography variant="subtitle1">{rowData.user_id}</Typography>
          </>
        )
      },
    },
    {
      title: "User Name",
      field: "username",

      headerStyle: {
        width: "20%",
        maxWidth: "20%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "20%",
        maxWidth: "20%",
        maxHeight: "25px",
        height: "25px",

        padding: "5px 10px",
      },
      render: function userNameFunc(rowData) {
        return (
          <>
            <Typography variant="subtitle1">{rowData.username}</Typography>
          </>
        )
      },
    },
    {
      title: "First Name",
      field: "first_name",
      headerStyle: {
        width: "20%",
        maxWidth: "20%",
        height: "25px",

        padding: "5px 10px",
      },
      cellStyle: {
        width: "20%",
        maxWidth: "20%",
        maxHeight: "25px",
        height: "25px",

        padding: "5px 10px",
      },
      render: function firstNameFunc(rowData) {
        return (
          <>
            <Typography variant="subtitle1">{rowData.first_name}</Typography>
          </>
        )
      },
    },
    {
      title: "Last Name",
      field: "last_name",
      headerStyle: {
        width: "20%",
        maxWidth: "20%",
        height: "25px",

        padding: "5px 10px",
      },
      cellStyle: {
        width: "20%",
        maxWidth: "20%",
        maxHeight: "25px",
        height: "25px",

        padding: "5px 10px",
      },
      render: function lastNameFunc(rowData) {
        return (
          <>
            <Typography variant="subtitle1">{rowData.last_name}</Typography>
          </>
        )
      },
    },
    {
      title: "Email",
      field: "email",
      headerStyle: {
        width: "15%",
        maxWidth: "15%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "15%",
        maxWidth: "15%",
        maxHeight: "25px",
        height: "25px",

        padding: "5px 10px",
      },
      render: function version(rowData) {
        return (
          <>
            <Typography variant="subtitle1">{rowData.email}</Typography>
          </>
        )
      },
    },
    {
      title: "Lesson Completion",
      field: "completed_lessons",
      headerStyle: {
        width: "15%",
        maxWidth: "15%",
        height: "25px",

        padding: "5px 10px",
      },
      cellStyle: {
        width: "15%",
        maxWidth: "15%",
        maxHeight: "25px",
        height: "25px",

        padding: "5px 10px",
      },
      render: function completedLessonsFunc(rowData) {
        return (
          <>
            <Typography variant="subtitle1">
              {rowData.completed_lessons}
            </Typography>
          </>
        )
      },
    },
    {
      title: "Course Status",
      field: "course_status",
      headerStyle: {
        width: "15%",
        maxWidth: "15%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "15%",
        maxWidth: "15%",
        maxHeight: "25px",
        height: "25px",

        padding: "5px 10px",
      },
      render: function courseStatusFunc(rowData) {
        return (
          <>
            <Typography variant="subtitle1">{rowData.course_status}</Typography>
          </>
        )
      },
    },
    {
      title: "Course ID",
      field: "course_id",
      headerStyle: {
        width: "15%",
        maxWidth: "15%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "15%",
        maxWidth: "15%",
        maxHeight: "25px",
        height: "25px",

        padding: "5px 10px",
      },
      render: function courseIdFunc(rowData) {
        return (
          <>
            <Typography variant="subtitle1">{rowData.course_id}</Typography>
          </>
        )
      },
    },
    {
      title: "Assessment Attemps",
      field: "assessment_attempts",
      headerStyle: {
        width: "15%",
        maxWidth: "15%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "15%",
        maxWidth: "15%",
        maxHeight: "25px",
        height: "25px",

        padding: "5px 10px",
      },
      render: function assessmentsAttempsFunc(rowData) {
        return (
          <>
            <Typography variant="subtitle1">
              {rowData.assessment_attempts}
            </Typography>
          </>
        )
      },
    },
    {
      title: "Passed Assessment",
      field: "passed_assessment",
      headerStyle: {
        width: "15%",
        maxWidth: "15%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "15%",
        maxWidth: "15%",
        maxHeight: "25px",
        height: "25px",

        padding: "5px 10px",
      },
      render: function passedAssessmentFunc(rowData) {
        return (
          <>
            <Typography variant="subtitle1">
              {rowData.passed_assessment === null
                ? "-"
                : rowData.passed_assessment}
            </Typography>
          </>
        )
      },
    },
    {
      title: "Best Assessment Score",
      field: "best_assessment_score",
      headerStyle: {
        width: "15%",
        maxWidth: "15%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "15%",
        maxWidth: "15%",
        maxHeight: "25px",
        height: "25px",

        padding: "5px 10px",
      },
      render: function bestAssessmentScoreFunc(rowData) {
        return (
          <>
            <Typography variant="subtitle1">
              {rowData.best_assessment_score === null
                ? "-"
                : rowData.best_assessment_score}
            </Typography>
          </>
        )
      },
    },
    {
      title: "Time on Course",
      field: "time_on_course",
      headerStyle: {
        width: "15%",
        maxWidth: "15%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "15%",
        maxWidth: "15%",
        maxHeight: "25px",
        height: "25px",

        padding: "5px 10px",
      },
      render: function timeOnCourseFunc(rowData) {
        return (
          <>
            <Typography variant="subtitle1">
              {rowData.time_on_course === null ? "-" : rowData.time_on_course}
            </Typography>
          </>
        )
      },
    },
    {
      title: "Unique days on course",
      field: "unique_days_on_course",
      headerStyle: {
        width: "15%",
        maxWidth: "15%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "15%",
        maxWidth: "15%",
        maxHeight: "25px",
        height: "25px",

        padding: "5px 10px",
      },
      render: function uniqueDaysOnCourseFunc(rowData) {
        return (
          <>
            <Typography variant="subtitle1">
              {rowData.unique_days_on_course === null
                ? "-"
                : rowData.unique_days_on_course}
            </Typography>
          </>
        )
      },
    },

    {
      title: "Version",
      field: "version",
      headerStyle: {
        width: "10%",
        maxWidth: "10%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "10%",
        maxWidth: "10%",
        height: "25px",
        maxHeight: "25px",
        padding: "5px 10px",
      },
      render: function version(rowData) {
        return (
          <>
            <Typography variant="subtitle1">{rowData.version}</Typography>
          </>
        )
      },
    },
  ]

  //checks auth user role
  useEffect(() => {
    if (authUser?.access_analytics !== 1) {
      history.push("/courses")
    }
  }, [authUser])

  const fields = [
    "user_id",
    "username",
    "first_name",
    "last_name",
    "email",
    "completed_lessons",
    "course_status",
    "course_id",
    "assessment_attempts",
    "passed_assessment",
    "best_assessment_score",
    "time_on_course",
    "unique_days_on_course",
    "version",
  ]

  return (
    <Page>
      <Box className={classes.container} width="100%">
        {/* <AnalyticsFilters type={"course"} /> */}
        <AnalyticsTable
          headCells={headCells}
          mutation={analyticsMutation}
          type={"course"}
          data={{ ...course, duration: courseDuration }}
          fields={fields}
        />
        <Router routes={routes} {...rest} />
      </Box>
    </Page>
  )
}

Analytics.propTypes = {
  routes: PropTypes.array,
}

export default Analytics
