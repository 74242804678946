import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  boxOne: {
    width: "20%",
    display: "flex",
    flexDirection: "row",
  },
  boxTwo: {
    width: "35%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  boxThree: {
    width: "20%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  boxFour: {
    width: "20%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },

  paper: {
    border: "1px solid #E2E2E2",
    borderRadius: "8px",
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: "200px",
  },
}))

export default styles
