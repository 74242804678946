import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core"
import PropTypes from "prop-types"

import useStyles from "./styles"

const Sidebar = ({ lockdown, questionTypes, store }) => {
  const classes = useStyles()

  const handleAddQuestion = (model, type) => {
    const Question = model
    const question = Question.create({ type })
    store.addQuestion(question)
  }

  return (
    <Box className={classes.sidebar}>
      <Typography
        color="secondary"
        style={{
          fontSize: "0.6875rem",
          textTransform: "uppercase",
          marginBottom: 14,
          fontWeight: 700,
        }}
      >
        Click to add new question
      </Typography>
      <List component="nav" aria-label="question type" className={classes.list}>
        {questionTypes.map((questionObj) => (
          <ListItem
            key={questionObj.type}
            onClick={() =>
              handleAddQuestion(questionObj.model, questionObj.type)
            }
            className={classes.listItem}
            button
            disableRipple
            disabled={lockdown}
          >
            <ListItemText
              classes={{ primary: classes.listItemText }}
              className={classes.listItemText}
              primary={questionObj.name}
            />
          </ListItem>
        ))}
        {/* <ListItem
          onClick={() => handleAddQuestion("true_false")}
          className={classes.listItem}
          button
          disableRipple
          disabled={lockdown}
        >
          <ListItemText
            classes={{ primary: classes.listItemText }}
            className={classes.listItemText}
            primary="True / False"
          />
        </ListItem>
        {!insideCourse && (
          <ListItem
            onClick={() => handleAddQuestion("fill_blank")}
            className={classes.listItem}
            button
            disableRipple
            disabled={lockdown}
          >
            <ListItemText
              classes={{ primary: classes.listItemText }}
              className={classes.listItemText}
              primary="Fill in the Blank"
            />
          </ListItem>
        )} */}
      </List>
    </Box>
  )
}

Sidebar.propTypes = {
  lockdown: PropTypes.bool,
  questionTypes: PropTypes.array,
  store: PropTypes.func,
}

export default Sidebar
