import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  nameText: {
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  emailText: {
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  switch: {
    width: "10%",
    [theme.breakpoints.down("sm")]: {
      width: "30%",
    },
  },
  statusText: {
    width: "10%",
    [theme.breakpoints.down("sm")]: {
      width: "30%",
    },
  },
  button: {
    width: "20%",
    [theme.breakpoints.down("sm")]: {
      width: "30%",
    },
  },
}))

export default styles
