import { addCourses, updatePath } from "actions/paths"
import { createTags } from "actions/paths"
import { publishLearningPath } from "actions/paths"
import { archiveLearningPath } from "actions/paths"
import { unarchiveLearningPath } from "actions/paths"
import { unpublishLearningPath } from "actions/paths"
import { pathAnalytics } from "actions/paths"
import { updatedDiff } from "deep-object-diff"
import PropTypes from "prop-types"
import { createContext, useContext, useEffect, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import serialize from "store/serialize"
import differenceBetween from "utils/differenceBetween"

import AppContext from "./App"

const PathContext = createContext()
export default PathContext

export const PathContainer = ({ children, path }) => {
  //state to check if there is data unsaved
  const [unsavedState, setUnsavedState] = useState([])
  const queryClient = useQueryClient()
  const { openSnackBar, openDialog } = useContext(AppContext)

  //loding state for the save  as published if learning path is published
  //or save as draft if is a draft
  const [saveLoading, setSaveLoading] = useState(false)
  // loading state when publish a learning path
  const [publishLoading, setPublishLoading] = useState(false)
  // loading state when unpublish a learning path
  const [unpublishLoading, setUnpublishLoading] = useState(false)
  // loading state when archive a learning path
  const [archiveLoading, setArchiveLoading] = useState(false)
  // loading state when unarchive a learning path
  const [unarchiveLoading, setUnarchiveLoading] = useState(false)
  //open and close header menu
  const [anchorEl, setAnchorEl] = useState(null)
  //state to show the validation errors if the user try to publish
  const [tryPublish, setTryPublish] = useState(false)
  //state to show the validation errors for name, descritpion, headline
  const [lengthError, setLengthError] = useState(false)

  useEffect(() => {
    setUnsavedState([])
  }, [])

  //learning path general information state with debounced
  const [debouncedDetails, setDebouncedDetails] = useState({
    name: "",
    description: "",
    headline: "",
  })

  //learning path general information state
  const [pathDetailsValues, setPathDetailsValues] = useState({
    accredible_group_id: "",
    name: "",
    description: "",
    headline: "",
    is_public: false,
  })

  //learning path  general information initial state
  const [initialDetailsContext, setInitialDetailsContext] = useState({
    name: "",
    description: "",
    headline: "",
    is_public: false,
  })

  //check unsave details data
  useEffect(() => {
    const unsavedData = updatedDiff(initialDetailsContext, pathDetailsValues)
    if (
      Object.keys(unsavedData)?.length > 0 &&
      !unsavedState.includes("details")
    ) {
      setUnsavedState((oldState) => [...oldState, "details"])
    } else if (
      Object.keys(unsavedData)?.length === 0 &&
      unsavedState.includes("details")
    ) {
      setUnsavedState(
        unsavedState.filter((x) => {
          return x !== "details"
        })
      )
    }
  }, [pathDetailsValues, JSON.stringify(path)])

  //tags state
  const [tagsContext, setTagsContext] = useState(path?.tags || [])

  //check unsave tags data
  useEffect(() => {
    let initialValues = path?.tags?.map((x) => x.name) || []
    let currentValues = tagsContext?.map((x) => x.name) || []

    let unsavedData = differenceBetween(initialValues, currentValues)

    if (unsavedData?.length > 0 && !unsavedState.includes("tags")) {
      setUnsavedState((oldState) => [...oldState, "tags"])
    } else if (unsavedData?.length === 0 && unsavedState.includes("tags")) {
      setUnsavedState(
        unsavedState.filter((x) => {
          return x !== "tags"
        })
      )
    }
  }, [tagsContext, JSON.stringify(path)])

  //add courses to a learning path state
  const [courseArray, setCourseArray] = useState([])

  //load data in case of the refresth
  useEffect(() => {
    if (path.courses.length > 0) {
      let newArr = []
      path.courses.map((c) => {
        if (c.versions.find((v) => v.published === 1)) {
          const published = c.versions.find((v) => v.published === 1)
          newArr = [...newArr, published]
        } else {
          const latestVersion = c.versions[c.versions.length - 1]
          newArr = [...newArr, latestVersion]
        }
      })
      setCourseArray(newArr)
    }
  }, [path])
  //check un save in adding courses
  useEffect(() => {
    const initialValues = path?.courses?.map((c) => Number(c.container_id))
    const currentValues = courseArray.map((c) => Number(c.container_id))

    const changes = differenceBetween(initialValues, currentValues)

    if (changes.length > 0 && !unsavedState.includes("courses")) {
      setUnsavedState((oldState) => [...oldState, "courses"])
    } else if (changes.length === 0 && unsavedState.includes("courses")) {
      setUnsavedState(
        unsavedState.filter((x) => {
          return x !== "courses"
        })
      )
    }
  }, [courseArray, JSON.stringify(path)])

  const updatePathMutation = useMutation((data) => updatePath(data, path.id))

  const addCoursesMutation = useMutation((data) => addCourses(data, path.id))

  const createTagsMutation = useMutation((data) => createTags(data, path.id))

  const publishPathMutation = useMutation(() => publishLearningPath(path?.id))

  const unpublishPathMutation = useMutation(() =>
    unpublishLearningPath(path?.id)
  )

  const archivePathMutation = useMutation(() => archiveLearningPath(path?.id))

  const unarchivePathMutation = useMutation(() =>
    unarchiveLearningPath(path?.id)
  )

  const analyticsMutation = useMutation(() => pathAnalytics(path?.id))

  //if stament to know what we need to complete to publish
  const disabledSave =
    debouncedDetails.name === "" ||
    debouncedDetails?.name?.length > 50 ||
    debouncedDetails.description === "" ||
    debouncedDetails?.description?.length > 2000 ||
    // debouncedDetails.headline === "" ||
    // debouncedDetails?.headline?.length > 100 ||
    tagsContext?.length === 0 ||
    Object.keys(path?.thumbnail)?.length === 0
  // Object.keys(path?.badge)?.length === 0  ....placeholder

  //disabled the input depends the length when save
  let correctInputsLength =
    debouncedDetails?.name?.length <= 50 &&
    // debouncedDetails?.headline?.length <= 100 &&
    debouncedDetails?.description?.length <= 2000

  //close menu
  const handleClose = () => {
    setAnchorEl(null)
  }
  //update tags promise
  const addTags = () => {
    return new Promise((resolve, reject) => {
      if (unsavedState.includes("tags")) {
        createTagsMutation.mutate(tagsContext, {
          onSuccess: (res) => {
            serialize("path", res.data).then((serializedData) => {
              return queryClient.setQueryData("paths", (oldState) => {
                let newArray = []
                newArray = oldState.map((x) => {
                  if (x.id !== serializedData.id) {
                    return x
                  } else {
                    return serializedData
                  }
                })
                return [...newArray]
              })
            })

            resolve()
          },
          onError: (err) => {
            openSnackBar({
              message: "Error Updating Learning Path",
            })
            setSaveLoading(false)
            reject()
          },
        })
      } else {
        resolve()
      }
    })
  }

  //update learning path data promise
  const updateData = () => {
    return new Promise((resolve, reject) => {
      if (unsavedState.includes("details")) {
        let data = debouncedDetails
        updatePathMutation.mutate(data, {
          onSuccess: (res) => {
            serialize("path", res.data).then((serializedData) => {
              return queryClient.setQueryData("paths", (oldData) => {
                let newArray = []
                newArray = oldData.map((x) => {
                  if (x.id !== serializedData.id) {
                    return x
                  } else {
                    return serializedData
                  }
                })
                return [...newArray]
              })
            })

            resolve()
          },
          onError: (err) => {
            openSnackBar({
              message: "Error Updating Learning Path",
            })
            setSaveLoading(false)
            reject()
          },
        })
      } else {
        resolve()
      }
    })
  }

  // add courses to learning path
  const addPathCourses = () => {
    return new Promise((resolve, reject) => {
      if (unsavedState.includes("courses")) {
        const containerIdArr = courseArray.map((c) => c.container_id)
        const data = containerIdArr.map((id) => {
          return { course_container_id: id }
        })
        addCoursesMutation.mutate(data, {
          onSuccess: (res) => {
            serialize("path", res.data).then((serializedData) => {
              return queryClient.setQueryData("paths", (oldData) => {
                let newArray = []
                newArray = oldData.map((x) => {
                  if (x.id !== serializedData.id) {
                    return x
                  } else {
                    return serializedData
                  }
                })
                return [...newArray]
              })
            })
            resolve()
          },
          onError: (res) => {
            openSnackBar({
              message: "Error Updating Learning Path",
            })
            setSaveLoading(false)
            reject()
          },
        })
      } else {
        resolve()
      }
    })
  }

  //update learning path
  const handleUpdate = (where) => {
    if (path.published === 0) {
      //hide errors needed if the user click publish before and have errors to publish
      setTryPublish(false)
      if (!correctInputsLength) {
        setLengthError(true)
      } else {
        setSaveLoading(true)
        return addTags()
          .then(addPathCourses)
          .then(updateData)
          .then(() => {
            openSnackBar({
              message: "Learning Path Updated",
            })
            setSaveLoading(false)
            handleClose()
          })
          .catch((err) => {
            setSaveLoading(false)
            handleClose()
          })
      }
    } else {
      if (disabledSave) {
        //if learning path is published dont let save if some data is ont completed
        setTryPublish(true)
        handleClose()
        //if learning path is published dont let save if there is not courses attached
      } else if (courseArray?.length === 0) {
        openSnackBar({
          message: "You must attach a course to save a published learning path",
        })
      } else if (
        //if learning path is published dont let save if there is not courses published attached
        courseArray.length > 0 &&
        courseArray.filter((x) => x.published).length !== courseArray.length &&
        where !== "fromUnPublish"
      ) {
        setPublishLoading(false)
        openSnackBar({
          message:
            "You must attach only published courses to save this published learning path",
        })
      } else {
        setSaveLoading(true)
        return addTags()
          .then(addPathCourses)
          .then(updateData)
          .then(() => {
            openSnackBar({
              message: "Learning Path Updated",
            })
            setSaveLoading(false)
            handleClose()
          })
          .catch((err) => {
            console.log(err)
            setSaveLoading(false)
            handleClose()
          })
      }
    }
  }
  //publish learning path
  const publishPathFunc = () => {
    //open errors if the users didnt complete all that is require in disabledSave
    if (disabledSave) {
      setTryPublish(true)
      handleClose()
    } else if (courseArray.length === 0) {
      // dont let save if there is not courses attached
      setPublishLoading(false)
      openSnackBar({
        message: "You must attach a course to publish a learning path",
      })
    } else if (
      // dont let save if there is not courses published attached
      courseArray.length > 0 &&
      courseArray.filter((x) => x.published).length !== courseArray.length
    ) {
      setPublishLoading(false)
      openSnackBar({
        message:
          "You must attach only published courses to publish a learning path",
      })
    } else {
      //open confirm dialog
      let data = {
        title: "Publish Confirmation",
        subtitle: "",
        type: "publish",
        confirmWord: "ship it",
      }
      handleClose()
      openDialog("confirmationTypingDialog", data).then(() => {
        handleUpdate().then(() => {
          setPublishLoading(true)
          publishPathMutation.mutate(
            {},
            {
              onSuccess: (res) => {
                serialize("path", res.data).then((serializedData) => {
                  return queryClient.setQueryData("paths", (oldData) => {
                    let newArray = []
                    newArray = oldData.map((x) => {
                      if (x.id !== serializedData.id) {
                        return x
                      } else {
                        return serializedData
                      }
                    })
                    return [...newArray]
                  })
                })
                openSnackBar({
                  message: "This learning path was published",
                })
                setPublishLoading(false)
                handleClose()
              },
              onError: (err) => {
                openSnackBar({
                  message: "Error Publishing Learning Path",
                })
                setPublishLoading(false)
                handleClose()
              },
            }
          )
        })
      })
    }
  }

  //unpublish learning path
  const unpublishPathFunc = () => {
    //check if the inputs have the corrent length
    if (!correctInputsLength) {
      setLengthError(true)
      handleClose()
    } else {
      //open confirm dialog
      let data = {
        title: "Unpublish Confirmation",
        subtitle: "",
        type: "unpublish",
        confirmWord: "unpublish",
      }
      handleClose()
      openDialog("confirmationTypingDialog", data).then(() => {
        setUnpublishLoading(true)
        handleUpdate("fromUnPublish").then(() => {
          unpublishPathMutation.mutate(
            {},
            {
              onSuccess: (res) => {
                serialize("path", res.data).then((serializedData) => {
                  return queryClient.setQueryData("paths", (oldData) => {
                    let newArray = []
                    newArray = oldData.map((x) => {
                      if (x.id !== serializedData.id) {
                        return x
                      } else {
                        return serializedData
                      }
                    })
                    return [...newArray]
                  })
                })
                openSnackBar({
                  message: "This learning path was unpublished",
                })
                setUnpublishLoading(false)
                handleClose()
              },
              onError: (err) => {
                openSnackBar({
                  message: "Error unpublishing Learning Path",
                })
                setUnpublishLoading(false)
                handleClose()
              },
            }
          )
        })
      })
    }
  }

  //archive learning path
  const archivePathFunc = () => {
    //delete errors if we try to publish before
    setTryPublish(false)
    //check if the inputs have the corrent length
    if (!correctInputsLength) {
      setLengthError(true)
      handleClose()
    } else {
      //open confirm dialog
      let data = {
        title: "Archive Confirmation",
        subtitle: "",
        type: "archive",
        confirmWord: "archive",
      }
      handleClose()
      openDialog("confirmationTypingDialog", data).then(() => {
        setArchiveLoading(true)
        handleUpdate().then(() => {
          archivePathMutation.mutate(
            {},
            {
              onSuccess: (res) => {
                serialize("path", res.data).then((serializedData) => {
                  return queryClient.setQueryData("paths", (oldData) => {
                    let newArray = []
                    newArray = oldData.map((x) => {
                      if (x.id !== serializedData.id) {
                        return x
                      } else {
                        return serializedData
                      }
                    })
                    return [...newArray]
                  })
                })
                openSnackBar({
                  message: "This learning path was archived",
                })
                setArchiveLoading(false)
                handleClose()
              },
              onError: (err) => {
                openSnackBar({
                  message: "Error archiving Learning Path",
                })
                setArchiveLoading(false)
                handleClose()
              },
            }
          )
        })
      })
    }
  }

  //unarchive course
  const unarchivePathFunc = () => {
    //check if the inputs have the corrent length
    if (!correctInputsLength) {
      setLengthError(true)
      handleClose()
    } else {
      //open confirm dialog
      let data = {
        title: "Un-archive Confirmation",
        subtitle: "",
        type: "unarchive",
        confirmWord: "unarchive",
      }
      openDialog("confirmationTypingDialog", data).then(() => {
        setUnarchiveLoading(true)
        handleUpdate().then(() => {
          unarchivePathMutation.mutate(
            {},
            {
              onSuccess: (res) => {
                serialize("path", res.data).then((serializedData) => {
                  return queryClient.setQueryData("paths", (oldData) => {
                    let newArray = []
                    newArray = oldData.map((x) => {
                      if (x.id !== serializedData.id) {
                        return x
                      } else {
                        return serializedData
                      }
                    })
                    return [...newArray]
                  })
                })
                openSnackBar({
                  message: "This learning path was un-archived",
                })
                setUnarchiveLoading(false)
                handleClose()
              },
              onError: (err) => {
                openSnackBar({
                  message: "Error Un-archiving Learning Path",
                })
                setUnarchiveLoading(false)
                handleClose()
              },
            }
          )
        })
      })
    }
  }

  //discard changes
  const discardChanges = () => {
    if (unsavedState.includes("details")) {
      setPathDetailsValues(initialDetailsContext)
      setDebouncedDetails(initialDetailsContext)
    } else if (unsavedState.includes("tags")) {
      setTagsContext(path?.tags)
    } else if (unsavedState.includes("courses")) {
      setCourseArray(path?.courses)
    }
  }

  return (
    <PathContext.Provider
      value={{
        debouncedDetails,
        setDebouncedDetails,
        path,
        pathDetailsValues,
        setPathDetailsValues,
        initialDetailsContext,
        setInitialDetailsContext,
        tryPublish,
        setTryPublish,
        unsavedState,
        setUnsavedState,
        saveLoading,
        publishLoading,
        unpublishLoading,
        archiveLoading,
        unarchiveLoading,
        anchorEl,
        setAnchorEl,
        lengthError,
        tagsContext,
        setTagsContext,
        handleUpdate,
        courseArray,
        setCourseArray,
        discardChanges,
        updatePathMutation,
        publishPathFunc,
        unpublishPathFunc,
        archivePathFunc,
        unarchivePathFunc,
        disabledSave,
        analyticsMutation,
      }}
    >
      {children}
    </PathContext.Provider>
  )
}

PathContainer.propTypes = {
  children: PropTypes.node,
  path: PropTypes.object,
}
