import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  tableContainer: {
    padding: "50px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
}))

export default styles
