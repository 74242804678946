import { listLessons } from "actions/lessons"
import { createLesson } from "actions/lessons"
import PropTypes from "prop-types"
import { createContext } from "react"
import { useMutation, useQuery } from "react-query"

const LessonsContext = createContext()
export default LessonsContext

export const LessonsContainer = ({ children }) => {
  const createLessonMutation = useMutation(createLesson)

  const { data: lessons, isLoading: lessonsLoading } = useQuery(
    "lessons",
    listLessons
  )

  return (
    <LessonsContext.Provider
      value={{ createLessonMutation, lessons, lessonsLoading }}
    >
      {children}
    </LessonsContext.Provider>
  )
}

LessonsContainer.propTypes = {
  children: PropTypes.node,
}
