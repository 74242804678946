import Route from "components/Route"
import NotFound from "pages/NotFound"
import PropTypes from 'prop-types'
import { Redirect,Switch } from "react-router-dom"

const PageComponents = {}
const componentsReq = require.context("./pages", true, /^(.*\.(jsx$))[^.]*$/im)
componentsReq.keys().filter(x => x.indexOf("index.jsx") > -1).forEach(x => {
  // https://regexr.com/54uqs - selects ./ and /pages/ from file locations
  // https://regexr.com/54uqv - selects index.jsx files
  const componentName = x.replace(/\/(\w|:|-)+\.jsx$/g, "").replace(/(\/pages\/|\.\/)/g, "")
  PageComponents[componentName] = componentsReq(x).default
})

export const routeConfig = [
  {
    "path": "/",
    "exact": true,
    "component": "Login"
  },
  {
    "path": "/login",
    "component": "Login",
    "protected": false
  },
  {
    "path": "/certifications",
    "component": "Certifications",
    "protected": true,
    "routes": [
      {
        "path": "/certifications",
        "component": "CertificationsList",
        "protected": true,
        "exact": true
      },
      {
        "path": "/certifications/:certification_id",
        "component": "CertificationsCertification",
        "protected": true,
        "routes": [
          {
            "path": "/certifications/:certification_id/content",
            "component": "CertificationsCertificationContent",
            "protected": true,
            "exact": true
          },
          {
            "path": "/certifications/:certification_id/analytics",
            "component": "CertificationsCertificationAnalytics",
            "protected": true
          }
        ]
      }
    ]
  },
  {
    "path": "/learning-paths",
    "component": "Paths",
    "protected": true,
    "routes": [
      {
        "path": "/learning-paths",
        "component": "PathsList",
        "protected": true,
        "exact": true
      },
      {
        "path": "/learning-paths/:learningPath_id",
        "component": "PathsPath",
        "protected": true,
        "routes": [
          {
            "path": "/learning-paths/:learningPath_id/content",
            "component": "PathsPathContent",
            "protected": true,
            "exact": true
          },
          {
            "path": "/learning-paths/:learningPath_id/analytics",
            "component": "PathsPathAnalytics",
            "protected": true
          }
        ]
      }
    ]
  },
  
  {
    "path": "/courses",
    "component": "Courses",
    "protected": true,
    "routes": [
      {
        "path": "/courses",
        "component": "CoursesList",
        "protected": true,
        "exact": true
      },
      {
        "path": "/courses/:course_id",
        "component": "CoursesCourse",
        "protected": true,
        "routes": [
          {
            "path": "/courses/:course_id",
            "component": "CoursesCourseContent",
            "protected": true,
            "exact": true
          },
          {
            "path": "/courses/:course_id/analytics",
            "component": "CoursesCourseAnalytics",
            "protected": true
          },
          {
            "path": "/courses/:course_id/resources",
            "component": "CoursesCourseResources",
            "protected": true
          }
        ]
      }
    ]
  },
  {
    "path": "/lessons",
    "component": "Lessons",
    "protected": true,
    "routes": [
      {
        "path": "/lessons",
        "component": "LessonsList",
        "protected": true,
        "exact": true
      },
      {
        "path": "/lessons/:lesson_id",
        "component": "LessonsLesson",
        "protected": true,
        "routes": [
          {
            "path": "/lessons/:lesson_id",
            "component": "LessonsLessonContent",
            "protected": true,
            "exact": true
          },
          {
            "path": "/lessons/:lesson_id/cues",
            "component": "LessonsLessonCues",
            "protected": true
          },
          {
            "path": "/lessons/:lesson_id/resources",
            "component": "LessonsLessonResources",
            "protected": true
          },
          {
            "path": "/lessons/:lesson_id/analytics",
            "component": "LessonsLessonAnalytics",
            "protected": true
          }
        ]
      }
    ]
  },
  {
    "path": "/labs",
    "component": "Labs",
    "protected": true,
    "routes": [
      {
        "path": "/labs",
        "component": "LabsList",
        "protected": true,
        "exact": true
      },
      {
        "path": "/labs/:lab_id",
        "component": "LabsLab",
        "protected": true
      }
    ]
  },
  {
    "path": "/users",
    "component": "Users",
    "protected": true,
    "routes": [
      {
        "path": "/users/user-list",
        "component": "UsersUser",
        "protected": true
      },
      {
        "path": "/users/admin-list",
        "component": "UsersAdmin",
        "protected": true
      },
      {
        "path": "/users/waitlist",
        "component": "UsersWaitlist",
        "protected": true
      }
    ]
  },
  {
    "path": "/clients",
    "component": "Clients",
    "protected": true
  },
  {
    "path": "/instructors",
    "component": "Instructors",
    "protected": true
  },
  {
    "path": "/subscriptions",
    "component": "Subscriptions",
    "protected": true
  },
  {
    "path": "/discounts",
    "component": "Discounts",
    "protected": true
  }
]

if (process.env.REACT_APP_ENV === "dev") {
  routeConfig.push({
    "path": "/kitchen-sink",
    "exact": true,
    "component": "KitchenSink"
  })
}

export const Router = ({ routes, match }) => {
  let path = ""
  if (match) {
    path = match.path
  }
  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={window.location.pathname.slice(0, -1)} />
      {routes.map(({ component, ...rest }, i) => (
        <Route key={i} {...rest} component={PageComponents[component]} />
      ))}
      <Route path={`${path}/*`} component={NotFound} />
    </Switch>
  )
}

Router.propTypes = {
  "routes": PropTypes.array,
  "match": PropTypes.object
}

export default Router
