import {
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core"
import { AddCircle } from "@material-ui/icons"
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle"
import DraftPublishedBox from "components/Common/DraftPublishedBox"
import HoverText from "components/Common/HoverText"
import InstructorGroup from "components/Common/InstructorGroup"
import PaperListRow from "components/Common/PaperListRow"
import InstructorsContext from "contexts/Instructors"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useLayoutEffect, useState } from "react"
import serialize from "store/serialize"

import useStyles from "./styles"
const AddLessonCard = ({
  lesson,
  lessonsComponentArray,
  setLessonsComponentArray,
}) => {
  const classes = useStyles()
  // const [addedLesson, setAddedLesson] = useState(false)
  const [selectedVersion, setSelectedVersion] = useState(-1)
  const currentVersion = lesson?.versions?.[selectedVersion] || lesson
  const lessonVersionIds = lesson?.versions?.map?.((v) => v.id)
  const addedLesson = lessonsComponentArray.find((l) =>
    lessonVersionIds.includes(l.lesson_id)
  )

  useLayoutEffect(() => {
    if (addedLesson) {
      setSelectedVersion(
        lesson?.versions?.findIndex?.(
          (v) => v.version === addedLesson.data.version
        )
      )
    } else {
      setSelectedVersion(lesson?.versions?.length - 1 || 0)
    }
  }, [lesson, addedLesson])

  const handleChange = (event) => {
    setSelectedVersion(event.target.value)
  }

  const addLesson = () => {
    setLessonsComponentArray((currentArray) => {
      return [
        ...currentArray,
        {
          lesson_id: currentVersion.id,
          data: currentVersion,
        },
      ]
    })
  }

  const removeLesson = () => {
    if (addedLesson) {
      setLessonsComponentArray((currentArray) =>
        currentArray.filter((lesson) => lesson.lesson_id !== currentVersion.id)
      )
    }
  }

  return (
    <Box className={classes.wrapper} marginBottom="16px">
      <PaperListRow color={addedLesson ? "#D0D5DD" : "#fff"}>
        <Box width="15%" className={classes.statusRow}>
          <DraftPublishedBox
            published={currentVersion.published}
            has_been_published={currentVersion.has_been_published}
            archived={currentVersion.archived}
            size={"90px"}
          />
        </Box>
        <Box width="30%" className={classes.nameBox}>
          <HoverText
            data={currentVersion?.name}
            size={30}
            variant="h3"
            className={classes.nameText}
          />
        </Box>
        <Box className={classes.versionBox}>
          <FormControl className={classes.formControl} variant="outlined">
            <Select
              SelectDisplayProps={{
                style: { paddingTop: 8, paddingBottom: 8, width: "100%" },
              }}
              value={selectedVersion}
              onChange={handleChange}
              className={classes.selectBorder}
              classes={{ icon: classes.icon }}
              disabled={Boolean(addedLesson)}
            >
              {lesson.versions?.map((x, i) => {
                return (
                  <MenuItem key={i} value={i}>
                    {x.version}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Box>
        <Box width="30%" className={classes.instructorsBox}>
          <InstructorGroup instructorsArray={currentVersion.instructors} />
        </Box>
        <Box width="15%" className={classes.buttonBox}>
          {addedLesson ? (
            <Box className={classes.addedBox}>
              <Typography>Added</Typography>
              <IconButton
                className={classes.removeButton}
                onClick={removeLesson}
              >
                <RemoveCircleIcon color="primary" />
              </IconButton>
            </Box>
          ) : (
            <Button
              className={classes.addButton}
              classes={{ label: classes.button }}
              variant="contained"
              disableElevation
              startIcon={<AddCircle color="primary" />}
              onClick={addLesson}
            >
              <Typography color="primary" className={classes.buttonText}>
                ADD
              </Typography>
            </Button>
          )}
        </Box>
      </PaperListRow>
    </Box>
  )
}

AddLessonCard.propTypes = {
  lesson: PropTypes.object,
  setLessonsComponentArray: PropTypes.func,
  lessonsComponentArray: PropTypes.array,
  lessons: PropTypes.array,
}

export default AddLessonCard
