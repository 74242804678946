import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  pageWrapper: {
    height: "100%",
    display: "flex",
    overflow: "hidden",
  },
  sideBar: {
    width: 60,
    flexShrink: 0,
    display: "flex",
    position: "fixed",
    left: 0,
    top: 0,
    bottom: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "60px",
    },
  },
  loginPage: {
    width: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    paddingLeft: "0px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
      paddingTop: "0px",
    },
  },
  mainContent: {
    width: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    paddingLeft: "60px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
      paddingTop: "70px",
    },
  },
  appBar: {
    height: 60,
    flexShrink: 0,
    display: "flex",
  },
  pageContent: {
    flex: 1,
    height: "calc(100% - 60px)",
  },
}))

export default styles
