import { Box, Button, Paper, Typography } from "@material-ui/core"
import PropTypes from "prop-types"

import useStyles from "./styles"

const DiscountCardMobile = ({
  name,
  active,
  code,
  fixed,
  fixedOff,
  formattedPrice,
  percentOff,
  start,
  end,
  formattedNumber,
  used,
  handleClick,
}) => {
  const classes = useStyles()
  return (
    <Paper className={classes.paper} elevation={0}>
      <Box display="flex" justifyContent="space-between">
        <Typography
          variant="h4"
          color="primary"
          style={{ fontWeight: 600, marginTop: "8px" }}
        >
          {name}
        </Typography>
        <Typography
          style={{ color: active ? "#29B117" : "#FF5E69", marginTop: "8px" }}
          variant="subtitle1"
        >
          {active ? "Active" : "Inactive"}
        </Typography>
      </Box>
      <Box display="flex" marginTop={2}>
        <Box width="50%" display="flex" flexDirection="column">
          <Box display="flex">
            <Typography
              variant="subtitle1"
              color="primary"
              style={{ marginRight: "4px", fontWeight: "bold" }}
            >
              Code:
            </Typography>
            <Typography variant="subtitle1">{code}</Typography>
          </Box>
          <Box display="flex">
            <Typography
              variant="subtitle1"
              color="primary"
              style={{ marginRight: "4px", fontWeight: "bold" }}
            >
              Type:
            </Typography>
            <Typography variant="subtitle1">
              {fixed ? "Fixed" : "Percent"}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography
              variant="subtitle1"
              color="primary"
              style={{ marginRight: "4px", fontWeight: "bold" }}
            >
              Amount:
            </Typography>
            <Typography variant="subtitle1">
              {fixedOff ? `$${formattedPrice}` : `${percentOff}%`}
            </Typography>
          </Box>
        </Box>
        <Box width="50%" display="flex" flexDirection="column">
          <Box display="flex">
            <Typography
              variant="subtitle1"
              color="primary"
              style={{ marginRight: "4px", fontWeight: "bold" }}
            >
              Start:
            </Typography>
            <Typography variant="subtitle1">{start}</Typography>
          </Box>
          <Box display="flex">
            <Typography
              variant="subtitle1"
              color="primary"
              style={{ marginRight: "4px", fontWeight: "bold" }}
            >
              End:
            </Typography>
            <Typography variant="subtitle1">{end}</Typography>
          </Box>
          <Box display="flex">
            <Typography
              variant="subtitle1"
              color="primary"
              style={{ marginRight: "4px", fontWeight: "bold" }}
            >
              Available:
            </Typography>
            <Typography variant="subtitle1">{formattedNumber}</Typography>
          </Box>
          <Box display="flex">
            <Typography
              variant="subtitle1"
              color="primary"
              style={{ marginRight: "4px", fontWeight: "bold" }}
            >
              Used:
            </Typography>
            <Typography variant="subtitle1">{used}</Typography>
          </Box>
        </Box>
      </Box>
      <Button color="secondary" onClick={handleClick} size="small">
        EDIT
      </Button>
    </Paper>
  )
}

DiscountCardMobile.propTypes = {
  name: PropTypes.string,
  active: PropTypes.number,
  code: PropTypes.string,
  fixed: PropTypes.number,
  fixedOff: PropTypes.number,
  formattedPrice: PropTypes.string,
  percentOff: PropTypes.number,
  start: PropTypes.string,
  end: PropTypes.string,
  formattedNumber: PropTypes.string,
  used: PropTypes.number,
  handleClick: PropTypes.func,
}

export default DiscountCardMobile
