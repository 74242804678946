import { Box } from "@material-ui/core"
import PropTypes from "prop-types"
import React from "react"

import useStyles from "./styles"

const CourseLessonContentContainer = ({ leftComponent, rightComponent }) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Box className={classes.leftContainer}>{leftComponent}</Box>
      <Box className={classes.rightContainer}>{rightComponent}</Box>
    </Box>
  )
}

CourseLessonContentContainer.propTypes = {
  leftComponent: PropTypes.node,
  rightComponent: PropTypes.node,
}

export default CourseLessonContentContainer
