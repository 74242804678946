import LoaderButton from "@bit/c_t.components.loader-button"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core"
import AppContext from "contexts/App"
import { orderBy } from "lodash"
import PropTypes from "prop-types"
import React from "react"
import { useContext, useState } from "react"
import { useQueryClient } from "react-query"

import useStyles from "./styles"

const ConfirmationClickDialog = () => {
  const queryClient = useQueryClient()
  //reused dialog for create course, create lesson, create cliente and create instructor
  const classes = useStyles()
  const { dialogs, closeDialog, openSnackBar } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const thisDialog = dialogs?.["confirmationClickDialog"] || {}
  const { open = false, data } = thisDialog
  let title = thisDialog?.data?.title
  let type = thisDialog?.data?.type
  let id = thisDialog?.data?.id

  //setFn is actionused to set lesson list
  let setFn = thisDialog?.data?.setFn

  let lessonId = data?.data?.[0]?.lesson_id
  let courseId = data?.data?.[0]?.course_id
  let dataArray = data?.data

  const handleClose = () => {
    closeDialog("confirmationClickDialog", false)
  }

  const removeResource = (values) => {
    setLoading(true)
    let newData = dataArray?.filter((x) => x.id !== id)
    setFn.mutate(
      // resets order prop based on index
      newData.map((x, i) => ({
        ...x,
        order: i,
      })),
      {
        onSuccess: (res) => {
          //differentiate on which data to update by existence of lessonId or courseId
          if (lessonId) {
            let orderedRes = orderBy(res.resources, [(x) => x.order])
            queryClient.setQueryData(["lesson", lessonId], (oldData) => {
              return { ...oldData, resources: orderedRes }
            })
          } else {
            let orderedRes = orderBy(res.resources, [(x) => x.order])
            queryClient.setQueryData(["course", courseId], (oldData) => {
              return { ...oldData, resources: orderedRes }
            })
          }
          openSnackBar({
            message: "Deletion Successful",
          })
          closeDialog("confirmationClickDialog", true)
        },
        onError: (err) => {
          console.log(err)
          openSnackBar({
            message: "Error deleting",
          })
        },
        onSettled: () => {
          setLoading(false)
        },
      }
    )
  }

  return (
    <Dialog classes={{ paperWidthSm: classes.dialogMinHeight }} open={open}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Typography>
          {`Are you sure that you want to delete this ${type}?`}
        </Typography>
      </DialogContent>
      <DialogActions
        style={{
          position: "absolute",
          bottom: 4,
          right: 16,
        }}
      >
        <Button onClick={handleClose} style={{ height: "40px" }}>
          Cancel
        </Button>
        <LoaderButton
          onClick={() => removeResource()}
          classoverrides={{
            wrapper: classes.button,
            button: {
              root: classes.buttonRoot,
            },
          }}
          variant="contained"
          color="secondary"
          size="small"
          working={loading}
        >
          Delete
        </LoaderButton>
      </DialogActions>
    </Dialog>
  )
}

ConfirmationClickDialog.propTypes = {
  title: PropTypes.string,
}

export default ConfirmationClickDialog
