import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import CounterHeader from "components/Common/CounterHeader"
import DateTime from "components/Common/DateTime"
import NumberFormatCustom from "components/Common/NumberFormat"
import Tag from "components/Common/Tag"
import ThumbnailUpload from "components/Common/ThumbnailUpload"
import DiscoverPage from "components/DiscoverPage"
import AppContext from "contexts/App"
import Course from "contexts/Course"
import CoursesContext from "contexts/Courses"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { useQueryClient } from "react-query"
import serialize from "store/serialize"
import { useDebounce } from "use-lodash-debounce"
import characterCounter from "utils/characterCounter"

import useStyles from "./styles"

const CourseDetails = ({ tags, categories }) => {
  const classes = useStyles()
  const queryClient = useQueryClient()

  const {
    setDebouncedDetails,
    debouncedDetails,
    course,
    setTagsContext,
    tagsContext,
    setPricingContext,
    pricingContext,
    setStripePrice,
    stripePrice,
    updateCourseMutation,
    lengthError,
    tryPublish,
    unsavedState,
    courseDetailsValues,
    setCourseDetailsValues,
    setInitialDetailsContext,
    categoriesContext,
    setCategoriesContext,
    levelValue,
    setLevelValue,
    disabledSave,
    dateValue,
    setDateValue,
  } = useContext(Course)
  const { openSnackBar, setPageSubTitle, authUser } = useContext(AppContext)
  const debouncedLocalValues = useDebounce(courseDetailsValues, 200)
  const [tagAutocompleteValue, setTagAutocompleteValue] = useState("")
  const [tagTextFieldValue, setTagTextFieldValue] = useState("")
  const [
    categoriesAutocompleteValue,
    setCategoriesAutocompleteValue,
  ] = useState("")

  const [categoriesSelectVal, setCategoriesSelectVal] = useState("")
  //level state

  const [levelState, setLevelState] = React.useState({
    fundamental: false,
    operational: false,
    technical: false,
    cloudPerformance: false,
    orchestration: false,
  })

  const {
    fundamental,
    operational,
    technical,
    cloudPerformance,
    orchestration,
  } = levelState

  //show errors if inputs are empty or more than allow characters
  const descriptionError =
    ((debouncedDetails?.description === "" ||
      debouncedDetails?.description?.length > 2000) &&
      tryPublish) ||
    (lengthError && debouncedDetails?.description?.length > 2000)

  const nameError =
    ((debouncedDetails?.name === "" || debouncedDetails?.name?.length > 50) &&
      tryPublish) ||
    (lengthError && debouncedDetails?.name?.length > 50)

  const headlineError =
    ((debouncedDetails?.headline === "" ||
      debouncedDetails?.headline?.length > 100) &&
      tryPublish) ||
    (lengthError && debouncedDetails?.headline?.length > 100)

  const mailgunSubjectError =
    (debouncedDetails?.mailgun_subject?.length > 256 && tryPublish) ||
    (lengthError && debouncedDetails?.mailgun_subject?.length > 256)

  // disable if: published, unpublished, or archived - only available to draft
  const disablePicker =
    course?.published === 1 ||
    (course?.published === 0 &&
      course?.has_been_published === 1 &&
      course?.archived === 0) ||
    (course?.archived === 1 && course?.published === 0)

  const setThumbnail = (uploadRes, loadingAction, type) => {
    const image = JSON.stringify({
      url: uploadRes.data.url,
      secure_url: uploadRes.data.secure_url,
      originial_filename: uploadRes.data.original_filename,
      version: uploadRes.data.version,
      format: uploadRes.data.format,
      public_id: uploadRes.data.public_id,
    })
    const data = {
      [type]: image,
    }
    updateCourseMutation.mutate(data, {
      onSuccess: (res) => {
        serialize("course", res.data).then((serializedData) => {
          queryClient.setQueryData(["course", res.data.id], (oldData) => {
            oldData[type] = serializedData[type]
            return oldData
          })
        })
        setTimeout(() => {
          loadingAction(false)
        }, 400)
        serialize("course", res.data).then((serializedData) => {
          return queryClient.setQueryData("courses", (oldData) => {
            let newArray = []
            newArray = oldData.map((x) => {
              if (x.id !== serializedData.id) {
                return x
              } else {
                return serializedData
              }
            })
            return [...newArray]
          })
        })
      },
      onError: (err) => {
        loadingAction(false)
        openSnackBar({
          message: "Error Updating Hero Image",
        })
      },
    })
  }

  //set course subtitle to empty string
  useEffect(() => {
    setPageSubTitle("")
  }, [])

  //delete tag from the tags array in course context
  const deleteTag = (name) => {
    setTagsContext(tagsContext?.filter((x) => x.name !== name))
  }

  //delete category from the categories array in course context
  const deleteCategory = (name) => {
    setCategoriesContext(categoriesContext?.filter((x) => x.name !== name))
  }

  //change level option
  const handleChangeLevel = (event) => {
    setLevelState({
      fundamental: event.target.name === "fundamental" ? true : false,
      operational: event.target.name === "operational" ? true : false,
      technical: event.target.name === "technical" ? true : false,
      cloudPerformance:
        event.target.name === "cloud-performance" ? true : false,
      orchestration: event.target.name === "orchestration" ? true : false,
    })
    setLevelValue(event.target.name)
  }

  // sets pricing to specific radio button / if paid, returns stripePrice in dollars
  useEffect(() => {
    if (course?.pricing !== "free" && course?.pricing !== "membership") {
      setPricingContext("paid")
      const priceFormatted = (course?.pricing / 100).toFixed(2)
      setStripePrice(priceFormatted)
    } else {
      setPricingContext(course?.pricing)
      setStripePrice("")
    }
  }, [])

  // set course details to compoment state
  useEffect(() => {
    if (course?.id) {
      setCourseDetailsValues({
        name: course.name,
        description: course.description || "",
        headline: course.headline || "",
        is_public: course.is_public,
        mailgun_template_id: course.mailgun_template_id || "",
        mailgun_subject: course.mailgun_subject || "",
      })
      setInitialDetailsContext({
        name: course.name,
        description: course.description || "",
        headline: course.headline || "",
        is_public: course.is_public,
        mailgun_template_id: course.mailgun_template_id || "",
        mailgun_subject: course.mailgun_subject || "",
      })
    }
  }, [course])

  //set initial values to level
  useEffect(() => {
    setLevelState({
      fundamental: course?.level === "fundamental" ? true : false,
      operational: course?.level === "operational" ? true : false,
      technical: course?.level === "technical" ? true : false,
      cloudPerformance: course?.level === "cloud-performance" ? true : false,
      orchestration: course?.level === "orchestration" ? true : false,
    })
  }, [course])

  //set details context state from this component state using debounce
  useEffect(() => {
    setDebouncedDetails(courseDetailsValues)
  }, [debouncedLocalValues])

  return (
    <Box className={classes.container}>
      <Typography variant="h3" color="primary" className={classes.title}>
        Course Details
      </Typography>
      <CounterHeader
        title="Course Name"
        value={debouncedDetails?.name}
        max={50}
      />
      <TextField
        value={courseDetailsValues?.name || ""}
        onChange={(e) => {
          setCourseDetailsValues((oldState) => ({
            ...oldState,
            name: e.target.value,
          }))
        }}
        style={{ marginBottom: "10px" }}
        InputProps={{
          classes: {
            root: classes.inputBackgroundColor,
          },
        }}
        variant="filled"
        size="small"
        fullWidth
      />
      {nameError ? (
        <Typography className={classes.requiredText}>
          Name is required and it has to be less than 50 characters
        </Typography>
      ) : null}
      <CounterHeader
        title="Headline"
        value={debouncedDetails?.headline}
        max={100}
      />

      <TextField
        value={courseDetailsValues?.headline || ""}
        onChange={(e) => {
          setCourseDetailsValues((oldState) => ({
            ...oldState,
            headline: e.target.value,
          }))
        }}
        style={{ marginBottom: "10px" }}
        InputProps={{
          classes: {
            root: classes.inputBackgroundColor,
          },
        }}
        variant="filled"
        size="small"
        fullWidth
      />
      {headlineError ? (
        <Typography className={classes.requiredText}>
          Headline is required and it has to be less than 255 characters
        </Typography>
      ) : null}
      <CounterHeader
        title="Course Description"
        value={debouncedDetails?.description}
        max={2000}
      />
      <TextField
        value={courseDetailsValues.description || ""}
        multiline
        rows={10}
        onChange={(e) => {
          setCourseDetailsValues((oldState) => ({
            ...oldState,
            description: e.target.value,
          }))
        }}
        InputProps={{
          disableUnderline: true,
          classes: {
            root: classes.inputDescription,
          },
        }}
        size="small"
        fullWidth
      />
      {descriptionError ? (
        <Typography className={classes.requiredText}>
          Description is required and it has to be less than 2000 characters
        </Typography>
      ) : null}
      <DateTime disablePicker={disablePicker} />

      <Typography color="secondary" className={classes.inputLabel}>
        Search Terms
      </Typography>
      <Autocomplete
        id="tags-autocomplete"
        options={tags || []}
        renderInput={(params) => <TextField {...params} variant="filled" />}
        value={tagAutocompleteValue}
        onChange={(e, val) => setTagAutocompleteValue(val)}
        getOptionLabel={(option) => option.name || option}
        getOptionDisabled={(option) =>
          !!tagsContext.find((y) => y.name === option.name)
        }
        freeSolo
        openOnFocus
        onInputChange={(e, val) => {
          setTagTextFieldValue(val)
        }}
        inputValue={tagTextFieldValue}
        style={{
          width: "100%",
          backgroundColor: "#F4F4F4",
          borderRadius: "4px",
        }}
        onKeyPress={(e) => {
          if (
            e.key === "Enter" &&
            (tagTextFieldValue !== "" || tagAutocompleteValue !== "")
          ) {
            //check if we are using the same work with capital letters or spaces
            if (
              tags.find(
                (y) =>
                  y.name.replace(/\s+/g, "").toLowerCase() ===
                  tagTextFieldValue.replace(/\s+/g, "").toLowerCase()
              ) &&
              tagsContext.find(
                (y) =>
                  y.name.replace(/\s+/g, "").toLowerCase() ===
                  tagTextFieldValue.replace(/\s+/g, "").toLowerCase()
              )
            ) {
              setTagAutocompleteValue("")
              setTagTextFieldValue("")
            } else {
              const found = tags.find(
                (y) =>
                  y.name.replace(/\s+/g, "").toLowerCase() ===
                  tagTextFieldValue.replace(/\s+/g, "").toLowerCase()
              )
              if (found) {
                setTagsContext([...tagsContext, found])
                setTagAutocompleteValue("")
                setTagTextFieldValue("")
              } else {
                setTagAutocompleteValue("")
                setTagTextFieldValue("")
                setTagsContext([
                  ...tagsContext,
                  typeof tagAutocompleteValue === "object"
                    ? tagAutocompleteValue
                    : { name: tagAutocompleteValue },
                ])
              }
              return queryClient.setQueryData("tags", (oldData) => {
                return [
                  ...oldData,
                  typeof tagAutocompleteValue === "object"
                    ? tagAutocompleteValue
                    : { name: tagAutocompleteValue },
                ]
              })
            }
          }
        }}
        size="small"
        classes={{
          inputRoot: classes.autocompleteRoot,
        }}
      />
      {tagsContext?.length === 0 && tryPublish ? (
        <Typography className={classes.requiredText}>
          Search Terms are required
        </Typography>
      ) : null}
      <Box
        display="flex"
        flexWrap="wrap"
        style={{ height: "100%" }}
        marginBottom={2}
      >
        {tagsContext?.map((x, i) => {
          return (
            <Tag
              key={i}
              tagName={x.name || x}
              deleteTag={() => deleteTag(x.name)}
            />
          )
        })}
      </Box>

      <Box>
        <Typography color="secondary" className={classes.inputLabel}>
          Public | Private
        </Typography>
        <DiscoverPage />
      </Box>

      <Typography color="secondary" className={classes.inputLabel}>
        Level
      </Typography>
      <FormGroup style={{ display: "flex", flexDirection: "row" }}>
        <FormControlLabel
          control={
            <Radio
              checked={fundamental}
              onChange={handleChangeLevel}
              name="fundamental"
            />
          }
          label="Fundamental"
        />
        <FormControlLabel
          control={
            <Radio
              checked={operational}
              onChange={handleChangeLevel}
              name="operational"
            />
          }
          label="Operational"
        />
        <FormControlLabel
          control={
            <Radio
              checked={technical}
              onChange={handleChangeLevel}
              name="technical"
            />
          }
          label="Technical"
        />
        <FormControlLabel
          control={
            <Radio
              checked={cloudPerformance}
              onChange={handleChangeLevel}
              name="cloud-performance"
            />
          }
          label="Cloud Performance"
        />
        <FormControlLabel
          control={
            <Radio
              checked={orchestration}
              onChange={handleChangeLevel}
              name="orchestration"
            />
          }
          label="Orchestration"
        />
      </FormGroup>
      {levelValue === null && tryPublish ? (
        <Typography className={classes.requiredText}>
          Level is required
        </Typography>
      ) : null}
      <Typography color="secondary" className={classes.inputLabel}>
        Categories
      </Typography>

      <Autocomplete
        id="categories-autocomplete"
        options={categories || []}
        renderInput={(params) => <TextField {...params} variant="filled" />}
        value={categoriesAutocompleteValue}
        onChange={(e, val) => setCategoriesAutocompleteValue(val)}
        getOptionLabel={(option) => option.name || option}
        getOptionDisabled={(option) =>
          !!categoriesContext.find((y) => y.name === option.name)
        }
        freeSolo
        openOnFocus
        onInputChange={(e, val) => {
          setCategoriesSelectVal(val)
        }}
        inputValue={categoriesSelectVal}
        style={{
          width: "100%",
          backgroundColor: "#F4F4F4",
          borderRadius: "4px",
        }}
        onKeyPress={(e) => {
          if (
            e.key === "Enter" &&
            (categoriesSelectVal !== "" || categoriesAutocompleteValue !== "")
          ) {
            //check if we are using the same work with capital letters or spaces
            if (
              categories.find(
                (y) =>
                  y.name.replace(/\s+/g, "").toLowerCase() ===
                  categoriesSelectVal.replace(/\s+/g, "").toLowerCase()
              ) &&
              categoriesContext.find(
                (y) =>
                  y.name.replace(/\s+/g, "").toLowerCase() ===
                  categoriesSelectVal.replace(/\s+/g, "").toLowerCase()
              )
            ) {
              setCategoriesAutocompleteValue("")
              setCategoriesSelectVal("")
            } else {
              const found = categories.find(
                (y) =>
                  y.name.replace(/\s+/g, "").toLowerCase() ===
                  categoriesSelectVal.replace(/\s+/g, "").toLowerCase()
              )
              if (found) {
                setCategoriesContext([...categoriesContext, found])
                setCategoriesAutocompleteValue("")
                setCategoriesSelectVal("")
              } else {
                setCategoriesAutocompleteValue("")
                setCategoriesSelectVal("")
                setCategoriesContext([
                  ...categoriesContext,
                  typeof categoriesAutocompleteValue === "object"
                    ? categoriesAutocompleteValue
                    : { name: categoriesAutocompleteValue },
                ])
              }
              return queryClient.setQueryData("categories", (oldData) => {
                return [
                  ...oldData,
                  typeof categoriesAutocompleteValue === "object"
                    ? categoriesAutocompleteValue
                    : { name: categoriesAutocompleteValue },
                ]
              })
            }
          }
        }}
        size="small"
        classes={{
          inputRoot: classes.autocompleteRoot,
        }}
      />
      {categoriesContext?.length === 0 && tryPublish ? (
        <Typography className={classes.requiredText}>
          Categories are required
        </Typography>
      ) : null}

      <Box
        display="flex"
        flexWrap="wrap"
        style={{ height: "100%" }}
        marginBottom={2}
      >
        {categoriesContext?.map((category, i) => {
          return (
            <Chip
              key={i}
              label={category.name}
              size="small"
              color="primary"
              onDelete={() => deleteCategory(category.name)}
              style={{
                marginRight: "8px",
                marginTop: "8px",
              }}
            />
          )
        })}
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box width="48%">
          <Typography color="secondary" className={classes.inputLabel}>
            Hero Image
          </Typography>
          <ThumbnailUpload
            title="Drop course hero image here"
            cloudinaryUploadPreset={
              process.env.REACT_APP_CLOUDINARY_CLOUD_THUMBNAIL_UPLOAD
            }
            value={course?.thumbnail}
            onUpload={setThumbnail}
            type="thumbnail"
          />
          {Object.keys(course?.thumbnail)?.length === 0 && tryPublish ? (
            <Typography className={classes.requiredText}>
              Hero Image is required
            </Typography>
          ) : null}
        </Box>
        <Box width="48%">
          <Typography color="secondary" className={classes.inputLabel}>
            Background Image
          </Typography>
          <ThumbnailUpload
            title="Drop course background here"
            cloudinaryUploadPreset={
              process.env.REACT_APP_CLOUDINARY_CLOUD_THUMBNAIL_UPLOAD
            }
            value={course?.background_image}
            type="background_image"
            onUpload={setThumbnail}
          />
          {Object.keys(course?.background_image)?.length === 0 && tryPublish ? (
            <Typography className={classes.requiredText}>
              Background Image is required
            </Typography>
          ) : null}
        </Box>
      </Box>
      {authUser?.super === 1 && (
        <Box marginTop={3}>
          <Typography
            variant="h3"
            color="primary"
            className={classes.inputLabel}
          >
            Pricing
          </Typography>
          <RadioGroup
            value={pricingContext}
            onChange={(e) => setPricingContext(e.target.value)}
          >
            <Box>
              <FormControlLabel label="Free" value="free" control={<Radio />} />
            </Box>
            <Box>
              <FormControlLabel
                label="Included in membership"
                value="membership"
                control={<Radio />}
              />
            </Box>
            <Box display="flex" alignItems="center">
              <FormControlLabel value="paid" label="Paid" control={<Radio />} />
              {pricingContext === "paid" && (
                <TextField
                  InputProps={{
                    classes: {
                      root: classes.inputBackgroundColor,
                    },
                    inputComponent: NumberFormatCustom,
                  }}
                  variant="filled"
                  size="small"
                  placeholder="Add a stripe price"
                  style={{ flexGrow: "1" }}
                  onChange={(e) => setStripePrice(e.target.value)}
                  value={stripePrice}
                />
              )}
            </Box>
            {pricingContext === "paid" && stripePrice === "" && tryPublish ? (
              <Typography className={classes.requiredText}>
                Please enter a valid price
              </Typography>
            ) : null}
          </RadioGroup>
        </Box>
      )}
      <Box marginTop={1}>
        <Typography color="secondary" className={classes.inputLabel}>
          Mailgun Template ID
        </Typography>
        <TextField
          value={courseDetailsValues?.mailgun_template_id || ""}
          onChange={(e) => {
            setCourseDetailsValues((oldState) => ({
              ...oldState,
              mailgun_template_id: e.target.value,
            }))
          }}
          style={{ marginBottom: "10px" }}
          InputProps={{
            classes: {
              root: classes.inputBackgroundColor,
            },
          }}
          variant="filled"
          size="small"
          fullWidth
        />
      </Box>
      <Box marginTop={1}>
        <CounterHeader
          title="Mailgun Subject"
          value={debouncedDetails?.mailgun_subject}
          max={256}
        />
        <TextField
          value={courseDetailsValues?.mailgun_subject || ""}
          onChange={(e) => {
            setCourseDetailsValues((oldState) => ({
              ...oldState,
              mailgun_subject: e.target.value,
            }))
          }}
          style={{ marginBottom: "10px" }}
          InputProps={{
            classes: {
              root: classes.inputBackgroundColor,
            },
          }}
          variant="filled"
          size="small"
          fullWidth
        />
        {mailgunSubjectError ? (
          <Typography className={classes.requiredText}>
            Mailgun Subject must be less than 256 characters
          </Typography>
        ) : null}
      </Box>
    </Box>
  )
}

CourseDetails.propTypes = {
  courseId: PropTypes.string,
  tags: PropTypes.array,
  categories: PropTypes.array,
}

export default CourseDetails
