import PropTypes from "prop-types"
import { createContext } from "react"

const AnalyticsContext = createContext()
export default AnalyticsContext

export const AnalyticsContainer = ({ children }) => {
  return (
    <AnalyticsContext.Provider value={{}}>{children}</AnalyticsContext.Provider>
  )
}

AnalyticsContainer.propTypes = {
  children: PropTypes.node,
}
