import { ReactComponent as CuePointIcon } from "assets/Icons/timeline-cuepoint-icon.svg"
import PropTypes from "prop-types"
import { useMemo } from "react"

import useStyles from "./styles"

const CuePoint = ({ highlighted, offset, cueType, disabled }) => {
  const classes = useStyles()

  const color = useMemo(() => {
    if (highlighted) {
      return "#FFD034"
    } else {
      if (cueType === "knowledge-check") {
        return "rgb(41, 177, 23)"
      } else if (cueType === "ready-check") {
        return "rgb(184, 33, 226)"
      } else if (cueType === "prefilled-notes") {
        return "rgb(0, 120, 246)"
      } else {
        return "#092958"
      }
    }
  }, [highlighted, cueType])

  return (
    <CuePointIcon
      className={classes.cuePointIcon}
      style={{
        left: `${offset}%`,
        pointerEvents: "all",
        fill: color,
        cursor: disabled ? "not-allowed" : "pointer",
      }}
    />
  )
}

CuePoint.propTypes = {
  highlighted: PropTypes.bool,
  offset: PropTypes.number,
  cueType: PropTypes.string,
  disabled: PropTypes.bool,
}

export default CuePoint
