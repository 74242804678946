import ResourceCardList from "components/Common/Resources/ResourceCardList"
import CourseContext from "contexts/Course"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useContext } from "react"
import Router from "router"

import useStyles from "./styles"

const Resources = ({ routes = [], ...rest }) => {
  const classes = useStyles()
  const {
    courseResourceMutation,
    course,
    recoursesCourseArray,
    setResourcesCourseArray,
    discardChanges,
  } = useContext(CourseContext)

  const data = course?.resources

  return (
    <Page>
      <ResourceCardList
        setFn={courseResourceMutation}
        data={data}
        recoursesArray={recoursesCourseArray}
        setRecoursesArray={setResourcesCourseArray}
        discardChanges={discardChanges}
      />
      <Router routes={routes} {...rest} />
    </Page>
  )
}

Resources.propTypes = {
  routes: PropTypes.array,
}

export default Resources
