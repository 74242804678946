import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  leftContainer: {
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      order: 2,
      width: "100%",
      paddingRight: theme.spacing(3),
    },
  },
  rightContainer: {
    minWidth: "18%",
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      order: 1,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginRight: theme.spacing(3),
    },
  },
  saveReorderButton: {
    width: "65%",
  },
  undoButton: {
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "30%",
    },
  },
  label: {
    color: theme.palette.primary.main,
  },
}))

export default styles
