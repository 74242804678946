import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  cueCountText: {
    fontWeight: 700,
    fontSize: "1.6875rem",
  },
  cuesText: {
    fontSize: "1.125rem",
    marginBottom: theme.spacing(2),
  },
  previewTitle: {
    fontSize: "0.875rem",
    fontWeight: 700,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1.5),
  },
  publishedOverlay: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 2,
    backgroundColor: "rgba(255,255,255,0.5)",
  },
  contentContainer: {
    padding: theme.spacing(3.5),
    overflow: "auto",
    // height: "100%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  publishedBanner: {
    position: "relative",
    width: "100%",
    height: 85,
    backgroundColor: "#092958",
    zIndex: 7,
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      padding: theme.spacing(2),
    },
  },
  title: {
    fontWeight: 600,
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  subtitle: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
  srtUploadContainer: {
    width: "300px",
    paddingLeft: theme.spacing(3.5),
    paddingBottom: theme.spacing(3.5),
  },
  inputLabel: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
  },
  srtContainer: {
    borderRadius: "8px",
    backgroundColor: "#D0D5DD",
    height: "150px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    position: "relative",
    "&:hover": {
      "& $editBox": {
        opacity: 1,
      },
    },
  },
  editBox: {
    zIndex: 1,
    transition: theme.transitions.create("opacity", {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeInOut,
    }),
    opacity: 0,
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
}))

export default styles
