import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  container: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  downloadText: {
    fontWeight: "bold",
  },
  dataText: {
    marginRight: theme.spacing(1),
    fontWeight: "bold",
  },
}))

export default styles
