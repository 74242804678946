import LoaderButton from "@bit/c_t.components.loader-button"
import {
  Box,
  Button,
  Divider,
  Menu,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import StarOutlineIcon from "@material-ui/icons/StarOutline"
import copy from "assets/Icons/feather-copy.svg"
import CourseLessonMenuItem from "components/Common/CourseLessonMenuItem"
import DraftPublishedBox from "components/Common/DraftPublishedBox"
import HoverText from "components/Common/HoverText"
import App from "contexts/App"
import Course from "contexts/Course"
import UsersContext from "contexts/Users"
import useHeaderBackground from "hooks/useHeaderBackground"
import PropTypes from "prop-types"
import { React, useEffect, useState } from "react"
import { useContext } from "react"
import { useQueryClient } from "react-query"
import { useHistory } from "react-router"

import useStyles from "./styles"

const CourseHeader = () => {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const queryClient = useQueryClient()

  const mdDown = useMediaQuery(theme.breakpoints.down("md"))
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))

  const nameSize = smDown ? 30 : mdDown ? 40 : 50

  const [adminName, setAdminName] = useState("")
  const [latestVersion, toggleLatestVersion] = useState(false)

  const {
    debouncedDetails,
    course,
    anchorEl,
    setAnchorEl,
    handleUpdate,
    saveLoading,
    publishLoading,
    archiveLoading,
    unarchiveLoading,
    unpublishLoading,
    publishCourseFunc,
    unpublishCourseFunc,
    archiveCourseFunc,
    unarchiveCourseFunc,
    newCourseVersionMutation,
    refetchCourse,
    copyCourseMutation,
    getPreviewMutation,
    correctInputsLength,
    disabledSave,
  } = useContext(Course)
  const { admins } = useContext(UsersContext)
  const headerSrc = useHeaderBackground(course?.thumbnail)

  // returns true if viewing latest version, returns false if not - use to render star
  const confirmMostRecent = () => {
    const length = course?.versions?.length
    const latest = course?.versions?.[length - 1]?.version
    const currentVersion = course?.version
    toggleLatestVersion(latest === currentVersion)
  }

  //set admin name using the id
  useEffect(() => {
    confirmMostRecent()
    if (admins) {
      setAdminName(admins?.find((x) => x.id === course?.updated_admin_id)?.name)
    }
  }, [admins, course.version, latestVersion])
  const { openSnackBar, openDialog } = useContext(App)

  //open menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  //open old versins dialog
  const openVersionDialog = () => {
    let data = {
      data: course,
      type: "course",
      history: history,
      refetch: refetchCourse,
    }
    openDialog("historyVersionDialog", data)
  }

  const openCreateNewVersion = () => {
    openDialog("createNewVersionDialog", {
      course,
      mutation: newCourseVersionMutation,
      type: "course",
      id: course.id,
    })
  }

  const handleCopy = () => {
    openDialog("createCopyDialog", {
      course,
      type: "course",
      copyMutation: copyCourseMutation,
    })
  }

  const [savingForPreview, setSavingForPreview] = useState(false)

  const openPreview = () => {
    //check if the inputs are ready to save
    if (!correctInputsLength || (disabledSave && course.published === 1)) {
      handleUpdate()
    } else {
      setSavingForPreview(true)
      handleUpdate()
        .then((res) => {
          getPreviewMutation
            .mutateAsync()
            .then((res) => {
              let link = `${process.env.REACT_APP_APP_URL}/courses/${course.id}/learn/?token=${res}&preview=course`
              window.open(link, "_blank")
            })
            .catch((err) => {
              openSnackBar({ message: "Error opening preview" })
            })
            .finally(() => {
              setSavingForPreview(false)
            })
        })
        .catch((err) => {
          openSnackBar({ message: "Error saving course" })
          setSavingForPreview(false)
        })
    }
  }

  const resources = history.location.pathname.includes("resources")
  const analytics = history.location.pathname.includes("analytics")

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-evenly"
      position="relative"
      width="100%"
      className={classes.headerContainer}
    >
      {headerSrc && (
        <img
          className={classes.backgroundImage}
          src={headerSrc}
          alt=""
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            objectFit: "cover",
            zIndex: -1,
            opacity: 0.2,
          }}
        />
      )}

      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        <Box marginLeft={2}>
          <HoverText
            data={
              debouncedDetails?.name !== ""
                ? debouncedDetails?.name
                : course?.name
            }
            variant="h1"
            className={classes.title}
            size={nameSize}
          />
        </Box>
        <Box marginRight={2} marginLeft={2} className={classes.saveContainer}>
          <LoaderButton
            variant="outlined"
            classoverrides={{
              button: {
                root: classes.previewBtn,
              },
            }}
            onClick={openPreview}
            disabled={course?.lessons?.length === 0}
            working={savingForPreview}
          >
            Save & Preview
          </LoaderButton>
          <Tooltip title="Create Copy" aria-label="create copy">
            <Button
              variant="outlined"
              size="large"
              className={classes.copyBtn}
              classes={{ root: classes.copyButton }}
              onClick={handleCopy}
            >
              <img src={copy} alt="" />
            </Button>
          </Tooltip>
          <Button
            variant="outlined"
            className={classes.createBtn}
            onClick={openCreateNewVersion}
            disabled={!course?.published && !course?.has_been_published}
          >
            Create New Version
          </Button>
          {!analytics && (
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={handleClick}
              className={classes.saveBtn}
            >
              Save
              <ExpandMoreIcon />
            </Button>
          )}
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            classes={{ paper: classes.menu }}
          >
            {course.archived === 1 && course.published === 0 ? (
              <div>
                <CourseLessonMenuItem
                  func={handleUpdate}
                  loadingState={saveLoading}
                  text="SAVE"
                />
                {!resources && (
                  <>
                    <Divider
                      style={{
                        backgroundColor: "#FFF",
                        opacity: "0.2",
                        margin: "3px",
                      }}
                    />
                    <CourseLessonMenuItem
                      func={unarchiveCourseFunc}
                      loadingState={unarchiveLoading}
                      text="SAVE & UNARCHIVE"
                    />
                  </>
                )}
              </div>
            ) : null}
            {course?.published === 0 && course.archived === 0 ? (
              <div>
                <CourseLessonMenuItem
                  func={handleUpdate}
                  loadingState={saveLoading && !publishLoading}
                  text="SAVE"
                />

                {!course?.has_been_published && !resources ? (
                  <>
                    <Divider
                      style={{
                        backgroundColor: "#FFF",
                        opacity: "0.2",
                        margin: "3px",
                      }}
                    />
                    <CourseLessonMenuItem
                      func={publishCourseFunc}
                      loadingState={publishLoading}
                      text="SAVE & PUBLISH"
                    />
                  </>
                ) : null}
                {!resources && (
                  <>
                    <Divider
                      style={{
                        backgroundColor: "#FFF",
                        opacity: "0.2",
                        margin: "3px",
                      }}
                    />
                    <CourseLessonMenuItem
                      func={archiveCourseFunc}
                      loadingState={archiveLoading}
                      text="SAVE & ARCHIVE"
                    />
                  </>
                )}
              </div>
            ) : null}
            {course?.published === 1 ? (
              <div>
                <CourseLessonMenuItem
                  func={handleUpdate}
                  loadingState={saveLoading}
                  text="SAVE"
                />
                {!resources && (
                  <>
                    <Divider
                      style={{
                        backgroundColor: "#FFF",
                        opacity: "0.2",
                        margin: "3px",
                      }}
                    />
                    <CourseLessonMenuItem
                      func={unpublishCourseFunc}
                      loadingState={unpublishLoading}
                      text="SAVE & UNPUBLISH"
                    />
                  </>
                )}
              </div>
            ) : null}
          </Menu>
        </Box>
      </Box>
      <Box
        marginLeft={2}
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        className={classes.detailBox}
      >
        <DraftPublishedBox
          published={course.published}
          has_been_published={course.has_been_published}
          archived={course.archived}
          publish_date={course.publish_date}
          size={"100px"}
        />
        <Typography
          variant="subtitle2"
          className={classes.version}
          onClick={() => openVersionDialog()}
          style={!latestVersion ? { marginRight: "10px" } : null}
        >
          VERSION {course?.version}
        </Typography>
        {latestVersion ? (
          <Box display="flex" justifyContent="center">
            <Tooltip title="Latest Version" aria-label="latest version">
              <StarOutlineIcon classes={{ root: classes.star }} />
            </Tooltip>
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" marginRight={1} />
        )}
        <Box className={classes.lastUpdated}>
          <Typography variant="subtitle2" className={classes.updated}>
            Last Updated {course?.updated?.format("M/D/YYYY")}
          </Typography>
          <Typography variant="subtitle2" className={classes.creator}>
            By {" " + adminName}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

CourseHeader.propTypes = {
  courseId: PropTypes.number,
}

export default CourseHeader
