import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  avatarBox: {
    position: "relative",
    height: "100%",
    width: "100%",
    borderRadius: 500,
    "&:hover": {
      "& $editBox": {
        opacity: 1,
      },
    },
  },
  editBox: {
    zIndex: 1,
    transition: theme.transitions.create("opacity", {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeInOut,
    }),
    opacity: 0,
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
}))

export default styles
