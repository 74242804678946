const characterCounter = (max, value) => {
  if (max > value) {
    return "#29B117"
  } else if (max === value) {
    return "inherit"
  } else {
    return "#FF5E69"
  }
}

export default characterCounter
