import { format } from "@cloudinary/base/actions/delivery"
import { fill } from "@cloudinary/base/actions/resize"
import { jpg } from "@cloudinary/base/qualifiers/format"
import { Avatar as A, Tooltip } from "@material-ui/core"
import { cloudinary } from "index"
import PropTypes from "prop-types"
import { useMemo } from "react"

// avatar components for rendering profile_image or initials
const Avatar = ({ user, size = 210, tooltipEnabled, ...props }) => {
  const cloudinarySrc = useMemo(() => {
    if (user?.profile_image) {
      return cloudinary
        .image(user.profile_image.public_id)
        .resize(
          fill()
            .width(size * 2)
            .height(size * 2)
        )
        .delivery(format(jpg()))
        .toURL()
    }
    return null
  }, [user?.profile_image])

  return (
    <>
      {tooltipEnabled ? (
        <Tooltip title={user?.name} placement="top">
          <A
            src={cloudinarySrc}
            {...props}
            style={{ width: size, height: size, ...props?.style }}
          >
            {/* renders the users initials from the user model in the circle in the instance there is no profile_image */}
            {user?.initials}
          </A>
        </Tooltip>
      ) : (
        <A
          src={cloudinarySrc}
          {...props}
          style={{ width: size, height: size, ...props?.style }}
        >
          {/* renders the users initials from the user model in the circle in the instance there is no profile_image */}
          {user?.initials}
        </A>
      )}
    </>
  )
}

Avatar.propTypes = {
  user: PropTypes.object,
  size: PropTypes.number,
  style: PropTypes.object,
  tooltipEnabled: PropTypes.bool,
}

export default Avatar
