import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(5),
    fontWeight: 600,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(1),
      overflow: "hidden",
    },
  },
  versionBtn: {
    color: theme.palette.primary.main,
    fontSize: ".725rem",
    fontWeight: "bold",
    zIndex: 1000,
    padding: 0,
    margin: 0,
    textTransform: "none",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  avatars: {
    cursor: "pointer",
  },
  statusRow: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      width: "15%",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
      width: "20%",
      display: "flex",
      justifyContent: "center",
    },
  },
  nameRow: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      display: "flex",
      justifyContent: "flex-start",
      width: "60%",
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "flex-start",
      marginTop: theme.spacing(2),
      width: "40%",
      maxHeight: "40px",
      overflow: "hidden",
    },
  },
  avatarRow: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      width: "25%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "25%",
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
  },
  versionRow: {
    [theme.breakpoints.down("sm")]: {
      width: "70%",
      alignItems: "center",
    },
    [theme.breakpoints.down("xs")]: {
      // marginTop: theme.spacing(2),
      width: "40%",
    },
  },
  durationRow: {
    [theme.breakpoints.down("sm")]: {
      width: "25%",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      display: "flex",
      justifyContent: "center",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
      width: "25%",
      marginBottom: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
}))

export default styles
