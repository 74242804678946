import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  dialogContainer: {
    minWidth: "870px",
    height: "700px",
    [theme.breakpoints.down("930")]: {
      minWidth: "90%",
      height: "100%",
    },
  },
  dialogTop: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  title: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  search: {
    flex: 10,
    background: "#EFEFEF",
    borderRadius: "5px",
    marginLeft: "2px",
    maxWidth: "200px",
    [theme.breakpoints.down("sm")]: {
      width: 200,
    },
    [theme.breakpoints.down("xs")]: {
      width: 150,
    },
  },
  searchIcon: {
    color: "#E2E2E2",
  },
  dialogContent: {
    marginBottom: "80px",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },

  buttonRoot: {
    height: "40px",
  },
  buttonText: {
    fontWeight: "bold",
    fontSize: " 0.6875rem",
  },
  addButton: {
    backgroundColor: "#D0D5DD",
  },
  remove: {
    color: "#092958",
    border: "1px solid #092958",
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(2),
    },
  },
  dialogActions: {
    position: "absolute",
    bottom: 4,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      bottom: 3,
    },
  },
}))

export default styles
