import { Box, Card, CardContent, Grow, Typography } from "@material-ui/core"
import lesson from "assets/lesson.svg"
import { Image, Transformation } from "cloudinary-react"
import DraftPublishedBox from "components/Common/DraftPublishedBox"
import HoverText from "components/Common/HoverText"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"

import useStyles from "./styles"

const CertificationCard = ({ openCert, certification }) => {
  const classes = useStyles()

  return (
    <Grow in={true} timeout={350} style={{ transformOrigin: "center" }}>
      <Box className={classes.container}>
        <Card className={classes.root} raised={true} elevation={0}>
          {!isEmpty(certification.thumbnail) && (
            <Image
              publicId={certification.thumbnail.public_id}
              style={{
                verticalAlign: "bottom",
                width: "100%",
                cursor: "pointer",
              }}
              onClick={openCert}
            >
              <Transformation
                width="335"
                crop="fill"
                gravity="auto"
                aspectRatio="16:9"
              />
            </Image>
          )}
          {isEmpty(certification.thumbnail) && (
            <Box className={classes.cardMedia} onClick={openCert} />
          )}
          <CardContent>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box className={classes.versionBtnBox}>
                <Typography variant="caption" className={classes.date}>
                  Last Updated {certification?.updated?.format("M/D/YYYY")}
                </Typography>
              </Box>
            </Box>
            <Box
              marginTop={1.5}
              height="60px"
              onClick={openCert}
              style={{ cursor: "pointer" }}
            >
              <HoverText
                variant="h5"
                className={classes.title}
                size={38}
                data={certification?.name}
              />
            </Box>
            <Box
              display="flex"
              marginTop={1.5}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <Typography
                  color="primary"
                  variant="subtitle1"
                  className={classes.lessons}
                >
                  {certification?.learning_paths_count}
                </Typography>
                <img src={lesson} alt="" className={classes.image} />
              </Box>
              <Box>
                <DraftPublishedBox
                  published={certification?.published}
                  has_been_published={certification?.has_been_published}
                  archived={0}
                  size={"90px"}
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Grow>
  )
}

CertificationCard.propTypes = {
  certification: PropTypes.object,
  learningPathLength: PropTypes.string,
  openCert: PropTypes.func,
}

export default CertificationCard
