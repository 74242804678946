import { Box, Button, Tooltip, Typography } from "@material-ui/core"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import StarOutlineIcon from "@material-ui/icons/StarOutline"
import DraftPublishedBox from "components/Common/DraftPublishedBox"
import HoverText from "components/Common/HoverText"
import InstructorGroup from "components/Common/InstructorGroup"
import PaperListRow from "components/Common/PaperListRow"
import AppContext from "contexts/App"
import UsersContext from "contexts/Users"
import dayjs from "dayjs"
import PropTypes from "prop-types"
import { useContext, useEffect, useState } from "react"

import useStyles from "./styles"

const LessonsCard = ({
  name,
  version,
  updatedDate,
  creator,
  openLesson,
  instructorsArray,
  duration,
  lesson,
}) => {
  const classes = useStyles()
  const theme = useTheme()

  const tabletView = useMediaQuery(theme.breakpoints.down("sm"))

  const titleSize = tabletView ? 30 : 40
  const componentHeight = tabletView ? "128px" : "96px"

  const [adminName, setAdminName] = useState("")
  const { admins } = useContext(UsersContext)
  const { openDialog } = useContext(AppContext)

  const latestVersion = lesson?.versions[lesson?.versions?.length - 1]

  const handleOpenHistory = () => {
    let data = {
      data: lesson,
      type: "lesson",
    }
    openDialog("historyVersionDialog", data)
  }

  //set admin name
  useEffect(() => {
    if (admins) {
      setAdminName(admins?.find((x) => x.id === lesson?.updated_admin_id)?.name)
    }
  }, [admins])

  return (
    <Box marginBottom={2}>
      <PaperListRow componentHeight={componentHeight}>
        <Box width="10%" className={classes.statusRow}>
          <DraftPublishedBox
            published={lesson.published}
            has_been_published={lesson.has_been_published}
            archived={lesson.archived}
            size={"90px"}
          />
        </Box>
        <Box width="30%" className={classes.nameRow}>
          <Box
            onClick={openLesson}
            style={{ cursor: "pointer" }}
            display="flex"
          >
            <HoverText
              data={name}
              size={titleSize}
              variant="h3"
              className={classes.title}
            />
          </Box>
        </Box>
        <Box
          width="30%"
          className={classes.avatarRow}
          display="flex"
          justifyContent="center"
        >
          {instructorsArray.length > 0 && (
            <InstructorGroup instructorsArray={instructorsArray} />
          )}
        </Box>
        {!tabletView ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="20%"
            className={classes.versionRow}
          >
            <Box display="flex" alignItems="center">
              <Button
                className={classes.versionBtn}
                onClick={handleOpenHistory}
              >
                Version {version}
              </Button>
              {latestVersion?.version === lesson?.version && (
                <Tooltip title="Latest Version" placement="right">
                  <StarOutlineIcon
                    style={{ marginLeft: "8px", fontSize: "1rem" }}
                    color="primary"
                    fontSize="inherit"
                  />
                </Tooltip>
              )}
            </Box>
            <Typography variant="subtitle2" style={{ color: "#868686" }}>
              Last Updated {updatedDate}
            </Typography>
            <Typography variant="subtitle2" style={{ color: "#868686" }}>
              by {adminName}
            </Typography>
          </Box>
        ) : (
          <Box
            width="75%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            marginBottom={2}
            marginTop={1}
          >
            <Button
              className={classes.versionBtn}
              // style={{ marginRight: "8px" }}
              onClick={handleOpenHistory}
            >
              Version {version}
            </Button>
            {latestVersion?.version === lesson?.version ? (
              <Tooltip title="Latest Version" placement="right">
                <StarOutlineIcon
                  style={{ fontSize: "1rem", marginRight: "8px" }}
                  color="primary"
                  fontSize="inherit"
                />
              </Tooltip>
            ) : (
              <Box width="18px" />
            )}
            <Typography
              variant="subtitle2"
              style={{ color: "#868686", marginLeft: "8px" }}
            >
              Last Updated {updatedDate} by {adminName}
            </Typography>
          </Box>
        )}
        <Box width="5%" className={classes.durationRow}>
          <Typography
            variant="subtitle1"
            color="primary"
            style={{ fontWeight: "600" }}
          >
            {dayjs.duration(0, "s").add(duration, "seconds").format("m:ss")}
          </Typography>
        </Box>
      </PaperListRow>
    </Box>
  )
}

LessonsCard.propTypes = {
  name: PropTypes.string,
  version: PropTypes.string,
  updatedDate: PropTypes.string,
  creator: PropTypes.number,
  openLesson: PropTypes.func,
  published: PropTypes.number,
  instructorsArray: PropTypes.array,
  duration: PropTypes.number,
  lesson: PropTypes.object,
}

export default LessonsCard
