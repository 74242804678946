import { Box } from "@material-ui/core"
import { uploadThumbnail } from "actions/upload"
import EditIcon from "assets/Icons/edit-icon.svg"
import { Image, Transformation } from "cloudinary-react"
import AppContext from "contexts/App"
import PropTypes from "prop-types"
import { useCallback, useContext, useState } from "react"

import FileUpload from "../FileUpload"
import useStyles from "./styles"

const ThumbnailUpload = ({
  title,
  cloudinaryUploadPreset,
  onUpload,
  value,
  type,
}) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)

  const { openDialog } = useContext(AppContext)

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles[0].type === "image/gif") {
        setLoading(true)
        uploadThumbnail(acceptedFiles[0], cloudinaryUploadPreset)
          .then((uploadRes) => onUpload(uploadRes, setLoading, type))
          .catch((err) => {
            setLoading(false)
            console.error(err)
          })
      } else {
        openDialog("cropThumbnail", {
          acceptedFiles,
          setLoading,
          type,
        })
          .then((res) => uploadThumbnail(res, cloudinaryUploadPreset))
          .then((uploadRes) => onUpload(uploadRes, setLoading, type))
          .catch((err) => {
            setLoading(false)
            console.error(err)
          })
      }
    },
    [cloudinaryUploadPreset, onUpload]
  )

  if (type !== "badge_image") {
    return (
      <FileUpload
        title={title ? title : "Drop thumbnail here"}
        onDrop={onDrop}
        showLoading={loading}
      >
        {value?.public_id && (
          <Box className={classes.thumbnailBox}>
            <Box className={classes.editBox}>
              <img src={EditIcon} alt="Edit thumbnail" style={{ width: 24 }} />
            </Box>
            <Image
              publicId={value.public_id}
              style={{ verticalAlign: "bottom", width: "100%" }}
            >
              <Transformation
                width="335"
                crop="fill"
                gravity="auto"
                aspectRatio="16:9"
              />
            </Image>
          </Box>
        )}
      </FileUpload>
    )
  } else {
    return (
      <FileUpload
        title={title ? title : "Drop thumbnail here"}
        onDrop={onDrop}
        showLoading={loading}
        childHeight={150}
        childWidth={150}
      >
        {value?.public_id && (
          <Box className={classes.badgeBox}>
            <Box className={classes.editBadgeBox}>
              <img src={EditIcon} alt="Edit thumbnail" style={{ width: 24 }} />
            </Box>
            <Image
              publicId={value.public_id}
              style={{ width: "100%", height: "100%", borderRadius: "100%" }}
            ></Image>
          </Box>
        )}
      </FileUpload>
    )
  }
}

ThumbnailUpload.propTypes = {
  title: PropTypes.string,
  cloudinaryUploadPreset: PropTypes.string,
  onUpload: PropTypes.func.isRequired,
  value: PropTypes.object,
  type: PropTypes.string,
}

export default ThumbnailUpload
