import {
  Badge,
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
  Typography,
} from "@material-ui/core"
import { Search } from "@material-ui/icons"
import UsersContext from "contexts/Users"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { useDebounce } from "use-lodash-debounce"

import useStyles from "./styles"

const UsersFilter = ({ totalNumber, totalShowing }) => {
  const classes = useStyles()
  const {
    searchValue,
    setSearchValue,
    activeValue,
    setActiveValue,
    sliderValue,
    setSliderValue,
    coursesCount,
    debouncedSliderValue,
    setDebouncedSliderValue,
  } = useContext(UsersContext)
  //search state in this component
  const [searchComponentValue, setSearchComponentValue] = useState("")
  //badge value
  const [badgeState, setBadgeState] = useState(0)
  //active filters array
  const [activeFilters, setActiveFilters] = useState([])
  const debouncedLocalSlider = useDebounce(sliderValue, 300)

  //change the dropdown value
  const handleChangeActive = (event) => {
    setActiveValue(event.target.value)
  }
  //useeffect to add active filters if search is used
  useEffect(() => {
    if (searchValue !== "" && !activeFilters.includes("search")) {
      setActiveFilters([...activeFilters, "search"])
    }
    if (searchValue === "" && activeFilters.includes("search")) {
      setActiveFilters(activeFilters.filter((x) => x !== "search"))
    }
  }, [searchValue])

  //useeffect to add active filters if active dropdown is used
  useEffect(() => {
    if (activeValue !== "all" && !activeFilters.includes("active")) {
      setActiveFilters([...activeFilters, "active"])
    }
    if (activeValue === "all" && activeFilters.includes("active")) {
      setActiveFilters(activeFilters.filter((x) => x !== "active"))
    }
  }, [activeValue])

  //useeffect to add courseCOunt if slider is used
  useEffect(() => {
    if (
      coursesCount !== null &&
      (debouncedSliderValue[0] !== 0 ||
        debouncedSliderValue[1] !== coursesCount) &&
      !activeFilters.includes("courseCount")
    ) {
      setActiveFilters([...activeFilters, "courseCount"])
    }

    if (
      coursesCount !== null &&
      debouncedSliderValue[0] === 0 &&
      debouncedSliderValue[1] === coursesCount &&
      activeFilters.includes("courseCount")
    ) {
      setActiveFilters(activeFilters.filter((x) => x !== "courseCount"))
    }
  }, [debouncedSliderValue])

  //handler change for slider
  const handleChangeSlider = (event, newValue) => {
    setSliderValue(newValue)
  }
  //value for the slider max and min
  function valueText(value) {
    return value
  }

  //useeffect to change the badge number if a filter or search is used
  useEffect(() => {
    setBadgeState(Number(activeFilters.length))
  }, [activeFilters])

  //reset filters and searching values
  const clearFilters = () => {
    setSearchComponentValue("")
    setActiveValue("all")
    setSearchValue("")
    setActiveFilters([])
    setSliderValue([0, coursesCount])
    setDebouncedSliderValue([0, coursesCount])
  }
  //clearFilters when we arrive to prevent errors when we change to admin tab
  useEffect(() => {
    clearFilters()
  }, [])

  //set slider using debounce
  useEffect(() => {
    setDebouncedSliderValue(sliderValue)
  }, [debouncedLocalSlider])

  return (
    <Box className={classes.filtersContainer}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h3" className={classes.title}>
          Filters
        </Typography>
        {badgeState > 0 && <Badge badgeContent={badgeState} color="primary" />}
      </Box>

      <Typography>
        Showing {totalShowing} of {totalNumber}
      </Typography>
      <TextField
        value={searchComponentValue}
        placeholder="Search by name or user ID"
        variant="filled"
        size="small"
        fullWidth
        InputProps={{
          classes: {
            root: classes.inputBackgroundColor,
          },
          startAdornment: (
            <InputAdornment
              position="start"
              style={{ marginLeft: "2px", opacity: "0.3", marginTop: 0 }}
            >
              <Search />
            </InputAdornment>
          ),
        }}
        onChange={(e) => {
          setSearchComponentValue(e.target.value)
          if (e.target.value === "") {
            setSearchValue("")
          }
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            setSearchValue(e.target.value)
          }
        }}
      />
      <Typography variant="h4" className={classes.subtitle}>
        Active
      </Typography>
      <FormControl variant="filled" className={classes.formControl}>
        <Select
          style={{ backgroundColor: "#0929582B", color: "#fff" }}
          className={classes.select}
          classes={{ select: classes.selectRoot }}
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={activeValue}
          onChange={handleChangeActive}
          inputProps={{
            classes: {
              icon: classes.icon,
            },
          }}
        >
          <MenuItem value={"all"}>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              All
            </Typography>
          </MenuItem>
          <MenuItem value={"30 days"}>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              Last 30 days
            </Typography>
          </MenuItem>
          <MenuItem value={"7 days"}>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              Last 7 days
            </Typography>
          </MenuItem>
          <MenuItem value={"1 day"}>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              Last 1 day
            </Typography>
          </MenuItem>
          <MenuItem value={"1 hour"}>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              Last 1 hour
            </Typography>
          </MenuItem>
        </Select>
      </FormControl>

      <Typography variant="h4" className={classes.subtitle}>
        Courses Completed
      </Typography>
      <Slider
        value={sliderValue}
        onChange={handleChangeSlider}
        defaultValue={0}
        step={1}
        min={0}
        max={coursesCount}
        valueLabelDisplay="on"
        aria-labelledby="range-slider"
        getAriaValueText={valueText}
        classes={{
          root: classes.sliderColor,
        }}
      />
      <Box className={classes.clearButton}>
        <Button classes={{ text: classes.buttonColor }} onClick={clearFilters}>
          Clear
        </Button>
      </Box>
    </Box>
  )
}

UsersFilter.propTypes = {
  totalNumber: PropTypes.number,
  totalShowing: PropTypes.number,
  coursesCount: PropTypes.number,
}

export default UsersFilter
