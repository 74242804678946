import { Box, Typography } from "@material-ui/core"
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf"
import PropTypes from "prop-types"

import FileUpload from "../FileUpload"
import useStyles from "./styles"

const PdfUpload = ({ onDrop, value, loading }) => {
  const classes = useStyles()

  return (
    <FileUpload
      onDrop={onDrop}
      title="ADD PDF"
      accepted="application/pdf"
      showLoading={loading}
    >
      {(value?.name || value) && (
        <Box height={110} width={190} className={classes.pdfBox}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <PictureAsPdfIcon color="primary" style={{ marginBottom: "4px" }} />
            <Typography color="primary" style={{ fontWeight: 600 }}>
              {value.name || value.split(/-(.+)/)[1]}
            </Typography>
          </Box>
        </Box>
      )}
    </FileUpload>
  )
}

PdfUpload.propTypes = {
  onDrop: PropTypes.func,
  value: PropTypes.object,
  loading: PropTypes.bool,
}

export default PdfUpload
