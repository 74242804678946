import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  chipRootPublished: {
    borderRadius: "3px",
    fontSize: "11px",
    fontWeight: "600",
    color: "#29B117",
    backgroundColor: "#D3EFCF",
  },
  chipRootDraft: {
    borderRadius: "3px",
    fontSize: "11px",
    fontWeight: "600",
    color: "#FFD034",
    backgroundColor: "#FFF2C5",
  },
  chipRootUnpublished: {
    borderRadius: "3px",
    fontSize: "11px",
    fontWeight: "600",
    color: "#FF8439",
    backgroundColor: "#FFDBC5",
  },
  chipRootArchive: {
    borderRadius: "3px",
    fontSize: "11px",
    fontWeight: "600",
    color: "#808080",
    backgroundColor: "#DDDDDD",
  },
  chipRootScheduled: {
    borderRadius: "3px",
    fontSize: "11px",
    fontWeight: "600",
    color: "#8952D8",
    backgroundColor: "#E7D6FF",
  },
}))

export default styles
