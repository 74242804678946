import LoaderButton from "@bit/c_t.components.loader-button"
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  Typography,
} from "@material-ui/core"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import EditIcon from "@material-ui/icons/Edit"
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle"
import dragGray from "assets/Icons/noun_drag_3124730_gray.svg"
import classnames from "clsx"
import AppContext from "contexts/App"
import LabContext from "contexts/Lab"
import { Field, Form, Formik, useFormik } from "formik"
import { TextField } from "formik-material-ui"
import { isEmpty, orderBy } from "lodash"
import { values } from "mobx"
import PropTypes from "prop-types"
import { useCallback, useContext, useEffect, useState } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import useDeepCompareEffect from "use-deep-compare-effect"
import * as Yup from "yup"

import useStyles from "./styles"

const validationSchema = Yup.object().shape({
  name: Yup.string().required("A name is required"),
  port: Yup.number()
    .typeError("Port should be a number")
    .positive("Port should be a positive number")
    .required("A port is required"),
})

const TabCard = ({ data = {}, index, disabled = false }) => {
  const classes = useStyles()

  const { removePort, updatePort } = useContext(LabContext)
  const [removing, setRemoving] = useState(false)
  const { openSnackBar } = useContext(AppContext)
  const [isEdit, setIsEdit] = useState(false)

  const handleToggleEdit = useCallback(() => {
    setIsEdit(!isEdit)
  }, [isEdit])

  const handleRemovePort = useCallback(async () => {
    try {
      setRemoving(true)
      await removePort(data?.id)
    } catch (e) {
      setRemoving(false)
      openSnackBar({
        message: "There was an error removing this port",
      })
    }
  }, [data?.id, removePort])

  const handleUpdatePort = useCallback(
    async (values, actions) => {
      try {
        await updatePort(data?.id, values)
        openSnackBar({
          message: "Port has been updated",
        })
        setIsEdit(false)
      } catch (e) {
        openSnackBar({
          message: "There was an error updating this port",
        })
        actions.resetForm()
      }
    },
    [data?.id, updatePort]
  )

  const {
    setValues,
    submitForm,
    isSubmitting,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
  } = useFormik({
    initialValues: {
      name: "",
      port: "",
    },
    validationSchema,
    onSubmit: handleUpdatePort,
  })

  useEffect(() => {
    setValues(
      {
        name: data.name,
        port: data.port,
      },
      false
    )
  }, [data.name, data.port])

  return (
    <Draggable draggableId={data.id.toString()} index={index}>
      {(provided) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={classnames(classes.tabCard, {
            [classes.isEdit]: isEdit,
          })}
        >
          <Box display="flex" justifyContent="space-between" flexGrow="1">
            <Box width="48%">
              {isEdit && (
                <FormControl
                  error={touched.name && errors.name}
                  fullWidth
                  style={{ marginTop: 4 }}
                >
                  <Input
                    name="name"
                    placeholder="Name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                  />
                  <FormHelperText>{errors?.name || " "}</FormHelperText>
                </FormControl>
              )}
              {!isEdit && <Typography>{data?.name}</Typography>}
            </Box>
            <Box width="48%">
              {isEdit && (
                <FormControl
                  error={touched.port && errors.port}
                  fullWidth
                  style={{ marginTop: 4 }}
                >
                  <Input
                    name="port"
                    placeholder="Port"
                    value={values.port}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                  />
                  <FormHelperText>{errors?.port || " "}</FormHelperText>
                </FormControl>
              )}
              {!isEdit && <Typography>{data?.port}</Typography>}
            </Box>
          </Box>
          {isEdit && (
            <Box minWidth={88} display="flex" justifyContent="flex-end">
              <LoaderButton
                style={{
                  flexShrink: 0,
                  flexGrow: 0,
                }}
                color="secondary"
                size="small"
                onClick={submitForm}
                working={isSubmitting}
              >
                Save
              </LoaderButton>
            </Box>
          )}
          {!isEdit && (
            <Box
              display="flex"
              flexShrink="0"
              flexGrow="0"
              visibility={disabled ? "hidden" : "visible"}
            >
              <IconButton
                size="small"
                onClick={handleToggleEdit}
                disabled={removing}
              >
                <EditIcon style={{ fontSize: "inherit" }} />
              </IconButton>
              <IconButton
                size="small"
                className={classes.removeButton}
                onClick={handleRemovePort}
                disabled={removing}
              >
                {removing && (
                  <CircularProgress
                    size={12}
                    style={{ position: "absolute" }}
                  />
                )}
                <RemoveCircleIcon style={{ fontSize: "inherit" }} />
              </IconButton>
              <Box className={classes.dragHandle} {...provided.dragHandleProps}>
                <img src={dragGray} alt="" width="11px" />
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Draggable>
  )
}

TabCard.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
  disabled: PropTypes.bool,
}

const TabsBuilder = ({ ports = [], disabled = false }) => {
  const classes = useStyles()

  const [orderedPorts, setOrderedPorts] = useState([])

  useEffect(() => {
    if (!isEmpty(ports)) {
      setOrderedPorts(orderBy(ports, ["order"], ["asc"]))
    }
  }, [ports])

  // const [checked, setChecked] = useState(false)

  // const handleChange = (e) => setChecked(e.target.checked)

  const { createPort, orderPorts } = useContext(LabContext)

  const handleCreatePort = useCallback(
    async (values, actions) => {
      await createPort({
        name: values.name,
        port: values.port + "", // API expects string
      })
      actions.resetForm()
      // actions.setSubmitting(false)
    },
    [createPort]
  )

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const handleOnDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const currentPorts = Array.from(orderedPorts)

    const newPorts = reorder(
      orderedPorts,
      result.source.index,
      result.destination.index
    )

    setOrderedPorts(newPorts)

    try {
      await orderPorts(newPorts.map((p) => p.id))
    } catch (e) {
      console.log(e)
      setOrderedPorts(currentPorts)
    }
  }

  return (
    <Box paddingTop={2} maxWidth="500px">
      {/* <Box
        display="flex"
        alignItems="center"
        marginBottom="12px"
        justifyContent="space-between"
        height="36px"
      >
        <Typography
          variant="subtitle1"
          style={{ fontWeight: 600 }}
          color="secondary"
        >
          Enable Code Editor
        </Typography>
        <Switch
          checked={checked}
          onChange={handleChange}
          color="primary"
          name="enable-code-editor"
          inputProps={{ "aria-label": "enable code editor" }}
        />
      </Box> */}
      <Typography
        variant="subtitle1"
        style={{ fontWeight: 600, marginRight: "160px" }}
        color="secondary"
      >
        Lab Output Tabs
      </Typography>
      {!disabled && (
        <>
          <Typography variant="body2" style={{ marginTop: 4 }}>
            What ports would you like your users to interact on?
          </Typography>
          <Formik
            initialValues={{
              name: "",
              port: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleCreatePort}
          >
            {({ isSubmitting }) => (
              <Form>
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                  marginTop={1}
                >
                  <Box
                    flexGrow={1}
                    display="flex"
                    justifyContent="space-between"
                    paddingRight={1}
                  >
                    <Field
                      component={TextField}
                      placeholder="Name"
                      name="name"
                      color="secondary"
                      style={{ width: "48%" }}
                    />
                    <Field
                      component={TextField}
                      placeholder="Port"
                      name="port"
                      color="secondary"
                      style={{ width: "48%" }}
                    />
                  </Box>
                  <LoaderButton
                    style={{
                      flexShrink: 0,
                      flexGrow: 0,
                    }}
                    color="secondary"
                    startIcon={<AddCircleIcon />}
                    size="small"
                    type="submit"
                    working={isSubmitting}
                  >
                    Add
                  </LoaderButton>
                </Box>
              </Form>
            )}
          </Formik>
        </>
      )}
      {ports.length > 0 && (
        <Box paddingTop="13px">
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="list">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {orderedPorts.map((tab, idx) => (
                    <TabCard
                      key={tab.id}
                      data={tab}
                      index={idx}
                      disabled={disabled}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      )}
    </Box>
  )
}

TabsBuilder.propTypes = {
  ports: PropTypes.array,
  disabled: PropTypes.bool,
}

export default TabsBuilder
