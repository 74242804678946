import { Box, Button, Paper, Typography } from "@material-ui/core"
import HoverText from "components/Common/HoverText"
import PropTypes from "prop-types"

import useStyles from "./styles"

const ClientCardMobile = ({
  client,
  openEditDialog,
  totalSeats,
  totalActive,
}) => {
  const classes = useStyles()

  return (
    <Paper className={classes.paper} elevation={0}>
      <Box display="flex" justifyContent="space-between">
        <Typography
          color="primary"
          variant="subtitle1"
          style={{ fontWeight: "bold", textDecoration: "underline" }}
        >
          Company
        </Typography>
        <Typography
          color="primary"
          variant="subtitle1"
          style={{ fontWeight: "bold", textDecoration: "underline" }}
        >
          Client ID
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <HoverText
          data={client?.name}
          size={20}
          variant="h3"
          className={classes.companyName}
        />
        <Typography variant="subtitle1" style={{ marginTop: "8px" }}>
          {client?.id}
        </Typography>
      </Box>
      <Box marginTop={2} display="flex" justifyContent="space-between">
        <Box display="flex" flexDirection="column">
          <Box display="flex">
            <Typography
              color="primary"
              variant="subtitle1"
              style={{ fontWeight: "bold", marginRight: "4px" }}
            >
              Codes:
            </Typography>
            <Typography variant="subtitle1">
              {client?.access_codes?.length}
            </Typography>
          </Box>
          {/* <Box display="flex" marginTop={1}>
            <Typography
              color="primary"
              variant="subtitle1"
              style={{ fontWeight: "bold", marginRight: "4px" }}
            >
              Completion Rate:
            </Typography>
            <Typography variant="subtitle1">
              {client?.access_codes?.length}
            </Typography>
          </Box> */}
        </Box>
        <Box>
          <Box display="flex">
            <Typography
              color="primary"
              variant="subtitle1"
              style={{ fontWeight: "bold", marginRight: "4px" }}
            >
              Total Seats:
            </Typography>
            <Typography variant="subtitle1">{totalSeats}</Typography>
          </Box>
          <Box display="flex" marginTop={1}>
            <Typography
              color="primary"
              variant="subtitle1"
              style={{ fontWeight: "bold", marginRight: "4px" }}
            >
              Active Seats:
            </Typography>
            <Typography variant="subtitle1">{totalActive}</Typography>
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" marginTop={1}>
        <Button color="secondary" onClick={openEditDialog}>
          Edit
        </Button>
      </Box>
    </Paper>
  )
}

ClientCardMobile.propTypes = {
  client: PropTypes.object,
  openEditDialog: PropTypes.func,
  totalSeats: PropTypes.number,
  totalActive: PropTypes.number,
}

export default ClientCardMobile
