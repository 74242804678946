import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  dialogContainer: {
    width: "740px",
    maxWidth: "740px",
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
  },
  questionTabs: {
    // flexGrow: 1,
  },
  startIcon: {
    marginRight: 2,
  },
  dialogContent: {
    display: "flex",
    padding: theme.spacing(2.25),
    paddingRight: theme.spacing(1.25),
    overflow: "hidden",
  },
  removeButton: {
    color: theme.palette.error.main,
  },
}))

export default styles
