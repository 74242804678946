import LoaderButton from "@bit/c_t.components.loader-button"
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import { Search } from "@material-ui/icons"
import { listPaths } from "actions/paths"
import AddedPathCard from "components/Certifications/AddedPathCard"
import AppContext from "contexts/App"
import CertificationContext from "contexts/Certification"
import { useContext, useEffect, useLayoutEffect, useState } from "react"
import { useQuery } from "react-query"
import { useHistory } from "react-router-dom"

import useStyles from "./styles"

const AddPathDialog = () => {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const { dialogs, closeDialog, openSnackBar } = useContext(AppContext)
  const { attachedPaths, setAttachedPaths } = useContext(CertificationContext)

  const thisDialog = dialogs?.["addPathDialog"] || {}
  const { open = false, data } = thisDialog
  const { data: paths, isLoading: pathsLoading } = useQuery("paths", listPaths)

  const smDown = useMediaQuery(theme.breakpoints.down("sm"))

  const [searchValue, setSearchValue] = useState("")

  // locally added learning paths
  const [addedPaths, setAddedPaths] = useState([])

  const handleClose = () => {
    closeDialog("addPathDialog", false)
    setSearchValue("")
    setAddedPaths(attachedPaths)
  }

  //open Lesson dialog
  const openLearningPaths = () => {
    history.push("/learning-paths")
    handleClose()
  }

  const addPaths = () => {
    setAttachedPaths(addedPaths)
    handleClose()
  }

  useLayoutEffect(() => {
    setAddedPaths(attachedPaths)
  }, [attachedPaths])

  const getFilteredPaths = () => {
    return paths?.filter((path) => {
      return path?.name?.toLowerCase?.()?.includes?.(searchValue.toLowerCase())
    })
  }

  const filteredPaths = searchValue.length > 0 ? getFilteredPaths() : paths

  return (
    <Dialog
      classes={{ paperWidthSm: classes.dialogContainer }}
      open={open}
      fullScreen={smDown}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={classes.dialogTop}
        >
          <Typography variant="h2" className={classes.title}>
            Add Learning Path
          </Typography>
          <TextField
            placeholder="Search Learning Paths"
            className={classes.search}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                setSearchValue(e.target.value)
              }
            }}
            onChange={(e) => e.target.value === "" && setSearchValue("")}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start" style={{ marginLeft: "10px" }}>
                  <Search className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        {pathsLoading ? (
          <CircularProgress />
        ) : (
          filteredPaths?.map((path) => {
            return (
              <AddedPathCard
                key={path.id}
                path={path}
                addedPaths={addedPaths}
                setAddedPaths={setAddedPaths}
              />
            )
          })
        )}
      </DialogContent>
      <DialogActions className={classes.DialogActions}>
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          marginLeft="20px"
          marginRight="20px"
        >
          <Box>
            <Button className={classes.remove} onClick={openLearningPaths}>
              Create Learning Path
            </Button>
          </Box>
          <Box>
            <Button
              onClick={handleClose}
              style={{ height: "40px", marginRight: "10px" }}
            >
              Cancel
            </Button>
            <LoaderButton
              onClick={addPaths}
              classoverrides={{
                wrapper: classes.button,
                button: {
                  root: classes.buttonRoot,
                },
              }}
              variant="contained"
              color="secondary"
              size="small"
            >
              add
            </LoaderButton>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

AddPathDialog.propTypes = {}

export default AddPathDialog
