import { Box, Button, IconButton, Tooltip } from "@material-ui/core"
import { Add, GetApp } from "@material-ui/icons"
import ListTitle from "components/Common/ListTitle"
import AdminCard from "components/Users/AdminCard"
import AppContext from "contexts/App"
import UsersContext from "contexts/Users"
import * as FileSaver from "file-saver"
import { Parser } from "json2csv"
import Page from "pages/Page"
import PropTypes from "prop-types"
import React, { useContext, useEffect } from "react"
import Router from "router"

import useStyles from "./styles"

const Admin = ({ routes = [], ...rest }) => {
  const classes = useStyles()

  const {
    openDialog,
    setPageSubTitle,
    setPageTitle,
    openSnackBar,
  } = useContext(AppContext)
  const { createAdminMutation, refetchAdmins, admins } = useContext(
    UsersContext
  )

  const openCreateAdmin = () => {
    openDialog("createAdminDialog", {
      createAdminFunc: createAdminMutation,
      refetchAdmins: refetchAdmins,
    })
  }

  const downloadAdmins = () => {
    const fields = [
      "access_analytics",
      "access_coupons",
      "created",
      "email",
      "id",
      "last_online",
      "name",
      "status",
      "super",
      "updated",
    ]
    const opts = { fields }

    try {
      const parser = new Parser(opts)
      const csv = parser.parse(admins)
      var file = new Blob([csv], { type: "text/csv" })
      FileSaver.saveAs(file, `admins.csv`)
    } catch (err) {
      openSnackBar({ message: "Error download admins data" })
    }
  }

  //set admin  subtitle number
  useEffect(() => {
    setPageTitle("Admins")
    setPageSubTitle(admins?.length)
  }, [])

  return (
    <Page>
      <Box className={classes.container}>
        <Box className={classes.leftContainer}>
          <Box marginLeft={2} position="absolute" top="0" right="0">
            <Tooltip title="Download data" placement="top">
              <IconButton onClick={downloadAdmins}>
                <GetApp color="secondary" />
              </IconButton>
            </Tooltip>
          </Box>
          <ListTitle
            list={[
              { name: "", size: "2%" },
              { name: "Name", size: "40%" },
              { name: "Email", size: "27%" },
              { name: "", size: "10%" },
              { name: "Status", size: "10%" },
              { name: "", size: "25%" },
            ]}
          />
          {admins?.map((x, i) => {
            return <AdminCard admin={x} key={i} />
          })}
        </Box>
        <Box className={classes.rightContainer}>
          <Button
            onClick={openCreateAdmin}
            variant="contained"
            color="secondary"
            startIcon={<Add fontSize="small" />}
            style={{ height: "40px" }}
          >
            Invite Admin
          </Button>
        </Box>
      </Box>

      <Router routes={routes} {...rest} />
    </Page>
  )
}
Admin.propTypes = {
  routes: PropTypes.array,
}
export default Admin
