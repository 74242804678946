import { Box, Typography } from "@material-ui/core"
import { AddCircle } from "@material-ui/icons"
import DarkButton from "components/Common/DarkButton"
import AppContext from "contexts/App"
import CertificationContext from "contexts/Certification"
import AddPathDialog from "dialogs/AddPathDialog"
import PropTypes from "prop-types"
import React, { useContext, useEffect } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"

import AddedPathCard from "../AddedPathCard"
import NoPathAdded from "../NoPathAdded"
import useStyles from "./styles"

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const LearningPath = ({ path, index, lockedCertification, type }) => {
  return (
    <Draggable draggableId={path?.id.toString()} index={index}>
      {(provided) => {
        return (
          <AddedPathCard
            path={path}
            provided={provided}
            type={type}
            lockedCertification={lockedCertification}
          />
        )
      }}
    </Draggable>
  )
}

const LearningPathList = React.memo(function LearningPathList({
  paths,
  type,
  lockedCertification,
}) {
  return paths.map((path, index) => (
    <LearningPath
      path={path}
      index={index}
      key={path.id}
      type={type}
      lockedCertification={lockedCertification}
    />
  ))
})

const CertificationContent = () => {
  const classes = useStyles()
  const { openDialog } = useContext(AppContext)

  const {
    certification,
    attachedPaths,
    setAttachedPaths,
    setInitialAttachedPaths,
  } = useContext(CertificationContext)

  const addLearningPath = () => {
    openDialog("addPathDialog")
  }

  //populate learning path array
  useEffect(() => {
    let newArray = []

    certification?.learning_paths?.map((path) => {
      if (path.id) {
        newArray.push(path)
      }
    })
    setAttachedPaths(newArray)
    setInitialAttachedPaths(newArray)
  }, [certification?.learning_paths])

  function onDragEnd(result) {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const orderedPaths = reorder(
      attachedPaths,
      result.source.index,
      result.destination.index
    )

    setAttachedPaths(orderedPaths)
  }

  const lockedCertification =
    certification?.published ||
    (!certification?.published && certification.has_been_published)

  return (
    <Box position="relative" minHeight="100%">
      {lockedCertification ? (
        <Box className={classes.publishedBanner}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography className={classes.title} variant="h3">
              You are in a{" "}
              {certification?.published ? "published" : "unpublished"}{" "}
              certification, this can not be updated
            </Typography>
          </Box>
        </Box>
      ) : null}
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        padding="31px 37px 8px 37px"
        marginBottom="10px"
      >
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          minHeight="37px"
        >
          <Typography
            variant="h3"
            color="primary"
            style={{ fontWeight: "700", marginRight: "8px" }}
          >
            Learning Paths{" "}
          </Typography>
          <Typography
            variant="h3"
            color="primary"
            className={classes.orderText}
          >
            In order as they appear
          </Typography>
        </Box>
        {attachedPaths?.length > 0 && (
          <DarkButton
            onClick={() => addLearningPath()}
            startIcon={
              <AddCircle
                fontSize="small"
                classes={{ root: classes.addCircle }}
              />
            }
            style={certification?.published ? { display: "none" } : null}
          >
            Add Learning Path
          </DarkButton>
        )}
      </Box>
      <Box marginLeft={"37px"} marginRight="37px">
        {attachedPaths?.length > 0 ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="path">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <LearningPathList
                    paths={attachedPaths}
                    lockedCertification={lockedCertification}
                    type="view"
                  />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <NoPathAdded onClick={() => addLearningPath()} />
        )}
      </Box>
      {lockedCertification ? (
        <Box className={classes.publishedOverlay} />
      ) : null}

      <AddPathDialog />
    </Box>
  )
}

LearningPath.propTypes = {
  path: PropTypes.object,
  index: PropTypes.number,
  lockedCertification: PropTypes.number,
  type: PropTypes.string,
}

LearningPathList.propTypes = {
  paths: PropTypes.array,
  type: PropTypes.string,
  lockedCertification: PropTypes.number,
}

CertificationContent.propTypes = {
  lesson: PropTypes.object,
  index: PropTypes.number,
}
export default CertificationContent
