import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  enabled: {},
  buttonDisabled: {
    cursor: ["not-allowed", "!important"],
  },
  chip: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    borderWidth: 1,
    borderStyle: "dashed",
    borderRadius: 4,
    height: 30,
    width: 60,
    cursor: "pointer",
    marginRight: theme.spacing(1),
    opacity: 0,
    transition: theme.transitions.create(
      ["opacity", "background-color", "border-color"],
      {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeIn,
      }
    ),
    "&$enabled": {
      opacity: 1,
      backgroundColor: "#E2F1F2",
      borderColor: "transparent",
    },
  },
  hovered: {
    opacity: 1,
    "&$buttonDisabled": {
      opacity: 0.5,
    },
  },
  chipSpacer: {
    width: 60,
    marginRight: theme.spacing(1),
  },
  media: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    "&$enabled": {
      backgroundColor: "#E2F1F2",
    },
  },
  knowledgeCheck: {
    color: "#29B117",
    borderColor: "#29B117",
    "&$enabled": {
      backgroundColor: "#D3EFCF",
    },
  },
  sentimentQuestions: {
    color: "rgb(255 31 31)",
    borderColor: "rgb(255 31 31)",
    "&$enabled": {
      backgroundColor: "rgb(253 201 201)",
    },
  },
  topBanner: {
    color: "#FF6A40",
    borderColor: "#FF6A40",
    "&$enabled": {
      backgroundColor: "#FFC4B4",
    },
  },
  bottomBanner: {
    color: "#E32B6E",
    borderColor: "#E32B6E",
    "&$enabled": {
      backgroundColor: "#FDE0EB",
    },
  },
  fullscreen: {
    color: "#888888",
    borderColor: "#888888",
    "&$enabled": {
      backgroundColor: "#e2e2e2",
    },
  },
  readyCheck: {
    color: "#B821E2",
    borderColor: "#B821E2",
    "&$enabled": {
      backgroundColor: "#EDC7F8",
    },
  },
  prefilledNotes: {
    color: "#0078F6",
    borderColor: "#0078F6",
    "&$enabled": {
      backgroundColor: "#B1D6FC",
    },
  },
}))

export default styles
