import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@material-ui/core"
import GetApp from "@material-ui/icons/GetApp"
import HoverText from "components/Common/HoverText"
import AppContext from "contexts/App"
import UsersContext from "contexts/Users"
import dayjs from "dayjs"
import * as FileSaver from "file-saver"
import MaterialTable from "material-table"
import PropTypes from "prop-types"
import React, { useContext, useState } from "react"

import useStyles from "./styles"

const UsersTable = ({
  tableRef,
  height,
  users,
  loading,
  setTotalNumber,
  setTotalShowing,
}) => {
  const classes = useStyles()
  const { setPageSubTitle, openSnackBar } = useContext(AppContext)
  const [notFirstTime, setNotFirstTime] = useState(false)
  const [pageSizeValue, setPageSizeValue] = useState(25)
  const [anchorEl, setAnchorEl] = useState(null)

  const { openDialog } = useContext(AppContext)
  const {
    sort,
    setSort,
    searchValue,
    activeValue,
    listAppUsersMutation,
    sliderValue,
  } = useContext(UsersContext)

  const handleOpenExportMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseExport = () => {
    setAnchorEl(null)
  }

  const downloadCSV = (allData) => {
    let data = {}
    if (!allData) {
      data = {
        download: true,
        sort: {
          key: sort.name,
          direction: "DESC",
        },
        filter: {
          search: searchValue,
          last_online: activeValue,
          completed_courses: sliderValue,
        },
        page: 0,
        pageSize: 100,
      }
    } else {
      data = {
        download: true,
        sort: {
          key: "id",
          direction: "DESC",
        },
        filter: {
          search: "",
          last_online: "all",
          completed_courses: sliderValue,
        },
        page: 0,
        pageSize: 100,
      }
    }

    listAppUsersMutation.mutate(data, {
      onSuccess: (res) => {
        var file = new Blob([res], { type: "text/csv" })

        FileSaver.saveAs(file, `users.csv`)
        setAnchorEl(null)
      },
    })
  }

  const headCells = [
    {
      title: "ID",
      field: "id",
      headerStyle: {
        // width: "5%",
        // maxWidth: "5%",
        padding: "5px 10px",
      },
      cellStyle: {
        // width: "5%",
        // maxWidth: "5%",
        height: "25px",
        maxHeight: "25px",
        padding: "5px 10px",
      },
      render: function idText(rowData) {
        return (
          <>
            <Typography variant="subtitle1">{rowData.id}</Typography>
          </>
        )
      },
    },
    {
      title: "First Name",
      field: "first_name",
      render: function nameText(rowData) {
        return (
          <>
            <HoverText
              variant="subtitle1"
              data={rowData?.first_name}
              size={20}
            />
          </>
        )
      },
      headerStyle: {
        width: "5%",
        maxWidth: "5%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "5%",
        maxWidth: "5%",
        maxHeight: "25px",
        height: "25px",
        padding: "5px 10px",
      },
    },
    {
      title: "Last Name",
      field: "last_name",
      render: function nameText(rowData) {
        return (
          <>
            <HoverText
              variant="subtitle1"
              data={rowData?.last_name}
              size={20}
            />
          </>
        )
      },
      headerStyle: {
        width: "5%",
        maxWidth: "5%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "5%",
        maxWidth: "5%",
        maxHeight: "25px",
        height: "25px",

        padding: "5px 10px",
      },
    },
    {
      title: "User Name",
      field: "username",
      render: function nameText(rowData) {
        return (
          <Box width="200px">
            <HoverText variant="subtitle1" data={rowData?.username} size={25} />
          </Box>
        )
      },
      headerStyle: {
        width: "15%",
        maxWidth: "15%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "15%",
        maxWidth: "15%",
        maxHeight: "25px",
        height: "25px",

        padding: "5px 10px",
      },
    },
    {
      title: "Email",
      field: "email",
      headerStyle: {
        width: "20%",
        maxWidth: "20%",
        height: "25px",

        padding: "5px 10px",
      },
      cellStyle: {
        width: "20%",
        maxWidth: "20%",
        maxHeight: "25px",
        height: "25px",

        padding: "5px 10px",
      },
      render: function emailText(rowData) {
        return (
          <Box width="200px">
            <HoverText variant="subtitle1" data={rowData?.email} size={25} />
          </Box>
        )
      },
    },
    {
      title: "Last Active",
      field: "last_online",
      headerStyle: {
        width: "10%",
        maxWidth: "10%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "10%",
        maxWidth: "10%",
        maxHeight: "25px",
        height: "25px",

        padding: "5px 10px",
      },
      render: function lastOnlineText(rowData) {
        return (
          <>
            <Typography variant="subtitle1">
              {dayjs(rowData.last_online).format("MM/DD/YYYY")}
            </Typography>
          </>
        )
      },
    },
    {
      title: "Created",
      field: "created",
      headerStyle: {
        width: "10%",
        maxWidth: "10%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "10%",
        maxWidth: "10%",
        maxHeight: "25px",
        height: "25px",

        padding: "5px 10px",
      },
      render: function createdText(rowData) {
        return (
          <>
            <Typography variant="subtitle1">
              {dayjs(rowData.created).format("MM/DD/YYYY")}
            </Typography>
          </>
        )
      },
    },
    {
      title: "Courses Completed",
      field: "completed_courses",
      headerStyle: {
        width: "5%",
        maxWidth: "5%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "5%",
        maxWidth: "5%",
        height: "25px",
        maxHeight: "25px",
        padding: "5px 10px",
      },
      render: function coursesCompletedFunc(rowData) {
        return (
          <>
            <Typography variant="subtitle1">
              {rowData.completed_courses}
            </Typography>
          </>
        )
      },
    },
    {
      title: "Courses In Progress",
      field: "in_progress_courses",
      headerStyle: {
        width: "5%",
        maxWidth: "5%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "5%",
        maxWidth: "5%",
        height: "25px",
        maxHeight: "25px",
        padding: "5px 10px",
      },
      render: function coursesCompletedFunc(rowData) {
        return (
          <>
            <Typography variant="subtitle1">
              {rowData.in_progress_courses}
            </Typography>
          </>
        )
      },
    },
    {
      title: "Accepted Invites",
      field: "accepted_invites",
      headerStyle: {
        width: "5%",
        maxWidth: "5%",
        padding: "5px 10px",
      },
      celStyle: {
        width: "5%",
        maxWidth: "5%",
        height: "25px",
        maxHeight: "25px",
        padding: "5px 10px",
      },
      render: function coursesCompletedFunc(rowData) {
        return (
          <>
            <Typography variant="subtitle1">
              {rowData.accepted_invites}
            </Typography>
          </>
        )
      },
    },

    {
      title: "Pending Invites",
      field: "pending_invites",
      headerStyle: {
        width: "5%",
        maxWidth: "5%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "5%",
        maxWidth: "5%",
        height: "25px",
        maxHeight: "25px",
        padding: "5px 10px",
      },
      render: function coursesCompletedFunc(rowData) {
        return (
          <>
            <Typography variant="subtitle1">
              {rowData.pending_invites}
            </Typography>
          </>
        )
      },
    },
    {
      title: "Inactive Invites",
      field: "inactive_invites",
      headerStyle: {
        width: "5%",
        maxWidth: "5%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "5%",
        maxWidth: "5%",
        height: "25px",
        maxHeight: "25px",
        padding: "5px 10px",
      },
      render: function coursesCompletedFunc(rowData) {
        return (
          <>
            <Typography variant="subtitle1">
              {rowData.inactive_invites}
            </Typography>
          </>
        )
      },
    },

    {
      title: (
        <Box>
          <Tooltip title="Download data">
            <IconButton
              className={classes.iconButton}
              onClick={handleOpenExportMenu}
            >
              <GetApp color="secondary" />
            </IconButton>
          </Tooltip>
          <Menu
            id="admin-dropdown"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            getContentAnchorEl={null}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseExport}
          >
            <MenuItem onClick={() => downloadCSV("allData")}>All Data</MenuItem>
            <MenuItem onClick={() => downloadCSV()}>Current Data</MenuItem>
          </Menu>
        </Box>
      ),
      field: "",
      headerStyle: {
        width: "10%",
        maxWidth: "10%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "10%",
        maxWidth: "10%",
        height: "25px",

        maxHeight: "25px",
        padding: "5px 10px",
      },
      sorting: false,
      render: function ViewDetailsButton(rowData) {
        let data = rowData
        return (
          <Button
            key={rowData.id}
            onClick={() => openDialog("userDetailsDialog", data)}
            color="secondary"
          >
            VIEW DETAILS
          </Button>
        )
      },
    },
  ]
  if (loading) {
    return null
  }

  return (
    <Box position="relative">
      <MaterialTable
        tableRef={tableRef}
        // isLoading={loading}
        // loadingType="linear"
        options={{
          toolbar: false,
          title: "",
          headerStyle: {
            backgroundColor: "#F5F5F5",
            borderBottom: "2px solid #17A5B1",
            position: "sticky",
            top: 0,
            whiteSpace: "nowrap",
            width: "100%",
            fontSize: "12px",
            fontWeight: "bold",
            height: "80px",
            padding: "5px 10px",
          },

          maxBodyHeight: height - 55,
          pageSize: pageSizeValue,
          pageSizeOptions: [10, 25, 50, 100],
          rowStyle: (rowData, i) => ({
            backgroundColor: i % 2 !== 0 ? "#fff" : "#F5F5F5",
            maxHeight: "25px",
            height: "25px",
            padding: "5px 10px",
          }),
        }}
        classses={{ root: classes.root }}
        columns={headCells}
        data={(query) => {
          return new Promise((resolve, reject) => {
            //prevent two calls every time that we do a sort or a filter after the first load
            if (notFirstTime) {
              setSort({
                name: !query?.orderBy?.field ? "id" : query?.orderBy?.field,
                direction:
                  query.orderDirection === ""
                    ? "DESC"
                    : query?.orderDirection.toUpperCase(),
              })

              setPageSizeValue(query?.pageSize)
              let data = {
                sort: {
                  key: !query?.orderBy?.field ? "id" : query?.orderBy?.field,
                  direction:
                    query.orderDirection === ""
                      ? "DESC"
                      : query?.orderDirection.toUpperCase(),
                },
                filter: {
                  search: searchValue,
                  last_online: activeValue,
                  completed_courses: sliderValue,
                },
                page: !query?.page ? 0 : query?.page,
                pageSize: query?.pageSize,
              }
              listAppUsersMutation.mutate(data, {
                onSuccess: (res) => {
                  resolve({
                    data: res.data,
                    page: !query?.page ? 0 : query?.page,
                    totalCount: res?.searchTotal,
                    pageSize: query?.pageSize,
                  })
                  setTotalNumber(res?.total)
                  setTotalShowing(res?.searchTotal)
                  //set usres  subtitle depends the search
                  setPageSubTitle(res?.searchTotal)
                  if (tableRef.current) {
                    // gets table container and sets scrolltop to 0 to move table to the top
                    tableRef.current.tableContainerDiv.current.scrollTop = 0
                  }
                },
                onError: (err) => {
                  openSnackBar({
                    message: "Error loading app users data",
                  })
                },
              })
            } else {
              resolve({
                data: users,
                page: 0,
                totalCount: users.length,
                pageSize: 25,
              })
            }
            setNotFirstTime(true)
          })
        }}
      />
    </Box>
  )
}

UsersTable.propTypes = {
  loading: PropTypes.bool,
  tableRef: PropTypes.object,
  classes: PropTypes.object,
  users: PropTypes.array,
  height: PropTypes.number,
  onChangeRowsPerPage: PropTypes.object,
  rowsPerPage: PropTypes.object,
  count: PropTypes.object,
  colSpan: PropTypes.object,
  page: PropTypes.string,
  onChangePage: PropTypes.object,
  icon: PropTypes.object,
  setTotalShowing: PropTypes.func,
  setTotalNumber: PropTypes.func,
  onlyFirstTime: PropTypes.string,
}

export default UsersTable
