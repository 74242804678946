import { Typography } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { Component } from "react"

import styles from "./styles"

class Index extends Component {
  render() {
    const { classes } = this.props
    return (
      <Page>
        <Typography variant="h4">This is the generated index page</Typography>
      </Page>
    )
  }
}

Index.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(Index)
