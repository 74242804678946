import {
  Box,
  Button,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import PaperListRow from "components/Common/PaperListRow"
import AppContext from "contexts/App"
import DiscountsContext from "contexts/Discounts"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import dayjs from "dayjs"

import DiscountCardMobile from "../DiscountCardMobile"
import useStyles from "./styles"

const DiscountCard = ({
  id,
  name,
  code,
  fixed,
  fixedOff,
  percentOff,
  start,
  end,
  available,
  used,
  containers,
  active,
}) => {
  const classes = useStyles()
  const theme = useTheme()

  const smDown = useMediaQuery(theme.breakpoints.down("sm"))

  const { openDialog } = useContext(AppContext)
  const {
    updateDiscountMutation,
    setDiscountCoursesMutatation,
    removeDiscountMutation,
  } = useContext(DiscountsContext)
  const formattedPrice = (fixedOff / 100).toFixed(2)
  const formattedNumber = available
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")

  const handleClick = () => {
    const data = {
      id,
      name,
      code,
      fixed,
      fixedOff,
      percentOff,
      start,
      end,
      available,
      used,
      containers,
      active,
      updateDiscount: updateDiscountMutation,
      setDiscountCourses: setDiscountCoursesMutatation,
      removeDiscount: removeDiscountMutation,
      edit: true,
    }
    openDialog("createDiscount", data)
  }
  const handleExpiredCode = dayjs(end) < dayjs()
  const statusStyle = {
    color: handleExpiredCode ? "#FF5E69" : active ? "#29B117" : "#FF5E69",
  }
  if (!smDown) {
    return (
      <Box marginBottom={2}>
        <PaperListRow>
          <Box className={classes.container}>
            {/* start box 1 */}
            <Box width="20%" className={classes.boxOne}>
              <Typography
                variant="h4"
                color="primary"
                style={{ fontWeight: 600 }}
              >
                {name}
              </Typography>
            </Box>
            {/* end box 1
          
          start box 2 */}
            <Box className={classes.boxTwo}>
              <Box
                width="33%"
                display="flex"
                justifyContent="center"
                className={classes.pushLeft}
              >
                <Typography variant="subtitle1">{code}</Typography>
              </Box>
              <Box width="33%" display="flex" justifyContent="center">
                <Typography variant="subtitle1">
                  {fixed ? "Fixed" : "Percent"}
                </Typography>
              </Box>
              <Box
                width="33%"
                display="flex"
                justifyContent="center"
                className={classes.pushRight}
              >
                <Typography variant="subtitle1">
                  {fixedOff ? `$${formattedPrice}` : `${percentOff}%`}
                </Typography>
              </Box>
            </Box>
            {/* end box 2
  
          start box 3 */}
            <Box className={classes.boxThree}>
              <Box
                width="53%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                className={classes.pushLeft}
              >
                <Typography variant="subtitle1">{start}</Typography>
              </Box>
              <Box
                width="50%"
                display="flex"
                justifyContent="center"
                className={classes.pushRight}
              >
                <Typography variant="subtitle1">{end}</Typography>
              </Box>
            </Box>
            {/* end box 3
  
          start box 4 */}
            <Box className={classes.boxFour}>
              <Box
                width="50%"
                display="flex"
                justifyContent="center"
                className={classes.pushLeft}
              >
                <Typography variant="subtitle1">{formattedNumber}</Typography>
              </Box>
              <Box
                width="50%"
                display="flex"
                justifyContent="center"
                className={classes.pushRight}
              >
                <Typography variant="subtitle1">{used}</Typography>
              </Box>
            </Box>
            {/* end box 4
  
          start box 5 */}
            <Box
              width="10%"
              display="flex"
              justifyContent="center"
              className={classes.boxFive}
            >
              <Typography style={statusStyle} variant="subtitle1">
                {handleExpiredCode ? "Expired" : active ? "Active" : "Inactive"}
              </Typography>
            </Box>
            {/* end box 5
          
          start box 6 */}
            <Box
              width="5%"
              display="flex"
              justifyContent="center"
              className={classes.boxSix}
            >
              <Button color="secondary" onClick={handleClick} size="small">
                EDIT
              </Button>
            </Box>
            {/* end box 6
          
           */}
          </Box>
        </PaperListRow>
      </Box>
    )
  } else {
    return (
      <DiscountCardMobile
        name={name}
        active={active}
        code={code}
        fixed={fixed}
        fixedOff={fixedOff}
        formattedPrice={formattedPrice}
        percentOff={percentOff}
        start={start}
        end={end}
        formattedNumber={formattedNumber}
        used={used}
        handleClick={handleClick}
      />
    )
  }
}

DiscountCard.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  code: PropTypes.string,
  fixed: PropTypes.number,
  fixedOff: PropTypes.number,
  percentOff: PropTypes.number,
  start: PropTypes.string,
  end: PropTypes.string,
  available: PropTypes.number,
  used: PropTypes.number,
  containers: PropTypes.array,
  active: PropTypes.number,
}

export default DiscountCard
