import ActionBar from "@bit/c_t.components.action-bar"
import { Box, Button, InputAdornment, TextField } from "@material-ui/core"
import { Add, Search } from "@material-ui/icons"
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward"
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward"
import PropTypes from "prop-types"
import React from "react"
import sortDirectionUtil from "utils/sortDirection"
import sortTypeUtil from "utils/sortType"

import useStyles from "./styles"

const AdminActionBar = ({
  buttonName,
  buttonFunc,
  sortOptions,
  sortState,
  setSortState,
  setFilterState,
  searchText,
}) => {
  const classes = useStyles()

  //change the icon depends of the direction
  const iconFunc = (name) => {
    if (name === sortState.name && sortState.direction === "ASC") {
      return <ArrowUpwardIcon />
    } else if (name === sortState.name && sortState.direction === "DSC") {
      return <ArrowDownwardIcon />
    } else {
      return ""
    }
  }
  return (
    <ActionBar
      elevation={0}
      left={() => (
        <Button
          onClick={buttonFunc}
          variant="contained"
          color="secondary"
          startIcon={<Add fontSize="small" />}
        >
          {buttonName}
        </Button>
      )}
      right={() => (
        <Box display="flex" alignItems="center" height="100%">
          {sortOptions?.map((x) => (
            <Button
              key={x.id}
              color="secondary"
              style={{ margin: "12px" }}
              className={classes.actionButtons}
              onClick={() => {
                let sortType = sortTypeUtil(x.name.toLowerCase())
                setSortState((oldState) => ({
                  name: x.name.toLowerCase(),
                  type: sortType,
                  direction: sortDirectionUtil(
                    oldState?.name,
                    x.name.toLowerCase(),
                    sortState.direction
                  ),
                }))
              }}
              endIcon={iconFunc(x.name.toLowerCase())}
            >
              {x.name}
            </Button>
          ))}
          <TextField
            placeholder={searchText}
            className={classes.search}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                setFilterState(e.target.value)
              }
            }}
            onChange={(e) => e.target.value === "" && setFilterState("")}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start" style={{ marginLeft: "10px" }}>
                  <Search className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}
    />
  )
}

AdminActionBar.propTypes = {
  buttonName: PropTypes.string,
  buttonFunc: PropTypes.func,
  sortOptions: PropTypes.array,
  sortState: PropTypes.object,
  setSortState: PropTypes.func,
  filterState: PropTypes.object,
  setFilterState: PropTypes.func,
  searchText: PropTypes.string,
}

export default AdminActionBar
