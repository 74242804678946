import AppContext from "contexts/App"
import { useContext, useEffect } from "react"
import api from "store/api"

const Interceptor = () => {
  const { removeAuthToken } = useContext(AppContext)

  useEffect(() => {
    // adds response interceptor to API instance
    api.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        // in the case of a 401 status code, clear token in the App context
        if (error.response.status === 401) {
          removeAuthToken()
        }
        return Promise.reject(error)
      }
    )
  }, [])

  return null
}

export default Interceptor
