import { makeStyles } from "@material-ui/core/styles"
import avatar from "assets/person-add.svg"

const styles = makeStyles((theme) => ({
  uploadBox: {
    width: "100%",
    paddingTop: "56.25%",
    position: "relative",
  },
  dropzoneBox: {
    width: "100%",
    height: "100%",
    top: "0",
    position: "absolute",
    textAlign: "center",
    borderRadius: "8px",
    backgroundColor: "#D0D5DD",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    cursor: "pointer",
    overflow: "hidden",
  },
  withBorder: {
    "&:before": {
      content: '""',
      position: "absolute",
      top: "16px",
      left: "16px",
      bottom: "16px",
      right: "16px",
      zIndex: "1",
      borderRadius: "8px",
      [theme.breakpoints.down("lg")]: {
        top: "6px",
        right: "6px",
        bottom: "6px",
        left: "6px",
      },
    },
  },
  loadingBox: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0.25)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  avatarDropzoneBox: {
    width: "100%",
    height: "100%",
    top: "0",
    position: "absolute",
    textAlign: "center",
    borderRadius: "500px",
    backgroundColor: "#eaecee",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    cursor: "pointer",
    overflow: "hidden",
    // padding: "12px",
    // paddingTop: "24px",
  },
  avatarWithBorder: {
    "&:before": {
      content: '""',
      position: "absolute",
      top: "0px",
      left: "0px",
      bottom: "0px",
      right: "0",
      zIndex: "1",
      borderRadius: "500px",
      backgroundImage: `url("${avatar}")`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },
  },
  uploadTitle: {
    color: theme.palette.primary.main,
    [theme.breakpoints.down("lg")]: {
      marginBottom: 10,
    },
  },
  uploadText: {
    fontSize: "0.875rem",
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px",
    },
  },
}))

export default styles
