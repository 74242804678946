import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  container: {
    backgroundColor: "rgb(23, 165, 177, 0.3)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      textAlign: "center",
    },
  },
  text: {
    fontWeight: "bold",
    marginBottom: theme.spacing(4),
  },
  button: {
    color: theme.palette.primary.main,
    fontSize: "0.6875rem",
  },
}))

export default styles
