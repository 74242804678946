import DayjsUtils from "@date-io/dayjs"
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Typography,
} from "@material-ui/core"
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined"
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import AppContext from "contexts/App"
import CourseContext from "contexts/Course"
import dayjs from "dayjs"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { useQueryClient } from "react-query"

import useStyles from "./styles"

const DateTime = ({ disablePicker }) => {
  // disablePicker gives T if course is published, unpublished, or archived - only available to draft

  const classes = useStyles()

  const {
    // setDebouncedDetails,
    // debouncedDetails,
    dateValue,
    setDateValue,
    tryPublish,
    setTryPublish,
    setPublishDateFunc,
    disabledSave,
    course,
  } = useContext(CourseContext)

  const { openDialog, closeDialog } = useContext(AppContext)

  const handleClose = () => {
    closeDialog("confirmationTypingDialog", true)
  }

  const handleDateSelection = (e) => {
    let data = {
      title: "Confirm Publish Date",
      subtitle: "",
      type: "publish",
      confirmWord: "ship it",
    }
    openDialog("confirmationTypingDialog", data, false).then(() => {
      setPublishDateFunc(e)
      setDateValue(e)
      handleClose()
    })
  }

  const handleClearDate = () => {
    let data = {
      title: "Confirm Cancel Scheduled Publish Date",
      subtitle: "",
      type: "schedule",
      confirmWord: "delete",
    }
    openDialog("confirmationTypingDialog", data, false).then(() => {
      setPublishDateFunc(null)
      setDateValue(null)
      setTryPublish(false)
      handleClose()
    })
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap"
    >
      <MuiPickersUtilsProvider utils={DayjsUtils}>
        <Box>
          <Typography color="secondary" className={classes.inputLabel}>
            {!course.published ? "Publish Date" : "Published Date"}
          </Typography>

          <Box display="flex" flexDirection="row">
            <DateTimePicker
              classes={{
                root: classes.root,
              }}
              value={dateValue}
              onChange={(e) => handleDateSelection(e)}
              disabled={disabledSave || disablePicker}
              inputVariant="filled"
              DialogProps={{ maxWidth: "xs" }}
              className={classes.publish}
              format="MMMM DD YYYY  hh:mm A"
              size="small"
              disablePast
              minDateMessage=""
            />
            {dateValue && !disablePicker ? (
              <Button
                variant="outlined"
                classes={{ root: classes.buttonRoot }}
                onClick={handleClearDate}
                style={{ marginLeft: "16px", height: "32px" }}
              >
                Cancel
              </Button>
            ) : null}
          </Box>
        </Box>
      </MuiPickersUtilsProvider>
    </Box>
  )
}

DateTime.propTypes = {
  disablePicker: PropTypes.bool,
}

export default DateTime
