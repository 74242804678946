import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    maxWidth: 320,
  },
  title: {
    fontSize: "0.8125rem",
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  explanation: {
    color: "#fff",
    marginBottom: theme.spacing(5),
  },
  textField: {
    marginBottom: theme.spacing(1),
  },
  inputBackgroundColor: {
    backgroundColor: "#fff",
  },
  formHelperText: {
    marginLeft: 0,
  },
  forgotPasswordText: {
    color: "#fff",
    cursor: "pointer",
    position: "absolute",
    bottom: 5,
    right: 0,
    fontSize: "11px",
    fontWeight: "bold",
  },
  buttonRoot: {
    height: "40px",
    marginLeft: theme.spacing(2),
  },
}))

export default styles
