import {
  Box,
  DialogTitle as MUIDialogTitle,
  Typography,
} from "@material-ui/core"
import useTimeFormat from "hooks/useTimeFormat"
import PropTypes from "prop-types"

import useStyles from "./styles"

const DialogTitle = ({
  title = "",
  subtitle = "",
  icon,
  timestamp,
  ...props
}) => {
  const classes = useStyles()

  const formattedTimestamp = useTimeFormat(timestamp)

  return (
    <MUIDialogTitle
      {...props}
      disableTypography
      className={classes.dialogTitle}
    >
      <Box>
        <Typography
          variant="h6"
          component="h2"
          style={{ fontWeight: 700, marginBottom: 8 }}
        >
          {title} {icon}
        </Typography>
        {subtitle && (
          <Typography style={{ fontSize: "0.8125rem", color: "#808080" }}>
            {subtitle}
          </Typography>
        )}
      </Box>
      <Box>
        <Typography variant="h6" component="h3" style={{ color: "#808080" }}>
          {formattedTimestamp}
        </Typography>
      </Box>
    </MUIDialogTitle>
  )
}

DialogTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.node,
  timestamp: PropTypes.string,
}

export default DialogTitle
