import { Box, Button } from "@material-ui/core"
import classnames from "clsx"
import { humanize } from "inflection"
import PropTypes from "prop-types"
import React, { useMemo, useState } from "react"

import Terminal from "../Terminal"
import useStyles from "./styles"

const TerminalContainer = ({ width, height = 200 }) => {
  const classes = useStyles()

  const [frames] = useState([
    { id: 1, name: "terminal", type: "terminal" },
    { id: 2, name: "terminal", type: "terminal" },
  ])
  const [activeTab, setActiveTab] = useState(0)

  const scrollOffset = useMemo(() => {
    return `${width * activeTab}`
  }, [activeTab, width])

  return (
    <Box className={classes.root} height={height}>
      <Box className={classes.tabs} width="100%">
        {frames?.map?.((f, idx) => (
          <Button
            key={f.id}
            size="small"
            onClick={() => setActiveTab(idx)}
            className={classnames(classes.tab, {
              [classes.active]: activeTab === idx,
            })}
          >
            {humanize(f.name)}
          </Button>
        ))}
      </Box>
      <Box className={classes.scroller}>
        <Box
          className={classes.framesWrapper}
          width={`calc(${width}px * ${frames?.length || 1})`}
          style={{
            transform: `translateX(-${scrollOffset}px)`,
          }}
        >
          {frames?.map?.((frame) => (
            <Box
              key={frame.id}
              bgcolor="#000"
              width={width}
              height="100%"
              style={{
                overflow: "hidden",
              }}
            >
              {frame.type === "terminal" && <Terminal />}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

TerminalContainer.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
}

export default TerminalContainer
