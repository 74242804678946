import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  inputBackgroundColor: {
    backgroundColor: "#F4F4F4",
  },
  subtitle: {
    fontWeight: 600,
    fontSize: "13px",
  },
  actions: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(15),
    },
  },
}))

export default styles
