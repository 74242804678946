import api from "store/api"
import serialize from "store/serialize"

export const listInstructors = (data) => {
  return api
    .get("/instructors", data)
    .then((res) => serialize("instructor", res.data.data))
}
export const createInstructor = (data) => {
  return api.post("/instructors", data).then((res) => res.data)
}

export const updateInstructor = (data, id) => {
  return api.put(`/instructors/${id}`, data).then((res) => res.data)
}

export const removeInstructor = (id) => {
  return api.delete(`instructors/${id}`).then((res) => res.data)
}
