import DayjsUtils from "@date-io/dayjs"
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core"
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined"
import DeleteForeverIcon from "@material-ui/icons/DeleteForever"
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle"
import { Autocomplete } from "@material-ui/lab"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import MaskedSeats from "components/Clients/MaskedSeats"
import AccessCodeVideoUpload from "components/Common/AccessCodeVideoUpload"
import CounterHeader from "components/Common/CounterHeader"
import LogoUpload from "components/Common/LogoUpload"
import AppContext from "contexts/App"
import dayjs from "dayjs"
import { Form, Formik } from "formik"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useRef, useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import ReactPlayer from "react-player"
import * as Yup from "yup"

import useStyles from "./styles"

const AccessCodeCard = ({
  accessCode,
  courses,
  accessCodeArray,
  setAccessCodeArray,
  saveClicked,
  setVideoLoading,
}) => {
  const classes = useStyles()
  const formRef = useRef()
  const defaultExpYear = dayjs().year() + 1
  const defaultExpiration = dayjs().year(defaultExpYear).hour(23).minute(59)
  const [clearTextField, setClearTextField] = useState(false)
  const { closeDialog, openDialog, openSnackBar } = useContext(AppContext)
  const [uploadImg, setUploadImg] = useState(null)
  const [uploadVideo, setUploadVideo] = useState(null)

  const handleClose = () => {
    closeDialog("confirmationTypingDialog", false)
  }

  const schema = Yup.object().shape({
    code: Yup.string()
      .test(
        "len",
        "Access code hast to be at least 5 characters length",
        (val) => val?.length >= 5
      )
      .required(`Access Code is required`),
    is_active: Yup.string(),
    seats: Yup.number()
      .moreThan(0)
      .required("Total Seats Availabe is required"),
    course_containers: Yup.array().test({
      message: "Select at least one course",
      test: (arr) => arr.length > 0,
    }),
    description: Yup.string()
      .required("Welcome Description is required")
      .max(400, "Description must be less than 400 characters"),
    image: Yup.string().required("A logo is required"),
    amazon_sso: Yup.bool(),
    facebook_sso: Yup.bool(),
    github_sso: Yup.bool(),
    google_sso: Yup.bool(),
    video: Yup.string().nullable(true),
  })

  const changeCode = (values) => {}

  useEffect(() => {
    // on page load, handles addition of ref object to each access code
    if (
      formRef &&
      formRef.current &&
      !accessCodeArray.find(
        (x, ii) => x.key === accessCode.key && x.ref && x.ref.current
      )
    ) {
      setAccessCodeArray((codes) =>
        codes.map((x, ii) =>
          x.key === accessCode.key ? { ...x, ref: formRef } : x
        )
      )
    }
  }, [formRef, formRef.current])

  const removeCode = () => {
    setAccessCodeArray((codes) =>
      codes.filter((x) => {
        return accessCode.key !== x.key
      })
    )
  }

  const handleRemove = () => {
    let data = {
      title: "Remove Access Code",
      subtitle: "",
      type: "client",
      id: accessCode?.id,
      confirmWord: "delete",
    }
    if (accessCode?.id) {
      openDialog("confirmationTypingDialog", data)
        .then(() => removeCode())
        .then(() => handleClose)
    } else {
      removeCode()
    }
  }

  const setLogo = (uploadRes, loadingAction, setFieldValue) => {
    setUploadImg(uploadRes.data)
    const image = JSON.stringify({
      url: uploadRes.data.url,
      secure_url: uploadRes.data.secure_url,
      originial_filename: uploadRes.data.original_filename,
      version: uploadRes.data.version,
      format: uploadRes.data.format,
      public_id: uploadRes.data.public_id,
    })
    setFieldValue("image", image)
    loadingAction(false)
  }

  const appLink = `${process.env.REACT_APP_APP_URL}/access-code?access_code=${formRef?.current?.values?.code}`

  return (
    <Box
      marginBottom="20px"
      border="1px solid #F4F4F4"
      padding="20px 10px"
      key={accessCode.key}
    >
      <Formik
        initialValues={{
          id: accessCode.id || undefined,
          code: accessCode?.code || "",
          is_active: accessCode?.is_active ? "active" : "inactive",
          seats: accessCode?.seats || "",
          expiration: accessCode.expiration
            ? dayjs(accessCode.expiration)
            : defaultExpiration,
          course_containers: accessCode?.courses || [],
          description: accessCode?.description || "",
          image: accessCode?.image || "",
          amazon_sso: accessCode?.amazon_sso === 0 ? false : true,
          facebook_sso: accessCode?.facebook_sso === 0 ? false : true,
          github_sso: accessCode?.github_sso === 0 ? false : true,
          google_sso: accessCode?.google_sso === 0 ? false : true,
          video: accessCode?.video || null,
        }}
        onSubmit={changeCode}
        validationSchema={schema}
        innerRef={formRef}
      >
        {({
          handleChange,
          values,
          errors,
          touched,
          handleBlur,
          handleSubmit,
          setFieldValue,
          dirty,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box>
              <Box position="relative">
                <Box position="absolute" right={-10} top={-20} zIndex={100}>
                  <Tooltip
                    title={accessCode.uses > 0 ? "Access Code in use" : ""}
                  >
                    <Box>
                      <Button
                        disabled={accessCode.uses > 0}
                        color="secondary"
                        onClick={handleRemove}
                        className={classes.removeCodeButton}
                      >
                        Delete
                      </Button>
                    </Box>
                  </Tooltip>
                  {/* <IconButton onClick={removeCode}>
                    <RemoveCircleIcon className={classes.removeCodeButton} />
                  </IconButton> */}
                </Box>

                <Box className={classes.topRow}>
                  <Box width="50%" className={classes.codeNameBox}>
                    <Typography color="secondary" className={classes.title}>
                      Access Code
                    </Typography>
                    <TextField
                      type="text"
                      name="code"
                      id="code"
                      value={values.code}
                      variant="filled"
                      size="small"
                      onChange={handleChange}
                      className={classes.textField}
                      onBlur={handleBlur}
                      error={touched.code && errors.code}
                      helperText={touched.code && errors.code}
                      FormHelperTextProps={{
                        className: classes.formHelperText,
                      }}
                    />
                  </Box>
                  <Box width="40%" classes={{ root: classes.radioBox }}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        name="is_active"
                        value={values.is_active}
                        onChange={handleChange}
                      >
                        <Box
                          display="flex"
                          marginTop="30px"
                          className={classes.radioSubBox}
                        >
                          <FormControlLabel
                            value="active"
                            control={
                              <Radio
                                size="small"
                                classes={{ checked: classes.active }}
                                color="default"
                              />
                            }
                            label="Active"
                          />
                          <FormControlLabel
                            value="inactive"
                            control={
                              <Radio
                                size="small"
                                classes={{ checked: classes.inactive }}
                                color="default"
                              />
                            }
                            label="Inactive"
                          />
                        </Box>
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  className={classes.rowTwo}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    width="30%"
                    className={classes.rowTwoBox}
                  >
                    <Typography color="secondary" className={classes.title}>
                      Total seats available
                    </Typography>
                    <TextField
                      name="seats"
                      id="seats"
                      value={values.seats}
                      variant="filled"
                      size="small"
                      InputProps={{
                        inputComponent: MaskedSeats,
                      }}
                      onChange={handleChange}
                      className={classes.textField}
                      onBlur={handleBlur}
                      helperText={touched.seats && errors.seats}
                      error={touched.seats && errors.seats}
                      FormHelperTextProps={{
                        className: classes.formHelperText,
                      }}
                    />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    width="30%"
                    className={classes.rowTwoBox}
                  >
                    <Typography color="secondary" className={classes.title}>
                      Active seats
                    </Typography>
                    <TextField
                      disabled
                      defaultValue={accessCode.uses}
                      InputProps={{
                        inputComponent: MaskedSeats,
                        classes: { root: classes.whiteBackgroundColor },
                      }}
                      variant="filled"
                      size="small"
                    />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    width="30%"
                    className={classes.rowTwoBox}
                  >
                    <Typography color="secondary" className={classes.title}>
                      Expiration Date
                    </Typography>
                    <MuiPickersUtilsProvider utils={DayjsUtils}>
                      <DatePicker
                        value={values.expiration}
                        onChange={(value) => {
                          setFieldValue("expiration", value)
                        }}
                        disablePast
                        inputVariant="filled"
                        className={classes.expiration}
                        size="small"
                        format="MM/DD/YYYY"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <CalendarTodayOutlinedIcon
                                fontSize="inherit"
                                className={classes.calendarIcon}
                              />
                            </InputAdornment>
                          ),
                        }}
                        DialogProps={{
                          classes: { dialogRoot: classes.dialogWidth },
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Box>
                </Box>

                <Typography color="secondary" className={classes.title}>
                  Select Course
                </Typography>
                <Autocomplete
                  id="courses"
                  name="course_containers"
                  size="small"
                  options={courses || []}
                  getOptionLabel={(option) => option.name || ""}
                  getOptionDisabled={(option) =>
                    !!values?.course_containers?.find((x) => x.id === option.id)
                  }
                  renderInput={(params) => {
                    if (clearTextField) {
                      params.inputProps.value = ""
                    }

                    return (
                      <TextField
                        {...params}
                        variant="filled"
                        helperText={
                          touched.course_containers && errors.course_containers
                        }
                        error={
                          touched.course_containers && errors.course_containers
                        }
                        FormHelperTextProps={{
                          className: classes.formHelperText,
                        }}
                        onChange={(val) => {
                          setClearTextField(false)
                        }}
                      />
                    )
                  }}
                  onChange={(e, val) => {
                    setFieldValue("course_containers", [
                      ...values.course_containers,
                      val,
                    ])
                    setClearTextField(true)
                  }}
                  openOnFocus
                  disableClearable
                  classes={{ inputRoot: classes.grayBackgroundColor }}
                />

                {values?.course_containers?.map((course, i) => {
                  return (
                    <Box
                      marginTop={1}
                      minHeight={32}
                      maxHeight={64}
                      border="1px solid #17A5B1"
                      borderRadius="5px"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      key={i}
                    >
                      <Typography
                        variant="subtitle1"
                        style={{
                          fontWeight: 600,
                          marginLeft: "8px",
                          overflowWrap: "break-word",
                        }}
                        color="secondary"
                      >
                        {course.name}
                      </Typography>
                      <IconButton
                        classes={{ root: classes.iconButton }}
                        styles={{ padding: 0 }}
                        onClick={() => {
                          let newCoursesArr = values.course_containers.filter(
                            (x) => x.id !== course?.id
                          )
                          setFieldValue("course_containers", newCoursesArr)
                        }}
                        className={classes.removeCourseIcon}
                      >
                        <RemoveCircleIcon
                          color="inherit"
                          className={classes.removeCourseButton}
                        />
                      </IconButton>
                    </Box>
                  )
                })}
                <Box marginTop={2}>
                  <CounterHeader
                    title="Welcome Description"
                    value={values.description}
                    max={400}
                  />
                  <TextField
                    type="text"
                    name="description"
                    id="description"
                    value={values.description}
                    variant="filled"
                    size="small"
                    multiline
                    rows={4}
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.description && errors.description}
                    helperText={touched.description && errors.description}
                    FormHelperTextProps={{
                      className: classes.formHelperText,
                    }}
                    InputProps={{
                      classes: {
                        root: classes.descriptionInput,
                      },
                    }}
                  />
                  <Box
                    className={classes.ssoContainer}
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                    flexWrap="wrap"
                  >
                    <Box width="48%">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.amazon_sso}
                            onChange={() =>
                              setFieldValue("amazon_sso", !values?.amazon_sso)
                            }
                            name="amazon_sso"
                          />
                        }
                        label="Amazon SSO"
                      />
                    </Box>
                    <Box width="48%">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.facebook_sso}
                            onChange={() =>
                              setFieldValue(
                                "facebook_sso",
                                !values?.facebook_sso
                              )
                            }
                            name="facebook_sso"
                          />
                        }
                        label="Facebook SSO"
                      />
                    </Box>
                    <Box width="48%">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.github_sso}
                            onChange={() =>
                              setFieldValue("github_sso", !values?.github_sso)
                            }
                            name="github_sso"
                          />
                        }
                        label="Github SSO"
                      />
                    </Box>
                    <Box width="48%">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.google_sso}
                            onChange={() =>
                              setFieldValue("google_sso", !values?.google_sso)
                            }
                            name="google_sso"
                          />
                        }
                        label="Google SSO"
                      />
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Box width="48%">
                      <Typography color="secondary" className={classes.title}>
                        Logo
                      </Typography>
                      <LogoUpload
                        cloudinaryUploadPreset={
                          process.env
                            .REACT_APP_CLOUDINARY_CLOUD_THUMBNAIL_UPLOAD
                        }
                        value={values.image}
                        uploadImg={uploadImg}
                        onUpload={setLogo}
                        setFieldValue={setFieldValue}
                      />
                      {saveClicked && errors.image ? (
                        <Typography className={classes.imgError}>
                          {errors.image}
                        </Typography>
                      ) : null}
                    </Box>
                    <Box width="48%">
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        position="relative"
                        top="50%"
                      >
                        <TextField
                          disabled
                          id="link"
                          name="link"
                          variant="filled"
                          value={values?.code}
                          style={{
                            width: "85%",
                            height: "100%",
                            marginRight: "8px",
                          }}
                        />
                        <CopyToClipboard
                          text={appLink}
                          onCopy={() =>
                            openSnackBar({ message: "Link copied" })
                          }
                          options={{ format: "text/plain" }}
                        >
                          <Button
                            variant="contained"
                            color="secondary"
                            disableElevation
                            size="small"
                            disabled={formRef?.current?.dirty}
                            classes={{
                              root: classes.copyButton,
                              label: classes.copyLabel,
                            }}
                            style={
                              values?.code?.length === 0
                                ? { display: "none" }
                                : null
                            }
                          >
                            Copy
                            {/* <img src={copy} alt="copy" /> */}
                          </Button>
                        </CopyToClipboard>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box marginTop="20px">
                {uploadVideo ? (
                  <Box>
                    <ReactPlayer
                      className="react-player fixed-bottom"
                      url={uploadVideo}
                      width="100%"
                      height="100%"
                      controls={true}
                    />
                    <Box
                      display="flex"
                      justifyContent="center"
                      marginTop="10px"
                    >
                      <Button
                        onClick={() => {
                          setUploadVideo(null)
                          setFieldValue("video", null)
                        }}
                      >
                        Remove Video
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <AccessCodeVideoUpload
                    setUploadVideo={setUploadVideo}
                    uploadVideo={uploadVideo}
                    cloudinaryUploadPreset={
                      process.env.REACT_APP_CLOUDINARY_CLOUD_THUMBNAIL_UPLOAD
                    }
                    setFieldValue={setFieldValue}
                    value={values.video}
                    setVideoLoading={setVideoLoading}
                  />
                )}
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

AccessCodeCard.propTypes = {
  accessCode: PropTypes.object,
  courses: PropTypes.array,
  accessCodeArray: PropTypes.array,
  setAccessCodeArray: PropTypes.func,
  open: PropTypes.bool,
  saveClicked: PropTypes.bool,
  setVideoLoading: PropTypes.func,
}

export default AccessCodeCard
