import { Box, Button, TextField, Typography } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import CounterHeader from "components/Common/CounterHeader"
import LabContext from "contexts/Lab"
import LabsContext from "contexts/Labs"
import PropTypes from "prop-types"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import useDeepCompareEffect from "use-deep-compare-effect"
import { useDebounce } from "use-lodash-debounce"

import useStyles from "./styles"

const images = [
  {
    name: "Default Image",
    value: "default",
  },
  {
    name: "Intel Image",
    value: "intel_image",
  },
]

const LabDetails = ({ tags }) => {
  const classes = useStyles()

  const {
    lab,
    debouncedDetails,
    setDebouncedDetails,
    labDetailsValues,
    setLabDetailsValues,
    tryPublish,
    lengthError,
    setInitialDetailsContext,
  } = useContext(LabContext)

  const { instanceTypes } = useContext(LabsContext)

  const detailsLocked = useMemo(() => {
    // use lab properties to determine if details should be locked
    return false
  }, [])

  const selectedImage = useMemo(() => {
    return images?.find?.((s) => s.value === labDetailsValues?.image) || null
  }, [labDetailsValues?.image])

  const debouncedLocalValues = useDebounce(labDetailsValues, 300)

  //sets lesson details to context
  useDeepCompareEffect(() => {
    if (lab?.id) {
      setLabDetailsValues({
        name: lab?.name,
        description: lab?.description,
        instance_type: lab?.instance_type,
        image: lab?.image,
      })
      setInitialDetailsContext({
        name: lab.name,
        description: lab.description || "",
        instance_type: lab?.instance_type,
        image: lab?.image,
      })
    }
  }, [lab])

  useEffect(() => {
    setDebouncedDetails(labDetailsValues)
  }, [debouncedLocalValues])

  const descriptionError =
    ((debouncedDetails?.description === "" ||
      debouncedDetails?.description?.length > 2000) &&
      tryPublish) ||
    (lengthError && debouncedDetails?.description?.length > 2000)

  const nameError =
    ((debouncedDetails?.name === "" || debouncedDetails?.name?.length > 50) &&
      tryPublish) ||
    (lengthError && debouncedDetails?.name?.length > 50)

  const serverTypeError = debouncedDetails?.instance_type === "" && tryPublish

  const imageError = debouncedDetails?.image === "" && tryPublish

  const handleSelectImage = useCallback(
    (_e, val) => {
      if (!detailsLocked) {
        setLabDetailsValues((oldState) => ({
          ...oldState,
          image: val?.value,
        }))
      }
    },
    [detailsLocked, setLabDetailsValues]
  )

  const handleSelectServerType = useCallback(
    (_e, val) => {
      if (!detailsLocked) {
        setLabDetailsValues((oldState) => ({
          ...oldState,
          instance_type: val,
        }))
      }
    },
    [detailsLocked, setLabDetailsValues]
  )

  const handleStartServer = useCallback(() => {
    if (!detailsLocked) {
      console.log("start server")
    }
  }, [detailsLocked])

  return (
    <Box className={classes.container}>
      {detailsLocked && (
        <Box className={classes.publishedBanner}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography className={classes.bannerTitle} variant="subtitle1">
              The server has started. Server type and Image can no longer be
              edited
            </Typography>
            <Typography variant="subtitle1" className={classes.subtitle}>
              To update this content, you must create a new lab
            </Typography>
          </Box>
        </Box>
      )}
      <Box className={classes.inner}>
        <Typography variant="h3" color="primary" className={classes.title}>
          Lab Details
        </Typography>
        <CounterHeader
          title="Lab Name"
          value={debouncedDetails?.name}
          max={50}
        />

        <TextField
          style={{ marginBottom: 16 }}
          value={labDetailsValues?.name || ""}
          onChange={(e) => {
            setLabDetailsValues((oldState) => ({
              ...oldState,
              name: e.target.value,
            }))
          }}
          InputProps={{
            classes: {
              root: classes.inputBackgroundColor,
            },
          }}
          variant="filled"
          size="small"
          fullWidth
          disabled={lab.locked}
        />
        {nameError ? (
          <Typography className={classes.requiredText}>
            Name is required and it has to be less than 50 characters
          </Typography>
        ) : null}
        <CounterHeader
          title="Lab Description"
          value={debouncedDetails?.description}
          max={2000}
        />
        <TextField
          value={labDetailsValues.description || ""}
          multiline
          rows={10}
          onChange={(e) => {
            setLabDetailsValues((oldState) => ({
              ...oldState,
              description: e.target.value,
            }))
          }}
          InputProps={{
            disableUnderline: true,
            classes: {
              root: classes.inputDescription,
            },
          }}
          size="small"
          fullWidth
          disabled={lab.locked}
        />
        {descriptionError ? (
          <Typography className={classes.requiredText}>
            Description is required and it has to be less than 2000 characters
          </Typography>
        ) : null}
        <Box position="relative">
          <Typography color="secondary" className={classes.inputLabel}>
            Server Type
          </Typography>
          <Autocomplete
            size="small"
            value={lab?.instance_type}
            options={instanceTypes}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select" variant="filled" />
            )}
            onChange={handleSelectServerType}
            openOnFocus
            disableClearable
            disabled={lab?.locked}
            classes={{
              inputRoot: classes.autocompleteRoot,
            }}
          />
          {serverTypeError ? (
            <Typography className={classes.requiredText}>
              Server type is required
            </Typography>
          ) : null}
          <Typography color="secondary" className={classes.inputLabel}>
            Build from Image
          </Typography>
          <Autocomplete
            size="small"
            value={selectedImage}
            options={images || []}
            getOptionLabel={(option) => option.name || ""}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select" variant="filled" />
            )}
            onChange={handleSelectImage}
            openOnFocus
            disableClearable
            disabled
            classes={{
              inputRoot: classes.autocompleteRoot,
            }}
          />
          {imageError ? (
            <Typography className={classes.requiredText}>
              An image is required
            </Typography>
          ) : null}
          <Box marginTop={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleStartServer}
              disabled
            >
              Start Server
            </Button>
          </Box>
          {detailsLocked && <Box className={classes.publishedOverlay} />}
        </Box>
      </Box>
    </Box>
  )
}

LabDetails.propTypes = {
  tags: PropTypes.array,
}

export default LabDetails
