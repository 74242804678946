import { Box, IconButton, Tooltip, Typography } from "@material-ui/core"
import { GetApp } from "@material-ui/icons"
import AppContext from "contexts/App"
import UsersContext from "contexts/Users"
import dayjs from "dayjs"
import MaterialTable from "material-table"
import Page from "pages/Page"
import PropTypes from "prop-types"
import React, { useContext, useEffect } from "react"
import AutoSizer from "react-virtualized-auto-sizer"
import Router from "router"
import * as FileSaver from "file-saver"
import { Parser } from "json2csv"
import useStyles from "./styles"

const Waitlist = ({ routes = [], ...rest }) => {
  const { waitlist } = useContext(UsersContext)
  const classes = useStyles()
  const { setPageSubTitle, setPageTitle, openSnackBar } = useContext(AppContext)

  const downloadWaitlist = () => {
    const fields = ["created", "email", "id", "updated"]
    const opts = { fields }

    try {
      const parser = new Parser(opts)
      const csv = parser.parse(waitlist)
      var file = new Blob([csv], { type: "text/csv" })
      FileSaver.saveAs(file, `admins.csv`)
    } catch (err) {
      openSnackBar({ message: "Error download waitlist data" })
    }
  }

  const DownloadIcon = () => {
    return (
      <Box>
        <Tooltip title="Download data" placement="top">
          <IconButton onClick={downloadWaitlist}>
            <GetApp color="secondary" />
          </IconButton>
        </Tooltip>
      </Box>
    )
  }

  //set admin  subtitle number
  useEffect(() => {
    setPageTitle("Waitlist")
    setPageSubTitle(waitlist?.length)
  }, [])

  const headCells = [
    {
      title: "EMAIL",
      field: "email",
      headerStyle: {
        width: "42%",
        maxWidth: "42%",
      },
      cellStyle: {
        width: "42%",
        maxWidth: "42%",
      },
      render: function Email(rowData) {
        return (
          <>
            <Typography variant="subtitle1">{rowData.email}</Typography>
          </>
        )
      },
    },
    {
      title: "CREATED",
      field: "created",
      headerStyle: {
        width: "42%",
        maxWidth: "42%",
      },
      cellStyle: {
        width: "42%",
        maxWidth: "42%",
      },
      render: function createdData(rowData) {
        return (
          <Typography variant="subtitle1">
            {dayjs(rowData?.created)?.format("M/D/YYYY")}
          </Typography>
        )
      },
    },
    {
      title: <DownloadIcon />,
      sorting: false,
      headerStyle: {
        width: "4%",
        maxWidth: "4%",
      },
      cellStyle: {
        width: "4%",
        maxWidth: "4%",
      },
    },
  ]

  return (
    <Page>
      <AutoSizer
        style={{ width: "100%", height: "80%" }}
        className={classes.tableContainer}
      >
        {({ width, height }) => {
          return (
            <MaterialTable
              // isLoading={loading}
              // loadingType="linear"
              options={{
                toolbar: false,
                title: "",
                headerStyle: {
                  backgroundColor: "#F5F5F5",
                  borderBottom: "2px solid #17A5B1",
                  position: "sticky",
                  top: 0,
                  whiteSpace: "nowrap",
                  width: "100%",
                  fontSize: "12px",
                  fontWeight: "bold",
                  height: "80px",
                  padding: "5px 10px",
                },

                maxBodyHeight: height - 200,
                pageSize: 25,
                pageSizeOptions: [10, 25, 50, 100],
                rowStyle: (rowData, i) => ({
                  backgroundColor: i % 2 !== 0 ? "#fff" : "#F5F5F5",
                  maxHeight: "25px",
                  height: "25px",
                  padding: "5px 10px",
                }),
              }}
              classses={{ root: classes.root }}
              columns={headCells}
              data={waitlist}
            />
          )
        }}
      </AutoSizer>
      <Router routes={routes} {...rest} />
    </Page>
  )
}
Waitlist.propTypes = {
  routes: PropTypes.array,
}
export default Waitlist
