import { Box, Typography } from "@material-ui/core"
import { requestSignedUrl } from "actions/upload"
import { uploadThumbnail } from "actions/upload"
import { createVideo } from "actions/upload"
import FileUpload from "components/Common/FileUpload"
import AppContext from "contexts/App"
import PropTypes from "prop-types"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { useHistory } from "react-router"

import LinearProgressWithLabel from "../LinearProgressWithLabel"

const AccessCodeVideoUpload = ({
  setUploadVideo,
  uploadVideo,
  cloudinaryUploadPreset,
  setFieldValue,
  value,
  setVideoLoading,
}) => {
  const { openSnackBar } = useContext(AppContext)
  const history = useHistory()

  const [uploadProgress, setUploadProgress] = useState(0)
  const [showLoading, setShowLoading] = useState(false)

  const resetState = () => {
    setUploadProgress(0)
    setShowLoading(false)
  }

  const onDrop = useCallback((acceptedFiles) => {
    setShowLoading(true)
    setVideoLoading(true)
    uploadThumbnail(acceptedFiles[0], cloudinaryUploadPreset)
      .then((res) => {
        resetState()
        setUploadVideo(res?.data?.url)
        setShowLoading(false)
        setFieldValue("video", JSON.stringify(res.data))
        setVideoLoading(false)
      })
      .catch((err) => {
        setShowLoading(false)
        setVideoLoading(false)
        console.error(err)
      })
  }, [])

  useEffect(() => {
    if (value !== null) {
      const video = JSON.parse(value)
      setUploadVideo(video?.url)
    }
  }, [value])

  const onError = useCallback((rejectedFiles) => {
    openSnackBar({
      message:
        rejectedFiles[0].errors[0].code === "file-invalid-type"
          ? "You can only upload a video file here"
          : "There was an error uploading that file",
    })
  }, [])

  return (
    <>
      <FileUpload
        title="Drop Access Code Video here"
        DropZoneProps={{ accept: "video/*" }}
        onDrop={onDrop}
        onError={onError}
        showLoading={showLoading}
      ></FileUpload>
      <Box textAlign="center" marginTop={2}>
        <Typography color="textSecondary">
          Video files should be under 100mb
        </Typography>
      </Box>
      {uploadProgress > 0 && (
        <LinearProgressWithLabel value={uploadProgress} color="secondary" />
      )}
    </>
  )
}

AccessCodeVideoUpload.propTypes = {
  setUploadVideo: PropTypes.func,
  setFieldValue: PropTypes.func,
  uploadVideo: PropTypes.string,
  cloudinaryUploadPreset: PropTypes.string,
  value: PropTypes.string,
  setVideoLoading: PropTypes.func,
}

export default AccessCodeVideoUpload
