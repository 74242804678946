import { Box } from "@material-ui/core"
import { uploadThumbnail } from "actions/upload"
import EditIcon from "assets/Icons/edit-icon.svg"
import { Image, Transformation } from "cloudinary-react"
import AppContext from "contexts/App"
import PropTypes from "prop-types"
import { useCallback, useContext, useState } from "react"
import { useEffect } from "react"

import FileUpload from "../FileUpload"
import useStyles from "./styles"

const LogoUpload = ({
  cloudinaryUploadPreset,
  onUpload,
  value,
  setFieldValue,
  uploadImg,
}) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [img, setImg] = useState(null)

  const { openDialog } = useContext(AppContext)

  const onDrop = useCallback(
    (acceptedFiles) => {
      setLoading(true)
      uploadThumbnail(acceptedFiles[0], cloudinaryUploadPreset)
        .then((uploadRes) => onUpload(uploadRes, setLoading, setFieldValue))
        .catch((err) => {
          setLoading(false)
          console.error(err)
        })
    },
    [cloudinaryUploadPreset, onUpload]
  )

  useEffect(() => {
    if (value) {
      const image = JSON.parse(value)
      setImg(image)
    }
  }, [value])

  if (img) {
    return (
      <FileUpload title="Drop logo here" onDrop={onDrop} showLoading={loading}>
        {img?.public_id && (
          <Box className={classes.thumbnailBox}>
            <Box className={classes.editBox}>
              <img src={EditIcon} alt="Edit thumbnail" style={{ width: 24 }} />
            </Box>
            <Image
              publicId={img.public_id}
              style={{ verticalAlign: "bottom", width: "100%" }}
            >
              {/* <Transformation
                width="335"
                crop="fill"
                gravity="auto"
                aspectRatio="16:9"
              /> */}
            </Image>
          </Box>
        )}
      </FileUpload>
    )
  } else {
    return (
      <FileUpload title="Drop logo here" onDrop={onDrop} showLoading={loading}>
        {uploadImg?.public_id && (
          <Box className={classes.thumbnailBox}>
            <Box className={classes.editBox}>
              <img src={EditIcon} alt="Edit thumbnail" style={{ width: 24 }} />
            </Box>
            <Image
              publicId={uploadImg.public_id}
              style={{ verticalAlign: "bottom", width: "100%" }}
            >
              {/* <Transformation
                width="335"
                crop="fill"
                gravity="auto"
                aspectRatio="16:9"
              /> */}
            </Image>
          </Box>
        )}
      </FileUpload>
    )
  }
}

LogoUpload.propTypes = {
  cloudinaryUploadPreset: PropTypes.string,
  onUpload: PropTypes.func.isRequired,
  value: PropTypes.string,
  setFieldValue: PropTypes.func,
  uploadImg: PropTypes.object,
}

export default LogoUpload
