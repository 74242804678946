import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  container: {
    marginLeft: theme.spacing(2),
    maxHeight: "calc(100% - 70px)",
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      maxHeight: "calc(100% - 120px)",
    },
  },
}))

export default styles
