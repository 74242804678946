import { createCourse } from "actions/courses"
import { listCourses } from "actions/courses"
import PropTypes from "prop-types"
import { createContext, useState } from "react"
import { useMutation, useQuery } from "react-query"

const CoursesContext = createContext()
export default CoursesContext

export const CoursesContainer = ({ children }) => {
  const createCourseMutation = useMutation(createCourse)
  const { data: courses, isLoading: coursesLoading } = useQuery(
    "courses",
    listCourses
  )
  return (
    <CoursesContext.Provider
      value={{
        createCourseMutation,
        courses,
        coursesLoading,
      }}
    >
      {children}
    </CoursesContext.Provider>
  )
}

CoursesContainer.propTypes = {
  children: PropTypes.node,
}
