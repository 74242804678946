import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core"
import InstructorCard from "components/Instructors/InstructorCard"
import AppContext from "contexts/App"
import InstructorsContext from "contexts/Instructors"
import { useContext } from "react"

const InstructorListDialog = () => {
  const { dialogs, closeDialog, openDialog } = useContext(AppContext)
  const { instructors } = useContext(InstructorsContext)
  const thisDialog = dialogs?.["instructorListDialog"] || {}
  const { open = false, data } = thisDialog

  const instructorIds = thisDialog?.data?.instructorIdArr
  const view = thisDialog?.data?.view

  const thisLessonInstructors = instructorIds?.map((i) => {
    return instructors?.find((instructor) => instructor.id === Number(i))
  })

  const handleClose = () => {
    closeDialog("instructorListDialog", false)
  }

  const handleView = (instructor) => {
    openDialog("viewInstructorDialog", { instructor, view: true })
  }

  return (
    <Dialog open={open}>
      <DialogTitle>Instructors</DialogTitle>
      <DialogContent>
        <Box overflow="auto">
          {thisLessonInstructors?.map((x) => (
            <InstructorCard
              key={x.id}
              name={x.name}
              profileImg={x.profile_image.url}
              view={view}
              viewFn={() => handleView(x)}
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box m={2}>
          <Button onClick={handleClose}>Close</Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default InstructorListDialog
