import { LinearProgress } from "@material-ui/core"
import LabsContext, { LabsContainer } from "contexts/Labs"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useContext } from "react"
import Router from "router"

import useStyles from "./styles"

const Labs = ({ routes = [], ...rest }) => {
  const classes = useStyles()

  return (
    <Page>
      <LabsContainer>
        <LabsWrapper>
          <Router routes={routes} {...rest} />
        </LabsWrapper>
      </LabsContainer>
    </Page>
  )
}

Labs.propTypes = {
  routes: PropTypes.array,
}

const LabsWrapper = ({ children }) => {
  const { labsLoading } = useContext(LabsContext)
  if (labsLoading) {
    return <LinearProgress />
  } else {
    return children
  }
}

LabsWrapper.propTypes = {
  children: PropTypes.node,
}

export default Labs
