import { makeStyles } from "@material-ui/core/styles"
const styles = makeStyles((theme) => ({
  appBar: {
    padding: theme.spacing(0, 2),
    display: "flex",
    backgroundColor: "#fff",
    flexGrow: 1,
    alignItems: "center",
  },
  subtitle: {
    opacity: "34%",
    fontWeight: 600,
    marginLeft: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  myProfile: {
    cursor: "pointer",
  },
}))
export default styles
