import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core"
import AppContext from "contexts/App"
import { useContext, useEffect, useState } from "react"
import { useQueryClient } from "react-query"
import { useHistory } from "react-router"

import useStyles from "./styles"

const CreateNewVersionDialog = () => {
  const classes = useStyles()
  const history = useHistory()
  const queryClient = useQueryClient()

  const { dialogs, closeDialog, openSnackBar } = useContext(AppContext)
  const thisDialog = dialogs?.["createNewVersionDialog"] || {}
  const { open = false, data } = thisDialog

  const course = thisDialog?.data?.course
  const lesson = thisDialog?.data?.lesson
  const type = thisDialog?.data?.type
  const id = thisDialog?.data?.id
  const newVersionMutation = thisDialog?.data?.mutation

  const [published, setPublished] = useState(false)

  //finds the latest version of the course or lesson
  const latestVersion =
    type === "course"
      ? course?.versions[course.versions.length - 1]
      : lesson?.versions[lesson.versions.length - 1]

  //increments version numbers
  const latestVersionSplit = latestVersion?.version?.split(".")
  let newVersion
  const getVersion = () => {
    if (latestVersionSplit[2] < 9) {
      newVersion = `${latestVersionSplit[0]}.${latestVersionSplit[1]}.${
        Number(latestVersionSplit[2]) + 1
      }`
      return newVersion
    } else if (latestVersionSplit[1] < 9) {
      newVersion = `${latestVersionSplit[0]}.${
        Number(latestVersionSplit[1]) + 1
      }.0`
      return newVersion
    } else {
      newVersion = `${Number(latestVersionSplit[0] + 1)}.0.0`
      return newVersion
    }
  }

  const createNewVersion = () => {
    getVersion()
    newVersionMutation.mutate(
      { version: newVersion },
      {
        onSuccess: (res) => {
          queryClient.setQueryData([type, id], (oldData) => {
            return { ...oldData, versions: res.data.versions }
          })
          history.push(`/${type}s/${res.data.id}`)
          handleClose()
        },
        onError: (err) => {
          openSnackBar({
            message: err?.response?.data?.error || "Error creating new version",
          })
        },
      }
    )
  }

  const handleClose = () => {
    closeDialog("createNewVersionDialog")
    setPublished(false)
  }

  useEffect(() => {
    if (course?.published || lesson?.published) {
      setPublished(true)
    }
  }, [course, lesson])

  return (
    <Dialog
      classes={{
        paperWidthSm: classes.dialogMinHeight,
      }}
      open={open}
    >
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography variant="h2" className={classes.title}>
          Create New Version
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1">
          {`Are you sure you want to create a new verison of this ${type}?`}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={createNewVersion}
        >
          confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateNewVersionDialog
