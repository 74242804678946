import { makeStyles } from "@material-ui/core/styles"
import Color from "color"

const styles = makeStyles((theme) => {
  const primaryColor = Color(theme.palette.primary.main)
  return {
    darkButton: {
      backgroundColor: "rgb(9 41 88 / 16%)",
      color: [primaryColor.string(), "!important"],
      "&:hover": {
        backgroundColor: "rgb(9 41 88 / 30%)",
      },
    },
    buttonDisabled: {
      backgroundColor: ["rgb(9 41 88 / 8%)", "!important"],
      color: [primaryColor.fade(0.7).string(), "!important"],
    },
    buttonLabel: {
      color: "inherit",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.75em",
      },
    },
  }
})

export default styles
