import { Chip } from "@material-ui/core"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import useStyles from "./styles"

const DraftPublishedBox = ({
  published,
  has_been_published,
  archived,
  size = "100%",
  publish_date,
}) => {
  const classes = useStyles()
  const [chipData, setChipData] = useState({})

  useEffect(() => {
    if (published === 1) {
      setChipData({ label: "PUBLISHED", class: "chipRootPublished" })
    } else if (publish_date && published === 0 && has_been_published === 0) {
      setChipData({ label: "SCHEDULED", class: "chipRootScheduled" })
    } else if (published === 0 && has_been_published === 1 && archived === 0) {
      setChipData({ label: "UNPUBLISHED", class: "chipRootUnpublished" })
    } else if (published === 0 && archived === 0 && has_been_published === 0) {
      setChipData({ label: "DRAFT", class: "chipRootDraft" })
    } else if (published === 0 && archived === 1) {
      setChipData({ label: "ARCHIVED", class: "chipRootArchive" })
    }
  }, [published, has_been_published, archived, publish_date])

  return (
    <>
      <Chip
        label={chipData.label}
        classes={{ root: classes[chipData.class] }}
        style={{ minWidth: size }}
      />
    </>
  )
}

DraftPublishedBox.propTypes = {
  published: PropTypes.number,
  has_been_published: PropTypes.number,
  archived: PropTypes.number,
  size: PropTypes.string,
  publish_date: PropTypes.string,
}

export default DraftPublishedBox
