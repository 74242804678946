import { Box, Grid, IconButton, Typography } from "@material-ui/core"
import PaperListRow from "components/Common/PaperListRow"
import dayjs from "dayjs"
import PropTypes from "prop-types"

import useStyles from "./styles"

const AddeddAssessment = ({ assessment }) => {
  const classes = useStyles()
  return (
    <PaperListRow componentWidth={"100%"}>
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        alignItems="center"
        style={{ zIndex: 8 }}
      >
        <Box>
          <Typography variant="h3" color="primary" className={classes.bankText}>
            Question Bank ({assessment?.total_question_count})
          </Typography>
          <Typography variant="body2" className={classes.updatedText}>
            Last updated: {dayjs(assessment?.updated)?.format("M/D/YYYY")}
          </Typography>
        </Box>
        <Typography variant="h3" className={classes.numQuestions}>
          {assessment?.question_count > 1
            ? `${assessment?.question_count} questions`
            : `${assessment?.question_count} question`}
        </Typography>
      </Box>
    </PaperListRow>
  )
}

AddeddAssessment.propTypes = {
  assessment: PropTypes.object,
}

export default AddeddAssessment
