import LoaderButton from "@bit/c_t.components.loader-button"
import {
  Box,
  Button,
  Divider,
  Menu,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import StarOutlineIcon from "@material-ui/icons/StarOutline"
import copy from "assets/Icons/feather-copy.svg"
import CourseLessonMenuItem from "components/Common/CourseLessonMenuItem"
import DraftPublishedBox from "components/Common/DraftPublishedBox"
import HoverText from "components/Common/HoverText"
import AppContext from "contexts/App"
import LearningCuesContext from "contexts/LearningCues"
import LessonContext from "contexts/Lesson"
import UsersContext from "contexts/Users"
import dayjs from "dayjs"
import useHeaderBackground from "hooks/useHeaderBackground"
import Resources from "pages/Lessons/pages/Lesson/pages/Resources"
import React, { useContext, useEffect, useState } from "react"
import { useQueryClient } from "react-query"
import { useHistory } from "react-router"

import useStyles from "./styles"

const LessonHeader = () => {
  const classes = useStyles()
  const history = useHistory()
  const theme = useTheme()
  const { openSnackBar, openDialog } = useContext(AppContext)
  const [adminName, setAdminName] = useState("")
  const [latestVersion, toggleLatestVersion] = useState(false)

  const mdDown = useMediaQuery(theme.breakpoints.down("md"))
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))

  const nameSize = smDown ? 30 : mdDown ? 40 : 50

  const {
    debouncedDetails,
    lesson,
    setAnchorEl,
    anchorEl,
    handleUpdate,
    saveLoading,
    publishLoading,
    archiveLoading,
    unarchiveLoading,
    unpublishLoading,
    publishLessonFunc,
    unpublishLessonFunc,
    archiveLessonFunc,
    unarchiveLessonFunc,
    newLessonVersionMutation,
    copyLessonMutation,
    getPreviewMutation,
    correctInputsLength,
    disabledSave,
  } = useContext(LessonContext)
  const { admins } = useContext(UsersContext)
  const { saveLearningCues, learningCuesQuery } = useContext(
    LearningCuesContext
  )

  const headerSrc = useHeaderBackground(lesson?.thumbnail)

  // returns true if viewing latest version, returns false if not - use to render star
  const confirmMostRecent = () => {
    const length = lesson?.versions?.length
    const latest = lesson?.versions?.[length - 1]?.version
    const currentVersion = lesson?.version
    toggleLatestVersion(latest === currentVersion)
  }

  //set admin name using the id
  useEffect(() => {
    confirmMostRecent()
    if (admins) {
      setAdminName(admins?.find((x) => x.id === lesson?.updated_admin_id)?.name)
    }
  }, [admins, lesson.version, latestVersion])

  //open menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  //open old versins dialog
  const openVersionDialog = () => {
    let data = { data: lesson, type: "lesson", history: history }
    openDialog("historyVersionDialog", data)
  }

  const openCreateNewVersion = () => {
    openDialog("createNewVersionDialog", {
      lesson,
      mutation: newLessonVersionMutation,
      type: "lesson",
      id: lesson.id,
    })
  }

  const handleCopy = () => {
    openDialog("createCopyDialog", {
      lesson,
      type: "lesson",
      copyMutation: copyLessonMutation,
    })
  }

  // // //set lesson details in case of refresh
  // useEffect(() => {
  //   if (lesson?.id && debouncedDetails.name === "") {
  //     setDebouncedDetails({
  //       name: lesson.name,
  //       description: lesson.description || "",
  //     })
  //   }
  // }, [lesson]))

  const beforeSave = (func) => {
    return new Promise((resolve, reject) => {
      if (history.location.pathname.indexOf("/cues") > -1) {
        saveLearningCues({
          onSuccess: (res) => {
            resolve(res)
          },
          onError: (err) => {
            reject(err)
          },
          onSettled: () => {
            setAnchorEl(null)
          },
        })
      } else {
        func()
        // .then((res) => {
        //   resolve(res)
        // })
        // .catch((err) => {
        //   reject(err)
        // })
      }
    })
  }

  const [savingForPreview, setSavingForPreview] = useState(false)

  const openPreview = () => {
    if (!correctInputsLength || (disabledSave && lesson.published === 1)) {
      handleUpdate()
    } else {
      setSavingForPreview(true)
      // beforeSave(handleUpdate)
      handleUpdate()
        .then(() => {
          getPreviewMutation
            .mutateAsync()
            .then((res) => {
              let link = `${process.env.REACT_APP_APP_URL}/courses/${lesson.id}/learn?token=${res}&preview=lesson`
              window.open(link, "_blank")
            })
            .catch((err) => {
              openSnackBar({ message: "Error opening preview" })
            })
            .finally(() => {
              setSavingForPreview(false)
            })
        })
        .catch(() => {
          setSavingForPreview(false)
          openSnackBar({ message: "Error saving lesson" })
        })
    }
  }

  const cues = history.location.pathname.includes("cues")
  const resources = history.location.pathname.includes("resources")
  const analytics = history.location.pathname.includes("analytics")

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-evenly"
      className={classes.headerContainer}
    >
      {headerSrc && (
        <img
          className={classes.backgroundImage}
          src={headerSrc}
          alt=""
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            objectFit: "cover",
            zIndex: -1,
            opacity: 0.2,
          }}
        />
      )}
      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        <Box marginLeft={2}>
          <HoverText
            data={
              debouncedDetails?.name !== ""
                ? debouncedDetails?.name
                : lesson?.name
            }
            variant="h1"
            size={nameSize}
            className={classes.title}
          />
        </Box>
        <Box marginRight={2} marginLeft={2} className={classes.saveContainer}>
          <LoaderButton
            variant="outlined"
            classoverrides={{
              button: {
                root: classes.previewBtn,
              },
            }}
            onClick={openPreview}
            disabled={lesson?.video_id === null}
            working={savingForPreview}
          >
            Save & Preview
          </LoaderButton>
          <Tooltip title="Create Copy" aria-label="create copy">
            <Button
              variant="outlined"
              size="large"
              className={classes.copyBtn}
              classes={{ root: classes.copyButton }}
              onClick={handleCopy}
            >
              <img src={copy} alt="" />
            </Button>
          </Tooltip>
          <Button
            variant="outlined"
            className={classes.createBtn}
            onClick={openCreateNewVersion}
            disabled={!lesson?.published && !lesson?.has_been_published}
          >
            Create New Version
          </Button>
          {!analytics && (
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={handleClick}
              className={classes.saveBtn}
            >
              Save
              <ExpandMoreIcon />
            </Button>
          )}
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            classes={{ paper: classes.menu }}
          >
            {lesson.archived === 1 && lesson.published === 0 ? (
              <div>
                <CourseLessonMenuItem
                  func={() => beforeSave(handleUpdate)}
                  loadingState={saveLoading}
                  text="SAVE"
                />

                {!cues && !resources && (
                  <>
                    <Divider
                      style={{
                        backgroundColor: "#FFF",
                        opacity: "0.2",
                        margin: "3px",
                      }}
                    />
                    <CourseLessonMenuItem
                      func={() => unarchiveLessonFunc}
                      loadingState={unarchiveLoading}
                      text="SAVE & UNARCHIVE"
                    />
                  </>
                )}
              </div>
            ) : null}
            {lesson?.published === 0 && lesson.archived === 0 ? (
              <div>
                <CourseLessonMenuItem
                  func={() => beforeSave(handleUpdate)}
                  loadingState={
                    (saveLoading && !publishLoading) ||
                    learningCuesQuery.isLoading
                  }
                  text="SAVE"
                />
                {!lesson?.has_been_published && !cues && !resources ? (
                  <>
                    <Divider
                      style={{
                        backgroundColor: "#FFF",
                        opacity: "0.2",
                        margin: "3px",
                      }}
                    />
                    <CourseLessonMenuItem
                      func={() => beforeSave(publishLessonFunc)}
                      loadingState={publishLoading}
                      text="SAVE & PUBLISH"
                    />
                  </>
                ) : null}
                {!cues && !resources && (
                  <>
                    <Divider
                      style={{
                        backgroundColor: "#FFF",
                        opacity: "0.2",
                        margin: "3px",
                      }}
                    />
                    <CourseLessonMenuItem
                      func={() => beforeSave(archiveLessonFunc)}
                      loadingState={archiveLoading}
                      text="SAVE & ARCHIVE"
                    />
                  </>
                )}
              </div>
            ) : null}
            {lesson?.published === 1 ? (
              <div>
                <CourseLessonMenuItem
                  func={() => beforeSave(handleUpdate)}
                  loadingState={saveLoading}
                  text="SAVE"
                />
                {!cues && !resources && (
                  <>
                    <Divider
                      style={{
                        backgroundColor: "#FFF",
                        opacity: "0.2",
                        margin: "3px",
                      }}
                    />
                    <CourseLessonMenuItem
                      func={() => beforeSave(unpublishLessonFunc)}
                      loadingState={unpublishLoading}
                      text="SAVE & UNPUBLISH"
                    />
                  </>
                )}
              </div>
            ) : null}
          </Menu>
        </Box>
      </Box>
      <Box
        marginLeft={2}
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        className={classes.detailBox}
      >
        <DraftPublishedBox
          published={lesson.published}
          has_been_published={lesson.has_been_published}
          archived={lesson.archived}
          size={"100px"}
        />
        <Typography
          variant="subtitle2"
          className={classes.version}
          onClick={() => openVersionDialog()}
          style={!latestVersion ? { marginRight: "10px" } : null}
        >
          VERSION {lesson?.version}
        </Typography>
        {latestVersion ? (
          <Box display="flex" justifyContent="center">
            <Tooltip title="Latest Version" aria-label="latest version">
              <StarOutlineIcon classes={{ root: classes.star }} />
            </Tooltip>
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" marginRight={1} />
        )}
        <Box className={classes.lastUpdated}>
          <Typography variant="subtitle2" className={classes.updated}>
            Last Updated {dayjs(lesson?.updated)?.format("M/D/YYYY")}
          </Typography>
          <Typography variant="subtitle2" className={classes.creator}>
            By {" " + adminName}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

LessonHeader.propTypes = {}

export default LessonHeader
