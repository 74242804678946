import { Box, Typography } from "@material-ui/core"
import PropTypes from "prop-types"
import characterCounter from "utils/characterCounter"

import useStyles from "./styles"

const CounterHeader = ({ title, value, max }) => {
  const classes = useStyles()
  return (
    <Box display="flex" justifyContent="space-between">
      <Typography color="secondary" className={classes.title}>
        {title}
      </Typography>
      <Box display="flex">
        <Typography
          style={{
            color: characterCounter(max, value?.length),
          }}
        >
          {value?.length}
        </Typography>
        <Typography>/{max}</Typography>
      </Box>
    </Box>
  )
}

CounterHeader.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  max: PropTypes.number,
}

export default CounterHeader
