import {
  Box,
  Button,
  Divider,
  Menu,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import CourseLessonMenuItem from "components/Common/CourseLessonMenuItem"
import DraftPublishedBox from "components/Common/DraftPublishedBox"
import HoverText from "components/Common/HoverText"
import AppContext from "contexts/App"
import CertificationContext from "contexts/Certification"
import UsersContext from "contexts/Users"
import dayjs from "dayjs"
import useHeaderBackground from "hooks/useHeaderBackground"
import React, { useContext, useEffect, useState } from "react"

import useStyles from "./styles"

const CertificationHeader = () => {
  const classes = useStyles()
  const theme = useTheme()
  const [adminName, setAdminName] = useState("")

  const mdDown = useMediaQuery(theme.breakpoints.down("md"))
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))

  const nameSize = smDown ? 30 : mdDown ? 40 : 50
  const {
    certification,
    anchorEl,
    setAnchorEl,
    debouncedDetails,
    saveLoading,
    handleUpdate,
    publishCert,
    publishLoading,
    unpublishLoading,
    unpublishCert,
  } = useContext(CertificationContext)

  const headerSrc = useHeaderBackground(certification?.thumbnail)

  const { admins } = useContext(UsersContext)

  //set admin name using the id
  useEffect(() => {
    if (admins) {
      setAdminName(
        admins?.find((x) => x.id === certification?.updated_admin_id)?.name
      )
    }
  }, [admins, certification?.updated_admin_id])

  //open menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-evenly"
      className={classes.headerContainer}
    >
      {headerSrc && (
        <img
          className={classes.backgroundImage}
          src={headerSrc}
          alt=""
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            objectFit: "cover",
            zIndex: -1,
            opacity: 0.2,
          }}
        />
      )}
      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        <Box marginLeft={2}>
          <HoverText
            data={
              debouncedDetails?.name !== ""
                ? debouncedDetails?.name
                : certification?.name
            }
            variant="h1"
            size={nameSize}
            className={classes.title}
          />
        </Box>
        <Box marginRight={2} marginLeft={2} className={classes.saveContainer}>
          {certification?.published === 1 && (
            <>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={handleClick}
                className={classes.saveBtn}
              >
                Save
                <ExpandMoreIcon />
              </Button>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                classes={{ paper: classes.menu }}
              >
                <div>
                  <CourseLessonMenuItem
                    func={handleUpdate}
                    loadingState={saveLoading}
                    text="SAVE"
                  />
                  <Divider
                    style={{
                      backgroundColor: "#FFF",
                      opacity: "0.2",
                      margin: "3px",
                    }}
                  />
                  <CourseLessonMenuItem
                    func={unpublishCert}
                    loadingState={unpublishLoading}
                    text="SAVE & UNPUBLISH"
                  />
                </div>
              </Menu>
            </>
          )}
          {!certification?.published && (
            <>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={handleClick}
                className={classes.saveBtn}
              >
                Save
                <ExpandMoreIcon />
              </Button>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                classes={{ paper: classes.menu }}
              >
                <div>
                  <CourseLessonMenuItem
                    func={handleUpdate}
                    loadingState={saveLoading}
                    text="SAVE"
                  />
                  {!certification?.has_been_published ? (
                    <>
                      <Divider
                        style={{
                          backgroundColor: "#FFF",
                          opacity: "0.2",
                          margin: "3px",
                        }}
                      />
                      <CourseLessonMenuItem
                        func={publishCert}
                        loadingState={publishLoading}
                        text="SAVE & PUBLISH"
                      />
                    </>
                  ) : null}
                </div>
              </Menu>
            </>
          )}
        </Box>
      </Box>
      <Box
        marginLeft={2}
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        className={classes.detailBox}
      >
        <DraftPublishedBox
          published={certification?.published}
          has_been_published={certification?.has_been_published}
          archived={0}
          size={"100px"}
        />
        <Box className={classes.lastUpdated}>
          <Typography variant="subtitle2" className={classes.updated}>
            Last Updated {dayjs(certification?.updated)?.format("M/D/YYYY")}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

CertificationHeader.propTypes = {}

export default CertificationHeader
