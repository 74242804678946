import { Box, Button, Tooltip, Typography } from "@material-ui/core"
import RedoIcon from "@material-ui/icons/Redo"
import UndoIcon from "@material-ui/icons/Undo"
import LearningCuesContext from "contexts/LearningCues"
import BottomVideoBannerDialog from "dialogs/LearningCueDialogs/BottomVideoBanner"
import FullscreenDialog from "dialogs/LearningCueDialogs/Fullscreen"
import NotesDialog from "dialogs/LearningCueDialogs/Notes"
import ReadyCheckDialog from "dialogs/LearningCueDialogs/ReadyCheck"
import TopVideoBannerDialog from "dialogs/LearningCueDialogs/TopVideoBanner"
import { AnimatePresence } from "framer-motion"
import { orderBy } from "lodash"
import PropTypes from "prop-types"
import { useContext } from "react"

import LearningCueItem from "./LearningCueItem"
import LearningCuesListEmpty from "./LearningCuesListEmpty"
import useStyles from "./styles"

const LearningCuesList = ({ lockdown }) => {
  const classes = useStyles()
  const {
    editingLearningCues,
    forwardSteps,
    backwardSteps,
    undo,
    redo,
    updateLearningCueTime,
  } = useContext(LearningCuesContext)

  const handleUpdateTime = (learningCue, time) => {
    updateLearningCueTime(learningCue, time)
  }

  return (
    <>
      <Box className={classes.learningCuesList}>
        <Box flexGrow={1} display="flex" flexDirection="column">
          {lockdown && (
            <Box className={classes.lockedBanner}>
              <Typography variant="h4" style={{ fontWeight: 500 }}>
                This content is locked.
              </Typography>
              {/* <Typography variant="subtitle2">
                To update this content, you must create a new version
              </Typography> */}
            </Box>
          )}
          <Box className={classes.header}>
            <Box className={classes.totalLearningCuesCount}>
              <Box width="53px" textAlign="center">
                <Typography
                  variant="body2"
                  className={classes.totalLearningCuesText}
                >
                  {editingLearningCues.length}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.learningCueCounts}>
              <Tooltip title="Media Cues" placement="top">
                <Box marginX={0.5} textAlign="center">
                  <Typography
                    variant="body2"
                    className={classes.learningCueTypeCount}
                    style={{ color: "#17A5B1", backgroundColor: "#E2F1F2" }}
                  >
                    {editingLearningCues.filter((c) => c.media).length}
                  </Typography>
                </Box>
              </Tooltip>
              <Tooltip title="Top Video Banner Cues" placement="top">
                <Box marginX={0.5} textAlign="center">
                  <Typography
                    variant="body2"
                    className={classes.learningCueTypeCount}
                    style={{
                      color: "#FF6A40",
                      backgroundColor: "#FFC4B4",
                    }}
                  >
                    {editingLearningCues.filter((c) => c.topBanner).length}
                  </Typography>
                </Box>
              </Tooltip>
              <Tooltip title="Bottom Video Banner Cues" placement="top">
                <Box marginX={0.5} textAlign="center">
                  <Typography
                    variant="body2"
                    className={classes.learningCueTypeCount}
                    style={{
                      color: "rgb(251 54 162)",
                      backgroundColor: "rgb(255 196 237)",
                    }}
                  >
                    {editingLearningCues.filter((c) => c.bottomBanner).length}
                  </Typography>
                </Box>
              </Tooltip>
              <Tooltip title="Knowledge Check Cues" placement="top">
                <Box marginX={0.5} textAlign="center">
                  <Typography
                    variant="body2"
                    className={classes.learningCueTypeCount}
                    style={{
                      color: "#29B117",
                      backgroundColor: "#D3EFCF",
                    }}
                  >
                    {editingLearningCues.filter((c) => c.knowledgeCheck).length}
                  </Typography>
                </Box>
              </Tooltip>
              <Tooltip title="Sentiment Cues" placement="top">
                <Box marginX={0.5} textAlign="center">
                  <Typography
                    variant="body2"
                    className={classes.learningCueTypeCount}
                    style={{
                      color: "rgb(255 31 31)",
                      backgroundColor: "rgb(253 201 201)",
                    }}
                  >
                    {
                      editingLearningCues.filter((c) => c.sentimentQuestions)
                        .length
                    }
                  </Typography>
                </Box>
              </Tooltip>
              <Tooltip title="Fullscreen Cues" placement="top">
                <Box marginX={0.5} textAlign="center">
                  <Typography
                    variant="body2"
                    className={classes.learningCueTypeCount}
                    style={{
                      color: "#888888",
                      backgroundColor: "#e2e2e2",
                    }}
                  >
                    {editingLearningCues.filter((c) => c.fullscreen).length}
                  </Typography>
                </Box>
              </Tooltip>
              <Tooltip title="Ready Check Cues" placement="top">
                <Box marginX={0.5} textAlign="center">
                  <Typography
                    variant="body2"
                    className={classes.learningCueTypeCount}
                    style={{
                      color: "#B821E2",
                      backgroundColor: "#EDC7F8",
                    }}
                  >
                    {editingLearningCues.filter((c) => c.readyCheck).length}
                  </Typography>
                </Box>
              </Tooltip>
              <Tooltip title="Notes Cues" placement="top">
                <Box marginX={0.5} textAlign="center">
                  <Typography
                    variant="body2"
                    className={classes.learningCueTypeCount}
                    style={{
                      color: "#0078F6",
                      backgroundColor: "#B1D6FC",
                    }}
                  >
                    {editingLearningCues.filter((c) => c.prefilledNotes).length}
                  </Typography>
                </Box>
              </Tooltip>
            </Box>

            <Box
              flexGrow={1}
              textAlign="right"
              style={lockdown ? { visibility: "hidden" } : null}
            >
              <Button
                size="small"
                disabled={backwardSteps.length <= 1}
                startIcon={<UndoIcon />}
                onClick={undo}
                className={classes.undoRedoButton}
              >
                Undo
              </Button>
              <Button
                size="small"
                disabled={forwardSteps.length === 0}
                endIcon={<RedoIcon />}
                onClick={redo}
                className={classes.undoRedoButton}
              >
                Redo
              </Button>
            </Box>
          </Box>
          <Box flexGrow={1} overflow="auto">
            {editingLearningCues.length === 0 && <LearningCuesListEmpty />}
            {editingLearningCues.length > 0 && (
              <AnimatePresence>
                {orderBy(editingLearningCues, ["start_seconds"], ["asc"]).map(
                  (learningCue) => (
                    <LearningCueItem
                      key={learningCue.id}
                      learningCue={learningCue}
                      updateTime={(time) => handleUpdateTime(learningCue, time)}
                      lockdown={lockdown}
                    />
                  )
                )}
              </AnimatePresence>
            )}
          </Box>
        </Box>
      </Box>
      <TopVideoBannerDialog />
      <BottomVideoBannerDialog />
      <FullscreenDialog />
      <ReadyCheckDialog />
      <NotesDialog />
    </>
  )
}

LearningCuesList.propTypes = {
  lockdown: PropTypes.bool,
}

export default LearningCuesList
