import { Button, Slider, Typography } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import { withStyles } from "@material-ui/styles"
import { ReactComponent as VideoSliderIcon } from "assets/Icons/video-slider-icon.svg"
import LearningCuesContext from "contexts/LearningCues"
import VideoContext from "contexts/Video"
import dayjs from "dayjs"
import PropTypes from "prop-types"
import { useContext, useMemo, useRef } from "react"
import getPercentFromTime from "utils/getPercentFromTime"

import LearningCueBlocks from "./LearningCueBlocks"
import useStyles from "./styles"

const SliderWithStyles = withStyles({
  root: {
    color: "transparent",
    height: "100%",
    padding: 0,
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: "transparent",
    marginTop: -4.5,
    // marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 12px)",
    top: -22,
    "& *": {
      background: "transparent",
      color: "#000",
    },
  },
  track: {
    height: 6,
    borderRadius: 4,
  },
  rail: {
    height: 6,
    borderRadius: 4,
  },
})(Slider)

function ValueLabelComponent(props) {
  const classes = useStyles()
  const { value, duration, children } = props

  const playheadTime = useMemo(() => {
    try {
      return (value / 100) * duration
    } catch (e) {
      return 0
    }
  }, [duration, value])

  const format = useMemo(() => {
    return duration > 3600 ? "HH:mm:ss" : "mm:ss"
  }, [duration])

  return (
    <div>
      <Typography
        color="secondary"
        variant="subtitle2"
        className={classes.playheadTime}
      >
        {dayjs.duration(0, "s").add(playheadTime, "seconds").format(format)}
      </Typography>
      {children}
    </div>
  )
}

ValueLabelComponent.propTypes = {
  value: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  children: PropTypes.element.isRequired,
  sliderRef: PropTypes.object,
}

const getValue = (value, duration) => {
  let playheadTime
  // try {
  //   playheadTime = (value / 100) * duration
  // } catch (e) {
  //   playheadTime = 0
  // }
  const format = duration > 3600 ? "HH:mm:ss" : "mm:ss"
  return dayjs.duration(0, "s").add(value, "seconds").format(format)
}

const ThumbComponent = ({ value, duration, sliderRef, ...props }) => {
  const classes = useStyles()

  const playheadTime = useMemo(() => {
    return getValue(value, duration)
  }, [value, duration])

  // const offset = useMemo(() => {
  //   if (sliderRef.current) {
  //     return (value / 100) * sliderRef.current.clientWidth
  //   } else {
  //     return 0
  //   }
  // }, [sliderRef, value])

  return (
    <div
      {...props}
      // style={{ transform: `translateX(${offset}px)` }}
      className={classes.playhead}
    >
      <Typography
        color="secondary"
        variant="subtitle2"
        className={classes.playheadTime}
      >
        {playheadTime}
      </Typography>
      <VideoSliderIcon className={classes.thumbComponentIcon} />
    </div>
  )
}

ThumbComponent.propTypes = {
  value: PropTypes.number,
  duration: PropTypes.number,
  sliderRef: PropTypes.object,
}

const TimelineSlider = ({ duration, lockdown, ...props }) => {
  const classes = useStyles()

  const sliderRef = useRef()

  const { videoProgress } = useContext(VideoContext)
  const { addNewLearningCue } = useContext(LearningCuesContext)

  const handleAddLearningCue = (e) => {
    e.preventDefault()
    e.stopPropagation()
    addNewLearningCue(Math.floor(videoProgress))
  }

  return (
    <>
      <LearningCueBlocks />
      <SliderWithStyles
        ref={sliderRef}
        ThumbComponent={(thumbProps) => (
          <ThumbComponent
            {...thumbProps}
            value={props.value}
            duration={duration}
            sliderRef={sliderRef}
          />
        )}
        max={duration}
        aria-labelledby="timeline"
        valueLabelDisplay="on"
        {...props}
      />
      <div
        style={{
          position: "absolute",
          zIndex: 10,
          bottom: -13,
          left: `${getPercentFromTime(props.value, duration)}%`,
        }}
      >
        <Button
          disableElevation
          disableRipple
          onClick={handleAddLearningCue}
          className={classes.playheadButton}
          disabled={lockdown}
        >
          <AddIcon style={{ fontSize: 14 }} />
        </Button>
      </div>
    </>
  )
}

TimelineSlider.propTypes = {
  value: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  lockdown: PropTypes.bool,
}

export default TimelineSlider
