import {
  Box,
  Button,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import HoverText from "components/Common/HoverText"
import PaperListRow from "components/Common/PaperListRow"
import AppContext from "contexts/App"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"

import ClientCardMobile from "../ClientCardMobile"
import useStyles from "./styles"

const ClientCard = ({ client }) => {
  const classes = useStyles()
  const theme = useTheme()
  const { openDialog } = useContext(AppContext)

  const smDown = useMediaQuery(theme.breakpoints.down("sm"))

  //open edit client dialog
  const openEditDialog = () => {
    let data = { client: client }
    openDialog("editClientDialog", data)
  }

  const codesArr = client?.access_codes

  const totalSeats = codesArr?.map((x) => x.seats).reduce((a, b) => a + b, 0)

  const totalActive = codesArr
    ?.filter((x) => x.is_active === 1)
    .map((i) => i.uses)
    .reduce((a, b) => a + b, 0)

  if (smDown) {
    return (
      <ClientCardMobile
        client={client}
        openEditDialog={openEditDialog}
        totalSeats={totalSeats}
        totalActive={totalActive}
      />
    )
  } else {
    return (
      <Box marginBottom={2}>
        <PaperListRow>
          <Box className={classes.rowWrapper}>
            <Box className={classes.idBox}>
              <Typography variant="subtitle1">{client?.id}</Typography>
            </Box>
            <Box className={classes.nameBox}>
              <HoverText
                data={client?.name}
                // size depends on page width - more chars on desktop, less on mobile
                size={25}
                variant="h3"
                className={classes.clientName}
              />
            </Box>
            <Box className={classes.codeCountBox}>
              <Typography variant="subtitle1">
                {client?.access_codes?.length}
              </Typography>
            </Box>
            <Box className={classes.totalSeatsBox}>
              <Typography variant="subtitle1">{totalSeats}</Typography>
            </Box>
            <Box className={classes.activeSeatsBox}>
              <Typography variant="subtitle1">{totalActive}</Typography>
            </Box>
            {/* <Box className={classes.completionRateBox}>
              <Typography variant="subtitle1">
                {client?.completionRate}
                90%
              </Typography>
            </Box> */}
            <Box className={classes.buttonBox}>
              <Button color="secondary" onClick={openEditDialog}>
                Edit
              </Button>
            </Box>
          </Box>
        </PaperListRow>
      </Box>
    )
  }
}

ClientCard.propTypes = {
  client: PropTypes.object,
}

export default ClientCard
