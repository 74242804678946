import DayjsUtils from "@date-io/dayjs"
import {
  Box,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Typography,
} from "@material-ui/core"
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import PropTypes from "prop-types"
import React from "react"

import useStyles from "./styles"

const DiscountDateTime = ({
  startDate,
  setStartDate,
  expirationDate,
  setExpirationDate,
  noExpiration,
  setNoExpiration,
  statusChanged,
  setStatusChanged,
}) => {
  const classes = useStyles()

  const handleStartDateChange = (e) => {
    setStartDate(e)
    //setExpirationDate(e)
    setStatusChanged(true)
  }

  const handleExpirationDateChange = (e) => {
    // if (startDate <= expirationDate) {
    setExpirationDate(e)
    setStatusChanged(true)
    // } else {
    //   console.log("error")
    // }
  }

  const handleExpiration = () => {
    setNoExpiration(!noExpiration)
    setStatusChanged(true)
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap"
    >
      <MuiPickersUtilsProvider utils={DayjsUtils}>
        <Box>
          <Typography color="secondary" className={classes.inputLabel}>
            Start Date
          </Typography>
          <DatePicker
            value={startDate}
            onChange={(e) => handleStartDateChange(e)}
            // disablePast
            inputVariant="filled"
            DialogProps={{
              maxWidth: "xs",
              classes: { dialogRoot: classes.dialogWidth },
            }}
            className={classes.publish}
            format="MM/DD/YYYY"
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarTodayOutlinedIcon
                    fontSize="inherit"
                    className={classes.calendarIcon}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box>
          <Typography color="secondary" className={classes.inputLabel}>
            Expiration Date
          </Typography>
          <DatePicker
            value={expirationDate}
            // disablePast
            minDate={startDate}
            onChange={(e) => handleExpirationDateChange(e)}
            inputVariant="filled"
            DialogProps={{
              maxWidth: "xs",
              classes: { dialogRoot: classes.dialogWidth },
            }}
            className={classes.expiration}
            format="MM/DD/YYYY"
            disabled={noExpiration}
            size="small"
            invalidDateMessage=""
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarTodayOutlinedIcon
                    fontSize="inherit"
                    className={classes.calendarIcon}
                  />
                </InputAdornment>
              ),
              disableUnderline: true,
              classes: { disabled: classes.disabledExpiration },
            }}
          />
        </Box>
        <Box marginTop={4} className={classes.checkBox}>
          <FormControlLabel
            style={{ margin: 0 }}
            control={
              <Checkbox
                color="secondary"
                checked={noExpiration}
                onChange={() => handleExpiration()}
              />
            }
            label={<Typography>Does not expire</Typography>}
            labelPlacement="end"
            color="secondary"
          />
        </Box>
      </MuiPickersUtilsProvider>
    </Box>
  )
}

DiscountDateTime.propTypes = {
  startDate: PropTypes.any,
  setStartDate: PropTypes.func,
  expirationDate: PropTypes.any,
  setExpirationDate: PropTypes.func,
  noExpiration: PropTypes.bool,
  setNoExpiration: PropTypes.func,
  statusChanged: PropTypes.bool,
  setStatusChanged: PropTypes.func,
}

export default DiscountDateTime
