import { Box, LinearProgress } from "@material-ui/core"
import { getCourse } from "actions/courses"
import resourcesColor from "assets/Icons/colored-glasses.svg"
import analyticsColor from "assets/Icons/colored-pie-chart.svg"
import contentColor from "assets/Icons/colored-video.svg"
import analytics from "assets/Icons/feather-pie-chart.svg"
import content from "assets/Icons/feather-video.svg"
import resources from "assets/Icons/noun_Glasses_1182135.svg"
import ContentTabs from "components/Common/ContentTabs"
import CourseHeader from "components/courses/CourseHeader"
import UnsavedChanges from "components/courses/UnsavedChanges"
import AppContext from "contexts/App"
import { CourseContainer } from "contexts/Course"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useEffect } from "react"
import { useContext } from "react"
import { useQuery } from "react-query"
import Router from "router"

import useStyles from "./styles"

const Course = ({ routes = [], ...rest }) => {
  const classes = useStyles()

  const { authUser } = useContext(AppContext)

  const courseId = +rest?.match?.params?.course_id
  const {
    data: course,
    isLoading: courseLoading,
    refetch: refetchCourse,
  } = useQuery(["course", courseId], () => getCourse(courseId), {
    cacheTime: 0,
  })

  useEffect(() => {
    refetchCourse()
  }, [])

  //declares tabs to map over in contenttabs component

  const withAnalytics = [
    {
      label: "Course Content",
      link: `/courses/${courseId}`,
      icon1: content,
      icon2: contentColor,
    },
    {
      label: "Resources",
      link: `/courses/${courseId}/resources`,
      icon1: resources,
      icon2: resourcesColor,
    },
    {
      label: "Analytics",
      link: `/courses/${courseId}/analytics`,
      icon1: analytics,
      icon2: analyticsColor,
    },
  ]
  const withoutAnalytics = [
    {
      label: "Course Content",
      link: `/courses/${courseId}`,
      icon1: content,
      icon2: contentColor,
    },
    {
      label: "Resources",
      link: `/courses/${courseId}/resources`,
      icon1: resources,
      icon2: resourcesColor,
    },
  ]

  const tabs =
    authUser?.access_analytics === 1 ? withAnalytics : withoutAnalytics

  if (courseLoading) {
    return <LinearProgress />
  }
  return (
    <Page>
      <CourseContainer course={course} courseLoading={courseLoading}>
        <Box display="flex" flexDirection="column" height="100%" width="100%">
          <Box flexShrink={0} flexGrow={0} width="100%">
            <CourseHeader courseId={courseId} />
            <ContentTabs tabs={tabs} />
          </Box>
          <Box flexGrow={1} overflow="auto">
            <Router routes={routes} {...rest} />
          </Box>
        </Box>
        <UnsavedChanges />
      </CourseContainer>
    </Page>
  )
}

Course.propTypes = {
  routes: PropTypes.array,
}

export default Course
