import AddCircleIcon from "@material-ui/icons/AddCircle"
import DarkButton from "components/Common/DarkButton"
import PropTypes from "prop-types"
import React from "react"

const NoLabAdded = ({ onClick }) => {
  return (
    <DarkButton
      onClick={onClick}
      size="large"
      fullWidth
      startIcon={<AddCircleIcon color="inherit" />}
    >
      Add lab
    </DarkButton>
  )
}

NoLabAdded.propTypes = {
  onClick: PropTypes.func,
}

export default NoLabAdded
