import api from "store/api"
import serialize from "store/serialize"

export const listCertifications = () => {
  return api
    .get("/certifications")
    .then((res) => serialize("certification", res.data.data))
}

export const createCertification = (data) => {
  return api.post("/certifications", data).then((res) => res.data)
}

export const updateCertification = (data, id) => {
  return api.put(`/certifications/${id}`, data).then((res) => res.data)
}

export const addLearningPaths = (data, id) => {
  return api
    .post(`certifications/${id}/learning_paths`, data)
    .then((res) => res.data)
}

export const getCertification = (id) => {
  return api
    .get(`/certifications/${id}`)
    .then((res) => serialize("certification", res.data.data))
}

export const publishCertification = (id) => {
  return api.get(`/certifications/${id}/publish`).then((res) => res.data)
}

export const unpublishCertification = (id) => {
  return api.get(`/certifications/${id}/unpublish`).then((res) => res.data)
}
