import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  orderText: {
    opacity: "27%",
  },
  publishedOverlay: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 5,
    backgroundColor: "rgba(255,255,255,0.5)",
  },
  publishedBanner: {
    position: "relative",
    width: "100%",
    height: 85,
    backgroundColor: "#092958",
    zIndex: 7,
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      padding: theme.spacing(2),
    },
  },
  title: {
    fontWeight: 600,
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  subtitle: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
  addCircle: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px !important",
    },
  },
}))

export default styles
