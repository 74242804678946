import PropTypes from "prop-types"

import ResponseListWithCorrectAnswer from "../../ResponseListWithCorrectAnswer"
import useStyles from "./styles"

const SingleChoice = ({ question, isEdit, showErrors }) => {
  const classes = useStyles()

  return (
    <ResponseListWithCorrectAnswer
      responseSet={question.sets[0]}
      isEdit={isEdit}
      showErrors={showErrors}
    />
  )
}

SingleChoice.propTypes = {
  question: PropTypes.object,
  isEdit: PropTypes.bool,
  showErrors: PropTypes.bool,
}

export default SingleChoice
