import LoaderButton from "@bit/c_t.components.loader-button"
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import { Search } from "@material-ui/icons"
import { listPublishedCourses } from "actions/courses"
import { listCourses } from "actions/courses"
import AddedCourseCard from "components/Paths/AddedCourseCard"
import AppContext from "contexts/App"
import PathContext from "contexts/Path"
import { uniq } from "lodash"
import { useContext, useEffect, useState } from "react"
import { useQuery } from "react-query"
import { useHistory } from "react-router-dom"
import serialize from "store/serialize"

import useStyles from "./styles"

const AddCourseDialog = () => {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const { dialogs, closeDialog, openSnackBar } = useContext(AppContext)
  const thisDialog = dialogs?.["addCourseDialog"] || {}
  const { open = false, data } = thisDialog
  const { data: courses } = useQuery("courses", () => listCourses())

  const { courseArray, setCourseArray } = useContext(PathContext)
  const [searchValue, setSearchValue] = useState("")
  const [filteredCourses, setFilteredCourses] = useState([])
  const [addedCourses, setAddedCourses] = useState([])

  const smDown = useMediaQuery(theme.breakpoints.down("sm"))

  //close dialog set search value to empty string and
  //set component array state to the context state
  const handleClose = () => {
    closeDialog("addCourseDialog", false)
    setSearchValue("")
    setAddedCourses(courseArray)
  }

  //open Lesson dialog
  const openCourses = () => {
    history.push("/courses")
    handleClose()
  }

  //close the dialog
  const addCourse = () => {
    setCourseArray(addedCourses)
    handleClose()
  }

  // returns the published or latest version of each course
  //for each container
  const getLatest = (array) => {
    const ids = array.map((c) => c.container_id)
    const uniqContainerIds = uniq(ids)
    const uniqArray = uniqContainerIds.map((u) =>
      array.find((c) => c.container_id === u)
    )
    let newArr = []
    uniqArray.map((c) => {
      if (c.versions.find((v) => v.published === 1)) {
        const published = c.versions.find((v) => v.published === 1)
        newArr = [...newArr, published]
      } else {
        const draft = c.versions[c.versions.length - 1]
        newArr = [...newArr, draft]
      }
    })
    return newArr
  }

  ///set course array in context to a component state to only replace the context array
  useEffect(() => {
    setAddedCourses(courseArray)
  }, [courseArray])

  // fitered data using searchValue
  useEffect(() => {
    if (courses) {
      const latestCourses = getLatest(courses)
      let newArray = [...latestCourses]
      if (searchValue.length > 0) {
        newArray = courses?.filter((course) => {
          return course?.versions[0].name
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        })
      } else if (searchValue === "") {
        newArray = latestCourses
      }
      setFilteredCourses(newArray)
    }
  }, [searchValue, courses])

  return (
    <Dialog
      classes={{ paperWidthSm: classes.dialogContainer }}
      open={open}
      fullScreen={smDown}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={classes.dialogTop}
        >
          <Typography variant="h2" className={classes.title}>
            Add Course
          </Typography>
          <TextField
            placeholder="Search courses"
            className={classes.search}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                setSearchValue(e.target.value)
              }
            }}
            onChange={(e) => e.target.value === "" && setSearchValue("")}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start" style={{ marginLeft: "10px" }}>
                  <Search className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        {!courses ? (
          <CircularProgress />
        ) : (
          filteredCourses?.map((course) => {
            return (
              <AddedCourseCard
                course={course}
                key={course.id}
                addedCourses={addedCourses}
                setAddedCourses={setAddedCourses}
              />
            )
          })
        )}
      </DialogContent>
      <DialogActions
        style={{
          position: "absolute",
          bottom: 4,
          width: "100%",
        }}
        className={classes.actions}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          marginLeft="20px"
          marginRight="20px"
          className={classes.actionBox}
        >
          <Box>
            <Button className={classes.remove} onClick={openCourses}>
              Create Course
            </Button>
          </Box>
          <Box>
            <Button
              onClick={handleClose}
              style={{ height: "40px", marginRight: "10px" }}
            >
              Cancel
            </Button>
            <LoaderButton
              onClick={addCourse}
              classoverrides={{
                wrapper: classes.button,
                button: {
                  root: classes.buttonRoot,
                },
              }}
              variant="contained"
              color="secondary"
              //   working={isSubmitting}
              size="small"
            >
              add
            </LoaderButton>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

AddCourseDialog.propTypes = {}

export default AddCourseDialog
