import Switch from "@material-ui/core/Switch"
import LessonContext from "contexts/Lesson"
import React, { useContext } from "react"

const TheatreMode = () => {
  const { lessonDetailsValues, setLessonDetailsValues } = useContext(
    LessonContext
  )
  return (
    <Switch
      checked={lessonDetailsValues?.theater_view}
      onChange={(e) => {
        setLessonDetailsValues((oldState) => ({
          ...oldState,
          theater_view: oldState.theater_view === 0 ? 1 : 0,
        }))
      }}
    />
  )
}

export default TheatreMode
