import LoaderButton from "@bit/c_t.components.loader-button"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core"
import { resetPassword } from "actions/users"
import App from "contexts/App"
import { Form, Formik } from "formik"
import { useContext } from "react"
import { useMutation } from "react-query"
import * as Yup from "yup"
import useStyles from "./styles"

const ConfirmPassword = () => {
  const classes = useStyles()
  const { dialogs, closeDialog } = useContext(App)
  const thisDialog = dialogs?.["confirmPassword"] || {}
  const magicCode = thisDialog?.data?.magic_code
  const magicHash = thisDialog?.data?.magic_hash
  const { open = false, data } = thisDialog

  const { openSnackBar } = useContext(App)

  const resetPassworMutation = useMutation(resetPassword)

  const schema = Yup.object().shape({
    password: Yup.string()
      .matches(
        /^(?=.*[\d])(?=.*[!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/])(?=.*[A-Z])[A-Za-z\d!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/]{8,}$/,
        "Must contain at least 8 characters, one number, and one special character"
      )
      .required("Password is required"),
    confirmPassword: Yup.string().required("Confirm Password is required"),
  })
  const handleClose = () => {
    closeDialog("confirmPassword", false)
  }

  //checks if password values are equal, makes reset password call w hash/code
  const onSubmit = (values, actions) => {
    if (values.password === values.confirmPassword) {
      resetPassworMutation.mutate(
        {
          magic_code: magicCode,
          magic_hash: magicHash,
          password: values.password,
        },
        {
          onSuccess: () => {
            openSnackBar({ message: "Your password was reset." })
            handleClose()
          },
          onError: () => openSnackBar({ message: "Error resetting password" }),
        }
      )
    } else {
      actions.setSubmitting(false)
      openSnackBar({ message: "Error. Passwords do not match" })
    }
  }

  return (
    <Dialog classes={{ paperWidthSm: classes.dialogMinHeight }} open={open}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography variant="h2" className={classes.title}>
          Reset Password
        </Typography>
      </DialogTitle>
      <Formik
        initialValues={{ password: "", confirmPassword: "" }}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        {({
          handleChange,
          values,
          errors,
          touched,
          handleBlur,
          isSubmitting,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <DialogContent className={classes.dialogContent}>
              <Typography color="secondary" className={classes.title}>
                New Password
              </Typography>

              <TextField
                type="password"
                name="password"
                value={values.password}
                // placeholder="Email"
                // label="Email"
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={
                  touched.password && errors.password ? errors.password : " "
                }
                InputProps={{
                  classes: {
                    root: classes.inputBackgroundColor,
                  },
                }}
                error={touched.password && errors.password}
                variant="outlined"
                size="small"
                fullWidth
                FormHelperTextProps={{
                  className: classes.formHelperText,
                }}
              />
              <Typography color="secondary" className={classes.title}>
                Confirm New Password
              </Typography>

              <TextField
                type="password"
                name="confirmPassword"
                value={values.confirmPassword}
                // placeholder="Email"
                // label="Email"
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={
                  touched.confirmPassword && errors.confirmPassword
                    ? errors.confirmPassword
                    : " "
                }
                InputProps={{
                  classes: {
                    root: classes.inputBackgroundColor,
                  },
                }}
                error={touched.confirmPassword && errors.confirmPassword}
                variant="outlined"
                size="small"
                fullWidth
                FormHelperTextProps={{
                  className: classes.formHelperText,
                }}
              />
            </DialogContent>
            <DialogActions
              style={{
                position: "absolute",
                bottom: 4,
                right: 16,
              }}
            >
              <LoaderButton
                type="submit"
                // onClick={onSubmit}
                classoverrides={{
                  wrapper: classes.button,
                  button: {
                    root: classes.buttonRoot,
                  },
                }}
                variant="contained"
                color="secondary"
                working={isSubmitting}
                size="small"
              >
                Save
              </LoaderButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default ConfirmPassword
