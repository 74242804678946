import { Box, LinearProgress, Typography } from "@material-ui/core"
import CheckIcon from "@material-ui/icons/Check"
import classnames from "clsx"
import PropTypes from "prop-types"
import useStyles from "./styles"

const LinearProgressWithLabel = (props) => {
  const classes = useStyles()

  return (
    <Box display="flex" alignItems="center" paddingY={1}>
      <Box width="100%" mr={1}>
        <LinearProgress
          variant="determinate"
          {...props}
          className={classes.progress}
          classes={{
            bar1Determinate: classnames({
              [classes.success]: props.success,
            }),
          }}
        />
      </Box>
      <Box
        minWidth={35}
        minHeight={28}
        textAlign="right"
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        {!props.success && (
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontSize: "1rem" }}
          >{`${Math.round(props.value)}%`}</Typography>
        )}
        {props.success && <CheckIcon className={classes.successIcon} />}
      </Box>
    </Box>
  )
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
  success: PropTypes.bool,
}

export default LinearProgressWithLabel
