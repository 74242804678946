import { Box, LinearProgress } from "@material-ui/core"
import { listCourses } from "actions/courses"
import AdminActionBar from "components/Common/AdminActionBar"
import StatusFilters from "components/Common/StatusFilters"
import CourseCardList from "components/courses/CourseCardList"
import AppContext from "contexts/App"
import Courses from "contexts/Courses"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useContext, useEffect, useState } from "react"
import { useQuery } from "react-query"
import Router from "router"

import useStyles from "./styles"

const List = ({ routes = [], ...rest }) => {
  const classes = useStyles()
  const { openDialog, setPageSubTitle, setPageTitle } = useContext(AppContext)
  const { createCourseMutation } = useContext(Courses)
  //set serach value
  const [searchValue, setSearchValue] = useState("")
  //set sort data
  const [sort, setSort] = useState({
    name: "",
    type: "",
    direction: "",
    icon: "",
  })

  const filterOptions = [
    { id: 1, name: "Name" },
    { id: 2, name: "Lessons" },
    { id: 3, name: "Last Updated" },
  ]

  const { data: courses, isLoading: coursesLoading } = useQuery("courses", () =>
    listCourses()
  )

  //set courses title and subtitle
  useEffect(() => {
    setPageTitle("Courses")
    setPageSubTitle(courses?.length)
  }, [courses])

  //reusable dialog to create lessons, courses, clientes and instructor
  //pass a title, subtitle, type (for example course) a create mutation func and refecth data
  const openCreateDialog = () => {
    let data = {
      title: "New Course",
      subtitle: "Create a new Course",
      type: "course",
      create: createCourseMutation,
    }
    openDialog("createCourseLessonDialog", data)
  }
  //set courses status
  const [statusState, setStatusState] = useState("published")
  //set the length of each type of course
  const [statusNumber, setStatusNumber] = useState({
    published: null,
    unpublished: null,
    draft: null,
    archived: null,
    scheduled: null,
  })
  return (
    <Page>
      <AdminActionBar
        buttonName="New Course"
        buttonFunc={openCreateDialog}
        sortOptions={filterOptions}
        sortState={sort}
        setSortState={setSort}
        setFilterState={setSearchValue}
        searchText="Search courses"
      />
      {coursesLoading ? (
        <LinearProgress color="primary" />
      ) : (
        <Box display="flex" height="100%" className={classes.container}>
          <Box className={classes.leftContainer}>
            <StatusFilters
              setStatusState={setStatusState}
              statusState={statusState}
              statusNumber={statusNumber}
              type="courses"
            />
          </Box>

          <Box className={classes.rightContainer}>
            <CourseCardList
              searchValue={searchValue}
              sort={sort}
              openCreateDialog={openCreateDialog}
              statusState={statusState}
              setStatusNumber={setStatusNumber}
              courses={courses}
            />
          </Box>
        </Box>
      )}

      <Router routes={routes} {...rest} />
    </Page>
  )
}

List.propTypes = {
  routes: PropTypes.array,
}

export default List
