import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  headerContainer: {
    position: "relative",
    background: "#17A5B1",
    height: "140px",
    opacity: 0.88,
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      height: "180px",
    },
  },
  backgroundImage: {
    height: "140px",
  },

  title: {
    color: "white",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.4rem",
    },
  },
  saveContainer: {
    [theme.breakpoints.down("sm")]: {
      width: "60%",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      flexWrap: "wrap",
    },
  },
  copyButton: {
    color: "white",
    borderColor: "white",
    width: 42,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: "15%",
      marginLeft: 0,
      height: "30px",
    },
  },
  previewBtn: {
    color: "white",
    borderColor: "white",
    fontSize: 12,
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(1),
      width: "50%",
      height: "30px",
    },
  },
  saveBtn: {
    [theme.breakpoints.down("sm")]: {
      width: "25%",
      height: "30px",
    },
  },
  version: {
    color: "white",
    textDecoration: "underline",
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(2),
    },
  },
  lastUpdated: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "row",
    },
  },
  updated: {
    color: "white",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  creator: {
    color: "white",
    marginLeft: theme.spacing(0.5),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  createBtn: {
    color: "white",
    borderColor: "white",
    marginRight: theme.spacing(1),
    width: 171,
    fontSize: 12,
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      height: "30px",
    },
  },
  menu: {
    color: "white",
    width: 180,
    backgroundColor: theme.palette.primary.main,
    marginTop: "34px",
  },
  snackBar: {
    zIndex: 2000,
    backgroundColor: "#fff",
  },
  star: {
    color: "#fff",
    fontSize: "12px",
    marginLeft: theme.spacing(0.25),
    marginRight: theme.spacing(1),
  },
  detailBox: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "4px",
    },
  },
}))

export default styles
