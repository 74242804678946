import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core"
import Avatar from "components/Common/Avatar"
import App from "contexts/App"
import React, { useContext } from "react"
import useStyles from "./styles"

const ViewInstructorDialog = () => {
  const classes = useStyles()

  const { dialogs, closeDialog } = useContext(App)

  const thisDialog = dialogs?.["viewInstructorDialog"] || {}
  const { open = false, data } = thisDialog
  const instructor = thisDialog?.data?.instructor

  const handleClose = () => {
    closeDialog("viewInstructorDialog")
  }

  return (
    <Dialog open={open} maxWidth="xs">
      <DialogTitle disableTypography>
        <Typography color="primary" variant="h3" style={{ fontWeight: 600 }}>
          View Instructor
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          height={150}
          alignItems="center"
          className={classes.contentContainer}
        >
          <Box width={250} height={145}>
            <Avatar user={instructor && instructor} size={138} />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-evenly"
            marginLeft={2}
            width="100%"
            height="100%"
            className={classes.textFieldContainer}
          >
            <Typography color="secondary" className={classes.title}>
              Name
            </Typography>
            <TextField
              value={instructor?.name}
              variant="filled"
              size="small"
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.inputBackgroundColor,
                  disabled: classes.disabled,
                },
              }}
              className={classes.textField}
              disabled={true}
            />
            <Typography color="secondary" className={classes.title}>
              Title
            </Typography>
            <TextField
              value={instructor?.title}
              variant="filled"
              size="small"
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.inputBackgroundColor,
                  disabled: classes.disabled,
                },
              }}
              className={classes.textField}
              disabled={true}
            />
          </Box>
        </Box>
        <Box marginTop={2}>
          <Typography
            color="secondary"
            className={classes.title}
            style={{ marginBottom: "10px" }}
          >
            Bio
          </Typography>
          <TextField
            value={instructor?.bio}
            size="small"
            fullWidth
            multiline
            rows={12}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: classes.inputBio,
                disabled: classes.disabled,
              },
            }}
            disabled={true}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button style={{ margin: "16px" }} onClick={() => handleClose()}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ViewInstructorDialog
