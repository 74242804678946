import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  switchLabel: {
    color: theme.palette.secondary.main,
  },
  buttonLabel: {
    color: theme.palette.primary.main,
  },
  snackbarStyleViaContentProps: {
    background: theme.palette.warning.main,
  },
}))

export default styles
