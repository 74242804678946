import Page from "pages/Page"
import PropTypes from "prop-types"
import Router from "router"
import useStyles from "./styles"
import { Box } from "@material-ui/core"
import PathDetails from "components/Paths/PathDetails"
import CourseLessonContentContainer from "components/Common/CourseLessonContentContainer"
import PathCourses from "components/Paths/PathCourses"
import { listTags } from "actions/courses"
import { useQuery } from "react-query"
import Slide from "@material-ui/core/Slide"

function TransitionDown(props) {
  return <Slide {...props} direction="up" />
}

const Content = ({ routes = [], ...rest }) => {
  const classes = useStyles()
  const { data: tags } = useQuery("tags", () => listTags())

  return (
    <Page>
      <Box className={classes.container}>
        <CourseLessonContentContainer
          leftComponent={<PathDetails tags={tags} />}
          rightComponent={<PathCourses />}
        />
        <Router routes={routes} {...rest} />
      </Box>
    </Page>
  )
}

Content.propTypes = {
  routes: PropTypes.array,
}

export default Content
