import { Box, useMediaQuery, useTheme } from "@material-ui/core"
import loginBackgroundImage from "assets/login-background.png"
import logo from "assets/logo-in-words.svg"
import EmailSent from "components/EmailSent"
import ForgotPassword from "components/Forms/ForgotPassword"
import LoginForm from "components/Forms/LoginForm"
import Page from "pages/Page"
import { useState } from "react"

import useStyles from "./styles"

const Index = () => {
  const theme = useTheme()
  const classes = useStyles()

  const smDown = useMediaQuery(theme.breakpoints.down("sm"))

  //index used with loginStepList to render LoginForm or ForgotPassword
  const [loginStep, setLoginStep] = useState(0)
  const loginStepList = [
    <LoginForm key={1} setLoginStep={setLoginStep} />,
    <ForgotPassword key={2} setLoginStep={setLoginStep} />,
    <EmailSent key={3} setLoginStep={setLoginStep} />,
  ]

  return (
    <Page>
      <Box
        height="100vh"
        width="100vw"
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{
          backgroundImage: `url(${loginBackgroundImage})`,
          backgroundSize: "cover",
        }}
      >
        <Box
          className={smDown ? classes.formMobile : classes.formContainer}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Box display="flex" justifyContent="center" className={classes.logo}>
            <img src={logo} alt="Data Protocolo Logo" />
          </Box>
          {loginStepList[loginStep]}
        </Box>
      </Box>
    </Page>
  )
}

export default Index
