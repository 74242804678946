import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  dialogWrapper: {
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },

  contentContainer: {
    padding: "0px !important",
    // overflow: "scroll",
    maxHeight: "500px",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "700px",
    },
  },
  contentSubContainer: {
    padding: "18px",
    maxHeight: "470px",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      height: 120,
    },
  },
  title: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },

  textField: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(2),
    },
  },
  formHelperText: {
    marginLeft: "0px",
    color: "#f44336",
  },
  addNewCodeButton: {
    borderRadius: 0,
    marginTop: "10px",
  },
  buttonsContainer: {
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
      justifyContent: "center",
    },
  },
  dialogActions: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  topRowButton: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      marginBottom: theme.spacing(1),
    },
  },
  cancel: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      marginRight: theme.spacing(4),
    },
  },
  remove: {
    color: "#FF5E69",
    border: "1px solid #A21F28",
    marginRight: theme.spacing(1),
  },
  addIcon: {
    marginRight: theme.spacing(1),
    height: "15px",
    width: "15px",
  },
}))

export default styles
