import { Box, CircularProgress, Typography } from "@material-ui/core"
import NoData from "components/Common/NoData"
import CourseCard from "components/courses/CourseCard"
import AppContext from "contexts/App"
import PropTypes from "prop-types"
import { useContext, useEffect, useState } from "react"
import { useQuery } from "react-query"
import { useHistory } from "react-router-dom"
import sortHelper from "utils/sortHelper"
import PathCard from "../PathCard"
import useStyles from "./styles"
import InfiniteScroll from "react-infinite-scroll-component"

const PathCardList = ({
  searchValue,
  sort,
  openCreateDialog,
  paths,
  setStatusNumber,
  statusState,
}) => {
  const [filteredData, setFilteredData] = useState([])
  const classes = useStyles()
  const history = useHistory()
  const { setPageSubTitle } = useContext(AppContext)
  //amount of data that we show using the infinite scroll
  const [showedData, setShowedData] = useState([])

  const handleRoute = (id) => {
    history.push(`/learning-paths/${id}/content`)
  }

  //set draft, published, unpublished, archived length
  useEffect(() => {
    setStatusNumber({
      published: paths.filter((x) => x.published === 1).length,
      unpublished: paths.filter(
        (x) =>
          x.published === 0 && x.has_been_published === 1 && x.archived === 0
      ).length,
      draft: paths.filter(
        (x) =>
          x.published === 0 && x.has_been_published === 0 && x.archived === 0
      ).length,
      archived: paths.filter((x) => x.archived === 1 && x.published === 0)
        .length,
    })
  }, [paths])

  //filteres depending on the searchValue or sort
  useEffect(() => {
    if (paths) {
      let newArray = [...paths]

      if (statusState === "published") {
        newArray = newArray.filter((x) => x.published === 1)
      } else if (statusState === "unpublished") {
        newArray = newArray.filter(
          (x) =>
            x.published === 0 && x.has_been_published === 1 && x.archived === 0
        )
      } else if (statusState === "draft") {
        newArray = newArray.filter(
          (x) =>
            x.published === 0 && x.has_been_published === 0 && x.archived === 0
        )
      } else if (statusState === "archived") {
        newArray = newArray.filter((x) => x.archived === 1 && x.published === 0)
      }
      if (searchValue.length > 0 || sort?.name !== "") {
        if (searchValue.length > 0) {
          newArray = newArray?.filter((x) =>
            x.name.toLowerCase().includes(searchValue.toLowerCase())
          )
        }
        if (sort?.name !== "") {
          //util function to sort the data
          newArray = sortHelper(
            newArray,
            sort?.type,
            sort?.name,
            sort?.direction
          )
        }
      }

      setFilteredData(newArray)
      //show 20 first elements of the newArray
      setShowedData(newArray?.slice(0, 20 || []))
      //set courses  subtitle depends the search
      setPageSubTitle(newArray?.length)
    }
  }, [searchValue, sort, paths, statusState])

  //loadData using infinite scroll
  const loadData = () => {
    setShowedData(() => filteredData.slice(0, showedData.length + 20))
  }

  //send the user to the top if a status state, sort or search value change
  useEffect(() => {
    const element = document.getElementById("pathsListScroll")
    if (element) {
      element.scrollTo(0, 0)
    }
  }, [statusState, sort, searchValue])

  return (
    <Box className={classes.container} id="pathsListScroll">
      {filteredData?.length > 0 ? (
        <InfiniteScroll
          style={{ overflow: "hidden", height: "100%" }}
          dataLength={showedData?.length || 0}
          scrollableTarget="pathsListScroll"
          next={loadData}
          loader={
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          }
          hasMore={filteredData?.length > showedData?.length || false}
          endMessage={
            <Box width="100%" textAlign="center">
              {filteredData.length > 20 && (
                <Typography className={classes.postsInfiniteText}>
                  End of Learning Paths List
                </Typography>
              )}
            </Box>
          }
        >
          <Box display="flex" flexWrap="wrap" height="100%">
            {showedData?.map((path) => {
              return (
                <PathCard
                  path={path}
                  openPath={() => handleRoute(path.id)}
                  key={path.id}
                />
              )
            })}
          </Box>
        </InfiniteScroll>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          minHeight="80vh"
        >
          <NoData
            text="You have not created any learning path"
            buttonFunc={openCreateDialog}
            searchValue={searchValue}
            buttonText="create my first learning path"
            length={paths?.length}
            searchLength={filteredData?.length}
          />
        </Box>
      )}
    </Box>
  )
}

PathCardList.propTypes = {
  searchValue: PropTypes.string,
  sort: PropTypes.object,
  openCreateDialog: PropTypes.func,
  statusState: PropTypes.string,
  setStatusNumber: PropTypes.func,
  paths: PropTypes.array,
}

export default PathCardList
