import LoaderButton from "@bit/c_t.components.loader-button"
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg"
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  InputLabel,
  Slider,
  TextField,
  Typography,
} from "@material-ui/core"
import MediaIcon from "@material-ui/icons/PermMedia"
import { getLearningCueMedia } from "actions/lessons"
import {
  requestLearningCueMediaUpload,
  uploadLearningCueMedia,
} from "actions/upload"
import EditIcon from "assets/Icons/edit-icon.svg"
import { Image, Transformation } from "cloudinary-react"
import FileUpload from "components/Common/FileUpload"
import LinearProgressWithLabel from "components/Common/LinearProgressWithLabel"
import PlayerWithControls from "components/Common/PlayerWithControls"
import SimplePlayer from "components/Common/SimplePlayer"
import { default as App, default as AppContext } from "contexts/App"
import { VideoContainer } from "contexts/Video"
import useLearningCueMediaFileName from "hooks/useLearningCueMediaFileName"
import useTimeFormat from "hooks/useTimeFormat"
import { isEmpty } from "lodash"
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import Cropper from "react-easy-crop"
import { useMutation, useQuery, useQueryClient } from "react-query"
import getCroppedImg from "utils/cropImage.js"

import DialogTitle from "../components/DialogTitle"
import useStyles from "./styles"

const MediaUploadDialog = () => {
  const classes = useStyles()
  const videoPlayerRef = useRef()

  const { dialogs, closeDialog, openSnackBar } = useContext(App)
  const { openSnackbar } = useContext(AppContext)

  const thisDialog = dialogs?.["mediaUpload"] || {}
  const { open = false, data = {} } = thisDialog

  let mainVideoSecondsLeft = data?.lessonVideoDuration
  let videoProgress = data?.videoProgress
  let startSeconds = data?.startSeconds

  const lockdown = data?.lockdown

  const [innerDisplaySeconds, setInnerDisplaySeconds] = useState(5)
  const [duration, setDuration] = useState(0)

  const [mediaData, setMediaData] = useState(null)
  const [temporaryFiles, setTemporaryFiles] = useState({})

  const [uploadingVideo, setUploadingVideo] = useState(false)
  const [droppedFile, setDroppedFile] = useState(null)
  const [videoFile, setVideoFile] = useState(null)
  const [videoUrl, setVideoUrl] = useState(null)
  const [cropImage, setCropImage] = useState(null)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState("")
  const [uploadProgress, setUploadProgress] = useState()
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1.1)
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const [mediaCueKey, setMediaCueKey] = useState("")

  const getLearningCueMediaQuery = useQuery(
    ["getLearningCueMedia", mediaCueKey],
    () => getLearningCueMedia(mediaCueKey),
    {
      enabled: false,
      onSuccess: (res) => {
        if (data?.is_video) {
          setVideoUrl(res)
        } else {
          setMediaData(res)
        }
      },
    }
  )

  const handleLoadImage = () => {
    if (data?.id) {
      setMediaCueKey(data.id)
      setTimeout(() => {
        getLearningCueMediaQuery.refetch()
      }, 150)
    } else if (data?.key) {
      if (temporaryFiles[data.key] !== undefined) {
        if (data?.is_video) {
          setVideoUrl(temporaryFiles[data.key])
        } else {
          setMediaData(temporaryFiles[data.key])
        }
      }
    }
    if (data?.display_seconds) {
      setInnerDisplaySeconds(data.display_seconds)
    }
  }

  function getBase64(file) {
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      setCropImage(reader.result)
    }

    reader.onerror = function (error) {
      console.log("Error: ", error)
    }
  }

  const handleClose = () => {
    closeDialog("mediaUpload")
  }

  const requestLearningCueMediaUploadMutation = useMutation(
    "requestLearningCueMediaUpload",
    requestLearningCueMediaUpload
  )

  const handleUpload = async (file, uploadFile) => {
    // handle uploading
    const timestamp = +new Date()
    const mediaKey = `lessonMedia/${timestamp}-${file.name}`
    const deferred = new Promise((resolve, reject) => {
      setUploadingVideo(true)
      requestLearningCueMediaUploadMutation
        .mutateAsync({
          key: mediaKey,
          type: file.type,
        })
        .then((data) => {
          uploadLearningCueMedia(
            {
              ...data.data,
              file: uploadFile,
            },

            {
              onUploadProgress: (evt) => {
                var uploaded = Math.round((evt.loaded / evt.total) * 100)
                setUploadProgress(uploaded)
              },
            }
          )
            .then((uploadResponse) => {
              resolve()
              setUploadingVideo(false)
              setUploadProgress(0)
              closeDialog("mediaUpload", true, {
                ...data,
                key: mediaKey,
                display_seconds: innerDisplaySeconds || 30,
                is_video: videoFile !== null,
              })
              setTemporaryFiles((currentTempFiles) => ({
                ...currentTempFiles,
                [mediaKey]: URL.createObjectURL(uploadFile),
              }))
            })
            .catch((uploadErr) => {
              reject()
              setUploadingVideo(false)
              console.error(uploadErr)
              setUploadProgress(0)
              openSnackBar({
                message: "There was an error uploading this video",
              })
            })
        })
        .catch((err) => {
          reject()
          setUploadingVideo(false)
          console.error(err)
          setUploadProgress(0)
          openSnackBar({
            message: "There was an error uploading this media file",
          })
        })
    })
    return deferred
  }

  const handleSubmit = async (file) => {
    if (droppedFile || videoFile) {
      if (cropImage) {
        const newImage = await getCroppedImg(cropImage, croppedAreaPixels)
        let blob = await fetch(newImage).then((r) => r.blob())
        await handleUpload(droppedFile, blob)
        // reset
      } else if (videoFile) {
        await handleUpload(videoFile, videoFile)
      } else {
        openSnackBar({ message: "No media to upload" })
      }
    } else {
      closeDialog("mediaUpload", true, {
        ...data,
        display_seconds: innerDisplaySeconds || 30,
      })
    }
  }

  const handleDrop = (files) => {
    if (files[0].type.indexOf("image/") > -1) {
      setDroppedFile(files[0])
      getBase64(files[0])
    } else {
      setVideoFile(files[0])
      setVideoUrl(URL.createObjectURL(files[0]))
    }
  }

  const handleRemove = () => {
    closeDialog("mediaUpload", true, undefined)
  }
  const clearState = () => {
    setMediaCueKey("")
    setMediaData(null)
    setDroppedFile(null)
    setCropImage(null)
    setCroppedAreaPixels("")
    setUploadProgress(0)
    setCrop({ x: 0, y: 0 })
    setZoom(1.1)
    setVideoFile(null)
    setVideoUrl(null)
    setInnerDisplaySeconds(5)
  }

  const handleVideoDuration = (duration) => {
    setDuration(Math.floor(duration))
    if (innerDisplaySeconds === 0) {
      setInnerDisplaySeconds(Math.floor(duration))
    }
  }

  const handleSetDisplaySeconds = (e) => {
    let value = e.target.value
    if (value) {
      value = parseInt(value)
    }
    const max = videoUrl ? duration : data.displaySecondsMax
    if (value > max) {
      value = max
    }
    if (e.target.value !== "" && value < 1) {
      value = 0
    }
    setInnerDisplaySeconds(value)
  }

  const mediaKeyName = useLearningCueMediaFileName(data?.key)
  const videoLengthFormatted = useTimeFormat(duration)

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      onEntering={handleLoadImage}
      onExited={clearState}
    >
      <DialogTitle
        title="Select Media"
        subtitle="PNGs, JPEGs, or Videos"
        icon={
          <MediaIcon
            style={{
              position: "relative",
              top: 3,
              marginLeft: 8,
              color: "#17A5B1",
            }}
          />
        }
        timestamp={data.startSeconds}
        disableTypography
      />
      <DialogContent>
        <>
          {droppedFile && (
            <>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box className={classes.cropContainer}>
                  <Cropper
                    image={cropImage}
                    crop={crop}
                    zoom={zoom}
                    aspect={16 / 9}
                    minZoom={0.1}
                    onCropChange={setCrop}
                    restrictPosition={false}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </Box>
              </Box>
              <Box marginTop={1} display="flex" justifyContent="center">
                <Slider
                  value={zoom}
                  min={0.1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, zoom) => setZoom(zoom)}
                  style={{ width: "100%" }}
                />
              </Box>
            </>
          )}
          {!droppedFile && !videoUrl && (
            <>
              <FileUpload
                title="Upload media here"
                onDrop={handleDrop}
                accepted="image/*,video/*"
                showLoading={getLearningCueMediaQuery.isLoading}
              >
                {!isEmpty(mediaData) && (
                  <>
                    <Box className={classes.thumbnailBox}>
                      <Box className={classes.editBox}>
                        <img
                          src={EditIcon}
                          alt="Edit thumbnail"
                          style={{ width: 24 }}
                        />
                      </Box>
                      {mediaData?.indexOf?.("blob:") > -1 && (
                        <Box className={classes.tempMediaImgBox}>
                          <img
                            alt="media"
                            src={mediaData}
                            className={classes.tempMediaImg}
                          />
                        </Box>
                      )}
                      {mediaData?.indexOf?.("blob:") === -1 && (
                        <Image
                          publicId={mediaData}
                          style={{ verticalAlign: "bottom", width: "100%" }}
                        >
                          <Transformation
                            width="335"
                            crop="fill"
                            gravity="auto"
                            aspectRatio="16:9"
                          />
                        </Image>
                      )}
                    </Box>
                    {Boolean(data?.key || false) && (
                      <Typography variant="h4" className={classes.mediaKey}>
                        {mediaKeyName}
                      </Typography>
                    )}
                  </>
                )}
              </FileUpload>
            </>
          )}
          {videoUrl && (
            <>
              <SimplePlayer
                onDuration={handleVideoDuration}
                url={videoUrl}
                disableMute
                videoPlayerRef={videoPlayerRef}
              />
              {Boolean(data?.key || false) && (
                <Typography variant="h4" className={classes.mediaKey}>
                  {mediaKeyName}
                  {duration ? ` - ${videoLengthFormatted}` : ""}
                </Typography>
              )}
            </>
          )}
          {uploadProgress > 0 && (
            <LinearProgressWithLabel value={uploadProgress} color="secondary" />
          )}
          <InputLabel htmlFor="display-time" className={classes.inputLabel}>
            Display time
          </InputLabel>
          <Box display="flex" alignItems="center" maxWidth="50%">
            <FormControl fullWidth>
              <TextField
                id="display-time"
                name="display_seconds"
                type="number"
                variant="filled"
                max={videoUrl ? duration : data.displaySecondsMax}
                value={innerDisplaySeconds}
                onChange={handleSetDisplaySeconds}
                disabled={lockdown}
                InputProps={{
                  inputProps: {
                    max: `${Math.floor(mainVideoSecondsLeft - startSeconds)}`,
                    min: 1,
                  },
                }}
              />
            </FormControl>
            <Typography
              style={{
                flexShrink: 0,
                color: "#444444",
                opacity: 0.3,
                marginLeft: 8,
                marginBottom: 4,
              }}
            >
              {`In seconds (${Math.floor(
                mainVideoSecondsLeft - startSeconds
              )} seconds left)`}
            </Typography>
          </Box>
        </>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Box>
          {!isEmpty(data) && (
            <Button
              className={classes.removeButton}
              onClick={handleRemove}
              type="button"
              size="small"
              disabled={lockdown}
            >
              Remove
            </Button>
          )}
        </Box>
        <Box>
          <Button
            onClick={handleClose}
            type="button"
            style={{ fontSize: "0.75rem", marginRight: "2vw" }}
          >
            Close
          </Button>
          <LoaderButton
            disableElevation
            color="secondary"
            variant="contained"
            onClick={handleSubmit}
            type="button"
            style={{ fontSize: "0.75rem" }}
            working={uploadingVideo}
            disabled={lockdown}
          >
            {droppedFile || videoFile ? "Upload" : "Done"}
          </LoaderButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default MediaUploadDialog
