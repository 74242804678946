import LoaderButton from "@bit/c_t.components.loader-button"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  Typography,
} from "@material-ui/core"
import { ReactComponent as BottomVideoBannerIcon } from "assets/Icons/bottom-video-banner-icon.svg"
import CounterHeader from "components/Common/CounterHeader"
import App from "contexts/App"
import { Field, Formik } from "formik"
import { TextField } from "formik-material-ui"
import { isEmpty } from "lodash"
import { useCallback, useContext, useMemo } from "react"
import * as Yup from "yup"

import DialogTitle from "../components/DialogTitle"
import useStyles from "./styles"

const BottomVideoBannerDialog = () => {
  const classes = useStyles()

  const { dialogs, closeDialog } = useContext(App)
  const thisDialog = dialogs?.["bottomVideoBanner"] || {}
  const { open = false, data = {} } = thisDialog
  const { lockdown, ...restData } = data

  let mainVideoSecondsLeft = thisDialog?.data?.lessonVideoDuration
  let videoProgress = thisDialog?.data?.videoProgress
  let startSeconds = thisDialog?.data?.startSeconds

  const handleClose = () => {
    closeDialog("bottomVideoBanner")
  }

  const schema = useMemo(() => {
    return Yup.object({
      header: Yup.string("Header is required")
        .required("Header is required")
        .max(255, "Header must be less than 255 characters"),
      body: Yup.string().max(255, "Body must be less than 255 characters"),
      display_seconds: Yup.number().moreThan(
        0,
        "Display seconds should be greater than 0"
      ),
    })
  }, [])

  const handleSubmit = useCallback((values, actions) => {
    if (!values.body) {
      values.body = ""
    }
    closeDialog("bottomVideoBanner", true, values)
    actions.setSubmitting(false)
  }, [])

  const initialValues = useMemo(() => {
    // new banners getting restData, but don't have id
    if (!isEmpty(restData) && !restData.id) {
      return {
        header: "", // sets default which can be overridden by ...restData
        body: "", // sets default which can be overridden by ...restData
        ...restData,
        display_seconds:
          mainVideoSecondsLeft - startSeconds < 5
            ? parseInt(mainVideoSecondsLeft - startSeconds)
            : 5,
      }
      // id only pre-existing bottom banner info
    } else if (!isEmpty(restData) && restData.id) {
      return restData
    } else {
      return {
        header: "",
        body: "",
        display_seconds: "",
      }
    }
  }, [restData])

  const handleRemove = () => {
    closeDialog("bottomVideoBanner", true, undefined)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ submitForm, resetForm, values }) => (
        <Dialog open={open} onClose={handleClose} onExited={resetForm}>
          <DialogTitle
            title="Bottom Video Banner"
            subtitle="Banner across the bottom of the video"
            icon={
              <BottomVideoBannerIcon
                style={{
                  position: "relative",
                  top: 3,
                  marginLeft: 10,
                  color: "#E32B6E",
                }}
              />
            }
            disableTypography
            timestamp={data.startSeconds}
          />
          <DialogContent>
            <CounterHeader
              title={" " + "Header"}
              value={values.header}
              max={255}
            />
            <Field
              component={TextField}
              variant="filled"
              name="header"
              fullWidth
              style={{ marginBottom: 24 }}
              disabled={lockdown}
            />
            <CounterHeader title={" " + "Body"} value={values.body} max={255} />
            <Field
              component={TextField}
              variant="filled"
              name="body"
              fullWidth
              style={{ marginBottom: 24 }}
              disabled={lockdown}
            />
            <InputLabel htmlFor="display-time" className={classes.inputLabel}>
              Display time
            </InputLabel>
            <Box display="flex" alignItems="center" maxWidth="50%">
              <FormControl fullWidth style={{ display: "flex" }}>
                <Field
                  component={TextField}
                  type="number"
                  name="display_seconds"
                  variant="filled"
                  disabled={lockdown}
                  InputProps={{
                    inputProps: {
                      max: `${Math.floor(mainVideoSecondsLeft - startSeconds)}`,
                      min: 1,
                    },
                  }}
                />
              </FormControl>
              <Typography
                style={{
                  flexShrink: 0,
                  color: "#444444",
                  opacity: 0.3,
                  marginLeft: 8,
                  marginBottom: 14,
                }}
              >
                {`In seconds (${Math.floor(
                  mainVideoSecondsLeft - startSeconds
                )} seconds left)`}{" "}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions style={{ justifyContent: "space-between" }}>
            <Box>
              {!isEmpty(restData) && (
                <Button
                  className={classes.removeButton}
                  onClick={handleRemove}
                  type="button"
                  size="small"
                  disabled={lockdown}
                >
                  Remove
                </Button>
              )}
            </Box>
            <Box>
              <Button
                onClick={handleClose}
                type="button"
                style={{ marginRight: "2vw" }}
              >
                Close
              </Button>
              <LoaderButton
                variant="contained"
                color="secondary"
                onClick={submitForm}
                type="button"
                disabled={lockdown}
              >
                Done
              </LoaderButton>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  )
}

export default BottomVideoBannerDialog
