import { Box, CircularProgress, Typography } from "@material-ui/core"
import NoData from "components/Common/NoData"
import AppContext from "contexts/App"
import PropTypes from "prop-types"
import { useContext, useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useHistory } from "react-router-dom"
import sortHelper from "utils/sortHelper"

import CertificationCard from "../CertificationCard"
import useStyles from "./styles"

const CertificationCardList = ({
  searchValue,
  sort,
  openCreateDialog,
  certifications,
  setStatusNumber,
  statusState,
}) => {
  const [filteredData, setFilteredData] = useState([])
  const classes = useStyles()
  const history = useHistory()
  const { setPageSubTitle } = useContext(AppContext)
  //amount of data that we show using the infinite scroll
  const [showedData, setShowedData] = useState([])

  const handleRoute = (id) => {
    history.push(`/certifications/${id}/content`)
  }

  //set statuses
  useEffect(() => {
    setStatusNumber({
      published: certifications.filter((x) => x.published).length,
      draft: certifications.filter((x) => !x.published && !x.has_been_published)
        .length,
      unpublished: certifications.filter(
        (x) => !x.published && x.has_been_published
      ).length,
    })
  }, [certifications])

  //filters depending on the searchValue or sort
  useEffect(() => {
    if (certifications) {
      let newArray = [...certifications]

      if (statusState === "published") {
        newArray = newArray.filter((x) => x.published)
      } else if (statusState === "unpublished") {
        newArray = newArray.filter((x) => !x.published && x.has_been_published)
      } else {
        newArray = newArray.filter((x) => !x.published && !x.has_been_published)
      }
      if (searchValue.length > 0 || sort?.name !== "") {
        if (searchValue.length > 0) {
          newArray = newArray?.filter((x) =>
            x.name.toLowerCase().includes(searchValue.toLowerCase())
          )
        }
        if (sort?.name !== "") {
          //util function to sort the data
          newArray = sortHelper(
            newArray,
            sort?.type,
            sort?.name,
            sort?.direction
          )
        }
      }
      setFilteredData(newArray)
      //show 20 first elements of the newArray
      setShowedData(newArray?.slice(0, 20 || []))
      //set labs  subtitle depends the search
      setPageSubTitle(newArray?.length)
    }
  }, [searchValue, sort, certifications, statusState])

  //loadData using infinite scroll
  const loadData = () => {
    setShowedData(() => filteredData.slice(0, showedData.length + 20))
  }

  //send the user to the top if a status state, sort or search value change
  useEffect(() => {
    const element = document.getElementById("certificationListScroll")
    if (element) {
      element.scrollTo(0, 0)
    }
  }, [statusState, sort, searchValue])

  return (
    <Box className={classes.container} id="certificationListScroll">
      {filteredData?.length > 0 ? (
        <InfiniteScroll
          style={{ overflow: "hidden", height: "100%" }}
          dataLength={showedData?.length || 0}
          scrollableTarget="certificationListScroll"
          next={loadData}
          loader={
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          }
          hasMore={filteredData?.length > showedData?.length || false}
          endMessage={
            <Box width="100%" textAlign="center">
              {filteredData.length > 20 && (
                <Typography className={classes.postsInfiniteText}>
                  End of Certifications List
                </Typography>
              )}
            </Box>
          }
        >
          <Box display="flex" flexWrap="wrap" height="100%">
            {showedData?.map((cert) => {
              return (
                <CertificationCard
                  key={cert.id}
                  certification={cert}
                  openCert={() => handleRoute(cert.id)}
                />
              )
            })}
          </Box>
        </InfiniteScroll>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          minHeight="80vh"
        >
          <NoData
            text="You have not created any learning path"
            buttonFunc={openCreateDialog}
            searchValue={searchValue}
            buttonText="create my first learning path"
            length={certifications?.length}
            searchLength={filteredData?.length}
          />
        </Box>
      )}
    </Box>
  )
}

CertificationCardList.propTypes = {
  searchValue: PropTypes.string,
  sort: PropTypes.object,
  openCreateDialog: PropTypes.func,
  statusState: PropTypes.string,
  setStatusNumber: PropTypes.func,
  certifications: PropTypes.array,
}

export default CertificationCardList
