import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  playhead: {
    width: 1,
    height: 56,
    backgroundColor: theme.palette.secondary.main,
    position: "absolute",
    left: 0,
    top: 0,
    cursor: "pointer",
    border: "none",
    outline: "none",
    padding: 0,
    zIndex: 2,
    "&:after": {
      top: 0,
      left: -16,
      content: "''",
      position: "absolute",
      height: "calc(100% - 8px)",
      padding: "0 16px",
    },
  },
  thumbComponentIcon: {
    position: "absolute",
    top: 22.5,
    left: "calc(50% + 0.5px)",
    transform: "translate(-50%, -50%)",
  },
  playheadButton: {
    minWidth: "initial",
    width: 18,
    height: 16,
    padding: 0,
    position: "absolute",
    bottom: -14,
    left: -8.5,
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
    borderRadius: 3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  playheadTime: {
    fontSize: "0.5625rem",
    fontWeight: 700,
    position: "absolute",
    top: -19,
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: "#F5F5F5",
  },
}))

export default styles
