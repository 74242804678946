import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  dialogMinHeight: {
    minHeight: "331px",
  },
  inputBackgroundColor: {
    backgroundColor: "#F4F4F4",
  },
  buttonRoot: {
    height: "40px",
  },
  formHelperText: {
    marginLeft: "0px",
  },
}))

export default styles
