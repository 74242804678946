import { Box, Typography } from "@material-ui/core"
import AnalyticsFilters from "components/Analytics/AnalyticsFilters"
import AnalyticsTable from "components/Analytics/AnalyticsTable"
import AppContext from "contexts/App"
import PathContext from "contexts/Path"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useEffect } from "react"
import { useContext } from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import Router from "router"

import useStyles from "./styles"

const Analytics = ({ routes = [], ...rest }) => {
  const classes = useStyles()
  const history = useHistory()

  const { authUser } = useContext(AppContext)
  const { analyticsMutation, path } = useContext(PathContext)

  const headCells = [
    {
      title: "USER ID",
      field: "user_id",
      headerStyle: {
        width: "5%",
        maxWidth: "5%",
        padding: "5px 20px",
      },
      cellStyle: {
        width: "5%",
        maxWidth: "5%",
        height: "25px",
        maxHeight: "25px",
        padding: "5px 20px",
      },
    },
    {
      title: "User Name",
      field: "username",

      headerStyle: {
        width: "20%",
        maxWidth: "20%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "20%",
        maxWidth: "20%",
        maxHeight: "25px",
        height: "25px",

        padding: "5px 10px",
      },
    },
    {
      title: "First Name",
      field: "first_name",
      headerStyle: {
        width: "10%",
        maxWidth: "10%",
        height: "25px",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "10%",
        maxWidth: "10%",
        maxHeight: "25px",
        height: "25px",

        padding: "5px 10px",
      },
    },
    {
      title: "Last Name",
      field: "last_name",
      headerStyle: {
        width: "10%",
        maxWidth: "10%",
        height: "25px",

        padding: "5px 10px",
      },
      cellStyle: {
        width: "10%",
        maxWidth: "10%",
        maxHeight: "25px",
        height: "25px",

        padding: "5px 10px",
      },
    },
    {
      title: "Email",
      field: "email",
      headerStyle: {
        width: "20%",
        maxWidth: "20%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "20%",
        maxWidth: "20%",
        maxHeight: "25px",
        height: "25px",

        padding: "5px 10px",
      },
    },
    {
      title: "Courses Completed",
      field: "courses_completed",
      headerStyle: {
        width: "5%",
        maxWidth: "5%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "5%",
        maxWidth: "5%",
        height: "25px",
        maxHeight: "25px",
        padding: "5px 10px",
      },
    },
    {
      title: "Progress",
      field: "progress",
      headerStyle: {
        width: "5%",
        maxWidth: "5%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "5%",
        maxWidth: "5%",
        height: "25px",
        maxHeight: "25px",
        padding: "5px 10px",
      },
    },
    {
      title: "Recieved Badge",
      field: "has_badge",
      headerStyle: {
        width: "5%",
        maxWidth: "5%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: "5%",
        maxWidth: "5%",
        height: "25px",
        maxHeight: "25px",
        padding: "5px 10px",
      },
    },
  ]

  const fields = [
    "user_id",
    "username",
    "first_name",
    "last_name",
    "email",
    "courses_completed",
    "progress",
    "has_badge",
  ]

  //checks auth user role
  useEffect(() => {
    if (authUser?.access_analytics !== 1) {
      history.push("/learning-paths")
    }
  }, [authUser])

  return (
    <Page>
      <Box className={classes.container}>
        {/* <AnalyticsFilters type="path" /> */}
        <AnalyticsTable
          headCells={headCells}
          mutation={analyticsMutation}
          type="Learning Path"
          data={path}
          fields={fields}
        />
        <Router routes={routes} {...rest} />
      </Box>
    </Page>
  )
}

Analytics.propTypes = {
  routes: PropTypes.array,
}

export default Analytics
