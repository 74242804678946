import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  column: {
    width: "150px",
    paddingRight: theme.spacing(2),
  },
  columnLabel: {
    color: theme.palette.secondary.main,
    fontWeight: 700,
    fontSize: "0.75rem",
  },
}))

export default styles
