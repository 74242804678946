import { Chip } from "@material-ui/core"
import PropTypes from "prop-types"
import React from "react"

const Tag = ({ tagName, deleteTag }) => {
  return (
    <Chip
      style={{ marginRight: "8px", marginTop: "16px" }}
      label={tagName}
      onDelete={() => deleteTag()}
      color="secondary"
      size="small"
    />
  )
}

Tag.propTypes = {
  tagName: PropTypes.string,
  deleteTag: PropTypes.func,
}

export default Tag
