import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  responseList: {
    marginBottom: theme.spacing(2),
  },
  isEdit: {
    "& $responseItem": {
      marginBottom: theme.spacing(1),
      "&:hover": {
        "& $removeButton": {
          opacity: 1,
        },
      },
    },
  },
  responseItem: {
    display: "flex",
  },
  radio: {
    padding: 0,
    paddingLeft: 3,
    paddingRight: 6,
    "& .MuiSvgIcon-root": {
      fontSize: 12,
    },
    "&:hover": {
      backgroundColor: ["transparent", "!important"],
    },
  },
  radioChecked: {
    color: [theme.palette.success.main, "!important"],
  },
  removeButton: {
    opacity: 0,
    transition: theme.transitions.create("opacity"),
  },
  removeIcon: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
}))

export default styles
