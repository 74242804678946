import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
    alignItems: "center",
    position: "relative",
  },
  disabled: {
    opacity: 0.5,
  },
  titleContainer: {
    cursor: "pointer",
    zIndex: "10",
    display: "flex",
    alignItems: "center",
  },
  buttonLabel: {
    color: theme.palette.primary.main,
  },
  nameText: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    wordBreak: "break-word",
  },
  spinnerBox: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    zIndex: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))

export default styles
