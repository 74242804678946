import { Box, Divider, Typography } from "@material-ui/core"
import PropTypes from "prop-types"
import React from "react"

import useStyles from "./styles"

const LabStatusFilters = ({ setStatusState, statusState, statusNumber }) => {
  const classes = useStyles()

  const statuses = [
    { type: "locked", label: "Locked" },
    { type: "unlocked", label: "Unlocked" },
  ]

  return (
    <Box className={classes.container}>
      {statuses.map((status, i) => {
        return (
          <Box className={classes.buttonContainer} key={i}>
            <Typography
              className={classes.button}
              onClick={() => setStatusState(status.type)}
              style={
                statusState === status.type
                  ? { color: "#092958", fontWeight: "bold" }
                  : { color: "#808080" }
              }
            >
              {status.label}{" "}
              {statusNumber[status.type] ? statusNumber[status.type] : ""}
            </Typography>
            <Divider />
          </Box>
        )
      })}
    </Box>
  )
}

LabStatusFilters.propTypes = {
  setStatusState: PropTypes.func,
  statusState: PropTypes.string,
  statusNumber: PropTypes.object,
}

export default LabStatusFilters
