const styles = (theme) => ({
  notFoundContainer: {
    width: "100%",
    height: "100%",
  },
  logoContainer: {
    width: "100%",
    padding: theme.spacing(4),
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
  },
  logo: {
    width: "200px ",
  },
  textContainer: {
    textAlign: "center",
  },
  notFoundNumbers: {
    marginTop: theme.spacing(8),
    fontSize: "150px",
    color: theme.palette.primary.default,
    [theme.breakpoints.down("md")]: {
      fontSize: "100px",
    },
  },

  notFoundText: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(5),
    color: theme.palette.primary.default,
  },
  backButton: {
    backgroundColor: theme.palette.primary.default,
    "&:hover": {
      backgroundColor: theme.palette.primary.default,
    },
    color: "#fff",
  },
})

export default styles

// const styles = (theme) => ({
//   notFound: {
//     display: "flex",
//     flexDirection: "column",
//   },
//   textSection: {
//     textAlign: "center",
//   },
//   text: {
//     lineHeight: "1em",
//     marginBottom: theme.spacing(4),
//     fontFamily: "'Oswald'",
//     fontSize: "2.4rem",
//   },
// })

// export default styles
