import { createDiscount } from "actions/discounts"
import { setDiscountCourses } from "actions/discounts"
import { updateDiscount } from "actions/discounts"
import { removeDiscount } from "actions/discounts"
import PropTypes from "prop-types"
import { createContext } from "react"
import { useMutation, useQuery } from "react-query"

const DiscountsContext = createContext()
export default DiscountsContext

export const DiscountsContainer = ({ children }) => {
  const updateDiscountMutation = useMutation((data) =>
    updateDiscount(data.data, data.id)
  )
  const setDiscountCoursesMutatation = useMutation((data) =>
    setDiscountCourses(data.courses, data.id)
  )

  const removeDiscountMutation = useMutation((id) => removeDiscount(id))

  return (
    <DiscountsContext.Provider
      value={{
        updateDiscountMutation,
        setDiscountCoursesMutatation,
        removeDiscountMutation,
      }}
    >
      {children}
    </DiscountsContext.Provider>
  )
}

DiscountsContainer.propTypes = {
  children: PropTypes.array,
}
