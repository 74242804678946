import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(2, 1),
    position: "relative",
  },
  collapse: {
    border: "1px solid transparent",
    backgroundColor: "#F4F4F4",
    borderRadius: 4,
    marginBottom: theme.spacing(1.75),
  },
  lockdown: {
    borderColor: ["transparent", "!important"],
  },
  isEdit: {
    backgroundColor: "transparent",
    borderColor: theme.palette.secondary.main,
  },
  isError: {
    borderColor: theme.palette.error.main,
  },
  button: {
    fontSize: "0.75rem",
    marginBottom: theme.spacing(2),
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  removeButton: {
    color: theme.palette.error.main,
  },
  inputBox: {
    marginBottom: theme.spacing(2),
  },
  inputLabel: {
    color: theme.palette.secondary.main,
    fontWeight: 700,
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  input: {
    padding: 6,
    minHeight: 20,
  },
  select: {
    paddingTop: 10,
    paddingBottom: 10,
    color: theme.palette.secondary.main,
  },
  selectIcon: {
    color: theme.palette.secondary.main,
  },
  attemptsText: {
    lineHeight: "12px",
    color: theme.palette.text.primary,
  },
  contentEditable: {
    outline: "none",
    position: "relative",
    transition: "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
    borderRadius: "6px",
    backgroundColor: "#F4F4F4",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    padding: 6,
    fontSize: "0.75rem",
  },
  fillInTheBlankToken: {
    color: theme.palette.secondary.main,
    cursor: "default",
    padding: "3px 4px",
    borderRadius: "4px",
    whiteSpace: "pre",
    "&:hover": {
      backgroundColor: "rgb(23, 165, 177, 0.18)",
    },
  },
  buttonsBox: {
    position: "absolute",
    top: 2,
    right: 2,
  },
}))

export default styles
