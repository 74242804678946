import { Box, CircularProgress, Typography } from "@material-ui/core"
import NoData from "components/Common/NoData"
import CourseCard from "components/courses/CourseCard"
import AppContext from "contexts/App"
import PropTypes from "prop-types"
import { useContext, useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useHistory } from "react-router-dom"
import sortHelper from "utils/sortHelper"

import useStyles from "./styles"

const CourseCardList = ({
  searchValue,
  sort,
  openCreateDialog,
  statusState,
  setStatusNumber,
  courses,
}) => {
  const [filteredData, setFilteredData] = useState([])
  const classes = useStyles()
  const history = useHistory()
  const { setPageSubTitle } = useContext(AppContext)
  //amount of data that we show using the infinite scroll
  const [showedData, setShowedData] = useState([])

  const handleRoute = (id) => {
    history.push(`/courses/${id}`)
  }

  //set draft, published, unpublished, archived length
  useEffect(() => {
    setStatusNumber({
      published: courses.filter((x) => x.published === 1).length,
      unpublished: courses.filter(
        (x) =>
          x.published === 0 && x.has_been_published === 1 && x.archived === 0
      ).length,
      draft: courses.filter(
        (x) =>
          x.published === 0 &&
          x.has_been_published === 0 &&
          x.archived === 0 &&
          !x.publish_date
      ).length,
      archived: courses.filter((x) => x.archived === 1 && x.published === 0)
        .length,
      scheduled: courses.filter(
        (x) =>
          x.publish_date &&
          x.published === 0 &&
          x.archived === 0 &&
          x.has_been_published === 0
      ).length,
    })
  }, [courses])

  //filteres depending on the searchValue or sort
  useEffect(() => {
    if (courses) {
      let newArray = [...courses]

      if (statusState === "published") {
        newArray = newArray.filter((x) => x.published === 1)
      } else if (statusState === "unpublished") {
        newArray = newArray.filter(
          (x) =>
            x.published === 0 && x.has_been_published === 1 && x.archived === 0
        )
      } else if (statusState === "draft") {
        newArray = newArray.filter(
          (x) =>
            x.published === 0 &&
            x.has_been_published === 0 &&
            x.archived === 0 &&
            !x.publish_date
        )
      } else if (statusState === "archived") {
        newArray = newArray.filter((x) => x.archived === 1 && x.published === 0)
      } else if (statusState === "scheduled") {
        newArray = newArray.filter(
          (x) =>
            x.published === 0 &&
            x.has_been_published === 0 &&
            x.archived === 0 &&
            x.publish_date
        )
      }
      if (searchValue.length > 0 || sort?.name !== "") {
        if (searchValue.length > 0) {
          newArray = newArray?.filter((x) =>
            x.name.toLowerCase().includes(searchValue.toLowerCase())
          )
        }
        if (sort?.name !== "") {
          //util function to sort the data
          newArray = sortHelper(
            newArray,
            sort?.type,
            sort?.name,
            sort?.direction
          )
        }
      }

      setFilteredData(newArray)
      //show 20 first elements of the newArray
      setShowedData(newArray?.slice(0, 20 || []))
      //set courses  subtitle depends the search
      setPageSubTitle(newArray?.length)
    }
  }, [searchValue, sort, courses, statusState])

  //loadData using infinite scroll
  const loadData = () => {
    setShowedData(() => filteredData.slice(0, showedData.length + 20))
  }

  //send the user to the top if a status state, sort or search value change
  useEffect(() => {
    const element = document.getElementById("coursesListScroll")
    if (element) {
      element.scrollTo(0, 0)
    }
  }, [statusState, sort, searchValue])

  return (
    <Box className={classes.container} id="coursesListScroll">
      {filteredData?.length > 0 ? (
        <InfiniteScroll
          style={{ overflow: "hidden" }}
          dataLength={showedData?.length || 0}
          scrollableTarget="coursesListScroll"
          next={loadData}
          loader={
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          }
          hasMore={filteredData?.length > showedData?.length || false}
          endMessage={
            <Box width="100%" textAlign="center">
              {filteredData.length > 20 && (
                <Typography className={classes.postsInfiniteText}>
                  End of Courses List
                </Typography>
              )}
            </Box>
          }
        >
          <Box display="flex" flexWrap="wrap">
            {showedData?.map((course) => {
              return (
                <CourseCard
                  course={course}
                  openCourse={() => handleRoute(course.id)}
                  key={course.id}
                  lessonLength=""
                />
              )
            })}
          </Box>
        </InfiniteScroll>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          minHeight="80vh"
        >
          <NoData
            text="You have not created any courses"
            buttonFunc={openCreateDialog}
            searchValue={searchValue}
            buttonText="create my first course"
            length={courses?.length}
            searchLength={filteredData?.length}
          />
        </Box>
      )}
    </Box>
  )
}

CourseCardList.propTypes = {
  searchValue: PropTypes.string,
  sort: PropTypes.object,
  openCreateDialog: PropTypes.func,
  statusState: PropTypes.string,
  setStatusNumber: PropTypes.func,
  courses: PropTypes.array,
}

export default CourseCardList
