import LoaderButton from "@bit/c_t.components.loader-button"
import { Box, TextField, Typography } from "@material-ui/core"
import { login } from "actions/authentication"
import { magicAuth } from "actions/users"
import App from "contexts/App"
import { Form, Formik } from "formik"
import PropTypes from "prop-types"
import { useContext, useEffect } from "react"
import { useMutation } from "react-query"
import { useHistory, useLocation } from "react-router-dom"
import * as Yup from "yup"

import useStyles from "./styles"

const LoginForm = ({ setLoginStep }) => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const authContext = useContext(App)
  const loginMutation = useMutation(login)
  const magicAuthMutation = useMutation(magicAuth)

  //checking query string for magic code/hash
  useEffect(() => {
    const queryString = location.search
    const params = new URLSearchParams(queryString)
    const hash = params.get("magic_hash")
    const code = params.get("magic_code")
    if (hash && code) {
      magicAuthMutation.mutate(
        { magic_hash: hash, magic_code: code },
        {
          //opens confirm password dialog to reset password, sends magic hash/code as data
          onSuccess: (res) => {
            authContext.setAuthToken(res.token)
            authContext.openDialog("confirmPassword", {
              magic_hash: hash,
              magic_code: code,
            })
          },
          onError: (err) => {
            console.error(err)
            authContext.openSnackBar({
              message: "There was an error logging in with the link",
            })
          },
        }
      )
    }
  }, [])

  //change the loginState to show the ForgotPassword Component
  const openForgotPassword = () => {
    setLoginStep(1)
  }

  const loginSchema = Yup.object().shape({
    email: Yup.string().email().required("Email address is required"),
    password: Yup.string().required("Password is required"),
  })

  //hit the admin/users/auth endpoint to login
  const onSubmit = (values, actions) => {
    loginMutation.mutate(values, {
      onSuccess: (res) => {
        authContext.setAuthToken(res.token)
      },
      onError: (err) => {
        console.error(err)
        authContext.openSnackBar({
          message: "There was an error logging in",
        })
      },
      onSettled: () => {
        actions.setSubmitting(false)
      },
    })
  }

  // //listen for an authcontext change, redirects user to courses page.
  useEffect(() => {
    if (authContext && authContext.token) {
      history.push("/courses")
    }
  }, [authContext])

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      onSubmit={onSubmit}
      validationSchema={loginSchema}
    >
      {({
        handleChange,
        values,
        errors,
        touched,
        handleBlur,
        isSubmitting,
        handleSubmit,
      }) => (
        <div className={classes.inputContainer}>
          <Form onSubmit={handleSubmit}>
            <Typography color="secondary" className={classes.title}>
              Email
            </Typography>
            <TextField
              type="email"
              name="email"
              id="email"
              value={values.email}
              // placeholder="Email"
              // label="Email"
              onChange={handleChange}
              onBlur={handleBlur}
              className={classes.textField}
              helperText={touched.email && errors.email ? errors.email : " "}
              InputProps={{
                classes: {
                  root: classes.inputBackgroundColor,
                },
              }}
              error={touched.email && errors.email}
              variant="outlined"
              size="small"
              fullWidth
              FormHelperTextProps={{
                className: classes.formHelperText,
              }}
            />
            <Box position="relative">
              <Typography color="secondary" className={classes.title}>
                Password
              </Typography>
              <TextField
                type="password"
                name="password"
                id="password"
                value={values.password}
                // placeholder="Password"
                // label="Password"
                onChange={handleChange}
                onBlur={handleBlur}
                className={classes.textField}
                helperText={
                  touched.password && errors.password ? errors.password : " "
                }
                InputProps={{
                  classes: {
                    root: classes.inputBackgroundColor,
                  },
                }}
                error={touched.password && errors.password}
                variant="outlined"
                size="small"
                fullWidth
                FormHelperTextProps={{
                  className: classes.formHelperText,
                }}
              />
              <Typography
                className={classes.forgotPasswordText}
                onClick={openForgotPassword}
              >
                Forgot Password?
              </Typography>
            </Box>

            <Box display="flex" justifyContent="center">
              <LoaderButton
                type="submit"
                classoverrides={{
                  wrapper: classes.button,
                  button: {
                    root: classes.buttonRoot,
                  },
                }}
                variant="contained"
                color="secondary"
                working={isSubmitting}
                size="small"
              >
                Sign in
              </LoaderButton>
            </Box>
          </Form>
        </div>
      )}
    </Formik>
  )
}

LoginForm.propTypes = {
  setLoginStep: PropTypes.func,
}

export default LoginForm
