import { Box, IconButton } from "@material-ui/core"
import Forward10Icon from "@material-ui/icons/Forward10"
import PauseIcon from "@material-ui/icons/Pause"
import PlayArrowIcon from "@material-ui/icons/PlayArrow"
import Replay10Icon from "@material-ui/icons/Replay10"
import VolumeOffIcon from "@material-ui/icons/VolumeOff"
import VolumeUpIcon from "@material-ui/icons/VolumeUp"
import VideoContext from "contexts/Video"
import PropTypes from "prop-types"
import { useCallback, useContext, useState } from "react"
import ReactPlayer from "react-player"
import urlPropType from "url-prop-type"

import VideoPlaybar from "../VideoPlaybar"
import useStyles from "./styles"

const PlayerWithControls = ({
  url,
  poster,
  ReactPlayerProps = {},
  duration,
  ...props
}) => {
  const classes = useStyles()

  const [muted, setMuted] = useState(false)

  const {
    playing,
    togglePlayPause,
    videoProgress,
    setVideoProgressFromInput,
    setVideoPlayhead,
    seek,
  } = useContext(VideoContext)

  const handleReplay10 = useCallback(() => {
    seek.subtract(10)
  }, [seek])

  const handleForward10 = useCallback(() => {
    seek.add(10)
  }, [seek])

  const handleToggleMute = useCallback(() => {
    setMuted(!muted)
  }, [muted])

  const handleChange = (e, value) => {
    setVideoPlayhead(value)
  }

  return (
    <Box position="relative" paddingTop="56.25%">
      <Box className={classes.controlsBox}>
        <IconButton color="inherit" onClick={togglePlayPause}>
          {playing && <PauseIcon />}
          {!playing && <PlayArrowIcon />}
        </IconButton>
        <Box className={classes.progressBox}>
          <VideoPlaybar
            value={videoProgress}
            onChange={handleChange}
            max={duration}
          />
          {/* <LinearProgress
            style={{ pointerEvents: "none" }} // helps with clicking the bar
            classes={{
              colorPrimary: classes.linearProgress,
              barColorPrimary: classes.barColorPrimary,
              bar1Determinate: classes.bar1Determinate,
            }}
            variant="determinate"
            value={videoProgress}
          /> */}
        </Box>
        <Box>
          <IconButton color="inherit" onClick={handleReplay10}>
            <Replay10Icon />
          </IconButton>
          <IconButton color="inherit" onClick={handleForward10}>
            <Forward10Icon />
          </IconButton>
          <IconButton color="inherit" onClick={handleToggleMute}>
            {muted && <VolumeUpIcon />}
            {!muted && <VolumeOffIcon />}
          </IconButton>
        </Box>
      </Box>
      <ReactPlayer
        playing={playing}
        muted={muted}
        url={url}
        config={{
          file: {
            attributes: {
              preload: "auto",
              poster,
            },
          },
        }}
        width="100%"
        height="100%"
        style={{ position: "absolute", top: 0 }}
        {...ReactPlayerProps}
      />
    </Box>
  )
}

PlayerWithControls.displayName = "PlayerWithControls"

PlayerWithControls.propTypes = {
  url: urlPropType.isRequired,
  onDuration: PropTypes.func,
  poster: PropTypes.string,
  ReactPlayerProps: PropTypes.object,
  duration: PropTypes.number,
}

export default PlayerWithControls
