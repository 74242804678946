import { removeClient } from "actions/clients"
import { createClient } from "actions/clients"
import { updateClient } from "actions/clients"
import { checkAccessCode } from "actions/clients"
import { setAccessCode } from "actions/clients"
import { listClients } from "actions/clients"
import PropTypes from "prop-types"
import { createContext, useState } from "react"
import { useMutation, useQuery } from "react-query"

const ClientsContext = createContext()
export default ClientsContext

export const ClientsContainer = ({ children }) => {
  const [clientId, setClientId] = useState(null)
  const createClientMutation = useMutation(createClient)
  const [accessCodeToSend, setAccessCodToSend] = useState([])

  const { data: clients, refetch: refetchClients } = useQuery("clients", () =>
    listClients()
  )

  const updateClientMutation = useMutation((data) =>
    updateClient(data, clientId)
  )

  const setAccessCodeMutation = useMutation((data) =>
    setAccessCode(data, clientId)
  )

  const checkAccessCodeMutation = useMutation((data) => checkAccessCode(data))

  const deleteClientMutation = useMutation(removeClient)

  return (
    <ClientsContext.Provider
      value={{
        createClientMutation,
        refetchClients,
        deleteClientMutation,
        updateClientMutation,
        clientId,
        setClientId,
        clients,
        setAccessCodeMutation,
        checkAccessCodeMutation,
        accessCodeToSend,
        setAccessCodToSend,
      }}
    >
      {children}
    </ClientsContext.Provider>
  )
}

ClientsContainer.propTypes = {
  children: PropTypes.node,
}
