import { LinearProgress } from "@material-ui/core"
import LearningPathsContext, { PathsContainer } from "contexts/Paths"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useContext } from "react"
import Router from "router"

import useStyles from "./styles"

const Paths = ({ routes = [], ...rest }) => {
  const classes = useStyles()

  return (
    <Page>
      <PathsContainer>
        <LearningPathsWrapper>
          <Router routes={routes} {...rest} />
        </LearningPathsWrapper>
      </PathsContainer>
    </Page>
  )
}

Paths.propTypes = {
  routes: PropTypes.array,
}

const LearningPathsWrapper = ({ children }) => {
  const { learningPathsLoading } = useContext(LearningPathsContext)
  if (learningPathsLoading) {
    return <LinearProgress />
  } else {
    return children
  }
}

LearningPathsWrapper.propTypes = {
  children: PropTypes.node,
}

export default Paths
