import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  profileImage: {
    border: `1px solid ${theme.palette.primary.main}`,
    marginRight: theme.spacing(4),
  },

  avatar: {
    width: "10%",
    [theme.breakpoints.down("sm")]: {
      // marginTop: theme.spacing(2),
      height: "100%",
      order: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    // [theme.breakpoints.down("xs")]: {
    //   width: "20%",
    // },
  },
  infoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "85%",
    [theme.breakpoints.down("sm")]: {
      order: 1,
      flexWrap: "wrap",
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  nameText: {
    [theme.breakpoints.down("sm")]: {
      width: "40%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  titleBox: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      overflow: "hidden",
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      marginTop: theme.spacing(1),
    },
  },
  bioBox: {
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      overflow: "hidden",
      // width: "35%",
      marginTop: theme.spacing(1),
      // marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      maxHeight: theme.spacing(3),
      width: "90%",
      marginTop: 0,
    },
  },
  buttonContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      width: "5%",
      order: 2,
    },
  },
}))

export default styles
