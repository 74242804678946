import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  sideBar: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
      height: "60px",
    },
  },
  divider: {
    backgroundColor: "rgb(23, 165, 177, 0.2)",
    height: 2,
    width: "100%",
  },
  phoneViewSideBar: {
    [theme.breakpoints.up("md")]: {
      display: "none",
      height: "20px",
    },
    [theme.breakpoints.down("md")]: {
      height: "60px",
      minWidth: "100%",
      backgroundColor: theme.palette.primary.main,
    },
  },
  logo: {
    height: "90%",
    width: "90%",
    zIndex: 1,
  },
  tab: {
    "&:hover": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "rgb(23, 165, 177, 0.2)",
      width: "100%",
    },
  },
  highlight: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "rgb(23, 165, 177, 0.2)",
    width: "100%",
  },
  link: {
    "&:focus, &:hover, &:visited, &:link, &:active": {
      textDecoration: "none",
      color: "inherit",
      outline: "none",
    },
  },
  menu: {
    width: "200px",
  },
}))

export default styles
