import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  radioButtons: {
    flexDirection: "row",
  },
  inputLabel: {
    color: theme.palette.secondary.main,
    fontWeight: 700,
    fontSize: "0.75rem",
    marginBottom: theme.spacing(0.5),
  },
  removeButton: {
    color: theme.palette.error.main,
  },
  publishedChip: {
    color: "#29B117",
    backgroundColor: "#D3EFCF",
    marginRight: theme.spacing(2),
  },
  draftChip: {
    color: "#FFD034",
    backgroundColor: "#FFF2C5",
    marginRight: theme.spacing(2),
  },
  autocompleteRoot: {
    paddingTop: "0 !important",
  },
}))

export default styles
