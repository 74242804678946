import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
  },
}))

export default styles
