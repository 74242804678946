import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core"
import App from "contexts/App"
import { useContext } from "react"

import useStyles from "./styles"

const UnsavedDataDialog = () => {
  const classes = useStyles()

  const { dialogs, closeDialog } = useContext(App)
  const thisDialog = dialogs?.["unsavedDataDialog"] || {}
  const { open = false, data = {} } = thisDialog

  const text = thisDialog?.data?.text
  const discardButtonText = thisDialog?.data?.discardButtonText

  const handleClose = () => {
    closeDialog("unsavedDataDialog", false)
  }

  const discardChanges = () => {
    closeDialog("unsavedDataDialog", true)
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant="h3" style={{ fontWeight: 700, flexShrink: 0 }}>
          Unsaved Changes
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography>Woah There!</Typography>
        <Typography>
          {text ||
            "Looks like you have some unsaved changes, do you want to discard your changes and continue?"}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button type="button" onClick={discardChanges}>
          {discardButtonText || "Discard Changes"}
        </Button>
        <Button
          disableElevation
          color="secondary"
          variant="contained"
          onClick={handleClose}
          type="button"
        >
          Go Back
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UnsavedDataDialog
