import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  container: {
    width: 280,
    height: 320,
    margin: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  root: {
    border: "1px solid #E2E2E2",
    borderRadius: "10px",
    height: "100%",
  },
  media: {
    height: 145,
  },
  version: {
    fontWeight: 600,
    marginRight: "2px",
  },
  versionBtn: {
    color: theme.palette.primary.main,
    fontSize: ".5rem",
    fontWeight: "bold",
    zIndex: 1000,
    padding: 0,
    margin: 0,
    textTransform: "none",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  versionBtnBox: {
    marginLeft: "-3px",
    display: "flex",
    alignItems: "center",
  },
  starIcon: {
    fontSize: "1rem",
    marginRight: "5px",
    marginBottom: "2px",
  },
  title: {
    fontWeight: 600,
    lineHeight: 1.2,
  },
  date: { color: "#808080", marginBottom: "1px" },

  lessons: {
    fontWeight: 600,
  },
  image: {
    height: 16,
    width: 10,
    marginLeft: "5px",
  },
  durationIcon: {
    height: "16",
    width: 16,
    marginLeft: "5px",
  },
  cardMedia: {
    width: "100%",
    paddingTop: "56.25%",
    backgroundColor: theme.palette.primary.main,
    cursor: "pointer",
  },
}))

export default styles
