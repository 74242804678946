import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Snackbar,
  SnackbarContent,
  Typography,
} from "@material-ui/core"
import Slide from "@material-ui/core/Slide"
import CloseIcon from "@material-ui/icons/Close"
import { Alert } from "@material-ui/lab"
import { listTags } from "actions/courses"
import { listCategories } from "actions/courses"
import CourseLessonContentContainer from "components/Common/CourseLessonContentContainer"
import CourseContent from "components/courses/CourseContent"
import CourseDetails from "components/courses/CourseDetails"
import AppContext from "contexts/App"
import Course from "contexts/Course"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useContext, useEffect, useRef, useState } from "react"
import { useQuery } from "react-query"
import { useHistory } from "react-router-dom"
import Router from "router"

import useStyles from "./styles"

const Content = ({ routes = [], ...rest }) => {
  const blockRef = useRef()
  const { data: tags } = useQuery("tags", () => listTags())
  const { data: categories } = useQuery("categories", () => listCategories())
  const classes = useStyles()
  const history = useHistory()
  const {
    unsavedState,
    handleUpdate,
    saveLoading,
    discardChanges,
    disabledSave,
    tryPublish,
  } = useContext(Course)

  return (
    <Page>
      <CourseLessonContentContainer
        leftComponent={<CourseDetails tags={tags} categories={categories} />}
        rightComponent={<CourseContent />}
      />
      <Router routes={routes} {...rest} />
    </Page>
  )
}

Content.propTypes = {
  routes: PropTypes.array,
}

export default Content
