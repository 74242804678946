import { createInstructor } from "actions/instructors"
import AdminActionBar from "components/Common/AdminActionBar"
import InstructorCardList from "components/Instructors/InstructorCardList"
import AppContext from "contexts/App"
import InstructorsContext from "contexts/Instructors"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useContext, useEffect, useState } from "react"
import { useMutation } from "react-query"
import Router from "router"
import useStyles from "./styles"

const Instructors = ({ routes = [], ...rest }) => {
  const classes = useStyles()
  const createInstructorMutation = useMutation(createInstructor)
  const { instructors } = useContext(InstructorsContext)
  const { openDialog, setPageSubTitle, setPageTitle } = useContext(AppContext)

  const [searchValue, setSearchValue] = useState("")
  const [sort, setSort] = useState({
    name: "",
    type: "",
    direction: "",
    icon: "",
  })

  const filterOptions = [
    { id: 1, name: "Name" },
    { id: 2, name: "Title" },
  ]
  //set instructors page title and subtitle
  useEffect(() => {
    setPageTitle("Instructors")
    setPageSubTitle(instructors?.length)
  }, [])

  //reusable dialog to create lessons, courses, clientes and instructor
  //pass a title, subtitle, type (for example course) a create mutation func and refecth data
  const openCreateDialog = () => {
    let data = {
      title: "New Instructor",
      subtitle: "Create a new Instructor",
      type: "instructor",
      create: createInstructorMutation,
    }
    openDialog("createInstructorDialog", data)
  }

  return (
    <Page>
      <AdminActionBar
        buttonName="New Instructor"
        buttonFunc={openCreateDialog}
        sortOptions={filterOptions}
        sortState={sort}
        setSortState={setSort}
        setFilterState={setSearchValue}
        searchText="Search instructors"
      />
      <InstructorCardList
        searchValue={searchValue}
        sort={sort}
        openCreateDialog={openCreateDialog}
      />
      <Router routes={routes} {...rest} />
    </Page>
  )
}

Instructors.propTypes = {
  routes: PropTypes.array,
}

export default Instructors
