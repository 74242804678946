import { Box, IconButton, Tooltip, Typography } from "@material-ui/core"
import { GetApp } from "@material-ui/icons"
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt"
import AppContext from "contexts/App"
import dayjs from "dayjs"
import * as FileSaver from "file-saver"
import { Parser } from "json2csv"
import MaterialTable from "material-table"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import AutoSizer from "react-virtualized-auto-sizer"

import useStyles from "./styles"

const AnalyticsTable = ({ headCells, data, mutation, type, fields }) => {
  const classes = useStyles()

  const [analytics, setAnalytics] = useState([])
  const { openSnackBar } = useContext(AppContext)

  const getAnalytics = () => {
    mutation.mutate(data, {
      onSuccess: (res) => {
        setAnalytics(res)
      },
      onError: (err) => {
        openSnackBar({ message: "Error getting Analytics" })
        setAnalytics([])
      },
    })
  }

  useEffect(() => {
    if (mutation) {
      getAnalytics()
    }
  }, [])

  const download = () => {
    const opts = { fields }

    try {
      const parser = new Parser(opts)
      const csv = parser.parse(analytics)
      var file = new Blob([csv], { type: "text/csv" })
      FileSaver.saveAs(file, `${type}-analytics_${data.name}.csv`)
    } catch (err) {
      openSnackBar({ message: "Error download analytics data" })
    }
  }

  return (
    <Box className={classes.container} height="100%">
      {type === "course" ? (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex">
            <Typography variant="subtitle1" className={classes.dataText}>
              Course Data:
            </Typography>
            <Typography variant="subtitle1" className={classes.dataText}>{`${
              data?.lesson_count || 0
            } lessons, `}</Typography>
            <Typography variant="subtitle1" className={classes.dataText}>
              {`${dayjs
                .duration(0, "s")
                .add(data?.duration, "seconds")
                .format("mm:ss")} duration `}
            </Typography>
          </Box>
          <Box marginLeft={2} display="flex" alignItems="center">
            <Typography
              variant="subtitle1"
              className={classes.downloadText}
            >{`${analytics?.length || 0} users took this ${type}`}</Typography>
            <Tooltip title="Download data" placement="top">
              <IconButton onClick={download}>
                <GetApp color="secondary" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      ) : type === "lesson" ? (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex">
            <Typography variant="subtitle1" className={classes.dataText}>
              Lesson Data:
            </Typography>
            <Typography variant="subtitle1" className={classes.dataText}>{`${
              data?.count || 0
            } knowledge check, `}</Typography>
            <Typography variant="subtitle1" className={classes.dataText}>
              {`${dayjs
                .duration(0, "s")
                .add(data?.video?.duration, "seconds")
                .format("mm:ss")} duration `}
            </Typography>
          </Box>
          <Box marginLeft={2} display="flex" alignItems="center">
            <Typography
              variant="subtitle1"
              className={classes.downloadText}
            >{`${analytics?.length || 0} users took this ${type}`}</Typography>
            <Tooltip title="Download data" placement="top">
              <IconButton onClick={download}>
                <GetApp color="secondary" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      ) : type === "Learning Path" ? (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex">
            <Typography variant="subtitle1" className={classes.dataText}>
              Learning Path Data:
            </Typography>
            <Typography variant="subtitle1" className={classes.dataText}>
              {data.courses.length} Courses
            </Typography>
          </Box>
          <Box marginLeft={2} display="flex" alignItems="center">
            <Typography variant="subtitle1" className={classes.downloadText}>
              {`${analytics?.length || 0} users took this ${type}`}
            </Typography>
            <Tooltip title="Download data" placement="top">
              <IconButton onClick={download}>
                <GetApp color="secondary" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      ) : (
        // TODO:
        <div></div>
      )}

      <AutoSizer
        style={{ width: "100%", height: "600px", borderRadius: "50px" }}
      >
        {({ width, height }) => {
          return (
            <>
              <MaterialTable
                options={{
                  toolbar: false,
                  title: "",
                  headerStyle: {
                    backgroundColor: "#fff",
                    borderBottom: "2px solid #17A5B1",
                    position: "sticky",
                    top: 0,
                    whiteSpace: "nowrap",
                    width: "100%",
                    fontSize: "12px",
                    fontWeight: "bold",
                    height: "60px",
                    padding: "5px 10px",
                  },

                  maxBodyHeight: height - 55,
                  pageSize: 25,
                  pageSizeOptions: [10, 25, 50, 100],
                  rowStyle: (rowData, i) => ({
                    backgroundColor: i % 2 !== 0 ? "#fff" : "#F5F5F5",
                    maxHeight: "25px",
                    height: "25px",
                    padding: "5px 10px",
                    fontSize: "14px",
                  }),
                }}
                classses={{ root: classes.root }}
                columns={headCells}
                data={analytics}
                isLoading={mutation?.isLoading}
              />
            </>
          )
        }}
      </AutoSizer>
    </Box>
  )
}

AnalyticsTable.propTypes = {
  headCells: PropTypes.array,
  data: PropTypes.object,
  loading: PropTypes.bool,
  mutation: PropTypes.func,
  type: PropTypes.string,
  fields: PropTypes.array,
}

export default AnalyticsTable
