import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(3),
    width: "60%",
  },
  buttonContainer: {
    cursor: "pointer",
  },
  button: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}))

export default styles
