import LoaderButton from "@bit/c_t.components.loader-button"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core"
import AppContext from "contexts/App"
import { useContext, useEffect } from "react"
import { useState } from "react"
import { useMemo } from "react"
import { useQueryClient } from "react-query"
import serialize from "store/serialize"

import useStyles from "./styles"

const PermissionsDialog = () => {
  const classes = useStyles()
  const queryClient = useQueryClient()

  const { dialogs, closeDialog, openSnackBar } = useContext(AppContext)
  const thisDialog = dialogs?.["permissionsDialog"] || {}
  const admin = thisDialog?.data?.admin
  const setPermissionsMutation = thisDialog?.data?.setPermissionsMutation
  const { open = false, data } = thisDialog

  const [accessChecked, setAccessChecked] = useState([])
  const [role, setRole] = useState("admin")
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    closeDialog("permissionsDialog")
    setAccessChecked([])
    setRole("admin")
  }

  const handleSubmit = () => {
    setLoading(true)
    const data = {
      id: admin.id,
      super: role === "superAdmin" ? 1 : 0,
      access_coupons: accessChecked.includes("discounts") ? 1 : 0,
      access_analytics: accessChecked.includes("analytics") ? 1 : 0,
    }
    setPermissionsMutation.mutate(data, {
      onSuccess: (res) => {
        serialize("admin", res.data).then((serializedData) => {
          queryClient.setQueryData("admins", (oldData) => {
            let newArray = oldData.filter((x) => x.id !== res.data.id)
            return [...newArray, serializedData]
          })
        })
        openSnackBar({
          message: "Admin updated",
        })
        handleClose()
      },
      onError: (err) => {
        openSnackBar({
          message: "There was an error updating admin",
        })
      },
      onSettled: () => setLoading(false),
    })
  }

  const handleChangeAccess = (e) => {
    if (accessChecked.includes(e.target.value)) {
      setAccessChecked(accessChecked.filter((x) => x !== e.target.value))
    } else {
      setAccessChecked([...accessChecked, e.target.value])
    }
  }

  const handleChangeRole = (e) => {
    setRole(e.target.value)
  }

  useEffect(() => {
    if (admin?.super === 1) {
      setRole("superAdmin")
      setAccessChecked(["discounts", "analytics"])
    }
    if (admin?.access_coupons === 1 && admin?.access_analytics === 1) {
      setAccessChecked(["analytics", "discounts"])
    } else if (admin?.access_analytics === 1) {
      setAccessChecked(["analytics"])
    } else if (admin?.access_coupons === 1) {
      setAccessChecked(["discounts"])
    }
  }, [admin])

  useEffect(() => {
    if (role === "superAdmin") {
      setAccessChecked(["discounts", "analytics"])
    } else {
      setAccessChecked([])
    }
  }, [role])

  return (
    <Dialog open={open} maxWidth="xs">
      <DialogTitle disabledTypography>
        <Typography variant="h3" color="primary" style={{ fontWeight: 600 }}>
          Admin Permissions
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box marginBottom={2}>
          <Typography color="secondary" className={classes.title}>
            Roles
          </Typography>
          <RadioGroup value={role} onChange={handleChangeRole}>
            <Box display="flex">
              <FormControlLabel
                value="admin"
                control={<Radio />}
                label="Admin"
              />
              <FormControlLabel
                value="superAdmin"
                control={<Radio />}
                label="Super Admin"
              />
            </Box>
          </RadioGroup>
        </Box>
        <Divider />
        <Box marginTop={2}>
          <Typography color="secondary" className={classes.title}>
            Access
          </Typography>
          <Box display="flex">
            <FormControlLabel
              label="Discount Codes"
              control={
                <Radio
                  onClick={(e) => handleChangeAccess(e)}
                  value="discounts"
                  checked={accessChecked.includes("discounts")}
                  disabled={role === "superAdmin"}
                  disableRipple={role === "superAdmin"}
                />
              }
            />
            <FormControlLabel
              label="Analytics"
              control={
                <Radio
                  onClick={(e) => handleChangeAccess(e)}
                  value="analytics"
                  checked={accessChecked.includes("analytics")}
                  disabled={role === "superAdmin"}
                  disableRipple={role === "superAdmin"}
                />
              }
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoaderButton
          onClick={handleSubmit}
          classoverrides={{
            wrapper: classes.button,
            button: {
              root: classes.buttonRoot,
            },
          }}
          working={loading}
          variant="contained"
          color="secondary"
          size="small"
        >
          Save
        </LoaderButton>
      </DialogActions>
    </Dialog>
  )
}

export default PermissionsDialog
