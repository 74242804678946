import { setPermissions } from "actions/users"
import { listAdmins } from "actions/users"
import { deleteAdmin } from "actions/users"
import { listAppUsers } from "actions/users"
import { publishedCoursesCount } from "actions/users"
import { getWailist } from "actions/users"
import { createAdmin } from "actions/users"
import PropTypes from "prop-types"
import { createContext, useContext, useState } from "react"
import { useMutation, useQuery } from "react-query"

import AppContext from "./App"

const UsersContext = createContext()
export default UsersContext

export const UsersContainer = ({ children }) => {
  const createAdminMutation = useMutation((data) => createAdmin(data))
  //state for search textfield
  const [searchValue, setSearchValue] = useState("")
  //state for active select
  const [activeValue, setActiveValue] = useState("all")
  //state for courses count slider
  const [sliderValue, setSliderValue] = useState([])
  //state for debounced courses count slider
  const [debouncedSliderValue, setDebouncedSliderValue] = useState([])
  //courses count initialValue
  const [coursesCount, setCoursesCount] = useState(null)
  //total of users and users showed
  const [total, setTotal] = useState(null)
  const [showing, setShowing] = useState(null)
  const [sort, setSort] = useState({ name: "id", direction: "" })

  const { authUser } = useContext(AppContext)

  const {
    data: admins,
    refetch: refetchAdmins,
    isLoading: listAdminLoading,
  } = useQuery("admins", () => listAdmins(), {
    enabled: !!authUser,
  })

  const { data: waitlist, isLoading: waitlistLoading } = useQuery(
    "waitlist",
    () => getWailist(),
    {
      enabled: !!authUser,
    }
  )
  const listAppUsersMutation = useMutation((data) => listAppUsers(data))
  const publishedCoursesCountMutation = useMutation(() =>
    publishedCoursesCount()
  )

  const setPermissionsMutation = useMutation((data) => setPermissions(data))
  const {
    isLoading: deleteAdminLoading,
    ...deleteAdminMutation
  } = useMutation((data) => deleteAdmin(data))

  return (
    <UsersContext.Provider
      value={{
        createAdminMutation,
        refetchAdmins,
        setPermissionsMutation,
        deleteAdminMutation,
        listAdminLoading,
        deleteAdminLoading,
        listAppUsersMutation,
        searchValue,
        setSearchValue,
        activeValue,
        setActiveValue,
        setTotal,
        total,
        showing,
        setShowing,
        sort,
        setSort,
        admins,
        waitlist,
        waitlistLoading,
        sliderValue,
        setSliderValue,
        publishedCoursesCountMutation,
        coursesCount,
        setCoursesCount,
        debouncedSliderValue,
        setDebouncedSliderValue,
      }}
    >
      {children}
    </UsersContext.Provider>
  )
}

UsersContainer.propTypes = {
  children: PropTypes.node,
}
