import { getMe } from "actions/users"
import { updateMe } from "actions/users"
import { updatePassword } from "actions/users"
import update from "immutability-helper"
import { isEmpty } from "lodash"
import Deferred from "promise-deferred"
import PropTypes from "prop-types"
import { createContext } from "react"
import { useEffect, useRef, useState } from "react"
import { useIndexedDB } from "react-indexed-db"
import { useMutation, useQuery } from "react-query"
import API from "store/api"

const AppContext = createContext()
export default AppContext

export const AppContainer = ({ children }) => {
  const { add, clear, getAll } = useIndexedDB("auth")

  const [authUser, setAuthUserState] = useState(null)
  const [token, setToken] = useState(null)
  const [shouldRender, setShouldRender] = useState(false)
  const [dialogs, setDialogsState] = useState({})
  const dialogPromises = useRef({})
  const [snackBar, setSnackbar] = useState({
    open: false,
    message: "There was an error",
    autoHideDuration: 3000,
  })

  const openDialog = (name, data) => {
    setDialogsState({
      ...dialogs,
      [name]: {
        data,
        open: false,
      },
    })
    setTimeout(() => {
      setDialogsState((newDialogs) => ({
        ...newDialogs,
        [name]: {
          ...newDialogs[name],
          open: true,
        },
      }))
    }, 150)
    dialogPromises.current = {
      ...dialogPromises.current,
      [name]: new Deferred(),
    }
    return dialogPromises.current[name].promise
  }

  const closeDialog = (name, confirm = false, data) => {
    // confirm is false by default so dialogs will always reject
    if (confirm) {
      dialogPromises.current[name].resolve(data)
    } else {
      dialogPromises.current[name].reject(data)
    }
    setDialogsState((currentDialogsState) =>
      update(currentDialogsState, {
        [name]: {
          open: {
            $set: false,
          },
        },
      })
    )
    setTimeout(() => {
      setDialogsState((stateDialogs) => {
        delete stateDialogs[name]
        return { ...stateDialogs }
      })
      delete dialogPromises.current[name]
    }, 300)
  }

  const openSnackBar = ({ open = true, ...rest }) => {
    setSnackbar({
      ...snackBar,
      ...rest,
      open,
    })
  }

  const setAuthToken = (token) => {
    return new Promise((resolve, reject) => {
      clear().then(() => {
        add({ name: "token", token }).then(
          () => {
            setToken(token)
            resolve()
          },
          (error) => {
            console.log(error)
            reject()
          }
        )
      })
    })
  }

  // const setAuthData = (user) => {
  //   setAuthUserState(user)
  // }

  const { refetch: refetchMe } = useQuery(["getMe", token], getMe, {
    enabled: Boolean(token),
    onSuccess: (res) => {
      setAuthUserState(res)
    },
  })

  const updateMeMutation = useMutation((data) => updateMe(data))

  const updatePasswordMutation = useMutation((data) => {
    updatePassword(data)
  })

  useEffect(() => {
    // indexdb
    getAll()
      .then((res) => {
        if (!isEmpty(res)) {
          API.defaults.headers.common["Authorization"] = res[0].token
          setToken(res[0].token)
        }
      })
      .finally(() => {
        setShouldRender(true)
      })
  }, [])

  const removeAuthToken = () => {
    return new Promise((resolve, reject) => {
      clear().then(() => {
        setToken("")
        resolve()
      })
    })
  }

  // page title related functionality
  const [pageTitle, setPageTitle] = useState("")
  const [pageSubTitle, setPageSubTitle] = useState("")

  return (
    <AppContext.Provider
      value={{
        // setAuthData,
        setAuthToken,
        token,
        authUser,
        dialogs,
        openDialog,
        closeDialog,
        snackBar,
        openSnackBar,
        pageTitle,
        setPageTitle,
        pageSubTitle,
        setPageSubTitle,
        updateMeMutation,
        updatePasswordMutation,
        refetchMe,
        removeAuthToken,
      }}
    >
      {shouldRender && children}
      {!shouldRender && null}
    </AppContext.Provider>
  )
}

AppContainer.propTypes = {
  children: PropTypes.node,
}
