import { Box, Card, CardContent, Grow, Typography } from "@material-ui/core"
import { Image, Transformation } from "cloudinary-react"
import DraftPublishedBox from "components/Common/DraftPublishedBox"
import HoverText from "components/Common/HoverText"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import { useHistory } from "react-router"

import useStyles from "./styles"

const PathCard = ({ path, openPath }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Grow in={true} timeout={350} style={{ transformOrigin: "center" }}>
      <Box className={classes.container} style={{ cursor: "pointer" }}>
        <Card className={classes.root} raised={true} elevation={0}>
          {!isEmpty(path.thumbnail) && (
            <Image
              publicId={path.thumbnail.public_id}
              style={{ verticalAlign: "bottom", width: "100%" }}
              onClick={openPath}
            >
              <Transformation
                width="335"
                crop="fill"
                gravity="auto"
                aspectRatio="16:9"
              />
            </Image>
          )}
          {isEmpty(path.thumbnail) && (
            <Box className={classes.cardMedia} onClick={openPath} />
          )}
          <CardContent>
            <Box display="flex" alignItems="center">
              <Typography variant="caption" className={classes.date}>
                Last Updated {path?.updated?.format("M/D/YYYY")}
              </Typography>
            </Box>
            <Box marginTop={1.5} height="60px">
              <HoverText
                variant="h5"
                className={classes.title}
                size={38}
                data={path?.name}
              />
            </Box>
            <Box
              display="flex"
              marginTop={1.5}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <Typography
                  color="primary"
                  variant="subtitle1"
                  className={classes.lessons}
                >
                  {path?.courses?.length} Courses
                </Typography>
              </Box>
              <Box>
                <DraftPublishedBox
                  published={path.published}
                  has_been_published={path.has_been_published}
                  archived={path.archived}
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Grow>
  )
}

PathCard.propTypes = {
  path: PropTypes.object,
  openPath: PropTypes.func,
}

export default PathCard
