import { format } from "@cloudinary/base/actions/delivery"
import { fill } from "@cloudinary/base/actions/resize"
import { jpg } from "@cloudinary/base/qualifiers/format"
import { focusOn } from "@cloudinary/base/qualifiers/gravity"
import { auto } from "@cloudinary/base/qualifiers/quality"
import { cloudinary } from "index"
import { isEmpty } from "lodash"
import { useMemo } from "react"

const useHeaderBackground = (thumbnail) => {
  return useMemo(() => {
    if (!isEmpty(thumbnail)) {
      return cloudinary
        .image(thumbnail.public_id)
        .resize(
          fill().width(window.outerWidth).height(140).gravity(focusOn(auto()))
        )
        .delivery(format(jpg()))
        .toURL()
    }
    return null
  }, [thumbnail])
}
export default useHeaderBackground
