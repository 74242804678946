import Record from "./base"

export class Lab extends Record {
  constructor(...args) {
    super(...args)
    const [model, props] = args
    this.published = 0
    this.archived = 0
    this.has_been_published = 0
  }
}
