import {
  Box,
  Grid,
  IconButton,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle"
import dragGray from "assets/Icons/noun_drag_3124730_gray.svg"
import HoverText from "components/Common/HoverText"
import InstructorGroup from "components/Common/InstructorGroup"
import PaperListRow from "components/Common/PaperListRow"
import CourseContext from "contexts/Course"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useHistory } from "react-router-dom"

import AddedLessonCardMobile from "../AddedLessonCardMobile"
import useStyles from "./styles"

const AddLessonCard = ({ lesson, provided, disabledCourse }) => {
  //provided is an object used to move the lessons inside courses
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()

  const xsDown = useMediaQuery(theme.breakpoints.down("xs"))

  const { lessonsArray, setLessonsArray } = useContext(CourseContext)
  //take out lesson from lessonsArray
  const removeLesson = (id) => {
    setLessonsArray(lessonsArray.filter((lesson) => lesson.lesson_id !== id))
  }

  if (!xsDown) {
    return (
      <PaperListRow componentWidth={"100%"} provided={provided}>
        <Grid container spacing={0} className={classes.container}>
          <Grid
            item
            md={4}
            xs={12}
            className={classes.titleContainer}
            onClick={() => history.push(`/lessons/${lesson.lesson_id}`)}
            style={disabledCourse ? { filter: "opacity(0.5)" } : null}
          >
            <HoverText
              data={lesson?.data?.name}
              size={30}
              variant="h3"
              className={classes.nameText}
            />
          </Grid>
          <Grid item md={3} xs={12} className={classes.instructorsContainer}>
            {lesson?.data?.instructors?.length > 0 ? (
              <InstructorGroup instructorsArray={lesson?.data?.instructors} />
            ) : null}
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography>{"Version " + lesson?.data?.version}</Typography>
          </Grid>
          <Grid item md={2} xs={12}>
            {/* Iconbutton to be disabled when not in draft mode */}
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <IconButton onClick={() => removeLesson(lesson?.lesson_id)}>
                <RemoveCircleIcon />
              </IconButton>
              <Box {...provided.dragHandleProps}>
                <img src={dragGray} alt="" width="20px" />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </PaperListRow>
    )
  } else {
    return (
      <AddedLessonCardMobile
        provided={provided}
        lesson={lesson}
        removeLesson={removeLesson}
        disabledCourse={disabledCourse}
      />
    )
  }
}

AddLessonCard.propTypes = {
  lesson: PropTypes.object,
  provided: PropTypes.object,
  disabledCourse: PropTypes.number,
}

export default AddLessonCard
