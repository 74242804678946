const CertificationModel = {
  created: "date",
  updated: "date",
  thumbnail: "object",
  background_image: "object",
  badge_image: "object",
  published: "number",
  archived: "number",
  learning_paths: ["hasMany", "path"],
}

export default CertificationModel
