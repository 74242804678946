import { LinearProgress } from "@material-ui/core"
import CertificationsContext, {
  CertificationsContainer,
} from "contexts/Certifications"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useContext } from "react"
import Router from "router"

const Certifications = ({ routes = [], ...rest }) => {
  return (
    <Page>
      <CertificationsContainer>
        <CertificationsWrapper>
          <Router routes={routes} {...rest} />
        </CertificationsWrapper>
      </CertificationsContainer>
    </Page>
  )
}

Certifications.propTypes = {
  routes: PropTypes.array,
}

const CertificationsWrapper = ({ children }) => {
  const { certificationsLoading } = useContext(CertificationsContext)
  if (certificationsLoading) {
    return <LinearProgress />
  } else {
    return children
  }
}

CertificationsWrapper.propTypes = {
  children: PropTypes.node,
}

export default Certifications
