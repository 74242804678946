import admin from "assets/Icons/admin.svg"
import coloredAdmin from "assets/Icons/colored-admin.svg"
import coloredUser from "assets/Icons/colored-user.svg"
import user from "assets/Icons/user.svg"
import ContentTabs from "components/Common/ContentTabs"
import AppContext from "contexts/App"
import { UsersContainer } from "contexts/Users"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useContext, useEffect } from "react"
import Router from "router"
import analyticsColor from "assets/Icons/colored-pie-chart.svg"
import analytics from "assets/Icons/feather-pie-chart.svg"
import useStyles from "./styles"

const User = ({ routes = [], ...rest }) => {
  const classes = useStyles()

  const { setPageTitle } = useContext(AppContext)

  //set user page title and subtitle
  useEffect(() => {
    setPageTitle("Users")
  }, [])

  const tabs = [
    {
      label: "Users",
      link: `/users/user-list`,
      icon1: user,
      icon2: coloredUser,
    },
    {
      label: "Administrators",
      link: `/users/admin-list`,
      icon1: admin,
      icon2: coloredAdmin,
    },
    {
      label: "Waitlist",
      link: `/users/waitlist`,
      icon1: analytics,
      icon2: analyticsColor,
    },
  ]

  return (
    <Page>
      <UsersContainer>
        <ContentTabs tabs={tabs} />
        <Router routes={routes} {...rest} />
      </UsersContainer>
    </Page>
  )
}

User.propTypes = {
  routes: PropTypes.array,
}

export default User
