const sortDirectionUtil = (oldName, currentName, sortDirection) => {
  //return sort direction checking old directino or old sort name
  if (oldName !== currentName) {
    return "ASC"
  } else if (sortDirection === "") {
    return "ASC"
  } else if (sortDirection === "ASC") {
    return "DSC"
  } else if (sortDirection === "DSC") {
    return ""
  }
}

export default sortDirectionUtil
