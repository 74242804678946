import ResourceCardList from "components/Common/Resources/ResourceCardList"
import LessonContext from "contexts/Lesson"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useContext } from "react"
import Router from "router"

import useStyles from "./styles"

const Resources = ({ routes = [], ...rest }) => {
  const classes = useStyles()

  const {
    lessonResourceMutation,
    lesson,
    resourcesLessonArray,
    setResourceLessonArray,
    discardChanges,
  } = useContext(LessonContext)

  const data = lesson?.resources

  return (
    <Page>
      <ResourceCardList
        data={data}
        setFn={lessonResourceMutation}
        recoursesArray={resourcesLessonArray}
        setRecoursesArray={setResourceLessonArray}
        discardChanges={discardChanges}
      />
      <Router routes={routes} {...rest} />
    </Page>
  )
}

Resources.propTypes = {
  routes: PropTypes.array,
}

export default Resources
