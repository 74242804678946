import { Box, LinearProgress } from "@material-ui/core"
import { getCertification } from "actions/certifications"
import { listCertifications } from "actions/certifications"
import analyticsColor from "assets/Icons/colored-pie-chart.svg"
import contentColor from "assets/Icons/colored-video.svg"
import analytics from "assets/Icons/feather-pie-chart.svg"
import content from "assets/Icons/feather-video.svg"
import CertificationHeader from "components/Certifications/CertificationHeader"
import UnsavedChanges from "components/Certifications/UnsavedChanges"
import ContentTabs from "components/Common/ContentTabs"
import AppContext from "contexts/App"
import { CertificationContainer } from "contexts/Certification"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useContext } from "react"
import { useQuery } from "react-query"
import Router from "router"

import useStyles from "./styles"

const Certification = ({ routes = [], ...rest }) => {
  const classes = useStyles()
  const certificationId = +rest?.match?.params?.certification_id

  const { authUser } = useContext(AppContext)

  const { data: certification, isLoading: certificationLoading } = useQuery(
    ["certification", certificationId],
    () => getCertification(certificationId),
    {
      cacheTime: 0,
    }
  )

  const withAnalytics = [
    {
      label: "Certification Content",
      link: `/certifications/${certificationId}/content`,

      icon1: content,
      icon2: contentColor,
    },

    {
      label: "Analytics",
      link: `/certifications/${certificationId}/analytics`,
      icon1: analytics,
      icon2: analyticsColor,
    },
  ]
  const withoutAnalytics = [
    {
      label: "Certification Content",
      link: `/certifications/${certificationId}/content`,

      icon1: content,
      icon2: contentColor,
    },
  ]

  const tabs =
    authUser?.access_analytics === 1 ? withAnalytics : withoutAnalytics

  if (!certification?.id) {
    return <LinearProgress color="primary" />
  }

  return (
    <Page>
      <CertificationContainer certification={certification}>
        <Box display="flex" flexDirection="column" height="100%">
          <Box flexShrink={0} flexGrow={0}>
            <CertificationHeader />
            <ContentTabs tabs={tabs} />
          </Box>
          <Box flexGrow={1} overflow="auto">
            <Router routes={routes} {...rest} />
          </Box>
        </Box>
        <UnsavedChanges />
      </CertificationContainer>
    </Page>
  )
}

Certification.propTypes = {
  routes: PropTypes.array,
}

export default Certification
