import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  page: {
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
}))

export default styles
