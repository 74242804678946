import { createMuiTheme } from "@material-ui/core/styles"

const primary = {
  main: "#092958",
}
const secondary = {
  light: "#E2F1F2",
  main: "#17A5B1",
}
const warning = {
  main: "#FF5E69",
}
const success = {
  main: "#1FC338",
}
const error = {}

const spacing = (factor) => factor * 8

export default createMuiTheme({
  spacing,
  palette: {
    primary,
    secondary,
    warning,
    success,
  },
  typography: {
    h1: {
      fontFamily: ["Roboto", "sans-serif"].join(","),
      fontSize: "3rem",
    },
    h2: {
      fontFamily: ["Roboto", "sans-serif"].join(","),
      fontSize: "1.75rem",
    },
    h3: {
      fontFamily: ["Roboto", "sans-serif"].join(","),
      fontSize: "1.125rem",
    },
    h4: {
      fontFamily: ["Roboto", "sans-serif"].join(","),
      fontSize: "1rem",
    },
    subtitle1: {
      fontFamily: ["Roboto", "sans-serif"].join(","),
      fontSize: "0.875rem",
    },
    subtitle2: {
      fontFamily: ["Roboto", "sans-serif"].join(","),
      fontSize: "0.75rem",
    },
    body1: {
      fontFamily: ["Roboto", "sans-serif"].join(","),
      fontSize: "0.75rem",
    },
    body2: {
      fontFamily: ["Roboto", "sans-serif"].join(","),
      fontSize: "0.625rem",
    },
    caption: {
      fontFamily: ["Roboto", "sans-serif"].join(","),
      fontSize: "0.5rem",
    },
  },
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: 8,
        width: "100%",
        // height: '50%'
      },
    },
    MuiDialogTitle: {
      root: {
        color: primary.main,
      },
    },
    MuiDialogActions: {
      root: {
        padding: "27px 22px",
      },
    },
    MuiChip: {
      colorSecondary: {
        backgroundColor: "#c8e5e7",
        color: "#000000DE",
      },
      deleteIconColorSecondary: {
        color: secondary.main,
      },
    },
    MuiButton: {
      contained: {
        color: "#999ba1",
        "&$disabled": {
          backgroundColor: "rgba(0, 0, 0, 0.05)",
        },
      },
      containedSecondary: {
        color: "#fff",
      },
      text: {
        color: "#999ba1",
      },
      outlined: {
        color: "#999ba1",
      },
    },
    MuiFab: {
      secondary: {
        color: "#fff",
      },
    },
    MuiInputLabel: {
      filled: {
        transform: "translate(12px, 22px) scale(1)",
        "& ~ .MuiInputBase-root .MuiInputBase-input": {
          paddingTop: 27,
        },
        "& ~ .MuiInputBase-multiline .MuiInputBase-input": {
          paddingTop: 27,
        },
      },
    },
    MuiInput: {
      underline: {
        "&$disabled": {
          "&:before": {
            borderBottom: "none",
          },
        },
      },
    },
    MuiInputBase: {
      input: {
        "&$disabled": {
          // padding: 0,
          color: "rgba(0,0,0,0.87)",
        },
      },
      multiline: {
        "&.Mui-disabled": {
          backgroundColor: "rgba(0, 0, 0, 0.12)",
        },
      },
    },
    MuiFilledInput: {
      root: {
        height: "32px",
        borderRadius: 6,
        backgroundColor: "#F4F4F4",
        "&:hover": {
          backgroundColor: "#F4F4F4",
        },
      },
      multiline: {
        padding: "0",
        height: "initial",
      },
      inputMultiline: {
        padding: "12px 10px",
        lineHeight: "1.3rem",
      },
      inputMarginDense: {
        paddingTop: 0,
        paddingBottom: 0,
      },
      input: {
        padding: "12px 10px",
      },
      underline: {
        "&:before": {
          borderBottom: "none",
        },
        "&:after": {
          borderBottom: "none",
        },
        "&:hover:before": {
          borderBottom: "none",
        },
        "&.Mui-disabled:before": {
          borderBottomStyle: "none",
        },
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },

    MuiSlider: {
      valueLabel: {
        color: "transparent",
      },
    },

    // MuiPickersToolbar: {
    //   toolbar: {
    //     backgroundColor: "#17A5B1",
    //   },
    // },
    // MuiPickerDTTabs: {
    //   tabs: {
    //     backgroundColor: "#17A5B1",
    //   },
    // },
    // MuiTabs: {
    //   indicator: {
    //     backgroundColor: "#092958",
    //   },
    // },
    // MuiTab: {
    //   textColorInherit: {
    //     color: "#092958",
    //   },
    // },
  },
})
