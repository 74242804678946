import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3.5),
    minHeight: "100%",
  },
  noContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100%",
  },
  buildLabButton: {
    backgroundColor: theme.palette.success.main,
    color: "#fff",
  },
}))

export default styles
