import {
  Avatar,
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import HoverText from "components/Common/HoverText"
import PaperListRow from "components/Common/PaperListRow"
import AppContext from "contexts/App"
import InstructorsContext from "contexts/Instructors"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"

import useStyles from "./styles"

const InstructorCard = ({ name, title, profileImg, bio, id, view, viewFn }) => {
  const { openDialog } = useContext(AppContext)
  const { updateInstructorMutation } = useContext(InstructorsContext)

  //sends the id that was clicked along with the updateInstructor function
  const handleClick = (id) => {
    openDialog("editInstructorDialog", {
      id: id,
      update: updateInstructorMutation,
    })
  }

  const classes = useStyles()
  const theme = useTheme()
  const smScreen = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Box marginBottom={2}>
      <PaperListRow
        className={classes.instructor}
        componentHeight={smScreen ? "96px" : "64px"}
      >
        <Box className={classes.avatar}>
          <Avatar src={profileImg} className={classes.profileImage} />
        </Box>
        <Box className={classes.infoContainer}>
          <Box width={255} className={classes.nameText}>
            <Typography
              variant="h3"
              style={{ fontWeight: 600 }}
              color="primary"
            >
              {name}
            </Typography>
          </Box>
          <Box width={300} className={classes.titleBox}>
            <HoverText
              data={title}
              // size={pageWidth > 600 ? 40 : 20}
              size={smScreen ? 25 : 40}
              variant="subtitle1"
            />
          </Box>
          <Box width="60%" className={classes.bioBox}>
            <HoverText
              data={bio}
              // size={pageWidth > 1150 ? 100 : 70}
              size={smScreen ? 60 : 100}
              variant="subtitle1"
            />
          </Box>
        </Box>
        <Box className={classes.buttonContainer}>
          {view ? (
            <Button color="secondary" onClick={viewFn}>
              View
            </Button>
          ) : (
            <Button color="secondary" onClick={() => handleClick(id)}>
              Edit
            </Button>
          )}
        </Box>
      </PaperListRow>
    </Box>
  )
}

InstructorCard.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  profileImg: PropTypes.string,
  bio: PropTypes.string,
  id: PropTypes.number,
  view: PropTypes.bool,
  viewFn: PropTypes.func,
}

export default InstructorCard
