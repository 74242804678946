import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import AppContext from "contexts/App"
import UsersContext from "contexts/Users"
import dayjs from "dayjs"
import React from "react"
import { useContext } from "react"
import { useHistory } from "react-router"

import useStyles from "./styles"

const HistoryVersionDialog = () => {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()

  const smDown = useMediaQuery(theme.breakpoints.down("sm"))

  const { dialogs, closeDialog, openSnackBar } = useContext(AppContext)
  const { admins } = useContext(UsersContext)
  const thisDialog = dialogs?.["historyVersionDialog"] || {}
  const { open = false, data } = thisDialog

  let versionsData = thisDialog?.data?.data?.versions || []
  let type = thisDialog?.data?.type
  let dataPassed = thisDialog?.data?.data

  const handleClose = () => {
    closeDialog("historyVersionDialog", false)
  }
  const openVersion = (id) => {
    history.push(`/${type}s/${id}`)
    handleClose()
  }

  const checkVersion = (version) => {
    if (version?.published) {
      return "Published"
    } else if (version?.archived) {
      return "Archived"
    } else if (version?.has_been_published) {
      return "Unpublished"
    } else {
      return "Draft"
    }
  }

  return (
    <Dialog
      classes={{ paperWidthSm: classes.dialogMinHeight }}
      open={open}
      fullScreen={smDown}
    >
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography variant="h2" className={classes.title}>
          {dataPassed?.name}
        </Typography>
        <Typography variant="subtitle1" className={classes.title}>
          History
        </Typography>
      </DialogTitle>
      <DialogContent classes={{ root: classes.contentRoot }}>
        <Box
          display="flex"
          marginTop="20px"
          marginBottom="20px"
          marginRight="24px"
          marginLeft="24px"
        >
          <Typography
            style={{ width: "25%" }}
            className={classes.title}
            variant="subtitle2"
          >
            VERSION
          </Typography>
          <Typography
            style={{ width: "25%" }}
            className={classes.title}
            variant="subtitle2"
          >
            LAST UPDATED
          </Typography>
          <Typography
            style={{ width: "25%" }}
            className={classes.title}
            variant="subtitle2"
          >
            BY WHO
          </Typography>
          <Typography
            style={{ width: "25%" }}
            className={classes.title}
            variant="subtitle2"
          >
            STATUS
          </Typography>
          <Typography style={{ width: "25%" }} />
        </Box>
        {versionsData.map((version, i) => {
          return (
            <>
              <Box
                display="flex"
                alignItems="center"
                key={i}
                paddingTop="8px"
                paddingRight="24px"
                paddingLeft="24px"
                height="45px"
                style={{
                  backgroundColor:
                    dataPassed?.version === version?.version && "#F4F4F4",
                }}
              >
                <Typography
                  style={{ width: "25%" }}
                  color="primary"
                  variant="subtitle2"
                >
                  {version?.version}
                </Typography>
                <Typography
                  style={{ width: "25%" }}
                  color="primary"
                  variant="subtitle2"
                >
                  {dayjs(version?.updated)?.format("M/D/YYYY")}
                </Typography>
                <Typography
                  style={{ width: "25%" }}
                  color="primary"
                  variant="subtitle2"
                >
                  {admins?.map((x) => {
                    if (x?.id === version?.updated_admin_id) {
                      return x?.name
                    }
                  })}
                </Typography>
                <Typography
                  style={{ width: "25%" }}
                  color="primary"
                  variant="subtitle2"
                >
                  {checkVersion(version)}
                </Typography>
                {dataPassed?.version !== version?.version ? (
                  <Button
                    className={classes.viewBtn}
                    color="secondary"
                    onClick={() => openVersion(version?.id)}
                  >
                    View
                  </Button>
                ) : (
                  <Box width="25%"></Box>
                )}
              </Box>
              <Divider />
            </>
          )
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}

HistoryVersionDialog.propTypes = {}

export default HistoryVersionDialog
