import LoaderButton from "@bit/c_t.components.loader-button"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core"
import AppContext from "contexts/App"
import { Form, Formik } from "formik"
import { useContext } from "react"
import { useQueryClient } from "react-query"
import { useHistory } from "react-router"
import serialize from "store/serialize"
import * as Yup from "yup"

import useStyles from "./styles"

const CreateCopyDialog = () => {
  const classes = useStyles()
  const history = useHistory()
  const queryClient = useQueryClient()

  const { dialogs, closeDialog, openSnackBar } = useContext(AppContext)
  const thisDialog = dialogs?.["createCopyDialog"] || {}
  const { open = false, data } = thisDialog

  const course = thisDialog?.data?.course
  const lesson = thisDialog?.data?.lesson
  const type = thisDialog?.data?.type
  const copyMutation = thisDialog?.data?.copyMutation

  const schema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  })

  const handleClose = () => {
    closeDialog("createCopyDialog")
  }

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true)
    const data = { name: values.name }
    copyMutation.mutate(data, {
      onSuccess: (res) => {
        serialize(type === "course" ? "course" : "lesson", res.data).then(
          (serializedData) => {
            return queryClient.setQueryData(
              type === "course" ? "courses" : "lessons",
              (oldata) => [...oldata, serializedData]
            )
          }
        )
        const basePath = type === "course" ? "courses" : "lessons"
        history.push(`/${basePath}/${res.data.id}`)
        openSnackBar({
          message: `${type === "course" ? "Course" : "Lesson"} copied.`,
        })
        actions.setSubmitting(false)
        handleClose()
      },
      onError: () =>
        openSnackBar({
          message: `Error copying  ${
            data.type === "course" ? "course." : "lesson."
          }`,
        }),
    })
  }

  return (
    <Dialog open={open}>
      <Formik
        initialValues={{
          name: type === "course" ? course?.name : lesson?.name,
        }}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({
          handleChange,
          values,
          errors,
          touched,
          handleBlur,
          isSubmitting,
          handleSubmit,
        }) => (
          <Form>
            <DialogTitle className={classes.dialogTitle} disableTypography>
              <Typography variant="h2" className={classes.title}>
                {type === "course" ? "Copy Course" : "Copy Lesson"}
              </Typography>
              <Typography variant="subtitle2">
                {`Please enter a name for the copy of this ${type}`}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Box>
                <Typography color="secondary" className={classes.title}>
                  {type === "course" ? "Course Name" : "Lesson Name"}
                </Typography>
                <TextField
                  type="text"
                  name="name"
                  value={values.name}
                  variant="filled"
                  size="small"
                  fullWidth
                  onChange={handleChange}
                  className={classes.textField}
                  onBlur={handleBlur}
                  error={touched.name && errors.name}
                  helperText={touched.name && errors.name}
                  FormHelperTextProps={{
                    className: classes.formHelperText,
                  }}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <LoaderButton
                type="submit"
                classoverrides={{
                  wrapper: classes.button,
                  button: {
                    root: classes.buttonRoot,
                  },
                }}
                variant="contained"
                color="secondary"
                working={isSubmitting}
                style={{ marginLeft: "8px" }}
              >
                Copy
              </LoaderButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default CreateCopyDialog
