import api from "store/api"
import serialize from "store/serialize"

export const listPaths = () => {
  return api
    .get("/learning_paths")
    .then((res) => serialize("path", res.data.data))
}

export const createPath = (data) => {
  return api.post("/learning_paths", data).then((res) => res.data)
}

export const updatePath = (data, id) => {
  return api.put(`/learning_paths/${id}`, data).then((res) => res.data)
}

export const addCourses = (data, id) => {
  return api.post(`learning_paths/${id}/courses`, data).then((res) => res.data)
}

export const createTags = (data, id) => {
  return api.post(`/learning_paths/${id}/tags`, data).then((res) => res.data)
}
export const publishLearningPath = (id) => {
  return api.post(`/learning_paths/${id}/publish`).then((res) => res.data)
}

export const unpublishLearningPath = (id) => {
  return api.post(`/learning_paths/${id}/unpublish`).then((res) => res.data)
}

export const archiveLearningPath = (id) => {
  return api.post(`/learning_paths/${id}/archive`).then((res) => res.data)
}

export const unarchiveLearningPath = (id) => {
  return api.post(`/learning_paths/${id}/unarchive`).then((res) => res.data)
}

export const pathAnalytics = (id) => {
  return api.get(`/learning_paths/analytics/${id}`).then((res) => res.data.data)
}
