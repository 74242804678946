import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  tabCard: {
    backgroundColor: "#fff",
    border: "1px solid #E2E2E2",
    borderRadius: 6,
    width: "100%",
    minHeight: 54,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 13px",
    marginBottom: 13,
  },
  removeButton: {
    color: theme.palette.warning.main,
    margin: theme.spacing(0, 1),
  },
  dragHandle: {
    display: "flex",
    justifyContent: "center",
    minWidth: 24,
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(0.5),
      order: 10,
    },
  },
}))

export default styles
