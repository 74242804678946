import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core"
import AppContext from "contexts/App"
import React from "react"
import { useContext } from "react"

import useStyles from "./styles"

const SimpleConfirmDialog = () => {
  const classes = useStyles()
  const { dialogs, closeDialog } = useContext(AppContext)
  const thisDialog = dialogs?.["confirmDialog"] || {}
  const { open = false, data } = thisDialog
  let title = data?.title || "Confirm"
  let message = data?.message || "Are you sure that you want to delete this?"

  const handleClose = () => {
    closeDialog("confirmDialog", false)
  }

  const confirmDelete = () => {
    closeDialog("confirmDialog", true)
  }

  return (
    <Dialog classes={{ paperWidthSm: classes.dialogMinHeight }} open={open}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions
        style={{
          position: "absolute",
          bottom: 4,
          right: 16,
        }}
      >
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={() => confirmDelete()}
          variant="contained"
          color="secondary"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SimpleConfirmDialog.propTypes = {}

export default SimpleConfirmDialog
