import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  icon: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 3,
    color: "#fff",
    fontSize: 15,
    position: "relative",
    top: 4,
    width: 18,
    height: 16,
    margin: "0 2px",
  },
}))

export default styles
