import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  learningCuesTimelineBox: {
    padding: theme.spacing(6.25),
    backgroundColor: "#F5F5F5",
    display: "flex",
    alignItems: "center",
  },
  timeline: {
    backgroundColor: "#d4d4d4",
    borderRadius: 4,
    height: 45,
    flexGrow: 1,
    position: "relative",
  },
  playhead: {
    width: 1,
    height: 64,
    backgroundColor: theme.palette.secondary.main,
    position: "absolute",
    left: 0,
    top: 0,
    cursor: "pointer",
    border: "none",
    outline: "none",
    padding: 0,
    zIndex: 2,
    "&:before": {
      content: "''",
      position: "absolute",
      top: 19,
      width: 7,
      height: 7,
      borderRadius: "50%",
      backgroundColor: theme.palette.secondary.main,
      left: -3.5,
    },
    "&:after": {
      top: 0,
      left: -7.5,
      content: "''",
      position: "absolute",
      height: "calc(100% - 8px)",
      padding: "0 8px",
    },
  },
  playheadButton: {
    minWidth: "initial",
    width: 18,
    height: 16,
    padding: 0,
    position: "absolute",
    bottom: -8,
    left: -8.5,
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
    borderRadius: 3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  playheadTime: {
    fontSize: "0.5625rem",
    fontWeight: 700,
    position: "absolute",
    top: -19,
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: "#F5F5F5",
  },
  breakpoint: {
    position: "absolute",
    bottom: 45,
    paddingBottom: theme.spacing(2),
    transform: "translateX(-50%)",
    color: "#444444",
    "&:after": {
      width: 1,
      height: theme.spacing(2),
      content: "''",
      position: "absolute",
      backgroundColor: "#444",
      left: "50%",
      transform: "translateX(-50%)",
    },
  },
  buttonLabel: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.6875rem",
  },
}))

export default styles
