import { Box, Typography } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"

import useStyles from "./styles"

const LearningCuesListEmpty = () => {
  const classes = useStyles()

  return (
    <Box
      display="flex"
      height="100%"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Typography
        variant="h4"
        style={{ fontWeight: 700, opacity: 0.27, marginBottom: 16 }}
      >
        No Learning Cues have been added
      </Typography>
      <Typography>
        Use the <AddIcon className={classes.icon} /> to add your first Learning
        Cue
      </Typography>
    </Box>
  )
}

export default LearningCuesListEmpty
