import LoaderButton from "@bit/c_t.components.loader-button"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core"
import AppContext from "contexts/App"
import PropTypes from "prop-types"
import React from "react"
import { useContext, useState } from "react"
import { useQueryClient } from "react-query"

import useStyles from "./styles"

const ConfirmationTypingDialog = () => {
  const queryClient = useQueryClient()
  //reused dialog for create course, create lesson, create cliente and create instructor
  const classes = useStyles()
  const { dialogs, closeDialog, openSnackBar, authUser } = useContext(
    AppContext
  )
  const [loading, setLoading] = useState(false)
  const thisDialog = dialogs?.["confirmationTypingDialog"] || {}
  const { open = false, data } = thisDialog
  //you need to pass a title (string), subittle(string), type(string, example: admin)
  //and delete (func to delete), refetchData(update data) and id to the dialog
  let title = thisDialog?.data?.title
  let subtitle = thisDialog?.data?.subtitle
  let deleteFuncMutation = thisDialog?.data?.delete
  let type = thisDialog?.data?.type
  let id = thisDialog?.data?.id
  let confirmWord = thisDialog?.data?.confirmWord
  const [deleteText, setDeleteText] = useState("")

  const handleClose = () => {
    closeDialog("confirmationTypingDialog", false)
    setDeleteText("")
  }
  //dont do delete mutation if
  const dontDoMutation =
    type !== "publish" &&
    type !== "unpublish" &&
    type !== "archive" &&
    type !== "unarchive"

  //submit confirmation
  const onSubmit = (values) => {
    if (!deleteFuncMutation) {
      closeDialog("confirmationTypingDialog", true)
      setDeleteText("")
    } else if (dontDoMutation) {
      setLoading(true)
      deleteFuncMutation.mutate(id, {
        onSuccess: () => {
          //rather than extraneous refetch call, returns filtered array minus the deleted record
          if (type !== "instructor") {
            queryClient.setQueryData(type + "s", (oldData) => {
              let newArray = oldData.filter((x) => x.id !== id)
              return [...newArray]
            })
          } else {
            queryClient.setQueryData(["instructors", authUser], (oldData) => {
              let newArray = oldData.filter((x) => x.id !== id)
              return [...newArray]
            })
          }
          openSnackBar({
            message: `${
              type?.[0]?.toUpperCase() + type.substring(1) + " Deleted"
            }`,
          })
          closeDialog("confirmationTypingDialog", true)
        },
        onError: (err) => {
          console.error(err)
          openSnackBar({
            message: `There was an error deleting a ${type}`,
          })
        },
        onSettled: () => {
          setLoading(false)
          setDeleteText("")
        },
      })
    } else {
      closeDialog("confirmationTypingDialog", true)
      setDeleteText("")
    }
  }
  //change delete textfield value
  const handleChangeText = (value) => {
    setDeleteText(value)
  }

  return (
    <Dialog classes={{ paperWidthSm: classes.dialogMinHeight }} open={open}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>
        <Typography color="primary" variant="subtitle2">
          {subtitle}
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        {/* <Typography color="secondary" className={classes.title}>
          {`Please type ${confirmWord} to confirm`}
        </Typography> */}
        <Box display="flex">
          <Typography color="primary" className={classes.title}>
            Please type
          </Typography>
          <Typography color="secondary" className={classes.title}>
            {confirmWord}
          </Typography>
          <Typography color="primary" className={classes.title}>
            to confirm
          </Typography>
        </Box>
        <TextField
          type="text"
          name="name"
          id="name"
          value={deleteText}
          /* eslint-disable */
          autoFocus
          /* eslint-enable */
          onChange={(e) => handleChangeText(e.target.value)}
          InputProps={{
            classes: {
              root: classes.inputBackgroundColor,
            },
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter" && e.target.value === confirmWord) {
              onSubmit()
            }
          }}
          variant="filled"
          size="small"
          fullWidth
        />
      </DialogContent>
      <DialogActions
        style={{
          position: "absolute",
          bottom: 4,
          right: 16,
        }}
      >
        <Button onClick={handleClose} style={{ height: "40px" }}>
          Cancel
        </Button>
        <LoaderButton
          type="submit"
          onClick={onSubmit}
          classoverrides={{
            wrapper: classes.button,
            button: {
              root: classes.buttonRoot,
            },
          }}
          working={loading}
          disabled={deleteText !== confirmWord || loading}
          variant="contained"
          color="secondary"
          size="small"
        >
          {dontDoMutation ? "Delete" : "Confirm"}
        </LoaderButton>
      </DialogActions>
    </Dialog>
  )
}

ConfirmationTypingDialog.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  content: PropTypes.node,
}

export default ConfirmationTypingDialog
