import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  progress: {
    height: 6,
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
  successIcon: {
    color: theme.palette.success.main,
  },
}))

export default styles
