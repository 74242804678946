import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  learningCuesList: {
    backgroundColor: "#fff",
    flexGrow: 1,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(8),
      marginTop: theme.spacing(8),
    },
  },
  lockedBanner: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#092958",
    alignItems: "center",
    height: 36,
    color: "#FFFFFF",
  },
  header: {
    height: 42,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexShrink: 0,
    paddingRight: theme.spacing(2),
    borderBottom: "1px solid #C4C4C4",
  },
  totalLearningCuesCount: {
    display: "flex",
    alignItems: "baseline",
    width: 100,
    justifyContent: "flex-end",
    paddingRight: theme.spacing(2),
  },
  totalLearningCuesText: {
    fontWeight: 700,
    fontSize: "1.125rem",
  },
  learningCueCounts: {
    paddingLeft: 7,
    display: "flex",
  },
  learningCueTypeCount: {
    fontWeight: 500,
    fontSize: "0.9375rem",
    opacity: 0.75,
    borderRadius: 6,
    width: 60,
    height: 30,
    lineHeight: "1.9rem",
  },
  largeButton: {
    fontSize: "0.625rem",
    color: theme.palette.secondary.main,
    textTransform: "initial",
    backgroundColor: "#E2F1F2",
    padding: "15px 8px",
    marginBottom: 14,
    "&:hover": {
      backgroundColor: "#bdeaec",
    },
    "& > .MuiButton-label": {
      display: "flex",
      flexDirection: "column",
      lineHeight: "11px",
      "& > svg": {
        marginBottom: theme.spacing(1),
      },
    },
  },
  undoRedoButton: {
    color: theme.palette.text.primary,
  },
}))

export default styles
