import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  formContainer: {
    backgroundColor: "#092958",
    width: "477px",
    height: "502px",
    borderRadius: "8px 8px 8px 8px",
  },
  formMobile: {
    backgroundColor: "#092958",
    width: "100%",
    height: "100%",
  },
  logo: {
    marginBottom: theme.spacing(6),
  },
}))

export default styles
