import { LinearProgress } from "@material-ui/core"
import { createDiscount } from "actions/discounts"
import { setDiscountCourses } from "actions/discounts"
import { listDiscounts } from "actions/discounts"
import AdminActionBar from "components/Common/AdminActionBar"
import DiscountCardList from "components/Discounts/DiscountCardList"
import AppContext from "contexts/App"
import { DiscountsContainer } from "contexts/Discounts"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useContext, useEffect, useState } from "react"
import { useMutation } from "react-query"
import { useQuery } from "react-query"
import { useHistory } from "react-router-dom"
import Router from "router"

import useStyles from "./styles"

const Discounts = ({ routes = [], ...rest }) => {
  const classes = useStyles()
  const [searchValue, setSearchValue] = useState("")
  const history = useHistory()

  const [sort, setSort] = useState({
    name: "",
    type: "",
    direction: "",
    icon: "",
  })

  const filterOptions = [{ id: 1, name: "Name" }]

  const { setPageTitle, setPageSubTitle, openDialog, authUser } = useContext(
    AppContext
  )
  const createDiscountMutation = useMutation((data) => createDiscount(data))
  const setDiscountCoursesMutatation = useMutation((data) =>
    setDiscountCourses(data.courses, data.id)
  )
  const { data: discounts, isLoading: discountsLoading } = useQuery(
    "discounts",
    () => listDiscounts()
  )

  useEffect(() => {
    setPageTitle("Discount Codes")
    setPageSubTitle(discounts?.length)
  }, [])

  const handleOpen = () => {
    openDialog("createDiscount", {
      create: createDiscountMutation,
      setDiscountCourses: setDiscountCoursesMutatation,
    })
  }

  //checks auth user role
  useEffect(() => {
    if (authUser?.access_coupons !== 1) {
      history.push("/learning-paths")
    }
  }, [authUser])

  return (
    <Page>
      <DiscountsContainer>
        <AdminActionBar
          buttonName="New Code"
          buttonFunc={handleOpen}
          sortOptions={filterOptions}
          sortState={sort}
          setSortState={setSort}
          setFilterState={setSearchValue}
          searchText="Search discount codes"
        />
        {discountsLoading ? (
          <LinearProgress color="primary" />
        ) : (
          <DiscountCardList
            searchValue={searchValue}
            discounts={discounts}
            handleOpen={handleOpen}
            sort={sort}
          />
        )}
        <Router routes={routes} {...rest} />
      </DiscountsContainer>
    </Page>
  )
}

Discounts.propTypes = {
  routes: PropTypes.array,
}

export default Discounts
