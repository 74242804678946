import {
  copyLab,
  createPort as createPortAction,
  lockLab,
  orderPorts as orderPortsAction,
  removePort as removePortAction,
  unlockLab,
  updateLab,
  updatePort as updatePortAction,
} from "actions/labs"
import { updatedDiff } from "deep-object-diff"
import PropTypes from "prop-types"
import { createContext, useCallback, useContext, useEffect } from "react"
import { useState } from "react"
import { useMutation, useQueryClient } from "react-query"

import AppContext from "./App"

const LabContext = createContext()
export default LabContext

export const LabContainer = ({ children, lab, labLoading }) => {
  //state to check if there is data unsaved
  const [unsavedState, setUnsavedState] = useState([])
  const queryClient = useQueryClient()
  const { openSnackBar, openDialog } = useContext(AppContext)
  //loding state for the save as locked if lab is locked
  //or save as draft if is a draft
  const [saveLoading, setSaveLoading] = useState(false)
  // loading state when locking a lab
  const [lockLoading, setLockLoading] = useState(false)
  // loading state when unlock a course
  const [unlockLoading, setUnlockLoading] = useState(false)
  //open and close header menu
  const [anchorEl, setAnchorEl] = useState(null)
  //state to show the validation errors if the user try to lock
  const [tryLock, setTryLock] = useState(false)
  //state to show the validation errors for name, descritpion, headline
  const [lengthError, setLengthError] = useState(false)

  useEffect(() => {
    setUnsavedState([])
  }, [])

  //lab general information state with debounced
  const [debouncedDetails, setDebouncedDetails] = useState({
    name: "",
    description: "",
    instance_type: "",
    image: null,
  })

  const [labDetailsValues, setLabDetailsValues] = useState({
    name: "",
    description: "",
    instance_type: "",
    image: null,
  })
  //course general information initial state
  const [initialDetailsContext, setInitialDetailsContext] = useState({
    name: "",
    description: "",
    instance_type: "",
    image: null,
  })

  //check unsave details data
  useEffect(() => {
    const unsavedData = updatedDiff(initialDetailsContext, labDetailsValues)
    if (
      Object.keys(unsavedData)?.length > 0 &&
      !unsavedState.includes("details")
    ) {
      setUnsavedState((oldState) => [...oldState, "details"])
    } else if (
      Object.keys(unsavedData)?.length === 0 &&
      unsavedState.includes("details")
    ) {
      setUnsavedState(
        unsavedState.filter((x) => {
          return x !== "details"
        })
      )
    }
  }, [labDetailsValues, JSON.stringify(lab)])

  const updateLabMutation = useMutation((data) => updateLab(data, lab.id))

  const lockLabMutation = useMutation(() => lockLab(lab))

  const unlockLabMutation = useMutation(() => unlockLab(lab))

  const copyLabMutation = useMutation((data) => copyLab(data, lab?.id))

  //if stament to know what we need to complete to lock
  const disabledSave =
    debouncedDetails?.name === "" ||
    debouncedDetails?.name?.length > 50 ||
    debouncedDetails?.description === "" ||
    debouncedDetails?.description?.length > 2000

  //disabled the input depends the length when save
  let correctInputsLength =
    debouncedDetails?.name?.length <= 50 &&
    debouncedDetails?.description !== null
      ? debouncedDetails?.description?.length <= 2000
      : true

  //close menu
  const handleClose = () => {
    setAnchorEl(null)
  }

  // update lab details
  const updateDetails = () => {
    return new Promise((resolve, reject) => {
      if (unsavedState.includes("details")) {
        updateLabMutation.mutate(debouncedDetails, {
          onSuccess: (res) => {
            queryClient.setQueryData(["lab", res.id + ""], (oldState) => {
              return res
            })
            queryClient.setQueryData("labs", (oldData) => {
              let newArray = []
              newArray = oldData.map((x) => {
                if (x.id !== res.id) {
                  return x
                } else {
                  return res
                }
              })
              return [...newArray]
            })
            //if is locked send the updated message
            if (lab.locked === 1) {
              openSnackBar({ message: "Lab Updated" })
            }
            resolve()
            openSnackBar({
              message: "Lab updated successfully",
            })
          },
          onError: (err) => {
            openSnackBar({
              message: "Error Updating Lab",
            })
            setSaveLoading(false)
            reject()
          },
        })
      } else {
        resolve()
      }
    })
  }

  //update labs depends if is locked or not
  const handleUpdate = () => {
    if (disabledSave && lab.locked === 1) {
      //if lab is locked dont let save if some data is ont completed
      setTryLock(true)
      handleClose()
    } else {
      //hide errors needed if the user click lock before and have errors to lock
      setTryLock(false)
      if (!correctInputsLength) {
        setLengthError(true)
      } else {
        setSaveLoading(true)
        return updateDetails()
          .then(() => {
            openSnackBar({ message: "Lab Updated" })
            setSaveLoading(false)
            handleClose()
          })
          .catch((err) => {
            openSnackBar({
              message: "Error updating Lab",
            })
            setSaveLoading(false)
            handleClose()
          })
      }
    }
  }

  //lock lab
  const lockLabFunc = () => {
    //open errors if the users didn't complete all that is require in disabledSave
    if (disabledSave) {
      setTryLock(true)
      handleClose()
    } else {
      let data = {
        title: "Lock Confirmation",
        subtitle: "",
        type: "lock",
        confirmWord: "lock",
      }
      handleClose()
      openDialog("confirmationTypingDialog", data).then(() => {
        //open confirm dialog
        handleUpdate().then(() => {
          setLockLoading(true)
          lockLabMutation.mutate(
            {},
            {
              onSuccess: (res) => {
                queryClient.setQueryData(["lab", res.id], (oldData) => {
                  return res
                })
                queryClient.setQueryData("labs", (oldData) => {
                  let newArray = []
                  newArray = oldData.map((x) => {
                    if (x.id !== res.id) {
                      return x
                    } else {
                      return res
                    }
                  })
                  return [...newArray]
                })
                openSnackBar({
                  message: "This lab was locked",
                })
                setLockLoading(false)
                handleClose()
              },
              onError: (err) => {
                openSnackBar({
                  message: "Error Locking Lab",
                })
                setLockLoading(false)
              },
            }
          )
        })
      })
    }
  }

  //unlock lab
  const unlockLabFunc = () => {
    //check if the inputs have the corrent length
    if (!correctInputsLength) {
      setLengthError(true)
      handleClose()
    } else {
      let data = {
        title: "Unlock Confirmation",
        subtitle: "",
        type: "unlock",
        confirmWord: "unlock",
      }
      handleClose()
      openDialog("confirmationTypingDialog", data).then(() => {
        setUnlockLoading(true)
        handleUpdate().then(() => {
          unlockLabMutation.mutate(
            {},
            {
              onSuccess: (res) => {
                queryClient.setQueryData(["lab", res.id], (oldData) => {
                  return res
                })
                queryClient.setQueryData("labs", (oldData) => {
                  let newArray = []
                  newArray = oldData.map((x) => {
                    if (x.id !== res.id) {
                      return x
                    } else {
                      return res
                    }
                  })
                  return [...newArray]
                })
                // refetchLockedLabs()
                openSnackBar({
                  message: "This lab was unlocked",
                })
                setUnlockLoading(false)
                handleClose()
              },
              onError: (err) => {
                openSnackBar({
                  message: "Error Unlocking Lab",
                })
                setUnlockLoading(false)
                handleClose()
              },
            }
          )
        })
      })
    }
  }

  const createPortMutation = useMutation("createPort", (data) =>
    createPortAction(data?.labId, data?.data)
  )

  const createPort = useCallback(
    async (data) => {
      const newLabData = await createPortMutation.mutateAsync({
        labId: lab.id,
        data,
      })
      return new Promise((resolve) => {
        // use string in query selector since we got a string back from the route params
        queryClient.setQueryData(["lab", lab.id + ""], () => {
          return newLabData
        })
        resolve()
      })
    },
    [lab.id, queryClient, createPortMutation]
  )

  const updatePortMutation = useMutation("updatePort", (data) =>
    updatePortAction(data?.labId, data?.portId, data?.data)
  )

  const updatePort = useCallback(
    async (portId, data) => {
      const newLabData = await updatePortMutation.mutateAsync({
        labId: lab.id,
        portId: portId,
        data,
      })
      return new Promise((resolve) => {
        // use string in query selector since we got a string back from the route params
        queryClient.setQueryData(["lab", lab.id + ""], () => {
          return newLabData
        })
        resolve()
      })
    },
    [lab.id, queryClient, updatePortMutation]
  )

  const removePortMutation = useMutation("removePort", (data) =>
    removePortAction(data?.labId, data?.portId)
  )

  const removePort = useCallback(
    async (portId) => {
      const newLabData = await removePortMutation.mutateAsync({
        labId: lab.id,
        portId,
      })
      // use string in query selector since we got a string back from the route params
      queryClient.setQueryData(["lab", lab.id + ""], () => {
        return newLabData
      })
    },
    [lab.id, queryClient, removePortMutation]
  )

  const orderPortsMutation = useMutation("orderPorts", (data) =>
    orderPortsAction(data?.labId, data?.data)
  )

  const orderPorts = useCallback(
    async (data) => {
      const newLabData = await orderPortsMutation.mutateAsync({
        labId: lab.id,
        data,
      })
      return new Promise((resolve) => {
        // use string in query selector since we got a string back from the route params
        queryClient.setQueryData(["lab", lab.id + ""], () => {
          return newLabData
        })
        resolve()
      })
    },
    [lab.id, queryClient, orderPortsMutation]
  )

  //discard changes
  const discardChanges = () => {
    if (unsavedState.includes("details")) {
      setLabDetailsValues(initialDetailsContext)
      setDebouncedDetails(initialDetailsContext)
    }
  }
  return (
    <LabContext.Provider
      value={{
        lab,
        unsavedState,
        setUnsavedState,
        saveLoading,
        lockLoading,
        unlockLoading,
        anchorEl,
        setAnchorEl,
        tryLock,
        lengthError,
        setTryLock,
        debouncedDetails,
        setDebouncedDetails,
        labDetailsValues,
        setLabDetailsValues,
        setInitialDetailsContext,
        lockLabMutation,
        updateLabMutation,
        updateLab,
        handleUpdate,
        lockLabFunc,
        unlockLabFunc,
        discardChanges,
        labLoading,
        copyLabMutation,
        disabledSave,
        correctInputsLength,
        createPort,
        updatePort,
        removePort,
        orderPorts,
      }}
    >
      {children}
    </LabContext.Provider>
  )
}

LabContainer.propTypes = {
  children: PropTypes.node,
  lab: PropTypes.object,
  labLoading: PropTypes.bool,
}
