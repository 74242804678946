import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  learningCueBlocks: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: "none",
  },
  block: {
    position: "absolute",
    height: "25%",
    borderRadius: 2,
  },
  mediaBlock: {
    top: 0,
    backgroundColor: "rgba(23, 165, 177, 0.4)",
    border: "1px solid rgba(23, 165, 177, 0.6)",
    "&$highlighted": {
      boxShadow: "0px 0px 4px 2px rgba(23, 165, 177, 0.3)",
      border: "1px solid rgba(23, 165, 177, 0.75)",
      backgroundColor: "rgba(23, 165, 177, 0.75)",
      zIndex: 9999,
    },
  },
  topBannerBlock: {
    top: "25%",
    backgroundColor: "rgba(255, 106, 64, 0.4)",
    border: "1px solid rgba(255, 106, 64, 0.6)",
    "&$highlighted": {
      boxShadow: "0px 0px 4px 2px rgba(255, 106, 64, 0.3)",
      border: "1px solid rgba(255, 106, 64, 0.75)",
      backgroundColor: "rgba(255, 106, 64, 0.75)",
      zIndex: 9999,
    },
  },
  bottomBannerBlock: {
    top: "50%",
    backgroundColor: "rgba(227, 43, 110, 0.4)",
    border: "1px solid rgba(227, 43, 110, 0.6)",
    "&$highlighted": {
      boxShadow: "0px 0px 4px 2px rgba(227, 43, 110, 0.3)",
      border: "1px solid rgba(227, 43, 110, 0.75)",
      backgroundColor: "rgba(227, 43, 110, 0.75)",
      zIndex: 9999,
    },
  },
  fullscreenBlock: {
    top: "75%",
    backgroundColor: "rgba(136, 136, 136, 0.4)",
    border: "1px solid rgba(136, 136, 136, 0.6)",
    "&$highlighted": {
      boxShadow: "0px 0px 4px 2px rgba(136, 136, 136, 0.3)",
      border: "1px solid rgba(136, 136, 136, 0.75)",
      backgroundColor: "rgba(136, 136, 136, 0.75)",
      zIndex: 9999,
    },
  },
  highlighted: {},
}))

export default styles
