import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  current: {
    backgroundColor: "#C8E5E7",
    height: "100%",
    opacity: 1,
  },

  tab: {
    opacity: 1,
  },
  label: {
    fontWeight: 500,
  },
  image: {
    marginRight: theme.spacing(1),
  },
  flexContainer: {
    height: "100%",
  },
}))

export default styles
