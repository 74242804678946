import LoaderButton from "@bit/c_t.components.loader-button"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core"
import CounterHeader from "components/Common/CounterHeader"
import AppContext from "contexts/App"
import { Form, Formik } from "formik"
import PropTypes from "prop-types"
import { useContext, useState } from "react"
import { useQueryClient } from "react-query"
import serialize from "store/serialize"
import characterCounter from "utils/characterCounter"
import * as Yup from "yup"

import useStyles from "./styles"

const CreateClientDialog = () => {
  const queryClient = useQueryClient()
  const classes = useStyles()
  const authContext = useContext(AppContext)
  const { dialogs, closeDialog, openSnackBar } = useContext(AppContext)
  const thisDialog = dialogs?.["createClientDialog"] || {}
  const { open = false, data } = thisDialog

  let title = thisDialog?.data?.title
  let subtitle = thisDialog?.data?.subtitle
  let createFuncMutation = thisDialog?.data?.create
  let type = thisDialog?.data?.type
  let listData = thisDialog?.data?.listData

  const [helperText, setHelperText] = useState(null)

  const schema = Yup.object().shape({
    name: Yup.string()
      .required(`Please enter a ${type} name`)
      .max(50, "Name must be less than 50 characters"),
  })

  const handleClose = () => {
    setHelperText(null)
    closeDialog("createClientDialog", false)
  }

  const onSubmit = (values, actions) => {
    if (
      listData?.find(
        (x) => x?.name?.toLowerCase() === values?.name?.toLowerCase()
      )
    ) {
      setHelperText("Please enter a different client name")
      openSnackBar({ message: "Error. This client name already exists." })
    } else {
      createFuncMutation.mutate(values, {
        onSuccess: (res) => {
          serialize("client", res.data).then((serializedData) => {
            queryClient.setQueryData("clients", (oldData) => {
              const newArray = [...oldData, serializedData]
              return newArray
            })
          })
          authContext.openSnackBar({
            message: `${
              type?.[0]?.toUpperCase() + type.substring(1) + " Created"
            }`,
          })
        },
        onSettled: () => {
          handleClose()
        },
      })
    }
  }

  return (
    <Dialog classes={{ paperWidthSm: classes.dialogMinHeight }} open={open}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>
        <Typography color="primary" variant="subtitle2">
          {subtitle}
        </Typography>
      </DialogTitle>
      <Formik
        initialValues={{ name: "" }}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        {({
          handleChange,
          values,
          errors,
          touched,
          handleBlur,
          isSubmitting,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <DialogContent className={classes.dialogContent}>
              <CounterHeader
                title={
                  type?.[0]?.toUpperCase() + type?.substring(1) + " " + "Name"
                }
                value={values.name}
                max={50}
              />
              <TextField
                type="text"
                name="name"
                id="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={
                  /* eslint-disable */
                  helperText
                    ? helperText
                    : touched.name && errors.name
                    ? errors.name
                    : " "
                  /* eslint-enable */
                }
                /* eslint-disable-next-line */
                autoFocus
                InputProps={{
                  classes: {
                    root: classes.inputBackgroundColor,
                  },
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter" && e.target.value.length > 0) {
                    onSubmit({ name: e.target.value })
                    e.preventDefault()
                  }
                }}
                error={helperText ? true : touched.name && errors.name}
                variant="filled"
                size="small"
                fullWidth
                FormHelperTextProps={{
                  className: classes.formHelperText,
                }}
              />
            </DialogContent>
            <DialogActions>
              <Box m={2}>
                <Button
                  onClick={handleClose}
                  style={{ height: "40px", marginRight: "8px" }}
                >
                  Cancel
                </Button>
                <LoaderButton
                  type="submit"
                  // onClick={onSubmit}
                  classoverrides={{
                    wrapper: classes.button,
                    button: {
                      root: classes.buttonRoot,
                    },
                  }}
                  variant="contained"
                  color="secondary"
                  working={isSubmitting}
                  size="small"
                >
                  add
                </LoaderButton>
              </Box>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

CreateClientDialog.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  content: PropTypes.node,
}

export default CreateClientDialog
