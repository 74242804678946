import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  profileImage: {
    border: `1px solid ${theme.palette.primary.main} `,
    height: 135,
    width: 135,
  },
  title: {
    fontWeight: "bold",
  },
  inputBackgroundColor: {
    backgroundColor: "#F4F4F4",
    "&.Mui-disabled": {
      backgroundColor: "#F4F4F4",
    },
  },
  inputBio: {
    borderRadius: "4px",
    paddingTop: "10px !important",
    paddingBottom: "10px !important",
    paddingLeft: "10px",
    paddingRight: "10px",
    "&.Mui-disabled": {
      backgroundColor: "#F4F4F4",
    },
  },
  disabled: {
    color: "black",
  },
  cancel: {
    marginRight: theme.spacing(1),
  },
  remove: {
    color: "#FF5E69",
    border: "1px solid #A21F28",
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(2),
    },
  },
  contentContainer: {
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
      height: 320,
    },
  },
  textFieldContainer: {
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
      justifyContent: "flex-start",
      height: 150,
      margin: 0,
    },
  },
  textField: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(2),
    },
  },
  buttonsContainer: {
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
      justifyContent: "center",
    },
  },
}))

export default styles
