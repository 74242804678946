import { Tooltip, Typography } from "@material-ui/core"
import React from "react"
import PropTypes from "prop-types"

const HoverText = ({ data, size, variant, className }) => {
  return (
    <>
      {data && data.length > size && typeof data === "string" ? (
        <Tooltip
          title={data}
          placement="bottom-start"
          enterDelay={700}
          interactive
          transitioncomponent="zoom"
        >
          <div>
            <Typography
              className={className}
              variant={variant}
              style={{ wordBreak: "break-word" }}
            >
              {data.slice(0, size) + "..."}
            </Typography>
          </div>
        </Tooltip>
      ) : (
        <Typography
          variant={variant}
          className={className}
          style={{ wordBreak: "break-word" }}
        >
          {data}
        </Typography>
      )}
    </>
  )
}

HoverText.propTypes = {
  data: PropTypes.string,
  size: PropTypes.number,
  variant: PropTypes.string,
  className: PropTypes.node,
}

export default HoverText
