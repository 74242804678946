import {
  Box,
  CircularProgress,
  LinearProgress,
  Typography,
} from "@material-ui/core"
import ListTitle from "components/Common/ListTitle"
import NoData from "components/Common/NoData"
import InstructorsCard from "components/Instructors/InstructorCard"
import AppContext from "contexts/App"
import InstructorsContext from "contexts/Instructors"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import sortHelper from "utils/sortHelper"

import useStyles from "./styles"

const InstructorCardList = ({ searchValue, sort, openCreateDialog }) => {
  const classes = useStyles()
  const [filteredData, setFilteredData] = useState([])
  const { setPageSubTitle } = useContext(AppContext)
  //amount of data that we show using the infinite scroll
  const [showedData, setShowedData] = useState([])
  const { instructors, instructorsLoading } = useContext(InstructorsContext)

  //sets instructor data to filteredData the first time
  useEffect(() => {
    if (instructors?.length > 0 && !filteredData) {
      setFilteredData(instructors)
    }
  }, [instructors])

  useEffect(() => {
    if (instructors) {
      let newArray = [...instructors]
      if (searchValue.length > 0 || sort?.name !== "") {
        // if a search val is applied
        if (searchValue.length > 0) {
          newArray = newArray?.filter((x) =>
            x.name.toLowerCase().includes(searchValue.toLowerCase())
          )
        }
        if (sort?.name !== "") {
          //util function to sort the data
          newArray = sortHelper(
            newArray,
            sort?.type,
            sort?.name,
            sort?.direction
          )
        }
      } else if (searchValue === "" && sort.name === "") {
        newArray = instructors
      }
      setFilteredData(newArray)
      //show 20 first elements of the newArray
      setShowedData(newArray?.slice(0, 20 || []))
      setPageSubTitle(newArray?.length)
    }
  }, [searchValue, sort, instructors])

  //loadData using infinite scroll
  const loadData = () => {
    setShowedData(() => filteredData.slice(0, showedData.length + 20))
  }

  //send the user to the top if a  sort or search value change
  useEffect(() => {
    const element = document.getElementById("instructorsListScroll")
    if (element) {
      element.scrollTo(0, 0)
    }
  }, [searchValue, sort])

  if (instructorsLoading) {
    return <LinearProgress color="primary" />
  }

  return (
    <Box
      overflow="auto"
      className={classes.container}
      id="instructorsListScroll"
    >
      <Box marginLeft={2} className={classes.dataWrapper}>
        {filteredData?.length > 0 && (
          <ListTitle
            list={[
              { name: "", size: "12%" },
              { name: "Name", size: "12%" },
              { name: "Title", size: "30%" },
              { name: "Bio", size: "30%" },
              { name: "", size: "10%" },
            ]}
          />
        )}

        {filteredData?.length > 0 ? (
          <InfiniteScroll
            style={{ overflow: "hidden" }}
            dataLength={showedData?.length || 0}
            scrollableTarget="instructorsListScroll"
            next={loadData}
            loader={
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            }
            hasMore={filteredData?.length > showedData?.length || false}
            endMessage={
              <Box width="100%" textAlign="center">
                {filteredData.length > 20 && (
                  <Typography className={classes.postsInfiniteText}>
                    End of Instructors List
                  </Typography>
                )}
              </Box>
            }
          >
            {showedData.map((instructor, i) => {
              return (
                <InstructorsCard
                  key={i}
                  name={instructor.name}
                  title={instructor.title}
                  profileImg={instructor.profile_image?.url}
                  bio={instructor.bio}
                  id={instructor.id}
                />
              )
            })}
          </InfiniteScroll>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            minHeight="80vh"
          >
            <NoData
              text="No Instructors"
              buttonFunc={openCreateDialog}
              buttonText="Add a new instructor"
              searchValue={searchValue}
              length={instructors?.length}
              searchLength={filteredData?.length}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}

InstructorCardList.propTypes = {
  searchValue: PropTypes.string,
  sort: PropTypes.object,
  openCreateDialog: PropTypes.func,
}

export default InstructorCardList
