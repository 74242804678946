import { Paper } from "@material-ui/core"
import PropTypes from "prop-types"

import useStyles from "./styles"

const PaperListRow = ({
  children,
  componentWidth = "100%",
  componentHeight = "64px",
  provided = {},
  color = "#fff",
}) => {
  const classes = useStyles()
  // optional to receive componentWidth, componentHeight, color from parent el.
  return (
    <Paper
      elevation={0}
      className={classes.paperRow}
      style={{
        width: componentWidth,
        height: componentHeight,
        backgroundColor: color,
      }}
      ref={provided.innerRef}
      {...provided.draggableProps}
    >
      {children}
    </Paper>
  )
}

PaperListRow.propTypes = {
  children: PropTypes.node,
  componentWidth: PropTypes.string,
  componentHeight: PropTypes.string,
  provided: PropTypes.object,
  color: PropTypes.string,
}

export default PaperListRow
