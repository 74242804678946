import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@material-ui/core"
import { getUsersCourseDetails, listUserInvites } from "actions/users"
import AppContext from "contexts/App"
import dayjs from "dayjs"
import PropTypes from "prop-types"
import { useContext } from "react"
import { useQuery } from "react-query"

import useStyles from "./styles"

const UserDetailsDialog = () => {
  //reused dialog for create course, create lesson, create cliente and create instructor
  const classes = useStyles()
  const { dialogs, closeDialog, openDialog } = useContext(AppContext)
  const thisDialog = dialogs?.["userDetailsDialog"] || {}
  const { open = false, data } = thisDialog
  let name = thisDialog?.data?.first_name + " " + thisDialog?.data?.last_name
  let email = thisDialog?.data?.email
  let activeDate = thisDialog?.data?.updated
  let id = thisDialog?.data?.id

  const usersCourseDetails = useQuery(
    ["completedCourses", id],
    () => getUsersCourseDetails(id),
    { enabled: Boolean(id) }
  )

  const usersInvites = useQuery(
    ["usersInvites", id],
    () => listUserInvites(id),
    { enabled: Boolean(id) }
  )

  const handleOpen = (i) => {
    if (i.accepted === 1) {
      const data = {
        id: i.invitee_user_id,
        first_name: i.first_name,
        last_name: i.last_name,
        email: i.invitee_email,
        updated: "",
      }
      openDialog("userDetailsDialog", data)
    }
  }

  const handleClose = () => {
    closeDialog("userDetailsDialog", false)
  }

  const noCourseData =
    usersCourseDetails?.data?.completedCourses?.length === 0 &&
    usersCourseDetails?.data?.progressCourses?.length === 0

  const completedCourses = usersCourseDetails?.data?.completedCourses
  const coursesInProgress = usersCourseDetails?.data?.progressCourses

  const invites = usersInvites?.data?.data

  return (
    <Dialog classes={{ paperWidthSm: classes.dialogMinHeight }} open={open}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Box display="flex">
          <Box width="90%">
            <Typography variant="h2" color="primary" className={classes.name}>
              {name}
            </Typography>
            <Typography color="primary" variant="subtitle2">
              {email}
            </Typography>
            <Typography className={classes.activeDate} variant="subtitle2">
              {activeDate &&
                "Last active " + dayjs(activeDate).format("MM/DD/YYYY")}
            </Typography>
          </Box>
          <Box width="10%" display="flex" justifyContent="flex-end">
            <Typography variant="h4">{"ID: " + id}</Typography>
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent>
        {noCourseData ? (
          <>
            <Box
              marginTop={
                coursesInProgress?.length !== 0 || completedCourses?.length
                  ? 2
                  : 0
              }
              marginBottom={2}
            >
              <Typography color="secondary" style={{ fontWeight: "bold" }}>
                Invites
              </Typography>
            </Box>
            <Box display="flex">
              <Box width="25%">
                <Typography color="secondary" style={{ fontWeight: "bold" }}>
                  Email
                </Typography>
              </Box>
              <Box width="25%" display="flex" justifyContent="flex-end">
                <Typography color="secondary" style={{ fontWeight: "bold" }}>
                  First
                </Typography>
              </Box>
              <Box width="25%" display="flex" justifyContent="flex-end">
                <Typography color="secondary" style={{ fontWeight: "bold" }}>
                  Last
                </Typography>
              </Box>
              <Box width="25%" display="flex" justifyContent="flex-end">
                <Typography color="secondary" style={{ fontWeight: "bold" }}>
                  Status
                </Typography>
              </Box>
            </Box>
            {invites?.map((i) => (
              <>
                <Box display="flex" marginTop={1}>
                  <Box
                    width="25%"
                    onClick={() => handleOpen(i)}
                    style={i.accepted ? { cursor: "pointer" } : null}
                  >
                    <Typography>{i.invitee_email}</Typography>
                  </Box>
                  <Box width="25%" display="flex" justifyContent="flex-end">
                    <Typography>{i.first_name}</Typography>
                  </Box>
                  <Box width="25%" display="flex" justifyContent="flex-end">
                    <Typography>{i.last_name}</Typography>
                  </Box>
                  <Box width="25%" display="flex" justifyContent="flex-end">
                    {i.accepted ? (
                      <Typography color="inhert" style={{ color: "#17B158" }}>
                        Accepted
                      </Typography>
                    ) : (
                      <Typography
                        color="inherit"
                        style={{ color: i.sent ? "#FFD034" : null }}
                      >
                        {i.sent ? "Pending" : "Inactive"}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Divider />
              </>
            ))}
          </>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            minHeight={200}
            maxHeight={500}
            style={{ overflowY: "scroll" }}
          >
            {completedCourses?.length !== 0 && (
              <Box display="flex">
                <Box width="90%">
                  <Typography color="secondary" style={{ fontWeight: "bold" }}>
                    Completed courses
                  </Typography>
                </Box>
                <Box width="10%" display="flex" justifyContent="flex-end">
                  <Typography color="secondary" style={{ fontWeight: "bold" }}>
                    Score
                  </Typography>
                </Box>
              </Box>
            )}
            {completedCourses?.map((c) => (
              <>
                <Box key={c.id} display="flex" marginTop={1}>
                  <Box width="90%">
                    <Typography>{c.name}</Typography>
                  </Box>
                  <Box width="10%" display="flex" justifyContent="flex-end">
                    <Typography color="inherit" style={{ color: "#17B158" }}>
                      {c.score.toFixed(1)}%
                    </Typography>
                  </Box>
                </Box>
                <Divider />
              </>
            ))}
            {coursesInProgress?.length !== 0 && (
              <Box
                display="flex"
                marginTop={completedCourses?.length !== 0 ? 2 : 0}
              >
                <Box width="90%">
                  <Typography color="secondary" style={{ fontWeight: "bold" }}>
                    In progress
                  </Typography>
                </Box>
                <Box width="10%" display="flex" justifyContent="flex-end">
                  <Typography color="secondary" style={{ fontWeight: "bold" }}>
                    Progress
                  </Typography>
                </Box>
              </Box>
            )}
            {coursesInProgress?.map((c) => (
              <>
                <Box key={c.id} display="flex" marginTop={1}>
                  <Box width="90%">
                    <Typography>{c.name}</Typography>
                  </Box>
                  <Box width="10%" display="flex" justifyContent="flex-end">
                    <Typography color="inherit" style={{ color: "#17B158" }}>
                      {c.progress.toFixed(1)}%
                    </Typography>
                  </Box>
                </Box>
                <Divider />
              </>
            ))}
            <>
              <Box
                marginTop={
                  coursesInProgress?.length !== 0 || completedCourses?.length
                    ? 2
                    : 0
                }
                marginBottom={2}
              >
                <Typography color="secondary" style={{ fontWeight: "bold" }}>
                  Invites
                </Typography>
              </Box>
              <Box display="flex">
                <Box width="25%">
                  <Typography color="secondary" style={{ fontWeight: "bold" }}>
                    Email
                  </Typography>
                </Box>
                <Box width="25%" display="flex" justifyContent="flex-end">
                  <Typography color="secondary" style={{ fontWeight: "bold" }}>
                    First
                  </Typography>
                </Box>
                <Box width="25%" display="flex" justifyContent="flex-end">
                  <Typography color="secondary" style={{ fontWeight: "bold" }}>
                    Last
                  </Typography>
                </Box>
                <Box width="25%" display="flex" justifyContent="flex-end">
                  <Typography color="secondary" style={{ fontWeight: "bold" }}>
                    Status
                  </Typography>
                </Box>
              </Box>
            </>
            {invites?.map((i) => (
              <>
                <Box display="flex" marginTop={1}>
                  <Box
                    width="25%"
                    onClick={() => handleOpen(i)}
                    style={i.accepted ? { cursor: "pointer" } : null}
                  >
                    <Typography>{i.invitee_email}</Typography>
                  </Box>
                  <Box width="25%" display="flex" justifyContent="flex-end">
                    <Typography>{i.first_name}</Typography>
                  </Box>
                  <Box width="25%" display="flex" justifyContent="flex-end">
                    <Typography>{i.last_name}</Typography>
                  </Box>
                  <Box width="25%" display="flex" justifyContent="flex-end">
                    {i.accepted ? (
                      <Typography color="inhert" style={{ color: "#17B158" }}>
                        Accepted
                      </Typography>
                    ) : (
                      <Typography
                        color="inherit"
                        style={{ color: i.sent ? "#FFD034" : null }}
                      >
                        {i.sent ? "Pending" : "Inactive"}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Divider />
              </>
            ))}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          style={{ height: "40px" }}
          variant="contained"
          color="secondary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

UserDetailsDialog.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  content: PropTypes.node,
}

export default UserDetailsDialog
