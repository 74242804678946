import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  usersListContainer: {
    width: "100%",
    height: "calc(100% - 50px)",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      display: "block",
      overflow: "scroll",
    },
  },
  leftContainer: {
    height: "100%",
    width: "25%",
    flexShrink: 0,
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "345px",
    },
  },
  rigthContainer: {
    width: "75%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}))

export default styles
