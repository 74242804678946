import { Box, useMediaQuery, useTheme } from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton"
import PaperListRow from "components/Common/PaperListRow"
import React from "react"

const NoCourseAdded = () => {
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <PaperListRow>
      <Box width={smDown ? "80%" : "50%"} display="flex" marginBottom="4px">
        <Skeleton animation="wave" height="60px" width="40%" />
        <Box
          marginLeft={1}
          marginTop={0.5}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="55%"
        >
          <Skeleton animation="wave" height="30px" width="80%" />
          <Skeleton animation="wave" height="20px" />
        </Box>
      </Box>
      <Box width={smDown ? "16%" : "10%"}>
        <Skeleton animation="wave" height="40px" width={smDown && "50px"} />
      </Box>
    </PaperListRow>
  )
}

export default NoCourseAdded
