import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import CounterHeader from "components/Common/CounterHeader"
import DateTime from "components/Common/DateTime"
import Tag from "components/Common/Tag"
import ThumbnailUpload from "components/Common/ThumbnailUpload"
import AppContext from "contexts/App"
import PathContext from "contexts/Path"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { useQueryClient } from "react-query"
import serialize from "store/serialize"
import { useDebounce } from "use-lodash-debounce"
import characterCounter from "utils/characterCounter"

import useStyles from "./styles"
const PathDetails = ({ tags }) => {
  const classes = useStyles()
  const queryClient = useQueryClient()

  const { openSnackBar, setPageSubTitle, authUser } = useContext(AppContext)
  const {
    path,
    setDebouncedDetails,
    debouncedDetails,
    pathDetailsValues,
    setPathDetailsValues,
    setInitialDetailsContext,
    tryPublish,
    lengthError,
    tagsContext,
    setTagsContext,
    updatePathMutation,
  } = useContext(PathContext)
  const debouncedLocalValues = useDebounce(pathDetailsValues, 200)
  const [tagAutocompleteValue, setTagAutocompleteValue] = useState("")
  const [tagTextFieldValue, setTagTextFieldValue] = useState("")

  //show errors if inputs are empty or more than allow characters
  const descriptionError =
    ((debouncedDetails?.description === "" ||
      debouncedDetails?.description?.length > 2000) &&
      tryPublish) ||
    (lengthError && debouncedDetails?.description?.length > 2000)

  const nameError =
    ((debouncedDetails?.name === "" || debouncedDetails?.name?.length > 50) &&
      tryPublish) ||
    (lengthError && debouncedDetails?.name?.length > 50)

  // const headlineError =
  //   ((debouncedDetails?.headline === "" ||
  //     debouncedDetails?.headline?.length > 100) &&
  //     tryPublish) ||
  //   (lengthError && debouncedDetails?.headline?.length > 100)

  const setThumbnail = (uploadRes, loadingAction, type) => {
    const image = JSON.stringify({
      url: uploadRes.data.url,
      secure_url: uploadRes.data.secure_url,
      originial_filename: uploadRes.data.original_filename,
      version: uploadRes.data.version,
      format: uploadRes.data.format,
      public_id: uploadRes.data.public_id,
    })
    const data = {
      [type]: image,
    }
    updatePathMutation.mutate(data, {
      onSuccess: (res) => {
        setTimeout(() => {
          loadingAction(false)
        }, 400)
        serialize("path", res.data).then((serializedData) => {
          return queryClient.setQueryData("paths", (oldData) => {
            let newArray = []
            newArray = oldData.map((x) => {
              if (x.id !== serializedData.id) {
                return x
              } else {
                //only update image to dont loss data that it is not save
                x[type] = serializedData[type]
                return x
              }
            })
            return [...newArray]
          })
        })
      },
      onError: (err) => {
        console.log(err)
        loadingAction(false)
        openSnackBar({
          message: "Error Updating Thumbnail",
        })
      },
    })
  }

  //set learning path subtitle to empty string
  useEffect(() => {
    setPageSubTitle("")
  }, [])

  //delete tag from the tags array in  learning path context
  const deleteTag = (name) => {
    setTagsContext(tagsContext?.filter((x) => x.name !== name))
  }

  // set learning path details to compoment state
  useEffect(() => {
    if (path?.id) {
      setPathDetailsValues({
        accredible_group_id: path.accredible_group_id || "",
        name: path.name,
        description: path.description || "",
        headline: path.headline || "",
        is_public: path?.is_public ? true : false,
      })
      setInitialDetailsContext({
        accredible_group_id: path.accredible_group_id || "",
        name: path.name,
        description: path.description || "",
        headline: path.headline || "",
        is_public: path?.is_public ? true : false,
      })
    }
  }, [path])

  //set details context state from this component state using debounce
  useEffect(() => {
    setDebouncedDetails(pathDetailsValues)
  }, [debouncedLocalValues])

  return (
    <Box className={classes.container}>
      <Typography variant="h3" color="primary" className={classes.title}>
        Learning Path Details
      </Typography>
      <CounterHeader
        title="Learning Path Name"
        value={debouncedDetails?.name}
        max={50}
      />
      <TextField
        value={pathDetailsValues?.name || ""}
        onChange={(e) => {
          setPathDetailsValues((oldState) => ({
            ...oldState,
            name: e.target.value,
          }))
        }}
        style={{ marginBottom: "10px" }}
        InputProps={{
          classes: {
            root: classes.inputBackgroundColor,
          },
        }}
        variant="filled"
        size="small"
        fullWidth
      />
      {nameError ? (
        <Typography className={classes.requiredText}>
          Name is required and it has to be less than 50 characters
        </Typography>
      ) : null}
      {/* <CounterHeader
        title="Headline"
        value={debouncedDetails?.headline}
        max={100}
      />
      <TextField
        value={pathDetailsValues?.headline || ""}
        onChange={(e) => {
          setPathDetailsValues((oldState) => ({
            ...oldState,
            headline: e.target.value,
          }))
        }}
        style={{ marginBottom: "10px" }}
        InputProps={{
          classes: {
            root: classes.inputBackgroundColor,
          },
        }}
        variant="filled"
        size="small"
        fullWidth
      />
      {headlineError ? (
        <Typography className={classes.requiredText}>
          Headline is required and it has to be less than 255 characters
        </Typography>
      ) : null} */}
      <CounterHeader
        title="Badge Overview"
        value={debouncedDetails?.description}
        max={2000}
      />
      <TextField
        value={pathDetailsValues.description || ""}
        multiline
        rows={10}
        onChange={(e) => {
          setPathDetailsValues((oldState) => ({
            ...oldState,
            description: e.target.value,
          }))
        }}
        InputProps={{
          disableUnderline: true,
          classes: {
            root: classes.inputDescription,
          },
        }}
        size="small"
        fullWidth
      />
      {descriptionError ? (
        <Typography className={classes.requiredText}>
          Badge overview is required and it has to be less than 2000 characters
        </Typography>
      ) : null}
      {/* <DateTime /> */}
      <Typography color="secondary" className={classes.inputLabel}>
        Search Terms
      </Typography>
      <Autocomplete
        id="tags-autocomplete"
        options={tags || []}
        renderInput={(params) => <TextField {...params} variant="filled" />}
        value={tagAutocompleteValue}
        onChange={(e, val) => setTagAutocompleteValue(val)}
        getOptionLabel={(option) => option.name || option}
        getOptionDisabled={(option) =>
          !!tagsContext.find((y) => y.name === option.name)
        }
        freeSolo
        openOnFocus
        onInputChange={(e, val) => {
          setTagTextFieldValue(val)
        }}
        inputValue={tagTextFieldValue}
        style={{
          width: "100%",
          backgroundColor: "#F4F4F4",
          borderRadius: "4px",
        }}
        onKeyPress={(e) => {
          if (
            e.key === "Enter" &&
            (tagTextFieldValue !== "" || tagAutocompleteValue !== "")
          ) {
            //check if we are using the same work with capital letters or spaces
            if (
              tags.find(
                (y) =>
                  y.name.replace(/\s+/g, "").toLowerCase() ===
                  tagTextFieldValue.replace(/\s+/g, "").toLowerCase()
              ) &&
              tagsContext.find(
                (y) =>
                  y.name.replace(/\s+/g, "").toLowerCase() ===
                  tagTextFieldValue.replace(/\s+/g, "").toLowerCase()
              )
            ) {
              setTagAutocompleteValue("")
              setTagTextFieldValue("")
            } else {
              const found = tags.find(
                (y) =>
                  y.name.replace(/\s+/g, "").toLowerCase() ===
                  tagTextFieldValue.replace(/\s+/g, "").toLowerCase()
              )
              if (found) {
                setTagsContext([...tagsContext, found])
                setTagAutocompleteValue("")
                setTagTextFieldValue("")
              } else {
                setTagAutocompleteValue("")
                setTagTextFieldValue("")
                setTagsContext([
                  ...tagsContext,
                  typeof tagAutocompleteValue === "object"
                    ? tagAutocompleteValue
                    : { name: tagAutocompleteValue },
                ])
              }
            }
          }
        }}
        size="small"
        classes={{
          inputRoot: classes.autocompleteRoot,
        }}
      />
      {tagsContext?.length === 0 && tryPublish ? (
        <Typography className={classes.requiredText}>
          Search Terms are required
        </Typography>
      ) : null}
      <Box
        display="flex"
        flexWrap="wrap"
        style={{ height: "100%" }}
        marginBottom={2}
      >
        {tagsContext?.map((x, i) => {
          return (
            <Tag
              key={i}
              tagName={x.name || x}
              deleteTag={() => deleteTag(x.name)}
            />
          )
        })}
      </Box>
      <Typography color="secondary" className={classes.inputLabel}>
        Accredible Group ID
      </Typography>
      <TextField
        value={pathDetailsValues?.accredible_group_id || ""}
        onChange={(e) => {
          setPathDetailsValues((oldState) => ({
            ...oldState,
            accredible_group_id: e.target.value,
          }))
        }}
        style={{ marginBottom: "10px" }}
        InputProps={{
          classes: {
            root: classes.inputBackgroundColor,
          },
        }}
        variant="filled"
        size="small"
        fullWidth
      />
      <Box display="flex" justifyContent="space-between">
        <Box width="48%">
          <Typography color="secondary" className={classes.inputLabel}>
            Hero Image
          </Typography>
          <ThumbnailUpload
            title="Drop hero image here"
            cloudinaryUploadPreset={
              process.env.REACT_APP_CLOUDINARY_CLOUD_THUMBNAIL_UPLOAD
            }
            value={path?.thumbnail}
            onUpload={setThumbnail}
            type="thumbnail"
          />
          {Object.keys(path?.thumbnail)?.length === 0 && tryPublish ? (
            <Typography className={classes.requiredText}>
              Hero Image is required
            </Typography>
          ) : null}
        </Box>
        <Box width="48%">
          <Typography color="secondary" className={classes.inputLabel}>
            Badge
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="center">
            <ThumbnailUpload
              title="Drop badge here"
              cloudinaryUploadPreset={
                process.env.REACT_APP_CLOUDINARY_CLOUD_THUMBNAIL_UPLOAD
              }
              value={path?.badge_image}
              type="badge_image"
              onUpload={setThumbnail}
            />
          </Box>
          {Object.keys(path?.badge_image)?.length === 0 && tryPublish ? (
            <Typography className={classes.requiredText}>
              Badge is required
            </Typography>
          ) : null}
        </Box>
      </Box>
      <Box>
        <Typography color="secondary" className={classes.inputLabel}>
          Public | Private
        </Typography>
        <Switch
          onChange={(e) => {
            setPathDetailsValues((oldState) => ({
              ...oldState,
              is_public: e.target.checked,
            }))
          }}
          value={pathDetailsValues?.is_public}
          checked={pathDetailsValues?.is_public}
        />
      </Box>
    </Box>
  )
}

PathDetails.propTypes = {
  tags: PropTypes.array,
}

export default PathDetails
