import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core"
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle"
import { setCourseLab } from "actions/courses"
import LabIcon from "assets/Icons/lab-icon.svg"
import classnames from "clsx"
import HoverText from "components/Common/HoverText"
import PaperListRow from "components/Common/PaperListRow"
import AppContext from "contexts/App"
import PropTypes from "prop-types"
import { useState } from "react"
import { useContext } from "react"
import { useMutation, useQueryClient } from "react-query"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

import useStyles from "./styles"

const AddedLab = ({ courseId, lab, disabled }) => {
  const classes = useStyles()

  const history = useHistory()

  const { openDialog } = useContext(AppContext)

  const [deleting, setDeleting] = useState(false)

  const queryClient = useQueryClient()

  const setCourseLabMutation = useMutation("setCourseLab", (mutationData) =>
    setCourseLab(...mutationData)
  )

  const handleRemoveLab = () => {
    openDialog("confirmDialog").then(() => {
      setDeleting(true)
      setCourseLabMutation.mutate([courseId, { lab_id: null }], {
        onSuccess: (res) => {
          queryClient.setQueryData(["course", courseId], () => {
            return res
          })
        },
        onError: (err) => {
          console.error(err)
        },
      })
    })
  }

  return (
    <PaperListRow componentWidth={"100%"}>
      <Grid
        container
        spacing={0}
        className={classnames(classes.container, {
          [classes.disabled]: deleting,
        })}
      >
        {deleting && (
          <Box className={classes.spinnerBox}>
            <CircularProgress size={20} />
          </Box>
        )}
        <Grid
          item
          md={4}
          xs={12}
          className={classes.titleContainer}
          onClick={() => history.push(`/labs/${lab.id}`)}
          style={disabled ? { filter: "opacity(0.5)" } : null}
        >
          <img src={LabIcon} alt="Lab" style={{ width: 18, marginRight: 16 }} />
          <HoverText
            data={lab?.name}
            size={30}
            variant="h3"
            className={classes.nameText}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Typography>{lab?.server_size}</Typography>
        </Grid>
        <Grid item md={3} xs={12}>
          <Typography>{lab?.server_type}</Typography>
        </Grid>
        <Grid item md={2} xs={12}>
          {/* Iconbutton to be disabled when not in draft mode */}
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <IconButton onClick={handleRemoveLab}>
              <RemoveCircleIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </PaperListRow>
  )
}

AddedLab.propTypes = {
  disabled: PropTypes.bool,
  lab: PropTypes.object,
  courseId: PropTypes.number,
}

export default AddedLab
