import { Box, Divider, Typography } from "@material-ui/core"
import PropTypes from "prop-types"
import React, { useMemo } from "react"

import useStyles from "./styles"

const StatusFilters = ({ setStatusState, statusState, statusNumber, type }) => {
  const classes = useStyles()

  const generalStatus = [
    { type: "published", label: "Published" },
    { type: "unpublished", label: "Unpublished" },
    { type: "draft", label: "Draft" },
    { type: "archived", label: "Archived" },
  ]

  const courseStatus = [
    { type: "published", label: "Published" },
    { type: "unpublished", label: "Unpublished" },
    { type: "draft", label: "Draft" },
    { type: "archived", label: "Archived" },
    { type: "scheduled", label: "Scheduled" },
  ]

  const certStatus = [
    { type: "published", label: "Published" },
    { type: "unpublished", label: "Unpublished" },
    { type: "draft", label: "Draft" },
  ]

  const statusArray = useMemo(() => {
    if (type === "courses") {
      return courseStatus
    } else if (type === "certifications") {
      return certStatus
    } else {
      return generalStatus
    }
  }, [type])

  return (
    <Box className={classes.container}>
      {statusArray.map((status, i) => {
        return (
          <Box className={classes.buttonContainer} key={i}>
            <Typography
              className={classes.button}
              onClick={() => setStatusState(status.type)}
              style={
                statusState === status.type
                  ? { color: "#092958", fontWeight: "bold" }
                  : { color: "#808080" }
              }
            >
              {status.label}{" "}
              {statusNumber[status.type] ? statusNumber[status.type] : ""}
            </Typography>
            <Divider />
          </Box>
        )
      })}
    </Box>
  )
}

StatusFilters.propTypes = {
  setStatusState: PropTypes.func,
  statusState: PropTypes.string,
  statusNumber: PropTypes.object,
  type: PropTypes.string,
}

export default StatusFilters
