import { Box, Button, Typography } from "@material-ui/core"
import { AddCircle } from "@material-ui/icons"
import PropTypes from "prop-types"
import React from "react"

import useStyles from "./styles"

const NoData = ({
  text,
  buttonText,
  buttonFunc,
  length,
  searchValue,
  searchLength,
}) => {
  const classes = useStyles()

  if (length === 0) {
    return (
      <Box
        width="600px"
        height="300px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        className={classes.container}
      >
        <Typography color="secondary" variant="h2" className={classes.text}>
          {text}
        </Typography>
        <Button
          onClick={buttonFunc}
          classes={{ label: classes.button }}
          variant="contained"
          disableElevation
          startIcon={<AddCircle />}
        >
          {buttonText.toUpperCase()}
        </Button>
      </Box>
    )
  }
  if (length > 0 && searchLength === 0) {
    return (
      <Box>
        <Typography variant="h2" color="primary">
          No Matches Found
        </Typography>
      </Box>
    )
  } else {
    return null
  }
}

NoData.propTypes = {
  text: PropTypes.string,
  buttonText: PropTypes.string,
  buttonFunc: PropTypes.func,
  searchValue: PropTypes.string,
  length: PropTypes.number,
  searchLength: PropTypes.number,
}

export default NoData
