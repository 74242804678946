import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  container: {
    width: 280,
    height: 320,
    margin: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  root: {
    border: "1px solid #E2E2E2",
    borderRadius: "10px",
    height: "100%",
  },
  media: {
    height: 145,
  },
  version: {
    fontWeight: 600,
    marginRight: "2px",
  },
  title: {
    fontWeight: 600,
    lineHeight: 1.2,
  },
  date: { color: "#808080" },
  lessons: {
    fontWeight: 600,
  },
  image: {
    height: 16,
    width: 10,
    marginLeft: "5px",
  },
  durationIcon: {
    height: "16",
    width: 16,
    marginLeft: "5px",
  },
  cardMedia: {
    width: "100%",
    paddingTop: "56.25%",
    backgroundColor: theme.palette.primary.main,
  },
}))

export default styles
