import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  Input,
  Radio,
  RadioGroup,
} from "@material-ui/core"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked"
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle"
import classnames from "clsx"
import { observer } from "mobx-react"
import PropTypes from "prop-types"

import useStyles from "./styles"

const ResponseList = ({ responseSet, isEdit, showErrors = false }) => {
  const classes = useStyles()

  return (
    <Box
      className={classnames(classes.responseList, {
        [classes.isEdit]: isEdit,
      })}
    >
      {responseSet.options.map((option, i) => (
        <Box key={option.id} className={classes.responseItem}>
          <Box flexGrow={1}>
            <Input
              readOnly={!isEdit}
              disabled={!isEdit}
              placeholder={`Option ${i + 1}`}
              fullWidth
              value={option.name}
              onChange={(e) => option.update(e.target.value)}
              endAdornment={
                <IconButton
                  tabIndex={-1}
                  disabled={!isEdit}
                  className={classes.removeButton}
                  size="small"
                  onClick={() => option.remove()}
                >
                  <RemoveCircleIcon className={classes.removeIcon} />
                </IconButton>
              }
            />
            {showErrors && (
              <FormHelperText error>{option?.errors?.name}</FormHelperText>
            )}
          </Box>
        </Box>
      ))}
      {showErrors &&
        Object.keys(responseSet.errors).map((key) => (
          <FormHelperText key={key} error>
            {responseSet.errors[key]}
          </FormHelperText>
        ))}
      {isEdit && (
        <Button
          onClick={() => responseSet.addOption()}
          color="secondary"
          style={{ fontSize: "0.75rem", marginLeft: -1 }}
          startIcon={<AddCircleIcon style={{ fontSize: 12 }} />}
        >
          Add Response
        </Button>
      )}
    </Box>
  )
}

ResponseList.propTypes = {
  responseSet: PropTypes.object,
  isEdit: PropTypes.bool,
  isMultiple: PropTypes.bool,
  showErrors: PropTypes.bool,
}

export default observer(ResponseList)
