import { Box } from "@material-ui/core"
import LearningCuesContext from "contexts/LearningCues"
import PropTypes from "prop-types"
import Slider, { Range } from "rc-slider"
import { forwardRef, useContext, useEffect, useMemo, useState } from "react"

import CuePoint from "./CuePoint"
import useStyles from "./styles"

const HandleComponent = forwardRef(
  ({ value, highlighted, cueType, max, ...props }, ref) => {
    const classes = useStyles()

    const offset = useMemo(() => {
      return (value / max) * 100
    }, [value, max])

    return (
      <CuePoint
        offset={offset}
        cueType={cueType}
        highlighted={highlighted}
        disabled={props.disabled}
      />
    )
  }
)
HandleComponent.displayName = "HandleComponent"

HandleComponent.propTypes = {
  value: PropTypes.number,
  max: PropTypes.number,
  highlighted: PropTypes.bool,
  cueType: PropTypes.string,
  disabled: PropTypes.bool,
}

const TimelineCuePoints = ({ duration, lockdown }) => {
  const classes = useStyles()

  const {
    editingLearningCues,
    updateLearningCueTime,
    highlightedLearningCue,
  } = useContext(LearningCuesContext)

  const [learningCueTimes, setLearningCueTimes] = useState([])

  useEffect(() => {
    setLearningCueTimes(editingLearningCues.map((c) => c.start_seconds))
  }, [editingLearningCues])

  const handleChange = (value, index) => {
    setLearningCueTimes((currentTimes) =>
      currentTimes.map((t, i) => (i === index ? value : t))
    )
  }

  const handleChangeCommitted = (learningCue, value) => {
    updateLearningCueTime(learningCue, value)
  }

  if (editingLearningCues.length === 0) {
    return null
  }

  const getCueType = (cue) => {
    if (cue.hasOwnProperty("knowledgeCheck")) {
      return "knowledge-check"
    } else if (cue.hasOwnProperty("readyCheck")) {
      return "ready-check"
    } else if (cue.hasOwnProperty("prefilledNotes")) {
      return "prefilled-notes"
    } else {
      return "none"
    }
  }

  return (
    <Box className={classes.timelineCuePoints}>
      {editingLearningCues.map((learningCue, index) => (
        <Slider
          className={classes.timelineCuePointsSlider}
          key={learningCue.id}
          handle={(handleProps) => (
            <HandleComponent
              key={handleProps.value}
              cueType={getCueType(learningCue)}
              highlighted={learningCue.id === highlightedLearningCue}
              {...handleProps}
            />
          )}
          value={learningCueTimes[index]}
          onChange={(v) => handleChange(v, index)}
          onAfterChange={(v) => handleChangeCommitted(learningCue, v)}
          max={duration}
          trackStyle={{
            backgroundColor: "transparent",
          }}
          railStyle={{ backgroundColor: "transparent" }}
          dots={false}
          disabled={lockdown}
        />
      ))}
    </Box>
  )

  // return (
  //   <Range
  //     handle={(handleProps) => (
  //       <HandleComponent key={handleProps.value} {...handleProps} />
  //     )}
  //     allowCross={false}
  //     value={learningCueTimes}
  //     onChange={handleChange}
  //     onAfterChange={handleChangeCommitted}
  //     max={duration}
  //     trackStyle={learningCueTimes.map(() => ({
  //       backgroundColor: "transparent",
  //     }))}
  //     dots={false}
  //     pushable={3}
  //     railStyle={{ backgroundColor: "transparent" }}
  //   />
  // )
}

TimelineCuePoints.propTypes = {
  duration: PropTypes.number,
  lockdown: PropTypes.bool,
}

export default TimelineCuePoints
