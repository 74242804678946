import Switch from "@material-ui/core/Switch"
import CourseContext from "contexts/Course"
import React, { useContext } from "react"

const DiscoverPage = () => {
  const { courseDetailsValues, setCourseDetailsValues } = useContext(
    CourseContext
  )

  return (
    <Switch
      checked={courseDetailsValues?.is_public}
      onChange={(e) => {
        setCourseDetailsValues((oldState) => ({
          ...oldState,
          is_public: oldState.is_public === 0 ? 1 : 0,
        }))
      }}
    />
  )
}

export default DiscoverPage
