import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  paperRow: {
    border: "1px solid #E2E2E2",
    borderRadius: "8px",
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    // justifyContent: "space-between",
    alignItems: "center",

    height: "64px",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      width: "100%",
      height: "150px",
      justifyContent: "center",
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
  addedBox: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  buttonBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.between("sm", "md")]: {
      width: "15%",
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      width: "15%",
      flexDirection: "row-start",
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  imgBox: {
    width: 132,
    height: "100%",
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
    [theme.breakpoints.down("sm")]: {
      borderTopLeftRadius: "0px",
      borderBottomLeftRadius: "0px",
      width: 200,
      height: 100,
      display: "none",
    },
  },
  image: {
    verticalAlign: "bottom",
    height: "100%",
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "8px",
      borderTopLeftRadius: "0px",
      borderBottomLeftRadius: "0px",
      width: 200,
      height: 100,
      display: "none",
    },
  },

  cardContainer: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginTop: "8px",
    },
  },
  addButton: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "8px",
    },
  },
  name: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    marginBottom: "8px",
  },
}))

export default styles
