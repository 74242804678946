import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  topRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  codeNameBox: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  title: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
    },
  },
  whiteBackgroundColor: {
    "&.Mui-disabled": {
      backgroundColor: "#fff",
      color: "#fff",
    },
  },
  grayBackgroundColor: {
    backgroundColor: "#F4F4F4",
    '&[class*="MuiFilledInput-root"]': {
      paddingTop: 0,
    },
  },
  radioBox: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: theme.spacing(5),
    },
  },
  radioSubBox: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },

  rowTwo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
    },
  },

  rowTwoBox: {
    display: "flex",
    flexDirection: "column",
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "40%",
      flexWrap: "wrap",
      justifyContent: "centers",
      alignItems: "center",
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },

  textField: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(2),
    },
  },
  formHelperText: {
    marginLeft: "0px",
  },

  removeCourseButton: {
    height: "15px",
    width: "15px",
    color: "#FF5E69",
  },
  iconButton: {
    padding: 0,
  },
  removeCourseIcon: {
    height: "20px",
    width: "20px",
    marginRight: theme.spacing(1),
  },
  removeCodeButton: {},
  // copyButton: {
  //   backgroundColor: theme.palette.primary.main,
  // },
  expiration: {
    width: "115px",
    backgroundColor: "#F4F4F4",
  },
  calendarIcon: {
    fontSize: 14,
  },
  active: {
    color: "#29B117",
  },
  inactive: {
    color: "#FF5E69",
  },
  descriptionInput: {
    paddingTop: 0,
    "&.MuiFilledInput-multiline.MuiFilledInput-marginDense": {
      paddingTop: 0,
    },
  },
  imgError: {
    marginTop: "4px",
    fontSize: "8px",
    color: theme.palette.warning.main,
  },
  dialogWidth: { width: "unset" },
  ssoContainer: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}))

export default styles
