import React from "react"
import MaskedInput from "react-text-mask"
import PropTypes from "prop-types"

const MaskedSeats = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
      ]}
      guide={false}
      showMask={false}
      keepCharPositions
    />
  )
}
MaskedSeats.propTypes = {
  inputRef: PropTypes.func,
}

export default MaskedSeats
